import React, { useMemo, useRef } from 'react'
import Box from 'src/components/Box'
import useChart from 'src/features/HrAdmin/hooks/useChart'
import styled from 'styled-components'
import ganttChart from './charts/Gantt'
import { Icon } from 'src/UIKit'

interface IProps {
  absences: IAbsence[]
  selectedAbsence: IAbsence
  selectedYear: number
  onClick: (a: IAbsence) => void
}

const ChartContainer = styled.div`
  width: 100%;
  flex: 1;
`

const BoxWrapper = styled(Box)`
  padding-top: 10px;
  padding-bottom: 10px;
`

const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 7px 0 0;
  gap: 4.55px;
  min-width: 180px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const IconWrapper = styled(Icon)`
  display: flex;
  align-items: center;
  padding-right: 4px;
`

const UserContainer = styled.button<{ selected: boolean }>`
  color: ${props =>
    props.selected ? props.theme.colors.light100 : props.theme.colors.main100};
  background-color: ${props =>
    props.selected ? props.theme.colors.main100 : props.theme.colors.light100};
  border-radius: 8px;
  outline-offset: 0 !important;
  padding: 4px 6px;
  font-size: 18px;
  align-items: center;
  text-align: right;
  margin-left: auto;
`

const Tooltip = styled.div`
  position: absolute;
  z-index: 10;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  background: ${props => props.theme.colors.light100};
  color: ${props => props.theme.colors.dark60};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
  visibility: hidden;
`

export const dataAttrs = {
  employeeBtn: () => 'users-timeline-employee-btn',
  tooltip: () => 'users-timeline-tooltip'
}

const UsersTimelineView = React.memo((props: IProps) => {
  const { absences, selectedAbsence, selectedYear, onClick } = props

  const chartContainerRef: any = useRef(null)
  const tooltipRef: any = useRef(null)

  const chartArgs = useMemo(
    () => ({ absences, selectedYear, tooltipRef }),
    [absences, selectedYear, tooltipRef]
  )

  useChart(chartContainerRef, ganttChart, chartArgs)

  const usersList = useMemo(
    () => (
      <>
        {absences.map((absence, index) => {
          const selected = absence.id === selectedAbsence.id
          return (
            <RowContainer key={index}>
              {absence.outdated && (
                <IconWrapper name={'exclamation_sign_filled'} />
              )}
              <UserContainer
                data-testid={dataAttrs.employeeBtn()}
                selected={selected}
                onClick={() => onClick(absence)}
              >
                {absence.employee.name}
              </UserContainer>
            </RowContainer>
          )
        })}
      </>
    ),
    [absences, onClick, selectedAbsence]
  )

  return (
    <BoxWrapper>
      <Tooltip data-testid={dataAttrs.tooltip()} ref={tooltipRef} />
      <UsersListContainer>{usersList}</UsersListContainer>
      <ChartContainer ref={chartContainerRef} />
    </BoxWrapper>
  )
})

UsersTimelineView.displayName = 'UsersTimelineView'

export default UsersTimelineView
