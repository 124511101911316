import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Button, H1 } from 'src/UIKit'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import { createLeaveButtons } from 'src/constants/frame'
import ReactMarkdown from 'react-markdown'

interface IProps {
  name: string
  onCancelClicked?: () => void
  onNextClicked: () => void
  isNextDisabled?: boolean
  children?: ReactNode
  hidesCancelButton?: boolean
  cancelButtonTextKey?: string
  nextButtonTextKey?: string
  disabilityEndDate?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          margin: 0 16px;
        `
      : css`
          margin: 0 auto;
          width: calc(100vw - 32px);
        `}
  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  max-width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 48px;
        `
      : css`
          margin-top: 16px;
        `}
`

const Description = styled(ReactMarkdown)`
  width: 100%;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin: 0 0 40px;
        `
      : css`
          font-size: 14px;
          margin: 0 0 16px;
        `}
  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ul {
    list-style: disc;
    padding: 1em 0 0 2em;
    white-space: normal;
    margin-block-start: -20px;
  }
`

const ButtonsContainer = styled.div<{ $centerItems: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.$centerItems ? 'center' : 'space-between'};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 48px;
          width: calc(${createLeaveButtons.width().px} + 16px);
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
          margin: 0 0 16px;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)<any>`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: ${props.mobileFullWidth ? '100%' : 'calc(50% - 8px)'};
          height: 48px;
          font-size: 16px;
        `}
`

export const dataAttrs = {
  title: () => 'transition-title',
  buttonNext: () => 'transition-button-next',
  buttonCancel: () => 'transition-button-cancel'
}

export const TransitionStepsView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    name,
    children,
    onCancelClicked,
    onNextClicked,
    isNextDisabled,
    hidesCancelButton,
    cancelButtonTextKey,
    nextButtonTextKey,
    disabilityEndDate
  } = props

  const content: any = useMemo(
    () => (
      <>
        {children}
        <ButtonsContainer $centerItems={hidesCancelButton}>
          {!hidesCancelButton && (
            <ButtonWrapper
              data-testid={dataAttrs.buttonCancel()}
              appearance={'cancel'}
              onClick={onCancelClicked}
            >
              {t(cancelButtonTextKey)}
            </ButtonWrapper>
          )}
          <ButtonWrapper
            data-testid={dataAttrs.buttonNext()}
            onClick={onNextClicked}
            disabled={isNextDisabled}
            mobileFullWidth={hidesCancelButton}
          >
            {t(nextButtonTextKey)}
          </ButtonWrapper>
        </ButtonsContainer>
      </>
    ),
    [
      t,
      children,
      hidesCancelButton,
      onCancelClicked,
      onNextClicked,
      isNextDisabled,
      cancelButtonTextKey,
      nextButtonTextKey
    ]
  )

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>
        {t(`transitionFlow.pages.${name}.title`)}
      </Title>
      <Description
        components={{
          a: (linkProps: any) => (
            <a
              href={linkProps.href}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {linkProps.children}
            </a>
          )
        }}
      >
        {t(`transitionFlow.pages.${name}.description`, {
          date: disabilityEndDate
        })}
      </Description>
      {content}
    </Container>
  )
})

TransitionStepsView.displayName = 'TransitionStepsView'

export default TransitionStepsView
