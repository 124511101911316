import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import {
  getDueDate,
  getDueDateLabelKey,
  getLeaveEndDate,
  getLeaveStartDate,
  getPeriodDurationTextComponents,
  IDuration
} from 'src/utils/leaveUtils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Row from './Row'
import { ICountryCode } from 'src/react-app-env'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import SharedContext from 'src/contexts/SharedContext'
import SyncStatusView from 'src/components/SyncStatusView'

interface IProps {
  className?: string
  leave: ILeave
  countryCode: ICountryCode
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
`

const Separator = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: ${props => props.theme.colors.main40};

  &:last-child {
    margin: 0;
  }
`

const EmptySpace = styled.div`
  height: 15px;
`

const LeaveTypeWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark80};
  margin: 16px 0 3px;
`

const SyncStatusViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 0 0;
`

export const dataAttrs = {
  emptySpace: () => 'journeymap-leave-info-empty-space'
}

const LeaveInfo = React.memo((props: IProps) => {
  const { className, leave, countryCode } = props
  const { customerConfig } = useContext(SharedContext)
  const { t } = useTranslation()

  const daysTillDueDate: number = useMemo(
    () => moment(leave.dueDate).diff(moment(), 'days'),
    [leave.dueDate]
  )
  const dueDateIn: IDuration = useMemo(
    () => getPeriodDurationTextComponents(daysTillDueDate, t),
    [daysTillDueDate, t]
  )
  const subtype: string = useMemo(() => {
    if (leave.type === 'Pregnancy' && leave.subtype) {
      return t(`subtypes.${leave.subtype}`)
    }
  }, [t, leave.subtype, leave.type])
  const dateLabel: string = useMemo(
    () => t(getDueDateLabelKey(leave)),
    [leave, t]
  )
  const dueDate: string = useMemo(
    () => leave.dueDate && getDueDate(leave).current.format(DATE_FORMAT),
    [leave]
  )
  const startDate: string = useMemo(
    () => getLeaveStartDate(leave).current.format(DATE_FORMAT),
    [leave]
  )
  const endDate: string = useMemo(
    () => getLeaveEndDate(leave).current.format(DATE_FORMAT),
    [leave]
  )
  const leaveType: any = useMemo(
    () => <LeaveTypeWrapper>{t(`types.${leave.type}`)}</LeaveTypeWrapper>,
    [leave, t]
  )
  const syncStatusError = useMemo(
    () =>
      leave?.tpa?.syncFailedAt &&
      customerConfig.leave.tpaSync.showSyncStatus(leave) && (
        <SyncStatusViewContainer>
          <SyncStatusView tpa={leave.tpa} />
        </SyncStatusViewContainer>
      ),
    [customerConfig.leave.tpaSync, leave]
  )
  const dueDateInRow = useMemo(
    () => (
      <>
        <Row
          label={t('leaveInfo.dateIn', { date: dateLabel })}
          text={dueDateIn.components[0].duration}
          value={dueDateIn.components[0].value.toString()}
        />
        <Separator />
      </>
    ),
    [t, dueDateIn, dateLabel]
  )
  const approvalState = useMemo(() => {
    if (
      !customerConfig.leave.timeline.useTpaApproval(leave.type, countryCode)
    ) {
      return null
    }

    return (
      <Row
        label={t('leaveInfo.approvalState')}
        text={t(`leaveInfo.${leave.approvedByTPA ? 'approved' : 'unapproved'}`)}
        isRedText={!leave.approvedByTPA}
      />
    )
  }, [leave, t, countryCode, customerConfig.leave.timeline])

  const showEmptySpace = useMemo(
    () => subtype || dueDate || approvalState,
    [subtype, dueDate, approvalState]
  )
  return (
    <Container
      className={className}
      role={'region'}
      aria-label={t('common.accessibilityText.regionJourneyLeaveInfo')}
    >
      {leaveType}
      {syncStatusError}
      {dueDateIn.components[0].value > 0
        ? dueDateInRow
        : showEmptySpace && <EmptySpace data-testid={dataAttrs.emptySpace()} />}
      {subtype && <Row label={t('leaveInfo.deliveryMethod')} text={subtype} />}
      {startDate && (
        <Row label={`${t('common.startDate')}:`} text={startDate} />
      )}
      {dueDate && <Row label={`${dateLabel}:`} text={dueDate} />}
      {endDate && <Row label={`${t('common.endDate')}:`} text={endDate} />}
      {approvalState}
    </Container>
  )
})

LeaveInfo.displayName = 'LeaveInfo'

export default LeaveInfo
