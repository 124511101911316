import React, { Ref, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import ReactMarkdown from 'react-markdown'
import usePrevious from 'src/components/hooks/usePrevious'
import createTheme from 'src/theme'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

interface IProps {
  text: string
  shows: boolean
  className?: string
  marginTop?: number
  marginBottom?: number
}

const Container = styled.div<{
  $shows: boolean
  $height: number
  $marginTop: number
  $marginBottom: number
}>`
  width: 100%;
  transition: all 300ms ease-out;
  height: auto;

  ${props =>
    props.theme.isDesktop &&
    css`
      overflow: hidden;
    `}

  ${(props: any) => {
    const { $shows, $height, $marginTop, $marginBottom } = props
    const h: string = $shows ? ($height ? `${$height}px` : '100%') : '0px'
    const opacity: number = $shows ? 1 : 0
    const marginT: string = $shows ? `${$marginTop}px` : '0'
    const marginB: string = $shows ? `${$marginBottom}px` : '0'
    const visibility: string = $shows ? 'visible' : 'hidden'
    return css`
      height: ${h};
      opacity: ${opacity};
      margin-top: ${marginT};
      margin-bottom: ${marginB};
      visibility: ${visibility};
    `
  }}
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
`

const Text = styled(ReactMarkdown)`
  margin-left: 18px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 2px solid ${props => props.theme.colors.main100};
      outline-offset: 0;
      border-radius: 2px;
    }
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

export const dataAttrs = {
  container: () => 'create-leave-note-container'
}

export const CreateLeaveNote = React.memo((props: IProps) => {
  const { text, className, shows, marginTop, marginBottom } = props

  const prevText: string = usePrevious(text)
  const [height, setHeight] = useState<number>(0)
  const contentRef: Ref<any> = useRef(null)
  const contentRect: IRect = useComponentRect(contentRef)

  useEffect(() => {
    if (text !== prevText) {
      setHeight(0)
    }
  }, [setHeight, prevText, text])

  useEffect(() => {
    if (height === 0 && contentRect.height !== 0) {
      setHeight(contentRect.height)
    }
  }, [height, contentRect, setHeight])

  return (
    <Container
      className={className}
      data-testid={dataAttrs.container()}
      $height={height}
      $shows={shows}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      aria-hidden={!shows}
      role={shows ? 'alert' : null}
    >
      <ContentContainer ref={contentRef}>
        <Icon name={'info_circle'} settings={{ fill: colors.main100 }} />
        <Text
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer
          }}
        >
          {text}
        </Text>
      </ContentContainer>
    </Container>
  )
})

CreateLeaveNote.displayName = 'CreateLeaveNote'

export default CreateLeaveNote
