import React, { ReactNode, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import isEqual from 'lodash.isequal'
import { ITimelinePeriodBlock } from 'src/react-app-env'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import { Icon } from 'src/UIKit'
import IncomeValueView from './components/IncomeValueView'
import IncomeNoteView from './components/IncomeNoteView'
import DurationView from './components/DurationView'
import RequiredActionView from './components/RequiredActionView'
import TypeView from './components/TypeView'
import JobProtectedView from './components/JobProtectedView'
import ActiveDaysView from './components/ActiveDaysView'
import ActiveDaysWeekView from './components/ActiveDaysWeekView'
import { useTranslation } from 'react-i18next'
import {
  isFiveWorkdaysSchedule,
  isFiveDaySchedule,
  DATE_FORMAT_WITH_DAY
} from 'src/utils/dateUtils'
import { isEqualActiveDays } from 'src/utils/periodUtils'
import TimelineArrow from 'src/features/Timeline/components/common/TimelineArrow'
import createTheme from 'src/theme'
import { getDaysBetweenDates } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/methods'
const { colors } = createTheme()

const MIN_BLOCK_HEIGHT = 64

const addedKeyframes = (height: number) => keyframes`
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: ${height + 'px'};
  }
`

const removedKeyframes = (height: number) => keyframes`
   0% {
    opacity: 1;
    height: ${height + 'px'};
  }

  100% {
    opacity: 0;
    height: 0;
  }
`

const Container = styled.li<any>`
  background: ${props => {
    const { $requiredActions, $isWeekend, $isRampBack } = props
    const {
      timelineBlockType1GradientTop,
      timelineBlockType1GradientBottom,
      timelineBlockType2GradientTop,
      timelineBlockType2GradientBottom
    } = props.theme.colors
    return $isWeekend || $isRampBack || $requiredActions?.length
      ? `linear-gradient(360deg, ${timelineBlockType2GradientTop} 0%, ${timelineBlockType2GradientBottom} 100%);`
      : `linear-gradient(360deg, ${timelineBlockType1GradientTop} 0%, ${timelineBlockType1GradientBottom} 100%);`
  }};

  ${props => {
    const { $animatesAdded, $animatesRemoved } = props

    if ($animatesAdded) {
      return css`
        animation: ${addedKeyframes(MIN_BLOCK_HEIGHT)}
          ${TIMELINE_DETAILS_PUSH_DURATION}ms ease-in-out forwards;
      `
    } else if ($animatesRemoved) {
      return css`
        animation: ${removedKeyframes(MIN_BLOCK_HEIGHT)}
          ${TIMELINE_DETAILS_PUSH_DURATION}ms ease-in-out forwards;
      `
    }
  }}

  ${props =>
    (props.$unused || props.$isFirst) &&
    css`
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    `};

  ${props =>
    (props.$unused || props.$isLast) &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    `};

  &:not(:first-child) {
    margin-top: 2px;
  }

  transition: height ${TIMELINE_DETAILS_PUSH_DURATION}ms;

  ${props =>
    props.$unused &&
    css`
      background: transparent;
      border: 1px solid ${props.theme.colors.timelineIncome};

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    `}

  @media print {
    width: 100%;
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  gap: 8px;
`

const LeftContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 64px;
  align-self: flex-start;
  margin: 4px 0;
  text-align: center;
`

const BlockDetails = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
`

const ColumnContainer = styled.div<{ $alignItems: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: ${props => props.$alignItems};
  gap: 8px;
`

const Text = styled.p`
  line-height: 100%;
  font-size: ${props => (props.theme.isDesktop ? '12px' : '11px')};
  color: ${props => props.theme.colors.light100};
`

const DurationAndActiveDaysContainer = styled.div`
  display: flex;
  align-items: center;
`

const UnusedLine = styled(Icon)`
  position: absolute;
  z-index: 10;
`

export const dataAttrs = {
  container: () => 'block-view-element',
  startDate: () => 'block-view-start-date',
  endDate: () => 'block-view-end-date'
}

export interface IProps {
  isFirst: boolean
  isLast: boolean
}

const BlockView = React.memo(
  (props: IProps & ITimelinePeriodBlock & IApplicableSchedule) => {
    const {
      isUnused,
      incomeValue,
      incomeNote,
      isWeekend,
      isRampBack,
      type,
      duration,
      durationFullString,
      animatesAdded,
      animatesRemoved,
      jobProtected,
      jobProtectedText,
      requiredActions,
      hidesIncomeSection,
      activeDays,
      activeDaysWeekOne,
      activeDaysWeekTwo,
      weekOne,
      weekTwo,
      startDate,
      endDate,
      isPartial,
      isFirst,
      isLast
    } = props

    const { t } = useTranslation()

    const leftContainer: ReactNode = useMemo(() => {
      if (hidesIncomeSection) {
        return null
      }
      return (
        <LeftContainer>
          <IncomeValueView isUnused={isUnused} isPartial={isPartial}>
            {incomeValue}
          </IncomeValueView>
          <IncomeNoteView isUnused={isUnused}>{incomeNote}</IncomeNoteView>
          {isUnused && <UnusedLine name={'unused_income'} />}
        </LeftContainer>
      )
    }, [isUnused, incomeValue, incomeNote, hidesIncomeSection, isPartial])

    const ariaLabel: string = useMemo(() => {
      let result = ''

      result += t('timeline.accessibility.block.basicInfo', {
        type,
        duration: durationFullString
      })

      if (!hidesIncomeSection) {
        result += t(
          `timeline.accessibility.block.${
            isUnused ? 'unusedPaymentInfo' : 'paymentInfo'
          }`,
          {
            incomeValue,
            incomeNote
          }
        )
      }

      if (activeDays?.length) {
        result += t('timeline.accessibility.block.selectedDays', {
          days: activeDays.join(', ')
        })
      }

      if (activeDaysWeekOne?.length) {
        result += t('timeline.accessibility.block.selectedDaysWeekOne', {
          days: activeDaysWeekOne.join(', ')
        })
      }

      if (activeDaysWeekTwo?.length) {
        result += t('timeline.accessibility.block.selectedDaysWeekTwo', {
          days: activeDaysWeekTwo.join(', ')
        })
      }

      requiredActions?.forEach((action: any) => {
        result += t(`timeline.accessibility.block.requiredAction.${action}`)
      })

      if (jobProtectedText) {
        result += jobProtectedText
      }

      return result
    }, [
      t,
      isUnused,
      incomeValue,
      incomeNote,
      type,
      durationFullString,
      requiredActions,
      hidesIncomeSection,
      jobProtectedText,
      activeDaysWeekOne,
      activeDaysWeekTwo,
      activeDays
    ])

    const showActiveDays = !isFiveWorkdaysSchedule(activeDays as IWeekday[])
    const showActiveWeekDays =
      !isEqualActiveDays(activeDaysWeekOne, weekOne) ||
      !isEqualActiveDays(activeDaysWeekTwo, weekTwo)
    const isDefaultSchedule = isFiveDaySchedule(weekOne, weekTwo)

    const blockDetails = useMemo(
      () => (
        <BlockDetails>
          <DurationAndActiveDaysContainer>
            <DurationView isUnused={isUnused}>{duration}</DurationView>
            {activeDays?.length && showActiveDays && (
              <ActiveDaysView activeDays={activeDays} />
            )}
            <div>
              {weekOne?.length && showActiveWeekDays && (
                <ActiveDaysWeekView
                  activeDays={activeDaysWeekOne}
                  schedule={weekOne}
                  isFiveDaySchedule={isDefaultSchedule}
                />
              )}
              {weekTwo?.length && showActiveWeekDays && (
                <ActiveDaysWeekView
                  activeDays={activeDaysWeekTwo}
                  schedule={weekTwo}
                  isFiveDaySchedule={isDefaultSchedule}
                />
              )}
            </div>
          </DurationAndActiveDaysContainer>
          <TypeView isUnused={isUnused}>{type}</TypeView>
          {requiredActions?.map((action: any) => (
            <RequiredActionView key={action} action={action} />
          ))}
          {jobProtectedText && (
            <JobProtectedView
              isUnused={isUnused}
              jobProtected={jobProtected}
              jobProtectedText={jobProtectedText}
            />
          )}
        </BlockDetails>
      ),
      [
        activeDays,
        activeDaysWeekOne,
        activeDaysWeekTwo,
        duration,
        isDefaultSchedule,
        isUnused,
        jobProtected,
        jobProtectedText,
        requiredActions,
        showActiveDays,
        showActiveWeekDays,
        type,
        weekOne,
        weekTwo
      ]
    )

    const blockDuration = useMemo(
      () => getDaysBetweenDates(startDate, endDate),
      [startDate, endDate]
    )

    const blockDates = useMemo(
      () =>
        startDate &&
        endDate && (
          <RowContainer>
            <ColumnContainer $alignItems={'end'}>
              <Text data-testid={dataAttrs.startDate()}>
                {startDate.format(DATE_FORMAT_WITH_DAY)}
              </Text>
              {blockDuration > 1 && (
                <Text data-testid={dataAttrs.endDate()}>
                  {endDate.format(DATE_FORMAT_WITH_DAY)}
                </Text>
              )}
            </ColumnContainer>
            {blockDuration > 1 && <TimelineArrow color={colors.light100} />}
          </RowContainer>
        ),
      [startDate, endDate, blockDuration]
    )

    return (
      <Container
        data-testid={dataAttrs.container()}
        $unused={isUnused}
        $isFirst={isFirst}
        $isLast={isLast}
        $animatesAdded={animatesAdded}
        $animatesRemoved={animatesRemoved}
        $requiredActions={requiredActions}
        $isWeekend={isWeekend}
        $isRampBack={isRampBack}
        aria-label={ariaLabel}
      >
        <ContentContainer aria-hidden>
          {leftContainer}
          {blockDetails}
          {blockDates}
        </ContentContainer>
      </Container>
    )
  },
  (prev: ITimelinePeriodBlock, next: ITimelinePeriodBlock): boolean =>
    isEqual(prev, next)
)

BlockView.displayName = 'BlockView'

export default BlockView
