import React from 'react'
import styled from 'styled-components'
import { getOverDueItems } from 'src/utils/journeyMap'
import OverdueBadge from 'src/features/ManagerJourneyMap/components/AbsenceListItemSharedComponents/OverdueBadge'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'

interface IProps {
  journeyMap: IJourneyMapItem[]
  numOfActiveFilters: number
}

const MobileExpandViewHeaderTitle = styled.span`
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  pointer-events: none;
  color: ${props => props.theme.colors.main100};
`

const OverdueBadgeWrapper = styled(OverdueBadge)`
  margin: 0;
`

export const CloseText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 40px;
  color: ${props => props.theme.colors.main100};
  height: 40px;
`

const MobilePlanSettingsButton = React.memo((props: IProps) => {
  const { journeyMap, numOfActiveFilters } = props
  const { t } = useTranslation()

  if (!journeyMap) {
    return null
  }

  const overdueCount: number = getOverDueItems(journeyMap).length
  const icon = overdueCount ? (
    <OverdueBadgeWrapper overdueCount={overdueCount} />
  ) : (
    <Icon
      name={'settings'}
      ariaLabel={t('common.accessibilityText.settings')}
    />
  )

  return (
    <React.Fragment>
      {icon}
      <MobileExpandViewHeaderTitle>
        {t('common.filters.filterNumber', { count: numOfActiveFilters })}
      </MobileExpandViewHeaderTitle>
    </React.Fragment>
  )
})

MobilePlanSettingsButton.displayName = 'MobilePlanSettingsButton'

export default MobilePlanSettingsButton
