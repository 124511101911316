import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'

interface IProps {
  className?: string
  children: ReactNode
  allowsParagraph?: boolean
}

const Wrapper = styled(ReactMarkdown)`
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const UIText = React.memo((props: IProps) => {
  const p: any = { ...props }
  if (!props.allowsParagraph) {
    p.allowedElements = ['text', 'strong', 'delete', 'emphasis', 'link']
    p.unwrapDisallowed = true
  }
  return (
    <Wrapper
      className={props.className}
      {...p}
      components={{
        link: (linkProps: any) => (
          <a href={linkProps.href} target="_blank" rel="noopener noreferrer">
            {linkProps.children}
          </a>
        )
      }}
    >
      {props.children && props.children.toString()}
    </Wrapper>
  )
})

UIText.displayName = 'UIText'

export default UIText
