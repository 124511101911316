import { css } from 'styled-components'

const focusMixin = css`
  outline: none;
  border: 1px solid transparent;

  &:focus {
    color: ${props => props.theme.colors.main100};
    font-weight: bold;
    border: 1px solid ${props => props.theme.colors.main100};
    background: ${props => props.theme.colors.main20} !important;
  }
`

const mainStylesMixin = css`
  .react-calendar__tile {
    color: ${props => props.theme.colors.dark80};
    background: white !important;
    font-size: 14px !important;
    border-radius: 32px !important;
    border: none;
    cursor: pointer;
    ${focusMixin};
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: ${props => props.theme.colors.main90} !important;
    color: white !important;
  }

  .react-calendar__tile:disabled {
    background-color: white !important;
    color: #c5c7cb;
    cursor: unset;
  }

  .react-calendar__tile--hasActive:disabled {
    color: #c5c7cb !important;
  }

  .react-calendar__navigation__arrow {
    border-radius: 32px !important;
    background: ${props => props.theme.colors.light100};
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: 40px;
    ${focusMixin};
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    font-size: 14px;
    width: 40px;
    border: none;
    ${focusMixin};
  }

  .react-calendar__navigation button[disabled] {
    background: white;
    cursor: unset;
  }

  .react-calendar__month-view__weekdays {
    text-decoration: none !important;
    font-size: 12px !important;
    font-weight: normal;
    text-align: center;
    height: 20px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${props => props.theme.colors.error80};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${props => props.theme.colors.main90} !important;
  }

  abbr {
    text-decoration: none !important;
  }

  .react-calendar__navigation {
    display: flex;
    color: green !important;
    height: 40px;
    margin-bottom: 0.5em;
  }

  .react-calendar__navigation__label {
    color: ${props => props.theme.colors.dark80};
    background: ${props => props.theme.colors.light100};
    font-size: 14px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    ${focusMixin};
  }

  background: ${props => props.theme.colors.light100};
  color: ${props => props.theme.colors.dark80};
  border: none !important;
`

export default mainStylesMixin
