import React, { useContext, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { Icon } from 'src/UIKit'
import {
  getCanBeShown,
  setCanBeShown
} from 'src/utils/EligibilityNoticeManager'
import createTheme from 'src/theme'
import useComponentRect from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import AccessibilityInvisibleText from 'src/components/AccessibilityInvisibleText'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  mobileMaxWidthMixin,
  timelineNoticeCloseButtonMixin,
  timelineNoticeContainerMixin,
  timelineNoticeTextMixin
} from 'src/theme/utils'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  note: string
}

const { colors } = createTheme()

const Container = styled.div<{
  $isClosing: boolean
  $isExpanded: boolean
  $contentHeight: number
  $top?: number
}>`
  ${timelineNoticeContainerMixin};

  ${props =>
    props.theme.isDesktop
      ? css`
          z-index: ${zIndex.timeline.footnote};
          max-width: 407px;
          border-radius: 8px;
        `
      : css`
          border-radius: 16px;
          margin: 16px auto 0;
          overflow: hidden;
          transition: all 300ms;
          height: auto;
          ${() => {
            if (props.$isClosing) {
              return css`
                max-height: 0;
                margin-top: 0;
                padding-top: 0;
                padding-bottom: 0;
                opacity: 0;
              `
            } else {
              return css`
                max-height: ${props.$isExpanded
                  ? `${props.$contentHeight + 14}px`
                  : `14px`};
              `
            }
          }}
        `}

  ${mobileMaxWidthMixin};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: bold;
        `}
`

const CloseButton = styled.button`
  ${timelineNoticeCloseButtonMixin};
`

const TextContainer = styled.div<{ $isExpanded: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  ${props =>
    props.theme.isMobile &&
    css`
      transition: opacity 300ms;
      opacity: ${props.$isExpanded ? '1' : '0'};
    `}
`

const ViewMoreText = styled.span<{ $isExpanded: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.main100};
  flex: 1;
  margin-right: 24px;
  text-align: right;
  transition: opacity 300ms;
  ${props =>
    props.$isExpanded &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const Text = styled(ReactMarkdown)`
  ${timelineNoticeTextMixin};

  p {
    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

const EligibilityNotice = React.memo((props: IProps) => {
  const { className, note } = props
  const { t } = useTranslation()
  const { isMobile, isDesktop, i18Context } = useContext(ScreenContext)
  const forceUpdate = useForceUpdate()
  const [isExpanded, setIsExpanded] = useState(isDesktop)
  const [isClosing, setIsClosing] = useState(false)
  const textContainerRef: any = useRef(null)
  const textContainerHeight: number = useComponentRect(textContainerRef).height

  if (!note || !getCanBeShown()) {
    return null
  }

  return (
    <Container
      className={className}
      $isExpanded={isExpanded}
      $isClosing={isClosing}
      $contentHeight={textContainerHeight}
      role={'region'}
      aria-label={t('eligibilityNotice.accessibility.region')}
    >
      <TitleContainer>
        <AccessibilityInvisibleText role={'alert'}>
          {t('eligibilityNotice.accessibility.title')}
        </AccessibilityInvisibleText>
        <Title aria-hidden>
          {t('eligibilityNotice.view.title', {
            context: i18Context
          })}
        </Title>
        {isMobile && (
          <ViewMoreText
            $isExpanded={isExpanded}
            onClick={() => {
              setIsExpanded(true)
            }}
          >
            {t('eligibilityNotice.view.viewMore')}
          </ViewMoreText>
        )}
        <CloseButton
          onClick={() => {
            setIsClosing(true)
            window.setTimeout(
              () => {
                setCanBeShown(false)
                forceUpdate()
              },
              isDesktop ? 0 : 300
            )
          }}
          aria-label={t('common.accessibilityText.close')}
        >
          <Icon
            name={'close_crossing'}
            settings={{
              fill: colors.main100
            }}
          />
        </CloseButton>
      </TitleContainer>
      <TextContainer ref={textContainerRef} $isExpanded={isExpanded}>
        <Text>{note}</Text>
      </TextContainer>
    </Container>
  )
})

EligibilityNotice.displayName = 'EligibilityNotice'

export default EligibilityNotice
