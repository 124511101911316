import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { ITimelinePeriod } from 'src/react-app-env'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import usePrevious from 'src/components/hooks/usePrevious'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { getChanges, getPeriodDuration, calculateDuration } from '../methods'
import { isBlankDate, areSameDates } from 'src/utils/dateUtils'
import { useResizeDetector } from 'react-resize-detector'
import InfoMessageView from '../components/InfoMessageView'
import { isCertificationRequired } from 'src/utils/leaveUtils'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { getPeriodKeyDate } from 'src/utils/periodUtils'
import {
  AlertsContainer,
  DateSelectors,
  StyledDatePicker
} from '../components/Datepickers'
import { ContentContainer } from '../components/ContentContainer'
import Buttons from '../components/Buttons'

interface IProps {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${$y - $containerHeight}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const Text = styled.span`
  font-size: 14px;
  line-height: 150%;
  white-space: pre-line;
  width: 100%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 0 24px;
        `}
`

const InfoMessageWrapper = styled.div`
  width: 100%;
  margin: 10px 16px;
`

const TitleWrapper = styled(Title)`
  margin: 0 16px 20px;
`

/**
 * Picker is represented by a single calendar which allows changing
 * a date defined by periodKeyDate configuration option.
 *
 * Sends period duration including changes for a whole timeline.
 */
const LeaveDateTimelinePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    dueDate,
    leaveStartDate,
    leave,
    onNewChanges,
    timelinePeriods,
    hasTimelineChanges,
    leaveHolidays,
    onCalendarActiveDateChanged,
    updateLeave
  } = context
  const { y, alignsToTop, bottomOffset, onExit } = props
  const period = props.period
  const { type } = period
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const [initialDate, setInitialDate] = useState<Moment>(null)
  const [initialTimelinePeriods] = useState(timelinePeriods)
  const hadTimelineChanges: boolean = usePrevious(hasTimelineChanges)

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const forceUpdate = useForceUpdate()

  const [activeStartDate, setActiveStartDate] = useState(undefined)
  const [activeEndDate, setActiveEndDate] = useState(undefined)

  useEffect(() => {
    setInitialDate(null)
  }, [type])

  useEffect(() => {
    if (!hasTimelineChanges && hadTimelineChanges) {
      setInitialDate(null)
    }
  }, [hasTimelineChanges, hadTimelineChanges])

  const alerts: string[] = Array.from(period.timelineConfig.alerts)

  if (
    period.type === 'PreDelivery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('preDeliverySickTimeCertificateRequired'))
  }

  if (
    period.type === 'Recovery' &&
    isCertificationRequired(leave, period.type)
  ) {
    alerts.push(t('postDeliveryDisabilityCertificateRequired'))
  }

  const canBeRemoved = useMemo(() => period.timelineConfig.removable, [period])
  const onRemove = useCallback(() => {
    onExit()
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: 0,
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    onNewChanges(changes, true)
  }, [
    timelinePeriods,
    leave,
    leaveStartDate,
    onNewChanges,
    onExit,
    period,
    dueDate
  ])

  const resetChanges = useCallback(() => {
    const changes = getChanges({
      timelinePeriods: initialTimelinePeriods,
      period,
      duration: getPeriodDuration(period),
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    onNewChanges(changes)
  }, [
    dueDate,
    initialTimelinePeriods,
    leave,
    leaveStartDate,
    period,
    onNewChanges
  ])

  const updatedPeriod = timelinePeriods.find(p => p.hash === props.period.hash)
  const momentCurrentMinMax =
    updatedPeriod[updatedPeriod.timelineConfig.periodKeyDate]

  const startDateMinMax = useMemo(
    () =>
      isBlankDate(updatedPeriod.periodStart.current)
        ? {
            ...updatedPeriod.periodStart,
            current: null
          }
        : updatedPeriod.periodStart,
    [updatedPeriod]
  )

  const endDateMinMax = useMemo(
    () =>
      isBlankDate(updatedPeriod.periodEnd.current)
        ? {
            ...updatedPeriod.periodEnd,
            current: null
          }
        : updatedPeriod.periodEnd,
    [updatedPeriod]
  )

  const onCancel = useCallback(() => {
    onExit()
    if (initialDate === null) {
      return
    }
    if (isBlankDate(initialDate) && canBeRemoved) {
      return onRemove()
    }
    resetChanges()
  }, [onExit, initialDate, canBeRemoved, onRemove, resetChanges])

  const onChange = useCallback(
    (date: Moment) => {
      if (initialDate && areSameDates(date, initialDate)) {
        return resetChanges()
      }
      const { periodKeyDate } = updatedPeriod.timelineConfig
      const changes: any = getChanges({
        timelinePeriods,
        period,
        duration: calculateDuration(updatedPeriod, periodKeyDate, date),
        dueDate: dueDate.current.toISOString(),
        startDate: leaveStartDate.current.toISOString(),
        leave
      })
      onNewChanges(changes)
    },
    [
      leave,
      onNewChanges,
      resetChanges,
      initialDate,
      updatedPeriod,
      dueDate,
      leaveStartDate,
      period,
      timelinePeriods
    ]
  )

  const onConfirm = useCallback(() => {
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: getPeriodDuration(updatedPeriod),
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    updateLeave(changes)
  }, [
    updateLeave,
    timelinePeriods,
    period,
    updatedPeriod,
    dueDate,
    leaveStartDate,
    leave
  ])

  const onDateChange = useCallback(
    (d: any) => {
      if (!initialDate) {
        setInitialDate(momentCurrentMinMax.current)
      }

      onChange(d)
    },
    [initialDate, onChange, momentCurrentMinMax]
  )

  const buttonsView: ReactNode = useMemo(() => {
    const hasChanges: boolean =
      !!initialDate && !momentCurrentMinMax.current.isSame(initialDate, 'day')
    const showRemoveButton =
      !hasChanges && canBeRemoved && period.appearance !== 'Addable'
    return (
      <Buttons
        hasChanges={hasChanges}
        isConfirmDisabled={false}
        onCancel={onCancel}
        onClose={onExit}
        onConfirm={() => {
          onExit()
          onConfirm()
        }}
        onRemove={showRemoveButton ? onRemove : null}
      />
    )
  }, [
    initialDate,
    momentCurrentMinMax,
    canBeRemoved,
    period.appearance,
    onCancel,
    onExit,
    onRemove,
    onConfirm
  ])

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [period, t])

  const title = useMemo(() => {
    const periodKeyDate = getPeriodKeyDate(period)
    return t('timeline.periodPicker.title.date', {
      periodName: period.timelineConfig.periodName,
      keyDate: periodKeyDate
    })
  }, [t, period])

  const startDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        holidays={leaveHolidays}
        disabled={getPeriodKeyDate(period) === 'end'}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.startDate')}
        momentCurrentMinMax={startDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onDateChange}
        activeStartDate={activeStartDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveStartDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      startDateMinMax,
      onDateChange,
      activeStartDate,
      onCalendarActiveDateChanged
    ]
  )

  const endDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        stickRight
        holidays={leaveHolidays}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.endDate')}
        disabled={getPeriodKeyDate(period) === 'start'}
        momentCurrentMinMax={endDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onDateChange}
        activeStartDate={activeEndDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveEndDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      endDateMinMax,
      onDateChange,
      activeEndDate,
      onCalendarActiveDateChanged
    ]
  )

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        {period.timelineConfig.datePickerDescription && (
          <Text>{period.timelineConfig.datePickerDescription}</Text>
        )}
        <DateSelectors>
          {startDatePicker}
          {endDatePicker}
        </DateSelectors>
        {infoMessageView}
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={bottomOffset} />
    </Container>
  )
})

LeaveDateTimelinePicker.displayName = 'LeaveDateTimelinePicker'

export default LeaveDateTimelinePicker
