import { QueryResult, useQuery } from '@apollo/client'
import { useState } from 'react'
import { queryAbsencesForUser } from 'src/graphql/queries'
import { convertJourneyMapDueDateToMoment } from 'src/utils/journeyMap'
import { sortAbsencesByStartDate } from 'src/utils/absences'

export interface IUseAbsences {
  loading: boolean
  error: any
  absences: IAbsence[]
}

const useAbsences = (
  skip = false,
  userID = null,
  archived = null
): IUseAbsences => {
  const [absences, setAbsences] = useState([])

  const returnValue: IUseAbsences = {
    loading: false,
    error: null,
    absences: []
  }

  const variables: any = {}
  if (userID) {
    variables.userID = userID
  }

  if (archived !== null) {
    variables.archived = archived
  }

  const absencesResult: QueryResult = useQuery(queryAbsencesForUser, {
    fetchPolicy: 'network-only',
    skip,
    variables
  })

  if (absencesResult.loading) {
    returnValue.loading = true
    return returnValue
  }

  if (absencesResult.error) {
    returnValue.error = absencesResult.error
    return returnValue
  }

  const aAll: IAbsence[] = absencesResult.data?.absences

  if (!!aAll && aAll !== absences) {
    sortAbsencesByStartDate(aAll)
    aAll.forEach((absence: IAbsence) => ({
      ...absence,
      journeyMap: convertJourneyMapDueDateToMoment(absence.journeyMap)
    }))

    setAbsences(aAll)
  }

  returnValue.absences = aAll

  return returnValue
}

export default useAbsences
