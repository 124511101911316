import { ApolloProvider } from '@apollo/client'
import React, {
  Suspense,
  useState,
  FC,
  ReactElement,
  useMemo,
  useEffect
} from 'react'
import Routes from 'src/routes'
import createClient from 'src/graphql'
import { csrf } from 'src/graphql/csrf'
import { authInfo } from 'src/graphql/authInfo'
import { ThemeProvider } from 'styled-components'
import './index.css'
import PageLoadingOverlay from 'src/components/PageLoadingOverlay'
import { ToastProvider } from 'src/components/ToastManager'
import { DialogProvider } from 'src/components/DialogManager'
import './i18n'
import GlobalStyle from 'src/theme/globalStyle'
import createTheme, { ITheme } from 'src/theme'
import { ServiceWorkerProvider } from 'src/components/hooks/useServiceWorker'
import ScreenContext from 'src/contexts/ScreenContext'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import { getCustomerConfig } from 'src/config/customers'
import useScreen, { IUseScreenResult } from './components/hooks/useScreen'
import { IHrAdminContext } from './features/HrAdmin/HrAdminContext'
import { getCustomer } from 'src/utils/userUtils'
import { errorPageRoute } from 'src/routes/constants'
import { setupNamespaces } from 'src/i18n'
import { infoUrl } from './config'
import { IBannerContext } from './components/InformationBanner/BannerContext'
import { captureResponseError } from './utils/sentry'

const theme: ITheme = createTheme()
const OVERLAY_FADEOUT_DURATION = 500
const client = createClient()

const fetchAuthInfo = () =>
  fetch(infoUrl())
    .then(response => {
      if (response.ok) {
        csrf.setToken(response.headers.get('X-CSRF-Token'))
        return response.json()
      }
      captureResponseError(response)
      throw Error('invalid response')
    })
    .then(data => {
      authInfo.setEnableOptOut(data.enableOptOut)
      authInfo.setEnableOtpLogin(data.enableOtpLogin)
      authInfo.setCustomerDisabled(data.customerDisabled)
      return data
    })
    .then(data => getCustomer(data))

const App: FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [infoLoaded, setInfoLoaded] = useState(false)
  const [showsOverlay, setShowsOverlay] = useState(true)
  const [pathname, setPathname] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [hrAdminContext, setHrAdminContext] = useState<IHrAdminContext>(null)
  const [bannerContext, setBannerContext] = useState<IBannerContext>({
    bannerHeight: 0
  })
  const useScreenResult: IUseScreenResult = useScreen()
  const sharedContextValue: ISharedContext = useMemo(
    () => ({
      bannerContext,
      setBannerContext,
      hrAdminContext,
      setHrAdminContext,
      apolloClient: client,
      customer,
      customerConfig: getCustomerConfig(customer)
    }),
    [hrAdminContext, setHrAdminContext, customer, bannerContext]
  )

  useEffect(() => {
    fetchAuthInfo()
      .then(customerName => {
        setCustomer(customerName)
        setupNamespaces(customerName, getCustomerConfig(customerName))
      })
      .catch(() => setPathname(errorPageRoute))
      .finally(() => setInfoLoaded(true))
  }, [])
  return (
    <ServiceWorkerProvider>
      <ScreenContext.Provider value={useScreenResult}>
        <SharedContext.Provider value={sharedContextValue}>
          <ThemeProvider theme={{ ...theme, ...useScreenResult }}>
            <GlobalStyle pathname={pathname} />
            <ToastProvider>
              <DialogProvider>
                <ApolloProvider client={client}>
                  <Suspense fallback={<div />}>
                    {infoLoaded && (
                      <Routes
                        client={client}
                        setPathname={setPathname}
                        setIsLoading={setIsLoading}
                        setCustomer={setCustomer}
                      />
                    )}
                    {showsOverlay && (
                      <PageLoadingOverlay
                        fadeOut={!isLoading}
                        fadeOutDuration={OVERLAY_FADEOUT_DURATION}
                        onAnimationComplete={() => {
                          setShowsOverlay(false)
                        }}
                      />
                    )}
                  </Suspense>
                </ApolloProvider>
              </DialogProvider>
            </ToastProvider>
          </ThemeProvider>
        </SharedContext.Provider>
      </ScreenContext.Provider>
    </ServiceWorkerProvider>
  )
}

export default App
