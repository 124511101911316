import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PregnancyChangeStepView } from '../PregnancyChangeStepView'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'

interface IProps {
  name: string
}

export const ConnectWithHRView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props

  const { onNextStage }: IPregnancyLossContext =
    useContext(PregnancyLossContext)

  return (
    <PregnancyChangeStepView
      name={name}
      onNextClicked={() => onNextStage()}
      isNextDisabled={false}
      nextButtonText={t('common.goToTimeline')}
    />
  )
})

ConnectWithHRView.displayName = 'ConnectWithHRView'

export default ConnectWithHRView
