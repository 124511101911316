import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import InfoMessageDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/InfoMessageDatePicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const leaveDescriptionKey = `${input.period.type}_${input.leave.type}`
  return {
    ...baseConfig,
    datePickerComponent:
      input.leave.type === 'Miscarriage'
        ? InfoMessageDatePicker
        : LeaveDatePeriodPicker,
    periodPickerInfoMessage: (t: any) =>
      t(`timeline.leaveDurationPicker.${leaveDescriptionKey}`),
    detailsType:
      input.leave.type === 'Miscarriage'
        ? leaveDescriptionKey
        : baseConfig.detailsType,
    // TODO: When the parameter sendTimelineToTPAEnabled is removed,
    // needs to remove this condition and the corresponding SendToTpaDisabled keys in the translation
    detailsHintPostfix:
      input.leave.metadata?.sendTimelineToTPAEnabled === false
        ? 'SendToTpaDisabled'
        : baseConfig.detailsHintPostfix
  }
}
