import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'

export type ContextMenuViewItemType = 'remove'

export interface IContextMenuViewItem {
  type: ContextMenuViewItemType
}

interface IProps {
  className?: string
  onClick: () => void
  error: boolean
  label: string
  ariaLabel: string
}

const ItemContainer = styled.li<{ $error: boolean }>`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  cursor: pointer;

  ${props => {
    const {
      $error,
      theme: { isDesktop, colors }
    } = props
    if (isDesktop) {
      return css`
        color: ${$error ? colors.error80 : colors.dark80};
        padding: 0 0 0 24px;
      `
    } else {
      return css`
        color: ${colors.dark80};
      `
    }
  }}

  &:hover {
    background: ${props => props.theme.colors.main100};
    color: ${props => props.theme.colors.light100};
  }

  &:focus {
    outline: none;
    background: ${props => props.theme.colors.main100};
    color: ${props => props.theme.colors.light100};
  }
`

const ItemLabel = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
`

const ControlMenuItem = React.memo((props: IProps) => {
  const { className, onClick, label, ariaLabel, error } = props
  const containerRef: any = useRef(null)

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 32 && event.target === containerRef.current) {
        event.preventDefault()
        onClick()
      }
    }
    window.addEventListener('keydown', onKeyDown)
  }, [onClick])

  useKeyActionHandler(containerRef.current, onClick)

  return (
    <ItemContainer
      className={className}
      onClick={onClick}
      tabIndex={0}
      aria-label={ariaLabel}
      role={'listitem'}
      ref={containerRef}
      $error={error}
    >
      <ItemLabel>{label}</ItemLabel>
    </ItemContainer>
  )
})

ControlMenuItem.displayName = 'ControlMenuItem'

export default ControlMenuItem
