import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Button, Checkbox } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  onShowEdit: () => void
  onCancel: () => void
  onChecked: (check: boolean) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isMobile &&
    css`
      height: 100%;
    `}
`

const BodyContent = styled(ReactMarkdown)`
  font-weight: 400;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: ${props => (props.theme.isDesktop ? '130%' : '150%')};

  ul {
    list-style: disc;
    padding-left: 2em;
    white-space: normal;
    margin-block-start: -20px;
    line-height: ${props => (props.theme.isDesktop ? '130%' : '150%')};

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 20px auto;
          width: auto;
        `
      : css`
          flex-direction: column;
          width: 100%;
        `}
`

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      justify-content: flex-end;
      width: 100%;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 200px;
          padding: 0 24px;

          &:not(:first-child) {
            margin-left: 16px;
          }
        `
      : css`
          margin: 8px;
          width: 100%;
          font-size: 14px;

          &:not(:first-child) {
            margin-bottom: 0;
          }
        `}
`

const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      margin-bottom: 10px;
    `}
`

const ConfirmationText = styled.span`
  margin: 0 0 0 16px;
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.dark50};
  cursor: pointer;
  user-select: none;
  align-self: center;
`

const VacationOverviewContent = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { onShowEdit, onCancel, onChecked } = props
  const { isMobile } = useContext(ScreenContext)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const onChange = useCallback(() => {
    setIsChecked(!isChecked)
    onChecked(isChecked)
  }, [isChecked, onChecked])

  const confirmationView: ReactNode = useMemo(
    () => (
      <ConfirmationContainer>
        <Checkbox
          name="checkbox"
          checked={isChecked}
          onChange={onChange}
          ariaLabel={t('vacationBalance.overview.checkBoxText')}
        />
        <ConfirmationText onClick={onChange} aria-hidden>
          {t('vacationBalance.overview.checkBoxText')}
        </ConfirmationText>
      </ConfirmationContainer>
    ),
    [isChecked, onChange, t]
  )

  return (
    <Container>
      <BodyContent>{t('vacationBalance.overview.body')}</BodyContent>
      {!isMobile && confirmationView}
      <ControlContainer>
        {isMobile && confirmationView}
        <ButtonsContainer>
          <ButtonWrapper onClick={onCancel} appearance={'cancel'}>
            {t('vacationBalance.overview.close')}
          </ButtonWrapper>
          <ButtonWrapper onClick={onShowEdit}>
            {t('vacationBalance.overview.takeCalculator')}
          </ButtonWrapper>
        </ButtonsContainer>
      </ControlContainer>
    </Container>
  )
})

VacationOverviewContent.displayName = 'VacationOverviewContent'

export default VacationOverviewContent
