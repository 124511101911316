import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import Buttons from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Buttons'
import useDate, {
  IUseDate
} from 'src/features/Timeline/components/vertical/LeaveDurationPickers/hooks/useDate'
import {
  DateSelectors,
  StyledDatePicker
} from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Datepickers'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import DescriptionText from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { ITimelinePeriod } from 'src/react-app-env'
import * as Methods from './methods'
import { getChanges, getDaysBetweenDates } from '../methods'
import InfoMessageView from '../components/InfoMessageView'
import {
  isPreOrdinaryMaternity,
  isPostOrdinaryMaternity
} from 'src/utils/leaveUtils'
import cloneDeep from 'lodash.clonedeep'
import { ContentContainer } from '../components/ContentContainer'
import SelectedMessage from '../components/SelectedMessage'

interface IProps {
  className?: string
  y?: number
  alignsToTop?: boolean
  onExit: () => void
  period: ITimelinePeriod
}

const InfoMessageWrapper = styled.div`
  margin: 16px 20px 0;
  width: 100%;
`

const TitleWrapper = styled(Title)`
  margin-bottom: 20px;
`

const OrdinaryMaternityPicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const { onExit } = props
  const { t } = useTranslation()
  const {
    leave,
    dueDate,
    onNewChanges,
    timelinePeriods,
    leaveHolidays,
    onCalendarActiveDateChanged
  } = context

  const preOrdinaryPeriod = timelinePeriods.find(isPreOrdinaryMaternity)
  const postOrdinaryPeriod = timelinePeriods.find(isPostOrdinaryMaternity)

  const isUsedOnePeriod = useMemo(
    () => !postOrdinaryPeriod,
    [postOrdinaryPeriod]
  )

  const period = isUsedOnePeriod ? preOrdinaryPeriod : postOrdinaryPeriod
  const startDate: IUseDate = useDate(preOrdinaryPeriod.periodStart)
  const endDate: IUseDate = useDate(period.periodEnd)
  const [activeStartDate, setActiveStartDate] = useState(undefined)
  const [activeEndDate, setActiveEndDate] = useState(undefined)

  const [infoMessage, setInfoMessage] = useState<string>(null)
  const [initialTimelinePeriods] = useState(cloneDeep(timelinePeriods))

  const startDateRef = useRef(null)
  const endDateRef = useRef(null)

  const resetChanges = useCallback(() => {
    const changes = getChanges({
      timelinePeriods: initialTimelinePeriods,
      period: null,
      duration: 0,
      dueDate: dueDate.current.toISOString(),
      startDate: null,
      leave
    })
    onNewChanges(changes)
  }, [dueDate, initialTimelinePeriods, leave, onNewChanges])

  const onStartDateChanged = useCallback(
    (date: Moment): void => {
      startDate.setCurrent(date)
      const duration = getDaysBetweenDates(dueDate.current, date)
      Methods.expandPostOrdinaryPeriod(
        preOrdinaryPeriod,
        duration,
        timelinePeriods
      )
      setActiveEndDate(
        isUsedOnePeriod
          ? preOrdinaryPeriod.periodEnd.current.toDate()
          : postOrdinaryPeriod.periodEnd.current.toDate()
      )
      setInfoMessage(
        Methods.infoMessage(
          preOrdinaryPeriod,
          duration,
          initialTimelinePeriods,
          t
        )
      )

      const changes: any = getChanges({
        timelinePeriods,
        period: preOrdinaryPeriod,
        duration,
        dueDate: dueDate.current.toISOString(),
        startDate: null,
        leave
      })
      onNewChanges(changes)
    },
    [
      t,
      leave,
      initialTimelinePeriods,
      timelinePeriods,
      startDate,
      preOrdinaryPeriod,
      postOrdinaryPeriod,
      onNewChanges,
      dueDate,
      isUsedOnePeriod
    ]
  )
  const onEndDateChanged = useCallback(
    (date: Moment): void => {
      endDate.setCurrent(date)
      const duration = getDaysBetweenDates(date, dueDate.current) + 1
      Methods.expandPreOrdinaryPeriod(
        isUsedOnePeriod ? preOrdinaryPeriod : postOrdinaryPeriod,
        duration,
        timelinePeriods
      )
      setActiveStartDate(preOrdinaryPeriod.periodStart.current.toDate())
      setInfoMessage(null)

      const changes: any = getChanges({
        timelinePeriods,
        period: isUsedOnePeriod ? preOrdinaryPeriod : postOrdinaryPeriod,
        duration,
        dueDate: dueDate.current.toISOString(),
        startDate: null,
        leave
      })
      onNewChanges(changes)
    },
    [
      leave,
      timelinePeriods,
      endDate,
      postOrdinaryPeriod,
      preOrdinaryPeriod,
      onNewChanges,
      dueDate,
      isUsedOnePeriod
    ]
  )

  const startDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        handleBottomOverlap
        disableWeekends
        holidays={leaveHolidays}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
          return endDateRef.current.isOpen && endDateRef.current.toggle()
        }}
        calendarRef={startDateRef}
        title={t('timeline.periodPicker.startDate')}
        momentCurrentMinMax={preOrdinaryPeriod.periodStart}
        placeholder={t('common.selectDate')}
        onDateChanged={onStartDateChanged}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveStartDate(action.activeStartDate)
        }}
        disabled={isUsedOnePeriod}
      />
    ),
    [
      t,
      leaveHolidays,
      onCalendarActiveDateChanged,
      preOrdinaryPeriod,
      activeStartDate,
      setActiveStartDate,
      onStartDateChanged,
      startDateRef,
      endDateRef,
      isUsedOnePeriod
    ]
  )

  const endDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        handleBottomOverlap
        stickRight
        holidays={leaveHolidays}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
          return startDateRef.current.isOpen && startDateRef.current.toggle()
        }}
        calendarRef={endDateRef}
        title={t('timeline.periodPicker.endDate')}
        momentCurrentMinMax={
          isUsedOnePeriod
            ? preOrdinaryPeriod.periodEnd
            : postOrdinaryPeriod.periodEnd
        }
        placeholder={t('common.selectDate')}
        onDateChanged={onEndDateChanged}
        activeStartDate={activeEndDate}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveEndDate(action.activeStartDate)
        }}
      />
    ),
    [
      t,
      leaveHolidays,
      onCalendarActiveDateChanged,
      activeEndDate,
      setActiveEndDate,
      postOrdinaryPeriod,
      onEndDateChanged,
      startDateRef,
      endDateRef,
      preOrdinaryPeriod,
      isUsedOnePeriod
    ]
  )

  const isConfirmDisabled: boolean = useMemo(
    () => Methods.isConfirmDisabled({ endDate }),
    [endDate]
  )

  const isNothingChanged: boolean = useMemo(
    () => Methods.isNothingChanged({ startDate, endDate }),
    [startDate, endDate]
  )

  const onCancelClicked = useCallback(() => {
    onExit()
    resetChanges()
  }, [onExit, resetChanges])

  const onConfirm = useCallback(() => {
    onExit()
    const changes: any = getChanges({
      timelinePeriods,
      period: null,
      startDate: null,
      duration: 0,
      dueDate: dueDate.current.toISOString(),
      leave
    })
    onNewChanges(changes, true)
  }, [onExit, timelinePeriods, dueDate, leave, onNewChanges])

  const infoMessageView: ReactNode = useMemo(
    () =>
      infoMessage && (
        <InfoMessageWrapper>
          <InfoMessageView message={infoMessage} />
        </InfoMessageWrapper>
      ),
    [infoMessage]
  )

  const selectedPeriodView: ReactNode = useMemo(() => {
    if (!period?.timelineConfig?.periodPickerSelectedNotice.show) {
      return null
    }

    const usedDuration = Methods.getUsedDuration(timelinePeriods)
    const unusedDuration = Methods.getUnusedDuration(timelinePeriods)

    return (
      <SelectedMessage
        used={usedDuration}
        unused={unusedDuration}
        showHumanReadableDuration
      />
    )
  }, [period, timelinePeriods])

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={!isNothingChanged}
        isConfirmDisabled={isConfirmDisabled}
        onCancel={onCancelClicked}
        onClose={onExit}
        onConfirm={onConfirm}
      />
    ),
    [isNothingChanged, isConfirmDisabled, onCancelClicked, onExit, onConfirm]
  )

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.dates', {
        periodName: period?.timelineConfig?.periodName
      }),
    [t, period]
  )

  return (
    <ContainerView {...props}>
      <ContentContainer>
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionText>
          {preOrdinaryPeriod?.timelineConfig?.datePickerDescription}
        </DescriptionText>
        <DateSelectors>
          {startDatePicker}
          {endDatePicker}
        </DateSelectors>
        {selectedPeriodView}
        {infoMessageView}
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

OrdinaryMaternityPicker.displayName = 'OrdinaryMaternityPicker'

export default OrdinaryMaternityPicker
