import React, { useState, useMemo, useEffect } from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import withQueries from 'src/components/HOC/withQueries'
import { createLeaveButtons } from 'src/constants/frame'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { authInfo } from 'src/graphql/authInfo'
import {
  errorPageRoute,
  timelineRoute,
  userConsentRoute,
  welcomeRoute
} from 'src/routes/constants'
import { IWithQueriesProps } from 'src/react-app-env'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const TextWrapper = styled(MarkdownText)`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 24px;
  white-space: pre-wrap;
  letter-spacing: 0.15px;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    padding-left: 2em;
    white-space: normal;
    margin-block-start: -20px;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  em {
    font-style: italic;
  }
`

const CollectButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 40px 0 24px;
          width: 275px;
        `
      : css`
          flex: 1;
          justify-content: flex-end;
          width: 100%;
          margin: 16px 0;
        `}

  ${mobileButtonMaxWidthMixin};
`

const CollectButtonWrapper = styled(Button)<any>`
  font-size: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: ${props.appearance === 'unbordered' ? '275px' : '256px'};
        `
      : css`
          width: 100%;
          height: 48px;
        `}
  &:not(:first-child) {
    margin-top: 16px;
  }
`

const DeleteButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 40px 0 24px;
          width: calc(${createLeaveButtons.width().px} + 32px);
        `
      : css`
          flex: 1;
          gap: 16px;
          align-items: flex-end;
          width: 100%;
          margin: 0 0 16px;
        `}

  ${mobileButtonMaxWidthMixin};
`

const DeleteButtonWrapper = styled(Button)`
  font-size: 16px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          flex: 1;
          height: 48px;
        `}
`

export const dataAttrs = {
  title: () => 'close-account-title',
  goToCloseAccountBtn: () => 'click-go-to-close-account-btn',
  backBtn: () => 'close-account-back-btn'
}

enum CloseAccountStage {
  Collect = 'collect',
  Delete = 'delete'
}

interface IProps extends IWithQueriesProps {}

export const CloseAccountPage = React.memo((props: IProps) => {
  const { queries } = props
  const navigate = useNavigate()

  if (!authInfo.getEnableOptOut()) {
    navigate(timelineRoute)
  }
  const { t } = useTranslation()
  const [stage, setStage] = useState<CloseAccountStage>()
  const [user, setUser] = useState<IUser>()
  const fetchMe = async () => {
    try {
      const userResult = await queries.fetchMe({
        unavailableForLegalReasons: () => setStage(CloseAccountStage.Collect)
      })
      if (userResult) {
        setUser(userResult)
        setStage(CloseAccountStage.Delete)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute)
      }
    }
  }
  useEffect(() => {
    if (authInfo.getEnableOptOut()) {
      fetchMe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePageTitle('closeAccount')

  const title = useMemo(
    () => (
      <H1 id={'title'} data-testid={dataAttrs.title()}>
        {t(`closeAccountPage.${stage}.title`)}
      </H1>
    ),
    [t, stage]
  )

  const body = useMemo(
    () => (
      <TextWrapper
        urlTransform={TelUrlTransformer}
        allowsParagraph
        components={{ a: LinkRenderer }}
      >
        {t(`closeAccountPage.${stage}.body`)}
      </TextWrapper>
    ),
    [t, stage]
  )

  const collectButtons = useMemo(
    () => (
      <CollectButtonsContainer>
        <CollectButtonWrapper onClick={() => navigate(userConsentRoute)}>
          {t('closeAccountPage.collect.backToPrivacyAndTerms')}
        </CollectButtonWrapper>
        <CollectButtonWrapper
          data-testid={dataAttrs.goToCloseAccountBtn()}
          onClick={() => setStage(CloseAccountStage.Delete)}
          appearance={'unbordered'}
        >
          {t('closeAccountPage.collect.goToCloseAccount')}
        </CollectButtonWrapper>
      </CollectButtonsContainer>
    ),
    [t, navigate]
  )

  const deleteButtons = useMemo(
    () =>
      user ? (
        <DeleteButtonsContainer>
          <DeleteButtonWrapper
            onClick={() => navigate(-1)}
            appearance={'cancel'}
          >
            {t('common.back')}
          </DeleteButtonWrapper>
        </DeleteButtonsContainer>
      ) : (
        <DeleteButtonsContainer>
          <DeleteButtonWrapper
            data-testid={dataAttrs.backBtn()}
            onClick={() => setStage(CloseAccountStage.Collect)}
            appearance={'cancel'}
          >
            {t('common.back')}
          </DeleteButtonWrapper>
          <DeleteButtonWrapper onClick={() => navigate(welcomeRoute)}>
            {t('closeAccountPage.delete.signOutVeer')}
          </DeleteButtonWrapper>
        </DeleteButtonsContainer>
      ),
    [t, navigate, user]
  )

  if (!stage) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      {title}
      {body}
      {stage === CloseAccountStage.Collect ? collectButtons : deleteButtons}
    </Container>
  )
})

CloseAccountPage.displayName = 'CloseAccountPage'

export default withRouter(withQueries(CloseAccountPage))
