import React, { useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ClaimBlock from 'src/features/UserProfile/components/ClaimData/components/ClaimBlock'
import MarkdownText from 'src/UIKit/MarkdownText'
import { Moment } from 'moment'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

interface IProps {
  className?: string
  syncing: boolean
  changedAt: Moment
  toggleTpaSyncing: () => void
  showLastUpdate: boolean
  leave: ILeave
}

const appearingKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${appearingKeyframes} 300ms linear forwards;
`
const Title = styled(MarkdownText)`
  margin: 0;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin: 32px 0;
        `
      : css`
          font-size: 14px;
          margin: 0 0 24px;
        `}

  &:first-child {
    margin-top: 0;
  }

  blockquote {
    white-space: normal;
    background-color: ${props => props.theme.colors.main05};
    border-left: 4px solid ${props => props.theme.colors.main90};
    padding: 16px;
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ol {
    list-style: auto;
    padding-left: 24px;
    white-space: normal;
    margin-block-start: -20px;
  }
`

export const dataAttrs = {
  description: () => 'claim-data-description'
}

const ClaimData = React.memo((props: IProps) => {
  const {
    className,
    syncing,
    changedAt,
    toggleTpaSyncing,
    showLastUpdate,
    leave
  } = props
  const { t } = useTranslation()

  const claimDataText = useMemo(() => {
    // TODO: When the parameter sendTimelineToTPAEnabled is removed,
    // needs to remove this condition and the corresponding _sendToTpaDisabled keys in the translation
    if (leave?.metadata?.sendTimelineToTPAEnabled === false) {
      return t([
        `claimData.text_${leave?.type}_sendToTpaDisabled`,
        'claimData.text_sendToTpaDisabled'
      ])
    }
    return t([`claimData.text_${leave?.type}`, 'claimData.text'])
  }, [leave, t])

  return (
    <Container className={className}>
      <Title
        data-testid={dataAttrs.description()}
        allowsParagraph
        components={{ a: LinkRenderer }}
        urlTransform={TelUrlTransformer}
      >
        {claimDataText}
      </Title>

      <ClaimBlock
        syncing={syncing}
        changedAt={changedAt}
        toggleTpaSyncing={toggleTpaSyncing}
        showLastUpdate={showLastUpdate}
      />
    </Container>
  )
})

ClaimData.displayName = 'ClaimData'

export default ClaimData
