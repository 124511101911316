import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { ISurveyResultsResponse } from 'src/graphql/hooks/useSurveySummaries'
import useChart from 'src/features/HrAdmin/hooks/useChart'
import SurveyMultipleChoiceChart from 'src/features/HrAdmin/components/charts/SurveyMultipleChoiceChart'

interface IProps {
  data: ISurveyResultsResponse[]
  isDesktop: boolean
}

const Container = styled.div`
  flex: 1;
`

const ChartContainer = styled.div<{ $height: number }>`
  height: ${props => props.$height}px;
`

export const SurveyMultipleChoiceResults = React.memo((props: IProps) => {
  const { data, isDesktop } = props
  const chartContainerRef: any = useRef(null)
  const isHorizontal: boolean = useMemo(() => {
    if (isDesktop) {
      return false
    }

    for (const obj of data) {
      if (!isNaN(+obj.label)) {
        return false
      }
    }

    return true
  }, [data, isDesktop])

  useChart(chartContainerRef, SurveyMultipleChoiceChart, {
    data,
    isHorizontal,
    isDesktop,
    isMobile: !isDesktop
  })

  return (
    <Container>
      <ChartContainer
        ref={chartContainerRef}
        $height={isHorizontal ? 236 : 212}
      />
    </Container>
  )
})

SurveyMultipleChoiceResults.displayName = 'SurveyMultipleChoiceResults'

export default SurveyMultipleChoiceResults
