import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import LeftSidebar from 'src/features/HrAdmin/components/LeftSidebar'
import ContentArea from 'src/features/HrAdmin/components/ContentArea'
import ScreenContext from 'src/contexts/ScreenContext'

const Container = styled.div`
  display: flex;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100vw;
        `
      : css`
          width: 100%;
        `}
`

const HrAdminPage = () => {
  const { isDesktop } = useContext(ScreenContext)
  return (
    <Container>
      {isDesktop && <LeftSidebar />}
      <ContentArea />
    </Container>
  )
}

export default HrAdminPage
