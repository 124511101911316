import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  text: string
  value: string
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 24px 0 21px;
        `
      : css`
          padding: 0 22px 0 21px;
        `}
`

const Text = styled.span`
  margin-left: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const Value = styled.span`
  margin-left: 11px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  white-space: nowrap;
  color: ${props => props.theme.colors.dark60};
`

export const LeaveStatRow = React.memo((props: IProps) => {
  const { className, text, value } = props
  const { t } = useTranslation()

  const ariaLabel: string = useMemo(
    () => t('common.accessibilityText.leaveStatRow'),
    [t]
  )

  return (
    <Container aria-label={ariaLabel} className={className}>
      <Text aria-hidden>{text}</Text>
      <Value aria-hidden>{value}</Value>
    </Container>
  )
})

LeaveStatRow.displayName = 'LeaveStatRow'

export default LeaveStatRow
