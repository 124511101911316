import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  disabled: boolean
  onClick: (index: number) => void
  index: number
  children: any
}

export const Container = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 0;

  ${props =>
    props.$disabled
      ? css`
          cursor: unset;
        `
      : css`
          cursor: pointer;
        `}
`

const FilterRowContainer = React.memo(
  (props: IProps) => {
    const { className, onClick, index, disabled, children } = props
    return (
      <Container
        className={className}
        $disabled={disabled}
        onClick={() => {
          if (disabled) {
            return
          }
          onClick(index)
        }}
      >
        {children}
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.onClick === nextProps.onClick &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.index === nextProps.index &&
    prevProps.children === nextProps.children
)

FilterRowContainer.displayName = 'FilterRowContainer'

export default FilterRowContainer
