import React, { useMemo } from 'react'
import Accordion from 'src/components/Accordion'
import CountrySelector from 'src/components/CountrySelector'
import { IHrAdminContext } from 'src/features/HrAdmin/HrAdminContext'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'
import styled from 'styled-components'

interface IProps {
  hrAdminContext: IHrAdminContext
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 16px;
`

const SurveysAccordion = styled(Accordion)`
  margin: 16px 0 0;
`

export const HrAdminMenuContent = React.memo((props: IProps) => {
  const { hrAdminContext } = props

  const {
    insights,
    surveys,
    lastClickedRootItem,
    countryCodes,
    selectedCountryCode,
    setSelectedCountryCode
  } = hrAdminContext || {}

  const showCountryDropdown = useMemo(
    () => countryCodes?.length > 1,
    [countryCodes]
  )

  const showSurveys = useMemo(
    () => selectedCountryCode !== GLOBAL_COUNTRY,
    [selectedCountryCode]
  )

  if (!hrAdminContext) {
    return null
  }

  return (
    <Container>
      {showCountryDropdown && (
        <CountrySelector
          countryCodes={countryCodes}
          selectedCountryCode={selectedCountryCode}
          setSelectedCountryCode={setSelectedCountryCode}
        />
      )}
      <Accordion {...insights} expanded={lastClickedRootItem === 'insights'} />
      {showSurveys && (
        <SurveysAccordion
          {...surveys}
          expanded={lastClickedRootItem === 'surveys'}
        />
      )}
    </Container>
  )
})

HrAdminMenuContent.displayName = 'HrAdminMenuContent'

export default HrAdminMenuContent
