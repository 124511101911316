import React, { useContext, useMemo } from 'react'
import { Dropdown } from 'src/UIKit'
import styled from 'styled-components'
import LeaveContext from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  title?: string
}

const Container = styled.div`
  width: 100%;
`

export const TypeSelector = React.memo((props: IProps) => {
  const { className, title } = props
  const { t } = useTranslation()
  const { leaveTypes, selectedLeaveType, onTypeChanged } =
    useContext(LeaveContext)

  const options = useMemo(
    () =>
      leaveTypes
        ? leaveTypes
            .filter(item => !item.hidden)
            .map((item: ILeaveTypeInfo) => ({
              value: item.type,
              text: t(`leaveTypeSelectorOptions.${item.type}`)
            }))
        : [],
    [leaveTypes, t]
  )

  const disabledOptionIndexes: number[] = useMemo(() => {
    const result: number[] = []

    if (!leaveTypes) {
      return result
    }

    leaveTypes.forEach((item: ILeaveTypeInfo, index: number) => {
      if (!item.enabled) {
        result.push(index)
      }
    })

    return result
  }, [leaveTypes])

  return (
    <Container className={className}>
      <Dropdown
        displayBlock
        name="type"
        title={title || t('createLeave.inputTitle')}
        options={options}
        selected={
          selectedLeaveType
            ? options.find(
                (option: any) => option.value === selectedLeaveType.type
              )
            : null
        }
        placeholder={t(`createLeave.inputPlaceholder`)}
        onChange={option => {
          const type = leaveTypes.find((l: any) => l.type === option.value)
          onTypeChanged(type)
        }}
        disabledOptionIndexes={disabledOptionIndexes}
      />
    </Container>
  )
})

TypeSelector.displayName = 'TypeSelector'

export default TypeSelector
