import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  selected: boolean
  hovered?: boolean
  ariaLabel?: string
}

const Container = styled.button<{ $hovered: boolean }>`
  position: relative;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${props => props.theme.colors.light100};
  box-sizing: border-box;
  cursor: pointer;

  ${props =>
    props.$hovered
      ? css`
          border: 1px solid ${props.theme.colors.main100};
        `
      : css`
          border: 1px solid ${props.theme.colors.main90};
        `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
  }
`

const Circle = styled.div<{ $hovered: boolean }>`
  position: absolute;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 1;

  ${props =>
    props.$hovered
      ? css`
          background: ${props.theme.colors.main100};
        `
      : css`
          background: ${props.theme.colors.main90};
        `}
`

const RadioButton = React.memo((props: IProps) => {
  const { className, selected, hovered, ariaLabel } = props

  return (
    <Container
      className={className}
      $hovered={hovered}
      role={'radio'}
      aria-checked={selected}
      aria-label={ariaLabel}
    >
      {selected && <Circle $hovered={hovered} />}
    </Container>
  )
})

RadioButton.displayName = 'RadioButton'

export default RadioButton
