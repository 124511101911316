import { ITimelinePeriodConfig } from '../../../index'
import PeriodKeyDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/PeriodKeyDatePicker'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  datePickerComponent: PeriodKeyDatePicker,
  timeViewProps: {
    ...baseConfig.timeViewProps
  },
  periodPickerSelectedNotice: {
    show: true
  }
})
