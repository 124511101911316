import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import { ResponseStrings } from 'src/components/Surveys/SurveyContainer'
import RadioChoiceButton from 'src/UIKit/RadioChoiceButton'

interface IProps {
  responses: string[]
  selectedResponse: ResponseStrings
  onAnswer: (response: ResponseStrings) => void
}

const Container = styled.div<{ $isMobileLandscape: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => {
    if (props.$isMobileLandscape || props.theme.isDesktop) {
      return css`
        max-width: 528px;
      `
    } else {
      return css`
        width: 100%;
      `
    }
  }}
`

export const ResponseView = styled(RadioChoiceButton)`
  width: 256px;
  margin: 4px 0;

  ${props =>
    props.theme.isMobile &&
    css`
      min-height: 40px;
    `}
`

export const dataAttrs = {
  component: () => 'answer-type-multiple-choice'
}

export const AnswerTypeMultipleChoice = React.memo((props: IProps) => {
  const { responses, onAnswer, selectedResponse } = props
  const { t } = useTranslation()
  const { isMobileLandscape } = useContext(ScreenContext)

  const responsesView: any = responses.map((response: string) => (
    <ResponseView
      appearance={'cancel'}
      key={response}
      onClick={() => {
        onAnswer(response)
      }}
      selected={response === selectedResponse}
    >
      {response}
    </ResponseView>
  ))

  return (
    <Container
      data-testid={dataAttrs.component()}
      $isMobileLandscape={isMobileLandscape}
      role={'region'}
      aria-label={t('common.accessibilityText.options')}
    >
      {responsesView}
    </Container>
  )
})

AnswerTypeMultipleChoice.displayName = 'AnswerTypeMultipleChoice'

export default AnswerTypeMultipleChoice
