import {
  mutationUpdateUser,
  queryLeaveMetadata,
  queryMe
} from 'src/graphql/queries'
import {
  welcomeRoute,
  unsupportedPageRoute,
  thanksPageRoute,
  outdatedPlanRoute,
  loginErrorPageRoute,
  closeAccountRoute,
  infoPageRoute
} from 'src/routes/constants'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import QueryExecutor from 'src/graphql/QueryExecutor'
import { isPathnameLikeOneOf } from 'src/utils/routeUtils'
import { parseAction, runAnAction } from 'src/utils/deeplinkManager'
import get from 'lodash.get'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import { UserRole } from '../react-app-env'
import { getIsEmployee } from './userUtils'
import { NavigateFunction, Location } from 'react-router-dom'
import { setupTranslation } from 'src/i18n'
import { ICustomerConfig } from 'src/config/customers/config'

const exceptionRoutes: string[] = [
  closeAccountRoute,
  unsupportedPageRoute,
  thanksPageRoute,
  loginErrorPageRoute
]

export default (
  client: ApolloClient<NormalizedCacheObject>,
  navigate: NavigateFunction,
  location: Location,
  onDone: () => void,
  customerConfig: ICustomerConfig
) => {
  const goToWelcome = () => {
    navigate(welcomeRoute)
    onDone()
  }

  const updateUserTimezoneIfNeeded = async (user: IUser) => {
    if (!user) {
      return
    }
    const { timezone } = user
    const currentTimezone: string =
      Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!timezone || timezone.length === 0 || timezone !== currentTimezone) {
      QueryExecutor(async () => {
        const result: any = await client.mutate({
          mutation: mutationUpdateUser,
          variables: { changes: { timezone: currentTimezone } }
        })
        const newUser: IUser = get(result, 'data.updateUser')
        client.writeQuery({
          query: queryMe,
          data: { me: newUser }
        })
      }, navigate)
    }
  }

  const fetchUser = () => {
    QueryExecutor(
      async () => {
        const userResult: any = await client.query({
          query: queryMe,
          fetchPolicy: 'cache-first'
        })
        const user: IUser = get(userResult, 'data.me')

        if (user) {
          setupTranslation(user)
        }

        updateUserTimezoneIfNeeded(user)

        if (getIsEmployee(user.roles as UserRole[])) {
          fetchLeave()
        }

        if (!runAnAction(navigate)) {
          if (customerConfig?.showInfoPage) {
            navigate(infoPageRoute)
          } else {
            await redirectByUserRole(user, navigate, client)
          }
        }
        onDone()
      },
      navigate,
      {
        unauthorized: goToWelcome,
        unhandled: () => {
          onDone()
        },
        notFound: goToWelcome
      }
    )
  }

  const fetchLeave = () => {
    QueryExecutor(
      async () => {
        const leaveResult: any = await client.query({
          query: queryLeaveMetadata
        })
        const leave: ILeave = get(leaveResult, 'data.leave', null)
        if (leave) {
          if (leave.metadata.outdated) {
            navigate(outdatedPlanRoute)
          }
        }

        onDone()
      },
      navigate,
      {
        unauthorized: goToWelcome,
        unhandled: () => {
          onDone()
        }
      }
    )
  }

  const start = async () => {
    const { pathname } = location
    if (isPathnameLikeOneOf(pathname, exceptionRoutes, true)) {
      onDone()
      return
    }
    fetchUser()
  }

  parseAction(location)
  start()
}
