import styled, { css } from 'styled-components'

const OptionContainer = styled.ul`
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`

OptionContainer.displayName = 'OptionContainer'

export default OptionContainer
