import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon, MarkdownText } from 'src/UIKit'
import useComponentRect from 'src/components/hooks/useComponentRect'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'

interface IProps {
  title: string
  body: string
  opened: boolean
  onClick: () => void
}

const Container = styled.li`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.main10};

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 16px 12px;
        `
      : css`
          padding: 16px;
        `}

  &:last-child {
    border-bottom: none;
  }

  &:focus {
    outline: none;
    background: ${props => props.theme.colors.main05};
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span<{ $hovered: boolean }>`
  transition: color 300ms;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${props =>
    props.$hovered ? props.theme.colors.dark100 : props.theme.colors.dark80};
`

const BodyContainer = styled.div<{ $height: number; $opened: boolean }>`
  transition: all 300ms;
  overflow: hidden;
  ${props => {
    const { $height, $opened } = props
    const margin: string = $opened ? '12px 0 0' : '0'
    const opacity: string = $opened ? '1' : '0'
    return css`
      height: ${$opened ? $height : 0}px;
      visibility: ${$opened ? 'visible' : 'hidden'};
      margin: ${margin};
      opacity: ${opacity};
    `
  }}
`

const Body = styled(MarkdownText)<any>`
  line-height: 143%;
  color: ${props => props.theme.colors.dark60};
  font-size: 14px;
  white-space: pre-wrap;

  ul {
    list-style: inside;
    white-space: normal;
  }

  blockquote {
    margin-left: 24px;
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
      outline-offset: 1px;
    }
  }

  i {
    font-style: italic;
  }
`

const IconWrapper = styled(Icon)<any>`
  transition: transform 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateZ(${props => (props.opened ? '180deg' : '0deg')});
`

const PeriodHintView = React.memo((props: IProps) => {
  const { title, body, opened, onClick } = props
  const bodyRef = useRef(null)
  const containerRef = useRef(null)
  const [hovered, setHovered] = useState(false)
  const { height } = useComponentRect(bodyRef)

  useKeyActionHandler(containerRef.current, onClick)

  return (
    <Container
      onClick={onClick}
      onMouseOver={() => {
        setHovered(true)
      }}
      onMouseOut={() => {
        setHovered(false)
      }}
      aria-label={title}
      ref={containerRef}
      tabIndex={0}
      aria-haspopup
      aria-expanded={opened}
    >
      <TitleContainer>
        <Title aria-hidden $hovered={hovered}>
          {title}
        </Title>
        <IconWrapper opened={opened} name={opened ? 'faq_minus' : 'faq_plus'} />
      </TitleContainer>
      <BodyContainer
        $height={height}
        $opened={opened}
        role={opened ? 'alert' : null}
      >
        <div ref={bodyRef}>
          <Body
            allowsParagraph
            components={{
              a: (linkProps: any) => {
                const target: string =
                  linkProps.href.indexOf('tel:') === 0 ? '_self' : '_blank'
                return (
                  <a
                    href={linkProps.href}
                    target={target}
                    rel="noopener noreferrer"
                    onClick={(event: any) => event.stopPropagation()}
                  >
                    {linkProps.children}
                  </a>
                )
              },
              em: 'i'
            }}
          >
            {body}
          </Body>
        </div>
      </BodyContainer>
    </Container>
  )
})

PeriodHintView.displayName = 'PeriodHintView'

export default PeriodHintView
