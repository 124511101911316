import React, { Fragment, Ref, useContext, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { isIOS } from 'src/utils/deviceTypes'
import {
  getPeriodDurationTextComponents,
  IDuration,
  IDurationTextComponent
} from 'src/utils/leaveUtils'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import Tooltip from 'src/components/Tooltip'

const { colors } = createTheme()

interface IProps {
  className?: string
  title: string
  value: number
  isUnapproved?: boolean
}

const Container = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const textMixin = css<{ $overrideColor: string }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  margin: 0;
  color: ${(props: any) => props.$overrideColor || props.theme.colors.dark60};
`

const Title = styled.span<{ $overrideColor: string }>`
  ${textMixin}
  ${props =>
    props.theme.isMobile &&
    css`
      @media not print {
        font-size: 11px;
        color: ${() => props.$overrideColor || props.theme.colors.dark60};
      }
    `};
`

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props =>
    props.theme.isMobile &&
    css`
      margin-left: 4px;
    `}
`

const Value = styled.span<{ $overrideColor: string }>`
  line-height: 100%;
  color: ${(props: any) => props.$overrideColor || props.theme.colors.dark60};
  font-weight: 400;
  font-size: 20px;
  margin: 0 8px;
  ${props =>
    props.theme.isMobile &&
    css`
      @media not print {
        font-weight: 500;
        font-size: 14px;
        margin: 0;

        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    `};

  ${isIOS() &&
  css`
    @media not print {
      transform: translate(0, 1px);
    }
  `}
`

const Duration = styled.span<any>`
  ${textMixin}
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 11px;
      margin-left: 4px;
    `};
`

const LabelDuration = React.memo((props: IProps) => {
  const { className, title, value, isUnapproved } = props
  const {
    leave: { type }
  } = useContext<ITimelineContext>(TimelineContext)
  const { t } = useTranslation()
  const containerRef: Ref<any> = useRef(null)

  const result: IDuration = useMemo(
    () => getPeriodDurationTextComponents(value, t),
    [value, t]
  )
  const overrideColor: string = useMemo(
    () => (isUnapproved ? colors.error80 : null),
    [isUnapproved]
  )
  const overrideColorValue: string = useMemo(
    () => (isUnapproved ? colors.error100 : null),
    [isUnapproved]
  )

  const ariaLabel: string = useMemo(() => {
    let label = ''
    if (title) {
      label += title.substring(0, title.length - 1) + ' '
    }
    label += result.humanReadableFullString

    return label
  }, [result, title])

  if (value < 1) {
    return null
  }

  return (
    <>
      <Container
        className={className}
        ref={containerRef}
        aria-label={ariaLabel}
        role={'status'}
      >
        <Title $overrideColor={overrideColor} aria-hidden>
          {isUnapproved ? (
            <Tooltip
              dashedUnderline={{ color: colors.error40 }}
              title={t('timeline.unapprovedLeaveTooltip.title')}
              body={t('timeline.unapprovedLeaveTooltip.body', {
                context: type
              })}
              offset={[20, 15]}
            >
              {title}
            </Tooltip>
          ) : (
            title
          )}
        </Title>
        <ComponentsContainer aria-hidden>
          {result.components.map((comp: IDurationTextComponent) => (
            <Fragment key={comp.value + comp.duration}>
              <Value $overrideColor={overrideColorValue}>{comp.value}</Value>
              <Duration $overrideColor={overrideColor}>
                {comp.duration}
              </Duration>
            </Fragment>
          ))}
        </ComponentsContainer>
      </Container>
    </>
  )
})

LabelDuration.displayName = 'LabelDuration'

export default LabelDuration
