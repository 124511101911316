import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import SignInContext, {
  ISignInContext
} from 'src/features/Welcome/components/WelcomePage/context'

const ButtonWrapper = styled(Button)`
  width: 100%;
`

export const dataAttrs = {
  cancelBtn: () => 'too-many-attempts-cancel-btn'
}

const TooManyAttempts = React.memo(() => {
  const { t } = useTranslation()
  const { resetWorkflow }: ISignInContext = useContext(SignInContext)

  const buttons = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.cancelBtn()}
        appearance="cancel"
        onClick={resetWorkflow}
      >
        {t('common.back')}
      </ButtonWrapper>
    ),
    [t, resetWorkflow]
  )

  return <div>{buttons}</div>
})

TooManyAttempts.displayName = 'TooManyAttempts'

export default TooManyAttempts
