import React from 'react'
import { Icon } from 'src/UIKit'
import styled from 'styled-components'

interface IProps {
  color: string
}

const Container = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Dot = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};
  width: 6px;
  height: 6px;
  border-radius: 3px;
`

const Line = styled.div<{ $color: string }>`
  border-left: 1px dashed ${props => props.$color};
  height: 12px;
`

const Arrow = styled(Icon)`
  transform: rotate(-90deg);
  width: 7px;
  height: 7px;
  display: flex;
  margin-top: 1px;
`

const TimelineArrow = React.memo((props: IProps) => (
  <Container>
    <Dot $color={props.color} />
    <Line $color={props.color} />
    <Arrow
      name={'arrow_bow_right'}
      settings={{
        fill: props.color,
        width: '7',
        height: '7'
      }}
    />
  </Container>
))

TimelineArrow.displayName = 'TimelineArrow'

export default TimelineArrow
