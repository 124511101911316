import React, { useContext, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import zIndex from 'src/constants/zIndex'
import Popover from 'src/components/Popover'
import PlanSettingsButtonPopoverContent from 'src/components/JourneyMap/PlanSettingsButton/PopoverContent'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'

const { colors } = createTheme()

interface IProps {
  className?: string
  onOpen?: () => void
}

const Container = styled.button<any>`
  cursor: pointer;
  padding: ${props => (props.theme.isDesktop ? '10px' : '0px')};
  background: rgb(255 255 255 / 90%);
  z-index: ${zIndex.journeyMap.list.button};
  transition: box-shadow 200ms ease-in-out;
  align-self: center;
  margin-left: 10px;
  outline-offset: 0 !important;
  border-radius: 24px;

  ${props =>
    props.theme.isDesktop &&
    css`
      &:hover {
        background: ${p => p.theme.colors.main20};
      }
    `}

  ${printDisplayNoneMixin}
`

const IconWrapper = styled(Icon)<any>`
  width: 24px;
  height: 24px;
`

const PopoverContainer = styled.div`
  position: relative;
`

export const dataAttrs = {
  contextMenu: () => 'manager-settings-context-menu'
}

export const PlanSettingsButton = React.memo((props: IProps) => {
  const { className, onOpen } = props
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const containerRef = useRef(null)
  const popoverRef = useRef(null)
  const { isDesktop } = useContext(ScreenContext)

  const { t } = useTranslation()

  const togglePopover = () => {
    const { current } = popoverRef
    if (!current) {
      return
    }

    if (current) {
      current.toggle()
    }
  }

  const onClick = () => {
    togglePopover()
  }

  const onPopoverChangesState = (oldIsOpen: boolean, newIsOpen: boolean) => {
    setIsPopoverOpen(newIsOpen)
    if (newIsOpen && onOpen) {
      onOpen()
    }
  }

  const renderPopover = () => (
    <Popover
      parent={containerRef.current}
      ref={popoverRef}
      beforeStateChanged={onPopoverChangesState}
    >
      <PlanSettingsButtonPopoverContent onArchiveClick={togglePopover} />
    </Popover>
  )

  return (
    <Container
      data-testid={dataAttrs.contextMenu()}
      className={className}
      onClick={onClick}
      aria-expanded={isPopoverOpen}
      aria-haspopup
      aria-label={t('managerJourneyMap.planSettings')}
    >
      <IconWrapper
        name={isDesktop ? 'context_menu' : 'context_submenu'}
        settings={{ fill: colors.main100 }}
      />
      <PopoverContainer ref={containerRef}>{renderPopover()}</PopoverContainer>
    </Container>
  )
})

PlanSettingsButton.displayName = 'PlanSettingsButton'

export default PlanSettingsButton
