import React from 'react'
import styled from 'styled-components'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  children: any
}

const Container = styled.div`
  display: flex;
`

const SelectedPeriodText = styled(MarkdownText)`
  font-size: 14px;
  line-height: 100%;
  margin: 8px 0 0;

  strong {
    color: ${p => p.theme.colors.dark60};
    font-weight: 700;
  }
`

export const dataAttrs = {
  component: () => 'selected-notice-component'
}

const SelectedNotice = React.memo((props: IProps) => (
  <Container data-testid={dataAttrs.component()}>
    <SelectedPeriodText>{props.children}</SelectedPeriodText>
  </Container>
))

SelectedNotice.displayName = 'SelectedNotice'

export default SelectedNotice
