import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { ITimelinePeriod } from 'src/react-app-env'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import { useTranslation } from 'react-i18next'
import usePrevious from 'src/components/hooks/usePrevious'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import useDate, { IUseDate } from '../hooks/useDate'
import DescriptionText from '../components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import * as Methods from './methods'
import AlertView from '../components/AlertView'
import { useResizeDetector } from 'react-resize-detector'
import { DateSelectors, StyledDatePicker } from '../components/Datepickers'
import { ContentContainer } from '../components/ContentContainer'
import Buttons from '../components/Buttons'

interface IProps {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const TitleWrapper = styled(Title)`
  margin: 0 16px 20px;
`

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${$y - $containerHeight}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const DescriptionTextWrapper = styled(DescriptionText)`
  margin: 0 16px;
  width: 100%;
`
const AlertContainer = styled.div`
  width: 100%;
`

const SicknessPicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    leaveStartDate,
    leaveEndDate,
    dueDate,
    updateLeave,
    onNewChanges,
    leaveHolidays,
    onCalendarActiveDateChanged,
    timelinePeriods
  } = context
  const { period, y, alignsToTop, bottomOffset, onExit } = props
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const [activeStartDate, setActiveStartDate] = useState(undefined)
  const [activeEndDate, setActiveEndDate] = useState(undefined)

  const updatedPeriod = timelinePeriods.find(p => p.type === 'Sickness')

  const startDateMinMax = useMemo(
    () => ({
      ...updatedPeriod.periodStart,
      current: leaveStartDate.current
    }),
    [leaveStartDate, updatedPeriod]
  )

  const endDateMinMax = useMemo(
    () => ({
      ...updatedPeriod.periodEnd,
      current: leaveEndDate.current
    }),
    [leaveEndDate, updatedPeriod]
  )

  const initialDate: IUseDate = useDate(endDateMinMax)
  const currentDate: IUseDate = useDate(endDateMinMax)
  const [isSendingDate, setIsSendingDate] = useState(false)
  const prevLeave: ILeave = usePrevious(leave)

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (isSendingDate && prevLeave !== leave) {
      requestAnimationFrame(() => {
        onExit()
      })
    }
  }, [isSendingDate, leave, prevLeave, onExit])

  const hasTbdPeriod = Methods.hasLongTermAbsenceItem(leave)

  const tbdAlertView: ReactNode = useMemo(
    () => (
      <AlertView
        text={t('timeline.leaveDurationPicker.longTermAbsenceAlertText')}
        shown={hasTbdPeriod}
        cursor={'unset'}
      />
    ),
    [t, hasTbdPeriod]
  )

  const onDateChange = useCallback(
    (date: any) => {
      Methods.onChange({
        currentDate,
        date,
        onNewChanges,
        timelinePeriods,
        period: updatedPeriod,
        dueDate: dueDate.current,
        startDate: leaveStartDate.current,
        leave
      })
    },
    [
      currentDate,
      dueDate,
      leave,
      leaveStartDate,
      onNewChanges,
      updatedPeriod,
      timelinePeriods
    ]
  )

  const isDateChanged: boolean = useMemo(
    () => Methods.isDateChanged({ initialDate, currentDate }),
    [initialDate, currentDate]
  )

  const onConfirm = useCallback(() => {
    Methods.onConfirm({
      onExit,
      currentDate,
      updateLeave,
      timelinePeriods,
      period: updatedPeriod,
      dueDate: dueDate.current,
      startDate: leaveStartDate.current,
      leave
    })
  }, [
    currentDate,
    dueDate,
    leave,
    leaveStartDate,
    onExit,
    updatedPeriod,
    timelinePeriods,
    updateLeave
  ])

  const onCancel = useCallback(() => {
    Methods.onCancel({
      setIsSendingDate,
      initialDate,
      onExit,
      isDateChangedValue: isDateChanged,
      onDateChangeFunc: onDateChange
    })
  }, [setIsSendingDate, initialDate, onExit, onDateChange, isDateChanged])

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={isDateChanged}
        isConfirmDisabled={false}
        onCancel={onCancel}
        onClose={onExit}
        onConfirm={onConfirm}
        onRemove={null}
      />
    ),
    [isDateChanged, onCancel, onExit, onConfirm]
  )

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.period', {
        periodName: period?.timelineConfig?.periodName
      }),
    [t, period]
  )

  const startDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        holidays={leaveHolidays}
        disabled
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.startDate')}
        momentCurrentMinMax={startDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onDateChange}
        activeStartDate={activeStartDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveStartDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      startDateMinMax,
      onDateChange,
      activeStartDate,
      onCalendarActiveDateChanged
    ]
  )

  const endDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        stickRight
        holidays={leaveHolidays}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        title={t('timeline.periodPicker.endDate')}
        momentCurrentMinMax={endDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onDateChange}
        activeStartDate={activeEndDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveEndDate(action.activeStartDate)
        }}
      />
    ),
    [
      leaveHolidays,
      period,
      t,
      endDateMinMax,
      onDateChange,
      activeEndDate,
      onCalendarActiveDateChanged
    ]
  )

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        <AlertContainer>{tbdAlertView}</AlertContainer>
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionTextWrapper>
          {period?.timelineConfig?.datePickerDescription}
        </DescriptionTextWrapper>
        <DateSelectors>
          {startDatePicker}
          {endDatePicker}
        </DateSelectors>
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={bottomOffset} />
    </Container>
  )
})

SicknessPicker.displayName = 'SicknessPicker'

export default SicknessPicker
