import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NormalRadioButtonOption from 'src/features/Leave/components/NormalRadioButtonOption'
import OptionContainer from 'src/UIKit/OptionContainer'
import { TransitionStage } from '../../TransitionFlowContainer'
import TransitionStepsView from '../TransitionStepsView'
import styled, { css } from 'styled-components'

interface IProps {
  name: TransitionStage
  setCurrentStage: (stage: TransitionStage) => void
  onComplete: () => void
}

type babySituationState = 'babyIsHealthy' | 'babyIsIll' | 'none'

const OptionContainerWrapper = styled(OptionContainer)`
  ${props =>
    props.theme.isDesktop &&
    css`
      margin-bottom: 32px;
    `}
`

export const BabyCurrentView = React.memo((props: IProps) => {
  const { name, setCurrentStage, onComplete } = props
  const { t } = useTranslation()
  const [situationState, setSituationState] =
    useState<babySituationState>('none')

  const onNextClicked = () => {
    const nextStage: TransitionStage =
      situationState === 'babyIsHealthy'
        ? 'finalBabyIsHealthy'
        : 'finalBabyIsIll'

    onComplete()
    setCurrentStage(nextStage)
  }

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={situationState === 'babyIsHealthy'}
        onClick={() => {
          setSituationState('babyIsHealthy')
        }}
      >
        {t(`transitionFlow.pages.${name}.options.babyIsHealthy`)}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={situationState === 'babyIsIll'}
        onClick={() => {
          setSituationState('babyIsIll')
        }}
        hidesSeparator
      >
        {t(`transitionFlow.pages.${name}.options.babyIsIll`)}
      </NormalRadioButtonOption>
    ],
    [situationState, t, name]
  )

  return (
    <TransitionStepsView
      name={name}
      onNextClicked={onNextClicked}
      onCancelClicked={() => setCurrentStage('extendDisabilityTime')}
      cancelButtonTextKey={'common.back'}
      isNextDisabled={situationState === 'none'}
      nextButtonTextKey={'common.submit'}
    >
      <OptionContainerWrapper>{options}</OptionContainerWrapper>
    </TransitionStepsView>
  )
})

BabyCurrentView.displayName = 'BabyCurrentView'

export default BabyCurrentView
