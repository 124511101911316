import React, { useMemo, ReactNode } from 'react'
import { ITimelinePeriod } from 'src/react-app-env'
import styled, { css } from 'styled-components'
import { mobileButtonContainerPaddingMixin } from 'src/theme/utils'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import { ContentContainer } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContentContainer'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import { Button } from 'src/UIKit'

interface IProps {
  className?: string
  y?: number
  alignsToTop?: boolean
  onExit: () => void
  period: ITimelinePeriod
}

const Text = styled(ReactMarkdown)`
  line-height: 130%;
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    padding-left: 2em;
    white-space: normal;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}
`

const ButtonContainer = styled.div`
  display: flex;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100%;
          margin: 16px 0 0;
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgb(255 255 255 / 95%);
          justify-content: center;
          align-items: center;
          margin: 16px;
        `}

  ${mobileButtonContainerPaddingMixin};
`

const ButtonWrapper = styled(Button)`
  flex: 1;
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 16px;
      height: 48px;
    `}
`

const TpaInfoBlockPicker = React.memo((props: IProps) => {
  const { onExit } = props
  const { t } = useTranslation()

  const buttonView: ReactNode = useMemo(
    () => (
      <ButtonContainer>
        <ButtonWrapper appearance={'cancel'} onClick={onExit}>
          {t('timelineLockInfo.exitButton')}
        </ButtonWrapper>
      </ButtonContainer>
    ),
    [onExit, t]
  )
  return (
    <ContainerView {...props}>
      <ContentContainer>
        <Text
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer
          }}
        >
          {t('timelineLockInfo.text')}
        </Text>
        {buttonView}
      </ContentContainer>
    </ContainerView>
  )
})

TpaInfoBlockPicker.displayName = 'TpaInfoBlockPicker'

export default TpaInfoBlockPicker
