import React, { useContext, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import createTheme from 'src/theme'
import { IIconName } from 'src/react-app-env'
import { Icon, MarkdownText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import UserProfileContext from 'src/features/UserProfile/Context'
import moment from 'moment'

const { colors } = createTheme()
const DATE_FORMAT = 'MMM DD, YYYY hh:mm A'

interface IProps {
  className?: string
  session: ISession
  isRevoking: boolean
  showsSeparator: boolean
}

const appearingKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  animation: ${appearingKeyframes} 300ms linear forwards;
`

const IconWrapper = styled(Icon)`
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin: 0 16px 0 24px;
    `}
`

const Separator = styled.div`
  height: 1px;
  background: ${props => props.theme.colors.main10};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 24px;
        `
      : css`
          width: 100%;
        `}
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => (props.theme.isDesktop ? '16px 0' : '12px 16px')};
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`

const Title = styled(MarkdownText)`
  color: ${colors.dark60};
  line-height: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const NoteText = styled(MarkdownText)`
  color: ${colors.dark60};
  line-height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 5px;
          font-size: 14px;
        `
      : css`
          margin-top: 4px;
          font-size: 11px;
        `}
`

const RevokeContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 60px;
`

const RevokeButton = styled.button`
  color: ${colors.main100};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
    color: ${colors.main110};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin-right: 24px;
        `
      : css`
          font-weight: 500;
          font-size: 14px;
        `}
`

const SessionsListItem = React.memo((props: IProps) => {
  const { revokeSession } = useContext(UserProfileContext)
  const {
    className,
    showsSeparator,
    isRevoking,
    session: { ip, device, current, client, date, id }
  } = props
  const { t } = useTranslation()

  const iconView = useMemo(() => {
    const lcDevice: string = device.toLowerCase()
    const isMobile: boolean =
      lcDevice.indexOf('ios') !== -1 || lcDevice.indexOf('android') !== -1
    const name: IIconName = isMobile ? 'device_mobile' : 'device_laptop'

    return (
      <IconWrapper
        name={name}
        settings={{
          fill: current ? colors.success100 : colors.dark50
        }}
      />
    )
  }, [device, current])

  const title: string = useMemo(
    () =>
      t('sessions.browserOnOsAndIp', {
        browser: client,
        os: device,
        ip
      }),
    [client, device, ip, t]
  )

  const strDate: string = useMemo(
    () => moment(date).format(DATE_FORMAT),
    [date]
  )

  const note: string = useMemo(
    () =>
      current
        ? t('sessions.yourCurrentSession')
        : `${t('sessions.createdOn')} ${strDate}`,
    [current, t, strDate]
  )

  const showsRevoke: boolean = useMemo(
    () => !current && !isRevoking,
    [current, isRevoking]
  )

  const ariaLabel: string = useMemo(
    () =>
      t('common.accessibilityText.sessionItem', { client, date: strDate, ip }),
    [client, strDate, ip, t]
  )

  return (
    <Container
      className={className}
      aria-hidden={current}
      aria-label={ariaLabel}
    >
      <ContentContainer>
        {iconView}
        <TextsContainer aria-hidden>
          <Title>{title}</Title>
          <NoteText>{note}</NoteText>
        </TextsContainer>
        {showsRevoke && (
          <RevokeContainer>
            <RevokeButton
              onClick={() => {
                revokeSession(id)
              }}
            >
              {t('common.revoke')}
            </RevokeButton>
          </RevokeContainer>
        )}
      </ContentContainer>
      {showsSeparator && <Separator />}
    </Container>
  )
})

SessionsListItem.displayName = 'SessionsListItem'

export default SessionsListItem
