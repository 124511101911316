import merge from 'lodash.merge'
import {
  isFamilyLeaveNotIncludingPersonal,
  openDatePickerForPreference
} from 'src/utils/leaveUtils'
import {
  defaultCustomerConfig,
  ICustomerConfig,
  LeaveNoticeItem
} from './config'
import { VIEW_LEAVE_NOTICE_DISABILITY_KEY } from 'src/utils/ls'
import { ITimelineContext } from 'src/features/Timeline/Context'
import logo from 'src/assets/images/intuitive-logo.svg'
import { ITimelinePeriod, CreateLeaveStage } from 'src/react-app-env'
import Workflow from 'src/utils/workflow'
import {
  isBondingPeriodInPast,
  isPeriodNotManagedTpa,
  isPeriodNotSynced
} from 'src/utils/periodUtils'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    logo,
    tpa: 'Sedgwick',
    leave: {
      create: {
        hasTpaFlow: (workflow: Workflow<CreateLeaveStage>) =>
          workflow.current === 'parental',
        showMedicalFiledClaim: true,
        showMedicalPermanentNote: true,
        showMilitaryNotSupportedNote: true,
        showsMoreThanNote: () => false,
        preferences: ['starter', 'reliever', 'middler'],
        workflow: {
          Medical: {
            init: 'medicalBeforeWeStart',
            medicalBeforeWeStart: 'medical',
            medical: 'selectState',
            selectState: 'congrats',
            congrats: null
          },
          Military: {
            military: ({ isMilitaryIncludingReturn }) =>
              isMilitaryIncludingReturn ? 'militaryReturning' : 'congrats',
            militaryReturning: 'congrats',
            congrats: null
          },
          Parental: {
            parental: 'selectState',
            selectState: ({ selectedLeaveType, tpaLeave }) =>
              tpaLeave
                ? 'selectTpa'
                : selectedLeaveType?.type !== 'Pregnancy'
                  ? 'selectLeavePreference'
                  : 'congrats',
            selectTpa: ({ selectedLeaveType, useTpaLeave }) =>
              useTpaLeave === 'no'
                ? selectedLeaveType?.type !== 'Pregnancy'
                  ? 'selectLeavePreference'
                  : 'congrats'
                : 'congrats',
            selectLeavePreference: 'congrats',
            congrats: null
          },
          Personal: { personal: 'congrats', congrats: null },
          Family: { family: 'congrats', congrats: null }
        }
      },
      timeline: {
        hasSupplementPay: (leave: ILeave) =>
          !isFamilyLeaveNotIncludingPersonal(leave),
        getLeaveNoticeItems: (leave: ILeave): LeaveNoticeItem[] => {
          const result: LeaveNoticeItem[] = []

          if (leave.type === 'Pregnancy') {
            result.push({
              noticeKey: VIEW_LEAVE_NOTICE_DISABILITY_KEY,
              titleKey: 'leaveNotice.viewTopUpPlan.title',
              bodyTextKey: 'leaveNotice.viewTopUpPlan.text'
            })
          }

          return result
        },
        showReviewAlerts: true,
        onAfterCloseReviewAlert: (timelineContext: ITimelineContext) => {
          openDatePickerForPreference(timelineContext, 'middler')
        }
      },
      planChange: {
        showAsteriskNoteView: true
      },
      tpaSync: {
        showSyncStatus: (leave: ILeave) =>
          isPublishedOrBabyArrivedLeave(leave.status),
        isPeriodNotManagedTpa: (leave: ILeave, period: ITimelinePeriod) =>
          isPeriodNotManagedTpa(leave, period),
        isPeriodNotSynced: (leave: ILeave, period: ITimelinePeriod) =>
          isPeriodNotSynced(leave, period),
        isPeriodNotUpdated: (leave: ILeave, period: ITimelinePeriod) =>
          isBondingPeriodInPast(leave, period)
      }
    },
    userProfile: {
      tpaSyncSection: {
        showPromptTurnOff: (tpaSyncing: boolean) => tpaSyncing,
        showLastUpdate: false
      }
    }
  })
