import emailValidator from 'email-validator'

const validateEmail = (value: string) => {
  if (value === '') {
    return { valid: true, errorString: null }
  }

  const valid = emailValidator.validate(value)
  let errorKey = 'emailIsNotValid'

  if (!valid && value) {
    const doesNotContainAt = /^((?!@).)*$/
    const nameAscii =
      /^.*([^A-Za-z0-9\\.\-_!#$%&'*+-/=?^_`{|}~]+).*[^.]@[^@]*.*$/
    const doubleAt = /[@]{2,}/
    const nameStartsWithDot = /^[.]/
    const nameEndsWithDot = /.*\.@.*/
    const nameDoubleDotsInside = /[.]{2,}/
    const atLeastOneCharacterBeforeAt = /^!*@.*$/
    const hasAtLeastOneNonAsciiCharacterInName =
      /^[A-Za-z0-9\\.\-_!#$%&'*+-/=?^_`{|}~]{1,}@.*$/

    if (value.substring(value.indexOf('@')).indexOf('.') === -1) {
      errorKey = 'emailIsNotValidDomain'
    } else if (doesNotContainAt.test(value)) {
      errorKey = 'emailIsNotValidAt'
    } else if (
      !hasAtLeastOneNonAsciiCharacterInName.test(value) ||
      nameAscii.test(value) ||
      doubleAt.test(value) ||
      nameStartsWithDot.test(value) ||
      nameEndsWithDot.test(value) ||
      nameDoubleDotsInside.test(value) ||
      atLeastOneCharacterBeforeAt.test(value)
    ) {
      errorKey = 'emailIsNotValidName'
    } else {
      const domainAscii =
        /^.*@[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]\.[A-Za-z]{2,}$/
      if (!domainAscii.test(value)) {
        errorKey = 'emailIsNotValidDomain'
      }
    }
  }

  return { valid, errorString: valid ? null : `common.${errorKey}` }
}

const validatePhone = (value: string, regexp: string) => {
  if (value === '') {
    return { valid: true, errorString: null }
  }

  const errorString = 'userProfile.phoneSettings.numberIsNotValid'
  if (!value) {
    return { valid: false, errorString }
  }
  const regexpPhone = new RegExp(regexp)
  const valid = regexpPhone.test(value)
  return { valid, errorString: valid ? null : errorString }
}

const validateConfirmationCode = (value: string) => {
  const errorString = 'common.codeIsNotValid'
  if (!value) {
    return { valid: false, errorString }
  }
  const regexp = /^\d\d-\d\d$/g
  const matches = value.match(regexp)
  const valid = !!matches
  return { valid, errorString: valid ? null : errorString }
}

export { validateEmail, validatePhone, validateConfirmationCode }
