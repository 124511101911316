import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import LeaveDateSinglePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateSinglePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isNotShowDate = input.period.blocks.length === 0
  return {
    ...baseConfig,
    datePickerComponent: LeaveDateSinglePicker,
    timeViewProps: {
      ...baseConfig.timeViewProps,
      date: isNotShowDate ? null : baseConfig.timeViewProps.date,
      symbol: '-'
    }
  }
}
