import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

interface IProps {
  isMaxAccrual: boolean
  maxAccrualDate: string
  recommendedDaysStart: number
  recommendedDaysDisability: number
  recommendedDaysBonding: number
  estimatedNextMaxAccrualDate: string
}

const IconWrapper = styled(Icon)`
  display: flex;
  padding: 0 6px;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.main10};
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 12.5px 0 16px;
        `
      : css`
          margin: 18px 0 16px;
        `}
`

const InfoText = styled(ReactMarkdown)`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isMobile &&
    css`
      line-height: 130%;
    `}
`

const Description = styled(ReactMarkdown)<{
  $marginTop: number
}>`
  font-size: 16px;
  font-weight: normal;
  color: ${props => props.theme.colors.dark60};
  margin-top: ${props => props.$marginTop}px;
  white-space: pre-wrap;
  margin-bottom: 20px;

  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 14px;
      line-height: 143%;
    `}

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

const MaxAccrualDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const Title = styled.div`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  font-weight: ${props => (props.theme.isDesktop ? '500' : '400')};
  color: ${props => props.theme.colors.dark60};
  margin: 16px 0 8px;
`

const DashedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 363px;
  width: 100%;
  height: 34px;
  color: ${props => props.theme.colors.dark60};
  border: 1px dashed ${props => props.theme.colors.dark10};
  border-radius: 8px;
`

const NumberText = styled.span`
  font-size: 22px;
  font-weight: 500;
  margin: 0 2px 0 10px;
  color: ${props => props.theme.colors.sub100};
`

const StringText = styled.span`
  color: ${props => props.theme.colors.sub60};
  margin-right: 8px;
  font-size: 12px;
`

export const dataAttrs = {
  recommendedItem: (itemTranslation: string) =>
    `recommended-item-${itemTranslation}`
}

const ResultView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    isMaxAccrual,
    maxAccrualDate,
    recommendedDaysStart,
    recommendedDaysDisability,
    recommendedDaysBonding,
    estimatedNextMaxAccrualDate
  } = props

  const accrualDate: string = useMemo(
    () => moment(maxAccrualDate).utc().format(DATE_FORMAT),
    [maxAccrualDate]
  )

  const nextAccrualDate: string = useMemo(
    () => moment(estimatedNextMaxAccrualDate).utc().format(DATE_FORMAT),
    [estimatedNextMaxAccrualDate]
  )

  const recommendedItems: any[] = useMemo(
    () => [
      { count: recommendedDaysStart, keyTranslation: 'recommendedStart' },
      {
        count: recommendedDaysDisability,
        keyTranslation: 'recommendedDisability'
      },
      { count: recommendedDaysBonding, keyTranslation: 'recommendedBonding' }
    ],
    [recommendedDaysBonding, recommendedDaysDisability, recommendedDaysStart]
  )

  const recommendedView = useMemo(
    () => (
      <>
        {recommendedItems.map(
          (item: any) =>
            item.count > 0 && (
              <DashedItem
                data-testid={dataAttrs.recommendedItem(item.keyTranslation)}
                key={item.keyTranslation}
              >
                <NumberText>{item.count}</NumberText>
                <StringText>
                  {t('common.durations.day', {
                    count: item.count
                  })}
                </StringText>
                <InfoText>
                  {t(`vacationBalance.dialog.${item.keyTranslation}`, {
                    accrualDate
                  })}
                </InfoText>
              </DashedItem>
            )
        )}
      </>
    ),
    [t, accrualDate, recommendedItems]
  )

  return isMaxAccrual ? (
    <MessageWrapper>
      <InfoWrapper>
        <IconWrapper
          name={'exclamation_sign'}
          settings={{
            fill: colors.orangeLight
          }}
        />
        <InfoText>
          {t('vacationBalance.dialog.maxOutInfo', { accrualDate })}
        </InfoText>
      </InfoWrapper>
      <Separator />
      <MaxAccrualDescription>
        <Title>{t('vacationBalance.dialog.maxOutText')}</Title>
        {recommendedView}
        <Description $marginTop={8}>
          {t('vacationBalance.dialog.followsRecommendations', {
            nextAccrualDate
          })}
        </Description>
      </MaxAccrualDescription>
    </MessageWrapper>
  ) : (
    <MessageWrapper>
      <InfoWrapper>
        <IconWrapper
          name={'checkbox_checkmark'}
          settings={{
            fill: colors.specialMedium,
            width: '17.5',
            height: '13.41'
          }}
        />
        <InfoText>
          {t('vacationBalance.dialog.looksGoodInfo', { accrualDate })}
        </InfoText>
      </InfoWrapper>
      <Separator />
      <Description
        urlTransform={TelUrlTransformer}
        components={{
          a: LinkRenderer
        }}
        $marginTop={16}
      >
        {t('vacationBalance.dialog.looksGoodText')}
      </Description>
    </MessageWrapper>
  )
})

ResultView.displayName = 'ResultView'

export default ResultView
