import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import ReadyToTransitionView from './components/ReadyToTransitionView'
import { IWithQueriesProps } from 'src/react-app-env'
import { errorPageRoute } from 'src/routes/constants'
import LoadingSpinner from 'src/components/LoadingSpinner'
import withQueries from 'src/components/HOC/withQueries'
import zIndex from 'src/constants/zIndex'
import DisabilityTimeView from './components/DisabilityTimeView'
import FinalActionView from './components/FinalActionView'
import BabyCurrentView from './components/BabyCurrentView'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { TRANSITION_FLOW_KEY } from 'src/utils/ls'
import withRouter from 'src/components/hooks/useRouter'
import useCreateMetric, {
  IUseCreateMetricArgs
} from 'src/graphql/hooks/useCreateMetric'
import {
  MetricEventTransitionFlowImpression,
  MetricEventTransitionFlowStartClick,
  MetricEventTransitionFlowSkipClick
} from 'src/constants/metrics'

interface IProps extends IWithQueriesProps {}

export type TransitionStage =
  | 'readyToTransition'
  | 'extendDisabilityTime'
  | 'babyCurrentSituation'
  | 'finalActionDisabilityTime'
  | 'finalBabyIsHealthy'
  | 'finalBabyIsIll'

export type MetricType = 'start' | 'skip'

const Page = styled.div`
  height: 100%;
  position: relative;
  z-index: ${zIndex.transitionSteps.page};
`

export const TransitionFlowContainer = React.memo((props: IProps) => {
  const { queries } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [, setSkipTransitionFlow] = useLocalStorage(TRANSITION_FLOW_KEY, {
    skip: false
  })

  const [currentStage, setCurrentStage] =
    useState<TransitionStage>('readyToTransition')
  const [fetching, setFetching] = useState(false)

  const currentFlowType: string = useMemo(
    () => state && (state as any).flow,
    [state]
  )

  const disabilityEnd: string = useMemo(
    () => state && (state as any).disabilityEnd,
    [state]
  )

  useCreateMetric({ eventType: MetricEventTransitionFlowImpression })

  const sendClickMetric = useCallback(
    async (type: MetricType) => {
      const createMetricArgs: IUseCreateMetricArgs = {
        eventType: null
      }
      switch (type) {
        case 'start':
          createMetricArgs.eventType = MetricEventTransitionFlowStartClick
          break
        case 'skip': {
          createMetricArgs.eventType = MetricEventTransitionFlowSkipClick
          break
        }
      }
      await queries.createMetric(createMetricArgs, {
        anyError: (er): void => console.error(er)
      })
    },
    [queries]
  )

  const setCompleteTransitionFlow = useCallback(
    async (transitionFlowType: string) => {
      try {
        setFetching(true)
        await queries.completeTransitionFlow(transitionFlowType)
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setFetching(false)
      }
    },
    [navigate, queries]
  )

  const onComplete = useCallback(() => {
    setCompleteTransitionFlow(currentFlowType)
  }, [currentFlowType, setCompleteTransitionFlow])

  const onSkip = useCallback(async () => {
    await sendClickMetric('skip')
    setSkipTransitionFlow({ skip: true })
    navigate(-1)
  }, [navigate, sendClickMetric, setSkipTransitionFlow])

  const getCurrentStageView = useCallback(
    (stage: TransitionStage): ReactNode => {
      switch (stage) {
        case 'readyToTransition':
          return (
            <ReadyToTransitionView
              name={stage}
              setCurrentStage={setCurrentStage}
              onSkip={onSkip}
              sendClickMetric={sendClickMetric}
            />
          )
        case 'extendDisabilityTime':
          return (
            <DisabilityTimeView
              name={stage}
              setCurrentStage={setCurrentStage}
              onComplete={onComplete}
              disabilityEnd={disabilityEnd}
            />
          )
        case 'babyCurrentSituation':
          return (
            <BabyCurrentView
              name={stage}
              setCurrentStage={setCurrentStage}
              onComplete={onComplete}
            />
          )
        case 'finalActionDisabilityTime':
        case 'finalBabyIsHealthy':
        case 'finalBabyIsIll':
          return <FinalActionView name={stage} />

        default:
          return null
      }
    },
    [disabilityEnd, onComplete, onSkip, sendClickMetric]
  )

  const currentContent = useMemo(
    () => getCurrentStageView(currentStage),
    [currentStage, getCurrentStageView]
  )

  return (
    <Page>
      {currentContent}
      {fetching && <LoadingSpinner fullScreen fadesIn />}
    </Page>
  )
})

TransitionFlowContainer.displayName = 'TransitionFlowContainer'

export default withQueries(withRouter(TransitionFlowContainer))
