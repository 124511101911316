import React from 'react'
import App from 'src/App'
import { createRoot } from 'react-dom/client'
import { initSentry } from './utils/sentry'

initSentry()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

window.addEventListener('beforeinstallprompt', (e: Event) => {
  e.preventDefault()
})
