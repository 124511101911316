import React from 'react'

export interface IManagerPreviousVersionsContext {
  clickBack: () => void
  showDates: boolean
  setShowDates: (v: boolean) => void
  currentAbsence: IAbsence
}

const ManagerPreviousVersionsContext = React.createContext(null)

export default ManagerPreviousVersionsContext
