import React from 'react'
import styled from 'styled-components'

interface IProps {
  children: string
}

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark60};
`

const TimelinePageTitle = React.memo((props: IProps) => (
  <Title>{props.children}</Title>
))

TimelinePageTitle.displayName = 'TimelinePageTitle'

export default TimelinePageTitle
