import React from 'react'
import { IAccordion } from 'src/components/Accordion'

export type LastClickedRootItem = 'insights' | 'surveys'

export interface IHrAdminContext {
  insights: IAccordion
  surveys: IAccordion
  insightsLeaveGroups: string[]
  surveysLeaveGroups: string[]
  insightSubItemTitles: string[]
  surveysSubItemTitles: string[]
  selectedInsightSubItemIndex: number
  selectedSurveySubItemIndex: number
  selectedAnalyticsType: string
  lastClickedRootItem: LastClickedRootItem

  countryCodes: string[]
  selectedCountryCode: string
  setSelectedCountryCode: (c: string) => void
}

const HrAdminContext = React.createContext(null)

export default HrAdminContext
