import React from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'

interface IProps {
  children: string
  isUnused: boolean
  isPartial: boolean
}

const Text = styled.span<{
  $unused: boolean
  $isPartial: boolean
}>`
  font-size: ${props => (props.$isPartial ? '13px' : '16px')};
  font-weight: 500;
  color: ${props =>
    props.$unused
      ? props.theme.colors.timelineIncome
      : props.theme.colors.light100};
`

const IncomeValueView = React.memo(
  (props: IProps) => {
    const { isUnused, children, isPartial } = props

    return (
      <Text $unused={isUnused} $isPartial={isPartial}>
        {children}
      </Text>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

IncomeValueView.displayName = 'IncomeValueView'

export default IncomeValueView
