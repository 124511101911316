import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import Buttons from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Buttons'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import DescriptionText from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import {
  IDialogData,
  ITimelinePeriod,
  IWithDialogManager
} from 'src/react-app-env'
import {
  getChangesForPeriodIntermittent,
  getExtraParameters,
  getPeriodDuration,
  IChangesIntermittentBlock
} from '../methods'
import InfoMessageView from '../components/InfoMessageView'
import BlockView from './components/BlockView'
import { newIntermittentBlock } from './methods'
import { ContentContainer } from '../components/ContentContainer'
import ApprovedByTpaView from '../components/ApprovedByTpaView'
import RemoveDialogContent from '../components/RemoveDialogContent'
import { withDialogManager } from 'src/components/DialogManager'

interface IProps extends IWithDialogManager {
  className?: string
  y?: number
  alignsToTop?: boolean
  onExit: () => void
  period: ITimelinePeriod
}

const InfoMessageWrapper = styled.div`
  margin: 16px 20px 0;
  width: 100%;
`

const TitleWrapper = styled(Title)`
  margin-bottom: 20px;
`

const IntermittentBlocksPicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  let { period } = props
  const { onExit, dialogManager } = props
  const { t } = useTranslation()
  const {
    onNewChanges,
    timelinePeriods,
    onCancelChanges,
    leaveHolidays,
    onCalendarActiveDateChanged
  } = context

  period =
    timelinePeriods.find((p: ITimelinePeriod) =>
      period?.timelineConfig.usePeriodRefIdSearch && period.refID
        ? p.refID === period.refID &&
          p.appearance === 'Standard' &&
          p.periodStart.current.isSame(period.periodStart.current)
        : p.hash === period.hash
    ) || period

  const [currentStartDate, setCurrentStartDate] = useState<Moment>(
    period.startDate
  )
  const [currentDuration, setCurrentDuration] = useState<number>(0)
  const [currentDaysWeekOne, setCurrentDaysWeekOne] = useState<IWeekday[]>()
  const [currentDaysWeekTwo, setCurrentDaysWeekTwo] = useState<IWeekday[]>()
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [newPeriod, setNewPeriod] = useState<boolean>(false)
  const [catchAddable, setCatchAddable] = useState<boolean>(false)
  const [durationValid, setDurationValid] = useState<boolean>(false)
  const [isDurationApproved, setIsDurationApproved] = useState<boolean>(false)
  const isRemovable = period.isRemovable

  const isAddable: boolean = useMemo(
    () => period.appearance === 'Addable',
    [period]
  )

  useEffect(() => {
    if (period.appearance === 'Addable') {
      setCatchAddable(true)
    }
  }, [period.appearance])

  const changesInput = useMemo(
    () => ({
      period
    }),
    [period]
  )

  const onCancelClicked = useCallback(() => {
    onExit()
    onCancelChanges()
  }, [onExit, onCancelChanges])

  const onConfirm = useCallback(() => {
    onExit()
    const intermittentBlockChanges: IChangesIntermittentBlock = {
      startDate: currentStartDate,
      duration: currentDuration,
      activeDaysWeekOne: currentDaysWeekOne,
      activeDaysWeekTwo: currentDaysWeekTwo,
      isNew: newPeriod
    }
    const extra = getExtraParameters({
      period,
      isDurationApproved
    })
    const changes: any = getChangesForPeriodIntermittent({
      ...changesInput,
      period,
      intermittentBlockChanges,
      extra
    })
    onNewChanges(changes, true)
  }, [
    onExit,
    currentStartDate,
    currentDuration,
    currentDaysWeekOne,
    currentDaysWeekTwo,
    newPeriod,
    changesInput,
    period,
    onNewChanges,
    isDurationApproved
  ])

  const onRemoveChanges = useCallback(() => {
    onExit()
    const intermittentBlockChanges: IChangesIntermittentBlock = {
      startDate: currentStartDate,
      duration: 0
    }
    const extra = getExtraParameters({
      period,
      isDurationApproved
    })
    const changes: any = getChangesForPeriodIntermittent({
      ...changesInput,
      period,
      intermittentBlockChanges,
      extra
    })
    onNewChanges(changes, true)
    setHasChanges(true)
  }, [
    onExit,
    currentStartDate,
    period,
    isDurationApproved,
    changesInput,
    onNewChanges
  ])

  const removeDialogView: IDialogData = useMemo(
    () => ({
      title: t('timeline.removeDialog.title'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('timeline.removeDialog.yes'),
          onClick: () => {
            onRemoveChanges()
          },
          order: 2
        }
      ],
      children: (
        <RemoveDialogContent
          periodType={period.type}
          isDurationApproved={isDurationApproved}
          setIsDurationApproved={setIsDurationApproved}
        />
      )
    }),
    [isDurationApproved, onRemoveChanges, period.type, t]
  )

  useEffect(() => {
    if (dialogManager?.opened) {
      dialogManager.update(removeDialogView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDurationApproved])

  const onRemove = useCallback(() => {
    if (period.timelineConfig.useApprovedByTpa) {
      dialogManager.add(removeDialogView)
    } else {
      onRemoveChanges()
    }
  }, [
    period.timelineConfig.useApprovedByTpa,
    dialogManager,
    removeDialogView,
    onRemoveChanges
  ])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t, null)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [period, t])

  const approvedByTpaView: ReactNode = useMemo(
    () =>
      period.timelineConfig.useApprovedByTpa && (
        <ApprovedByTpaView
          approved={isDurationApproved}
          hasChanges={hasChanges}
          onClick={() => {
            setIsDurationApproved(!isDurationApproved)
          }}
        />
      ),
    [isDurationApproved, setIsDurationApproved, hasChanges, period]
  )

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={hasChanges}
        isConfirmDisabled={!durationValid}
        onCancel={onCancelClicked}
        onClose={onExit}
        onConfirm={onConfirm}
        onRemove={isRemovable && !hasChanges ? onRemove : null}
      />
    ),
    [
      hasChanges,
      durationValid,
      onCancelClicked,
      onExit,
      onConfirm,
      isRemovable,
      onRemove
    ]
  )

  const onBlockStartDateChanged = useCallback(
    (p: ITimelinePeriod, date: Moment) => {
      const intermittentBlockChanges: IChangesIntermittentBlock = {
        startDate: date,
        isNew: newPeriod
      }

      if (p.timelineConfig.intermittentPickerResetBlock) {
        intermittentBlockChanges.duration = getPeriodDuration(p)
      } else {
        intermittentBlockChanges.duration = currentDuration
        intermittentBlockChanges.activeDaysWeekOne = currentDaysWeekOne
        intermittentBlockChanges.activeDaysWeekTwo = currentDaysWeekTwo
      }

      const changes: any = getChangesForPeriodIntermittent({
        ...changesInput,
        period: p,
        intermittentBlockChanges
      })
      onNewChanges(changes)
      setCurrentStartDate(date)
      setHasChanges(true)
    },
    [
      newPeriod,
      currentDuration,
      currentDaysWeekOne,
      currentDaysWeekTwo,
      changesInput,
      onNewChanges
    ]
  )

  const getChangesParams = useCallback(
    (isChange: boolean) => {
      setHasChanges(isChange)

      if (!isAddable && catchAddable) {
        setHasChanges(true)
        setNewPeriod(true)
      }
    },
    [isAddable, catchAddable]
  )

  const getParams = useCallback(
    (
      startDate: Moment,
      duration: number,
      activeDaysWeekOne: Set<IWeekday>,
      activeDaysWeekTwo: Set<IWeekday>,
      isDurationValid
    ) => {
      setCurrentStartDate(startDate)
      setCurrentDuration(duration)
      setCurrentDaysWeekOne(Array.from(activeDaysWeekOne))
      setCurrentDaysWeekTwo(Array.from(activeDaysWeekTwo))
      setDurationValid(isDurationValid)
    },
    []
  )

  const editBlockView: ReactNode = useMemo(
    () => (
      <BlockView
        isNew
        removable={isRemovable}
        onStartDateChanged={onBlockStartDateChanged}
        period={period}
        getChangesParams={getChangesParams}
        getParams={getParams}
        holidays={leaveHolidays}
        onCalendarActiveDateChanged={onCalendarActiveDateChanged}
      />
    ),
    [
      isRemovable,
      onBlockStartDateChanged,
      period,
      getChangesParams,
      getParams,
      leaveHolidays,
      onCalendarActiveDateChanged
    ]
  )

  useEffect(() => {
    if (isAddable && !hasChanges) {
      const intermittentBlockChanges = newIntermittentBlock(
        period,
        timelinePeriods
      )
      const changes: any = getChangesForPeriodIntermittent({
        ...changesInput,
        period,
        intermittentBlockChanges
      })
      onNewChanges(changes)
    }
    if (isAddable && hasChanges) {
      onExit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges, isAddable])

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.duration', {
        periodName: period.timelineConfig.periodName
      }),
    [t, period]
  )

  if (isAddable && hasChanges) {
    return null
  }

  return (
    <ContainerView {...props}>
      <ContentContainer>
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionText>
          {period?.timelineConfig?.datePickerDescription}
        </DescriptionText>
        {editBlockView}
        {infoMessageView}
        {approvedByTpaView}
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

IntermittentBlocksPicker.displayName = 'IntermittentBlocksPicker'

export default withDialogManager(IntermittentBlocksPicker)
