import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { ITimelinePeriodConfig, IGetPeriodConfigInput } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import getDetailsType from '../getDetailsType'

const paternityTypes = ['AdoptionFather', 'SurrogateFather', 'Partner']

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  if (paternityTypes.includes(input.leave.type)) {
    return {
      ...baseConfig,
      periodPickerShowWorkdays: true,
      datePickerComponent: MultiDatePicker,
      detailsType: 'PaternityPaidLeave'
    }
  }
  return {
    ...baseConfig,
    datePickerComponent: LeaveDatePeriodPicker,
    periodKeyDate: 'periodEnd',
    detailsType:
      input.leave.type === 'Pregnancy'
        ? getDetailsType(input)
        : baseConfig.detailsType
  }
}
