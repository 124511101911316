import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const FLOAT_REG_EXP = /^\d{1,3}(\.\d{0,2})?$/

interface IProps {
  value: string
  onChange: (value: string) => void
  onKeyDown: (event: SyntheticEvent) => void
  width?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RowContainer = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  gap: 6px;
  width: ${props => props.$width};
`

const Item = styled.div`
  width: 10px;
`

const Input = styled.input`
  outline: none;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.dark50};
  border-radius: 6px;
  box-sizing: border-box;
  background: ${props => props.theme.colors.light100};
  font-size: 16px;
  line-height: 100%;
  padding: 0 12px;
  height: 46px;
  color: ${props => props.theme.colors.dark80};

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main110};
    outline-offset: 0;
    border-radius: 8px;
  }
`

const VacationInput = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { value, onChange, onKeyDown, width } = props

  const handleValidationOnChange = (e: any, funcOnChange: any) => {
    const amount = e.target.value
    if (!amount || amount.match(FLOAT_REG_EXP)) {
      funcOnChange(amount)
    }
  }

  return (
    <Container>
      <RowContainer $width={width || '134px'}>
        <Input
          onChange={e => handleValidationOnChange(e, onChange)}
          onKeyDown={onKeyDown}
          value={value || ''}
          alt={'text'}
          name={'input'}
          type={'string'}
          placeholder={t('vacationBalance.input.placeholder')}
          spellCheck={'false'}
          autoCorrect={'off'}
          autoCapitalize={'off'}
          autoComplete={'off'}
          autoFocus
        />
        <Item>
          {t('common.hour', {
            count: parseInt(value, 10)
          })}
        </Item>
      </RowContainer>
    </Container>
  )
})

VacationInput.displayName = 'VacationInput'

export default VacationInput
