import { QueryResult, useQuery } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { queryLeaveJourneyMap } from 'src/graphql/queries'
import LeftPanel from 'src/components/JourneyMap/LeftPanel'
import zIndex from 'src/constants/zIndex'
import LeaveInfo from 'src/components/JourneyMap/LeaveInfo'
import { getJourneyMapStats } from 'src/utils/journeyMap'
import Settings from 'src/features/JourneyMap/components/Settings'
import JourneyMapContext, {
  IJourneyMapContext
} from 'src/features/JourneyMap/Context'
import ScreenContext from 'src/contexts/ScreenContext'

const LeftPanelWrapper = styled(LeftPanel)`
  grid-area: leftPanel;
  overflow-y: auto;
  ${printDisplayNoneMixin};
`

const SettingsWrapper = styled(Settings)`
  ${props =>
    props.theme.isMobile &&
    css`
      grid-area: filters;
      box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
      z-index: ${zIndex.journeyMap.page.priorityFiltersWrapper};
    `}

  ${printDisplayNoneMixin}
`

export const JourneyMapSettingsContainer = React.memo(() => {
  const context: IJourneyMapContext = useContext(JourneyMapContext)
  const {
    isOverdueMode,
    onOverdueClick,
    priorityFilters,
    periodFilters,
    countryCode
  } = context
  const { isDesktop } = useContext(ScreenContext)

  const result: QueryResult = useQuery(queryLeaveJourneyMap, {
    fetchPolicy: 'cache-first'
  })

  const leave: ILeave = result.data.leave

  const { totalItemsCount, currentItemsCount, overdueItemsCount } =
    getJourneyMapStats(
      leave.journeyMap || [],
      priorityFilters,
      periodFilters,
      isOverdueMode
    )

  useEffect(() => {
    if (isOverdueMode && overdueItemsCount === 0) {
      window.setTimeout(onOverdueClick, 0)
    }
  }, [isOverdueMode, overdueItemsCount, onOverdueClick])

  const overdueProps: any = {
    overdueItemsCount,
    showOverdueAsRed: !isOverdueMode,
    onOverdueClick
  }

  const settingsView = (
    <SettingsWrapper
      filters={priorityFilters}
      periods={periodFilters}
      onFilterClick={context.onFilterClick}
      onPeriodClick={context.onPeriodClick}
      onResetFilters={isOverdueMode ? null : context.onResetFiltersClick}
      totalItemsCount={totalItemsCount}
      currentItemsCount={currentItemsCount}
      onExpanderStateDidChange={context.onMobileFiltersExpandStateDidChange}
      {...overdueProps}
    />
  )

  return isDesktop ? (
    <LeftPanelWrapper useShortTopMargin {...overdueProps}>
      {leave && <LeaveInfo leave={leave} countryCode={countryCode} />}
      {settingsView}
    </LeftPanelWrapper>
  ) : (
    settingsView
  )
})

JourneyMapSettingsContainer.displayName = 'JourneyMapSettingsContainer'

export default JourneyMapSettingsContainer
