import React, { useContext, useImperativeHandle, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import MobileExpandView from 'src/components/MobileExpandView'
import ScreenContext from 'src/contexts/ScreenContext'
import zIndex from 'src/constants/zIndex'

interface IMobileExpandViewProps {
  top?: string
  headerView: any
  onExpanderStateWillChange?: (isExpanded: boolean) => void
  onExpanderStateDidChange: (isExpanded: boolean) => void
}

interface IProps {
  className?: string
  children: any
  mobileExpandViewProps: IMobileExpandViewProps
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: filters;
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  z-index: ${zIndex.journeyMap.page.priorityFiltersWrapper};
`

const expandKeyFrames = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    height: 476px;
    opacity: 1;
}`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  ${props =>
    props.theme.isMobile &&
    css`
      animation: ${expandKeyFrames} 200ms ease-in-out;
      background: ${props.theme.colors.light100};
      box-shadow: 0 6px 10px rgb(0 0 0 / 5%);
      padding: 0;
    `}
`

export const MobileFiltersTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Separator = styled.div`
  margin: 12px 0 0;
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: ${props => props.theme.colors.main40};
`

const JourneyMapSettingsViewContainer = React.forwardRef(
  (props: IProps, ref: any) => {
    const { className, children, mobileExpandViewProps } = props
    const mobileExpandViewRef = useRef(null)
    const { isDesktop } = useContext(ScreenContext)

    useImperativeHandle(ref, () => ({
      collapse: () => {
        if (mobileExpandViewRef.current) {
          mobileExpandViewRef.current.collapse()
        }
      },
      expand: () => {
        if (mobileExpandViewRef.current) {
          mobileExpandViewRef.current.expand()
        }
      },
      toggle: () => {
        if (mobileExpandViewRef.current) {
          mobileExpandViewRef.current.toggle()
        }
      }
    }))

    const contentView = <ContentContainer>{children}</ContentContainer>

    return (
      <Container className={className}>
        {isDesktop ? (
          contentView
        ) : (
          <MobileExpandView
            ref={mobileExpandViewRef}
            {...mobileExpandViewProps}
          >
            {contentView}
          </MobileExpandView>
        )}
      </Container>
    )
  }
)

JourneyMapSettingsViewContainer.displayName = 'JourneyMapSettingsViewContainer'

export default JourneyMapSettingsViewContainer
