import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  'data-testid'?: string
  children: ReactNode | ReactNode[]
  onClick?: () => void
  className?: string
  color?: string
  disabled?: boolean
}

const Link = (props: IProps) => {
  const { children, onClick, className, disabled } = props
  return (
    <button
      data-testid={props['data-testid']}
      className={className}
      onClick={!disabled ? onClick : (): void => undefined}
      tabIndex={0}
      role={'link'}
    >
      {children}
    </button>
  )
}

const Wrapper = styled(Link)<{ $color?: string; $disabled?: boolean }>`
  display: inline-block;
  outline: none;
  color: ${props =>
    props.$color ||
    (props.$disabled ? props.theme.colors.main40 : props.theme.colors.main100)};
  border: none;
  cursor: ${props => (props.$disabled ? 'initial' : 'pointer')};
  font-weight: normal;
  line-height: 100%;
  text-align: center;
  background: none;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: ${props => (props.$disabled ? 'none' : 'underline')};
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: ${props => (props.$disabled ? 'none' : 'underline')};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 17px;
        `
      : css`
          font-size: 14px;
        `}
`

export default Wrapper
