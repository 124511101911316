import { useQuery } from '@apollo/client'
import { querySurveySummaries } from 'src/graphql/queries'
import { useState } from 'react'
import { getSurveySummaries } from 'src/utils/surveySummaries'

export interface ISurveyResultsResponse {
  label: string
  value: number
}

export type SurveyNpsScaleFieldType = 'detractor' | 'passive' | 'promoter'

export interface ISurveyNpsScaleField {
  type: SurveyNpsScaleFieldType
  value: number
  startIndex: number
  length: number
  order: number
}

export type SurveyChartType = 'Columns' | 'NetScore'

export interface ISurveyResults {
  text: string
  description: string
  chart: SurveyChartType
  responses: ISurveyResultsResponse[]
  totalResponsesCount: number
  npsScaleFields: ISurveyNpsScaleField[]
  npsScore: number
}

export interface ISurveySummary {
  survey: ISurvey
  results: ISurveyResults[]
}

export interface IUseSurveySummaries {
  loading: boolean
  error: any
  surveySummaries: ISurveySummary[]
}

const useSurveySummaries = (
  type: string,
  countryCode: string
): IUseSurveySummaries => {
  const key = `${type}-${countryCode}`
  const [summaries, setSummaries] = useState<any>({})

  const returnValue: IUseSurveySummaries = {
    loading: false,
    error: null,
    surveySummaries: []
  }

  const skip = !!summaries[key]

  const { loading, error, data } = useQuery(querySurveySummaries, {
    fetchPolicy: 'network-only',
    skip,
    variables: { type, countryCode }
  })

  if (skip) {
    returnValue.surveySummaries = summaries[key]
    return returnValue
  }

  if (loading) {
    returnValue.loading = true
    return returnValue
  }

  if (error) {
    returnValue.error = error
    return returnValue
  }

  if (data) {
    returnValue.surveySummaries = getSurveySummaries(data.surveySummaries)
    setSummaries({ ...summaries, [key]: returnValue.surveySummaries })
  }

  return returnValue
}

export default useSurveySummaries
