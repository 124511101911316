import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import PlansList from './components/PlansList'
import UserProfileContext, {
  IUserProfileContext
} from 'src/features/UserProfile/Context'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  width: 100%;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin-bottom: 32px;
        `
      : css`
          font-size: 14px;
          margin-bottom: 16px;
        `}
`

const PlanHistory = React.memo((props: IProps) => {
  const { className } = props
  const { user } = useContext<IUserProfileContext>(UserProfileContext)
  const { t } = useTranslation()

  if (!user) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  return (
    <Container className={className}>
      <Text>{t('planHistory.text')}</Text>
      <PlansList />
    </Container>
  )
})

PlanHistory.displayName = 'PlanHistory'

export default PlanHistory
