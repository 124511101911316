import React, { ReactNode } from 'react'
import styled from 'styled-components'
import FieldCardLabel from 'src/components/FieldCard/FieldCardLabel'
import FieldCardIcon from 'src/components/FieldCard/FieldCardIcon'
import { IIcon } from 'src/react-app-env'

interface IProps {
  children: ReactNode
  label: string
  icon?: IIcon
  className?: any
  contentContainerMinHeight?: number
  addsLeftMargin?: boolean
  ariaHidden?: boolean
}

const Container = styled.div<{ $addsLeftMargin: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.$addsLeftMargin ? 'calc(100% - 40px)' : '100%')};
  margin-left: ${props => (props.$addsLeftMargin ? '40px' : '0px')};
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`

const LabelWrapper = styled(FieldCardLabel)`
  &:not(:first-child) {
    margin-left: 16px;
  }
`

const ContentContainer = styled.div<{
  $minHeight?: number
  $hasLeftMargin: boolean
}>`
  display: flex;
  max-width: 100%;
  min-height: ${props => (props.$minHeight ? props.$minHeight + 'px' : '0px')};
  margin-left: ${(props: any) => (props.$hasLeftMargin ? '40px' : '0')};
  margin-top: 4px;
`

const FieldCard = React.memo((props: IProps) => {
  const {
    children,
    label,
    icon,
    className,
    contentContainerMinHeight,
    addsLeftMargin,
    ariaHidden
  } = props
  return (
    <Container className={className} $addsLeftMargin={addsLeftMargin}>
      <TitleContainer>
        {icon && <FieldCardIcon icon={icon} />}
        <LabelWrapper ariaHidden={ariaHidden}>{label}</LabelWrapper>
      </TitleContainer>
      <ContentContainer
        $hasLeftMargin={!!icon}
        $minHeight={contentContainerMinHeight}
      >
        {children}
      </ContentContainer>
    </Container>
  )
})

FieldCard.displayName = 'FieldCard'

export default FieldCard
