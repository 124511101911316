import React from 'react'
import styled, { css } from 'styled-components'
import { IDialogButton } from 'src/react-app-env'
import { Button } from 'src/UIKit'

const StyledButton = styled(Button)<{ $minWidth: string }>`
  padding: 0 24px;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${props.$minWidth || '200px'};

          &:not(:first-child) {
            margin-left: 16px;
          }
        `
      : css`
          width: 100%;
          margin: 6px;
        `}
`

const DialogButton = React.memo((props: IDialogButton) => {
  const { title, appearance, onClick, disabled, minWidth } = props

  return (
    <StyledButton
      appearance={appearance}
      onClick={onClick}
      disabled={disabled}
      $minWidth={minWidth}
    >
      {title}
    </StyledButton>
  )
})

DialogButton.displayName = 'DialogButton'
export default DialogButton
