import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  children?: ReactNode
  color?: any
}

const Wrapper = styled.p<{ $color: string }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${props => props.$color || props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`

const Text = React.memo((props: IProps) => (
  <Wrapper $color={props.color}>{props.children}</Wrapper>
))

Text.displayName = 'Text'

export default Text
