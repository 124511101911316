import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  getDueDate,
  getDueDateLabelKey,
  getLeaveStartDate,
  getLeaveEndDate,
  getLeaveGradualReturnStartDate,
  getActiveDutyEndDate
} from 'src/utils/leaveUtils'
import LeaveInfoViewRow from './components/LeaveInfoViewRow'
import { DATE_FORMAT, isBlankDate } from 'src/utils/dateUtils'
import moment from 'moment'

interface IProps {
  className?: string
  leave: ILeave
  hidesLeaveType?: boolean
}

const Container = styled.div`
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 380px;
        `
      : css`
          width: 100%;
        `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  font-weight: 500;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
  background: ${props => props.theme.colors.dark05};
  border-radius: 8px 8px 0 0;
`

const RowsContainer = styled.div`
  padding: 0 12px;
`

const LeaveInfoView = React.memo((props: IProps) => {
  const { leave, className, hidesLeaveType } = props
  const { type } = leave
  const { t } = useTranslation()

  const leaveTypeValue: string = t(`types.${type}`)

  const leaveTypeView: ReactNode = useMemo(() => {
    if (hidesLeaveType) {
      return null
    }

    return (
      <LeaveInfoViewRow
        title={t('planPage.leaveType')}
        value={leaveTypeValue}
      />
    )
  }, [t, hidesLeaveType, leaveTypeValue])

  const leaveStartDateView: ReactNode = useMemo(
    () => (
      <LeaveInfoViewRow
        title={t(`planPage.leaveStartDate`, { context: type })}
        value={getLeaveStartDate(leave).current.format(DATE_FORMAT)}
      />
    ),
    [leave, type, t]
  )

  const dueDateView: ReactNode = useMemo(() => {
    if (!leave.dueDate) {
      return null
    }

    const key: string = getDueDateLabelKey(leave)
    return (
      <LeaveInfoViewRow
        title={t(key)}
        value={getDueDate(leave).current.format(DATE_FORMAT)}
      />
    )
  }, [leave, t])

  const leaveStartOfGradualReturnStartDateView: ReactNode = useMemo(() => {
    if (
      !leave.dates.gradualReturnStartDate?.current ||
      isBlankDate(moment(leave.dates.gradualReturnStartDate?.current))
    ) {
      return null
    }

    return (
      <LeaveInfoViewRow
        title={t('planPage.leaveStartOfGradualReturnStartDate')}
        value={getLeaveGradualReturnStartDate(leave).current.format(
          DATE_FORMAT
        )}
      />
    )
  }, [leave, t])

  const leaveEndDateView: ReactNode = useMemo(
    () => (
      <LeaveInfoViewRow
        title={t(`planPage.leaveEndDate`, { context: type })}
        value={
          type === 'Military'
            ? getActiveDutyEndDate(leave).current.format(DATE_FORMAT)
            : getLeaveEndDate(leave).current.format(DATE_FORMAT)
        }
      />
    ),
    [leave, type, t]
  )

  const recoveryLeaveEndDateView: ReactNode = useMemo(() => {
    if (type !== 'Military') {
      return null
    }

    if (
      getLeaveEndDate(leave).current.isSame(
        getActiveDutyEndDate(leave).current,
        'day'
      )
    ) {
      return null
    }

    return (
      <LeaveInfoViewRow
        title={t(`planPage.reinstatementPeriodEndDate`)}
        value={getLeaveEndDate(leave).current.format(DATE_FORMAT)}
      />
    )
  }, [leave, type, t])

  return (
    <Container className={className}>
      <TitleContainer>{t('planPage.infoTitle')}</TitleContainer>
      <RowsContainer>
        {leaveTypeView}
        {leaveStartDateView}
        {dueDateView}
        {leaveStartOfGradualReturnStartDateView}
        {leaveEndDateView}
        {recoveryLeaveEndDateView}
      </RowsContainer>
    </Container>
  )
})

LeaveInfoView.displayName = 'LeaveInfoView'

export default LeaveInfoView
