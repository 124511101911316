import { useState, useEffect } from 'react'

const getStorageValue = (key: string, defaultValue: any) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || defaultValue
  } catch (e) {
    return undefined
  }
}

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue))

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
