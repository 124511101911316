import BabyBonding from './BabyBonding'
import ExtendedFamilyCare from './ExtendedFamilyCare'
import FamilyCare from './FamilyCare'
import LongTermDisability from './LongTermDisability'
import Military from './Military'
import Miscarriage from './Miscarriage'
import Other from './Other'
import PaidMedical from './PaidMedical'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import Reinstatement from './Reinstatement'
import Sickness from './Sickness'
import Unknown from './Unknown'

export default {
  BabyBonding,
  ExtendedFamilyCare,
  FamilyCare,
  LongTermDisability,
  Military,
  Miscarriage,
  Other,
  PaidMedical,
  PreDelivery,
  Recovery,
  Reinstatement,
  Sickness,
  Unknown
}
