import React from 'react'
import styled from 'styled-components'
import notAvailable from 'src/assets/images/not-available.svg'
import { useTranslation } from 'react-i18next'

const NotAvailableImg = styled.img`
  width: 100%;
`

export const dataAttrs = {
  component: () => 'not-available-img'
}

const NotAvailableView = React.memo(() => {
  const { t } = useTranslation()

  return (
    <NotAvailableImg
      data-testid={dataAttrs.component()}
      src={notAvailable}
      alt={t('common.accessibilityText.notAvailable')}
    />
  )
})

NotAvailableView.displayName = 'NotAvailableView'

export default NotAvailableView
