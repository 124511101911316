import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ITEM_COMPLETE_ANIMATION_DURATION } from 'src/features/JourneyMap/constants'

interface IProps {
  className?: string
  fadesOut: boolean
  animatesMarginTop: boolean
  children: string
  useShortTopMargin?: boolean
}

const fadeOutKeyFrames = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const marginTopToKeyFrames = (props: any) => keyframes`
  0% {
  }
  100% {
    ${
      props.theme.isDesktop
        ? css`
            margin-top: 16px;
          `
        : css`
            margin-top: 12px;
          `
    }
  }
`

const Title = styled.span<{
  $fadesOut: boolean
  $animatesMarginTop: boolean
  $useShortTopMargin: boolean
}>`
  display: block;
  font-weight: normal;
  line-height: 100%;
  text-align: center;
  color: ${props => props.theme.colors.main90};
  background: ${props => props.theme.colors.backgroundColor};
  border-radius: 16px;
  border: 1px dotted ${props => props.theme.colors.main90};
  padding: 4px 8px;
  width: fit-content;

  ${props =>
    props.$fadesOut &&
    css`
      animation: ${fadeOutKeyFrames} ${ITEM_COMPLETE_ANIMATION_DURATION}ms
        forwards;
    `}

  ${props =>
    props.$animatesMarginTop &&
    css`
      animation: ${marginTopToKeyFrames} ${ITEM_COMPLETE_ANIMATION_DURATION}ms
        forwards;
    `}

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin: 24px auto 12px;

          &:first-child {
            ${props.$useShortTopMargin
              ? css`
                  margin-top: 24px;
                `
              : css`
                  margin-top: 104px;
                `}
          }
        `
      : css`
          font-size: 11px;
          margin: 16px auto 8px;

          &:first-child {
            margin-top: 8px;
          }
        `}
`

export const arePropsEqual = (prevProps: IProps, nextProps: IProps) =>
  prevProps.children === nextProps.children &&
  prevProps.fadesOut === nextProps.fadesOut &&
  prevProps.animatesMarginTop === nextProps.animatesMarginTop &&
  prevProps.useShortTopMargin === nextProps.useShortTopMargin

const JourneyMapMonthSectionTitle = React.memo((props: IProps) => {
  const { children, fadesOut, animatesMarginTop, useShortTopMargin } = props

  return (
    <Title
      $fadesOut={fadesOut}
      $animatesMarginTop={animatesMarginTop}
      $useShortTopMargin={useShortTopMargin}
      aria-hidden
    >
      {children}
    </Title>
  )
}, arePropsEqual)

JourneyMapMonthSectionTitle.displayName = 'JourneyMapMonthSectionTitle'

export default JourneyMapMonthSectionTitle
