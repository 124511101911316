import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'

const {
  colors: { main40, main100, main20 }
} = createTheme()

interface IProps {
  className?: string
  onClick: () => void
  isIncrement: boolean
  disabled: boolean
}

const plusSvg = (color: string) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9025 7.9971V6.00306H7.99702V0.0976064H6.00298V6.00306H0.0975251V7.9971H6.00298V13.9026H7.99702V7.9971H13.9025Z"
      fill={color}
    />
  </svg>
)

const minusSvg = (color: string) => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9025 1.9971V0.00306084H0.0975251V1.9971H13.9025Z"
      fill={color}
    />
  </svg>
)

const Container = styled.button<{ $disabled: boolean }>`
  border-radius: 50%;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${main100};
  cursor: pointer;
  transition: all 100ms linear;

  ${(props: any) => {
    if (props.$disabled) {
      return css`
        border-color: ${main40};
        cursor: initial;
      `
    } else {
      return css`
        &:hover {
          background: ${main20};
        }

        &:active {
          background: ${main20};
        }
      `
    }
  }}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
  }
`

const NumStepperButton = React.memo((props: IProps) => {
  const { onClick, isIncrement, disabled } = props
  const { t } = useTranslation()

  const sign: any = useMemo(() => {
    const color: string = disabled ? main40 : main100
    return isIncrement ? plusSvg(color) : minusSvg(color)
  }, [disabled, isIncrement])

  const ariaLabel: string = useMemo(
    () =>
      t(`numStepper.accessibility.${isIncrement ? 'increase' : 'decrease'}`),
    [isIncrement, t]
  )

  return (
    <Container $disabled={disabled} onClick={onClick} aria-label={ariaLabel}>
      {sign}
    </Container>
  )
})

NumStepperButton.displayName = 'NumStepperButton'

export default NumStepperButton
