import { Moment } from 'moment'
import { IUseDate } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/hooks/useDate'
import { ITimelinePeriod } from 'src/react-app-env'
import { PeriodKeyDate } from '../../../../periodsConfig'
import {
  getChanges,
  getDaysBetweenDates,
  getWeeksBetweenDates
} from '../methods'

export interface IPeriodKeyDatePickerMethodsInput {
  startDate?: IUseDate
  endDate?: IUseDate
  dueDate?: string
  date?: Moment
  initialSelectedDays?: number
  selectedDays?: number
  setSelectedDays?: (days: number) => void
  makeInvalid?: () => void
  makeValid?: () => void
  isSendingChanges?: boolean
  timelinePeriods?: ITimelinePeriod[]
  period?: ITimelinePeriod
  periodKeyDate?: PeriodKeyDate
  leave?: ILeave
  prevLeave?: ILeave
  setIsSendingChanges?: (value: boolean) => void
  onExit?: () => void
  onNewChanges?: (changes: any, forceUpdateLeave: boolean) => void
}
export interface IAnnualParams {
  annualMaxDays: number
  annualAvailableDays: number
  annualUsedDays: number
}

export const getMinWeeksCount = ({
  startDate,
  endDate,
  periodKeyDate
}: IPeriodKeyDatePickerMethodsInput): number =>
  getWeeksBetweenDates(
    periodKeyDate === 'periodStart' ? startDate.max : startDate.current,
    periodKeyDate === 'periodStart' ? endDate.current : endDate.min
  )

export const getMinDaysCount = ({
  startDate,
  endDate
}: IPeriodKeyDatePickerMethodsInput): number =>
  getDaysBetweenDates(startDate.current, endDate.min)

export const getAvailableWeeksCount = ({
  startDate,
  endDate,
  periodKeyDate
}: IPeriodKeyDatePickerMethodsInput): number =>
  getWeeksBetweenDates(
    periodKeyDate === 'periodStart' ? startDate.min : startDate.current,
    periodKeyDate === 'periodStart' ? endDate.current : endDate.max
  )

export const getAvailableDaysCount = ({
  startDate,
  endDate,
  periodKeyDate
}: IPeriodKeyDatePickerMethodsInput): number =>
  getDaysBetweenDates(
    periodKeyDate === 'periodStart' ? startDate.min : startDate.current,
    periodKeyDate === 'periodStart' ? endDate.current : endDate.max
  )

export const getSelectedDaysCount = ({
  startDate,
  endDate
}: IPeriodKeyDatePickerMethodsInput): number =>
  getDaysBetweenDates(startDate.current, endDate.current)

export const shouldExit = ({
  isSendingChanges,
  leave,
  prevLeave
}: IPeriodKeyDatePickerMethodsInput): boolean =>
  isSendingChanges && prevLeave !== leave

export const onStartDateChanged = ({
  date,
  startDate,
  makeInvalid,
  makeValid
}: IPeriodKeyDatePickerMethodsInput) => {
  makeInvalid()
  startDate.setCurrent(date)
  makeValid()
}

export const onEndDateChanged = ({
  date,
  endDate,
  startDate,
  setSelectedDays,
  makeInvalid,
  makeValid
}: IPeriodKeyDatePickerMethodsInput) => {
  makeInvalid()
  if (!endDate.current) {
    endDate.setCurrent(date)
  }
  setSelectedDays(date.diff(startDate.current, 'days') + 1)
  makeValid()
}

export const isNothingChanged = ({
  selectedDays,
  initialSelectedDays
}: IPeriodKeyDatePickerMethodsInput): boolean =>
  selectedDays === initialSelectedDays

export const onConfirm = ({
  onExit,
  onNewChanges,
  leave,
  timelinePeriods,
  period,
  selectedDays,
  dueDate
}: IPeriodKeyDatePickerMethodsInput) => {
  onExit()

  const changes: any = getChanges({
    timelinePeriods,
    period,
    duration: selectedDays,
    dueDate,
    startDate: leave.dates.leaveStart.current,
    leave
  })

  onNewChanges(changes, true)
}

export const onRemove = ({
  onExit,
  onNewChanges,
  leave,
  timelinePeriods,
  period,
  dueDate
}: IPeriodKeyDatePickerMethodsInput) => {
  onExit()

  const changes: any = getChanges({
    timelinePeriods,
    period,
    duration: 0,
    dueDate,
    startDate: leave.dates.leaveStart.current,
    leave
  })

  onNewChanges(changes, true)
}

export const getCanBeRemoved = (period: ITimelinePeriod): boolean =>
  period.timelineConfig.removable && period.appearance !== 'Addable'

export const getAsWeeksPicker = (period: ITimelinePeriod): boolean =>
  period.timelineConfig.periodPickerAsWeeksPicker

export const getAsDaysPicker = (period: ITimelinePeriod): boolean =>
  period.type === 'Annual'

export const getAnnualParams = (
  leave: ILeave,
  period: ITimelinePeriod
): IAnnualParams => {
  const result: IAnnualParams = {
    annualMaxDays: 0,
    annualAvailableDays: 0,
    annualUsedDays: 0
  }

  if (period.type === 'Annual') {
    result.annualMaxDays = leave.metadata.annualDurationMax
    result.annualAvailableDays = leave.metadata.annualDurationAvailable
    result.annualUsedDays = leave.metadata.annualDurationUsed
  }
  return result
}

export const getAsMaxSelector = (period: ITimelinePeriod): boolean =>
  period.type === 'UnpaidParental' ||
  period.type === 'AdditionalMaternity' ||
  period.type === 'StatutoryParents' ||
  period.type === 'AdditionalAdoption'
