import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { IMomentCurrentMinMax, ITimelinePeriod } from 'src/react-app-env'
import useComponentRect, { IRect } from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import usePrevious from 'src/components/hooks/usePrevious'
import { isCertificationRequired } from 'src/utils/leaveUtils'
import { isBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { getChanges } from '../methods'
import { isBlankDate } from 'src/utils/dateUtils'
import { useResizeDetector } from 'react-resize-detector'
import InfoMessageView from '../components/InfoMessageView'
import DescriptionText from '../components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { AlertsContainer, StyledDatePicker } from '../components/Datepickers'
import { ContentContainer } from '../components/ContentContainer'
import Buttons from '../components/Buttons'

interface IProps {
  className?: string
  period: ITimelinePeriod
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${Math.max(0, $y - $containerHeight)}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          margin: 0 auto;
        `}
  ${mobileMaxWidthMixin};
`

const TitleWrapper = styled(Title)`
  margin: 0 16px 20px;
`

const DescriptionTextWrapper = styled(DescriptionText)`
  width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 8px 24px 0;
        `}
`

const ChangeBirthDateButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  ${props =>
    props.theme.isMobile &&
    css`
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      margin: 0 16px;
      margin-bottom: 80px;
    `}
`

const ChangeBirthDateButton = styled(Button)`
  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      font-size: 16px;
      height: 48px;
    `}
`

const InfoMessageWrapper = styled.div`
  width: 100%;
  margin: 10px 16px;
`

/**
 * Picker that performs a change to a single date ignoring rest of the timeline.
 * Supported periods must be added explicitly.
 */
const LeaveDateSinglePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    dueDate,
    onDueDateChanged,
    leaveStartDate,
    onStartDateChanged,
    leaveEndDate,
    onLeaveEndDateChanged,
    disabilityEndDate,
    onDisabilityEndDateChanged,
    onChangeBirthDate,
    birthDate,
    leave,
    onNewChanges,
    timelinePeriods,
    leaveHolidays,
    onCalendarActiveDateChanged,
    hasTimelineChanges,
    updateLeave
  } = context
  const { status, isDirty } = leave
  const { y, alignsToTop, bottomOffset, onExit } = props
  const period = props.period
  const { type } = period
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const [initialDate, setInitialDate] = useState<Moment>(null)
  const [currentDate, setCurrentDate] = useState<Moment>(null)

  const [isSendingDate, setIsSendingDate] = useState(false)
  const prevLeave: ILeave = usePrevious(leave)
  const hadTimelineChanges: boolean = usePrevious(hasTimelineChanges)

  const containerRef: any = useRef(null)
  const containerRect: IRect = useComponentRect(containerRef)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    setInitialDate(null)
  }, [type])

  useEffect(() => {
    if (!hasTimelineChanges && hadTimelineChanges) {
      setInitialDate(null)
    }
  }, [hasTimelineChanges, hadTimelineChanges])

  let momentCurrentMinMax: IMomentCurrentMinMax
  let onChange: (v: any) => void
  let onConfirm: () => void
  let keyDate = null
  let shouldShowChangeBirthDateButton = false

  const alerts: string[] = Array.from(period.timelineConfig.alerts)
  const canBeRemoved = useMemo(() => period.timelineConfig.removable, [period])
  const onRemove = useCallback(() => {
    onExit()
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: 0,
      dueDate: dueDate.current.toISOString(),
      startDate: leaveStartDate.current.toISOString(),
      leave
    })
    onNewChanges(changes, true)
  }, [
    timelinePeriods,
    leave,
    leaveStartDate,
    onNewChanges,
    onExit,
    period,
    dueDate
  ])

  switch (type) {
    case 'PregnancyLossDate':
      momentCurrentMinMax = timelinePeriods.find(
        (p: ITimelinePeriod) => p.hash === period.hash
      ).periodStart
      onChange = onDueDateChanged
      onConfirm = () =>
        updateLeave({ dueDate: currentDate.toDate().toISOString() })
      break
    case 'BirthDate':
    case 'DueDate':
      shouldShowChangeBirthDateButton = isBabyArrivedLeave(status)
      momentCurrentMinMax = shouldShowChangeBirthDateButton
        ? birthDate
        : dueDate
      onChange = onDueDateChanged
      onConfirm = () =>
        updateLeave({ dueDate: currentDate.toDate().toISOString() })
      break
    case 'StartDate':
      momentCurrentMinMax = leaveStartDate
      onChange = onStartDateChanged
      onConfirm = () =>
        updateLeave({ startDate: currentDate.toDate().toISOString() })
      if (isCertificationRequired(leave, type)) {
        alerts.push(t('preAdditionDelivery'))
      }
      break
    case 'PreDelivery':
      momentCurrentMinMax = leaveStartDate
      onChange = onStartDateChanged
      onConfirm = () =>
        updateLeave({ startDate: currentDate.toDate().toISOString() })
      if (isCertificationRequired(leave, type)) {
        alerts.push(t('preAdditionDelivery'))
      }
      keyDate = 'start'
      break
    case 'BabyBonding':
      momentCurrentMinMax = leaveEndDate
      onChange = onLeaveEndDateChanged
      onConfirm = () =>
        updateLeave({ endDate: currentDate.toDate().toISOString() })
      keyDate = 'end'
      break
    case 'Recovery':
      momentCurrentMinMax = disabilityEndDate
      onChange = onDisabilityEndDateChanged
      onConfirm = () =>
        updateLeave({
          extra: { disabilityEndDate: currentDate.toDate().toISOString() }
        })
      if (isCertificationRequired(leave, type)) {
        alerts.push(t('postAdditionRecovery'))
      }
      keyDate = 'end'
      break
    case 'UnpaidMedical':
    case 'UnpaidPersonal':
      momentCurrentMinMax = timelinePeriods.find(
        (p: ITimelinePeriod) => p.hash === period.hash
      ).periodEnd
      onChange = onLeaveEndDateChanged
      onConfirm = () =>
        updateLeave({ endDate: currentDate.toDate().toISOString() })
      keyDate = 'end'
      break
    default:
      throw Error(`LeaveDateSinglePicker does not support period type: ${type}`)
  }

  useEffect(() => {
    if (isSendingDate && prevLeave !== leave) {
      requestAnimationFrame(() => {
        onExit()
      })
    }
  }, [isSendingDate, leave, prevLeave, onExit])

  const changeBirthDateButton: any = shouldShowChangeBirthDateButton && (
    <ChangeBirthDateButtonContainer>
      <ChangeBirthDateButton
        onClick={onChangeBirthDate}
        appearance={isDesktop ? 'unbordered' : 'default'}
        disabled={isDirty}
      >
        {t('babyHasArrived.calendarButtonChangeTitle')}
      </ChangeBirthDateButton>
    </ChangeBirthDateButtonContainer>
  )

  const onDateChange = useCallback(
    (d: any) => {
      if (!initialDate) {
        setInitialDate(momentCurrentMinMax.current)
      }

      setCurrentDate(d)
      onChange(d)
    },
    [initialDate, onChange, momentCurrentMinMax]
  )

  const onCancel = useCallback(() => {
    onExit()
    if (!isBlankDate(initialDate)) {
      setIsSendingDate(true)
      onDateChange(initialDate)
    }
  }, [onExit, onDateChange, initialDate])

  const buttonsView: ReactNode = useMemo(() => {
    const hasChanges: boolean =
      !!initialDate && !momentCurrentMinMax.current.isSame(initialDate, 'day')
    const showRemoveButton =
      !hasChanges && canBeRemoved && period.appearance !== 'Addable'
    return (
      <Buttons
        hasChanges={hasChanges}
        isConfirmDisabled={false}
        onCancel={onCancel}
        onClose={onExit}
        onConfirm={() => {
          onExit()
          onConfirm()
        }}
        onRemove={showRemoveButton ? onRemove : null}
      />
    )
  }, [
    canBeRemoved,
    initialDate,
    momentCurrentMinMax,
    onCancel,
    onConfirm,
    onExit,
    onRemove,
    period.appearance
  ])

  const onResize = useCallback(() => {
    if (isDesktop && !alignsToTop) {
      forceUpdate()
    }
  }, [forceUpdate, isDesktop, alignsToTop])

  useResizeDetector({
    targetRef: containerRef,
    handleHeight: true,
    onResize
  })

  const getBottomOffset = useCallback(
    () =>
      !alignsToTop
        ? Math.max(bottomOffset, containerRect.height - y)
        : bottomOffset,
    [alignsToTop, bottomOffset, containerRect.height, y]
  )

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t, leave)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [period, t, leave])

  const title = useMemo(
    () =>
      t(`timeline.periodPicker.title.${keyDate ? 'date' : 'period'}`, {
        periodName: period.timelineConfig.periodName,
        keyDate
      }),
    [t, period, keyDate]
  )

  const datePickerView: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        title={t(`timeline.periodPicker.periodType.${period.type}`)}
        disabled={shouldShowChangeBirthDateButton}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        momentCurrentMinMax={momentCurrentMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onDateChange}
        holidays={leaveHolidays}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
        }}
      />
    ),
    [
      t,
      momentCurrentMinMax,
      onDateChange,
      leaveHolidays,
      onCalendarActiveDateChanged,
      shouldShowChangeBirthDateButton,
      period
    ]
  )

  return (
    <Container
      ref={containerRef}
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerRect.height}
      role={'region'}
      aria-label={t('timeline.accessibility.regionLeaveDurationSettings')}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        {period.timelineConfig.datePickerDescription && (
          <DescriptionTextWrapper>
            {period.timelineConfig.datePickerDescription}
          </DescriptionTextWrapper>
        )}
        {datePickerView}
        {changeBirthDateButton}
        {infoMessageView}
        {buttonsView}
      </ContentContainer>
      <ArrowView alignsToTop={alignsToTop} bottomOffset={getBottomOffset()} />
    </Container>
  )
})

LeaveDateSinglePicker.displayName = 'LeaveDateSinglePicker'

export default LeaveDateSinglePicker
