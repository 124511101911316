import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import { createLeaveButtons } from 'src/constants/frame'

interface IProps {
  backTitle: string
  onBackClick: () => void
  nextTitle: string
  onNextClick: () => void
  isNextDisabled: boolean
  nextAriaLabel?: string
  backButtonRef?: any
  hidesBackButton?: boolean
  hidesNextButton?: boolean
}

export const dataAttrs = {
  buttonNext: () => 'create-leave-button-next',
  buttonCancel: () => 'create-leave-button-cancel'
}

const ButtonsContainer = styled.div<{ $centerItems: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.$centerItems ? 'center' : 'space-between'};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 48px;
          width: calc(${createLeaveButtons.width().px} + 16px);
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
          padding: 16px 0;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)<any>`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: ${props.mobileFullWidth ? '100%' : 'calc(50% - 8px)'};
          height: 48px;
          font-size: 16px;
        `}
`

export const CreateLeaveButtons = React.memo((props: IProps) => {
  const {
    backTitle,
    onBackClick,
    nextTitle,
    onNextClick,
    isNextDisabled,
    nextAriaLabel,
    backButtonRef,
    hidesBackButton,
    hidesNextButton
  } = props

  return (
    <ButtonsContainer $centerItems={hidesBackButton || hidesNextButton}>
      {!hidesBackButton && (
        <ButtonWrapper
          data-testid={dataAttrs.buttonCancel()}
          appearance={'cancel'}
          onClick={onBackClick}
          ref={backButtonRef}
          mobileFullWidth={hidesNextButton}
        >
          {backTitle}
        </ButtonWrapper>
      )}
      {!hidesNextButton && (
        <ButtonWrapper
          data-testid={dataAttrs.buttonNext()}
          onClick={onNextClick}
          disabled={isNextDisabled}
          ariaLabel={nextAriaLabel}
          mobileFullWidth={hidesBackButton}
        >
          {nextTitle}
        </ButtonWrapper>
      )}
    </ButtonsContainer>
  )
})

CreateLeaveButtons.displayName = 'CreateLeaveButtons'

export default CreateLeaveButtons
