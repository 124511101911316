import React, { useMemo } from 'react'
import { Icon } from 'src/UIKit'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import { Table, Th, Td } from '../Table'

const { colors } = createTheme()

interface IProps {
  programs: IPeriodLeaveProgram[]
}

const Title = styled.span`
  display: block;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 20px;
          line-height: 23px;
          margin: 8px 0 20px 12px;
        `
      : css`
          font-size: 16px;
          line-height: 19px;
          margin: 8px 0 20px;
        `}
`

export const dataAttrs = {
  title: () => 'program-list-title',
  tr: () => 'program-list-tr'
}

const ProgramList = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { programs } = props

  const getIcon = (option: any): React.ReactElement => {
    switch (option) {
      case 'Yes':
        return <Icon name={'checkmark'} settings={{ fill: colors.success }} />
      case 'No':
        return (
          <Icon name={'close_crossing'} settings={{ fill: colors.purple }} />
        )
      case 'None':
        return <Icon name={'minus'} />
      default:
        return null
    }
  }

  const rows = useMemo(
    () =>
      programs.map((program, index) => (
        <tr data-testid={dataAttrs.tr()} key={index}>
          <Td>{program.name}</Td>
          <Td
            $textAlign="center"
            aria-label={t('common.accessibilityText.programPayment', {
              item: program.payment
            })}
          >
            {getIcon(program.payment)}
          </Td>
          <Td
            $textAlign="center"
            aria-label={t('common.accessibilityText.programJobProtection', {
              item: program.jobProtection
            })}
          >
            {getIcon(program.jobProtection)}
          </Td>
        </tr>
      )),
    [t, programs]
  )
  return (
    <div>
      <Title data-testid={dataAttrs.title()}>{t('common.leaveDetails')}</Title>
      <Table>
        <colgroup>
          <col width="70%" />
          <col width="10%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <tr data-testid={dataAttrs.tr()}>
            <Th>{t('common.programs.name')}</Th>
            <Th>{t('common.programs.pay')}</Th>
            <Th>{t('common.programs.jobProtection')}</Th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  )
})

ProgramList.displayName = 'ProgramList'

export default ProgramList
