import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import Info from 'src/features/Timeline/components/common/Info'
import Buttons from 'src/features/Timeline/components/common/Buttons'
import RequestClaimUpdateButton from 'src/features/Timeline/components/common/RequestClaimUpdateButton'
import { useTranslation } from 'react-i18next'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import SubtypeSelector from 'src/features/Timeline/components/common/SubtypeSelector'
import zIndex from 'src/constants/zIndex'
import {
  shouldShowBabyArrivedButton,
  shouldShowSubtypeSelector,
  miscarriageSupported
} from 'src/utils/leaveUtils'
import LeaveApprovalSelector from 'src/features/Timeline/components/common/LeaveApprovalSelector'
import LeaveHeading from 'src/components/LeaveHeading'
import { isRotatingSchedule } from 'src/utils/dateUtils'
import ScreenContext from 'src/contexts/ScreenContext'
import Popover from 'src/components/Popover'
import ControlMenuView, {
  ControlMenuViewItemType,
  IControlMenuViewItem
} from './components/ControlMenuView'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import {
  userProfileRoute,
  somethingHasChangedRoute
} from 'src/routes/constants'
import { isFirefox } from 'src/utils/deviceTypes'
import { isDraftLeave, isNewLeave } from 'src/utils/leaveStatusUtils'
import { SECTION_CLOSE_PLAN } from 'src/utils/anchors'
import SupplementPayControlBar from 'src/features/Timeline/components/common/SupplementPayControlBar'
import SharedContext from 'src/contexts/SharedContext'
import VacationBalanceEditor from 'src/features/Timeline/components/common/VacationBalanceEditor'
import SyncStatusView from 'src/components/SyncStatusView'
import { isBondingPeriodInPast } from 'src/utils/periodUtils'

const { colors } = createTheme()

interface IProps {
  timelineHeaderRef: any
}

const Container = styled.div<any>`
  background: ${props => props.theme.colors.light95};
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  padding: 16px 32px;
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  max-height: 128px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${zIndex.timeline.vertical.header};
`

const ButtonsWrapper = styled(Buttons)<{ $isShowButtons: boolean }>`
  flex: 1;
  padding-top: 0;
  display: ${props => (props.$isShowButtons ? 'flex' : 'none')};
  transition: all 0.3s;
`

const SubtypeSelectorWrapper = styled(SubtypeSelector)`
  margin: 0 48px 0 0;
`

const LeaveApprovalSelectorWrapper = styled(LeaveApprovalSelector)`
  margin: 0 48px 0 0;
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 11px;
  row-gap: 12px;
`

const ControlMenuButton = styled.button`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 10px 0 24px;
        `
      : css`
          margin: 0 8px;
        `}
  position: relative;
  border: 2px solid transparent;
  border-radius: 24px;
  padding: 10px;

  &:hover {
    outline: none;
    background-color: ${props => props.theme.colors.main20};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main110};
  }
`

const ControlMenu = styled(Icon)<any>`
  display: flex;
  align-items: center;
  cursor: ${(props: any) => (props.clickable ? 'pointer' : 'unset')};
`

const SyncStatusViewWrapper = styled(SyncStatusView)`
  margin-left: 16px;
`

const StartJourneyLabel = styled.span`
  align-self: flex-start;
  padding: 0 10px;
  white-space: nowrap;
  user-select: none;
  font-size: 12px;
  font-weight: normal;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
`

const TimelineDesktopHeader = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    timelinePeriods,
    countryCode,
    subtypes,
    subtype,
    onLeaveSubtypeChanged,
    onUseSupplementPayChanged,
    onVacationBalanceChanged,
    totalLeaveDays,
    onBabyHasArrivedClicked,
    onApprovedByTpaChanged,
    isLeaveDurationPickerOpened,
    onRequestClaimUpdateClick
  } = context
  const { customerConfig } = useContext(SharedContext)
  const { type, approvedByTPA, metadata } = leave
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const popoverRef: any = useRef(null)
  const contextMenuRef: any = useRef(null)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(true)

  const isNewOrDraft: boolean =
    isNewLeave(leave.status) || isDraftLeave(leave.status)

  const isShowButtons: boolean = isNewOrDraft || leave.isDirty
  const canRequestClaimUpdate: boolean =
    !leave.isDirty &&
    metadata?.canRequestClaimUpdate &&
    !isLeaveDurationPickerOpened

  const showApprovedDuration: boolean =
    customerConfig.leave.timeline.showApprovedDuration(leave)
  const isShowSubtypeSelector = shouldShowSubtypeSelector(leave, subtypes)
  const useTpaApproval = customerConfig.leave.timeline.useTpaApproval(
    type,
    countryCode
  )

  const subtypeSelector: ReactNode = useMemo(
    () =>
      isShowSubtypeSelector && (
        <SubtypeSelectorWrapper
          subtype={subtype}
          subtypes={subtypes}
          onLeaveSubtypeChanged={onLeaveSubtypeChanged}
          appearance={'timeline'}
          disabled={isLeaveDurationPickerOpened}
        />
      ),
    [
      isShowSubtypeSelector,
      subtype,
      subtypes,
      onLeaveSubtypeChanged,
      isLeaveDurationPickerOpened
    ]
  )

  const leaveApprovalSelector: ReactNode = useMemo(
    () =>
      useTpaApproval && (
        <LeaveApprovalSelectorWrapper
          value={approvedByTPA}
          onValueChanged={onApprovedByTpaChanged}
          disabled={isLeaveDurationPickerOpened}
        />
      ),
    [
      useTpaApproval,
      approvedByTPA,
      onApprovedByTpaChanged,
      isLeaveDurationPickerOpened
    ]
  )

  const supplementPayControl: ReactNode = useMemo(
    () =>
      customerConfig.leave.timeline.hasSupplementPay(leave) && (
        <SupplementPayControlBar
          canUseSupplementPay={metadata?.canUseSupplementPay}
          useSupplementPay={metadata?.useSupplementPay}
          sickHoursToBeUsed={metadata?.sickHoursToBeUsed}
          vacationHoursToBeUsed={metadata?.vacationHoursToBeUsed}
          onUseSupplementPayChanged={onUseSupplementPayChanged}
          disabled={
            isLeaveDurationPickerOpened || !metadata?.canUseSupplementPay
          }
          leaveType={leave.type}
          leaveSubtype={leave.subtype}
        />
      ),
    [
      onUseSupplementPayChanged,
      metadata,
      isLeaveDurationPickerOpened,
      customerConfig,
      leave
    ]
  )

  const vacationBalanceEdit: ReactNode = useMemo(
    () =>
      customerConfig.leave.timeline.showVacationBalanceEdit(leave) && (
        <VacationBalanceEditor
          metadata={metadata}
          onVacationBalanceChanged={onVacationBalanceChanged}
          disabled={isLeaveDurationPickerOpened}
        />
      ),
    [
      customerConfig,
      isLeaveDurationPickerOpened,
      metadata,
      onVacationBalanceChanged,
      leave
    ]
  )

  const buttonsView: ReactNode = useMemo(
    () => <ButtonsWrapper $isShowButtons={isShowButtons} />,
    [isShowButtons]
  )

  const requestClaimUpdateButtonView: ReactNode = useMemo(
    () =>
      canRequestClaimUpdate && (
        <RequestClaimUpdateButton
          hasBackdatedPeriod={timelinePeriods.some((period: any) =>
            isBondingPeriodInPast(leave, period)
          )}
          disabled={!metadata?.hasChangesForTPA || metadata?.tpaRequestSent}
          tpaRequestSent={metadata?.tpaRequestSent}
          onClickCallback={onRequestClaimUpdateClick}
        />
      ),
    [
      canRequestClaimUpdate,
      metadata,
      leave,
      timelinePeriods,
      onRequestClaimUpdateClick
    ]
  )

  const infoView: any = useMemo(
    () =>
      !isRotatingSchedule(timelinePeriods) && (
        <Info
          buttons={null}
          metadata={metadata}
          totalLeaveDays={totalLeaveDays}
          onBabyArrivedClicked={
            shouldShowBabyArrivedButton(leave) ? onBabyHasArrivedClicked : null
          }
          timelinePeriods={timelinePeriods}
          isSlimBabyArrivedButton
          approvedByTPA={approvedByTPA}
          disabled={isLeaveDurationPickerOpened}
          useTpaApproval={useTpaApproval}
          isFirstBirthDateButton={
            !isShowSubtypeSelector && !useTpaApproval && !leave.unusedBonding
          }
          unusedBonding={leave.unusedBonding}
          showUnplannedBondingTooltipLessThan={
            customerConfig.leave.timeline.showUnplannedBondingTooltipLessThan
          }
          showApprovedDuration={showApprovedDuration}
        />
      ),
    [
      leave,
      metadata,
      totalLeaveDays,
      onBabyHasArrivedClicked,
      approvedByTPA,
      timelinePeriods,
      isLeaveDurationPickerOpened,
      showApprovedDuration,
      isShowSubtypeSelector,
      useTpaApproval,
      customerConfig.leave.timeline.showUnplannedBondingTooltipLessThan
    ]
  )

  const onContextMenuClick = useCallback(() => {
    popoverRef.current?.toggle()
  }, [popoverRef])

  const popoverView = useMemo(
    () => {
      const controlMenuItems: IControlMenuViewItem[] = [
        { type: 'print' },
        { type: 'close' }
      ]
      if (miscarriageSupported(leave)) {
        controlMenuItems.splice(1, 0, { type: 'somethingHasChanged' })
      }
      return (
        <Popover
          parent={contextMenuRef.current}
          ref={popoverRef}
          afterStateChanged={(value: boolean) => {
            setIsContextMenuOpen(value)
          }}
          offset={{
            top: isDesktop ? 5 : -116,
            left: isDesktop ? -14 : 0
          }}
        >
          <ControlMenuView
            items={controlMenuItems}
            onItemClick={(item: ControlMenuViewItemType) => {
              popoverRef.current?.toggle()

              if (item === 'print') {
                if (isFirefox()) {
                  window.print()
                } else {
                  try {
                    document.execCommand('print', false, null)
                  } catch (e) {
                    window.print()
                  }
                }
              }

              if (item === 'close') {
                navigate(userProfileRoute + '/' + SECTION_CLOSE_PLAN)
              }

              if (item === 'somethingHasChanged') {
                navigate(somethingHasChangedRoute)
              }
            }}
          />
        </Popover>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contextMenuRef,
      popoverRef,
      isContextMenuOpen,
      setIsContextMenuOpen,
      isDesktop
    ]
  )

  return (
    <Container
      ref={props.timelineHeaderRef}
      role={'region'}
      aria-label={t('timeline.accessibility.regionTimelineSettings')}
    >
      <TopRow>
        <LeftContainer>
          <LeaveHeading>{t(`types.${leave.type}`)}</LeaveHeading>
          {customerConfig.leave.tpaSync.showSyncStatus(leave) && (
            <SyncStatusViewWrapper tpa={leave?.tpa} />
          )}
        </LeftContainer>
        <RightContainer>
          {buttonsView}
          {requestClaimUpdateButtonView}
          {!isNewOrDraft && (
            <ControlMenuButton
              onClick={onContextMenuClick}
              ref={contextMenuRef}
              aria-haspopup
              aria-label={t('common.accessibilityText.contextMenu')}
              aria-expanded={isContextMenuOpen}
            >
              <ControlMenu name={'context_menu'} settings={colors.main100} />
              {popoverView}
            </ControlMenuButton>
          )}
        </RightContainer>
      </TopRow>
      {(subtypeSelector ||
        leaveApprovalSelector ||
        infoView ||
        supplementPayControl ||
        vacationBalanceEdit) && (
        <BottomRow>
          <ControlsContainer>
            {subtypeSelector}
            {leaveApprovalSelector}
            {supplementPayControl}
            {vacationBalanceEdit}
            {infoView}
          </ControlsContainer>
          {isNewOrDraft && (
            <StartJourneyLabel>
              {t('header.startJourneyText')}
            </StartJourneyLabel>
          )}
        </BottomRow>
      )}
    </Container>
  )
})

TimelineDesktopHeader.displayName = 'TimelineDesktopHeader'

export default TimelineDesktopHeader
