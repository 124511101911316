import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import PeriodKeyDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/PeriodKeyDatePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  removable: true,
  datePickerComponent: PeriodKeyDatePicker,
  datePickerDisableWeekends: true,
  periodPickerSelectedNotice: {
    show: true
  },
  detailsType:
    input.leave.type === 'Personal' ? 'AnnualPersonal' : input.period.type
})
