import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import useFocused from 'src/components/hooks/useFocused'
import useHovered from 'src/components/hooks/useHovered'
import { buttonWrapperAppearanceMixin } from 'src/theme/utils'

interface IProps {
  className?: string
  selected: boolean
  children: any
  disabled?: boolean
  appearance: string
  onClick: () => void
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
`

const RadioChoiceContainer = styled.div<{
  $hovered: boolean
  $focused: boolean
}>`
  position: relative;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.main100};
  box-sizing: border-box;

  ${props =>
    props.$hovered &&
    css`
      border: 1px solid ${props.theme.colors.main110};
    `}

  ${props =>
    props.$focused &&
    css`
      border: 2px solid ${props.theme.colors.main110};
    `}
`

const Circle = styled.div<{ $focused: boolean }>`
  position: absolute;
  top: ${props => (props.$focused ? '2px' : '3px')};
  left: ${props => (props.$focused ? '2px' : '3px')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 1;
  background: ${props => props.theme.colors.main100};
`

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  text-align: left;
`

const Wrapper = styled.button<any>`
  cursor: pointer;
  outline: none;
  user-select: none;
  display: inline-block;
  width: auto;
  min-height: 36px;
  padding: 0 12px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;

  ${buttonWrapperAppearanceMixin}
`

export const dataAttrs = {
  button: () => 'radio-choice-button'
}

const RadioChoiceButton = React.memo((props: IProps) => {
  const { children, className, onClick, selected, disabled, appearance } = props
  const ref: any = useRef(null)
  const focused: boolean = useFocused(ref)
  const hovered: boolean = useHovered(ref)

  const content = (
    <Content>
      <RadioChoiceContainer $hovered={hovered} $focused={focused}>
        {selected && <Circle $focused={focused} />}
      </RadioChoiceContainer>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Content>
  )

  return (
    <Wrapper
      data-testid={dataAttrs.button()}
      ref={ref}
      className={className}
      disabled={disabled}
      $disabled={disabled}
      $appearance={appearance || 'default'}
      onClick={onClick}
    >
      {content}
    </Wrapper>
  )
})

RadioChoiceButton.displayName = 'RadioChoiceButton'

export default RadioChoiceButton
