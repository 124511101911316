import React, { useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import zIndex from 'src/constants/zIndex'
import {
  mobileButtonMaxWidthMixin,
  printDisplayNoneMixin
} from 'src/theme/utils'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'

const { colors } = createTheme()

interface IProps {
  className?: string
  onClick: () => void
}

const DesktopContainer = styled.button<{ $dropsShadow: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 11px;
  background: rgb(255 255 255 / 90%);
  border-radius: 8px;
  z-index: ${zIndex.journeyMap.list.button};
  border: 2px solid transparent;
  transition: box-shadow 200ms ease-in-out;

  ${props =>
    props.$dropsShadow &&
    css`
      border: 2px solid ${props.theme.colors.dark10};
      box-sizing: border-box;
      box-shadow: 0 0 12px rgb(0 0 0 / 8%);
    `}

  ${printDisplayNoneMixin}

  &:focus {
    border: 2px solid ${props => props.theme.colors.main110};
    outline: none;
  }
`

const Text = styled.span<{ $hovered: boolean }>`
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin-left: 8px;
  transition: color 200ms ease-in-out;
  ${props =>
    props.$hovered
      ? css`
          color: ${props.theme.colors.main110};
        `
      : css`
          color: ${props.theme.colors.main100};
        `}
`

const IconContainer = styled(Icon)<{ $hovered: boolean }>`
  width: 20px;
  height: 20px;
  transition: transform 200ms ease-in-out;
  transform-origin: center;
  ${props =>
    props.$hovered
      ? css`
          transform: rotate(-6deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`

const MobileContainer = styled.div`
  height: 80px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  display: flex;
  z-index: ${zIndex.notifications.page.markAllAsReadWrapper};
`

export const MobileButton = styled.button`
  border: 1px solid ${props => props.theme.colors.main100};
  border-radius: 16px;
  background: transparent;
  outline: none;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  padding: 16px 0;
  width: calc(100% - 32px);

  ${mobileButtonMaxWidthMixin};

  &:active {
    transition: all 200ms;
    background: ${props => props.theme.colors.main40};
    color: ${props => props.theme.colors.light100};
  }

  color: ${props => props.theme.colors.main90};
`

export const UnarchiveButton = React.memo((props: IProps) => {
  const { className, onClick } = props
  const [hovered, setHovered] = useState(false)
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const title: string = useMemo(() => t('common.unarchive'), [t])

  return isDesktop ? (
    <DesktopContainer
      className={className}
      onClick={onClick}
      onMouseOver={() => {
        setHovered(true)
      }}
      onMouseOut={() => {
        setHovered(false)
      }}
      $dropsShadow={hovered}
      aria-label={title}
    >
      <IconContainer
        name={'archive'}
        $hovered={hovered}
        settings={{ fill: hovered ? colors.main110 : colors.main100 }}
      />
      <Text $hovered={hovered}>{title}</Text>
    </DesktopContainer>
  ) : (
    <MobileContainer>
      <MobileButton aria-label={title} onClick={onClick}>
        {title}
      </MobileButton>
    </MobileContainer>
  )
})

UnarchiveButton.displayName = 'UnarchiveButton'

export default UnarchiveButton
