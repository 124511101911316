export const getAnimationDuration = (count: number): number => 4000 / count
export const getAnimationDelay = (count: number): number => 1000 / count

export const createTopRoundedVerticalPath = (
  x: number,
  y: number,
  width: number,
  height: number,
  r: number
) =>
  `M${x} ${y} v${height + r} q0,-${r} ${r},-${r} h${
    width - r * 2
  } q${r},0 ${r},${r} v${-height - r} z`

export const createTopRoundedHorizontalPath = (
  x: number,
  y: number,
  width: number,
  height: number,
  r: number
) =>
  `M${x} ${y} h${width - r} q${r},0 ${r},${r} v${
    height - r * 2
  } q0,${r} ${-r},${r} h${-width + r} z`

const getMaxVerticalValue = (value: number): number => {
  if (value < 2) {
    return 2
  }

  let result: number = value + Math.ceil(value / 10)
  const dividedTimes: number = Math.max(0, Math.round(Math.log10(result)) - 1)
  if (value > 20) {
    result = Math.ceil(result / Math.pow(10, dividedTimes))
  }
  while (
    result % 7 !== 0 &&
    result % 5 !== 0 &&
    result % 4 !== 0 &&
    result % 3 !== 0
  ) {
    result++
  }
  return result * Math.pow(10, dividedTimes)
}

const getVerticalTicksCount = (value: number): number => {
  if (value > 20) {
    const dividedTimes: number = Math.max(0, Math.floor(Math.log10(value)) - 1)
    value = value / Math.pow(10, dividedTimes)
  }

  const divides: number[] = [3, 4, 5, 7, 2]

  for (const divide of divides) {
    if (value % divide === 0) {
      return divide
    }
  }

  return 2
}

const getVerticalTickValues = (count: number, max: number): number[] => {
  const step: number = max / count
  const result: number[] = [0]
  let current = 0

  for (let i = 0; i < count; i++) {
    result.push((current += step))
  }

  return result
}

export interface IVerticalAxisData {
  maxValue: number
  tickValues: number[]
}

export const getVerticalAxisData = (data: any[]): IVerticalAxisData => {
  const maxValue: number = getMaxVerticalValue(
    Math.max(...data.map((obj: any) => obj.value))
  )
  const ticksCount: number = getVerticalTicksCount(maxValue)
  const tickValues: number[] = getVerticalTickValues(ticksCount, maxValue)

  return { maxValue, tickValues }
}
