import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import HrAdminFilter, {
  IHrAdminFilter
} from 'src/features/HrAdmin/components/Card/Filter'

export interface IProps {
  title: string
  description?: string
  filters?: IHrAdminFilter[]
  className?: string
  rightLabel?: string
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom: 1px solid ${props.theme.colors.dark05};
          margin: 48px 0 16px;
        `
      : css`
          margin: 24px 0 16px;
        `}
`

const TopContainer = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
          margin: 0 0 8px;
        `
      : css`
          align-items: flex-start;
          margin: 0 0 8px;
        `}
`

const Title = styled.span`
  flex: 1;
  line-height: 100%;
  overflow: hidden;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 20px;
          min-height: 22px;
        `
      : css`
          font-size: 16px;
          font-weight: 500;
        `}
`

const Description = styled.p`
  line-height: 130%;
  overflow: hidden;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          margin: 0 0 16px;
        `
      : css`
          font-size: 12px;
        `}
`

const RightLabelText = styled.span`
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
        `}
`

export const HrAdminCardHeader = React.memo((props: IProps) => {
  const { title, description, filters, className, rightLabel } = props

  const filterViews: any[] = useMemo(
    () =>
      filters &&
      filters.map((filter: IHrAdminFilter) => (
        <HrAdminFilter
          key={filter.options[0] + filter.selectedIndex}
          {...filter}
        />
      )),
    [filters]
  )

  const rightTextView = useMemo(
    () => <RightLabelText>{rightLabel}</RightLabelText>,
    [rightLabel]
  )

  return (
    <Container className={className}>
      <TopContainer>
        <Title>{title}</Title>
        {filters ? filterViews : rightTextView}
      </TopContainer>
      {description && <Description>{description}</Description>}
    </Container>
  )
})

HrAdminCardHeader.displayName = 'HrAdminCardHeader'

export default HrAdminCardHeader
