import React, { useContext, useMemo, useRef, useState } from 'react'
import Accordion from 'src/components/Accordion'
import CountryDropdown from 'src/components/CountryDropdown'
import LeftSidebarSectionSeparator from 'src/features/HrAdmin/components/LeftSidebar/LeftSidebarSectionSeparator'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'
import styled from 'styled-components'
import { Button } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import useElementFocused from 'src/components/hooks/useElementFocused'
import { IIcon } from 'src/react-app-env'
import theme from 'src/theme'

const { colors } = theme()

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  min-width: calc(256px - 24px);
  width: calc(256px - 24px);
  padding: 16px 12px 0;
`

const BottomContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const ButtonWrapper = styled(Button)`
  max-width: 204px;
  font-size: 16px;
  margin: 0 14px 26px;
  color: ${props => props.theme.colors.main100};
`

export const dataAttrs = {
  feedbackBtn: () => 'feedback-btn'
}

export const LeftSidebar = React.memo(() => {
  const context: IHrAdminContext = useContext(HrAdminContext) || {}
  const {
    insights,
    surveys,
    lastClickedRootItem,
    countryCodes,
    selectedCountryCode,
    setSelectedCountryCode
  } = context
  const buttonRef: any = useRef(null)
  const [hovered, setHovered] = useState(false)
  const focused: boolean = useElementFocused(buttonRef.current)
  const { t } = useTranslation()

  const showCountryDropdown = useMemo(
    () => countryCodes?.length > 1,
    [countryCodes]
  )

  const showSurveys = useMemo(
    () => selectedCountryCode !== GLOBAL_COUNTRY,
    [selectedCountryCode]
  )

  const icon: IIcon = {
    iconName: 'message',
    iconProps: {
      fill: hovered || focused ? colors.main110 : colors.main100
    }
  }

  return (
    <Container>
      {showCountryDropdown && (
        <>
          <CountryDropdown
            countryCodes={countryCodes}
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
          />
          <LeftSidebarSectionSeparator />
        </>
      )}
      <Accordion {...insights} expanded={lastClickedRootItem === 'insights'} />
      {showSurveys && (
        <>
          <LeftSidebarSectionSeparator />
          <Accordion
            {...surveys}
            expanded={lastClickedRootItem === 'surveys'}
          />
        </>
      )}
      <BottomContainer>
        <ButtonWrapper
          data-testid={dataAttrs.feedbackBtn()}
          ref={buttonRef}
          appearance={'unbordered'}
          icon={icon}
          onClick={() =>
            window.open(t('hrAdmin.feedbackLink'), '_blank', 'noopener')
          }
          onMouseOver={() => {
            setHovered(true)
          }}
          onMouseOut={() => {
            setHovered(false)
          }}
        >
          {t('hrAdmin.submitFeedback')}
        </ButtonWrapper>
      </BottomContainer>
    </Container>
  )
})

LeftSidebar.displayName = 'LeftSidebar'

export default LeftSidebar
