import React from 'react'
import { Button, Icon } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'

interface IProps {
  isArchiveMode: boolean
  onPlansArchiveClick: () => void
}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;

  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: 8px;
      justify-self: center;
      width: 100%;
    `}
`

const IconContainer = styled(Icon)`
  margin-top: 24px;
`

const Text = styled.span`
  font-style: normal;
  line-height: 150%;
  text-align: center;
  margin: 16px 0 48px;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: 14px;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 8px 0 24px;
    `}
`

export const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
          margin: 0 16px;
        `}

  ${mobileButtonMaxWidthMixin};
`

const NoAbsencesView = React.memo((props: IProps) => {
  const { onPlansArchiveClick, isArchiveMode } = props
  const { t } = useTranslation()

  return (
    <Container>
      <IconContainer
        name={'pagick_unfortunately'}
        ariaLabel={t('common.accessibilityText.noAbsences')}
      />
      <Text>
        {t(
          `managerJourneyMap.${
            isArchiveMode ? 'emptyStateArchiveBody' : 'emptyStateBody'
          }`
        )}
      </Text>
      <ButtonContainer>
        <ButtonWrapper onClick={onPlansArchiveClick}>
          {t(
            `managerJourneyMap.${
              isArchiveMode ? 'backToPlans' : 'plansArchive'
            }`
          )}
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  )
})

NoAbsencesView.displayName = 'NoAbsencesView'

export default NoAbsencesView
