import { css } from 'styled-components'
import { isSafari } from 'src/utils/deviceTypes'

export default css`
  width: 13.2857% !important;
  ${isSafari() &&
  css`
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  `}
`
