import breakpoints from 'src/constants/breakpoints'
import { useMediaQuery } from 'react-responsive'

export interface IUseScreenResult {
  isMobile: boolean
  isDesktop: boolean
  isPortrait: boolean
  isLandscape: boolean
  isMobileLandscape: boolean
  i18Context: string
}

export default (): IUseScreenResult => {
  const isMobile: boolean = useMediaQuery({
    query: `only screen and (min-width: 0px) and (max-width: ${
      breakpoints.size.lg - 1
    }px)`
  })

  const isPortrait: boolean = useMediaQuery({
    query: '(orientation: portrait)'
  })

  const isLandscape: boolean = useMediaQuery({
    query: '(orientation: landscape)'
  })

  return {
    isMobile,
    isDesktop: !isMobile,
    isPortrait,
    isLandscape,
    isMobileLandscape: isMobile && isLandscape,
    i18Context: isMobile ? 'mobile' : 'desktop'
  }
}
