import React from 'react'
import styled, { css } from 'styled-components'

export interface IListItem {
  title: string
  action: () => void
}

interface IProps {
  className?: any
  items: IListItem[]
}

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
`

const ItemButton = styled.button<{ $isFirst: boolean; $isLast: boolean }>`
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: pointer;
  font-weight: normal;
  font-size: 17px;
  line-height: 100%;
  white-space: nowrap;
  padding: 16px 24px;
  ${p =>
    p.$isFirst &&
    css`
      padding-top: 24px;
    `}
  ${p =>
    p.$isLast &&
    css`
      padding-bottom: 24px;
    `}
  color: ${props => props.theme.colors.dark60};

  &:hover {
    color: ${props => props.theme.colors.dark80};
  }

  &:focus {
    color: ${props => props.theme.colors.dark80};
    text-decoration: underline;
    outline: none;
  }
`

const PopoverList = React.memo((props: IProps) => {
  const { className, items } = props

  return (
    <Wrapper className={className}>
      {items.map((data: IListItem, index) => (
        <li key={data.title}>
          <ItemButton
            onClick={data.action}
            $isFirst={index === 0}
            $isLast={index === items.length - 1}
          >
            {data.title}
          </ItemButton>
        </li>
      ))}
    </Wrapper>
  )
})

PopoverList.displayName = 'PopoverList'

export default PopoverList
