import { useCallback, useEffect, useState } from 'react'

export default (componentRef: any) => {
  const [focused, setFocused] = useState<boolean>(false)

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [setFocused])

  const onBlur = useCallback(() => {
    setFocused(false)
  }, [setFocused])

  useEffect(() => {
    if (!componentRef) {
      return
    }

    const { current } = componentRef
    if (!current) {
      return
    }

    current.addEventListener('focusin', onFocus)
    current.addEventListener('focusout', onBlur)

    return () => {
      current.removeEventListener('focusin', onFocus)
      current.removeEventListener('focusout', onBlur)
    }
  })

  return focused
}
