import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import Tooltip from 'src/components/Tooltip'
import ScreenContext from 'src/contexts/ScreenContext'
import {
  mobileButtonContainerPaddingMixin,
  mobileButtonMaxWidthMixin
} from 'src/theme/utils'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  disabled: boolean
  hasBackdatedPeriod?: boolean
  tpaRequestSent?: boolean
  onClickCallback: () => void
}

const TOOLTIP_OFFSET = 9 + 5 + 8 + 15 // 9px - button padding, 5px - tooltip padding, 8px - gap, 15px - hint height

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  ${mobileButtonContainerPaddingMixin};
`

const buttonMixin = css`
  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      height: 48px;
      font-size: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${buttonMixin}
  ${mobileButtonMaxWidthMixin};
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 182px;
        `
      : css`
          width: 100%;
          margin: 0 16px;
        `}
`

const Hint = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  align-self: center;
  position: absolute;
  top: 48px;
  margin-top: 6px;
  color: ${props => props.theme.colors.dark60};
`

export const dataAttrs = {
  button: () => 'request-claim-update-button'
}

const RequestClaimUpdateButton = React.memo((props: IProps) => {
  const {
    className,
    disabled,
    hasBackdatedPeriod,
    onClickCallback,
    tpaRequestSent
  } = props
  const { t } = useTranslation()

  const { isDesktop } = useContext(ScreenContext)

  const body = useMemo(() => {
    if (tpaRequestSent) {
      return t('timeline.requestClaimUpdateTooltip.body_sent')
    }
    return hasBackdatedPeriod
      ? t('timeline.requestClaimUpdateTooltip.body_backdated')
      : t('timeline.requestClaimUpdateTooltip.body')
  }, [tpaRequestSent, hasBackdatedPeriod, t])

  const buttonTitle: string = useMemo(
    () =>
      tpaRequestSent
        ? t('timeline.requestClaimUpdate.title_sent')
        : t('timeline.requestClaimUpdate.title'),
    [t, tpaRequestSent]
  )

  const buttonContent = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.button()}
        disabled={disabled}
        onClick={onClickCallback}
        ariaLabel={buttonTitle}
      >
        {buttonTitle}
      </ButtonWrapper>
    ),
    [disabled, onClickCallback, buttonTitle]
  )

  return (
    <ButtonsContainer className={className}>
      {isDesktop && (
        <Tooltip
          dashedUnderline={{ skipUnderline: true }}
          body={body}
          offset={[0, TOOLTIP_OFFSET]}
        >
          {buttonContent}
        </Tooltip>
      )}
      {!isDesktop && buttonContent}
      {isDesktop && <Hint>{t('timeline.requestClaimUpdate.hint')}</Hint>}
    </ButtonsContainer>
  )
})

RequestClaimUpdateButton.displayName = 'RequestClaimUpdateButton'

export default RequestClaimUpdateButton
