import React from 'react'
import { useTranslation } from 'react-i18next'
import TileInfo from 'src/components/Dashboard/TileInfo'
import { getOverDueItems } from 'src/utils/journeyMap'
import styled from 'styled-components'
import { DashboardProps } from '../../config'

const Overdue = styled.span<{ $important: boolean }>`
  display: inline;
  color: ${props =>
    props.$important ? props.theme.colors.error80 : props.theme.colors.dark80};
`

const OverdueTasks = React.memo((props: DashboardProps) => {
  const { absence } = props
  const { t } = useTranslation()

  const title = t('manager.tiles.overdueTasks.title')
  const amount = getOverDueItems(absence.journeyMap || []).length
  return (
    <TileInfo title={title}>
      <Overdue $important={amount > 0}>{amount}</Overdue>
    </TileInfo>
  )
})

OverdueTasks.displayName = 'OverdueTasks'

export default OverdueTasks
