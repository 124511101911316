import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { H1, H2 } from 'src/UIKit'
import FieldCard from 'src/components/FieldCard'
import ProfileFormFieldText from 'src/components/FieldCard/ProfileFormFieldText'
import { useTranslation } from 'react-i18next'
import Sessions from 'src/features/UserProfile/components/Sessions'
import UserProfileContext, {
  IUserProfileContext
} from 'src/features/UserProfile/Context'
import { useLocation } from 'react-router-dom'
import withRouter from 'src/components/hooks/useRouter'
import { MENU_OPEN } from 'src/constants/events'
import CloseOutPlanOverlay from 'src/features/UserProfile/components/CloseOutPlanOverlay'
import usePageTitle from 'src/components/hooks/usePageTitle'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import SharingSettings from '../SharingSettings'
import PlanHistory from '../PlanHistory'
import ClaimData from '../ClaimData'
import NotificationsSettings from '../NotificationsSettings'
import ProfileForm from '../ProfileForm'
import ClosePlan from '../ClosePlan'
import PrivacyControls from '../PrivacyControls'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import moment from 'moment'
import {
  SECTION_USER,
  SECTION_NOTIFICATION_SETTINGS,
  SECTION_ACTIVATE_MANAGER,
  SECTION_PRIVACY_CONTROLS,
  SECTION_CLAIM_DATA,
  SECTION_SESSIONS,
  SECTION_PLAN_HISTORY,
  SECTION_CLOSE_PLAN
} from 'src/utils/anchors'
import { authInfo } from 'src/graphql/authInfo'
import { scrollOffset } from 'src/constants/frame'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${props =>
    props.theme.isMobile &&
    css`
      overflow-x: hidden;
    `}
`

const Title = styled(H1)`
  margin-bottom: 0;
`

const SectionTitle = styled(H2)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 32px;
        `
      : css`
          margin: 0 0 24px;
          width: calc(100vw - 32px);
        `}
`

const sectionStyle = css`
  justify-self: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
        `
      : css`
          padding: 0 16px;
          width: calc(100vw - 32px);
        `}

  ${mobileMaxWidthMixin};
`

const wideSection = css`
  ${props =>
    props.theme.isDesktop &&
    css`
      width: 736px;
    `}
`

const StaticFieldsWrapper = styled.div`
  ${sectionStyle}
`

const Form = styled(ProfileForm)`
  ${sectionStyle}
`

const NotificationSettingsWrapper = styled(NotificationsSettings)`
  ${sectionStyle}
`

const SharingSettingsWrapper = styled(SharingSettings)`
  ${sectionStyle}
  ${wideSection}
`

const ClaimDataWrapper = styled(ClaimData)`
  ${sectionStyle}
  ${wideSection}
`

const PrivacyControlsWrapper = styled(PrivacyControls)`
  ${sectionStyle}
  ${wideSection}
`

const SessionsWrapper = styled(Sessions)`
  ${sectionStyle}
  ${wideSection}
`

const PlanHistoryWrapper = styled(PlanHistory)`
  ${sectionStyle}
  ${wideSection}

  ${props =>
    props.theme.isMobile &&
    css`
      width: calc(100vw - 32px);
    `}
`

const ClosePlanWrapper = styled(ClosePlan)`
  ${sectionStyle}
  ${wideSection}
`

const SectionContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 48px;
        `
      : css`
          margin-top: 24px;
        `}

  &:first-child {
    ${props =>
      props.theme.isDesktop
        ? css`
            margin-top: 48px;
          `
        : css`
            margin-top: 16px;
          `}
  }

  &:last-child {
    ${props =>
      props.theme.isDesktop
        ? css`
            margin-bottom: 48px;
          `
        : css`
            margin-bottom: 16px;
          `}
  }
`

const UserProfilePage = React.memo(() => {
  const {
    user,
    updateNotificationSettings,
    hasPlanToCloseOut,
    hasPlanHistory,
    closeOutPlan,
    toggleTpaSyncing,
    activateManagerForLeave,
    leave,
    tpaSyncing,
    showTpaLastUpdate,
    ...formProps
  } = useContext<IUserProfileContext>(UserProfileContext)

  const { isDesktop } = useContext(ScreenContext)

  const { t } = useTranslation()
  const location = useLocation()

  const hashRef = useRef(location.hash)
  const sectionUserRef = useRef(null)
  const sectionNotificationSettingsRef = useRef(null)
  const sectionActivateManagerRef = useRef(null)
  const sectionPrivacyControlsRef = useRef(null)
  const sectionSessionsRef = useRef(null)
  const sectionClaimDataRef = useRef(null)
  const sectionPlanHistoryRef = useRef(null)
  const sectionClosePlanRef = useRef(null)

  const [isUserClicked, setIsUserClicked] = useState(false)
  const [isClosingOutPlanMode, setIsClosingOutPlanMode] =
    useState<boolean>(false)

  usePageTitle('profile')

  const enableOutOut = authInfo.getEnableOptOut()
  const canClaimData = useMemo(
    () => leave?.tpa && isPublishedOrBabyArrivedLeave(leave.status),
    [leave]
  )

  useEffect(() => {
    const handleMouseClick = () => {
      setIsUserClicked(true)
    }

    document.addEventListener('mousedown', handleMouseClick)

    return () => {
      document.removeEventListener('mousedown', handleMouseClick)
    }
  }, [])

  useEffect(() => {
    if (isUserClicked) {
      return
    }
    let scrollToRef: any = null
    switch (hashRef.current) {
      case SECTION_USER:
        scrollToRef = sectionUserRef.current
        break
      case SECTION_NOTIFICATION_SETTINGS:
        scrollToRef = sectionNotificationSettingsRef.current
        break
      case SECTION_ACTIVATE_MANAGER:
        scrollToRef = sectionActivateManagerRef.current
        break
      case SECTION_PRIVACY_CONTROLS:
        scrollToRef = sectionPrivacyControlsRef.current
        break
      case SECTION_CLAIM_DATA:
        scrollToRef = sectionClaimDataRef.current
        break
      case SECTION_SESSIONS:
        scrollToRef = sectionSessionsRef.current
        break
      case SECTION_PLAN_HISTORY:
        scrollToRef = sectionPlanHistoryRef.current
        break
      case SECTION_CLOSE_PLAN:
        scrollToRef = sectionClosePlanRef.current
        break
    }

    if (!scrollToRef) {
      return
    }

    const elementTop: number = scrollToRef.getBoundingClientRect().top || 0

    const top: number =
      elementTop +
      document.body.scrollTop -
      scrollOffset.top({ isDesktop }).value

    document.body.scrollTo({ top, behavior: 'smooth' })
  })

  useEffect(() => {
    const onMenuOpened = () => {
      hideClosingOutPlanOverlay()
    }

    window.addEventListener(MENU_OPEN, onMenuOpened)

    return () => {
      window.removeEventListener(MENU_OPEN, onMenuOpened)
    }
  }, [])

  const hideClosingOutPlanOverlay = () => {
    setIsClosingOutPlanMode(false)
  }

  return (
    <Container>
      {!isClosingOutPlanMode && (
        <>
          <Title>{t('userProfile.title')}</Title>
          <SectionContainer
            role={'region'}
            aria-label={t('common.accessibilityText.regionUserProfile')}
          >
            <SectionTitle ref={sectionUserRef}>
              {t('userProfile.titles.profile')}
            </SectionTitle>
            <StaticFieldsWrapper>
              <FieldCard
                label={t('common.employeeNumber')}
                icon={{ iconName: 'sharp' }}
                contentContainerMinHeight={40}
              >
                <ProfileFormFieldText>
                  {user ? user.employeeNumber : ''}
                </ProfileFormFieldText>
              </FieldCard>
              <FieldCard
                label={t('common.name')}
                icon={{ iconName: 'user' }}
                contentContainerMinHeight={40}
              >
                <ProfileFormFieldText>
                  {user ? `${user.firstName} ${user.lastName}` : ''}
                </ProfileFormFieldText>
              </FieldCard>
              <FieldCard
                label={t('common.corporateEmail')}
                icon={{ iconName: 'corporate_email' }}
                contentContainerMinHeight={40}
              >
                <ProfileFormFieldText>
                  {user ? user.email : ''}
                </ProfileFormFieldText>
              </FieldCard>
            </StaticFieldsWrapper>
            <Form user={user} {...formProps} />
          </SectionContainer>
          <SectionContainer
            role={'region'}
            aria-label={t(
              'common.accessibilityText.regionNotificationSettings'
            )}
          >
            <SectionTitle ref={sectionNotificationSettingsRef}>
              {t('userProfile.titles.notifications')}
            </SectionTitle>
            <NotificationSettingsWrapper
              notificationSettings={user ? user.notificationSettings : null}
              updateNotificationSettings={updateNotificationSettings as any}
            />
          </SectionContainer>
          {leave?.metadata?.activateManager && user?.managerEmail && (
            <SectionContainer
              role={'region'}
              aria-label={t('common.accessibilityText.regionSharingSettings')}
            >
              <SectionTitle ref={sectionActivateManagerRef}>
                {t('userProfile.titles.sharing')}
              </SectionTitle>
              <SharingSettingsWrapper
                leave={leave}
                managerEmail={user.managerEmail}
                activateManagerForLeave={activateManagerForLeave}
              />
            </SectionContainer>
          )}
          {enableOutOut && (
            <SectionContainer
              role={'region'}
              aria-label={t('common.accessibilityText.regionPrivacyControls')}
            >
              <SectionTitle ref={sectionPrivacyControlsRef}>
                {t('userProfile.titles.privacyControls')}
              </SectionTitle>
              <PrivacyControlsWrapper />
            </SectionContainer>
          )}
          {canClaimData && (
            <SectionContainer
              role={'region'}
              aria-label={t('common.accessibilityText.regionClaimData')}
            >
              <React.Fragment>
                <SectionTitle ref={sectionClaimDataRef}>
                  {t('userProfile.titles.claimData')}
                </SectionTitle>
                <ClaimDataWrapper
                  syncing={tpaSyncing}
                  changedAt={moment(leave.tpa.changedAt)}
                  toggleTpaSyncing={toggleTpaSyncing}
                  showLastUpdate={showTpaLastUpdate}
                  leave={leave}
                />
              </React.Fragment>
            </SectionContainer>
          )}

          <SectionContainer
            role={'region'}
            aria-label={t('common.accessibilityText.regionActiveSessions')}
          >
            <SectionTitle ref={sectionSessionsRef}>
              {t('userProfile.titles.sessions')}
            </SectionTitle>
            <SessionsWrapper />
          </SectionContainer>
          {hasPlanHistory && (
            <SectionContainer
              role={'region'}
              aria-label={t('common.accessibilityText.regionPlanHistory')}
            >
              <React.Fragment>
                <SectionTitle ref={sectionPlanHistoryRef}>
                  {t('userProfile.titles.planHistory')}
                </SectionTitle>
                <PlanHistoryWrapper />
              </React.Fragment>
            </SectionContainer>
          )}
          {hasPlanToCloseOut && (
            <SectionContainer
              role={'region'}
              aria-label={t('common.accessibilityText.regionClosePlan')}
            >
              <React.Fragment>
                <SectionTitle ref={sectionClosePlanRef}>
                  {t('userProfile.titles.closePlan')}
                </SectionTitle>
                <ClosePlanWrapper
                  onIWantToCloseOut={() => {
                    setIsClosingOutPlanMode(true)
                  }}
                />
              </React.Fragment>
            </SectionContainer>
          )}
        </>
      )}
      {isClosingOutPlanMode && (
        <CloseOutPlanOverlay
          onBack={hideClosingOutPlanOverlay}
          behaviour={
            leave.managerActivated ? 'askNotifyManager' : 'justCloseOut'
          }
          onCloseOutPlan={closeOutPlan}
        />
      )}
    </Container>
  )
})

UserProfilePage.displayName = 'UserProfilePage'

export default withRouter(UserProfilePage)
