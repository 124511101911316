import React from 'react'
import { useTranslation } from 'react-i18next'
import TileInfo from 'src/components/Dashboard/TileInfo'
import { tasksThisWeek } from 'src/utils/journeyMap'
import { DashboardProps } from '../../config'

const TasksThisWeek = React.memo((props: DashboardProps) => {
  const { t } = useTranslation()
  const { absence } = props

  const title = t('manager.tiles.tasksThisWeek.title')
  const amount = tasksThisWeek(absence.journeyMap || []).length

  return <TileInfo title={title}>{amount} </TileInfo>
})

TasksThisWeek.displayName = 'TasksThisWeek'

export default TasksThisWeek
