import React from 'react'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import HelpCenterPage from '../HelpCenter/components/HelpCenterPage'

const HelpCenterContainer = React.memo(() => {
  const navigate = useNavigate()
  useRedirectByUserRole({ navigate, allowedRole: 'manager' })
  usePageTitle('helpCenter')
  return <HelpCenterPage role="manager" />
})

HelpCenterContainer.displayName = 'HelpCenterContainer'

export default withRouter(HelpCenterContainer)
