import React, {
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimeView from 'src/features/Timeline/components/vertical/TimeView'
import BlockFooterView, { Separator } from './components/Footer'
import zIndex from 'src/constants/zIndex'
import ScreenContext from 'src/contexts/ScreenContext'
import { ITimelinePeriod } from 'src/react-app-env'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { useTranslation } from 'react-i18next'
import AnimateHeight from 'react-animate-height'
import ConvertPeriodTypeRow from './components/ConvertPeriodTypeRow'
import TimelineViewContext, {
  ITimelineViewContext
} from 'src/features/Timeline/components/vertical/TimelineView/TimelineViewContext'

interface IProps {
  children?: any
  inactive?: boolean
  unselected?: boolean
  selected?: boolean
  period?: ITimelinePeriod
  expandableGroup?: ITimelinePeriod[]
  dashedBorder?: boolean
  hidesOnPrint?: boolean
}

const TRANSITION_DURATION = 200

const Container = styled.div<{ $hidesOnPrint: boolean }>`
  display: flex;
  width: 100%;
  cursor: default;
  position: relative;
  ${p =>
    p.$hidesOnPrint &&
    css`
      ${printDisplayNoneMixin}
    `}
`

const InnerContainer = styled.div<{ $isNotice: boolean }>`
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: ${props.$isNotice ? '16px 16px 4px' : '16px'};
        `
      : css`
          padding: ${props.$isNotice ? '16px 16px 0' : '16px'};
        `}
`

const ContentContainer = styled.div<{
  $isNotice?: boolean
  $isHidesFooter?: boolean
  $dashedBorder?: boolean
  $inactive?: boolean
  $unselected?: boolean
  $selected?: boolean
  $convertible?: boolean
}>`
  transition: background 300ms;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 20px
    ${props =>
      props.$dashedBorder ? 'transparent' : 'rgba(98, 106, 116, 0.05)'};

  ${props => {
    const { $isNotice, $isHidesFooter } = props
    if ($isNotice && $isHidesFooter) {
      return css`
        padding-bottom: 16px;
      `
    }
  }}

  ${props => {
    const {
      $inactive,
      $unselected,
      $selected,
      $isNotice,
      $dashedBorder,
      $convertible,
      theme: { colors }
    } = props

    const borderStyle = $dashedBorder ? 'dashed' : 'solid'

    if ($unselected) {
      return css`
        border: 1px ${borderStyle} transparent;
        background: ${$isNotice ? colors.error05 : colors.backgroundColor};
      `
    } else {
      if ($selected) {
        return css`
          border-left: 1px ${borderStyle} ${colors.dark10};
          border-top: 1px ${borderStyle} ${colors.dark10};
          border-bottom: 1px ${borderStyle} ${colors.dark10};
          background: ${colors.light100};
          border-radius: 8px 0 0 8px;
          width: calc(100% + 10px);
        `
      } else if ($convertible) {
        return css`
          border: 1px dashed ${colors.main100};
          background: ${colors.light100};
        `
      } else if ($inactive) {
        return css`
          border: 1px ${borderStyle} ${colors.dark05};
          background: ${colors.backgroundColor};
        `
      } else {
        return css`
          border: ${$isNotice ? '2px' : '1px'} ${borderStyle}
            ${$isNotice
              ? colors.error20
              : $dashedBorder
                ? colors.dark10
                : colors.dark05};
          background: ${$isNotice ? colors.error05 : colors.light100};
        `
      }
    }
  }}

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 4px 0;
        `
      : css`
          margin: 2px 0;
        `}

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    margin: 4px 0;
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.dark10};
    background: ${props => props.theme.colors.light100};
    page-break-inside: avoid;
  }
`

const InnerGroupContentContainer = styled(ContentContainer)`
  margin: 0;
`

const WhiteSide = styled.div`
  position: absolute;
  right: -11px;
  top: 4px;
  bottom: 4px;
  width: 11px;
  background: ${props => props.theme.colors.light100};
  border-top: 1px solid ${props => props.theme.colors.dark10};
  border-bottom: 1px solid ${props => props.theme.colors.dark10};
  z-index: ${zIndex.timeline.vertical.whiteSide};
`

const InnerGroupItemView = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px 16px;
          gap: 8px;
        `
      : css`
          margin: 0 8px 8px;
          gap: 4px;
        `}
`

const AnimatedButtonText = styled.span<any>`
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
  opacity: ${props => props.opacity};
  position: ${props => (props.opacity ? 'relative' : 'absolute')};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${printDisplayNoneMixin}
`

const Button = styled.button<{
  $disabled: boolean
}>`
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          line-height: 16px;
          padding: 12px 0;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
          padding: 8px 0;
        `}

  color: ${props => props.theme.colors.main100};
  user-select: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 10px;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    outline: none;
  }

  ${(props: any) => {
    if (props.$disabled) {
      return css`
        cursor: unset;
        color: ${props.theme.colors.dark20};
        text-decoration: none;

        &:hover {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }

        &:focus {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }
      `
    }
  }}
`

export const propsAreEqual = (prevProps: IProps, nextProps: IProps) => {
  if (prevProps.period !== nextProps.period) {
    return false
  }
  if (prevProps.inactive !== nextProps.inactive) {
    return false
  }
  if (prevProps.unselected !== nextProps.unselected) {
    return false
  }
  if (prevProps.selected !== nextProps.selected) {
    return false
  }
  if (prevProps.children !== nextProps.children) {
    return false
  }
  if (prevProps.dashedBorder !== nextProps.dashedBorder) {
    return false
  }
  if (prevProps.hidesOnPrint !== nextProps.hidesOnPrint) {
    return false
  }

  return true
}

export const dataAttrs = {
  expandButton: () => 'expandable-blocks-expand-button'
}

const BlockBackgroundView = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      children,
      inactive,
      unselected,
      selected,
      period,
      expandableGroup,
      dashedBorder,
      hidesOnPrint
    } = props
    const { selectedDatePickerPeriod }: ITimelineViewContext =
      useContext(TimelineViewContext)
    const { isDesktop } = useContext(ScreenContext)
    const convertible = period.convertibleToType?.length > 0
    const {
      appearance,
      timelineConfig: { timeViewProps }
    } = period
    const [expanded, setExpanded] = useState<boolean>(false)
    const { t } = useTranslation()
    const onClick = useCallback(() => {
      if (selectedDatePickerPeriod) {
        return
      }
      setExpanded(!expanded)
    }, [expanded, setExpanded, selectedDatePickerPeriod])

    const isNotice: boolean = useMemo(
      () => appearance === 'Notice',
      [appearance]
    )

    const convertToBlocks = useMemo(
      () =>
        convertible && (
          <InnerGroupItemView>
            {period.convertibleToType?.map((type, index) => (
              <InnerGroupContentContainer
                key={index}
                $dashedBorder
                $isHidesFooter
              >
                <ConvertPeriodTypeRow period={period} type={type} />
              </InnerGroupContentContainer>
            ))}
          </InnerGroupItemView>
        ),
      [period, convertible]
    )

    const expandableBlocks = useMemo(
      () =>
        expandableGroup?.length && (
          <>
            <Separator $isNotice={false} $selected={false} />
            <ButtonContainer onClick={onClick}>
              <Button
                data-testid={dataAttrs.expandButton()}
                disabled={!!selectedDatePickerPeriod}
                $disabled={!!selectedDatePickerPeriod}
              >
                <AnimatedButtonText opacity={expanded ? 1 : 0}>
                  {t('timeline.expandablePeriods.cancel')}
                </AnimatedButtonText>
                <AnimatedButtonText opacity={expanded ? 0 : 1}>
                  {t('timeline.expandablePeriods.addTimeAway')}
                </AnimatedButtonText>
              </Button>
            </ButtonContainer>
            <AnimateHeight
              duration={300}
              height={expanded ? 'auto' : 0}
              animateOpacity
            >
              <InnerGroupItemView>
                {expandableGroup?.map((item, index) => (
                  <InnerGroupContentContainer
                    key={index}
                    $dashedBorder
                    $isHidesFooter
                  >
                    <BlockFooterView
                      period={item}
                      hidesSeparator
                      selected={false}
                    />
                  </InnerGroupContentContainer>
                ))}
              </InnerGroupItemView>
            </AnimateHeight>
          </>
        ),
      [expandableGroup, onClick, selectedDatePickerPeriod, expanded, t]
    )

    const isHidesFooter: boolean = useMemo(
      () => period.timelineConfig.hidesFooter,
      [period]
    )

    const footerView: ReactNode = useMemo(() => {
      if (expandableBlocks) {
        return expandableBlocks
      }

      if (convertToBlocks) {
        return convertToBlocks
      }

      if (isHidesFooter) {
        return null
      }

      return (
        <BlockFooterView
          period={period}
          hidesSeparator={!children}
          selected={selected}
        />
      )
    }, [
      isHidesFooter,
      expandableBlocks,
      convertToBlocks,
      period,
      children,
      selected
    ])

    const ariaLabel: string = useMemo(() => {
      let result: string

      result = period.timelineConfig.title

      if (timeViewProps?.date) {
        result += '. ' + timeViewProps.date.format('MMM DD, YYYY')
      }

      return result
    }, [period, timeViewProps])

    return (
      <Container
        ref={ref}
        role={'region'}
        aria-label={ariaLabel}
        $hidesOnPrint={hidesOnPrint}
      >
        <TimeView period={period} />
        <ContentContainer
          $inactive={inactive}
          $unselected={unselected}
          $selected={selected}
          $isNotice={isNotice}
          $dashedBorder={dashedBorder}
          $convertible={convertible}
          $isHidesFooter={isHidesFooter} // period without a footer, save the padding
        >
          {children && (
            <InnerContainer $isNotice={isNotice}>{children}</InnerContainer>
          )}
          {footerView}
        </ContentContainer>
        {selected && isDesktop && <WhiteSide />}
      </Container>
    )
  }),
  propsAreEqual
)

BlockBackgroundView.displayName = 'BlockBackgroundView'

export default BlockBackgroundView
