import React from 'react'
import styled from 'styled-components'

export interface IProps {
  className?: string
}

const DashedLine = styled.div<any>`
  width: 1px;
  background-image: linear-gradient(
    to bottom,
    transparent 50%,
    ${props => props.theme.colors.timelineLeaveLite} 50%
  );
  background-size: 100% 4px;

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background: ${props => props.theme.colors.timelineLeaveLite};
  }
`

const TimelineTimeViewDashedLine = React.memo((props: IProps) => (
  <DashedLine {...props} />
))

TimelineTimeViewDashedLine.displayName = 'TimelineTimeViewDashedLine'

export default TimelineTimeViewDashedLine
