import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { Button, H1, Icon, MarkdownText } from 'src/UIKit'
import createTheme from 'src/theme'
import zIndex from 'src/constants/zIndex'
import ModalsView from 'src/components/ModalsView'
import { useTranslation } from 'react-i18next'
import {
  mobileButtonMaxWidthMixin,
  printDisplayNoneMixin
} from 'src/theme/utils'
import SurveyContainer, {
  SURVEY_STATE
} from 'src/components/Surveys/SurveyContainer'
import { withToastManager } from 'src/components/ToastManager'
import { IWithDialogManager, IWithToastManager } from 'src/react-app-env'
import ReactFocusLock from 'react-focus-lock'
import ScreenContext from 'src/contexts/ScreenContext'
import { KEY_ESCAPE } from 'src/constants/keys'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import useSurvey from 'src/graphql/hooks/useSurvey'
import { withDialogManager } from 'src/components/DialogManager'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

export interface ISurveyParams {
  surveyId: string
  answerValue: string
}

interface IProps extends IWithDialogManager, IWithToastManager {
  className?: string
  surveyParams: ISurveyParams
  onClose: () => void
}

const Container = styled.div`
  z-index: ${zIndex.survey.itemView.container};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media print {
    margin-top: 32px;
  }
`

const ContentContainer = styled.div`
  background: ${props => props.theme.colors.light100};
  display: flex;
  flex-direction: column;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          border-radius: 16px;
          max-width: 1440px;
          width: 65vw;
          max-height: 90vh;
          min-height: 40%;
        `
      : css`
          width: 100%;
        `}

  @media print {
    border-radius: 0;
    width: 100%;
    max-height: unset;
    min-height: unset;
    box-shadow: none;
    display: block;
  }
`

const BackWrapper = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  ${printDisplayNoneMixin}
`

const CancelSurvey = styled.button`
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.main100};
  padding: 8px 8px 8px 0;
`

const TitleAndCloseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TitleWrapper = styled(H1)`
  text-align: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 auto;
          max-width: 90%;
        `
      : css`
          @media not print {
            border: unset;
            display: inline-block;
            padding: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 130%;
            color: ${props.theme.colors.dark80};
            width: 100%;
          }

          @media print {
            color: ${props.theme.colors.dark60};
            display: table;
            margin: 0 auto;
          }
        `};
`

const CloseButton = styled.button`
  position: relative;
  transition: border 100ms;
  width: 30px;
  height: 30px;
  margin: 9px 14px -15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  ${printDisplayNoneMixin};

  &:hover {
    border: 2px solid ${props => props.theme.colors.main100};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main100};
  }
`

const CloseIcon = styled(Icon)`
  height: 14px;
`

const BottomPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 16px;
  margin-top: 58px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  min-height: 80px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  ${printDisplayNoneMixin}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 200px;
        `
      : css`
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          border-radius: 16px;
          height: 48px;
          width: calc(100% - 32px);
        `}

  ${mobileButtonMaxWidthMixin};
  ${printDisplayNoneMixin}
`

const ButtonMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 80px;
  z-index: ${zIndex.survey.itemView.buttonContainer};
`

const DialogContent = styled(MarkdownText)`
  white-space: pre-wrap;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

export const SurveyGlobalView = React.memo((props: IProps) => {
  const { className, surveyParams, onClose, dialogManager, toastManager } =
    props
  const { isDesktop } = useContext(ScreenContext)
  const [surveyState, setSurveyState] = useState(SURVEY_STATE.started)
  const containerRef = useRef(null)
  const { t } = useTranslation()
  const userResult: IUseUser = useUser()
  const { loading, survey } = useSurvey(surveyParams?.surveyId)

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.keyCode === KEY_ESCAPE) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown])

  useEffect(() => {
    if (survey?.status === 'Completed') {
      onClose()
      dialogManager.add({
        title: t('surveys.completedDialog.title'),
        children: (
          <DialogContent
            urlTransform={TelUrlTransformer}
            allowsParagraph
            components={{ a: LinkRenderer }}
          >
            {t('surveys.completedDialog.text')}
          </DialogContent>
        ),
        ariaText: t('surveys.completedDialog.title'),
        onUserClosed: () => undefined,
        buttons: [
          {
            title: t('common.ok'),
            onClick: () => undefined
          }
        ]
      })
    }
  }, [dialogManager, onClose, survey, t])

  const titleView: ReactNode = useMemo(() => {
    if (isDesktop) {
      return (
        <div>
          <CloseButton
            onClick={onClose}
            aria-label={t('common.accessibilityText.closeButton')}
          >
            <CloseIcon
              name={'close_crossing'}
              settings={{ fill: colors.main100 }}
            />
          </CloseButton>
          <TitleAndCloseWrapper>
            {surveyState === SURVEY_STATE.submitted && (
              <TitleWrapper>{t('surveys.titleGeneralSubmitted')}</TitleWrapper>
            )}
          </TitleAndCloseWrapper>
        </div>
      )
    } else {
      return (
        surveyState === SURVEY_STATE.submitted && (
          <TitleWrapper>{t('surveys.titleGeneralSubmitted')}</TitleWrapper>
        )
      )
    }
  }, [isDesktop, onClose, t, surveyState])

  const onSurveyError = useCallback(
    (close: boolean) => {
      if (close) {
        onClose()
      }
      toastManager.addError(t('common.somethingWentWrong'))
    },
    [onClose, toastManager, t]
  )

  const surveyView: ReactNode = useMemo(
    () => (
      <SurveyContainer
        surveyId={surveyParams?.surveyId}
        onSubmit={() => setSurveyState(SURVEY_STATE.submitted)}
        state={surveyState}
        onStart={(): void => undefined}
        onClose={onClose}
        onError={onSurveyError}
        isGlobalSurvey
        answerValue={surveyParams?.answerValue}
      />
    ),
    [surveyParams, surveyState, onClose, onSurveyError]
  )

  const backWrapper: ReactNode = useMemo(
    () => (
      <BackWrapper>
        <CancelSurvey onClick={onClose}>
          {t('surveys.cancelSurvey')}
        </CancelSurvey>
      </BackWrapper>
    ),
    [onClose, t]
  )

  const buttonView: ReactNode = useMemo(() => {
    if (isDesktop) {
      return (
        <ButtonWrapper onClick={() => onClose()}>
          {t('common.close')}
        </ButtonWrapper>
      )
    }

    return (
      <ButtonMobileContainer>
        <ButtonWrapper onClick={() => onClose()}>
          {t('common.close')}
        </ButtonWrapper>
      </ButtonMobileContainer>
    )
  }, [onClose, t, isDesktop])

  const bottomPanelView: ReactNode = useMemo(
    () => <BottomPanel>{buttonView}</BottomPanel>,
    [buttonView]
  )

  const contentContainerView = useMemo(() => {
    if (isDesktop) {
      return (
        <ContentContainer>
          {titleView}
          {surveyView}
          {surveyState === SURVEY_STATE.submitted && bottomPanelView}
        </ContentContainer>
      )
    } else {
      return (
        <ContentContainer>
          <ReactFocusLock autoFocus={false}>
            {surveyState !== SURVEY_STATE.submitted && backWrapper}
            {titleView}
            {surveyView}
          </ReactFocusLock>
        </ContentContainer>
      )
    }
  }, [
    isDesktop,
    titleView,
    surveyView,
    backWrapper,
    surveyState,
    bottomPanelView
  ])

  const containerProps: any = {
    className,
    ref: containerRef,
    onClick: (event: any) => {
      if (isDesktop && event.target === containerRef.current) {
        onClose()
      }
    }
  }

  if (!userResult.user || loading) {
    return null
  }

  if (isDesktop) {
    return (
      <ModalsView {...containerProps} role={'dialog'}>
        <ReactFocusLock>{contentContainerView}</ReactFocusLock>
      </ModalsView>
    )
  }

  return <Container {...containerProps}>{contentContainerView}</Container>
})

SurveyGlobalView.displayName = 'SurveyGlobalView'

export default withDialogManager(withToastManager(SurveyGlobalView))
