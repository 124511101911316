import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import UserProfileContext, {
  IUserProfileContext
} from 'src/features/UserProfile/Context'
import { Button } from 'src/UIKit'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'

interface IProps {
  className?: string
  onIWantToCloseOut: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const ButtonWrapper = styled(Button)`
  font-size: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 0;
        `
      : css`
          margin: 16px 0 0;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ClosePlan = React.memo((props: IProps) => {
  const { className, onIWantToCloseOut } = props
  const { user } = useContext<IUserProfileContext>(UserProfileContext)
  const { t } = useTranslation()

  if (!user) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  return (
    <Container className={className}>
      <Text>{t('closePlan.text')}</Text>
      <ButtonWrapper onClick={onIWantToCloseOut} appearance={'destructive'}>
        {t('closePlan.iWantToCloseOutMyPlan')}
      </ButtonWrapper>
    </Container>
  )
})

ClosePlan.displayName = 'ClosePlan'

export default ClosePlan
