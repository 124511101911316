import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  ariaLabel?: string
  role?: string
}

const Button = styled.button`
  position: absolute;
  color: transparent;
  width: 0;
  height: 0;

  &:focus {
    outline: none;
  }
`

const AccessibilitySelfFocusText = React.memo((props: IProps) => {
  const { className, ariaLabel, role } = props

  return (
    <Button
      className={className}
      aria-label={ariaLabel}
      role={role}
      aria-hidden
      tabIndex={-1}
      ref={btn => btn?.focus()}
    />
  )
})

AccessibilitySelfFocusText.displayName = 'AccessibilitySelfFocusText'

export default AccessibilitySelfFocusText
