import React, { useMemo, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next'
import { Link, Hint } from 'src/UIKit'
import ItemListTitled from 'src/components/ItemListTitled'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import {
  convertTpaChangeItems,
  convertTpaSyncItems
} from 'src/utils/periodUtils'
import styled, { css } from 'styled-components'
import ClaimUpdateButtons from '../ClaimUpdateButtons'
import UpcomingPeriodItems from './components/UpcomingPeriodItems'
import NoUpcomingPeriods from './components/NoUpcomingPeriods'

const ANIMATION_DURATION = 300

interface IProps {
  name: string
  leaveType: ILeaveType
  claimUpdateDetails: IClaimUpdateDetails
  onBackClick: () => void
  onNextClick: () => void
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          margin: 0 auto;
          align-items: center;
        `}

  ${mobileMaxWidthMixin};
`

const UpcomingPeriodContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 640px;
  align-self: center;
  width: 100%;
`

const CurrentTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`

const TimelineItems = styled.div`
  width: 100%;
  max-width: 640px;
  align-self: center;
`

const ToggleCurrentTimeline = styled(Link)`
  margin: auto;
  margin-top: 32px;
  line-height: 130%;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.main100};
`

const ReviewDatesView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name, onBackClick, onNextClick, leaveType, claimUpdateDetails } =
    props
  const [showCurrentTimeline, setShowCurrentTimeline] = useState<boolean>(false)

  const futureDatedPeriods = useMemo(
    () =>
      convertTpaSyncItems(
        claimUpdateDetails.futureDatedPeriods || [],
        leaveType,
        t
      ),
    [claimUpdateDetails.futureDatedPeriods, leaveType, t]
  )

  const groupedFutureDatedPeriods = useMemo(() => {
    const groups = []
    let currentGroup = []

    futureDatedPeriods.forEach((item, index) => {
      if (item.groupWithPrevious) {
        currentGroup.push(item)
      } else {
        if (currentGroup.length > 0) {
          groups.push(currentGroup)
        }
        currentGroup = [item]
      }

      if (index === futureDatedPeriods.length - 1) {
        groups.push(currentGroup)
      }
    })

    return groups
  }, [futureDatedPeriods])

  const futureDatedPeriodsItems = useMemo(
    () =>
      groupedFutureDatedPeriods.map((items, index) => (
        <UpcomingPeriodItems
          key={index}
          period={items[0]}
          extraPeriods={items.slice(1)}
        />
      )),
    [groupedFutureDatedPeriods]
  )

  const currentTimelineItems = useMemo(
    () =>
      convertTpaChangeItems(claimUpdateDetails.currentTimeline, leaveType, t),
    [claimUpdateDetails.currentTimeline, leaveType, t]
  )

  return (
    <>
      <ContentContainer>
        <Hint text={t(`claimUpdate.${name}.hint`)} />

        <UpcomingPeriodContainer>
          {futureDatedPeriodsItems.length > 0 ? (
            futureDatedPeriodsItems
          ) : (
            <NoUpcomingPeriods />
          )}
        </UpcomingPeriodContainer>

        <ToggleCurrentTimeline
          onClick={() => setShowCurrentTimeline(!showCurrentTimeline)}
        >
          {showCurrentTimeline
            ? t(`claimUpdate.${name}.currentTimeline.hide`)
            : t(`claimUpdate.${name}.currentTimeline.show`)}
        </ToggleCurrentTimeline>

        <AnimateHeight
          duration={ANIMATION_DURATION}
          height={showCurrentTimeline ? 'auto' : 0}
          style={{ width: '100%' }}
          animateOpacity
        >
          <CurrentTimelineContainer>
            <Hint text={t(`claimUpdate.${name}.currentTimeline.hint`)} />
            <TimelineItems>
              <ItemListTitled
                items={currentTimelineItems}
                title={t(`claimUpdate.${name}.currentTimeline.title`)}
              />
            </TimelineItems>
          </CurrentTimelineContainer>
        </AnimateHeight>
      </ContentContainer>
      <ClaimUpdateButtons
        backTitle={
          claimUpdateDetails?.skipWelcomePage
            ? t(`claimUpdate.${name}.control.cancel`)
            : t('common.back')
        }
        onBackClick={onBackClick}
        nextTitle={t(`claimUpdate.${name}.control.submit`)}
        onNextClick={onNextClick}
      />
    </>
  )
})

ReviewDatesView.displayName = 'ReviewDatesView'

export default ReviewDatesView
