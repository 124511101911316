import React from 'react'
import { MetricType, TransitionStage } from '../../TransitionFlowContainer'
import TransitionStepsView from '../TransitionStepsView'

interface IProps {
  name: TransitionStage
  setCurrentStage: (stage: TransitionStage) => void
  onSkip: () => void
  sendClickMetric: (type: MetricType) => void
}

export const ReadyToTransitionView = React.memo((props: IProps) => {
  const { name, setCurrentStage, onSkip, sendClickMetric } = props

  return (
    <TransitionStepsView
      name={name}
      onCancelClicked={() => onSkip()}
      onNextClicked={() => {
        sendClickMetric('start')
        setCurrentStage('extendDisabilityTime')
      }}
      cancelButtonTextKey={`transitionFlow.pages.${name}.cancelButtonText`}
      nextButtonTextKey={`transitionFlow.pages.${name}.nextButtonText`}
    />
  )
})

ReadyToTransitionView.displayName = 'ReadyToTransitionView'

export default ReadyToTransitionView
