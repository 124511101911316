import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { planChangeRoute } from 'src/routes/constants'
import { withToastManager } from 'src/components/ToastManager'
import JourneyMapListContainer from 'src/features/JourneyMap/containers/JourneyMapListContainer'
import JourneyMapSettingsContainer from 'src/features/JourneyMap/containers/JourneyMapSettingsContainer'
import {
  createPeriodFilters,
  createPriorityFilters,
  fetchLeaveJourneyMap,
  flipFilter,
  resetFilters,
  showStaticJourneyMap,
  switchFiltersDisability
} from 'src/utils/journeyMap'
import { IWithQueriesProps, IWithToastManager } from 'src/react-app-env'
import LoadingSpinner from 'src/components/LoadingSpinner'
import withQueries from 'src/components/HOC/withQueries'
import { useTranslation } from 'react-i18next'
import JourneyMapContext, {
  IJourneyMapContext
} from 'src/features/JourneyMap/Context'
import JourneyMapTopViewContainer from 'src/components/JourneyMap/TopViewContainer'
import UserContext, { IUserContext } from 'src/contexts/UserContext'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import { getUserContextValue } from 'src/contexts/UserContext/methods'
import usePageTitle from 'src/components/hooks/usePageTitle'
import ScreenContext from 'src/contexts/ScreenContext'
import usePrevious from 'src/components/hooks/usePrevious'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useNavigate, useLocation } from 'react-router-dom'
import { hasNewTpaChanges } from 'src/utils/leaveUtils'
import { getCountryCode } from 'src/utils/userUtils'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import {
  JOURNEY_PERIODS_KEY,
  JOURNEY_PRIORITIES_KEY,
  TRANSITION_FLOW_KEY
} from 'src/utils/ls'
import { checkTransitionFlow } from 'src/features/TransitionFlow/methods'
import StaticJourneymapPage from '../components/StaticJourneymapPage'
import { getNavigationComponent } from 'src/utils/leaveChangesRedirectManager'

interface IProps extends IWithQueriesProps, IWithToastManager {}

export const JourneyMapContainer = React.memo((props: IProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { queries, toastManager } = props

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })

  const { t } = useTranslation()
  const { isMobile } = useContext(ScreenContext)
  const wasMobile: boolean = usePrevious(isMobile)
  const [skipTransitionFlow] = useLocalStorage(TRANSITION_FLOW_KEY, {
    skip: false
  })
  const [selectedPriorityFilters, setSelectedPriorityFilters] = useLocalStorage(
    JOURNEY_PRIORITIES_KEY,
    []
  )
  const defaultPriorityFilters = useMemo(
    () => createPriorityFilters(selectedPriorityFilters),
    [selectedPriorityFilters]
  )
  const [priorityFilters, setPriorityFilters] = useState(defaultPriorityFilters)

  const [selectedPeriodFilters, setSelectedPeriodFilters] = useLocalStorage(
    JOURNEY_PERIODS_KEY,
    []
  )
  const defaultPeriodFilters = useMemo(
    () => createPeriodFilters(selectedPeriodFilters),
    [selectedPeriodFilters]
  )
  const [periodFilters, setPeriodFilters] = useState(defaultPeriodFilters)
  const [isOverdueMode, setIsOverdueMode] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [areMobileFiltersExpanded, setAreMobileFiltersExpanded] =
    useState(false)
  const [leave, setLeave] = useState<ILeave>(null)

  const userResult: IUseUser = useUser()

  const userContextValue: IUserContext = useMemo(
    () => getUserContextValue(userResult),
    [userResult]
  )

  const countryCode = useMemo(
    () => getCountryCode(userResult.user),
    [userResult]
  )

  usePageTitle('journeyMap')

  useEffect(
    () =>
      setSelectedPriorityFilters(
        priorityFilters.filter(f => f.checked).map(f => f.priority)
      ),
    [priorityFilters] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(
    () =>
      setSelectedPeriodFilters(
        periodFilters.filter(f => f.checked).map(f => f.type)
      ),
    [periodFilters] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (!isMobile && wasMobile) {
      setAreMobileFiltersExpanded(false)
    }
  }, [isMobile, wasMobile, setAreMobileFiltersExpanded])

  useEffect(() => {
    fetchLeaveJourneyMap({
      navigate,
      location,
      setIsFetching,
      queries,
      shouldSendImpressionMetric: true,
      setLeave
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasNewTpaChanges(leave)) {
      navigate(planChangeRoute)
    }

    checkTransitionFlow(leave, navigate, skipTransitionFlow.skip)
  }, [leave, navigate, skipTransitionFlow])

  const onPeriodClick = useCallback(
    (index: number) => {
      setPeriodFilters(flipFilter(index, periodFilters))
    },
    [periodFilters]
  )

  const onFilterClick = useCallback(
    (index: number) => {
      setPriorityFilters(flipFilter(index, priorityFilters))
    },
    [priorityFilters]
  )

  const onResetFiltersClick = useCallback(() => {
    setPeriodFilters(resetFilters(periodFilters))
    setPriorityFilters(resetFilters(priorityFilters))
  }, [periodFilters, priorityFilters])

  const onOverdueClick = useCallback(() => {
    const newOverdueMode = !isOverdueMode
    setIsOverdueMode(newOverdueMode)
    setPeriodFilters(switchFiltersDisability(periodFilters, newOverdueMode))
    setPriorityFilters(switchFiltersDisability(priorityFilters, newOverdueMode))
  }, [periodFilters, priorityFilters, isOverdueMode])

  const onToggleError = useCallback(() => {
    toastManager.addError(t('common.somethingWentWrong'))
    setIsFetching(true)
    fetchLeaveJourneyMap({
      navigate,
      location,
      setIsFetching,
      queries,
      setLeave
    })
  }, [t, toastManager, navigate, location, queries])

  if (userResult?.user && leave) {
    const navigationComponent = getNavigationComponent(leave, {
      employeeEmail: userResult.user.email
    })
    if (navigationComponent) {
      return navigationComponent
    }
  }

  if (isFetching) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  const contextValue: IJourneyMapContext = {
    priorityFilters,
    periodFilters,
    isOverdueMode,
    onPeriodClick,
    onFilterClick,
    onResetFiltersClick,
    onOverdueClick,
    onMobileFiltersExpandStateDidChange: (expanded: boolean) => {
      setAreMobileFiltersExpanded(expanded)
    },
    blurred: areMobileFiltersExpanded,
    queries,
    navigate,
    location,
    onToggleError,
    leave,
    countryCode
  }

  if (showStaticJourneyMap(leave)) {
    return (
      <StaticJourneymapPage leaveType={leave.metadata.transitionFromType} />
    )
  }

  return (
    <JourneyMapContext.Provider value={contextValue}>
      <UserContext.Provider value={userContextValue}>
        <JourneyMapTopViewContainer>
          <JourneyMapSettingsContainer />
          <JourneyMapListContainer />
        </JourneyMapTopViewContainer>
      </UserContext.Provider>
    </JourneyMapContext.Provider>
  )
})

JourneyMapContainer.displayName = 'JourneyMapContainer'

export default withToastManager(withQueries(JourneyMapContainer))
