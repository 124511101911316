import React from 'react'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { H1 } from 'src/UIKit'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import {
  HeadingRenderer,
  LinkRenderer,
  TelUrlTransformer
} from 'src/utils/contentRenderUtils'
import { useTranslation } from 'react-i18next'

interface IProps {
  leaveType: ILeaveType
}

export const dataAttrs = {
  title: () => 'static-journey-map-title',
  content: () => 'static-journey-map-content'
}

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 736px;
        `
      : css`
          width: unset;
        `}

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 0 16px 16px;
      align-items: center;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px auto 32px;
        `
      : css`
          margin: 16px auto 20px;
        `}
`

const Content = styled(ReactMarkdown)`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  h2 {
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    color: ${(props: any) => props.theme.colors.dark80};

    ${props =>
      props.theme.isDesktop
        ? css`
            margin: 14px auto 20px;
            font-size: 22px;
          `
        : css`
            margin: auto;
            font-size: 20px;
          `}
  }

  p {
    ${props =>
      props.theme.isDesktop
        ? css`
            font-size: 16px;
          `
        : css`
            font-size: 14px;
          `}
  }

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  ul {
    list-style: disc;
    padding-left: 24px;
    white-space: break-spaces;
    margin-block-start: -20px;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

const StaticJourneymapPage = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { leaveType } = props

  return (
    <PageWrapper>
      <Container>
        <Title>{t(`staticJourneyMap.title_${leaveType}`)}</Title>
        <Content
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer,
            h2: HeadingRenderer
          }}
        >
          {t(`staticJourneyMap.content_${leaveType}`)}
        </Content>
      </Container>
    </PageWrapper>
  )
})

StaticJourneymapPage.displayName = 'StaticJourneymapPage'

export default StaticJourneymapPage
