import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import {
  IWithQueriesProps,
  IWithToastManager,
  UserRole
} from 'src/react-app-env'
import { withToastManager } from 'src/components/ToastManager'
import withQueries from 'src/components/HOC/withQueries'
import { queryLeaveJourneyMap } from 'src/graphql/queries'
import get from 'lodash.get'
import { useTranslation } from 'react-i18next'
import {
  INavigationMethodsData,
  renderItems
} from 'src/components/Header/components/Navigation/methods'
import RouteContext from 'src/routes/Context'
import ScreenContext from 'src/contexts/ScreenContext'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import { getIsEmployee } from 'src/utils/userUtils'
import { useNavigate, useLocation } from 'react-router-dom'

interface IProps extends IWithQueriesProps, IWithToastManager {
  className?: any
  onNavigated?: () => void
}

const NavigationContainer = styled.nav`
  display: flex;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`

const ListContainer = styled.ul`
  justify-self: right;
  display: flex;
  align-items: start;

  ${props =>
    props.theme.isMobile &&
    css`
      justify-self: left;
      flex-direction: column;
      align-items: flex-start;
    `}
`

export const Navigation = React.memo((props: IProps) => {
  const { className, onNavigated, toastManager } = props
  const { t } = useTranslation()
  const { isManagerRoute, isHrAdminRoute } = useContext(RouteContext)
  const { isDesktop } = useContext(ScreenContext)
  const userResult: IUseUser = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  const { data, loading } = useQuery(queryLeaveJourneyMap)
  if (loading) {
    return
  }

  const mdata: INavigationMethodsData = {
    leave: get(data, 'leave', null),
    t,
    toastManager,
    navigate,
    location,
    onNavigated,
    isManagerRoute,
    isHrAdminRoute,
    isEmployee: getIsEmployee(userResult.user?.roles as UserRole[])
  }

  return (
    <NavigationContainer
      className={className}
      role="navigation"
      aria-label="Main"
    >
      <ListContainer>{renderItems(mdata, isDesktop)}</ListContainer>
    </NavigationContainer>
  )
})

Navigation.displayName = 'Navigation'

export default withQueries(withToastManager(Navigation))
