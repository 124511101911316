import React, { useEffect } from 'react'
import ReactDom from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { MODAL_HIDDEN, MODAL_SHOWN } from 'src/constants/events'
import { header } from 'src/constants/frame'

interface IProps {
  children?: any
  onClick?: any
  childrenContainerStyle?: any
  role?: string
}

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 25%);
  z-index: ${zIndex.modals.overlay};
  display: flex;
  justify-content: center;
  align-items: center;

  ${() => css`
    animation: ${fadeInKeyFrames} 0.1s ease-in-out;
  `}

  @media print {
    background: transparent;
    align-items: flex-start;
    top: ${p => header.height(p.theme).px};
    bottom: unset;
    position: absolute;
    display: block;
  }
`

const ChildrenContainer = styled.div<any>`
  ${props => css`
    ${props.style}
  `}
`

const ModalsManager = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const { children, onClick, childrenContainerStyle, role } = props
    useEffect(() => {
      window.dispatchEvent(new Event(MODAL_SHOWN))

      return () => {
        window.dispatchEvent(new Event(MODAL_HIDDEN))
      }
    }, [])

    if (!children) {
      return null
    }

    let content: any

    if (childrenContainerStyle) {
      content = (
        <ChildrenContainer style={childrenContainerStyle}>
          {children}
        </ChildrenContainer>
      )
    } else {
      content = children
    }

    return ReactDom.createPortal(
      <StyledOverlay ref={ref} onClick={onClick} role={role}>
        {content}
      </StyledOverlay>,
      document.body
    )
  })
)

ModalsManager.displayName = 'ModalsManager'

export default ModalsManager
