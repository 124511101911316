import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import { Button, Icon } from 'src/UIKit'
import useComponentRect from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'

const { colors } = createTheme()

export const TOP_OFFSET = 16
export const MARGIN = 8
export const HORIZONTAL_OFFSET = 32
export const WIDTH = 353

export interface IBubbleProps {
  title: string
  body: string
  currentNumber: number
  totalNumbers: number
  onSkipClick: () => void
  onNextClick: () => void
  onPrevClick: () => void
  onCompleteClick: () => void
  top?: number
  left?: number
  bottom?: number
  triangleFromRight?: boolean
  triangleFromBottom?: boolean
}

const Container = styled.div<{
  $top: number
  $bottom: number
  $left: number
  $containerHeight: number
  $triangleFromBottom: boolean
  $triangleFromRight: boolean
  $animated: boolean
}>`
  position: fixed;
  left: ${props => props.$left}px;
  width: ${WIDTH}px;
  padding: 16px;
  border-radius: 8px;
  ${props => {
    const { $top, $bottom, $containerHeight } = props
    if ($bottom) {
      return css`
        top: ${$bottom - $containerHeight}px;
      `
    } else {
      return css`
        top: ${$top}px;
      `
    }
  }}
  box-sizing: border-box;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  background: ${props => props.theme.colors.main100};
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.bubblesOverlay.bubble};

  ${props =>
    props.$animated &&
    css`
      transition: all 200ms ease-in-out;
    `}
  &::after {
    content: '';
    position: absolute;
    left: ${props => !props.$triangleFromRight && '32px'};
    right: ${props => props.$triangleFromRight && '16px'};
    width: 0;
    height: 0;
    border: 16px solid transparent;
    margin-left: ${props => (props.$triangleFromRight ? '16px' : '-16px')};

    ${props =>
      props.$animated &&
      css`
        transition: all 200ms ease-in-out;
      `}

    ${props => {
      const { $triangleFromBottom } = props
      if ($triangleFromBottom) {
        return css`
          bottom: -14px;
          border-top-color: ${props.theme.colors.main100};
          border-bottom: 0;
        `
      } else {
        return css`
          margin-top: -16px;
          top: 2px;
          border-bottom-color: ${props.theme.colors.main100};
          border-top: 0;
        `
      }
    }}
  }
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.light100};
  font-weight: 700;
  line-height: 100%;
`

const NumbersText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.light100};
`

const Body = styled.span`
  margin: 12px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SkipOnboardingButton = styled.button`
  color: ${props => props.theme.colors.light100};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

const ControlPrevButton = styled.button`
  width: 40px;
  height: 40px;
  background: ${props => props.theme.colors.main100};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.light100};
  padding: 4px 4px 1px;
  margin-right: 10px;

  &:hover {
    background: ${props => props.theme.colors.light20};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
    outline: none;
  }
`

const ControlNextButton = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  width: 82px;
  height: 40px;
  border-radius: 8px;
`

export const dataAttrs = {
  title: () => 'bubble-text-title',
  next: () => 'bubble-button-next',
  complete: () => 'bubble-button-complete'
}

const Bubble = React.memo(
  React.forwardRef((props: IBubbleProps, ref: any) => {
    const {
      title,
      currentNumber,
      totalNumbers,
      body,
      onNextClick,
      onPrevClick,
      onSkipClick,
      onCompleteClick,
      top,
      left,
      bottom,
      triangleFromRight,
      triangleFromBottom
    } = props
    const { t } = useTranslation()

    const forceUpdate = useForceUpdate()
    const containerRef = useRef(null)
    const containerRect: any = useComponentRect(containerRef)
    const bodyRef = useRef(null)
    const bodyRect: any = useComponentRect(bodyRef)
    const [animated, setAnimated] = useState(false)

    useEffect(() => {
      if (!animated) {
        setAnimated(top !== 0 && left !== 0)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [top, left])

    useEffect(() => {
      forceUpdate()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyRect])

    useImperativeHandle(ref, () => containerRef.current)

    const controlsView: ReactNode = useMemo(() => {
      const isFirst: boolean = currentNumber === 1
      const isLast: boolean = currentNumber === totalNumbers
      const prevColor: string = colors.light100
      return (
        <ControlsContainer>
          {!isFirst && (
            <ControlPrevButton
              aria-label={t('timelineTutorial.prevButtonAlt')}
              onClick={() => {
                onPrevClick()
              }}
            >
              <Icon
                name={'arrow_prev'}
                settings={{
                  fill: prevColor
                }}
              />
            </ControlPrevButton>
          )}
          {isLast && (
            <ControlNextButton
              data-testid={dataAttrs.complete()}
              onClick={onCompleteClick}
              aria-label={t('timelineTutorial.completeButtonAlt')}
              appearance={'startUnbordered'}
            >
              {t('common.finish')}
            </ControlNextButton>
          )}
          {!isLast && (
            <ControlNextButton
              data-testid={dataAttrs.next()}
              onClick={onNextClick}
              aria-label={t('timelineTutorial.nextButtonAlt')}
              appearance={'startUnbordered'}
            >
              {t('common.next')}
            </ControlNextButton>
          )}
        </ControlsContainer>
      )
    }, [
      currentNumber,
      onCompleteClick,
      onNextClick,
      onPrevClick,
      t,
      totalNumbers
    ])

    const ariaLabel: string = useMemo(
      () =>
        t('timelineTutorial.accessibility.tutorialStepAria', {
          current: currentNumber,
          total: totalNumbers,
          title,
          body
        }),
      [t, currentNumber, totalNumbers, title, body]
    )

    return (
      <Container
        ref={containerRef}
        $top={top}
        $left={left}
        $bottom={bottom}
        $triangleFromRight={triangleFromRight}
        $triangleFromBottom={triangleFromBottom}
        $containerHeight={containerRect.height}
        $animated={animated}
      >
        <TopContainer aria-label={ariaLabel} role={'alert'}>
          <Title data-testid={dataAttrs.title()} aria-hidden>
            {title}
          </Title>
          <SkipOnboardingButton onClick={onSkipClick}>
            {t('timelineTutorial.skipOnBoarding')}
          </SkipOnboardingButton>
        </TopContainer>
        <Body ref={bodyRef} aria-hidden>
          {body}
        </Body>
        <BottomContainer
          role={'region'}
          aria-label={t('timelineTutorial.accessibility.controls')}
        >
          <NumbersText aria-hidden>
            {t('timelineTutorial.stepText', { currentNumber, totalNumbers })}
          </NumbersText>
          {controlsView}
        </BottomContainer>
      </Container>
    )
  })
)

Bubble.displayName = 'Bubble'

export default Bubble
