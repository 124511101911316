import { Moment } from 'moment'
import { ITimelinePeriod } from 'src/react-app-env'
import {
  isPreOrdinaryMaternity,
  isPostOrdinaryMaternity
} from 'src/utils/leaveUtils'
import { isBlankDate, isEndOfDay } from 'src/utils/dateUtils'

const excludeChangesLeaveTypes = [
  'Partner',
  'Miscarriage',
  'PartnerMiscarriage',
  'Personal',
  'Adoption'
]

export interface IPeriodPickerMethodsInput {
  dueDate?: string
  date?: Moment
  initialSelectedDays?: number
  selectedDays?: number
  setSelectedDays?: (days: number) => void
  makeInvalid?: () => void
  makeValid?: () => void
  isSendingChanges?: boolean
  timelinePeriods?: ITimelinePeriod[]
  period?: ITimelinePeriod
  leave?: ILeave
  prevLeave?: ILeave
  setIsSendingChanges?: (value: boolean) => void
  onExit?: () => void
  onNewChanges?: (changes: any, forceUpdateLeave: boolean) => void
}

export interface IChangesIntermittentBlock {
  startDate?: Moment
  duration?: number
  activeDaysWeekOne?: IWeekday[]
  activeDaysWeekTwo?: IWeekday[]
  isNew?: boolean
}

interface IChangesInput {
  timelinePeriods: ITimelinePeriod[]
  period: ITimelinePeriod
  duration?: number
  startDate: string
  endDate?: string
  dueDate?: string
  leave: ILeave
}

interface IChangesInputForPeriod {
  period: ITimelinePeriod
  duration?: number
  intermittentBlockChanges?: IChangesIntermittentBlock
  extra?: IExtraUpdateInput
}

interface IExtraInputParameters {
  period: ITimelinePeriod
  balance?: number
  isDurationApproved?: boolean
}

/**
 * Some specific leave types can accept only one of DueDate/StartDate
 * and change a second date automatically.
 * @param leave
 * @param period
 */
export const excludeChanges = (leave: ILeave, period: ITimelinePeriod): any => {
  if (!excludeChangesLeaveTypes.includes(leave.type)) {
    return {}
  }

  if (
    period.type === 'DueDate' ||
    period.type === 'PregnancyLossDate' ||
    (period.type === 'EndDate' && leave.type === 'Personal')
  ) {
    return { startDate: null }
  }

  if (period.type === 'StartDate') {
    if (leave.type === 'Personal') {
      return { endDate: null }
    }
    return { dueDate: null }
  }

  return {}
}

export const getPeriodDuration = (period: ITimelinePeriod): number => {
  if (
    isBlankDate(period.periodStart.current) ||
    isBlankDate(period.periodEnd.current)
  ) {
    return 0
  }
  const start: Moment = period.periodStart.current
    ? period.periodStart.current
    : period.startDate
  const end: Moment = period.periodEnd.current
    ? period.periodEnd.current
    : period.endDate

  return end.clone().utc().add(1, 'second').diff(start.utc(), 'days')
}

export const getDaysBetweenDates = (m1: Moment, m2: Moment): number => {
  if (!m1 || !m2) {
    return 0
  }

  if (isEndOfDay(m1)) {
    m1 = m1.clone().add(1, 'second')
  }

  if (isEndOfDay(m2)) {
    m2 = m2.clone().add(1, 'second')
  }

  return Math.abs(m1.utc().diff(m2.utc(), 'days'))
}

export const calculateDuration = (
  period: ITimelinePeriod,
  periodKeyDate: string,
  newDate: Moment
): number => {
  const { periodStart, periodEnd } = period
  const startOfDay = newDate.startOf('day')

  if (periodKeyDate === 'periodEnd') {
    const start = isBlankDate(periodStart.current)
      ? periodStart.min
      : periodStart.current
    return getDaysBetweenDates(start, startOfDay) + 1
  }

  if (periodKeyDate === 'periodStart') {
    const end = isBlankDate(periodEnd.current)
      ? periodEnd.min
      : periodEnd.current
    return getDaysBetweenDates(startOfDay, end)
  }

  return 0
}

export const getWeeksBetweenDates = (m1: Moment, m2: Moment): number => {
  if (!m1 || !m2) {
    return 0
  }

  if (isEndOfDay(m1)) {
    m1 = m1.clone().add(1, 'second')
  }

  if (isEndOfDay(m2)) {
    m2 = m2.clone().add(1, 'second')
  }

  return Math.abs(m1.diff(m2, 'weeks'))
}

const convertPeriodType = (period: ITimelinePeriod): string => {
  if (isPreOrdinaryMaternity(period)) {
    return 'PreOrdinaryMaternity'
  }

  if (isPostOrdinaryMaternity(period)) {
    return 'PostOrdinaryMaternity'
  }

  return period.type as string
}

export const getAnnualDays = (period: ITimelinePeriod): number => {
  if (period.type === 'Annual' && period.blocks.length > 0) {
    return period.blocks.find(p => p.key === 'Annual.0')?.durationDays || 0
  }
  return 0
}

const getSicknessChanges = (
  result: any,
  timelinePeriods: any
): IChangesInput => {
  let countDays = 0
  timelinePeriods.forEach((p: ITimelinePeriod) => {
    if (p.appearance !== 'KeyDate') {
      countDays += getPeriodDuration(p)
    }
  })
  result.periods.push({
    type: 'Sickness',
    duration: countDays
  })
  return result
}

export const isIntermittent = (period: ITimelinePeriod) => !!period.schedule

export const hasIntermittent = (periods: ITimelinePeriod[]) =>
  !!periods?.find(p => isIntermittent(p))

export const getIntermittentBlocks = ({
  type,
  period,
  changes,
  timelinePeriods
}: any) => {
  const periodBlocks = timelinePeriods.filter(
    (p: ITimelinePeriod) => isIntermittent(p) && p.type === type
  )

  return periodBlocks.map((p: ITimelinePeriod) => {
    const block = p.blocks[0]
    const isPeriodToUpdate = p.hash === period.hash
    return isPeriodToUpdate && changes
      ? changes
      : {
          startDate: p.periodStart.current,
          duration: getPeriodDuration(p),
          activeDaysWeekOne: block?.activeDaysWeekOne,
          activeDaysWeekTwo: block?.activeDaysWeekTwo
        }
  })
}

export const getChanges = ({
  timelinePeriods,
  duration,
  period,
  dueDate,
  startDate,
  endDate,
  leave
}: IChangesInput): any => {
  const result: any = {
    periods: []
  }

  if (leave.type !== 'Maternity') {
    result.startDate = startDate
  }

  if (dueDate) {
    result.dueDate = dueDate
  }

  if (endDate) {
    result.endDate = endDate
  }

  if (leave.type === 'Sickness' && !period) {
    return getSicknessChanges(result, timelinePeriods)
  }

  if (leave.type === 'Personal') {
    return result
  }

  if (!timelinePeriods) {
    return result
  }

  let destroyRestOfTimeline = false

  timelinePeriods.forEach((p: ITimelinePeriod) => {
    const isPeriodToUpdate = p.hash === period?.hash

    if (
      !isPeriodToUpdate &&
      (p.type === 'PublicHolidays' || p.appearance !== 'Standard')
    ) {
      return
    }

    if (
      duration === 0 &&
      isPeriodToUpdate &&
      shouldDestroyRestOfTimeline(period)
    ) {
      destroyRestOfTimeline = true
    }

    if (destroyRestOfTimeline) {
      return
    }

    const d =
      isPeriodToUpdate && duration !== undefined
        ? duration
        : getAnnualDays(p) || getPeriodDuration(p)
    if (d === 0) {
      return
    }

    const periodObject: any = { type: convertPeriodType(p), duration: d }

    result.periods.push(periodObject)
  })

  return result
}

const shouldDestroyRestOfTimeline = (period: ITimelinePeriod) =>
  period.type !== 'AdditionalMaternity' &&
  period.type !== 'AdditionalAdoption' &&
  period.type !== 'Sickness' &&
  period.type !== 'PreDelivery'

export const getChangesForPeriodIntermittent = ({
  period,
  intermittentBlockChanges,
  extra = {}
}: IChangesInputForPeriod): any => {
  const result: any = {
    period: {
      type: period.type,
      id: intermittentBlockChanges?.isNew ? null : period.id
    },
    extra
  }

  if (period.refID) {
    result.period.refID = period.refID
  }

  if (period.parent) {
    result.extra.freezeExistingPeriods = true
  }

  if (period.appearance === 'Addable') {
    result.period.startDate = intermittentBlockChanges.startDate
  } else {
    result.period.startDate = intermittentBlockChanges?.startDate
    result.period.duration = intermittentBlockChanges?.duration
    result.period.activeDaysWeekOne =
      intermittentBlockChanges?.activeDaysWeekOne
    result.period.activeDaysWeekTwo =
      intermittentBlockChanges?.activeDaysWeekTwo
  }

  return result
}

export const getChangesForPeriod = ({
  period,
  intermittentBlockChanges,
  duration,
  extra = {}
}: IChangesInputForPeriod): any => {
  const result: any = {
    period: {
      type: period.type,
      id: intermittentBlockChanges?.isNew ? null : period.id,
      duration
    },
    extra
  }

  if (period.refID) {
    result.period.refID = period.refID
  }

  if (period.parent) {
    result.extra = { freezeExistingPeriods: true }
  }

  return result
}

export const getExtraParameters = ({
  period,
  balance,
  isDurationApproved
}: IExtraInputParameters): IExtraUpdateInput => {
  const result: IExtraUpdateInput = {}

  if (period.timelineConfig.periodPickerShowBalance) {
    result.PTOBalance = balance
  }
  if (period.timelineConfig.useApprovedByTpa) {
    result.approvedState = isDurationApproved
  }

  return result
}
