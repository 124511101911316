/* eslint-disable react/display-name */
import React from 'react'
import { IIconProps } from 'src/react-app-env'

export default (props: IIconProps) => {
  const { day, mobile, active } = props?.extra || {}
  switch (day) {
    case 'm':
      if (mobile) {
        return active ? (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.49658 7.63281L5.21826 4.02344H3.87842V9H4.90381V7.63965L4.80127 5.29492L6.14111 9H6.84521L8.18848 5.2915L8.08594 7.63965V9H9.11475V4.02344H7.76807L6.49658 7.63281Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21826 4.02344L6.49658 7.63281L7.76807 4.02344H9.11475V9H8.08594V7.63965L8.18848 5.2915L6.84521 9H6.14111L4.80127 5.29492L4.90381 7.63965V9H3.87842V4.02344H5.21826Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      } else {
        return active ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.99561 9.24219L6.35205 4.60156H4.62939V11H5.94775V9.25098L5.81592 6.23633L7.53857 11H8.44385L10.1709 6.23193L10.0391 9.25098V11H11.3618V4.60156H9.63037L7.99561 9.24219Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.35205 4.60156L7.99561 9.24219L9.63037 4.60156H11.3618V11H10.0391V9.25098L10.1709 6.23193L8.44385 11H7.53857L5.81592 6.23633L5.94775 9.25098V11H4.62939V4.60156H6.35205Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      }
    case 't':
      if (mobile) {
        return active ? (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.99902 4.854H8.52344V4.02344H4.46973V4.854H5.97363V9H6.99902V4.854Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.52344 4.854H6.99902V9H5.97363V4.854H4.46973V4.02344H8.52344V4.854Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      } else {
        return active ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.6416 5.66943H10.6016V4.60156H5.38965V5.66943H7.32324V11H8.6416V5.66943Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6016 5.66943H8.6416V11H7.32324V5.66943H5.38965V4.60156H10.6016V5.66943Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      }
    case 'w':
      if (mobile) {
        return active ? (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM8.42773 4.02344L7.75781 7.56445L6.94092 4.02344H6.07275L5.25244 7.55762L4.5791 4.02344H3.55713L4.66113 9H5.69336L6.50342 5.6709L7.31348 9H8.3457L9.44971 4.02344H8.42773Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.75781 7.56445L8.42773 4.02344H9.44971L8.3457 9H7.31348L6.50342 5.6709L5.69336 9H4.66113L3.55713 4.02344H4.5791L5.25244 7.55762L6.07275 4.02344H6.94092L7.75781 7.56445Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      } else {
        return active ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4785 4.60156L9.61719 9.1543L8.56689 4.60156H7.45068L6.396 9.14551L5.53027 4.60156H4.21631L5.63574 11H6.96289L8.00439 6.71973L9.0459 11H10.373L11.7925 4.60156H10.4785Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.61719 9.1543L10.4785 4.60156H11.7925L10.373 11H9.0459L8.00439 6.71973L6.96289 11H5.63574L4.21631 4.60156H5.53027L6.396 9.14551L7.45068 4.60156H8.56689L9.61719 9.1543Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      }
    case 'f':
      if (mobile) {
        return active ? (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.05225 6.96631H8.021V6.13916H6.05225V4.854H8.26709V4.02344H5.02686V9H6.05225V6.96631Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.021 6.96631H6.05225V9H5.02686V4.02344H8.26709V4.854H6.05225V6.13916H8.021V6.96631Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      } else {
        return active ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.42432 8.38525H9.95557V7.32178H7.42432V5.66943H10.272V4.60156H6.10596V11H7.42432V8.38525Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.95557 8.38525H7.42432V11H6.10596V4.60156H10.272V5.66943H7.42432V7.32178H9.95557V8.38525Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      }
    case 's':
      if (mobile) {
        return active ? (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM7.1665 7.25C7.30322 7.35254 7.37158 7.50065 7.37158 7.69434C7.37158 7.87207 7.30322 8.01107 7.1665 8.11133C7.02979 8.20931 6.83952 8.2583 6.5957 8.2583C5.93945 8.2583 5.61133 7.98372 5.61133 7.43457H4.58252C4.58252 7.75586 4.66455 8.03841 4.82861 8.28223C4.99495 8.52604 5.23535 8.71859 5.5498 8.85986C5.86426 8.99886 6.21289 9.06836 6.5957 9.06836C7.14714 9.06836 7.58577 8.94645 7.91162 8.70264C8.23747 8.45654 8.40039 8.11816 8.40039 7.6875C8.40039 7.30013 8.26709 6.97656 8.00049 6.7168C7.73389 6.45703 7.30892 6.23942 6.72559 6.06396C6.40885 5.96826 6.16846 5.86572 6.00439 5.75635C5.84261 5.64697 5.76172 5.51139 5.76172 5.34961C5.76172 5.17643 5.83122 5.03744 5.97021 4.93262C6.10921 4.82552 6.30404 4.77197 6.55469 4.77197C6.81445 4.77197 7.01611 4.83577 7.15967 4.96338C7.30322 5.0887 7.375 5.2653 7.375 5.49316H8.40039C8.40039 5.19466 8.32292 4.92806 8.16797 4.69336C8.0153 4.45866 7.79997 4.27751 7.52197 4.1499C7.24398 4.02002 6.92725 3.95508 6.57178 3.95508C6.21859 3.95508 5.89958 4.01432 5.61475 4.13281C5.33219 4.2513 5.11458 4.4165 4.96191 4.62842C4.81152 4.83805 4.73633 5.07731 4.73633 5.34619C4.73633 5.86344 5.01888 6.27474 5.58398 6.58008C5.79134 6.69173 6.07275 6.80566 6.42822 6.92188C6.78369 7.03581 7.02979 7.14518 7.1665 7.25Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.37158 7.69434C7.37158 7.50065 7.30322 7.35254 7.1665 7.25C7.02979 7.14518 6.78369 7.03581 6.42822 6.92188C6.07275 6.80566 5.79134 6.69173 5.58398 6.58008C5.01888 6.27474 4.73633 5.86344 4.73633 5.34619C4.73633 5.07731 4.81152 4.83805 4.96191 4.62842C5.11458 4.4165 5.33219 4.2513 5.61475 4.13281C5.89958 4.01432 6.21859 3.95508 6.57178 3.95508C6.92725 3.95508 7.24398 4.02002 7.52197 4.1499C7.79997 4.27751 8.0153 4.45866 8.16797 4.69336C8.32292 4.92806 8.40039 5.19466 8.40039 5.49316H7.375C7.375 5.2653 7.30322 5.0887 7.15967 4.96338C7.01611 4.83577 6.81445 4.77197 6.55469 4.77197C6.30404 4.77197 6.10921 4.82552 5.97021 4.93262C5.83122 5.03743 5.76172 5.17643 5.76172 5.34961C5.76172 5.51139 5.84261 5.64697 6.00439 5.75635C6.16846 5.86572 6.40885 5.96826 6.72559 6.06396C7.30892 6.23942 7.73389 6.45703 8.00049 6.7168C8.26709 6.97656 8.40039 7.30013 8.40039 7.6875C8.40039 8.11816 8.23747 8.45654 7.91162 8.70264C7.58577 8.94645 7.14714 9.06836 6.5957 9.06836C6.21289 9.06836 5.86426 8.99886 5.5498 8.85986C5.23535 8.71859 4.99495 8.52604 4.82861 8.28223C4.66455 8.03841 4.58252 7.75586 4.58252 7.43457H5.61133C5.61133 7.98372 5.93945 8.2583 6.5957 8.2583C6.83952 8.2583 7.02979 8.20931 7.1665 8.11133C7.30322 8.01107 7.37158 7.87207 7.37158 7.69434Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      } else {
        return active ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.85693 8.75C9.03271 8.88184 9.12061 9.07227 9.12061 9.32129C9.12061 9.5498 9.03271 9.72852 8.85693 9.85742C8.68115 9.9834 8.43652 10.0464 8.12305 10.0464C7.2793 10.0464 6.85742 9.69336 6.85742 8.9873H5.53467C5.53467 9.40039 5.64014 9.76367 5.85107 10.0771C6.06494 10.3906 6.37402 10.6382 6.77832 10.8198C7.18262 10.9985 7.63086 11.0879 8.12305 11.0879C8.83203 11.0879 9.396 10.9312 9.81494 10.6177C10.2339 10.3013 10.4434 9.86621 10.4434 9.3125C10.4434 8.81445 10.272 8.39844 9.9292 8.06445C9.58643 7.73047 9.04004 7.45068 8.29004 7.2251C7.88281 7.10205 7.57373 6.97021 7.36279 6.82959C7.15479 6.68896 7.05078 6.51465 7.05078 6.30664C7.05078 6.08398 7.14014 5.90527 7.31885 5.77051C7.49756 5.63281 7.74805 5.56396 8.07031 5.56396C8.4043 5.56396 8.66357 5.646 8.84814 5.81006C9.03271 5.97119 9.125 6.19824 9.125 6.49121H10.4434C10.4434 6.10742 10.3438 5.76465 10.1445 5.46289C9.94824 5.16113 9.67139 4.92822 9.31396 4.76416C8.95654 4.59717 8.54932 4.51367 8.09229 4.51367C7.63818 4.51367 7.22803 4.58984 6.86182 4.74219C6.49854 4.89453 6.21875 5.10693 6.02246 5.37939C5.8291 5.64893 5.73242 5.95654 5.73242 6.30225C5.73242 6.96729 6.0957 7.49609 6.82227 7.88867C7.08887 8.03223 7.45068 8.17871 7.90771 8.32812C8.36475 8.47461 8.68115 8.61523 8.85693 8.75Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.12061 9.32129C9.12061 9.07227 9.03271 8.88184 8.85693 8.75C8.68115 8.61523 8.36475 8.47461 7.90771 8.32812C7.45068 8.17871 7.08887 8.03223 6.82227 7.88867C6.0957 7.49609 5.73242 6.96729 5.73242 6.30225C5.73242 5.95654 5.8291 5.64893 6.02246 5.37939C6.21875 5.10693 6.49854 4.89453 6.86182 4.74219C7.22803 4.58984 7.63818 4.51367 8.09229 4.51367C8.54932 4.51367 8.95654 4.59717 9.31396 4.76416C9.67139 4.92822 9.94824 5.16113 10.1445 5.46289C10.3438 5.76465 10.4434 6.10742 10.4434 6.49121H9.125C9.125 6.19824 9.03271 5.97119 8.84814 5.81006C8.66357 5.646 8.4043 5.56396 8.07031 5.56396C7.74805 5.56396 7.49756 5.63281 7.31885 5.77051C7.14014 5.90527 7.05078 6.08398 7.05078 6.30664C7.05078 6.51465 7.15479 6.68896 7.36279 6.82959C7.57373 6.97021 7.88281 7.10205 8.29004 7.2251C9.04004 7.45068 9.58643 7.73047 9.9292 8.06445C10.272 8.39844 10.4434 8.81445 10.4434 9.3125C10.4434 9.86621 10.2339 10.3013 9.81494 10.6177C9.396 10.9312 8.83203 11.0879 8.12305 11.0879C7.63086 11.0879 7.18262 10.9985 6.77832 10.8198C6.37402 10.6382 6.06494 10.3906 5.85107 10.0771C5.64014 9.76367 5.53467 9.40039 5.53467 8.9873H6.85742C6.85742 9.69336 7.2793 10.0464 8.12305 10.0464C8.43652 10.0464 8.68115 9.9834 8.85693 9.85742C9.03271 9.72852 9.12061 9.5498 9.12061 9.32129Z"
              fill="white"
              fillOpacity="0.9"
            />
          </svg>
        )
      }
  }

  throw new Error(`Active day icon '${day}' is not implemented`)
}
