import styled, { css } from 'styled-components'
import DatePicker from 'src/components/DatePicker'

const datePickerMixin = css`
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 20px 0 0;
        `
      : css`
          margin: 16px 0 0;
        `}
`

const StyledDatePicker = styled(DatePicker)<{ $margin?: string }>`
  ${datePickerMixin}

  ${props =>
    props.$margin &&
    css`
      margin: ${props.$margin};
    `}
`

const DateSelectors = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`

const AlertsContainer = styled.div`
  width: 100%;
  margin: 0 0 20px;
`

export { datePickerMixin, StyledDatePicker, DateSelectors, AlertsContainer }
