import React, { ReactNode, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { PregnancyChangeStepView } from '../PregnancyChangeStepView'
import ScreenContext from 'src/contexts/ScreenContext'
import DatePickerDefaultView from 'src/components/DatePicker/components/views/DefaultView'
import moment from 'moment'
import DatePicker from 'src/components/DatePicker'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'
import { isMultiBirthLeaveType } from 'src/features/Leave/methods'

interface IProps {
  name: string
}

const datePickerMixin = css`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 360px;
        `
      : css`
          width: 100%;
        `}
`
const DatePickerWrapper = styled(DatePicker)`
  ${datePickerMixin}
`

const CheckboxConfirmationWrapper = styled(CheckboxConfirmationView)`
  width: 100%;
  margin-top: ${props => (props.theme.isDesktop ? '32px' : '16px')};
`

export const PregnancyLossView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props

  const {
    leave,
    dueDate,
    setDueDate,
    onNextStage,
    onPrevStage,
    expectedChildren,
    setExpectedChildren
  }: IPregnancyLossContext = useContext(PregnancyLossContext)

  const { isDesktop } = useContext(ScreenContext)
  const calendarTitle = useMemo(
    () => `${t('pregnancyChangeFlow.pages.pregnancyLoss.dateOfLoss')}:`,
    [t]
  )

  const birthDateView: ReactNode = useMemo(
    () => (
      <DatePickerWrapper
        TopViewComponent={DatePickerDefaultView}
        title={calendarTitle}
        momentCurrentMinMax={{
          current: dueDate,
          min: moment('1970-01-01'),
          max: moment()
        }}
        onDateChanged={date => setDueDate(date)}
        description=""
        mobileDialogTitle={calendarTitle}
        placeholder={t(`common.${isDesktop ? 'selectDate' : 'tapToSelect'}`)}
        isUsedDefaultOffset
      />
    ),
    [t, isDesktop, calendarTitle, dueDate, setDueDate]
  )

  const isMultiBirth = useMemo(() => isMultiBirthLeaveType(leave.type), [leave])

  const multipleBirthView: ReactNode = useMemo(
    () =>
      isMultiBirth && (
        <CheckboxConfirmationWrapper
          confirmed={expectedChildren !== null}
          translationKey={`pregnancyChangeFlow.pages.${name}.expectingAtLeastOneChild`}
          onChange={(value: boolean) =>
            setExpectedChildren(value ? ('One' as IExpectedChildren) : null)
          }
          fontSize={'16px'}
        />
      ),
    [name, expectedChildren, setExpectedChildren, isMultiBirth]
  )
  const content: any = useMemo(
    () => (
      <>
        {birthDateView}
        {multipleBirthView}
      </>
    ),
    [birthDateView, multipleBirthView]
  )

  return (
    <PregnancyChangeStepView
      name={name}
      onBackClicked={() => onPrevStage()}
      onNextClicked={() => onNextStage()}
      isNextDisabled={dueDate === null}
    >
      {content}
    </PregnancyChangeStepView>
  )
})

PregnancyLossView.displayName = 'PregnancyLossView'

export default PregnancyLossView
