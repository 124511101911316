import React, { useState, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { UIText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import FlexibleContainer from 'src/components/FlexibleContainer'
import ShareWithManagerView from 'src/features/UserProfile/components/SharingSettings/components/ShareWithManagerView'
import { withToastManager } from 'src/components/ToastManager'
import { IWithToastManager } from 'src/react-app-env'
import LoadingSpinner from 'src/components/LoadingSpinner'

interface IProps extends IWithToastManager {
  className?: any
  leave: ILeave
  managerEmail: string
  activateManagerForLeave: () => void
}

const DescriptionWrapper = styled(UIText)`
  white-space: pre-wrap;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 24px;
        `
      : css`
          margin: 0 0 8px;
        `}
`

const FlexibleContainerWrapper = styled(FlexibleContainer)`
  border: 1px solid ${props => props.theme.colors.dark10};
  height: auto;
`

const SharingSettings = React.memo((props: IProps) => {
  const {
    className,
    leave,
    managerEmail,
    activateManagerForLeave,
    toastManager
  } = props
  const [wasActivated, setWasActivated] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (wasActivated && leave.managerActivated) {
      toastManager.addInfo(t('sharingSettings.activatedToast'))

      setWasActivated(false)
    }
  }, [wasActivated, leave.managerActivated, t, toastManager])

  const onShareButtonClick = () => {
    setWasActivated(true)
    activateManagerForLeave()
  }

  const description = useMemo(
    () =>
      leave.managerActivated
        ? t('sharingSettings.descriptionActivated')
        : t('sharingSettings.descriptionNotActivated'),
    [t, leave.managerActivated]
  )

  return (
    <div className={className}>
      {wasActivated && <LoadingSpinner fullScreen fadesIn />}
      <DescriptionWrapper allowsParagraph>{description}</DescriptionWrapper>
      <FlexibleContainerWrapper>
        <ShareWithManagerView
          leave={leave}
          managerActivated={leave.managerActivated}
          onButtonClick={onShareButtonClick}
          email={managerEmail}
        />
      </FlexibleContainerWrapper>
    </div>
  )
})

SharingSettings.displayName = 'SharingSettings'

export default withToastManager(SharingSettings)
