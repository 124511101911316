import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: LeaveDatePeriodPicker,
  periodKeyDate: 'periodStart',
  removable: true,
  // TODO: When the parameter sendTimelineToTPAEnabled is removed,
  // needs to remove this condition and the corresponding SendToTpaDisabled keys in the translation
  detailsHintPostfix:
    input.leave.metadata?.sendTimelineToTPAEnabled === false
      ? 'SendToTpaDisabled'
      : baseConfig.detailsHintPostfix
})
