import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'src/UIKit'

interface IProps {
  className?: string
  approved: boolean
  hasChanges: boolean
  onClick: () => void
}

const Container = styled.div<{ $shown: boolean }>`
  width: 100%;
  margin: 20px 0 0;
  display: flex;
  cursor: pointer;
  user-select: none;
  transition:
    height 300ms ease-in,
    margin-top 300ms ease-in,
    opacity 300ms ease-in;

  ${(props: any) => {
    if (props.$shown) {
      return css`
        opacity: 1;
        height: 48px;
        pointer-events: auto;
        margin-top: 20px;
      `
    } else {
      return css`
        opacity: 0;
        height: 0;
        margin-top: 0;
        pointer-events: none;
      `
    }
  }}
`

const Text = styled.p`
  width: 100%;
  margin-left: 14px;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
`

const ApprovedByTpaView = React.memo((props: IProps) => {
  const { hasChanges, approved, onClick } = props
  const { t } = useTranslation()
  const text: string = useMemo(
    () => t('timeline.leaveDurationPicker.updatedDurationApprovedByTpa'),
    [t]
  )

  return (
    <Container $shown={hasChanges} onClick={onClick} aria-hidden={!hasChanges}>
      <Checkbox
        checked={approved}
        name={'approvedByTpa'}
        disabled={!hasChanges}
        ariaLabel={text}
      />
      <Text aria-hidden>{text}</Text>
    </Container>
  )
})

ApprovedByTpaView.displayName = 'ApprovedByTpaView'

export default ApprovedByTpaView
