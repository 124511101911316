import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import LoadingSpinner from 'src/components/LoadingSpinner'
import useSurvey from 'src/graphql/hooks/useSurvey'
import SurveyQuestionView from 'src/components/Surveys/SurveyQuestionView'
import { areSurveyAnswersValid } from 'src/utils/surveys'
import SurveyIntroductionView from 'src/components/Surveys/SurveyIntroductionView'
import SurveyQuestionViewBottomPanel from 'src/components/Surveys/SurveyQuestionView/components/BottomPanel'
import withQueries from 'src/components/HOC/withQueries'
import { IGraphqlExecutorOptions, IWithQueriesProps } from 'src/react-app-env'
import SurveySubmittedView from 'src/components/Surveys/SurveySubmittedView'

export type ResponseStrings = string | string[]

export enum SURVEY_STATE {
  ready,

  started,

  submitted
}

export type ContactUserType = 'Yes' | 'No'

interface IProps extends IWithQueriesProps {
  surveyId: string
  state: SURVEY_STATE
  onSubmit: () => void
  onStart: () => void
  onClose: () => void
  onError: (close: boolean) => void
  isGlobalSurvey?: boolean
  answerValue?: string
  absenceId?: string
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `}
`

const LoadingSpinnerContainer = styled.div`
  flex: 10000;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  ${props =>
    props.theme.isDesktop
      ? css`
          max-height: 570px;
        `
      : css`
          max-height: calc(100vh - 170px);
          max-height: calc(var(--vh, 1vh) * 100 - 170px);
        `}
`

const SurveyContainer = React.memo((props: IProps) => {
  const {
    surveyId,
    onSubmit,
    state,
    onStart,
    onClose,
    onError,
    queries: {
      createSurveyQuestionResponse,
      createAbsenceSurveyQuestionResponse
    },
    isGlobalSurvey,
    answerValue,
    absenceId
  } = props
  const [surveyResponseId, setSurveyResponseId] = useState(null)
  const [responding, setResponding] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const [isValid, setIsValid] = useState(false)
  const [contactUser, setContactUser] = useState<ContactUserType>('No')

  const { loading, survey, error } = useSurvey(surveyId)

  useEffect(() => {
    if (hasError) {
      onError(true)
    }
  }, [hasError, onError])

  const questions: ISurveyQuestion[] = useMemo(
    () => (survey ? survey.questions : []),
    [survey]
  )
  const questionsCount: number = useMemo(
    () => (survey ? questions.length : 0),
    [survey, questions]
  )

  const question: ISurveyQuestion = useMemo(
    () => questions[currentQuestionIndex],
    [questions, currentQuestionIndex]
  )

  const response: ResponseStrings = useMemo(
    () => (question ? (answers as any)[question.id] : null),
    [answers, question]
  )

  const isLastQuestion: boolean = useMemo(
    () => currentQuestionIndex === questionsCount - 1,
    [currentQuestionIndex, questionsCount]
  )

  const onAnswer = useCallback(
    (answer: ResponseStrings) => {
      const id: string = questions[currentQuestionIndex].id
      const newAnswers: any = { ...answers, [id]: answer }
      setIsValid(areSurveyAnswersValid(newAnswers, questions))
      setAnswers(newAnswers)
    },
    [questions, currentQuestionIndex, setIsValid, setAnswers, answers]
  )

  useEffect(() => {
    if (
      answerValue &&
      questions.length &&
      currentQuestionIndex === 0 &&
      question.responses.includes(answerValue)
    ) {
      onAnswer(answerValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, answerValue])

  const respond = useCallback(async () => {
    if (!response && !question.responseRequired && !isGlobalSurvey) {
      return
    }
    setResponding(true)
    const surveyResponse: ISurveyResponseInput = {
      questionID: question.id,
      response
    }

    if (isGlobalSurvey && isLastQuestion) {
      surveyResponse.extra = {
        contactUser: contactUser as IExtraSurveyResponseContactUser
      }
    }

    const surveyResponseIdToUse =
      question?.lastUserResponse?.id || surveyResponseId
    const queryOptions: IGraphqlExecutorOptions = {
      badRequest: () => {
        onError(false)
      }
    }

    const id = absenceId
      ? await createAbsenceSurveyQuestionResponse(
          absenceId,
          survey.id,
          surveyResponse,
          surveyResponseIdToUse,
          queryOptions
        )
      : await createSurveyQuestionResponse(
          survey.id,
          surveyResponse,
          surveyResponseIdToUse,
          queryOptions
        )
    setSurveyResponseId(id)
    setResponding(false)
  }, [
    question,
    response,
    setResponding,
    survey,
    surveyResponseId,
    onError,
    createSurveyQuestionResponse,
    contactUser,
    isGlobalSurvey,
    isLastQuestion,
    absenceId,
    createAbsenceSurveyQuestionResponse
  ])

  const nextClicked = useCallback(async () => {
    await respond()
    const index: number = Math.min(currentQuestionIndex + 1, questionsCount - 1)
    setCurrentQuestionIndex(index)
  }, [respond, currentQuestionIndex, questionsCount, setCurrentQuestionIndex])

  const submitSurvey = useCallback(
    () => respond().then(() => onSubmit()),
    [respond, onSubmit]
  )

  const currentQuestionView: ReactNode = useMemo(
    () => (
      <React.Fragment>
        <InnerContainer>
          <SurveyQuestionView
            question={question}
            onAnswer={onAnswer}
            currentResponse={response}
            isGlobalSurvey={isGlobalSurvey}
            isLast={isLastQuestion}
            contactUser={contactUser}
            setContactUser={setContactUser}
          />
        </InnerContainer>
        <SurveyQuestionViewBottomPanel
          totalCount={questionsCount}
          currentQuestionNumber={currentQuestionIndex + 1}
          onPrevClick={() => {
            const index: number = Math.max(currentQuestionIndex - 1, 0)
            setCurrentQuestionIndex(index)
          }}
          onNextClick={nextClicked}
          isFirst={currentQuestionIndex === 0}
          isLast={isLastQuestion}
          isNextEnabled={
            question?.responseRequired ? response?.length > 0 : true
          }
          showsSubmitButton={isLastQuestion}
          isSubmitButtonActive={isValid}
          onSubmit={submitSurvey}
        />
      </React.Fragment>
    ),
    [
      currentQuestionIndex,
      isValid,
      onAnswer,
      question,
      questionsCount,
      response,
      nextClicked,
      submitSurvey,
      isGlobalSurvey,
      contactUser,
      isLastQuestion
    ]
  )

  const introductionView: ReactNode = useMemo(
    () => <SurveyIntroductionView survey={survey} onStart={onStart} />,
    [survey, onStart]
  )

  const submittedView: ReactNode = useMemo(
    () => <SurveySubmittedView onClose={onClose} />,
    [onClose]
  )

  const currentStateView = useMemo(() => {
    switch (state) {
      case SURVEY_STATE.ready:
        return introductionView
      case SURVEY_STATE.started:
        return currentQuestionView
      case SURVEY_STATE.submitted:
        return submittedView

      default:
        return null
    }
  }, [introductionView, currentQuestionView, submittedView, state])

  if (error) {
    if (!hasError) {
      setHasError(true)
    }

    return null
  }

  return (
    <Container>
      {loading || responding ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ) : (
        currentStateView
      )}
    </Container>
  )
})

SurveyContainer.displayName = 'SurveyContainer'

export default withQueries(SurveyContainer)
