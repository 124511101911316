import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import NumStepper from 'src/components/NumStepper'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  className?: string
  title: string
  minDays: number
  maxDays: number
  valid: boolean
  days: number
  onChanged: (days: number) => void
  editable: boolean
}

const Container = styled.div`
  user-select: none;
`

const Title = styled.span`
  display: block;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  margin: 0 0 15px;
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  width: 132px;
`

const DaysText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  margin-left: 8px;
`

const DurationText = styled(MarkdownText)`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};

  strong {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.dark80};
  }
`

const PeriodDurationPickerDay = React.memo((props: IProps) => {
  const { className, title, minDays, maxDays, onChanged, editable, days } =
    props
  const { t } = useTranslation()

  const numStepper: ReactNode = useMemo(
    () =>
      editable && (
        <NumStepper
          minValue={minDays}
          maxValue={maxDays}
          onValueChanged={onChanged}
          value={days}
        />
      ),
    [maxDays, onChanged, editable, days, minDays]
  )

  const daysText: ReactNode = useMemo(
    () =>
      editable && (
        <DaysText aria-hidden>
          {t('common.durations.day', {
            count: days
          })}
        </DaysText>
      ),
    [days, t, editable]
  )

  const durationString: string = useMemo(() => {
    if (editable) {
      return null
    }
    const d: number = days
    const strD: string =
      d !== 0
        ? `${t('common.durations.dayValueBold', {
            count: d
          })}`
        : ''
    return `${strD}`
  }, [editable, days, t])

  const ariaLabel: string = useMemo(
    () =>
      durationString
        ? t('timeline.periodPicker.regionLeaveDurationWithValue', {
            duration: durationString.replace(/\*/g, '')
          })
        : t('timeline.periodPicker.regionLeaveDuration'),
    [durationString, t]
  )

  return (
    <Container className={className} role={'region'} aria-label={ariaLabel}>
      <Title aria-hidden>{title}</Title>
      <BottomContainer>
        {numStepper}
        {daysText}
        <div aria-hidden>
          <DurationText>{durationString}</DurationText>
        </div>
      </BottomContainer>
    </Container>
  )
})

PeriodDurationPickerDay.displayName = 'PeriodDurationPickerDay'

export default PeriodDurationPickerDay
