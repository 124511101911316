import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../index'
import moment from 'moment'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate).utc().format(DATE_FORMAT_WITH_DAY)
})
