import React from 'react'
import { Moment } from 'moment'
import { ICountryCode, ILeaveGroupWithTypes } from 'src/react-app-env'
import { ICustomerLeaveCreateConfig } from 'src/config/customers/config'

export type TpaApprovalState = 'none' | 'approved' | 'notApproved'
export type MilitaryTraining = 'none' | 'activeDuty' | 'adHoc'
export type MedicalWorkRelatedInjury = 'none' | 'yes' | 'no'
export type TpaLeaveState = 'none' | 'yes' | 'no'

export interface ICreateLeaveContext {
  countryCode: ICountryCode
  countryState: string
  customer: string
  customerLeaveCreateConfig: ICustomerLeaveCreateConfig
  fetchStates: () => Promise<string[]>
  highlightedDateNow: Moment
  injuryState: MedicalWorkRelatedInjury
  isCreatingLeave: boolean
  isFetchingLeave: boolean
  isMilitaryIncludingReturn: boolean
  isMoreThanOneChild: boolean
  leaveGroups: ILeaveGroupWithTypes[]
  leaveStatus: string
  leaveTypes: ILeaveTypeInfo[]
  maxDate: Moment
  maxEndDate: Moment
  militaryDaysCount: number
  minDate: Moment
  minEndDate: Moment
  nextCreateLeaveStage: (...args: any[]) => void
  onDueDateChanged: (date: Moment) => void
  onEndDateChanged: (date: Moment) => void
  onLeaveGroupSelected: (name: string) => void
  onReinstatementDateChanged: (date: Moment) => void
  onStartDateChanged: (date: Moment) => void
  onTpaApprovalStateChange: (value: TpaApprovalState) => void
  onTypeChanged: (leaveType: ILeaveTypeInfo) => void
  onWorkDayClicked: (week: number, day: string) => void
  prevCreateLeaveStage: () => void
  reinstatementMaxDurations: any[]
  resetWorkSchedule: () => void
  selectedDueDate: Moment
  selectedEndDate: Moment
  selectedLeaveGroupName: string
  selectedLeaveType: ILeaveTypeInfo
  selectedReinstatementDate: Moment
  selectedStartDate: Moment
  setCountryState: (s: string) => void
  setInjuryState: (value: MedicalWorkRelatedInjury) => void
  setIsMoreThanOneChild: (value: boolean) => void
  setLeaveStatus: (value: string) => void
  setSubtype: (value: string) => void
  setTimelinePreference: (value: string) => void
  setUseTpaLeave: (value: TpaLeaveState) => void
  setWorkSchedule: (value: string) => void
  setWorkScheduleStartDate: (m: Moment) => void
  showsMoreThanSixMonthsMilitaryNote: boolean
  showsMoreThanTenWeeksMedicalNote: boolean
  subtype: string
  timelinePreference: string
  tpaApprovalState: TpaApprovalState
  tpaLeave: ILeave
  user: IUser
  useTpaLeave: TpaLeaveState
  workDays: Array<Set<string>>
  workSchedule: string
  workScheduleStartDate: Moment
}

export default React.createContext(null)
