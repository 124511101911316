import moment, { Moment } from 'moment'
import { NavigateFunction, Location } from 'react-router-dom'
import { AbsenceTimelineType, IAbsenceTimelineItem } from 'src/react-app-env'
import { getPeriodDurationTextComponents } from '../leaveUtils'

export const sortAbsencesByStartDate = (absences: IAbsence[]): IAbsence[] => {
  if (!absences) {
    return null
  }

  return [...absences].sort((a1: IAbsence, a2: IAbsence) =>
    moment(a1.startDate).diff(moment(a2.startDate))
  )
}

export const sortAbsencesByCreatedDate = (absences: IAbsence[]): IAbsence[] => {
  if (!absences) {
    return null
  }

  return [...absences].sort((a1: IAbsence, a2: IAbsence) =>
    moment(a2.createdAt).diff(moment(a1.createdAt))
  )
}

export const getAbsenceById = (absences: IAbsence[], id: string): IAbsence => {
  for (const a of absences) {
    if (a.id === id) {
      return a
    }
  }
  return null
}

export const openItemIfNeeded = (
  navigate: NavigateFunction,
  location: Location,
  absences: IAbsence[],
  selectAbsence: (a: IAbsence) => void
) => {
  try {
    const routerState: any = location.state
    if (routerState && routerState.openItemId) {
      const journeyMapItemId: string = routerState.openItemId
      const absenceId: string = routerState.absenceId
      if (absenceId && journeyMapItemId) {
        const absence: IAbsence = getAbsenceById(absences, absenceId)
        if (absence) {
          selectAbsence(absence)
          navigate(location.pathname, {
            replace: true,
            state: { openItemId: routerState.openItemId }
          })
        }
      }
    }
  } catch (e) {
    navigate(location.pathname, { replace: true, state: {} })
  }
}

export const getAbsenceTimeline = (
  absence: IAbsence
): IAbsenceTimelineItem[] => {
  const periods: IAbsenceTimelineItem[] = absence.timeline.map(period => ({
    type: period.type as AbsenceTimelineType,
    startDate: moment(period.startDate).utc(),
    endDate: moment(period.endDate).utc()
  }))

  const leaveStart: IAbsenceTimelineItem = {
    type: 'LeaveStart' as AbsenceTimelineType,
    startDate: moment(absence.startDate).utc(),
    endDate: moment(absence.startDate).utc()
  }

  const leaveEnd: IAbsenceTimelineItem = {
    type: 'LeaveEnd' as AbsenceTimelineType,
    startDate: moment(absence.endDate).utc(),
    endDate: moment(absence.endDate).utc()
  }

  const now = moment.utc().startOf('day')
  const items = [leaveStart, ...periods, leaveEnd].sort((item1, item2) =>
    item1.startDate.diff(item2.startDate)
  )

  const [firstItem] = items
  if (now.isBefore(firstItem.startDate)) {
    const endDate = moment(firstItem.startDate)
      .utc()
      .subtract(1, 'day')
      .endOf('day')
    const atWork: IAbsenceTimelineItem = {
      isBeforeLeave: true,
      type: 'AtWork' as AbsenceTimelineType,
      startDate: now,
      endDate
    }

    items.unshift(atWork)
  }

  return items
}

export const getAbsencePeriodDuration = (
  period: IAbsenceTimelinePeriod
): number => {
  const start: Moment = moment(period.startDate)
  const end: Moment = moment(period.endDate)

  return end.clone().utc().add(1, 'second').diff(start.utc(), 'days')
}

export const getAbsencePeriodDurationString = (
  period: IAbsenceTimelinePeriod,
  t: (key: string, extras: any) => any
) => {
  const days = getAbsencePeriodDuration(period)
  return getPeriodDurationTextComponents(days, t)
}

export const isCurrentAbsencePeriod = (
  period: IAbsenceTimelinePeriod
): boolean => {
  const now = moment.utc()
  return (
    now.isSameOrAfter(period.startDate) && now.isSameOrBefore(period.endDate)
  )
}

export const yearBounds = (absences: IAbsence[]) => {
  const currentYear = moment().year()
  let min = currentYear
  let max = currentYear

  absences.forEach(absence => {
    const periods = absence.timeline.filter(period => period.type !== 'AtWork')
    const startYear = Math.min(
      ...periods.map(period => moment(period.startDate).utc().year())
    )
    const endYear = Math.max(
      ...periods.map(period => moment(period.endDate).utc().year())
    )

    min = startYear < min ? startYear : min
    max = endYear > max ? endYear : max
  })

  return { min, max }
}
