import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
  text: string
  shown: boolean
  cursor?: string
}

const Container = styled.div<{ $shown: boolean; $cursor: string }>`
  width: 100%;
  display: flex;
  cursor: ${props => props.$cursor};
  user-select: none;
  border-radius: 8px;
  background: ${props => props.theme.colors.error05};
  transition:
    height 300ms ease-in,
    margin-top 300ms ease-in,
    opacity 300ms ease-in;

  ${(props: any) => {
    if (props.$shown) {
      return css`
        opacity: 1;
        pointer-events: auto;
        margin-bottom: 16px;
      `
    } else {
      return css`
        opacity: 0;
        height: 0;
        margin-top: 0;
        pointer-events: none;
      `
    }
  }}
`

const IconContainer = styled.div<any>`
  margin: 9px 0 0 9px;
`

const Text = styled.span`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  line-height: 130%;
  font-weight: normal;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-line;
`

const AlertView = React.memo((props: IProps) => {
  const { text, shown, cursor = 'pointer' } = props

  return (
    <Container
      $shown={shown}
      $cursor={cursor}
      aria-hidden={!shown}
      role={'alert'}
    >
      <IconContainer>
        <Icon name={'exclamation_sign'} />
      </IconContainer>
      <Text>{text}</Text>
    </Container>
  )
})

AlertView.displayName = 'AlertView'

export default AlertView
