import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  label: string
  text: string
  value?: string
  isRedText?: boolean
}

const Label = styled.span`
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const Value = styled.span`
  margin: 0;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
  transform: translate(0, 4px);
`

const Text = styled.span<{ $isRedText: boolean }>`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${(props: any) =>
    props.$isRedText ? props.theme.colors.error80 : props.theme.colors.dark60};
`

const Postfix = styled.span`
  margin: 0 0 0 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const RowContainer = styled.div<{ $noMarginTop?: boolean }>`
  width: 100%;
  margin: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  ${props =>
    props.$noMarginTop &&
    css`
      margin-top: 0;
    `}
`

const ValueAndPostfixContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const LeaveInfoRow = React.memo((props: IProps) => {
  const { className, label, text, value, isRedText } = props

  if (value) {
    return (
      <RowContainer $noMarginTop aria-label={`${label} ${value}`} role={'note'}>
        <Label aria-hidden>{label}</Label>
        <ValueAndPostfixContainer>
          <Value aria-hidden>{value}</Value>
          <Postfix aria-hidden>{text}</Postfix>
        </ValueAndPostfixContainer>
      </RowContainer>
    )
  }

  return (
    <RowContainer
      className={className}
      aria-label={`${label} ${text}`}
      role={'note'}
    >
      <Label aria-hidden>{label}</Label>
      <Text $isRedText={isRedText} aria-hidden>
        {text}
      </Text>
    </RowContainer>
  )
})

LeaveInfoRow.displayName = 'LeaveInfoRow'

export default LeaveInfoRow
