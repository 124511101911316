import React from 'react'
import styled, { css } from 'styled-components'
import SurveyQuestionViewTitle from 'src/components/Surveys/SurveyQuestionView/components/Title'
import SurveyQuestionViewDescription from 'src/components/Surveys/SurveyQuestionView/components/Description'
import AnswerTypeText from 'src/components/Surveys/SurveyQuestionView/components/AnswerTypes/AnswerTypeText'
import AnswerTypeMultipleChoice from 'src/components/Surveys/SurveyQuestionView/components/AnswerTypes/AnswerTypeMultipleChoice'
import AnswerTypeNps from 'src/components/Surveys/SurveyQuestionView/components/AnswerTypes/AnswerTypeNps'
import AnswerTypeCheckChoice from './components/AnswerTypes/AnswerTypeCheckChoice'
import { ContactUserType, ResponseStrings } from '../SurveyContainer'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'

interface IProps {
  question: ISurveyQuestion
  currentResponse: ResponseStrings
  onAnswer: (answer: ResponseStrings) => void
  isGlobalSurvey: boolean
  isLast: boolean
  contactUser: ContactUserType
  setContactUser: (value: ContactUserType) => void
}

const Container = styled.div<{ $isGlobalSurvey: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: ${props.$isGlobalSurvey ? '0 32px 32px' : '32px'};
          width: calc(100% - 64px);
        `
      : css`
          width: calc(100% - 32px);
          ${props.theme.isMobileLandscape
            ? css`
                margin: 16px 16px 96px;
              `
            : css`
                margin: 16px;
              `}
        `}
`

const AnswerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 16px;
        `}
`

const CheckboxConfirmationWrapper = styled(CheckboxConfirmationView)`
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 24px 0 16px;
        `
      : css`
          margin: 16px 0;
          line-height: 130%;
        `}
`

const SurveyQuestionView = React.memo((props: IProps) => {
  const {
    question: {
      responseRequired,
      description,
      responses,
      text,
      type,
      labels,
      maxTextLength
    },
    onAnswer,
    currentResponse,
    isGlobalSurvey,
    isLast,
    contactUser,
    setContactUser
  } = props

  const renderAnswerSection = (): any => {
    switch (type) {
      case 'Text':
        return (
          <AnswerTypeText
            selectedResponse={currentResponse}
            onAnswer={onAnswer}
            maxTextLength={maxTextLength}
            isGlobalSurvey={isGlobalSurvey}
          />
        )
      case 'MultipleChoice':
        return (
          <AnswerTypeMultipleChoice
            responses={responses}
            onAnswer={onAnswer}
            selectedResponse={currentResponse}
          />
        )
      case 'NPS':
        return (
          <AnswerTypeNps
            responses={responses}
            onAnswer={onAnswer}
            selectedResponse={currentResponse}
            labels={labels}
            isGlobalSurvey={isGlobalSurvey}
          />
        )
      case 'Checkboxes':
        return (
          <AnswerTypeCheckChoice
            responses={responses}
            onAnswer={onAnswer}
            selectedResponse={currentResponse}
          />
        )

      default:
        return null
    }
  }

  return (
    <Container $isGlobalSurvey={isGlobalSurvey}>
      <SurveyQuestionViewTitle responseRequired={responseRequired}>
        {text}
      </SurveyQuestionViewTitle>
      {description && (
        <SurveyQuestionViewDescription>
          {description}
        </SurveyQuestionViewDescription>
      )}
      <AnswerContainer>{renderAnswerSection()}</AnswerContainer>
      {isLast && isGlobalSurvey && (
        <CheckboxConfirmationWrapper
          confirmed={contactUser === 'Yes'}
          translationKey={'surveys.contactUserCheckboxText'}
          onChange={() => {
            setContactUser(contactUser === 'Yes' ? 'No' : 'Yes')
          }}
          fontSize={'16px'}
        />
      )}
    </Container>
  )
})

SurveyQuestionView.displayName = 'SurveyQuestionView'

export default SurveyQuestionView
