import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { MarkdownText } from 'src/UIKit'
import Tippy from '@tippyjs/react'
import { Placement } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import ScreenContext from 'src/contexts/ScreenContext'
import zIndex from 'src/constants/zIndex'
import { tooltip } from 'src/constants/frame'
import { printDisplayNoneMixin } from 'src/theme/utils'

interface IDashedUnderline {
  skipUnderline?: boolean
  color?: string
}

interface IProps {
  body: string
  children: any
  dashedUnderline?: IDashedUnderline
  disabled?: boolean
  offset?: [number, number]
  placement?: Placement
  title?: string
  zIndexParam?: number
}

const Component = styled(Tippy)`
  &.tippy-box {
    background-color: ${props => props.theme.colors.dark60};
    border-radius: 8px;
  }

  .tippy-content {
    background-color: ${props => props.theme.colors.dark60};
    padding: ${props =>
      props.theme.isDesktop ? '12px 12px 10px 12px' : '10px 10px 8px 10px'};
    border-radius: 8px;
  }

  .tippy-arrow {
    color: ${props => props.theme.colors.dark60};
  }

  .tippy-arrow::before {
    transform: scale(1.3);
  }

  ${printDisplayNoneMixin};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => (props.theme.isDesktop ? '6px' : '4px')};
`

const Title = styled.span`
  font-weight: 500;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
`

const Body = styled(MarkdownText)`
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  line-height: ${props => (props.theme.isDesktop ? '150%' : '130%')};
  color: ${props => props.theme.colors.light100};
  white-space: pre-wrap;

  ol {
    list-style-type: decimal;
    padding-left: 1em;
    white-space: normal;
    margin-block-start: -11px;
    line-height: 150%;
  }

  ul {
    list-style-type: disc;
    padding-left: 1em;
    white-space: normal;
    margin-block-start: -18px;
    line-height: 150%;
  }
`

const Underline = styled.div<{ $color: string }>`
  border-bottom: 1px dashed
    ${props => props.$color || props.theme.colors.main80};
`

export const dataAttrs = {
  content: () => `timeline-tooltip-content`
}

const Tooltip = React.memo((props: IProps) => {
  const {
    title,
    body,
    children,
    dashedUnderline,
    offset,
    disabled,
    placement,
    zIndexParam
  } = props
  const { isMobile } = useContext(ScreenContext)

  const content = useMemo(
    () => (
      <ContentContainer data-testid={dataAttrs.content()}>
        {title && <Title>{title}</Title>}
        <Body allowsParagraph>{body}</Body>
      </ContentContainer>
    ),
    [body, title]
  )

  const renderChildren = useMemo(
    () =>
      dashedUnderline?.skipUnderline ? (
        <div>{children}</div>
      ) : (
        <Underline $color={dashedUnderline?.color}>{children}</Underline>
      ),
    [children, dashedUnderline]
  )

  return (
    <Component
      content={content}
      placement={placement || 'bottom-start'}
      trigger={isMobile ? 'click' : 'mouseenter focus'}
      offset={offset || tooltip.offset()}
      zIndex={zIndexParam || zIndex.tooltip}
      disabled={disabled}
    >
      {renderChildren}
    </Component>
  )
})

Tooltip.displayName = 'Tooltip'

export default Tooltip
