import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'
import styled from 'styled-components'
import { IIcon } from 'src/react-app-env'

interface IProps {
  countryCodes: string[]
  selectedCountryCode: string
  setSelectedCountryCode: (s: string) => void
}

const Text = styled.p`
  color: ${props => props.theme.colors.dark60};
  font-weight: 400;
  font-size: 14px;
`

const Container = styled.div`
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
`

const ButtonWrapper = styled(Button)`
  border-radius: 32px;
  height: 35px;
  padding: 0 6px;
  font-size: 14px;

  svg {
    border-radius: 50%;
    margin-top: 3px;
  }
`

export const dataAttrs = {
  button: (i: string) => `country-selector-button-${i}`
}

export const CountrySelector = React.memo((props: IProps) => {
  const { countryCodes, selectedCountryCode, setSelectedCountryCode } = props
  const { t } = useTranslation()

  const extendedCountryCodes = useMemo(
    () =>
      countryCodes.length > 1
        ? [GLOBAL_COUNTRY, ...countryCodes]
        : countryCodes,
    [countryCodes]
  )

  const buttons = useMemo(
    () =>
      extendedCountryCodes.map((countryCode: string) => {
        const icon: IIcon = {
          iconName: 'country',
          iconProps: { extra: { countryCode } }
        }

        return (
          <ButtonWrapper
            data-testid={dataAttrs.button(countryCode)}
            key={countryCode}
            appearance={'select'}
            disabled={countryCode === selectedCountryCode}
            onClick={() => setSelectedCountryCode(countryCode)}
            icon={icon}
          >
            {t(`common.countries_mobile.${countryCode}`)}
          </ButtonWrapper>
        )
      }),
    [t, extendedCountryCodes, selectedCountryCode, setSelectedCountryCode]
  )

  return (
    <Container>
      <Text>Country:</Text>
      <ButtonsContainer> {buttons} </ButtonsContainer>
    </Container>
  )
})

CountrySelector.displayName = 'CountrySelector'

export default CountrySelector
