import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import createIcon from 'src/UIKit/icons'
import createTheme from 'src/theme'
import usePrevious from 'src/components/hooks/usePrevious'

const theme = createTheme()

interface IProps {
  className?: string
  isOpen: boolean
  onClick?: () => void
  color?: string
  hoveredColor?: string
  pointerEvents?: string
  hovered?: boolean
}

const arrowKeyFrames = keyframes`
  0% {
    transform: rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
}
`

const ArrowContainer = styled.div<{
  $pointerEvents: string
  $shouldAnimate: boolean
}>`
  margin-bottom: 8px;
  margin-left: 10px;
  transform-origin: center 12px;
  pointer-events: ${props => props.$pointerEvents || 'auto'};
  ${props =>
    props.$shouldAnimate &&
    css`
      animation: ${arrowKeyFrames} 100ms linear forwards;
    `}
  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: -3px;
      margin-bottom: 2px;
    `}
`

export const dataAttrs = {
  arrow: () => `popover-arrow`
}

const PopoverArrow = React.memo((props: IProps) => {
  const {
    isOpen,
    onClick,
    className,
    color,
    pointerEvents,
    hovered,
    hoveredColor
  } = props

  const [mounted, setMounted] = useState(false)
  const prevIsOpen = usePrevious(isOpen)

  useEffect(() => {
    setMounted(true)
  }, [])

  const shouldAnimate: boolean = mounted && prevIsOpen !== isOpen

  let fill: string = color || theme.colors.main100
  if (hovered && hoveredColor) {
    fill = hoveredColor
  }

  return (
    <ArrowContainer
      data-testid={dataAttrs.arrow()}
      className={className}
      onClick={onClick}
      $shouldAnimate={shouldAnimate}
      $pointerEvents={pointerEvents}
    >
      {createIcon(isOpen ? 'arrow_triangle_up' : 'arrow_triangle_down', {
        fill
      })}
    </ArrowContainer>
  )
})

PopoverArrow.displayName = 'PopoverArrow'

export default PopoverArrow
