import * as d3 from 'd3'
import createTheme from 'src/theme'
import {
  createTopRoundedHorizontalPath,
  createTopRoundedVerticalPath,
  getAnimationDelay,
  getAnimationDuration,
  getVerticalAxisData,
  IVerticalAxisData
} from 'src/features/HrAdmin/components/charts/methods'

const { colors } = createTheme()

const TICKS_FONT_SIZE = 14

export default (args: any, parentContainer: any) => {
  const { data, isHorizontal, isDesktop, isMobile } = args
  const parentRect = parentContainer
    ? parentContainer.getBoundingClientRect()
    : { width: 0, height: 0 }
  const margin: any = isDesktop
    ? { top: 8, right: 0, bottom: 24, left: 14 }
    : { top: 24, right: 0, bottom: 24, left: 0 }
  const width: number = parentRect.width - margin.left - margin.right
  const height: number = parentRect.height - margin.top - margin.bottom

  const animationDuration: number = getAnimationDuration(data.length)
  const animationDelay: number = getAnimationDelay(data.length)

  const verticalAxisData: IVerticalAxisData = getVerticalAxisData(data)

  const d3svg: any = d3
    .select(parentContainer)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  const drawHorizontal = () => {
    const xh: any = d3.scaleLinear().range([0, width - 16])
    xh.domain([0, verticalAxisData.maxValue])

    const yh: any = d3.scaleLinear().range([height, 0])
    yh.domain([0, verticalAxisData.maxValue])
    d3.axisBottom(xh).tickSize(1).tickPadding(10)
    d3.axisLeft(yh)
      .tickValues(verticalAxisData.tickValues)
      .tickFormat(d3.format('d') as any)
      .tickSize(0)

    const barHeight = 16
    const barGap = 28

    d3svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('path')
      .style('display', (d: any) => (d.value === null ? 'none' : null))
      .style('fill', () => colors.main100)
      .attr('d', (d: any, index: number) =>
        createTopRoundedHorizontalPath(
          0,
          index * (barHeight + barGap),
          0,
          16,
          4
        )
      )
      .transition()
      .duration(animationDuration)
      .delay((d: any, i: number) => i * animationDelay)
      .attr('d', (d: any, index: number) =>
        createTopRoundedHorizontalPath(
          0,
          index * (barHeight + barGap),
          d.value === 0 ? 4 : xh(d.value),
          16,
          4
        )
      )

    d3svg
      .selectAll('.label')
      .data(data)
      .enter()
      .append('text')
      .style('display', (d: any) => (d.value === null ? 'none' : null))
      .style('font-size', `12px`)
      .style('color', colors.main100)
      .attr('x', 10)
      .style('fill', () => colors.main100)
      .attr('y', (d: any, index: number) => index * (barHeight + barGap) + 12)
      .transition()
      .duration(animationDuration)
      .delay((d: any, i: number) => i * animationDelay)
      .text((d: any) => d.value)
      .attr('x', (d: any) => (d.value === 0 ? 12 : xh(d.value) + 8))

    d3svg
      .selectAll('.text')
      .data(data)
      .enter()
      .append('text')
      .style('font-size', `12px`)
      .attr('x', 0)
      .style('fill', () => colors.dark60)
      .attr('y', (d: any, index: number) => index * (barHeight + barGap) - 6)
      .text((d: any) => d.label)
  }

  const drawVertical = () => {
    const xv: any = d3
      .scaleBand()
      .range([0, width])
      .padding(isDesktop ? 0.3 : 0.2)
    const yv: any = d3.scaleLinear().range([height, 0])

    const xAxis: any = d3
      .axisBottom(xv)
      .tickSize(isDesktop ? 1 : 0)
      .tickPadding(10)
    const yAxis: any = d3
      .axisLeft(yv)
      .tickValues(verticalAxisData.tickValues)
      .tickFormat(d3.format('d') as any)
      .tickSize(0)

    xv.domain(data.map((d: any) => d.label))
    yv.domain([0, verticalAxisData.maxValue])

    d3svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .style('font-size', '10px')
      .style('color', colors.dark60)
      .call(xAxis)
      .call((g: any) => {
        if (isMobile) {
          g.select('.domain').remove()
        }
      })

    if (isDesktop) {
      d3svg
        .append('g')
        .style('font-size', '10px')
        .style('color', colors.dark60)
        .call(yAxis)
    }

    d3svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('path')
      .style('display', (d: any) => (d.value === null ? 'none' : null))
      .style('fill', () => colors.main100)
      .attr('d', (d: any) =>
        createTopRoundedVerticalPath(xv(d.label), height, xv.bandwidth(), 0, 4)
      )
      .transition()
      .duration(animationDuration)
      .delay((d: any, i: number) => i * animationDelay)
      .attr('d', (d: any) =>
        createTopRoundedVerticalPath(
          xv(d.label),
          height,
          xv.bandwidth(),
          d.value === 0 ? -4 : yv(d.value) - height,
          4
        )
      )

    d3svg
      .selectAll('.label')
      .data(data)
      .enter()
      .append('text')
      .style('display', (d: any) => (d.value === null ? 'none' : null))
      .style('font-size', `${TICKS_FONT_SIZE}px`)
      .style('color', colors.main100)
      .attr('x', (d: any) => xv(d.label) + xv.bandwidth() / 2 - 4)
      .style('fill', () => colors.main100)
      .attr('y', () => height)
      .attr('height', 0)
      .transition()
      .duration(animationDuration)
      .delay((d: any, i: number) => i * animationDelay)
      .text((d: any) => d.value)
      .attr('y', (d: any) => yv(d.value) + 0.1)
      .attr('dy', '-.7em')
  }

  if (isHorizontal) {
    drawHorizontal()
  } else {
    drawVertical()
  }
}
