import React, { useCallback } from 'react'
import { Icon } from 'src/UIKit'
import styled from 'styled-components'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  selected: number
  min: number
  max: number
  onChange: (n: number) => void
}

const Container = styled.div`
  display: flex;
  gap: 13px;
`

const Button = styled.button`
  margin-top: 1px;
  height: 12px;
`

const Text = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
`

const LeftChevron = styled(Icon)`
  transform: rotate(180deg);
  margin-bottom: 3px;
  height: 12px;
`

const RightChevron = styled(Icon)`
  height: 12px;
`

export const dataAttrs = {
  left: () => 'year-scroll-btn-left',
  right: () => 'year-scroll-btn-right',
  currentYear: () => 'year-scroll-current-year'
}

const YearScroll = React.memo((props: IProps) => {
  const { selected, min, max, onChange } = props

  const canClickLeft = selected > min
  const canClickRight = selected < max

  const onLeftClick = useCallback(
    () => canClickLeft && onChange(selected - 1),
    [selected, canClickLeft, onChange]
  )

  const onRightClick = useCallback(
    () => canClickRight && onChange(selected + 1),
    [selected, canClickRight, onChange]
  )

  return (
    <Container>
      <Button
        data-testid={dataAttrs.left()}
        disabled={!canClickLeft}
        onClick={onLeftClick}
      >
        <LeftChevron
          name="chevron_right"
          settings={{ fill: canClickLeft ? colors.main90 : colors.main60 }}
        />
      </Button>

      <Text data-testid={dataAttrs.currentYear()}>{selected}</Text>

      <Button
        data-testid={dataAttrs.right()}
        disabled={!canClickRight}
        onClick={onRightClick}
      >
        <RightChevron
          name="chevron_right"
          settings={{ fill: canClickRight ? colors.main90 : colors.main60 }}
        />
      </Button>
    </Container>
  )
})

YearScroll.displayName = 'YearScroll'

export default YearScroll
