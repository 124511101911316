import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { queryNotificationsFeed } from 'src/graphql/queries'
import withQueries from 'src/components/HOC/withQueries'
import styled from 'styled-components'
import RouteContext from 'src/routes/Context'
import * as RouteUtils from 'src/utils/routeUtils'
import { markAllAsRead, seeAll } from './methods'
import NotificationsPopoverContent from './components/NotificationsPopoverContent'
import { useNavigate, useLocation } from 'react-router-dom'

const Container = styled.div`
  width: 360px;
  overflow: hidden;
`

const NotificationsPopoverContainer = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { notificationType } = useContext(RouteContext)

  const onMarkAllAsReadClicked = async () => {
    await markAllAsRead(props.queries.readAllNotifications, notificationType)
  }

  const onSeeAllClicked = () => {
    seeAll(navigate, notificationType)
  }

  const { pathname } = location

  const { data, loading, error } = useQuery(queryNotificationsFeed, {
    variables: { type: notificationType, after: null, count: 4 },
    fetchPolicy: 'cache-only',
    skip: RouteUtils.isHrAdminRoute(pathname)
  })

  if (error) {
    return
  }

  if (!data) {
    return
  }

  if (RouteUtils.isHrAdminRoute(pathname)) {
    return null
  }

  return (
    <Container>
      <NotificationsPopoverContent
        records={data.notifications.records}
        fetching={loading}
        onMarkAllAsReadClicked={onMarkAllAsReadClicked}
        onSeeAllClicked={onSeeAllClicked}
        {...props}
      />
    </Container>
  )
}

export default withQueries(NotificationsPopoverContainer)
