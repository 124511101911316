import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import TimelineView from 'src/features/Timeline/components/vertical/TimelineView'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import PrintNotes from 'src/features/Timeline/components/common/PrintNotes'
import PrintFooter from 'src/components/PrintFooter'
import Info from 'src/features/Timeline/components/common/Info'
import zIndex from 'src/constants/zIndex'
import { isSafari } from 'src/utils/deviceTypes'

const Container = styled.div`
  @media not print {
    display: none;
  }

  @media print {
    height: auto;
  }
`

const InfoWrapper = styled(Info)<any>`
  position: absolute;
  top: 5mm;
  left: 234mm;
  z-index: ${zIndex.timeline.info.printable};
  width: 100%;

  @media not print {
    display: none;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  top: 60px;
  margin-bottom: 200px;
`

const TimelineViewWrapper = styled(TimelineView)`
  width: 460px;
  padding: 0 0 24px;
  margin: 0;
`

const RightContainer = styled.div`
  width: 300px;
  margin: 0 0 0 24px;
  height: auto;
  position: relative;
`

const PrintNotesContainer = styled.div`
  inset: -14px 0 0;
`

const PrintNotesWrapper = styled(PrintNotes)`
  padding: 0 0 24px;
  font-size: 14px;
`
const NoteContainer = styled.div`
  position: absolute;
  inset: 0 0 2px;
  display: flex;
  align-items: flex-end;
  z-index: 10;
`

const NoteInnerContainer = styled.div`
  background: ${props => props.theme.colors.light100};
  padding: 48px 0 0;
`

const Note = styled.p`
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  padding: 16px;
  background: ${props => props.theme.colors.main10};
  border-radius: 8px;
  width: calc(100% - 32px);
`

const PrintFooterWrapper = styled(PrintFooter)`
  position: fixed;
  bottom: 2mm;
  left: 0;
  right: 0;
  margin: 0 11mm;

  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: 48px;
    `}

  ${isSafari() &&
  css`
    position: fixed;
    bottom: 23mm;
    left: 0;
  `}
`

const TimelinePageVerticalPrintable = React.memo(() => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave: { note },
    totalLeaveDays
  } = context
  const dummy: any = (): void => undefined

  return (
    <Container>
      <InfoWrapper leaveStartsInDays={0} totalLeaveDays={totalLeaveDays} />
      <ContentContainer>
        <TimelineViewWrapper
          onDetailsClick={dummy}
          onDatePickerClick={dummy}
          onBabyHasArrivedClick={dummy}
          selectedDetailsPeriod={null}
        />
        <RightContainer>
          <PrintNotesContainer>
            <PrintNotesWrapper lineSpacing={48} linesCount={20} />
          </PrintNotesContainer>
          {note && (
            <NoteContainer>
              <NoteInnerContainer>
                <Note>{note}</Note>
              </NoteInnerContainer>
            </NoteContainer>
          )}
        </RightContainer>
      </ContentContainer>
      <PrintFooterWrapper />
    </Container>
  )
})

TimelinePageVerticalPrintable.displayName = 'TimelinePageVerticalPrintable'

export default TimelinePageVerticalPrintable
