import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { isFirefox } from 'src/utils/deviceTypes'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  item: IJourneyMapItem
}

const Container = styled.div`
  display: flex;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      justify-content: flex-start;

      @media print {
        flex-direction: row;
      }
    `}
`

const textByMixin = css`
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}

  @media print {
    font-size: 14pt;
    ${isFirefox() &&
    css`
      font-size: 12px;
    `}
  }
`

const Label = styled.span`
  ${textByMixin};
  font-weight: normal;
`

const Value = styled.span`
  ${textByMixin};
  color: ${props => props.theme.colors.dark60};
  font-weight: 500;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 4px;
        `
      : css`
          margin-top: 4px;
        `}

  @media print {
    margin-top: 0;
    margin-left: 4px;
  }
`

const CompleteByView = React.memo((props: IProps) => {
  const { className, item } = props

  const { t } = useTranslation()
  const labelText: string = useMemo(() => t('common.journeyMapCompleteBy'), [t])
  const dateText: string = useMemo(
    () => t(item.dueDate.format('MMM DD, YYYY')),
    [t, item]
  )
  const ariaLabel: string = useMemo(
    () => `${labelText} ${dateText}`,
    [labelText, dateText]
  )

  return (
    <Container className={className} aria-label={ariaLabel} role={'note'}>
      <Label aria-hidden>{labelText}</Label>
      <Value aria-hidden>{dateText}</Value>
    </Container>
  )
})

CompleteByView.displayName = 'CompleteByView'

export default CompleteByView
