import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Icon } from 'src/UIKit'
import { GLOBAL_COUNTRY } from 'src/utils/userUtils'
import styled from 'styled-components'

interface IProps {
  countryCodes: string[]
  selectedCountryCode: string
  setSelectedCountryCode: (s: string) => void
}

const Container = styled.div`
  width: 100%;
`

const CountryIconWrapper = styled(Icon)`
  border-radius: 50%;
  overflow: hidden;
  height: 24px;
  width: 24px;
  margin-right: 12px;
`

export const CountryDropdown = React.memo((props: IProps) => {
  const { countryCodes, selectedCountryCode, setSelectedCountryCode } = props
  const { t } = useTranslation()

  const extendedCountryCodes = useMemo(
    () =>
      countryCodes.length > 1
        ? [GLOBAL_COUNTRY, ...countryCodes]
        : countryCodes,
    [countryCodes]
  )

  const options = useMemo(
    () =>
      extendedCountryCodes.map((countryCode: string) => ({
        value: countryCode,
        text: t(`common.countries.${countryCode}`),
        icon: (
          <CountryIconWrapper
            name={'country'}
            settings={{ extra: { countryCode } }}
          />
        )
      })),
    [t, extendedCountryCodes]
  )

  const selectedOption = useMemo(
    () =>
      selectedCountryCode
        ? options.find((option: any) => option.value === selectedCountryCode)
        : null,
    [options, selectedCountryCode]
  )
  return (
    <Container>
      <Dropdown
        name="type"
        title={t('common.country')}
        options={options}
        selected={selectedOption}
        placeholder={''}
        onChange={option => {
          setSelectedCountryCode(option.value)
        }}
      />
    </Container>
  )
})

CountryDropdown.displayName = 'CountryDropdown'

export default CountryDropdown
