import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  text: string
  background?: string
  onClick?: () => void
}

const Wrapper = styled.span<{ $background: string }>`
  color: ${props => props.theme.colors.light100};
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  max-height: 24px;

  ${props => {
    if (props.$background) {
      return css`
        background: ${props.$background};
      `
    } else {
      return `background: ${props.theme.colors.main100}`
    }
  }}
`

const Badge = React.memo((props: IProps) => {
  const { className, text, background, onClick } = props
  return (
    <Wrapper
      className={className}
      $background={background}
      onClick={onClick}
      aria-hidden
    >
      {text}
    </Wrapper>
  )
})

Badge.displayName = 'Badge'

export default Badge
