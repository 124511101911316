import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import getDetailsType from '../getDetailsType'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: LeaveDatePeriodPicker,
  periodKeyDate: 'periodStart',
  defaultActiveKeyDate: 'periodEnd',
  removable: true,
  detailsType: getDetailsType(input)
})
