import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { withToastManager } from 'src/components/ToastManager'
import { IWithToastManager } from 'src/react-app-env'
import { UIText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import SessionsList from 'src/features/UserProfile/components/Sessions/components/SessionsList'
import UserProfileContext from 'src/features/UserProfile/Context'
import VisibilitySensor from 'react-visibility-sensor'
import LoadingSpinner from 'src/components/LoadingSpinner'

interface IProps extends IWithToastManager {
  className?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const DescriptionWrapper = styled(UIText)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 24px;
        `
      : css`
          margin: 0 0 8px;
        `}
`

const RevokeAllButton = styled.button`
  line-height: 100%;
  color: ${props => props.theme.colors.main100};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin: 32px 0 0;
        `
      : css`
          font-size: 14px;
          font-weight: 500;
          margin: 16px 0 0;
        `}

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.main110};
  }
`

const Sessions = React.memo((props: IProps) => {
  const {
    sessions,
    revokingSessionsId,
    revokeAllSessions,
    sessionsResult,
    onSessionSectionAppeared
  } = useContext(UserProfileContext)
  const { className } = props
  const { t } = useTranslation()
  const hasOtherSessions: boolean = useMemo(
    () => sessions.filter((session: ISession) => !session.current).length !== 0,
    [sessions]
  )

  let content: any

  if (sessionsResult.loading || sessionsResult.error) {
    content = <LoadingSpinner />
  } else {
    content = (
      <SessionsList
        sessions={sessions}
        revokingSessionsId={revokingSessionsId}
      />
    )
  }

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible: boolean) => {
        if (isVisible) {
          onSessionSectionAppeared()
        }
      }}
    >
      <Container className={className}>
        <DescriptionWrapper allowsParagraph>
          {t('sessions.description')}
        </DescriptionWrapper>
        {content}
        {hasOtherSessions && (
          <RevokeAllButton onClick={revokeAllSessions}>
            {t('sessions.revokeAllSessions')}
          </RevokeAllButton>
        )}
      </Container>
    </VisibilitySensor>
  )
})

Sessions.displayName = 'Sessions'

export default withToastManager(Sessions)
