import React, { useContext, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import theme from 'src/theme'
import Badge from 'src/components/Badge'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'
import useElementFocused from 'src/components/hooks/useElementFocused'
import ScreenContext from 'src/contexts/ScreenContext'
import ReactMarkdown from 'react-markdown'

const { colors } = theme()

export interface IOverdueItemsProps {
  overdueItemsCount: number
  onOverdueClick: () => void
  showOverdueAsRed: boolean
}

interface IProps extends IOverdueItemsProps {
  className?: string
}

const Container = styled.button<{ $showsAsRed?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: normal;

  ${props => {
    if (props.theme.isDesktop) {
      return css`
        height: 48px;
        width: 100%;
        border-radius: unset;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: ${props.$showsAsRed
          ? props.theme.colors.specialCriticalHigh
          : props.theme.colors.main100};
        color: ${props.theme.colors.light100};

        &:hover {
          background: ${props.$showsAsRed
            ? props.theme.colors.error100
            : props.theme.colors.main110};
          color: ${props.theme.colors.light100};
        }

        &:focus {
          background: ${props.$showsAsRed
            ? props.theme.colors.specialCriticalHigh
            : props.theme.colors.main110};
          color: ${props.theme.colors.light100};
        }
      `
    } else {
      return css`
        height: 100%;
        border-radius: 20px;
        border: 2px solid transparent;

        &:focus {
          border: 2px solid ${props.theme.colors.main100};
        }
      `
    }
  }}

  &:focus {
    font-weight: 500;
    outline: none;
  }
`
const Text = styled(ReactMarkdown)<{ $showsAsRed?: boolean }>`
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          line-height: 100%;

          strong {
            font-weight: 600;
          }
        `
      : css`
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: ${props.$showsAsRed
            ? props.theme.colors.specialCriticalHigh
            : props.theme.colors.main100};
        `}
`

const RedContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
`

const RightContentWrapper = styled.div`
  display: flex;
`

const BadgeWrapper = styled(Badge)`
  margin-right: 8px;
`

const ChevronIconWrapper = styled(Icon)`
  margin-left: 10px;
  margin-top: 2px;
`

const ArrowBackIconWrapper = styled(Icon)`
  margin-right: 12px;
`

const OverdueView = React.memo(
  (props: IProps) => {
    const { className, overdueItemsCount, onOverdueClick, showOverdueAsRed } =
      props
    const { t } = useTranslation()

    const containerRef = useRef(null)
    const focused: boolean = useElementFocused(containerRef.current)

    useEffect(() => {
      const { current } = containerRef
      if (current && !focused) {
        current.blur()
      }
    }, [focused])

    const { isMobile, isDesktop } = useContext(ScreenContext)

    const renderRedContent = () => {
      const overdueItemsText: string = t('journeyMap.overdueItemsCount', {
        count: overdueItemsCount,
        context: isMobile && 'mobile'
      })
      const text: any = (
        <Text $showsAsRed={showOverdueAsRed}>{overdueItemsText}</Text>
      )
      if (isDesktop) {
        return (
          <RedContentContainer>
            {text}
            <RightContentWrapper>
              <Text>{t('common.show')}</Text>
              <ChevronIconWrapper
                name={'chevron_right'}
                settings={{ fill: colors.light100 }}
              />
            </RightContentWrapper>
          </RedContentContainer>
        )
      } else {
        return (
          <React.Fragment>
            <BadgeWrapper
              text={overdueItemsCount.toString()}
              background={colors.specialCriticalHigh}
            />
            {text}
          </React.Fragment>
        )
      }
    }

    const renderBackContent = () => {
      const text: string = t('common.backToAllItems')

      return (
        <React.Fragment>
          <ArrowBackIconWrapper
            name={'arrow_back_small'}
            settings={{
              fill: isDesktop ? colors.light100 : colors.main100
            }}
          />
          <Text $showsAsRed={showOverdueAsRed}>{text}</Text>
        </React.Fragment>
      )
    }

    return (
      <Container
        className={className}
        onClick={onOverdueClick}
        $showsAsRed={showOverdueAsRed}
        ref={containerRef}
        role={'switch'}
        aria-checked={!showOverdueAsRed}
        aria-label={t(
          `common.accessibilityText.${
            showOverdueAsRed ? 'showOverdueItems' : 'backToAllItems'
          }`
        )}
      >
        {showOverdueAsRed ? renderRedContent() : renderBackContent()}
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.overdueItemsCount === nextProps.overdueItemsCount &&
    prevProps.showOverdueAsRed === nextProps.showOverdueAsRed
)

OverdueView.displayName = 'OverdueView'

export default OverdueView
