import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  name: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 14px;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 16px;
    `}
`

const Text = styled.span`
  line-height: 100%;
  margin-left: 12px;
  text-align: left;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
          font-size: 28px;
          color: ${props.theme.colors.dark60};
          margin-top: -4px;
        `
      : css`
          font-size: 20px;
          color: ${props.theme.colors.dark80};
        `}
`

export const dataAttrs = {
  name: () => 'absence-archived-employee-name'
}

export const arePropsEqual = (prev: IProps, next: IProps) => {
  if (prev.name !== next.name) {
    return false
  }

  return true
}

const AbsenceArchivedEmployeeName = React.memo((props: IProps) => {
  const { name } = props
  const { t } = useTranslation()

  return (
    <Container
      aria-label={t('common.accessibilityText.employeeName', { name })}
    >
      <Icon name={'user'} settings={{ fill: colors.main90 }} />
      <Text data-testid={dataAttrs.name()}>{name}</Text>
    </Container>
  )
}, arePropsEqual)

AbsenceArchivedEmployeeName.displayName = 'AbsenceArchivedEmployeeName'

export default AbsenceArchivedEmployeeName
