import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import SignInContext, {
  ISignInContext
} from 'src/features/Welcome/components/WelcomePage/context'
import ScreenContext from 'src/contexts/ScreenContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
    `}
`

const ButtonWrapper = styled(Button)`
  width: 100%;
`

export const dataAttrs = {
  backBtn: () => 'code-expired-back-btn',
  submitBtn: () => 'code-expired-submit-btn'
}

const CodeExpired = React.memo(() => {
  const { t } = useTranslation()
  const { resetWorkflow, moveToStage }: ISignInContext =
    useContext(SignInContext)
  const { isDesktop } = useContext(ScreenContext)

  const cancelButton = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.backBtn()}
        appearance="cancel"
        onClick={resetWorkflow}
      >
        {t('common.back')}
      </ButtonWrapper>
    ),
    [t, resetWorkflow]
  )

  const submitButton = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.submitBtn()}
        onClick={() => moveToStage('providePersonalEmail')}
      >
        {t('common.sendCode')}
      </ButtonWrapper>
    ),
    [t, moveToStage]
  )

  const buttons = useMemo(
    () =>
      isDesktop ? (
        <ButtonsContainer>
          {cancelButton}
          {submitButton}
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          {submitButton}
          {cancelButton}
        </ButtonsContainer>
      ),
    [isDesktop, cancelButton, submitButton]
  )

  return <Container>{buttons}</Container>
})

CodeExpired.displayName = 'CodeExpired'

export default CodeExpired
