import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Buttons from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Buttons'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import DescriptionText from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/DescriptionText'
import { ITimelinePeriod } from 'src/react-app-env'
import { ContentContainer } from '../components/ContentContainer'

interface IProps {
  onExit: () => void
  period: ITimelinePeriod
}

const InfoMessageDatePicker = React.memo((props: IProps) => {
  const { period, onExit } = props
  const { t } = useTranslation()

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={false}
        isConfirmDisabled={false}
        onCancel={null}
        onClose={onExit}
        onConfirm={null}
        onRemove={null}
      />
    ),
    [onExit]
  )
  const description: string = useMemo(
    () => period.timelineConfig.periodPickerInfoMessage(t),
    [t, period]
  )
  return (
    <ContainerView {...props}>
      <ContentContainer>
        <DescriptionText>{description}</DescriptionText>
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

InfoMessageDatePicker.displayName = 'InfoMessageDatePicker'

export default InfoMessageDatePicker
