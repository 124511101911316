import React from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'

interface IProps {
  children: string
  isUnused: boolean
}

const Text = styled.span<{ $isUnused: boolean }>`
  margin: 4px 0 0;
  font-size: 10px;
  line-height: 10px;
  text-wrap: balance;
  color: ${props =>
    props.$isUnused
      ? props.theme.colors.timelineIncome
      : props.theme.colors.light100};
`

const IncomeNoteView = React.memo(
  (props: IProps) => {
    const { isUnused, children } = props

    return <Text $isUnused={isUnused}>{children}</Text>
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

IncomeNoteView.displayName = 'IncomeNoteView'

export default IncomeNoteView
