import { ITimelinePeriod } from 'src/react-app-env'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import { calculateDaysDifferenceInclusive } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: MultiDatePicker,
  datePickerFooterInfoMessage: (t: any) => {
    const reinstatementPeriod = input.periods.find(
      (period: ITimelinePeriod) => period.type === 'Reinstatement'
    )
    if (!reinstatementPeriod) {
      return null
    }

    const count: number = calculateDaysDifferenceInclusive(
      reinstatementPeriod.periodEnd?.min,
      reinstatementPeriod.periodEnd?.max
    )

    return t('timeline.datePickerAlerts.reinstatementEligibility.text', {
      count
    })
  },
  useApprovedByTpa: true
})
