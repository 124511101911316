import defaultTheme from './default'

export interface ITheme {
  [key: string]: any
}

let currentTheme: any

const getCurrentTheme = (): ITheme => {
  if (currentTheme) {
    return currentTheme
  }

  currentTheme = defaultTheme

  return currentTheme
}

export default (): ITheme => getCurrentTheme()
