import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, MarkdownText } from 'src/UIKit'
import VacationInput, { FLOAT_REG_EXP } from '../VacationInput'
import { IIcon } from 'src/react-app-env'
import theme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'
import zIndex from 'src/constants/zIndex'
import ResultView from '../ResultView'
import { KEY_ENTER } from 'src/constants/keys'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import Tooltip from 'src/components/Tooltip'

const { colors } = theme()

interface IProps {
  vacationHours: string
  onUpdate: (value: string) => void
  isMaxAccrual: boolean
  maxAccrualDate: string
  plannedVacationDays: number
  plannedVacationHours: number
  recommendedDaysStart: number
  recommendedDaysDisability: number
  recommendedDaysBonding: number
  estimatedNextMaxAccrualDate: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      height: 100%;
    `}
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  border-radius: 8px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(640px - 48px);
        `
      : css`
          width: 100%;
        `}
`

const NoteText = styled(MarkdownText)`
  margin: 16px 0 8px;
  font-size: 14px;
  line-height: 100%;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.dark60};
  height: 68px;
  line-height: 130%;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          width: calc(100% - 48px);
        `
      : css`
          font-size: 14px;
          width: calc(100% - 36px);
        `}
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.main10};
`

const PlanedBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const NumberText = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: ${props => props.theme.colors.dark80};
`

const StringText = styled.span`
  color: ${props => props.theme.colors.dark80};
  font-size: 12px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 16px auto 20px;
          width: 428px;
        `
      : css`
          flex-direction: column;
          padding: 0 8px;
          flex: 1;
          justify-content: flex-end;
          width: 100%;
        `}
`

const ButtonWrapper = styled(Button)`
  margin: 8px 8px 0;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 200px;
          padding: 0 24px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}
`

export const dataAttrs = {
  button: () => `calculate-button`
}

const VacationDialogContent = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    onUpdate,
    vacationHours,
    isMaxAccrual,
    maxAccrualDate,
    plannedVacationDays,
    plannedVacationHours,
    recommendedDaysStart,
    recommendedDaysDisability,
    recommendedDaysBonding,
    estimatedNextMaxAccrualDate
  } = props
  const { isMobile } = useContext(ScreenContext)
  const [vacation, setVacation] = useState(vacationHours)
  const [isValid, setIsValid] = useState(false)
  const isEquals: boolean = Number(vacation) === Number(vacationHours)

  const iconButton: IIcon = {
    iconName: 'calculator',
    iconProps: {
      fill: colors.light100
    }
  }

  const onChange = useCallback((value: string) => {
    const valid = FLOAT_REG_EXP.test(value)
    setIsValid(valid)
    setVacation(value)
  }, [])

  const onUpdateVacation = useCallback(
    (vacationValue: string) => {
      onUpdate(vacationValue)
    },
    [onUpdate]
  )

  const keyHandler = useCallback(
    (event: SyntheticEvent) => {
      if (!isValid) {
        return
      }
      const { keyCode } = event as any
      switch (keyCode) {
        case KEY_ENTER: {
          onUpdateVacation(vacation)
          break
        }
      }
    },
    [onUpdateVacation, vacation, isValid]
  )

  const plannedVacation = useMemo(
    () => (
      <RowContainer>
        <LeftContainer>
          <Tooltip
            body={t('vacationBalance.tooltip.description')}
            offset={[20, 15]}
            zIndexParam={zIndex.vacationSteps.tooltip}
          >
            {t('vacationBalance.dialog.plannedVacation')}
          </Tooltip>
        </LeftContainer>
        <PlanedBlock>
          <NumberText>{plannedVacationDays}</NumberText>
          <StringText>
            {t('common.durations.day', {
              count: plannedVacationDays
            })}
          </StringText>
          <StringText>
            (
            {t('common.hourFull', {
              count: plannedVacationHours
            })}
            )
          </StringText>
        </PlanedBlock>
      </RowContainer>
    ),
    [plannedVacationDays, plannedVacationHours, t]
  )

  const editVacation = useMemo(
    () => (
      <RowContainer>
        {t('vacationBalance.dialog.editVacation')}
        <VacationInput
          value={vacation}
          onChange={onChange}
          onKeyDown={keyHandler}
        />
      </RowContainer>
    ),
    [onChange, t, vacation, keyHandler]
  )

  return (
    <Container>
      <BodyContainer>
        {plannedVacation}
        <Separator />
        {editVacation}
      </BodyContainer>
      <NoteText
        allowsParagraph
        urlTransform={TelUrlTransformer}
        components={{
          a: LinkRenderer
        }}
      >
        {t('vacationBalance.vacationNote')}
      </NoteText>
      {vacationHours && isMobile && (
        <ResultView
          isMaxAccrual={isMaxAccrual}
          maxAccrualDate={maxAccrualDate}
          recommendedDaysStart={recommendedDaysStart}
          recommendedDaysDisability={recommendedDaysDisability}
          recommendedDaysBonding={recommendedDaysBonding}
          estimatedNextMaxAccrualDate={estimatedNextMaxAccrualDate}
        />
      )}
      <ButtonsContainer>
        <ButtonWrapper
          data-testid={dataAttrs.button()}
          icon={iconButton}
          onClick={() => onUpdateVacation(vacation)}
          disabled={!isValid || !vacation || isEquals}
          showChildByCenter
        >
          {t('vacationBalance.dialog.calculate')}
        </ButtonWrapper>
      </ButtonsContainer>
      {vacationHours && !isMobile && (
        <ResultView
          isMaxAccrual={isMaxAccrual}
          maxAccrualDate={maxAccrualDate}
          recommendedDaysStart={recommendedDaysStart}
          recommendedDaysDisability={recommendedDaysDisability}
          recommendedDaysBonding={recommendedDaysBonding}
          estimatedNextMaxAccrualDate={estimatedNextMaxAccrualDate}
        />
      )}
    </Container>
  )
})

VacationDialogContent.displayName = 'VacationDialogContent'

export default VacationDialogContent
