import React, { ReactNode, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import OptionContainer from 'src/UIKit/OptionContainer'
import NormalRadioButtonOption from 'src/features/Leave/components/NormalRadioButtonOption'
import { PregnancyChangeStepView } from '../PregnancyChangeStepView'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'

interface IProps {
  name: string
}

export const PregnancyChangeView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props
  const {
    leave,
    onNextStage,
    onPrevStage,
    isMiscarriageOrStillBirth,
    setIsMiscarriageOrStillBirth
  }: IPregnancyLossContext = useContext(PregnancyLossContext)

  const options: ReactNode = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={isMiscarriageOrStillBirth}
        onClick={() => setIsMiscarriageOrStillBirth(true)}
      >
        {t([
          `pregnancyChangeFlow.pages.${name}.options.pregnancyLoss_${leave.type}`,
          `pregnancyChangeFlow.pages.${name}.options.pregnancyLoss`
        ])}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={!isMiscarriageOrStillBirth}
        onClick={() => setIsMiscarriageOrStillBirth(false)}
        hidesSeparator
      >
        {t([
          `pregnancyChangeFlow.pages.${name}.options.somethingElse_${leave.type}`,
          `pregnancyChangeFlow.pages.${name}.options.somethingElse`
        ])}
      </NormalRadioButtonOption>
    ],
    [t, name, leave, isMiscarriageOrStillBirth, setIsMiscarriageOrStillBirth]
  )

  const content: any = useMemo(
    () => <OptionContainer>{options}</OptionContainer>,
    [options]
  )

  return (
    <PregnancyChangeStepView
      name={name}
      onCancelClicked={() => onPrevStage()}
      onNextClicked={() => onNextStage()}
      isNextDisabled={false}
    >
      {content}
    </PregnancyChangeStepView>
  )
})

PregnancyChangeView.displayName = 'PregnancyChangeView'

export default PregnancyChangeView
