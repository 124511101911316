import React from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import { Icon } from 'src/UIKit'
import zIndex from 'src/constants/zIndex'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'

const { colors } = createTheme()

interface IProps {
  children: string
  onArrowClick: () => void
  role?: string
}

const TitleContainer = styled.div`
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.light100};
  display: flex;
  align-items: center;
  position: absolute;
  top: auto;
  z-index: ${zIndex.userProfile.closeOutPlanOverlayMobileTitle};
  box-shadow: 0 4px 13px rgb(98 106 116 / 10%);
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${props => props.theme.colors.dark60};
`

const ButtonContainer = styled.button`
  outline: none;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: -8px;
    border-radius: 12px;
  }
`

export const CloseWrapper = styled(Icon)`
  padding: 20px;
`

const CloseOutPlanTitleMobile = React.memo(
  (props: IProps) => {
    const { children, onArrowClick, role } = props
    const { t } = useTranslation()

    return (
      <TitleContainer>
        <ButtonContainer
          aria-label={t('common.accessibilityText.closeButton')}
          onClick={onArrowClick}
        >
          <CloseWrapper
            name={'close_crossing'}
            settings={{ fill: colors.main100 }}
            ariaLabel={t('common.accessibilityText.closeButton')}
          />
        </ButtonContainer>
        <Title role={role}>{children}</Title>
      </TitleContainer>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

CloseOutPlanTitleMobile.displayName = 'CloseOutPlanTitleMobile'

export default CloseOutPlanTitleMobile
