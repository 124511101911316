import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ControlMenuItem from './components/ControlMenuItem'

export type ControlMenuViewItemType = 'print' | 'close' | 'somethingHasChanged'

export interface IControlMenuViewItem {
  type: ControlMenuViewItemType
}

interface IProps {
  className?: string
  items: IControlMenuViewItem[]
  onItemClick: (type: ControlMenuViewItemType) => void
}

const Container = styled.ul`
  width: 308px;
  padding: 8px 0;
`

const ControlMenuView = React.memo((props: IProps) => {
  const { items, onItemClick } = props
  const { t } = useTranslation()

  const itemViews: ReactNode[] = useMemo(
    () =>
      items.map((item: IControlMenuViewItem) => {
        const { type } = item
        const label: string = t(`header.controlMenu.${type}`)
        const ariaLabel: string = t(
          `common.accessibilityText.ControlMenuActions.${type}`
        )

        return (
          <ControlMenuItem
            key={type}
            label={label}
            onClick={() => {
              onItemClick(type)
            }}
            ariaLabel={ariaLabel}
            error={type === 'close'}
          />
        )
      }),
    [items, t, onItemClick]
  )

  return <Container>{itemViews}</Container>
})

ControlMenuView.displayName = 'ControlMenuView'

export default ControlMenuView
