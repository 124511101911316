import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'

const {
  colors: { light100 }
} = createTheme()
const mainColor = '#ECF5FF'

interface IProps {
  fadeOut: boolean
  fadeOutDuration: number
  onAnimationComplete: () => void
}

const fadeOutKeyFrames = keyframes`
  0% {
    opacity: 1;
    background-color: ${mainColor};
  }
  100% {
    opacity: 0;
    background-color: ${light100};
}`

const Container = styled.div<any>`
  background: ${mainColor};
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loadingOverlay};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.$fadeOut &&
    css`
      animation: ${fadeOutKeyFrames} ${props.$fadeOutDuration}ms linear forwards;
    `}
`

const flyKeyFrames = keyframes`
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.02);
}`

const VeerWrapper = styled(Icon)`
  animation: ${flyKeyFrames} 2s infinite alternate ease-in-out forwards;
`

const PageLoadingOverlay = React.memo((props: IProps) => {
  const { fadeOut, fadeOutDuration, onAnimationComplete } = props
  const { t } = useTranslation()

  return (
    <Container
      $fadeOut={fadeOut}
      $fadeOutDuration={fadeOutDuration}
      onAnimationEnd={onAnimationComplete}
      role={'region'}
      aria-label={t('common.accessibilityText.loading')}
    >
      <VeerWrapper
        name={'veer'}
        settings={{
          fill: light100,
          width: '255',
          height: '66'
        }}
        ariaLabel={t('common.accessibilityText.veerLogo')}
      />
    </Container>
  )
})

PageLoadingOverlay.displayName = 'PageLoadingOverlay'

export default PageLoadingOverlay
