const consentUrl = () => '/auth/consent'
const getPrivacyPolicyUrl = () => '/privacy'
const getTermsUrl = () => '/terms'
const graphqlUri = () => '/api/query'
const infoUrl = () => '/auth/info'
const loginOtpUrl = () => '/auth/otp-login'
const loginUrl = () => '/auth/login'
const requestOtpUrl = () => '/auth/otp-request'

export {
  consentUrl,
  getPrivacyPolicyUrl,
  getTermsUrl,
  graphqlUri,
  infoUrl,
  loginOtpUrl,
  loginUrl,
  requestOtpUrl
}
