import {
  DatePickerKeyDate,
  IGetPeriodConfigInput,
  ITimelinePeriodConfig
} from '../index'
import LeaveDateTimelinePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateTimelinePicker'
import moment from 'moment'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent: LeaveDateTimelinePicker,
  datePickerKeyDate: 'endDate' as DatePickerKeyDate
})
