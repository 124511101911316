import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import ScreenContext from 'src/contexts/ScreenContext'

const { colors } = createTheme()

interface IProps {
  alignsToTop: boolean
  bottomOffset?: number
}

const ArrowContainer = styled.div<{
  $alignsToTop: boolean
  $bottomOffset?: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  width: 16px;
  height: 32px;
  margin-left: -1px;

  ${props => {
    const { $alignsToTop, $bottomOffset } = props
    if ($alignsToTop) {
      return css`
        margin-top: 14px;
      `
    } else {
      return css`
        margin-bottom: ${$bottomOffset + 36}px;
      `
    }
  }}
`

const ArrowView = React.memo((props: IProps) => {
  const { alignsToTop, bottomOffset } = props
  const { isMobile } = useContext(ScreenContext)

  if (isMobile) {
    return null
  }

  return (
    <ArrowContainer
      $alignsToTop={alignsToTop}
      $bottomOffset={bottomOffset || 0}
    >
      <svg
        viewBox="0 0 16 32"
        xmlns="http://www.w3.org/2000/svg"
        fill={colors.light100}
      >
        <path d="M 0 0 L 16 16 L 0 32 Z" />
        <path d="M 0 0 L 16 16 L 0 32" stroke={colors.dark05} />
      </svg>
    </ArrowContainer>
  )
})

ArrowView.displayName = 'TimelineDatePickerArrowView'

export default ArrowView
