import { useQuery } from '@apollo/client'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  createLeaveRoute,
  errorPageRoute,
  claimUpdateRoute,
  publishedLeaveRoute
} from 'src/routes/constants'
import withQueries from 'src/components/HOC/withQueries'
import {
  ITimelinePeriod,
  IWithDialogManager,
  IWithQueriesProps,
  IWithToastManager
} from 'src/react-app-env'
import * as LeaveUtils from 'src/utils/leaveUtils'
import TimelineDiff from 'src/utils/timelineDiff'
import { IToastPeriod } from 'src/utils/leaveUtils'
import moment, { Moment } from 'moment'
import { withDialogManager } from 'src/components/DialogManager'
import LoadingSpinner from 'src/components/LoadingSpinner'
import MediaQuery from 'react-responsive'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import BirthDateOverlay from 'src/features/Timeline/components/common/BirthdateOverlay'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import {
  MetricClaimUpdateRequestClick,
  MetricEventTimelineImpression
} from 'src/constants/metrics'
import useCreateMetric, {
  IUseCreateMetricArgs
} from 'src/graphql/hooks/useCreateMetric'
import {
  isBabyArrivedLeave,
  isDraftLeave,
  isNewLeave
} from 'src/utils/leaveStatusUtils'
import ConfirmationLeaveApprovalOverlay from 'src/features/Timeline/components/common/ConfirmationLeaveApprovalOverlay'
import { TpaApprovalState } from 'src/features/Leave/context'
import usePrevious from 'src/components/hooks/usePrevious'
import TimelineTpaApprovedViewContainer from 'src/features/Leave/components/TpaApprovedView/TimelineTpaApprovedViewContainer'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { withToastManager } from 'src/components/ToastManager'
import ScreenContext from 'src/contexts/ScreenContext'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useLocation, useNavigate } from 'react-router-dom'
import PagePrintableVertical from './components/vertical/PagePrintable'
import PageMobileVertical from './components/vertical/PageMobile'
import PageDesktopVertical from './components/vertical/PageDesktop'
import { getCountryCode } from 'src/utils/userUtils'
import SharedContext from 'src/contexts/SharedContext'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import {
  TIMELINE_REVIEW_ALERTS_KEY,
  TRANSITION_FLOW_KEY,
  closePlanLocalStorageHook
} from 'src/utils/ls'
import { checkTransitionFlow } from '../TransitionFlow/methods'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { queryLeaveJourneyMap } from 'src/graphql/queries'
import LeaveHolidays from '../Leave/holidays'
import { isBlankDate } from 'src/utils/dateUtils'
import VacationMobileContentView from './components/common/VacationBalanceEditor/components/VacationMobileContentView'
import { getNavigationComponent } from 'src/utils/leaveChangesRedirectManager'

interface IProps
  extends IWithQueriesProps,
    IWithDialogManager,
    IWithToastManager {}

interface IChanges {
  [key: string]: string
  dueDate?: string
  subtype?: string
  startDate?: string
  disabilityEndDate?: string
  endDate?: string
}

const AlertContent = styled(ReactMarkdown)`
  white-space: pre-wrap;
`

export const TimelineContainer = React.memo((props: IProps) => {
  const { dialogManager, queries, toastManager } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const { customer, customerConfig } = useContext(SharedContext)

  const wasMobile: boolean = usePrevious(isMobile)
  const [leave, setLeave] = useState<ILeave>(null)
  const [user, setUser] = useState<IUser>(null)

  const countryCode = useMemo(() => getCountryCode(user), [user])

  const periodItemId: string = useMemo(
    () => location.state && (location.state as any).periodItemId,
    [location.state]
  )

  const timelinePeriods: ITimelinePeriod[] = useMemo(
    () =>
      LeaveUtils.getTimelinePeriods(
        leave,
        customer,
        customerConfig,
        countryCode,
        t
      ),
    [leave, customer, customerConfig, countryCode, t]
  )

  const [config, setConfig] = useState(null)
  const [savedTimelinePeriods, setSavedTimelinePeriods] =
    useState(timelinePeriods)
  const prevSavedTimelinePeriods: ITimelinePeriod[] =
    usePrevious(savedTimelinePeriods)

  const [latestRequestChanges, setLatestRequestChanges] = useState<any>(null)
  const [hasUnsavedChangesOnBackend, setHasUnsavedChangesOnBackend] =
    useState(false)
  const [shouldCallOnSavePlan, setShouldCallOnSavePlan] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [isBirthDateMode, setIsBirthDateMode] = useState(false)
  const [isConfirmationLeaveApprovalMode, setIsConfirmationLeaveApprovalMode] =
    useState(false)
  const [isTimelineViewInactive, setTimelineViewInactive] =
    useState<boolean>(false)
  const [isDetailsOpened, setDetailsOpened] = useState<boolean>(false)
  const [currentDetailsViewPeriod, setCurrentDetailsViewPeriod] =
    useState<ITimelinePeriod>(null)
  const [currentDatePickerViewPeriod, setCurrentDatePickerViewPeriod] =
    useState<ITimelinePeriod>(null)
  const [tpaApprovedViewChanges, setTpaApprovedViewChanges] =
    useState<any>(null)
  const [isNotSendToast, setIsNotSendToast] = useState<boolean>(false)
  const [leaveHolidays, setLeaveHolidays] = useState<LeaveHolidays>(null)
  const [externalNotices, setExternalNotices] = useState<IAlert[]>([])
  const [showVacationMobileView, setShowVacationMobileView] = useState(false)
  const [skipTransitionFlow] = useLocalStorage(TRANSITION_FLOW_KEY, {
    skip: false
  })
  const [skipTransitionForUpdate, setSkipTransitionForUpdate] =
    useState<boolean>(false)
  const [showReviewAlert, setShowReviewAlert] = useLocalStorage(
    TIMELINE_REVIEW_ALERTS_KEY,
    {}
  )
  const prevLeave: ILeave = usePrevious(leave)
  const forceUpdate = useForceUpdate()
  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  useCreateMetric(
    { eventType: MetricEventTimelineImpression, id: leave?.id },
    !leave?.id
  )
  usePageTitle('timeline')

  const isScreenSwitched = useMemo(
    () => (wasMobile ? isDesktop : isMobile),
    [isMobile, isDesktop, wasMobile]
  )

  const isLeaveDurationPickerOpened = useMemo(
    () => !!currentDatePickerViewPeriod,
    [currentDatePickerViewPeriod]
  )

  const leaveStartYear = useMemo(
    () =>
      leave?.dates?.leaveStart
        ? moment(leave.dates.leaveStart.current).utc().startOf('year').year()
        : null,
    [leave?.dates?.leaveStart]
  )

  const fetchHolidays = useCallback(
    async (start: Moment, end: Moment) => {
      if (
        !(
          customerConfig.leave.holidays.enabled &&
          start?.isValid() &&
          end?.isValid()
        )
      ) {
        return
      }

      try {
        const holidays = await queries.fetchHolidays(
          start.toISOString(),
          end.toISOString()
        )
        if (holidays) {
          const lh = leaveHolidays || new LeaveHolidays([])
          lh.add(holidays)
          setLeaveHolidays(lh.clone())
        }
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      }
    },
    // eslint-disable-next-line
    [navigate, queries, customerConfig.leave.holidays.enabled]
  )

  const fetchHolidaysForYear = useCallback(
    async (year: number) => {
      if (leaveHolidays?.forYear(year)) {
        return
      }
      const start = moment.utc({ year }).startOf('year')
      const end = start.clone().add(1, 'years').endOf('year')
      fetchHolidays(start, end)
    },
    // eslint-disable-next-line
    [fetchHolidays]
  )

  const onCalendarActiveDateChanged = useCallback(
    date => {
      if (isBlankDate(moment(date))) {
        return
      }
      const year = moment(date).utc().year()
      fetchHolidaysForYear(year)
    },
    [fetchHolidaysForYear]
  )

  useEffect(() => {
    fetchHolidaysForYear(leaveStartYear)
  }, [fetchHolidaysForYear, leaveStartYear])

  useEffect(() => {
    if (currentDatePickerViewPeriod && isScreenSwitched) {
      setCurrentDatePickerViewPeriod(null)
    }
    if (showVacationMobileView && isScreenSwitched) {
      setShowVacationMobileView(null)
    }
  }, [
    currentDatePickerViewPeriod,
    setCurrentDatePickerViewPeriod,
    isScreenSwitched,
    showVacationMobileView
  ])

  useEffect(() => {
    if (savedTimelinePeriods?.length === 0 && timelinePeriods?.length > 0) {
      setSavedTimelinePeriods(timelinePeriods)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelinePeriods])

  useEffect(() => {
    if (periodItemId && timelinePeriods.length) {
      const period = timelinePeriods.find(
        (p: ITimelinePeriod) => p.itemID === periodItemId
      )
      if (period) {
        setCurrentDatePickerViewPeriod(period)
      }
      navigate(location, { state: {} })
    }
  }, [location, navigate, periodItemId, timelinePeriods])

  useEffect(() => {
    if (
      leave &&
      isMobile &&
      isNewLeave(leave.status) &&
      leave.type !== 'Family'
    ) {
      updateLeaveWithChanges({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leave, isMobile])

  // used to refresh cached journey map in apollo cache
  useQuery(queryLeaveJourneyMap, { fetchPolicy: 'cache-and-network' })

  useEffect(() => {
    fetchConfig()
    fetchLeaveTimeline({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkTransitionFlow(
      leave,
      navigate,
      skipTransitionFlow.skip || skipTransitionForUpdate
    )
  }, [leave, navigate, skipTransitionFlow, skipTransitionForUpdate])

  useEffect(() => {
    if (shouldCallOnSavePlan) {
      onSavePlan()
    }

    fetchExternalNotices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leave])

  useEffect(() => {
    if (isBirthDateMode && isBabyArrivedLeave(leave.status)) {
      setIsBirthDateMode(false)
    }
  }, [leave, isBirthDateMode, setIsBirthDateMode])

  useEffect(() => {
    if (
      leave?.metadata?.canUseSupplementPay &&
      prevLeave?.metadata?.canUseSupplementPay === false
    ) {
      toastManager.addInfo(t('supplementPay.toggleIsAppeared'))
    }
  }, [leave, prevLeave, t, toastManager])

  const onPrintMediaChange = () => {
    forceUpdate()
  }

  const fetchLeaveTimeline = async (changes: IChanges) => {
    try {
      setFetching(true)
      const { leave: leaveResult, user: userResult } =
        await queries.fetchLeaveWithUser(
          {
            fetchPolicy: 'network-only',
            notFound: () => {
              pushCreateLeaveRoute()
            },
            badRequest: onLeaveBadRequest
          },
          changes,
          true
        )
      setUser(userResult)
      onLeaveResult(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const fetchConfig = async () => {
    try {
      setFetching(true)
      const configResult: IConfig = await queries.fetchConfig({
        fetchPolicy: 'network-only'
      })
      setConfig(configResult)
      setFetching(false)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const fetchExternalNotices = async () => {
    try {
      setFetching(true)
      const alerts: IAlert[] = await queries.fetchAlerts({
        fetchPolicy: 'network-only'
      })
      setExternalNotices(
        alerts.filter((a: IAlert) => a.type === 'TimelineNotice')
      )
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }

  const onCloseExternalNotice = async (id: string) => {
    try {
      await queries.closeAlert(id)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      fetchExternalNotices()
    }
  }

  const onNewChanges = (changes: any, forceUpdateLeave = false) => {
    const { status } = leave
    setSkipTransitionForUpdate(true)
    setIsNotSendToast(false)
    if (
      forceUpdateLeave ||
      (isDraftLeave(status) && 'subtype' in changes) ||
      (isDraftLeave(status) && changes?.extra?.approvedState !== undefined)
    ) {
      setHasUnsavedChangesOnBackend(true)
      updateLeaveWithChanges(changes)
    } else {
      fetchLeaveTimeline(changes)
    }
  }

  const updateLeave = (changes: any) => {
    updateLeaveWithChanges(changes)
  }

  const onTutorialCompleted = () => updateLeaveWithChanges({})

  const onLeaveSubtypeChanged = (subtype: ILeaveSubType) => {
    onNewChanges({ subtype }, true)
    if (subtype === 'Cesarean' || subtype === 'Vaginal') {
      toastManager.addInfo(
        t([
          `timeline.cesarianSwitching.${subtype}_${leave?.type}`,
          `timeline.cesarianSwitching.${subtype}`
        ])
      )
    }
  }

  const onUseSupplementPayChanged = (useSupplementPay: boolean) => {
    updateLeaveWithChanges({ extra: { useSupplementPay } })
  }

  const onVacationBalanceChanged = (vacationBalance: number) => {
    updateLeaveWithChanges({ extra: { PTOBalance: vacationBalance } })
  }

  const onApprovedByTpaChanged = (approvedState: boolean) => {
    if (!leave.approvedByTPA) {
      setIsConfirmationLeaveApprovalMode(true)
      return
    }

    const changes: any = {
      extra: { approvedState },
      startDate: leave.dates.leaveStart.current,
      endDate: leave.dates.leaveEnd.current
    }

    if (leave.dates.activeDutyEndDate?.current) {
      changes.extra.activeDutyEndDate = leave.dates.activeDutyEndDate.current
    }

    onNewChanges(changes, true)
  }

  const onDueDateChanged = (date: Moment) => {
    onNewChanges({ dueDate: date.toDate().toISOString() })
  }

  const onStartDateChanged = (date: Moment) => {
    onNewChanges({ startDate: date.toDate().toISOString() })
  }

  const onDisabilityEndDateChanged = (date: Moment) => {
    onNewChanges({ extra: { disabilityEndDate: date.toDate().toISOString() } })
  }

  const onLeaveEndDateChanged = (date: Moment) => {
    onNewChanges({ endDate: date.toDate().toISOString() })
  }

  const onLeaveResult = (leaveResult: ILeave) => {
    if (!leaveResult) {
      pushCreateLeaveRoute()
    } else {
      setLeave(leaveResult)
      setHasUnsavedChangesOnBackend(isDraftLeave(leaveResult.status))
      setFetching(false)
    }
  }

  const onLeaveBadRequest = () => {
    dialogManager.add({
      title: t('common.oops'),
      children: t('common.weAreSorryButSomethingWentWrong'),
      buttons: [
        {
          title: t('common.ok'),
          onClick: () => {
            fetchLeaveTimeline({})
          }
        }
      ]
    })
  }

  const updateLeaveWithChanges = async (changes: any) => {
    try {
      setFetching(true)
      const leaveResult: ILeave = await queries.updateLeave(
        { changes },
        { badRequest: onLeaveBadRequest }
      )
      if (leaveResult) {
        onLeaveResult(leaveResult)
        setSavedTimelinePeriods(
          LeaveUtils.getTimelinePeriods(
            leaveResult,
            customer,
            customerConfig,
            countryCode,
            t
          )
        )
      }
      setLatestRequestChanges(changes)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const startLeave = async () => {
    setFetching(true)
    try {
      const leaveResult: ILeave = await queries.startLeave({
        badRequest: onLeaveBadRequest
      })
      if (leaveResult) {
        onLeaveResult(leaveResult)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const saveLeaveChanges = async () => {
    setFetching(true)
    setSkipTransitionForUpdate(false)
    try {
      const leaveResult: ILeave = await queries.saveLeaveChanges({
        badRequest: onLeaveBadRequest
      })
      if (leaveResult) {
        onLeaveResult(leaveResult)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const cancelLeaveChanges = async () => {
    setFetching(true)
    try {
      const leaveResult: ILeave = await queries.cancelLeaveChanges({
        badRequest: onLeaveBadRequest
      })
      if (leaveResult) {
        onLeaveResult(leaveResult)
        setSavedTimelinePeriods(
          LeaveUtils.getTimelinePeriods(
            leaveResult,
            customer,
            customerConfig,
            countryCode,
            t
          )
        )
      }
      setLatestRequestChanges({})
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const handleTutorialCompleted = () => {
    onTutorialCompleted().then(() => {
      startLeave()
    })
  }

  const onTimelineSaveChanges = () => {
    if (isDraftLeave(leave.status) || isNewLeave(leave.status)) {
      setHasUnsavedChangesOnBackend(false)
      setShouldCallOnSavePlan(true)
      if (isNewLeave(leave.status)) {
        handleTutorialCompleted()
      } else {
        startLeave()
      }
    } else {
      saveLeaveChanges()
    }
  }

  const onTimelineCancelChanges = () => {
    cancelLeaveChanges()
  }

  const onCancelChanges = () => {
    setIsNotSendToast(true)
    fetchLeaveTimeline({})
  }

  const removeLeave = async () => {
    setFetching(true)

    const result: boolean = await queries.removeLeave(leave.id, {
      notFound: () => {
        onLeaveBadRequest()
      },
      badRequest: onLeaveBadRequest,
      unhandled: onLeaveBadRequest
    })

    setFetching(false)
    if (result) {
      closePlanLocalStorageHook()
      navigate(createLeaveRoute)
    }
  }

  const onStartOver = () => {
    dialogManager.add({
      title: t('timeline.startOverDialog.title'),
      children: t('timeline.startOverDialog.body'),
      ariaText: t('timeline.startOverDialog.ariaText'),
      buttons: [
        {
          title: t('common.back'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('timeline.startOverDialog.buttonYes'),
          onClick: () => {
            removeLeave()
          },
          order: 2
        }
      ]
    })
  }

  const pushCreateLeaveRoute = () => {
    navigate(createLeaveRoute)
  }

  const onSavePlan = () => {
    requestAnimationFrame(() => {
      navigate(publishedLeaveRoute)
    })
  }

  const onBirthDate = async (
    birthDate: Moment,
    subtype: string,
    disabilityEndDate: Moment
  ) => {
    try {
      setFetching(true)
      const variables: any = {
        birthDate: birthDate.toDate().toISOString()
      }
      if (subtype) {
        variables.subtype = subtype
      }
      if (disabilityEndDate) {
        variables.disabilityEndDate = disabilityEndDate.toDate().toISOString()
      }
      const leaveResult: ILeave = await queries.setBirthDate(variables, {
        badRequest: onLeaveBadRequest
      })
      if (leaveResult) {
        onLeaveResult(leaveResult)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onChangeBirthDate = async () => {
    try {
      setFetching(true)
      const leaveResult: ILeave = await queries.resetBirthForLeave({
        badRequest: onLeaveBadRequest
      })
      if (leaveResult) {
        onLeaveResult(leaveResult)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onBabyHasArrivedClicked = () => {
    setIsBirthDateMode(true)
  }

  useEffect(() => {
    const userClosedReviewAlert = () => {
      setShowReviewAlert({ leavePreference: null })

      const { onAfterCloseReviewAlert } = customerConfig.leave.timeline
      if (onAfterCloseReviewAlert && contextValue) {
        onAfterCloseReviewAlert(contextValue)
      }
    }

    const preference = showReviewAlert.timelinePreference
    if (preference && timelinePeriods.length) {
      dialogManager.add({
        title: t(`timeline.alerts.${preference}.title`),
        children: (
          <AlertContent>
            {t([
              `timeline.alerts.${preference}.body_${leave.type}`,
              `timeline.alerts.${preference}.body`
            ])}
          </AlertContent>
        ),
        ariaText: t(`timeline.alerts.${preference}.title`),
        onUserClosed: userClosedReviewAlert,
        buttons: [
          {
            title: t(`timeline.alerts.${preference}.action`),
            onClick: userClosedReviewAlert
          }
        ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, showReviewAlert, timelinePeriods])

  useEffect(() => {
    const periodsForToast: IToastPeriod[] = LeaveUtils.getPeriodTypesForToast(
      prevSavedTimelinePeriods,
      savedTimelinePeriods,
      latestRequestChanges
    )

    if (periodsForToast.length !== 0 && !isNotSendToast) {
      periodsForToast.forEach((p: IToastPeriod) => {
        const info = t(
          [
            `timeline.toast.${p.action}_${p.item.type}`,
            `timeline.toast.${p.action}`
          ],
          {
            period:
              p.action === 'available'
                ? p.item.timelineConfig.title.toLowerCase()
                : p.item.timelineConfig.title
          }
        )
        toastManager.addInfo(info)
      })
    }
  }, [
    t,
    isDesktop,
    toastManager,
    isNotSendToast,
    latestRequestChanges,
    savedTimelinePeriods,
    prevSavedTimelinePeriods
  ])

  useEffect(() => {
    if (
      !customerConfig.leave.timeline.showRBTToastMessages ||
      !savedTimelinePeriods ||
      !prevSavedTimelinePeriods ||
      prevSavedTimelinePeriods.length === 0
    ) {
      return
    }

    const timelineDiff = new TimelineDiff(
      prevSavedTimelinePeriods,
      savedTimelinePeriods
    )

    const message = LeaveUtils.rbtToastMessage(timelineDiff)
    if (message) {
      toastManager.addInfo(t(`timeline.toast.period.RampBack.${message}`))
    }
  }, [
    t,
    toastManager,
    customerConfig,
    savedTimelinePeriods,
    prevSavedTimelinePeriods
  ])

  const onRequestClaimUpdateClick = useCallback(async () => {
    const createMetricArgs: IUseCreateMetricArgs = {
      id: leave.id,
      eventType: MetricClaimUpdateRequestClick
    }

    await queries.createMetric(createMetricArgs, {
      anyError: (er): void => console.error(er)
    })

    navigate(claimUpdateRoute)
  }, [queries, navigate, leave])

  const showTpaApprovedView = useCallback(
    (changes: any) => {
      setTpaApprovedViewChanges(changes)
    },
    [setTpaApprovedViewChanges]
  )

  const tpaApprovedView: ReactNode = useMemo(() => {
    if (!tpaApprovedViewChanges) {
      return null
    }

    return (
      <TimelineTpaApprovedViewContainer
        leaveType={leave.type}
        onNext={(state: TpaApprovalState) => {
          onNewChanges(
            {
              ...tpaApprovedViewChanges,
              extra: { approvedState: state === 'approved' }
            },
            true
          )
        }}
        onBack={() => {
          setTpaApprovedViewChanges(null)
        }}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpaApprovedViewChanges, setTpaApprovedViewChanges, leave])

  if (user && leave) {
    const navigationComponent = getNavigationComponent(leave, {
      employeeEmail: user.email
    })
    if (navigationComponent) {
      return navigationComponent
    }
  }
  const loadingSpinner = <LoadingSpinner fullScreen fadesIn />

  if (!leave || !config) {
    return loadingSpinner
  }

  const leaveConfig: any = config.leaveTypes.filter(
    (item: any) => item.type === leave.type
  )[0]

  const contextValue: ITimelineContext = {
    onSavePlan,
    leave,
    countryCode,
    externalNotices,
    leaveStartDate: LeaveUtils.getLeaveStartDate(leave),
    dueDate: LeaveUtils.getDueDate(leave),
    birthDate: LeaveUtils.getBirthDate(leave),
    disabilityEndDate: LeaveUtils.getDisabilityEndDate(leave),
    leaveEndDate: LeaveUtils.getLeaveEndDate(leave),
    gradualReturnStartDate: LeaveUtils.getLeaveGradualReturnStartDate(leave),
    leaveActiveDutyEndDate: LeaveUtils.getActiveDutyEndDate(leave),
    subtypes: leaveConfig.subtypes,
    subtype: leave.subtype,
    fromStartToReturnDays: LeaveUtils.getFromStartToReturnDuration(
      leave,
      'day'
    ),
    totalLeaveDays: LeaveUtils.getTotalLeaveDuration(leave),
    onLeaveSubtypeChanged,
    onUseSupplementPayChanged,
    onVacationBalanceChanged,
    onDueDateChanged,
    onStartDateChanged,
    onDisabilityEndDateChanged,
    onLeaveEndDateChanged,
    onNewChanges,
    updateLeave,
    onTimelineSaveChanges,
    onTimelineCancelChanges,
    leaveHolidays,
    onCalendarActiveDateChanged,
    onCloseExternalNotice,
    onStartOver,
    hasTimelineChanges: hasUnsavedChangesOnBackend,
    onBirthDate,
    onBabyHasArrivedClicked,
    onChangeBirthDate,
    onTutorialCompleted,
    timelinePeriods,
    timelinePreference: showReviewAlert?.timelinePreference,
    onApprovedByTpaChanged,
    setTimelineViewInactive,
    isTimelineViewInactive,
    isLeaveDurationPickerOpened,
    currentDetailsViewPeriod,
    setCurrentDetailsViewPeriod,
    currentDatePickerViewPeriod,
    setCurrentDatePickerViewPeriod,
    setDetailsOpened,
    isDetailsOpened,
    showTpaApprovedView,
    onCancelChanges,
    fetchLeaveTimeline,
    setShowVacationMobileView,
    onRequestClaimUpdateClick
  }

  let timelinePage: any = null
  if (
    !isConfirmationLeaveApprovalMode &&
    !isBirthDateMode &&
    !tpaApprovedViewChanges &&
    !showVacationMobileView
  ) {
    timelinePage = isDesktop ? <PageDesktopVertical /> : <PageMobileVertical />
  }

  const pagePrintable: any = <PagePrintableVertical />

  const birthDateOverlayView: ReactNode = isBirthDateMode && (
    <BirthDateOverlay
      defaultDueDate={leave.dueDate.current}
      subtypes={leaveConfig.subtypes}
      initialSubtype={leave.subtype}
      onSubmit={onBirthDate}
      onCancel={() => {
        setIsBirthDateMode(false)
      }}
    />
  )

  const vacationMobileView = isMobile && showVacationMobileView && (
    <VacationMobileContentView
      metadata={leave.metadata}
      onVacationBalanceChanged={onVacationBalanceChanged}
      onExit={() => setShowVacationMobileView(false)}
    />
  )

  const confirmationLeaveView: ReactNode = isConfirmationLeaveApprovalMode && (
    <ConfirmationLeaveApprovalOverlay
      onConfirm={() => {
        const changes: any = {
          extra: {
            approvedState: true,
            gradualReturnStartDate: leave.dates.gradualReturnStartDate
              ? leave.dates.gradualReturnStartDate.current
              : null,
            activeDutyEndDate: leave.dates.activeDutyEndDate
              ? leave.dates.activeDutyEndDate.current
              : null
          },
          startDate: leave.dates.leaveStart.current,
          endDate: leave.dates.leaveEnd.current
        }

        onNewChanges(changes, true)
        setIsConfirmationLeaveApprovalMode(false)
      }}
      onCancel={() => {
        setIsConfirmationLeaveApprovalMode(false)
      }}
    />
  )

  return (
    <TimelineContext.Provider value={contextValue}>
      <MediaQuery print onChange={onPrintMediaChange}>
        {null}
      </MediaQuery>
      {pagePrintable}
      {timelinePage}
      {birthDateOverlayView}
      {confirmationLeaveView}
      {fetching && loadingSpinner}
      {tpaApprovedView}
      {vacationMobileView}
    </TimelineContext.Provider>
  )
})

TimelineContainer.displayName = 'TimelineContainer'

export default withDialogManager(
  withToastManager(withQueries(TimelineContainer))
)
