import React, { useCallback } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import { printDisplayNoneMixin } from 'src/theme/utils'

const { Consumer, Provider } = React.createContext({})
const { colors } = createTheme()

interface IProps {
  children: any
}

const ToastWrapper = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    ${props =>
      props.theme.isDesktop
        ? css`
            bottom: 41px;
          `
        : css`
            top: 51px;
            height: max-content;
          `}
  }

  .Toastify__toast-body {
    line-height: 130%;
    font-family: Roboto, serif;
    font-size: 14px;
    color: ${props => props.theme.colors.dark60};
  }

  .Toastify__close-button {
    opacity: 1;
    color: ${props => props.theme.colors.dark60};
  }

  .Toastify__close-button:hover {
    opacity: 1;
    color: ${props => props.theme.colors.dark80};
  }

  .Toastify__progress-bar--bg {
    background: transparent !important;
  }

  ${printDisplayNoneMixin};
`

export const ToastProvider = React.memo((props: IProps) => {
  const { children } = props

  const addInfo = useCallback((text: string) => {
    toast(text, {
      type: 'info',
      progressStyle: { background: colors.main100, opacity: 1 },
      icon: (
        <Icon name={'info_circle'} settings={{ fill: colors.main100 }} flex />
      )
    })
  }, [])

  const addError = useCallback((text: string) => {
    toast(text, {
      type: 'error',
      progressStyle: { background: colors.error100, opacity: 1 },
      icon: (
        <Icon
          name={'exclamation_sign_inverted'}
          settings={{ fill: colors.error100 }}
          flex
        />
      )
    })
  }, [])

  const context = {
    addInfo,
    addError
  }

  return (
    <Provider value={context}>
      {children}
      <ToastWrapper
        role={'alert'}
        position={'bottom-left'}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  )
})

ToastProvider.displayName = 'ToastProvider'

export const ToastConsumer = (props: any) => (
  <Consumer>{context => props.children(context)}</Consumer>
)

export const withToastManager = (Comp: any) => {
  const toastConsumer = (props: any) => (
    <ToastConsumer>
      {(context: any) => <Comp toastManager={context} {...props} />}
    </ToastConsumer>
  )

  toastConsumer.displayName = 'ToastConsumer'

  return toastConsumer
}
