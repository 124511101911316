import React from 'react'
import styled, { css } from 'styled-components'
import { H1 } from 'src/UIKit'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

interface IProps {
  children: any
  name: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.theme.isDesktop ? '32px' : '16px')};
  min-height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          margin-bottom: 16px;
        `
      : css`
          padding: 0 16px;
          margin: 0 auto;
          align-items: center;
        `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  font-size: ${props => (props.theme.isDesktop ? '26px' : '22px')};
  width: auto;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px auto 20px;
        `
      : css`
          margin: 16px auto 0;
        `}
`

const Description = styled(ReactMarkdown)`
  white-space: pre-wrap;

  ol {
    list-style: auto;
    padding-left: 24px;
    white-space: normal;
    margin-block-start: -20px;
  }

  line-height: 24px;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.dark60};
`

export const dataAttrs = {
  title: () => 'claim-update-page-title'
}

const ClaimUpdatePage = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name, children } = props

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>{t('claimUpdate.title')}</Title>
      <Description>{t(`claimUpdate.${name}.description`)}</Description>
      {children}
    </Container>
  )
})

ClaimUpdatePage.displayName = 'ClaimUpdatePage'

export default ClaimUpdatePage
