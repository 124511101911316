import CasualLeave from './CasualLeave'
import PaidLeave from './PaidLeave'
import PaidLeaves from './PaidLeaves'
import PreDelivery from './PreDelivery'
import PublicHolidays from './PublicHolidays'
import Sabbatical from './Sabbatical'
import Sickness from './Sickness'
import UnpaidMedical from './UnpaidMedical'
import UnpaidPersonal from './UnpaidPersonal'
import Vacation from './Vacation'

export default {
  CasualLeave,
  PaidLeave,
  PaidLeaves,
  PreDelivery,
  PublicHolidays,
  Sabbatical,
  Sickness,
  UnpaidMedical,
  UnpaidPersonal,
  Vacation
}
