import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { Icon } from 'src/UIKit'

const { colors } = createTheme()

interface IProps {
  name: string
  onChange?: (value: boolean, payload?: string | number) => void
  checked: boolean
  disabled?: boolean
  className?: string
  payload?: string | number
  ariaLabel?: string
  testId?: string
}

interface ICheckboxContainer {
  $disabled: boolean
  $isFocus: boolean
}

export const CheckboxContainer = styled.button<ICheckboxContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  outline-offset: -3px;
  ${props =>
    props.$disabled &&
    css`
      cursor: unset;
    `}
  border-style: solid;

  ${props => {
    const { $disabled, $isFocus } = props
    if ($disabled) {
      return css`
        border-width: 1px;
        border-color: ${colors.main20};
      `
    } else {
      if ($isFocus) {
        return css`
          border-width: 2px;
          border-color: ${colors.main110};
        `
      } else {
        return css`
          border-width: 1px;
          border-color: ${colors.main100};
        `
      }
    }
  }}

  &:focus {
    border-width: 2px;
    outline: none;
  }
`

interface IStyledCheckbox {
  name: string
  checked: boolean
}

const StyledCheckbox = styled.div<IStyledCheckbox>`
  display: inline-block;
  margin-top: 4px;
`

export const dataAttrs = {
  checkbox: (testId?: string) =>
    testId ? `checkbox-button-${testId}` : 'checkbox-button'
}

export const Checkbox = React.memo((props: IProps) => {
  const {
    checked,
    payload,
    onChange,
    name,
    disabled,
    className,
    ariaLabel,
    testId
  } = props

  const [isFocus, setIsFocus] = useState(false)

  const onChangeCallback = useCallback(() => {
    if (!onChange || disabled) {
      return null
    }
    onChange(!checked, payload)
  }, [onChange, checked, payload, disabled])

  const onFocus = useCallback(() => {
    setIsFocus(true)
  }, [setIsFocus])

  const onBlur = useCallback(() => {
    setIsFocus(false)
  }, [setIsFocus])

  const checkmarkView: ReactNode = useMemo(() => {
    if (checked) {
      return disabled ? (
        <Icon
          name={'checkbox_checkmark'}
          settings={{
            fill: colors.main20
          }}
        />
      ) : (
        <Icon
          name={'checkbox_checkmark'}
          settings={{
            fill: colors.main100
          }}
        />
      )
    }
  }, [checked, disabled])

  return (
    <CheckboxContainer
      data-testid={dataAttrs.checkbox(testId)}
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onChangeCallback}
      disabled={disabled}
      $disabled={disabled}
      $isFocus={isFocus}
      tabIndex={disabled ? -1 : 0}
      role={'checkbox'}
      aria-hidden={disabled}
      aria-checked={checked}
      aria-label={ariaLabel}
    >
      <StyledCheckbox name={name} checked={checked}>
        {checkmarkView}
      </StyledCheckbox>
    </CheckboxContainer>
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
