import React, { useMemo } from 'react'
import styled from 'styled-components'
import Title from './components/Title'
import Description from './components/Description'
import { IPeriodStatusAttr } from '../PeriodView'
import { Icon } from 'src/UIKit'
import Tooltip from 'src/components/Tooltip'
import { isEmpty } from 'lodash'
import ClaimStatusBadge from 'src/components/ClaimStatusBadge'

export interface ITimelineTitleView {
  title: string
  titleBold?: boolean
  description: string
  duration?: string
  children?: any
  statusAttr?: IPeriodStatusAttr
  claimStatus?: IPeriodClaimStatus
}

const Container = styled.div`
  width: 100%;
`

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ChildrenContainer = styled.div`
  margin-top: 16px;
`

export const propsAreEqual = (
  prev: ITimelineTitleView,
  next: ITimelineTitleView
): boolean => {
  if (prev.children !== next.children) {
    return false
  }
  if (prev.titleBold !== next.titleBold) {
    return false
  }
  if (prev.title !== next.title) {
    return false
  }
  if (prev.description !== next.description) {
    return false
  }

  return true
}

const Duration = styled.span`
  white-space: nowrap;
  font-weight: 400;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled(Icon)`
  margin-right: 6px;
  margin-bottom: ${props => (props.theme.isDesktop ? '8px' : '2px')};
`

const ClaimStatusBadgeWrapper = styled(ClaimStatusBadge)`
  margin-bottom: 12px;
`

const TitleView = React.memo((props: ITimelineTitleView) => {
  const {
    title,
    titleBold,
    description,
    duration,
    children,
    statusAttr,
    claimStatus
  } = props

  const statusIcon = useMemo(() => {
    if (isEmpty(statusAttr)) {
      return
    }

    return (
      <Tooltip
        dashedUnderline={{ skipUnderline: true }}
        title={statusAttr.tooltipTitle}
        body={statusAttr.tooltipBody}
        offset={[-15, 10]}
      >
        <IconWrapper
          name={statusAttr.iconName}
          settings={{
            fill: statusAttr.iconColor,
            width: '16px',
            height: '16px'
          }}
        />
      </Tooltip>
    )
  }, [statusAttr])

  return (
    <Container>
      <InnerContainer>
        <TextsContainer>
          <TitleContainer>
            <LeftContainer>
              {statusIcon}
              <Title bold={titleBold}>{title}</Title>
            </LeftContainer>
            <ClaimStatusBadgeWrapper claimStatus={claimStatus} />
          </TitleContainer>
          <Description>
            <span>{description}</span>
            {duration && <Duration>{duration}</Duration>}
          </Description>
        </TextsContainer>
      </InnerContainer>
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </Container>
  )
}, propsAreEqual)

TitleView.displayName = 'TitleView'

export default TitleView
