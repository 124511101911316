import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export default (pageTitleKey: string) => {
  const { t } = useTranslation()

  const title = useMemo(() => {
    const veer = t('common.pageTitles.veer')

    if (!pageTitleKey) {
      return veer
    }

    return `${veer} - ${t(`common.pageTitles.${pageTitleKey}`)}`
  }, [pageTitleKey, t])

  useEffect(() => {
    document.title = title
  }, [title])
}
