import React from 'react'
import styled from 'styled-components'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  attachment: ILink
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;
`

const Label = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.main100};
  padding: 0;
  margin: 0 0 0 8px;
`

const AttachmentView = React.memo((props: IProps) => {
  const {
    className,
    attachment: { text, link }
  } = props
  const { t } = useTranslation()

  return (
    <Container
      className={className}
      onClick={() => {
        window.open(link, '_blank', 'noopener')
      }}
    >
      <Icon
        name={'attachment'}
        ariaLabel={t('common.accessibilityText.attachment')}
      />
      <Label>{text}</Label>
    </Container>
  )
})

AttachmentView.displayName = 'AttachmentView'

export default AttachmentView
