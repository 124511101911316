import React from 'react'
import { H1 } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import usePageTitle from 'src/components/hooks/usePageTitle'
import Markdown from 'react-markdown'
import withRouter from 'src/components/hooks/useRouter'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { useParams } from 'react-router'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    'title'
    'text';
  padding: 0 16px;
  margin: 0 auto;
  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  grid-area: title;
`

const TextWrapper = styled(Markdown)`
  grid-area: text;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          font-size: 16px;
          line-height: 24px;
        `
      : css`
          width: 100%;
        `}
`

const hashToTranslationKeyDict: any = {
  bm90LXVz: 'nonUs',
  bGVzcy0yNGhvdXJz: 'lessThan24HoursPerWeek'
}

export const UnsupportedPage = React.memo(() => {
  const { t } = useTranslation()

  usePageTitle('unsupported')
  const { hash } = useParams()

  let translationKey = hashToTranslationKeyDict.bm90LXVz
  // eslint-disable-next-line no-prototype-builtins
  if (hashToTranslationKeyDict.hasOwnProperty(hash)) {
    translationKey = hashToTranslationKeyDict[hash]
  }

  return (
    <Container>
      <Title>{t(`unsupportedPage.${translationKey}.title`)}</Title>
      <TextWrapper
        urlTransform={TelUrlTransformer}
        components={{
          link: LinkRenderer
        }}
      >
        {t(`unsupportedPage.${translationKey}.body`)}
      </TextWrapper>
    </Container>
  )
})

UnsupportedPage.displayName = 'UnsupportedPage'

export default withRouter(UnsupportedPage)
