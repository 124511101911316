import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Button from 'src/UIKit/Button'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import Box from 'src/components/Box'
import ReactMarkdown from 'react-markdown'

interface IProps {
  absence: IAbsence
  onRemoveAbsence: (id: string) => void
}

const RemovePlanButton = styled(Button)`
  margin: 24px 0 16px;
  width: 256px;
  height: 48px;

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      margin: 0;
    `}
`

const MobileButtonContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -webkit-fill-available;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -moz-available;
  width: fill-available;
  padding: 8px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TextWrapper = styled(ReactMarkdown)`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 16px;
    `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin: 0 0 16px;
    `}
`

export const dataAttrs = {
  removePlanBtn: () => 'manager-dashboard-remove-plan-btn'
}

const DashboardNoActive = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { absence, onRemoveAbsence } = props

  const userName: string = absence.employee.name.split(' ')[0]

  const removePlanButton = useMemo(
    () => (
      <RemovePlanButton
        data-testid={dataAttrs.removePlanBtn()}
        onClick={() => onRemoveAbsence(absence.id)}
      >
        {t('manager.removePlan')}
      </RemovePlanButton>
    ),
    [absence, onRemoveAbsence, t]
  )

  const textContent = useMemo(
    () => (
      <TextWrapper
        components={{
          a: (linkProps: any) => (
            <a
              href={linkProps.href}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              {linkProps.children}
            </a>
          )
        }}
      >
        {t('manager.noActiveText', { userName })}
      </TextWrapper>
    ),
    [t, userName]
  )

  const desktopContainer = useMemo(
    () => (
      <Box>
        <BodyContainer>
          {textContent}
          <ButtonContainer>{removePlanButton}</ButtonContainer>
        </BodyContainer>
      </Box>
    ),
    [removePlanButton, textContent]
  )

  const mobileContainer = useMemo(
    () => (
      <>
        {textContent}
        <MobileButtonContainer>{removePlanButton}</MobileButtonContainer>
      </>
    ),
    [removePlanButton, textContent]
  )

  return <div>{isDesktop ? desktopContainer : mobileContainer}</div>
})

DashboardNoActive.displayName = 'DashboardNoActive'

export default DashboardNoActive
