const dropDown = () => ({
  wrapper: 100,
  optionsContainer: 13000,
  placeholderWrapper: 13010,
  arrow: 1003,
  openButton: 1004
})

const datePicker = () => ({
  container: 2000,
  clickOverlay: 2002
})

const popover = () => ({
  parent: 12000
})

const loadingSpinner = () => ({
  fullScreenContainer: 1000
})

const tooltip = () => 10000

const toast = () => 11000

const mobileExpanderView = () => ({
  content: 100
})

const modals = () => ({
  overlay: 13000
})

const loadingOverlay = () => 13000

const updatePrompt = () => 12001

const banner = () => 12000

const header = () => 12000

const footer = () => 1000

const menu = () => ({
  overlay: 3000,
  content: 3010
})

const timeline = () => ({
  rowsLabels: {
    labelsContainer: 2,
    overlay: 3
  },
  info: {
    printable: 2000
  },
  footnote: 10,
  mobileButtons: 15,
  coverOverlay: 20,
  birthDateOverlay: 300,
  mobile: {
    detailsView: 10,
    detailsViewTitle: 15,
    detailsContainer: {
      controlsContainer: 10
    }
  },
  vertical: {
    header: 200,
    whiteSide: 100
  },
  tpaApprovedView: 300
})

const userProfile = () => ({
  closeOutPlanPlanOverlay: 1000,
  closeOutPlanOverlayMobileTitle: 1010
})

const notifications = () => ({
  page: {
    markAllAsReadWrapper: 2
  }
})

const journeyMap = () => ({
  header: 100,
  list: {
    item: {
      clickWrapper: 2
    },
    blurred: -1,
    button: 10
  },
  itemView: {
    rootContainer: 1000,
    container: 1001,
    mobileCtaButtonWrapper: 1010,
    video: {
      loadingSpinner: 1100,
      horizontalLine: 1100,
      mobileIFrame: 1
    }
  },
  page: {
    priorityFiltersWrapper: 3
  }
})

const survey = () => ({
  bottomPanel: 3000,
  itemView: {
    container: 1001,
    buttonContainer: 1010
  }
})

const bubblesOverlay = () => ({
  overlay: 12000,
  bubble: 12010
})

const charts = () => ({
  tooltip: 1000
})

// eslint-disable-next-line
const welcome = () => {
  return {
    itemOverlay: 10,
    infoOverlay: 20,
    disabledOverlay: 20
  }
}

const createLeave = () => ({
  page: 100
})

const vacationSteps = () => ({
  page: 100,
  tooltip: 13000,
  mobileOverlay: 20
})

const transitionSteps = () => ({
  page: 100
})

export default {
  dropDown: dropDown(),
  datePicker: datePicker(),
  popover: popover(),
  loadingSpinner: loadingSpinner(),
  tooltip: tooltip(),
  toast: toast(),
  mobileExpanderView: mobileExpanderView(),
  modals: modals(),
  loadingOverlay: loadingOverlay(),
  updatePrompt: updatePrompt(),
  header: header(),
  footer: footer(),
  menu: menu(),
  timeline: timeline(),
  userProfile: userProfile(),
  notifications: notifications(),
  journeyMap: journeyMap(),
  survey: survey(),
  bubblesOverlay: bubblesOverlay(),
  charts: charts(),
  welcome: welcome(),
  createLeave: createLeave(),
  vacationSteps: vacationSteps(),
  transitionSteps: transitionSteps(),
  banner: banner()
}
