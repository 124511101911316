import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import withQueries from 'src/components/HOC/withQueries'
import styled from 'styled-components'
import { IWithQueriesProps } from 'src/react-app-env'
import {
  errorPageRoute,
  managerDashboardRoute,
  managerArchiveJourneyMapRoute
} from 'src/routes/constants'
import usePageTitle from 'src/components/hooks/usePageTitle'
import DashboardContext, { IManagerDashboardContext } from '../DashboardContext'
import { sortAbsencesByStartDate } from 'src/utils/absences'
import LoadingSpinner from 'src/components/LoadingSpinner'
import NoAbsencesView from '../components/NoAbsencesView'
import AbsencesArchivedList from '../components/AbsencesArchivedList'
import { footer } from 'src/constants/frame'

interface IProps extends IWithQueriesProps {}

const Container = styled.div`
  max-width: 736px;
  margin: auto;
  padding-bottom: ${props =>
    props.theme.isDesktop ? `calc(${footer.height().px} + 10px)` : '10px'};
`

export const DashboardArchivedContainer = React.memo((props: IProps) => {
  const navigate = useNavigate()

  const [absences, setAbsences] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useRedirectByUserRole({ navigate, allowedRole: 'manager' })
  usePageTitle('managerDashboardArchive')

  const selectAbsence = useCallback(
    (absence: IAbsence) =>
      navigate(managerArchiveJourneyMapRoute.replace(':absenceId', absence.id)),
    [navigate]
  )

  const fetchArchivedAbsences = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await props.queries.fetchAbsencesDashboard(true, {
        fetchPolicy: 'network-only'
      })
      setAbsences(sortAbsencesByStartDate(result))
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [props, navigate, setAbsences])

  useEffect(() => {
    fetchArchivedAbsences()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue: IManagerDashboardContext = {
    dashboardAbsences: absences,
    selectAbsence
  }

  const content = useMemo(
    () => (
      <Container>
        <AbsencesArchivedList
          absences={absences}
          selectAbsence={selectAbsence}
        />
      </Container>
    ),
    [absences, selectAbsence]
  )

  if (isLoading) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  if (absences.length === 0) {
    return (
      <NoAbsencesView
        isArchiveMode
        onPlansArchiveClick={() => navigate(managerDashboardRoute)}
      />
    )
  }

  return (
    <DashboardContext.Provider value={contextValue}>
      {content}
    </DashboardContext.Provider>
  )
})

DashboardArchivedContainer.displayName = 'DashboardActiveContainer'

export default withQueries(DashboardArchivedContainer)
