import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveView from '../UI/CreateLeaveView'
import styled from 'styled-components'
import DayButton from 'src/UIKit/DayButton'
import { weekDays } from 'src/utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { Text } from 'src/UIKit'
import moment from 'moment'

const WorkingDaysContainer = styled.div`
  padding: 15px;
  min-width: 100px;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
`

const WorkingDaysTitle = styled(Text)`
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 10px;
`

export const dataAttrs = {
  dayButton: (day: string) => `setup-one-week-day-button-${day}`
}

export const SetupOneWeekSchedule = React.memo(() => {
  const { t } = useTranslation()

  const {
    workDays,
    onWorkDayClicked,
    resetWorkSchedule,
    setWorkScheduleStartDate,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  useEffect(() => {
    setWorkScheduleStartDate(moment().utc().startOf('isoWeek'))
  }, [setWorkScheduleStartDate])

  const days = useMemo(() => workDays[0], [workDays])

  const daysView: ReactNode[] = useMemo(
    () =>
      weekDays.map((day: string) => (
        <DayButton
          data-testid={dataAttrs.dayButton(day)}
          key={day}
          $selected={days.has(day)}
          onClick={() => onWorkDayClicked(0, day)}
          role={'switch'}
          aria-checked={days.has(day)}
          aria-label={day}
        >
          {day.toUpperCase().charAt(0)}
        </DayButton>
      )),
    [days, onWorkDayClicked]
  )

  return (
    <CreateLeaveView
      name={'oneWeekSchedule'}
      onBackClicked={() => {
        resetWorkSchedule()
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
      isNextDisabled={days.size === 0}
    >
      <WorkingDaysContainer>
        <WorkingDaysTitle>
          {t('createLeave.oneWeekSchedule.subtitle')}
        </WorkingDaysTitle>
        {daysView}
      </WorkingDaysContainer>
    </CreateLeaveView>
  )
})

SetupOneWeekSchedule.displayName = 'SetupOneWeekSchedule'

export default SetupOneWeekSchedule
