import React, { useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { getTodayYesterdayOrDate } from 'src/utils/dateUtils'
import { useTranslation } from 'react-i18next'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'

interface IProps {
  className?: string
  notification: INotification
  onClick: () => void
  isAlternativeView: boolean
}

const { colors } = createTheme()

const Container = styled.li<{ $isAlternativeView: boolean }>`
  cursor: pointer;
  min-height: 78px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.colors.light100};

  &:focus {
    background: ${props => props.theme.colors.main05};
    outline: none;
  }

  ${props =>
    props.$isAlternativeView
      ? css`
          border-bottom: 1px solid ${props.theme.colors.main20};
          padding: 0 16px;
          width: calc(100% - 32px);
        `
      : props.theme.isDesktop
        ? css`
            border: 1px solid ${props.theme.colors.dark05};
            box-sizing: border-box;
            box-shadow: 0 0 10px rgb(0 0 0 / 5%);
            border-radius: 8px;
            margin: 8px 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 32px;
            }
          `
        : css`
            background: ${props.theme.colors.backgroundColor};
            border-top: 1px solid ${props.theme.colors.main20};

            &:last-child {
              border-bottom: 1px solid ${props.theme.colors.main20};
              margin-bottom: 80px;
            }
          `}

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: unset;
  }
`

const Circle = styled.div<{ $isAlternativeView: boolean; $color: string }>`
  border-radius: 50%;
  ${props =>
    !props.$isAlternativeView &&
    css`
      margin-left: 16px;
    `};
  ${props =>
    !props.$isAlternativeView && props.theme.isDesktop
      ? css`
          width: 16px;
          height: 16px;
        `
      : css`
          width: 8px;
          height: 8px;
        `}
  background: ${props => props.$color};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: 16px;
`

const Title = styled.div<{ $read: boolean; $isAlternativeView: boolean }>`
  text-align: left;
  ${props =>
    !props.$isAlternativeView && props.theme.isDesktop
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: 14px;
        `}
  font-weight: ${props => (props.$read ? 'normal' : '500')};
  color: ${props =>
    props.$read ? props.theme.colors.dark60 : props.theme.colors.dark80};
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Date = styled.div<{ $isAlternativeView: boolean }>`
  font-weight: normal;
  flex: 1;
  text-align: left;

  ${props =>
    !props.$isAlternativeView && props.theme.isDesktop
      ? css`
          margin-top: 8px;
          font-size: 14px;
          height: 21px;
          line-height: 100%;
          color: ${props.theme.colors.dark60};
        `
      : css`
          margin-top: 4px;
          font-size: 12px;
          line-height: 130%;
          color: ${props.theme.colors.dark60};
        `}
`

const Tag = styled.span`
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  background: ${props => props.theme.colors.backgroundColor};
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.colors.dark10};
  border-radius: 16px;
`

const NotificationsListItem = React.memo((props: IProps) => {
  const {
    className,
    notification: { title, read, date, tag },
    onClick,
    isAlternativeView
  } = props

  const containerRef = useRef(null)

  const { t } = useTranslation()
  const dateString: string = useMemo(
    () => getTodayYesterdayOrDate(date, t),
    [date, t]
  )
  const hasTag: boolean = useMemo(() => tag && tag.length > 0, [tag])
  const ariaLabel: string = useMemo(() => {
    const readOrUnread: string = t(
      `common.accessibilityText.${
        read ? 'readNotification' : 'unreadNotification'
      }`
    )
    const received: string = t('common.accessibilityText.received')
    return `${readOrUnread}. ${received} ${dateString}. ${title}`
  }, [read, title, t, dateString])

  useKeyActionHandler(containerRef.current, onClick)

  return (
    <Container
      className={className}
      onClick={onClick}
      $isAlternativeView={isAlternativeView}
      aria-label={ariaLabel}
      tabIndex={0}
      ref={containerRef}
    >
      <Circle
        $color={read ? colors.dark05 : colors.main100}
        $isAlternativeView={isAlternativeView}
      />
      <ContentContainer>
        <Title $read={read} $isAlternativeView={isAlternativeView} aria-hidden>
          {title}
        </Title>
        <BottomContainer aria-hidden>
          <Date $isAlternativeView={isAlternativeView}>{dateString}</Date>
          {hasTag && <Tag>{tag}</Tag>}
        </BottomContainer>
      </ContentContainer>
    </Container>
  )
})

NotificationsListItem.displayName = 'NotificationsListItem'

export default NotificationsListItem
