import React, { useRef, useEffect, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import useComponentRect from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import ScreenContext from 'src/contexts/ScreenContext'
import usePrevious from '../hooks/usePrevious'

const ANIMATION_TIME = 300

interface IProps {
  className?: string
  children: any
}

const Container = styled.div<{ $childrenHeight: number }>`
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  border-radius: 8px;
  transition: all ${ANIMATION_TIME}ms ease-in-out;
  ${props => {
    const { $childrenHeight } = props
    if ($childrenHeight !== 0) {
      return css`
        height: ${$childrenHeight}px;
      `
    }
  }}
`

const FlexibleContainer = React.memo((props: IProps) => {
  const { className, children } = props

  const childrenRef = useRef(null)
  const { height } = useComponentRect(childrenRef)
  const forceUpdate = useForceUpdate()
  const { isDesktop, isMobile } = useContext(ScreenContext)

  const wasMobile: boolean = usePrevious(isMobile)
  const isScreenSwitched = useMemo(
    () => (wasMobile ? isDesktop : isMobile),
    [isMobile, isDesktop, wasMobile]
  )

  useEffect(() => {
    forceUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, isScreenSwitched])

  return (
    <Container className={className} $childrenHeight={height}>
      {React.cloneElement(children, { ref: childrenRef })}
    </Container>
  )
})

FlexibleContainer.displayName = 'FlexibleContainer'

export default FlexibleContainer
