import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Title = styled.span`
  grid-area: title;
  color: ${props => props.theme.colors.dark80};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: normal;
          font-size: 22px;
          margin: 16px 16px 8px;
          line-height: 130%;
        `
      : css`
          font-weight: 500;
          font-size: 16px;
          margin: 8px 0 8px 8px;
          line-height: 150%;
        `};
`

const JourneyMapListItemTitle = React.memo((props: IProps) => {
  const { className, children } = props

  return (
    <Title className={className} aria-hidden>
      {children}
    </Title>
  )
})

JourneyMapListItemTitle.displayName = 'JourneyMapListItemTitle'

export default JourneyMapListItemTitle
