import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'

interface IProps {
  children?: ReactNode
  color?: any
  urlTransform?: any
  allowsParagraph?: boolean
  'data-testid'?: string
  components?: any
}

const Wrapper = styled(ReactMarkdown)<{ $color: string }>`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${props => props.$color || props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`

const MarkdownText = React.memo((props: IProps) => {
  const p: any = { ...props }
  if (!props.allowsParagraph) {
    p.allowedElements = ['text', 'strong', 'delete', 'emphasis', 'link']
    p.unwrapDisallowed = true
    p.$color = props.color
  }
  const dataTestId = props['data-testid']
  return dataTestId ? (
    <div data-testid={dataTestId}>
      <Wrapper {...p}>{props.children}</Wrapper>
    </div>
  ) : (
    <Wrapper {...p}>{props.children}</Wrapper>
  )
})

MarkdownText.displayName = 'MarkdownText'

export default MarkdownText
