import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DetailsContainer from 'src/features/Timeline/components/vertical/DetailsView/components/DetailsContainer'
import { ITimelinePeriod } from 'src/react-app-env'
import TimelineContext from 'src/features/Timeline/Context'

interface IProps {
  period: ITimelinePeriod
}

const DetailsContentView = React.memo((props: IProps) => {
  const { leave } = useContext(TimelineContext)
  const { period } = props
  const { t }: { t: any } = useTranslation()

  const detailsType = period.timelineConfig?.detailsType
  const transitionFromType = leave.metadata?.transitionFromType
  const pregnancyLossType = leave.metadata?.pregnancyLossType
  const extraDescription = period.timelineConfig?.detailsExtraDescription(t)

  const description = useMemo(
    () =>
      t(
        [
          `timelineHints.periods.${detailsType}.description_${leave.type}_${transitionFromType}_${pregnancyLossType}`,
          `timelineHints.periods.${detailsType}.description_${leave.type}_${transitionFromType}`,
          `timelineHints.periods.${detailsType}.description_${leave.type}`,
          `timelineHints.periods.${detailsType}.${
            leave.note ? 'descriptionNonQualifying' : 'description'
          }`
        ],
        {
          ...leave.metadata,
          context: leave.metadata?.countryState
        }
      ),
    [leave, detailsType, transitionFromType, pregnancyLossType, t]
  )

  return (
    <DetailsContainer
      period={period}
      description={description}
      extraDescription={extraDescription}
    />
  )
})

DetailsContentView.displayName = 'DetailsContentView'

export default DetailsContentView
