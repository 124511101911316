import React from 'react'
import { INavigationItem } from 'src/react-app-env'
import {
  helpCenterRoute,
  journeyMapRoute,
  managerArchiveRoute,
  managerDashboardRoute,
  managerHelpCenterRoute,
  timelineRoute,
  transitionFlowRoute,
  userProfileRoute
} from 'src/routes/constants'
import {
  isNavigationArchivedPlansSelected,
  isNavigationHelpCenterSelected,
  isNavigationJourneyMapSelected,
  isNavigationManagerDashboardSelected,
  isNavigationManagerHelpCenterSelected,
  isNavigationTimelineSelected,
  isNavigationUserProfileRouteSelected,
  isPathnameLike
} from 'src/utils/routeUtils'
import { getOverDueItems } from 'src/utils/journeyMap'
import { isPublishedOrBabyArrivedLeave } from 'src/utils/leaveStatusUtils'
import Item from './Item'
import { NavigateFunction, Location } from 'react-router-dom'
import {
  isDeniedClaimStatus,
  isExtensionDeniedClaimStatus
} from 'src/utils/leaveUtils'

export interface INavigationMethodsData {
  leave: ILeave
  t: any
  toastManager: any
  navigate: NavigateFunction
  location: Location
  onNavigated: () => void
  isManagerRoute: boolean
  isHrAdminRoute: boolean
  isEmployee: boolean
}

const onItemClick = (item: INavigationItem, data: INavigationMethodsData) => {
  data.navigate(item.route)

  if (data.onNavigated) {
    data.onNavigated()
  }
}

const getItems = (data: INavigationMethodsData, isDesktop: boolean) => {
  const {
    leave,
    t,
    location: { pathname }
  } = data
  const isPublishedOrArrived: boolean =
    leave && isPublishedOrBabyArrivedLeave(leave.status)
  const isDeniedOrExtensionDeniedClaimStatus: boolean =
    isDeniedClaimStatus(leave) || isExtensionDeniedClaimStatus(leave)

  const isTransitionFlow: boolean = isPathnameLike(
    pathname,
    transitionFlowRoute
  )

  const hasOverdueItems: boolean =
    !data.isManagerRoute &&
    leave &&
    leave.journeyMap &&
    getOverDueItems(leave.journeyMap).length !== 0

  const items: INavigationItem[] = []
  const isJourneyMapDisabled: boolean =
    !data.isManagerRoute && (!leave || !isPublishedOrArrived)
  const isArchivedPlansDisabled = !data.isManagerRoute

  const timelineItem: INavigationItem = {
    title: t('navigation.timeline'),
    route: timelineRoute,
    selected: isNavigationTimelineSelected(pathname),
    disabled: isTransitionFlow
  }

  const journeyItem: INavigationItem = {
    title: t('navigation.journeyMap'),
    route: journeyMapRoute,
    selected: isNavigationJourneyMapSelected(pathname),
    disabled:
      isJourneyMapDisabled ||
      isTransitionFlow ||
      isDeniedOrExtensionDeniedClaimStatus,
    showsDot:
      hasOverdueItems &&
      !isTransitionFlow &&
      !isDeniedOrExtensionDeniedClaimStatus
  }

  const managerDashboardItem: INavigationItem = {
    title: t('navigation.dashboard'),
    route: managerDashboardRoute,
    selected: isNavigationManagerDashboardSelected(pathname),
    disabled: false,
    showsDot: false
  }

  const managerPlansArchive: INavigationItem = {
    title: t('navigation.plansArchive'),
    route: managerArchiveRoute,
    selected: isNavigationArchivedPlansSelected(pathname),
    disabled: isArchivedPlansDisabled
  }

  const managerHelpCenterItem: INavigationItem = {
    title: t('navigation.helpCenter'),
    route: managerHelpCenterRoute,
    selected: isNavigationManagerHelpCenterSelected(pathname),
    disabled: false
  }

  const helpCenterItem: INavigationItem = {
    title: t('navigation.helpCenter'),
    route: helpCenterRoute,
    selected: isNavigationHelpCenterSelected(pathname),
    disabled: false
  }

  const profileItem: INavigationItem = {
    title: t('header.profileAndSettings'),
    route: userProfileRoute,
    selected: isNavigationUserProfileRouteSelected(pathname)
  }

  if (!data.isManagerRoute && !data.isHrAdminRoute && data.isEmployee) {
    items.push(timelineItem)
    items.push(journeyItem)
    items.push(helpCenterItem)
    if (!isDesktop) {
      items.push(profileItem)
    }
  } else if (data.isManagerRoute) {
    items.push(managerDashboardItem)
    items.push(managerPlansArchive)
    items.push(managerHelpCenterItem)
  }

  return items
}

const renderItems = (data: INavigationMethodsData, isDesktop: boolean) => {
  const items: INavigationItem[] = getItems(data, isDesktop)
  return items.map((itemData: INavigationItem) => (
    <Item
      key={itemData.title}
      data={itemData}
      onClick={() => {
        onItemClick(itemData, data)
      }}
    />
  ))
}

export { renderItems, onItemClick, getItems }
