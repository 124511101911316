import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'

import createTheme from 'src/theme'
import { IIconName } from 'src/react-app-env'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

interface IProps {
  iconName?: IIconName
  text: string
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  line-height: 24px;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

const IconWrapper = styled(Icon)`
  padding-top: 2px;
`

const Hint = React.memo((props: IProps) => {
  const { iconName, text } = props
  return (
    <Container>
      <IconWrapper
        name={iconName || 'info_circle'}
        settings={{ fill: colors.main100 }}
      />
      <ReactMarkdown
        urlTransform={TelUrlTransformer}
        components={{ a: LinkRenderer }}
      >
        {text}
      </ReactMarkdown>
    </Container>
  )
})

Hint.displayName = 'Hint'

export default Hint
