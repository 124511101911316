import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import BirthDateButton from 'src/features/Timeline/components/common/BirthdateButton'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import LabelDuration from '../LabelDuration'
import { ITimelinePeriod } from 'src/react-app-env'
import SupplementPayDetails from '../SupplementPayControlBar/SupplementPayDetails'
import RemainingBondingDetails from '../RemainingBondingDetails'

interface IProps {
  className?: string
  totalLeaveDays: number
  approvedByTPA: boolean
  metadata: any
  buttons?: any
  onBabyArrivedClicked?: () => void
  timelinePeriods: ITimelinePeriod[]
  isSlimBabyArrivedButton?: boolean
  disabled?: boolean
  useTpaApproval: boolean
  isFirstBirthDateButton?: boolean
  unusedBonding?: IUnusedBonding
  showUnplannedBondingTooltipLessThan: (unusedBondingDays: number) => boolean
  showApprovedDuration?: boolean
}

const Container = styled.div<any>`
  display: flex;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin: 16px 16px 0;
      gap: 16px;
    `}
`

const InfoItem = styled.div`
  ${props =>
    props.theme.isDesktop &&
    css`
      &:not(:first-child) {
        margin-left: 56px;
      }
    `}

  @media print {
    opacity: 0;
  }
`

const Info = React.memo(
  (props: IProps) => {
    const {
      className,
      totalLeaveDays,
      buttons,
      approvedByTPA,
      onBabyArrivedClicked,
      metadata,
      isSlimBabyArrivedButton,
      disabled,
      useTpaApproval,
      isFirstBirthDateButton,
      unusedBonding,
      showUnplannedBondingTooltipLessThan,
      showApprovedDuration
    } = props
    const { t } = useTranslation()

    const { isDesktop } = useContext(ScreenContext)

    const supplementPayView = useMemo(() => {
      if (
        (!metadata?.sickHoursToBeUsed && !metadata?.vacationHoursToBeUsed) ||
        (metadata?.sickHoursToBeUsed === 0 &&
          metadata?.vacationHoursToBeUsed === 0)
      ) {
        return
      }

      return (
        <InfoItem>
          <SupplementPayDetails
            sickHoursToBeUsed={metadata.sickHoursToBeUsed}
            vacationHoursToBeUsed={metadata.vacationHoursToBeUsed}
          />
        </InfoItem>
      )
    }, [metadata])

    const remainingBondingView = useMemo(() => {
      if (!unusedBonding) {
        return
      }

      return (
        <InfoItem>
          <RemainingBondingDetails
            durationType={unusedBonding.durationType}
            unusedBondingDays={unusedBonding.unusedDays}
            unusedBondingCalendarDays={unusedBonding.unusedCalendarDays}
            showUnplannedBondingTooltipLessThan={
              showUnplannedBondingTooltipLessThan
            }
          />
        </InfoItem>
      )
    }, [showUnplannedBondingTooltipLessThan, unusedBonding])

    const leaveTpaApprovalView = useMemo(() => {
      if (!showApprovedDuration || !useTpaApproval) {
        return null
      }

      const title: string = t(
        `timelineCounters.${
          approvedByTPA ? 'approvedLeave' : 'unapprovedLeave'
        }`
      )
      return (
        <InfoItem>
          <LabelDuration
            title={title}
            value={totalLeaveDays}
            isUnapproved={!approvedByTPA}
          />
        </InfoItem>
      )
    }, [t, totalLeaveDays, approvedByTPA, useTpaApproval, showApprovedDuration])

    const birthDateButton = useMemo(() => {
      if (!onBabyArrivedClicked) {
        return null
      }

      return (
        <BirthDateButton
          onClick={onBabyArrivedClicked}
          isSlim={isSlimBabyArrivedButton}
          disabled={disabled}
          isFirstBirthDateButton={isFirstBirthDateButton}
        />
      )
    }, [
      onBabyArrivedClicked,
      isSlimBabyArrivedButton,
      disabled,
      isFirstBirthDateButton
    ])

    if (
      !isDesktop &&
      !supplementPayView &&
      !leaveTpaApprovalView &&
      !remainingBondingView
    ) {
      return null
    }

    const content = isDesktop ? (
      <React.Fragment>
        {leaveTpaApprovalView}
        {remainingBondingView}
        {birthDateButton}
        {buttons}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {supplementPayView}
        {leaveTpaApprovalView}
        {remainingBondingView}
      </React.Fragment>
    )

    return <Container className={className}>{content}</Container>
  },
  (prevProps: IProps, nextProps: IProps) => isEqual(prevProps, nextProps)
)

Info.displayName = 'TimelineInfo'

export default Info
