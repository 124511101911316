/* eslint-disable react/display-name */
import React from 'react'
import { IIconProps } from 'src/react-app-env'

export default (props: IIconProps) => {
  const { day, mobile } = props?.extra || {}
  switch (day) {
    case 'm':
      if (mobile) {
        return (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.49658 7.63281L5.21826 4.02344H3.87842V9H4.90381V7.63965L4.80127 5.29492L6.14111 9H6.84521L8.18848 5.2915L8.08594 7.63965V9H9.11475V4.02344H7.76807L6.49658 7.63281Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      } else {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.99561 9.24219L6.35205 4.60156H4.62939V11H5.94775V9.25098L5.81592 6.23633L7.53857 11H8.44385L10.1709 6.23193L10.0391 9.25098V11H11.3618V4.60156H9.63037L7.99561 9.24219Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      }

    case 't':
      if (mobile) {
        return (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.99902 4.854H8.52344V4.02344H4.46973V4.854H5.97363V9H6.99902V4.854Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      } else {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.6416 5.66943H10.6016V4.60156H5.38965V5.66943H7.32324V11H8.6416V5.66943Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      }

    case 'w':
      if (mobile) {
        return (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM8.42773 4.02344L7.75781 7.56445L6.94092 4.02344H6.07275L5.25244 7.55762L4.5791 4.02344H3.55713L4.66113 9H5.69336L6.50342 5.6709L7.31348 9H8.3457L9.44971 4.02344H8.42773Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      } else {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4785 4.60156L9.61719 9.1543L8.56689 4.60156H7.45068L6.396 9.14551L5.53027 4.60156H4.21631L5.63574 11H6.96289L8.00439 6.71973L9.0459 11H10.373L11.7925 4.60156H10.4785Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      }

    case 'f':
      if (mobile) {
        return (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.05225 6.96631H8.021V6.13916H6.05225V4.854H8.26709V4.02344H5.02686V9H6.05225V6.96631Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      } else {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.42432 8.38525H9.95557V7.32178H7.42432V5.66943H10.272V4.60156H6.10596V11H7.42432V8.38525Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      }

    case 's':
      if (mobile) {
        return (
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM7.1665 7.25C7.30322 7.35254 7.37158 7.50065 7.37158 7.69434C7.37158 7.87207 7.30322 8.01107 7.1665 8.11133C7.02979 8.20931 6.83952 8.2583 6.5957 8.2583C5.93945 8.2583 5.61133 7.98372 5.61133 7.43457H4.58252C4.58252 7.75586 4.66455 8.03841 4.82861 8.28223C4.99495 8.52604 5.23535 8.71859 5.5498 8.85986C5.86426 8.99886 6.21289 9.06836 6.5957 9.06836C7.14714 9.06836 7.58577 8.94645 7.91162 8.70264C8.23747 8.45654 8.40039 8.11816 8.40039 7.6875C8.40039 7.30013 8.26709 6.97656 8.00049 6.7168C7.73389 6.45703 7.30892 6.23942 6.72559 6.06396C6.40885 5.96826 6.16846 5.86572 6.00439 5.75635C5.84261 5.64697 5.76172 5.51139 5.76172 5.34961C5.76172 5.17643 5.83122 5.03744 5.97021 4.93262C6.10921 4.82552 6.30404 4.77197 6.55469 4.77197C6.81445 4.77197 7.01611 4.83577 7.15967 4.96338C7.30322 5.0887 7.375 5.2653 7.375 5.49316H8.40039C8.40039 5.19466 8.32292 4.92806 8.16797 4.69336C8.0153 4.45866 7.79997 4.27751 7.52197 4.1499C7.24398 4.02002 6.92725 3.95508 6.57178 3.95508C6.21859 3.95508 5.89958 4.01432 5.61475 4.13281C5.33219 4.2513 5.11458 4.4165 4.96191 4.62842C4.81152 4.83805 4.73633 5.07731 4.73633 5.34619C4.73633 5.86344 5.01888 6.27474 5.58398 6.58008C5.79134 6.69173 6.07275 6.80566 6.42822 6.92188C6.78369 7.03581 7.02979 7.14518 7.1665 7.25Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      } else {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.85693 8.75C9.03271 8.88184 9.12061 9.07227 9.12061 9.32129C9.12061 9.5498 9.03271 9.72852 8.85693 9.85742C8.68115 9.9834 8.43652 10.0464 8.12305 10.0464C7.2793 10.0464 6.85742 9.69336 6.85742 8.9873H5.53467C5.53467 9.40039 5.64014 9.76367 5.85107 10.0771C6.06494 10.3906 6.37402 10.6382 6.77832 10.8198C7.18262 10.9985 7.63086 11.0879 8.12305 11.0879C8.83203 11.0879 9.396 10.9312 9.81494 10.6177C10.2339 10.3013 10.4434 9.86621 10.4434 9.3125C10.4434 8.81445 10.272 8.39844 9.9292 8.06445C9.58643 7.73047 9.04004 7.45068 8.29004 7.2251C7.88281 7.10205 7.57373 6.97021 7.36279 6.82959C7.15479 6.68896 7.05078 6.51465 7.05078 6.30664C7.05078 6.08398 7.14014 5.90527 7.31885 5.77051C7.49756 5.63281 7.74805 5.56396 8.07031 5.56396C8.4043 5.56396 8.66357 5.646 8.84814 5.81006C9.03271 5.97119 9.125 6.19824 9.125 6.49121H10.4434C10.4434 6.10742 10.3438 5.76465 10.1445 5.46289C9.94824 5.16113 9.67139 4.92822 9.31396 4.76416C8.95654 4.59717 8.54932 4.51367 8.09229 4.51367C7.63818 4.51367 7.22803 4.58984 6.86182 4.74219C6.49854 4.89453 6.21875 5.10693 6.02246 5.37939C5.8291 5.64893 5.73242 5.95654 5.73242 6.30225C5.73242 6.96729 6.0957 7.49609 6.82227 7.88867C7.08887 8.03223 7.45068 8.17871 7.90771 8.32812C8.36475 8.47461 8.68115 8.61523 8.85693 8.75Z"
              fill="white"
              fillOpacity="0.4"
            />
          </svg>
        )
      }
  }
  throw new Error(`Disabled day icon '${day}' is not implemented`)
}
