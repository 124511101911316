import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

interface IProps {
  text: string
  className?: string
  marginTop?: number
  marginBottom?: number
}

const Container = styled.div<{ $marginTop: number; $marginBottom: number }>`
  width: 100%;
  height: auto;
  margin-top: ${props => props.$marginTop}px;
  margin-bottom: ${props => props.$marginBottom}px;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
`

const Text = styled(ReactMarkdown)`
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  em {
    font-style: italic;
  }
`

export const WidgetNoteText = React.memo((props: IProps) => {
  const { text, className, marginTop, marginBottom } = props

  return (
    <Container
      className={className}
      $marginTop={marginTop}
      $marginBottom={marginBottom}
    >
      <ContentContainer>
        <Text
          components={{
            a: (linkProps: any) => (
              <a
                href={linkProps.href}
                target={'_blank'}
                rel="noopener noreferrer"
                aria-label={linkProps.href}
              >
                {linkProps.children}
              </a>
            )
          }}
        >
          {text}
        </Text>
      </ContentContainer>
    </Container>
  )
})

WidgetNoteText.displayName = 'WidgetNoteText'

export default WidgetNoteText
