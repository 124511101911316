import React from 'react'
import styled, { css } from 'styled-components'
import { ITimelinePeriod } from 'src/react-app-env'
import DashedLine from 'src/features/Timeline/components/vertical/TimeView/components/DashedLine'
import Circle from 'src/features/Timeline/components/vertical/TimeView/components/Circle'
import { Icon } from 'src/UIKit'

export interface ITimelineTimeViewProps {
  className?: string
  period: ITimelinePeriod
}

const Container = styled.div`
  display: flex;
  width: 33px;
`

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 13px;
        `
      : css`
          width: 19px;
          margin: 0 0 0 6px;
        `}

  @media print {
    width: 13px;
    page-break-inside: avoid;
  }
`

export const EmptySpace = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          height: 30px;
        `
      : css`
          height: 28px;
        `}

  @media print {
    height: 30px;
  }
`

export const DashedLineFlex = styled(DashedLine)`
  flex: 1;
`

export const DashedLineNoIcon = styled(DashedLine)`
  ${props =>
    props.theme.isDesktop
      ? css`
          height: 30px;
        `
      : css`
          height: 28px;
        `}

  @media print {
    height: 30px;
  }
`

export const DashedLineIcon = styled(DashedLine)`
  ${props =>
    props.theme.isDesktop
      ? css`
          height: 26px;
        `
      : css`
          height: 22px;
        `}

  @media print {
    height: 26px;
  }
`

export const timelineTimeViewPropsAreEqual = (
  prev: ITimelineTimeViewProps,
  next: ITimelineTimeViewProps
): boolean => {
  if (next.className !== prev.className) {
    return false
  }

  return next.period === prev.period
}

export const dataAttrs = {
  dashedLine: () => 'dashed-line',
  dashedLineIcon: () => 'dashed-line-icon',
  dashedLineNoIcon: () => 'dashed-line-no-icon',
  empty: () => 'empty-space'
}

const TimeView = React.memo((props: ITimelineTimeViewProps) => {
  const { className, period } = props

  let content: any

  if (!period?.timelineConfig?.timeViewProps) {
    content = <DashedLineFlex data-testid={dataAttrs.dashedLine()} />
  } else {
    const { iconName, iconAriaLabel, isFirst, isLast, isCircleHidden, skip } =
      period.timelineConfig.timeViewProps
    content = skip || (isFirst && isLast) || (
      <React.Fragment>
        {isFirst && <EmptySpace data-testid={dataAttrs.empty()} />}
        {!isFirst && !iconName && (
          <DashedLineNoIcon data-testid={dataAttrs.dashedLineNoIcon()} />
        )}
        {iconName && !isFirst && (
          <DashedLineIcon data-testid={dataAttrs.dashedLineIcon()} />
        )}
        {iconName && <Icon name={iconName} ariaLabel={iconAriaLabel} />}
        {!iconName && !isCircleHidden && <Circle />}
        {!isLast && <DashedLineFlex data-testid={dataAttrs.dashedLine()} />}
      </React.Fragment>
    )
  }
  return (
    <Container className={className}>
      <LineContainer>{content}</LineContainer>
    </Container>
  )
}, timelineTimeViewPropsAreEqual)

TimeView.displayName = 'TimeView'

export default TimeView
