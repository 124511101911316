import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => (props.theme.isDesktop ? '4px' : '2px')};
  width: ${props => (props.theme.isDesktop ? 'auto' : 'calc(100% - 32px)')};
`

const Item = styled.div<{
  $isDone: boolean
  $isFirst: boolean
  $isLast: boolean
}>`
  height: ${props => (props.theme.isDesktop ? '4px' : '2px')};
  width: ${props => (props.theme.isDesktop ? '48px' : '100%')};
  background-color: ${props =>
    props.$isDone ? props.theme.colors.main100 : props.theme.colors.dark10};

  ${props =>
    props.$isFirst &&
    css`
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    `}

  ${props =>
    props.$isLast &&
    css`
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    `}
`

interface IProps {
  current: number
  total: number
}

export const ProgressBar = React.memo((props: IProps) => {
  const { current, total } = props
  const items = Array.from({ length: total })

  if (total <= 0 || current <= 0 || current > total) {
    return null
  }

  return (
    <Container>
      {items.map((_, index) => (
        <Item
          key={index}
          $isFirst={index === 0}
          $isLast={index === total - 1}
          $isDone={index < current}
        />
      ))}
    </Container>
  )
})

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
