import React from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { Icon, MarkdownText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { PrivacyItem } from '../..'

const { colors } = createTheme()

interface IProps {
  className?: string
  item: PrivacyItem
  onViewItem: (item: PrivacyItem) => void
  showsSeparator?: boolean
}

const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0 0;
`

const IconWrapper = styled(Icon)`
  display: flex;
  justify-content: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 24px;
        `
      : css`
          padding: 0 16px;
        `}
`

const Separator = styled.div<any>`
  height: 1px;
  background: ${props => props.theme.colors.main10};
  visibility: ${props => props.visibility};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 16px 24px 0;
        `
      : css`
          margin: 16px 0 0;
          width: 100%;
        `}
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(MarkdownText)`
  color: ${colors.dark60};
  line-height: 130%;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
          margin-right: 16px;
        `}
`

const ViewButton = styled.button`
  color: ${colors.main100};
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
    color: ${colors.main110};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin-right: 24px;
        `
      : css`
          font-size: 14px;
          margin-right: 16px;
        `}
`

export const dataAttrs = {
  title: () => 'privacy-list-item-title',
  viewBtn: () => 'privacy-list-item-view-btn'
}

const PrivacyListItem = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { className, showsSeparator, item, onViewItem } = props

  return (
    <Container className={className}>
      <ContentContainer>
        <IconWrapper name={'protected'} />
        <Title data-testid={dataAttrs.title()}>{item.title}</Title>
        <ViewButton
          data-testid={dataAttrs.viewBtn()}
          onClick={() => onViewItem(item)}
        >
          {t('common.view')}
        </ViewButton>
      </ContentContainer>
      <Separator visibility={showsSeparator ? 'visible' : 'hidden'} />
    </Container>
  )
})

PrivacyListItem.displayName = 'PrivacyListItem'

export default PrivacyListItem
