import AdditionalAdoption from './AdditionalAdoption'
import AdditionalMaternity from './AdditionalMaternity'
import AdditionalPersonal from './AdditionalPersonal'
import Annual from './Annual'
import EndDate from './EndDate'
import LongTermAbsence from './LongTermAbsence'
import Miscarriage from './Miscarriage'
import OrdinaryAdoption from './OrdinaryAdoption'
import OrdinaryMaternity from './OrdinaryMaternity'
import Partner from './Partner'
import Personal from './Personal'
import PregnancyLossDate from './PregnancyLossDate'
import PublicHolidays from './PublicHolidays'
import Sickness from './Sickness'
import StartDate from './StartDate'
import StatutoryParents from './StatutoryParents'
import UnpaidParental from './UnpaidParental'

export default {
  AdditionalAdoption,
  AdditionalMaternity,
  AdditionalPersonal,
  Annual,
  EndDate,
  LongTermAbsence,
  Miscarriage,
  OrdinaryAdoption,
  OrdinaryMaternity,
  Partner,
  Personal,
  PregnancyLossDate,
  PublicHolidays,
  Sickness,
  StartDate,
  StatutoryParents,
  UnpaidParental
}
