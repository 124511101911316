import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import { ITimelinePeriodConfig, IGetPeriodConfigInput } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  periodPickerSelectedNotice: {
    show: true,
    notShowUnused: input.period?.schedule?.maximumAllowed === 0
  },
  periodPickerShowWorkdays: true,
  periodPickerBlankStartDate: baseConfig.timeViewProps.isFrontAddable,
  periodPickerShowBalance: input.leave.type !== 'Miscarriage',
  datePickerComponent: MultiDatePicker,
  datePickerFooterInfoMessage: (t: any) =>
    t('timeline.datePickerAlerts.rememberNotice.text'),
  isMaximumAllowed:
    input.period?.schedule?.maximumAllowed > 0 &&
    input.period?.schedule?.usedInPeriod >
      input.period?.schedule?.maximumAllowed
})
