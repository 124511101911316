import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import BubblesOverlay from 'src/components/BubblesOvelay'
import { isSafari } from 'src/utils/deviceTypes'
import { IBubbleProps } from 'src/components/BubblesOvelay/components/Bubble'
import { useTranslation } from 'react-i18next'
import {
  DATE_PICKER_CALENDAR_DATE_CHANGED,
  DATE_PICKER_CALENDAR_HIDDEN,
  DATE_PICKER_CALENDAR_SHOWN,
  SUBTYPE_SELECTOR_TOGGLED
} from 'src/constants/events'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { isNewLeave } from 'src/utils/leaveStatusUtils'
import ScreenContext from 'src/contexts/ScreenContext'
import InductionTrip from './components/InductionTrip'
import SharedContext from 'src/contexts/SharedContext'
import { hasAddableBabyBonding } from 'src/utils/leaveUtils'

type TutorialStage =
  | 'subtype'
  | 'leaveStart'
  | 'leaveEnd'
  | 'leaveDurationPicker'
  | 'startJourney'
  | 'details'
  | 'vacationCalculator'

const TimelineTutorial = React.memo(() => {
  const { t } = useTranslation()
  const timelineContext: ITimelineContext = useContext(TimelineContext)
  const { leave, countryCode, isLeaveDurationPickerOpened, isDetailsOpened } =
    timelineContext
  const { customerConfig } = useContext(SharedContext)
  const { isMobile } = useContext(ScreenContext)

  const stages: TutorialStage[] = useMemo(() => {
    switch (leave.type) {
      case 'Medical':
      case 'Military':
      case 'Sickness':
      case 'Personal':
      case 'Family':
        return countryCode === 'US'
          ? ['leaveDurationPicker', 'details', 'startJourney']
          : ['leaveStart', 'details', 'startJourney']
      case 'Miscarriage':
        return leave.subtype === 'LossBefore24Weeks'
          ? ['leaveStart', 'details', 'startJourney']
          : ['leaveDurationPicker', 'details', 'startJourney']
      case 'PartnerMiscarriage':
        return ['leaveDurationPicker', 'details', 'startJourney']
      default:
        if (countryCode === 'IN') {
          return ['leaveStart', 'details', 'startJourney']
        }

        if (
          customerConfig.leave.tutorial.useOtherTutorialSteps &&
          hasAddableBabyBonding(leave)
        ) {
          return [
            'leaveStart',
            'leaveDurationPicker',
            'details',
            'startJourney'
          ]
        }

        if (customerConfig.leave.tutorial.hasVacationStep(leave)) {
          return [
            'subtype',
            'leaveStart',
            'details',
            'vacationCalculator',
            'startJourney'
          ]
        }

        return leave.subtype?.length !== 0
          ? ['subtype', 'leaveStart', 'details', 'startJourney']
          : ['leaveStart', 'details', 'startJourney']
    }
  }, [leave, countryCode, customerConfig])

  const forceUpdate = useForceUpdate()
  const [currentStage, setCurrentStage] = useState(stages[0])
  const [selectDateMode, setSelectDateMode] = useState(false)
  const [startTour, setStartTour] = useState(false)
  const requestAnimationFrameRef = React.useRef(0)

  const {
    subtypeSelectorElement,
    leaveStartElement,
    leaveEndElement,
    leaveDurationPickerElement,
    startJourneyElement,
    detailsElement,
    timelineViewElement,
    vacationCalculatorElement
  }: any = TutorialElementsRegister.getInstance()

  const getTargetElement = useCallback(() => {
    switch (currentStage) {
      case 'subtype':
        return subtypeSelectorElement
      case 'leaveStart':
        return leaveStartElement
      case 'leaveEnd':
        return leaveEndElement
      case 'leaveDurationPicker':
        return leaveDurationPickerElement
      case 'startJourney':
        return startJourneyElement
      case 'details':
        return detailsElement
      case 'vacationCalculator':
        return vacationCalculatorElement
      default:
        return null
    }
  }, [
    currentStage,
    subtypeSelectorElement,
    leaveStartElement,
    leaveEndElement,
    leaveDurationPickerElement,
    startJourneyElement,
    detailsElement,
    vacationCalculatorElement
  ])

  useEffect(() => {
    try {
      const element: any = getTargetElement()
      if (element && isNewLeave(leave.status)) {
        element.scrollIntoView({
          block: 'center',
          behavior: isSafari() ? 'auto' : 'smooth'
        })

        let same = 0
        let lastPos = 0
        const check = () => {
          if (element) {
            const newPos = element.getBoundingClientRect().top
            forceUpdate()
            if (newPos === lastPos) {
              if (same++ > 2) {
                return
              }
            } else {
              same = 0
              lastPos = newPos
            }
            requestAnimationFrameRef.current = requestAnimationFrame(check)
          }
        }
        requestAnimationFrameRef.current = requestAnimationFrame(check)
      }
    } catch (_) {
      return () => cancelAnimationFrame(requestAnimationFrameRef.current)
    }
    return () => cancelAnimationFrame(requestAnimationFrameRef.current)
  }, [
    forceUpdate,
    getTargetElement,
    currentStage,
    subtypeSelectorElement,
    leaveStartElement,
    leaveEndElement,
    leaveDurationPickerElement,
    startJourneyElement,
    detailsElement,
    timelineViewElement,
    leave.status
  ])

  const onNextClick = useCallback(() => {
    const index: number = Math.min(
      stages.indexOf(currentStage) + 1,
      stages.length - 1
    )
    setCurrentStage(stages[index])
  }, [stages, currentStage, setCurrentStage])

  const scrollTimelineToTop = () => {
    if (timelineViewElement) {
      timelineViewElement.scrollTop = 0
    }
  }

  const onPrevClick = () => {
    const index: number = Math.max(stages.indexOf(currentStage) - 1, 0)
    setCurrentStage(stages[index])
  }

  const onSkipClick = async () => {
    await timelineContext.onTutorialCompleted()
    scrollTimelineToTop()
  }

  const onCompleteClick = async () => {
    await timelineContext.onTutorialCompleted()
    scrollTimelineToTop()
  }

  useEffect(() => {
    const selectDateModeOn = () => {
      setSelectDateMode(true)
    }

    const selectDateModeOff = () => {
      setSelectDateMode(false)
    }

    const onDateChanged = () => {
      onNextClick()
    }

    const onSubtypeToggled = () => {
      window.setTimeout(onNextClick, 400)
    }

    window.addEventListener(DATE_PICKER_CALENDAR_SHOWN, selectDateModeOn)
    window.addEventListener(DATE_PICKER_CALENDAR_HIDDEN, selectDateModeOff)
    window.addEventListener(DATE_PICKER_CALENDAR_DATE_CHANGED, onDateChanged)
    window.addEventListener(SUBTYPE_SELECTOR_TOGGLED, onSubtypeToggled)

    return () => {
      window.removeEventListener(DATE_PICKER_CALENDAR_SHOWN, selectDateModeOn)
      window.removeEventListener(DATE_PICKER_CALENDAR_HIDDEN, selectDateModeOff)
      window.removeEventListener(
        DATE_PICKER_CALENDAR_DATE_CHANGED,
        onDateChanged
      )
      window.removeEventListener(SUBTYPE_SELECTOR_TOGGLED, onSubtypeToggled)
    }
  }, [onNextClick])

  useEffect(() => {
    if (!isNewLeave(leave.status)) {
      setCurrentStage(stages[0])
    }
  }, [stages, leave])

  if (isMobile) {
    return null
  }

  if (!isNewLeave(leave.status)) {
    return null
  }

  if (isLeaveDurationPickerOpened || isDetailsOpened) {
    return null
  }

  const getBubbleTitle = () => {
    if (currentStage === 'subtype') {
      return t(`timelineTutorial.subtypeTitleByLeaveType.${leave.type}`)
    } else {
      return t(`timelineTutorial.titles.${currentStage}`)
    }
  }

  const getBubbleBody = () =>
    t([
      `timelineTutorial.bodiesByLeaveType.${leave.type}.${currentStage}`,
      `timelineTutorial.bodiesByLeaveType.${currentStage}`
    ])

  const currentNumber = stages.indexOf(currentStage) + 1
  const bubbleProps: IBubbleProps = {
    title: getBubbleTitle(),
    body: getBubbleBody(),
    currentNumber,
    totalNumbers: stages.length,
    onCompleteClick,
    onSkipClick,
    onPrevClick,
    onNextClick
  }

  const getAreProps = () => {
    switch (currentStage) {
      case 'subtype':
        return { areaAddedWidth: 16, areaAddedHeight: 16, areaBorderRadius: 20 }
      case 'leaveStart':
        return { areaAddedWidth: 24, areaAddedHeight: 1, areaBorderRadius: 22 }
      case 'vacationCalculator':
      case 'startJourney':
        return { areaAddedWidth: 16, areaAddedHeight: 16, areaBorderRadius: 20 }
      case 'details':
        return { areaAddedWidth: 24, areaAddedHeight: 1, areaBorderRadius: 22 }
      default:
        return {}
    }
  }

  return !startTour ? (
    <InductionTrip onSkipClick={onSkipClick} onStartTour={setStartTour} />
  ) : (
    <BubblesOverlay
      targetElement={getTargetElement()}
      bubbleProps={bubbleProps}
      hidesBubble={selectDateMode}
      {...getAreProps()}
    />
  )
})

TimelineTutorial.displayName = 'TimelineTutorial'

export class TutorialElementsRegister {
  public static getInstance(): TutorialElementsRegister {
    if (!TutorialElementsRegister.instance) {
      TutorialElementsRegister.instance = new TutorialElementsRegister()
    }

    return TutorialElementsRegister.instance
  }

  private static instance: TutorialElementsRegister
  public subtypeSelectorElement: any
  public leaveStartElement: any
  public leaveEndElement: any
  public startJourneyElement: any
  public detailsElement: any
  public timelineViewElement: any
  public leaveDurationPickerElement: any
  public vacationCalculatorElement: any
}

TimelineTutorial.displayName = 'TimelineTutorial'

export default TimelineTutorial
