import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  toggled: boolean
  disabled?: boolean
  onClick?: () => void
  ariaLabel?: string
}

const Container = styled.button<{ $toggled: boolean; $disabled: boolean }>`
  border-radius: 32px;
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  transition: background 100ms ease-in;
  ${props => {
    const {
      $toggled,
      $disabled,
      theme: { colors }
    } = props
    const color: string = $toggled
      ? $disabled
        ? colors.main40
        : colors.main100
      : $disabled
        ? colors.dark10
        : colors.dark60
    return css`
      background: ${color};
      cursor: ${$disabled ? 'unset' : 'pointer'};
    `
  }}

  &:focus {
    outline: none;
    box-shadow: ${props =>
      `0 0 0 2px ${
        props.$toggled ? props.theme.colors.main110 : props.theme.colors.dark80
      }`};
  }
`

const Pusher = styled.div<{ $toggled: boolean }>`
  height: 10px;
  transition: width 100ms ease-in;
  ${props =>
    props.$toggled
      ? css`
          width: 18px;
        `
      : css`
          width: 2px;
        `}
`

const Circle = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => props.theme.colors.light100};
  border-radius: 50%;
`

export const dataAttrs = {
  toggle: () => `toggle-pusher`
}

const Toggle = React.memo((props: IProps) => {
  const { className, onClick, toggled, disabled, ariaLabel } = props

  const onClickCallback = useCallback(() => {
    if (!disabled && onClick) {
      onClick()
    }
  }, [disabled, onClick])

  return (
    <Container
      data-testid={dataAttrs.toggle()}
      className={className}
      onClick={onClickCallback}
      $toggled={toggled}
      $disabled={disabled}
      disabled={disabled}
      aria-label={ariaLabel}
      role={'switch'}
      aria-checked={toggled}
    >
      <Pusher $toggled={toggled} />
      <Circle />
    </Container>
  )
})

Toggle.displayName = 'Toggle'

export default Toggle
