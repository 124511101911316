import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect,
  useState
} from 'react'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { useTranslation } from 'react-i18next'
import Buttons from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Buttons'
import {
  AlertsContainer,
  DateSelectors,
  StyledDatePicker
} from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Datepickers'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import DescriptionText from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import {
  IDialogData,
  ITimelinePeriod,
  IWithDialogManager
} from 'src/react-app-env'
import {
  calculateDuration,
  getChangesForPeriodIntermittent,
  getExtraParameters,
  getPeriodDuration
} from '../methods'
import { ContentContainer } from '../components/ContentContainer'
import { Moment } from 'moment'
import {
  areSameDates,
  convertToMomentArray,
  isBlankDate
} from 'src/utils/dateUtils'
import VacationWidget from '../components/VacationWidget'
import styled, { css } from 'styled-components'
import InfoMessageView from '../components/InfoMessageView'
import SelectedMessage from '../components/SelectedMessage'
import { getDurationItem } from 'src/utils/leaveUtils'
import DatePickerAlert from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Alert'
import ApprovedByTpaView from '../components/ApprovedByTpaView'
import RemoveDialogContent from '../components/RemoveDialogContent'
import { withDialogManager } from 'src/components/DialogManager'

interface IProps extends IWithDialogManager {
  className?: string
  y?: number
  alignsToTop?: boolean
  onExit: () => void
  period: ITimelinePeriod
}

const infoMessageMixin = css`
  width: 100%;
  margin-top: 20px;
`

const InfoMessageWrapper = styled.div`
  ${infoMessageMixin}
  margin: 20.5px 0 4.5px;
`

const AdjustedMessageWrapper = styled.div`
  ${infoMessageMixin}
`

const MaximumAllowedMessageWrapper = styled.div`
  ${infoMessageMixin}
`

const TitleWrapper = styled(Title)`
  margin-bottom: 20px;
`

const MultiDatePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  let { period } = props
  const { onExit, dialogManager } = props
  const { t } = useTranslation()
  const {
    onNewChanges,
    timelinePeriods,
    onCancelChanges,
    leave,
    leaveHolidays,
    onCalendarActiveDateChanged
  } = context

  period =
    timelinePeriods.find((p: ITimelinePeriod) => p.hash === period.hash) ||
    period

  const { periodStart, appearance } = period
  const [wasAddable] = useState(appearance === 'Addable')
  const periodStartCurrent =
    period.appearance === 'Addable' ? periodStart.min : periodStart.current
  const alerts: string[] = Array.from(period.timelineConfig.alerts)
  const isRemovable = period.isRemovable
  const addableBlankStartDate =
    period.timelineConfig?.periodPickerBlankStartDate

  const [activeStartDate, setActiveStartDate] = useState(undefined)
  const [activeEndDate, setActiveEndDate] = useState(undefined)
  const [isDurationApproved, setIsDurationApproved] = useState<boolean>(false)
  const [initialPeriod] = useState(period)
  const { parent } = initialPeriod
  const balance = leave.metadata?.PTOBalance
  const [previousBalance] = useState(balance)

  const startDateRef = useRef(null)
  const endDateRef = useRef(null)

  if (initialPeriod.refID) {
    period.refID = initialPeriod.refID
  }

  const startDateMinMax = useMemo(
    () =>
      isBlankDate(period.periodStart.current)
        ? {
            ...period.periodStart,
            current: null
          }
        : period.periodStart,
    [period]
  )

  const endDateMinMax = useMemo(
    () =>
      isBlankDate(period.periodEnd.current)
        ? {
            ...period.periodEnd,
            current: null
          }
        : period.periodEnd,
    [period]
  )

  useEffect(() => {
    if (period.appearance === 'Addable' && !addableBlankStartDate) {
      const intermittentBlockChanges = {
        startDate: period.periodStart.min
      }
      const changes: any = getChangesForPeriodIntermittent({
        period: { ...period, parent },
        intermittentBlockChanges
      })
      onNewChanges(changes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setActiveStartDate(
      startDateMinMax.current
        ? startDateMinMax.current.utc().toDate()
        : startDateMinMax.max.utc().toDate()
    )
  }, [startDateMinMax])

  const removeDialogView: IDialogData = useMemo(
    () => ({
      title: t('timeline.removeDialog.title'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('timeline.removeDialog.yes'),
          onClick: () => {
            onExit()
            const intermittentBlockChanges = { duration: 0 }
            const extra = getExtraParameters({
              period,
              isDurationApproved
            })
            const changes: any = getChangesForPeriodIntermittent({
              period,
              intermittentBlockChanges,
              extra
            })
            onNewChanges(changes, true)
          },
          order: 2
        }
      ],
      children: (
        <RemoveDialogContent
          periodType={period.type}
          isDurationApproved={isDurationApproved}
          setIsDurationApproved={setIsDurationApproved}
        />
      )
    }),
    [isDurationApproved, onExit, onNewChanges, period, t]
  )

  useEffect(() => {
    if (dialogManager?.opened) {
      dialogManager.update(removeDialogView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDurationApproved])

  const onVacationBalanceChanged = useCallback(
    (vacationBalance: number): void => {
      const intermittentBlockChanges = {
        startDate: periodStartCurrent,
        duration: getPeriodDuration(period),
        isNew: wasAddable
      }
      const extra = { PTOBalance: vacationBalance }
      const changes: any = getChangesForPeriodIntermittent({
        period: { ...period, parent },
        intermittentBlockChanges,
        extra
      })
      onNewChanges({ ...changes })
    },
    [onNewChanges, period, periodStartCurrent, wasAddable, parent]
  )

  const onStartDateChanged = useCallback(
    (date: Moment): void => {
      const intermittentBlockChanges = {
        startDate: date,
        duration: getPeriodDuration(period),
        isNew: wasAddable
      }
      const extra = getExtraParameters({
        period,
        balance
      })
      const changes: any = getChangesForPeriodIntermittent({
        period: { ...period, parent },
        intermittentBlockChanges,
        extra
      })

      onNewChanges(changes)
    },
    [balance, onNewChanges, period, wasAddable, parent]
  )

  const onEndDateChanged = useCallback(
    (date: Moment): void => {
      const intermittentBlockChanges = {
        startDate: periodStartCurrent,
        duration: calculateDuration(period, 'periodEnd', date),
        isNew: wasAddable
      }
      const extra = getExtraParameters({
        period,
        balance
      })
      const changes: any = getChangesForPeriodIntermittent({
        period: { ...period, parent },
        intermittentBlockChanges,
        extra
      })
      onNewChanges(changes)
    },
    [parent, periodStartCurrent, period, wasAddable, onNewChanges, balance]
  )

  const disabledDays: Moment[] = useMemo(
    () => convertToMomentArray(period.disabledDates?.dates),
    [period.disabledDates]
  )

  const startDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        holidays={leaveHolidays}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        calendarRef={startDateRef}
        title={t('timeline.periodPicker.startDate')}
        momentCurrentMinMax={startDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onStartDateChanged}
        activeStartDate={activeStartDate || startDateMinMax.max.utc().toDate()}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
          return endDateRef.current.isOpen && endDateRef.current.toggle()
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveStartDate(action.activeStartDate)
        }}
        disabledDays={disabledDays}
        handleBottomOverlap={
          period.timelineConfig?.handleDatePickerBottomOverlap
        }
      />
    ),
    [
      t,
      period,
      leaveHolidays,
      onCalendarActiveDateChanged,
      startDateMinMax,
      activeStartDate,
      setActiveStartDate,
      onStartDateChanged,
      startDateRef,
      endDateRef,
      disabledDays
    ]
  )

  const endDatePicker: ReactNode = useMemo(
    () => (
      <StyledDatePicker
        stickRight
        holidays={leaveHolidays}
        $margin={period.timelineConfig?.datePickerDescription ? null : '0'}
        calendarRef={endDateRef}
        title={t('timeline.periodPicker.endDate')}
        disabled={!startDateMinMax.current}
        momentCurrentMinMax={endDateMinMax}
        placeholder={t('common.selectDate')}
        onDateChanged={onEndDateChanged}
        activeStartDate={activeEndDate}
        onOpened={(date: any) => {
          onCalendarActiveDateChanged(date)
          return startDateRef.current.isOpen && startDateRef.current.toggle()
        }}
        onActiveStartDateChange={(action: any) => {
          onCalendarActiveDateChanged(action.activeStartDate)
          setActiveEndDate(action.activeStartDate)
        }}
        handleBottomOverlap={
          period.timelineConfig?.handleDatePickerBottomOverlap
        }
      />
    ),
    [
      t,
      period,
      leaveHolidays,
      onCalendarActiveDateChanged,
      activeEndDate,
      startDateMinMax,
      endDateMinMax,
      setActiveEndDate,
      onEndDateChanged,
      startDateRef,
      endDateRef
    ]
  )

  const hasChanges: boolean = useMemo(() => {
    const samePeriodStart = areSameDates(
      initialPeriod.periodStart.current,
      period.periodStart.current
    )
    const samePeriodEnd = areSameDates(
      initialPeriod.periodEnd.current,
      period.periodEnd.current
    )
    const sameBalance = balance === previousBalance
    return (
      !samePeriodStart ||
      !samePeriodEnd ||
      !sameBalance ||
      (initialPeriod === period && period.isDirty)
    )
  }, [period, initialPeriod, previousBalance, balance])

  const onCancelClicked = useCallback(() => {
    onExit()
    onCancelChanges()
  }, [onExit, onCancelChanges])

  const onConfirm = useCallback(() => {
    onExit()
    const intermittentBlockChanges = {
      startDate: periodStartCurrent,
      duration: getPeriodDuration(period),
      isNew: wasAddable
    }

    const extra = getExtraParameters({
      period,
      balance,
      isDurationApproved
    })

    const changes: any = getChangesForPeriodIntermittent({
      period: { ...period, parent },
      intermittentBlockChanges,
      extra
    })
    onNewChanges(changes, true)
  }, [
    onExit,
    periodStartCurrent,
    parent,
    period,
    wasAddable,
    onNewChanges,
    balance,
    isDurationApproved
  ])

  const onRemove = useCallback(() => {
    if (period.timelineConfig.useApprovedByTpa) {
      dialogManager.add(removeDialogView)
    } else {
      onExit()
      const intermittentBlockChanges = { duration: 0 }
      const changes: any = getChangesForPeriodIntermittent({
        period,
        intermittentBlockChanges
      })
      onNewChanges(changes, true)
    }
  }, [onExit, period, onNewChanges, dialogManager, removeDialogView])

  const selectedPeriodView: ReactNode = useMemo(
    () =>
      period.timelineConfig?.periodPickerSelectedNotice.show &&
      period?.schedule && (
        <SelectedMessage
          used={period.schedule.usedInPeriod}
          unused={period.schedule.totalUnused}
          usedCalendarDays={period.schedule?.usedInPeriodCalendarDays}
          unusedCalendarDays={period.schedule?.totalUnusedCalendarDays}
          externalDurationType={period.schedule.durationType}
          showDoubleText={
            period.timelineConfig?.periodPickerSelectedNotice.showDoubleText
          }
          notShowUnused={
            period.timelineConfig?.periodPickerSelectedNotice.notShowUnused
          }
        />
      ),
    [period]
  )

  const vacationWidgetView: ReactNode = useMemo(
    () =>
      period.timelineConfig.periodPickerShowBalance && (
        <VacationWidget
          period={period}
          metadata={leave.metadata}
          onVacationBalanceChanged={onVacationBalanceChanged}
        />
      ),
    [leave, onVacationBalanceChanged, period]
  )

  const adjustedMessageView: ReactNode = useMemo(
    () =>
      period.isAdjusted && (
        <AdjustedMessageWrapper>
          <InfoMessageView message={t('timeline.periodPicker.adjusted')} />
        </AdjustedMessageWrapper>
      ),
    [t, period]
  )

  const maximumAllowedMessageView: ReactNode = useMemo(() => {
    if (!period.timelineConfig.isMaximumAllowed || !period.schedule) {
      return null
    }

    const durationItem = getDurationItem(period.schedule?.durationType)
    const duration = t(`common.durations.${durationItem}`, {
      count: period.schedule.maximumAllowed
    })

    return (
      <MaximumAllowedMessageWrapper>
        <InfoMessageView
          message={t('timeline.periodPicker.maximumAllowed', {
            days: period.schedule.maximumAllowed,
            duration
          })}
        />
      </MaximumAllowedMessageWrapper>
    )
  }, [t, period])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = period.timelineConfig.datePickerFooterInfoMessage(t, leave)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [period.timelineConfig, t, leave])

  const alertsView: ReactNode = useMemo(() => {
    if (alerts.length === 0) {
      return null
    }

    return (
      <AlertsContainer>
        {alerts.map((key: string) => (
          <DatePickerAlert
            key={key}
            alertTranslationKey={key}
            metadata={leave.metadata}
          />
        ))}
      </AlertsContainer>
    )
  }, [alerts, leave.metadata])

  const approvedByTpaView: ReactNode = useMemo(
    () =>
      period.timelineConfig.useApprovedByTpa && (
        <ApprovedByTpaView
          approved={isDurationApproved}
          hasChanges={hasChanges}
          onClick={() => {
            setIsDurationApproved(!isDurationApproved)
          }}
        />
      ),
    [isDurationApproved, setIsDurationApproved, hasChanges, period]
  )

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={hasChanges}
        isConfirmDisabled={false}
        onCancel={onCancelClicked}
        onClose={onExit}
        onConfirm={onConfirm}
        onRemove={isRemovable && !hasChanges ? onRemove : null}
      />
    ),
    [hasChanges, onCancelClicked, onExit, onConfirm, isRemovable, onRemove]
  )

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.dates', {
        periodName: period.timelineConfig.periodName
      }),
    [t, period]
  )

  return (
    <ContainerView {...props}>
      <ContentContainer>
        {alertsView}
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionText>
          {period.timelineConfig?.datePickerDescription}
        </DescriptionText>
        <DateSelectors>
          {startDatePicker}
          {endDatePicker}
        </DateSelectors>
        {adjustedMessageView}
        {selectedPeriodView}
        {maximumAllowedMessageView}
        {vacationWidgetView}
        {infoMessageView}
        {approvedByTpaView}
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

MultiDatePicker.displayName = 'MultiDatePicker'

export default withDialogManager(MultiDatePicker)
