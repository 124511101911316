import React, { useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ToggleRow from 'src/components/ToggleRow'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'
import { Icon } from 'src/UIKit'
import DashboardContext from 'src/features/ManagerJourneyMap/DashboardContext'
import useHovered from 'src/components/hooks/useHovered'
import createTheme from 'src/theme'
import useFocused from 'src/components/hooks/useFocused'

const { colors } = createTheme()

interface IProps {
  className?: string
  onArchiveClick: () => void
}

const Container = styled.div`
  width: 275px;
  padding: 8px 0;

  ${props =>
    props.theme.isMobile &&
    css`
      border: 1px solid ${p => p.theme.colors.dark50};
      box-shadow: 0 0 10px rgb(0 0 0 / 5%);
      border-radius: 6px;
    `}
`

const ArchivePlanButton = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  font-size: ${props => (props.theme.isMobile ? '16px' : '17px')};
  line-height: 100%;
  color: ${props =>
    props.$active ? props.theme.colors.light100 : props.theme.colors.dark60};

  ${props =>
    props.theme.isMobile &&
    css`
      color: ${props.theme.colors.dark80};
    `}
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 10px;
`

const ArchiveRow = styled(Row)`
  margin: 0;
  padding: 0 10px;
  outline-offset: 0 !important;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.main100};
    color: ${props => props.theme.colors.light100};
  }
`

const IconWrapper = styled(Icon)`
  margin-right: 14px;
  margin-top: 3px;
`

const ToggleRowWrapper = styled(ToggleRow)`
  span {
    margin-right: 36px;
    font-size: ${props => (props.theme.isMobile ? '16px' : '17px')};
    ${props =>
      props.theme.isMobile &&
      css`
        color: ${props.theme.colors.dark80};
      `}
  }
`

export const dataAttrs = {
  toggleEmailNotifications: () => 'manager-toggle-email-notifications',
  archivePlan: () => 'manager-archive-plan'
}

export const PlanSettingsButtonPopoverContent = React.memo((props: IProps) => {
  const { dashboardAbsence, toggleEmailNotifications, archivePlan } =
    useContext(DashboardContext)
  const { className } = props
  const { t } = useTranslation()
  const archiveButtonRef: any = useRef(null)
  useKeyActionHandler(archiveButtonRef.current, archivePlan)
  const archiveButtonHovered: boolean = useHovered(archiveButtonRef)
  const archiveButtonFocused: boolean = useFocused(archiveButtonRef)

  const archiveActive = archiveButtonFocused || archiveButtonHovered

  const emailNotificationsOn = dashboardAbsence.emailNotifications

  return (
    <Container className={className} role={'region'}>
      <Row>
        <IconWrapper name={'mail_at'} />
        <ToggleRowWrapper
          data-testid={dataAttrs.toggleEmailNotifications()}
          onClick={() => toggleEmailNotifications(dashboardAbsence)}
          toggled={emailNotificationsOn}
          text={t('managerJourneyMap.emailNotifications')}
          ariaLabel={t('managerJourneyMap.emailNotifications')}
        />
      </Row>
      <ArchiveRow
        ref={archiveButtonRef}
        data-testid={dataAttrs.archivePlan()}
        onClick={() => {
          archivePlan(dashboardAbsence)
          props.onArchiveClick()
        }}
        role={'button'}
        tabIndex={0}
      >
        <IconWrapper
          name={'catalog'}
          settings={{
            fill: archiveActive ? colors.light100 : colors.main100
          }}
        />
        <ArchivePlanButton $active={archiveActive}>
          {t('managerJourneyMap.archivePlan')}
        </ArchivePlanButton>
      </ArchiveRow>
    </Container>
  )
})

PlanSettingsButtonPopoverContent.displayName =
  'PlanSettingsButtonPopoverContent'

export default PlanSettingsButtonPopoverContent
