import React from 'react'
import { DropdownWithSearch } from 'src/UIKit'
import styled from 'styled-components'
import { IOption } from 'src/react-app-env'

interface IProps {
  className?: string
  title: string
  placeholder?: string
  selectedOption?: IOption
  options: IOption[]
  onOptionChange: (option: IOption) => void
  disabledOptionIndexes?: number[]
}

const Container = styled.div`
  width: 100%;
`

export const OptionSelector = React.memo((props: IProps) => {
  const {
    className,
    title,
    placeholder,
    selectedOption,
    options,
    disabledOptionIndexes,
    onOptionChange
  } = props

  return (
    <Container className={className}>
      <DropdownWithSearch
        title={title}
        options={options.map(o => o.value)}
        selected={selectedOption?.value || ''}
        placeholder={placeholder}
        onChange={(str: string) => {
          const option: IOption = options.find((o: IOption) => o.value === str)
          onOptionChange(option)
        }}
        disabledOptionIndexes={disabledOptionIndexes}
      />
    </Container>
  )
})

OptionSelector.displayName = 'OptionSelector'

export default OptionSelector
