import React from 'react'
import styled from 'styled-components'
import bgImage from 'src/assets/images/video-preview-bg.jpg'

interface IProps {
  title: string
  onClick?: () => void
}

const playButtonSvg = (
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54 29C54 42.8071 42.8071 54 29 54C15.1929 54 4 42.8071 4 29C4 15.1929 15.1929 4 29 4C42.8071 4 54 15.1929 54 29ZM58 29C58 45.0163 45.0163 58 29 58C12.9837 58 0 45.0163 0 29C0 12.9837 12.9837 0 29 0C45.0163 0 58 12.9837 58 29ZM42 29L23 41V17L42 29Z"
      fill="#8EBFFA"
    />
  </svg>
)

const Container = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(${bgImage});
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
`

const BlurContainer = styled.div`
  position: relative;
  backdrop-filter: blur(7px);
  height: 100%;
  background: rgb(255 255 255 / 80%);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`

const TitleContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.dark100};
  opacity: 0.3;
  border-radius: 8px 8px 0 0;
`

const Title = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.light100};
  margin: 8px 10px;
`

const VideoMobileOverlayView = React.memo((props: IProps) => {
  const { onClick, title } = props

  return (
    <Container onClick={onClick}>
      <BlurContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <ButtonContainer>{playButtonSvg}</ButtonContainer>
      </BlurContainer>
    </Container>
  )
})

VideoMobileOverlayView.displayName = 'VideoMobileOverlayView'

export default VideoMobileOverlayView
