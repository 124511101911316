import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import useServiceWorker from 'src/components/hooks/useServiceWorker'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import zIndex from 'src/constants/zIndex'
import { updateAppPrompt } from 'src/constants/frame'
import createTheme from 'src/theme'

const { colors } = createTheme()

const Container = styled.div`
  z-index: ${zIndex.updatePrompt};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.specialGreenHigh};
  min-height: ${p => updateAppPrompt.height(p.theme).px};
  display: flex;
  align-items: center;
`

const IconWrapper = styled(Icon)`
  margin-left: ${props => (props.theme.isDesktop ? '20px' : '10px')};
`

const TextsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 0 10px;
        `}
`

const textMixin = css`
  color: ${props => props.theme.colors.light100};
`

const TextTop = styled.span`
  ${textMixin};
  font-size: ${props => (props.theme.isDesktop ? '16px' : '12px')};
  line-height: ${props => (props.theme.isDesktop ? '20.8px' : '12px')};
  font-weight: 500;
`

const TextBottom = styled.span`
  ${textMixin}
  font-size: ${props => (props.theme.isDesktop ? '14px' : '11px')};
  line-height: ${props => (props.theme.isDesktop ? '18.2px' : '14.3px')};
`

const RefreshButton = styled(Button)`
  transition: all 300ms;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.light100};
  color: ${props => props.theme.colors.light100};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.light100};
    border: none;
    color: ${props => props.theme.colors.specialGreenHigh};
    box-shadow: none;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 180px;
          font-size: 16px;
        `
      : css`
          width: 113px;
          height: 32px;
          font-size: 11px;
          padding: 0 8px;
        `}
`

const CloseButton = styled(Icon)`
  cursor: pointer;
  transition: transform 300ms;
  margin: ${props => (props.theme.isDesktop ? '0 20px 0 16px' : '0 10px')};
  padding: ${props => (props.theme.isDesktop ? '10.4px' : '7.8px')};

  &:hover {
    transform: scale(1.2);
  }
`

export const dataAttrs = {
  title: () => 'update-prompt-title'
}

const UpdateAppPromptView = React.memo(() => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const { t } = useTranslation()
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const userResult: IUseUser = useUser()

  if (isClosed || !isUpdateAvailable) {
    return null
  }

  if (!userResult.user) {
    return null
  }

  return (
    <Container>
      <IconWrapper
        name={'update'}
        settings={{ fill: colors.light100, width: '24px', height: '24px' }}
      />
      <TextsContainer>
        <TextTop data-testid={dataAttrs.title()}>
          {t('updateNewVersion.title')}
        </TextTop>
        <TextBottom>{t('updateNewVersion.text')}</TextBottom>
      </TextsContainer>
      <RefreshButton onClick={updateAssets}>
        {t('updateNewVersion.button')}
      </RefreshButton>
      <CloseButton
        name={'close_crossing'}
        settings={{ fill: colors.light100, width: '12px', height: '12px' }}
        onClick={() => {
          setIsClosed(true)
        }}
        ariaLabel={t('common.accessibilityText.closeButton')}
      />
    </Container>
  )
})

UpdateAppPromptView.displayName = 'UpdateAppPromptView'

export default UpdateAppPromptView
