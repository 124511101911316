import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import OverdueRow from 'src/components/JourneyMap/OverdueRow'
import FiltersPriority from 'src/components/JourneyMap/Filters/FiltersPriority'
import ResetFiltersButton from 'src/components/JourneyMap/ResetFiltersButton'
import ApplyFiltersButton from 'src/components/JourneyMap/ApplyFiltersButton'
import { getOverDueItems } from 'src/utils/journeyMap'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
  onClose: () => void
  viewContainerRef: any
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  padding: 2px 16px 16px;
`

const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const OverdueRowWrapper = styled(OverdueRow)`
  margin-top: 16px;
  width: 100%;
`

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.main10};
  margin: 8px 0 0;
`

const IconWrapper = styled(Icon)`
  position: absolute;
  right: 19px;
  padding: 2px;
`

const MobilePlanSettingsContent = React.memo((props: IProps) => {
  const context: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const {
    onFilterClick,
    priorityFilters,
    isOverdueMode,
    onOverdueClick,
    onResetFiltersClick,
    currentAbsence
  } = context

  const { className, onClose, viewContainerRef } = props
  const { t } = useTranslation()

  const overdueCount: number = getOverDueItems(currentAbsence.journeyMap).length

  return (
    <Container className={className}>
      <Title>{t('common.filters.title')}</Title>
      <IconWrapper name="close_crossing" onClick={onClose} />
      {overdueCount !== 0 && (
        <React.Fragment>
          <OverdueRowWrapper
            value={overdueCount}
            selected={isOverdueMode}
            onClick={onOverdueClick}
          />
          <Separator />
        </React.Fragment>
      )}
      <FiltersPriority
        onFilterClick={onFilterClick}
        filters={priorityFilters}
      />
      <ResetFiltersButton
        onResetFilters={() => {
          onResetFiltersClick()
          viewContainerRef.current.collapse()
        }}
      />
      <ApplyFiltersButton
        onApplyFilters={() => {
          viewContainerRef.current.collapse()
        }}
      />
    </Container>
  )
})

MobilePlanSettingsContent.displayName = 'MobilePlanSettingsContent'

export default MobilePlanSettingsContent
