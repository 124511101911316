import React from 'react'
import { ITimelinePeriod } from 'src/react-app-env'

export interface ITimelineViewContext {
  selectedDatePickerPeriod: ITimelinePeriod
  selectedDetailsPeriod: ITimelinePeriod
  onDetailsClick: (period: ITimelinePeriod) => void
  onDatePickerClick: (period: ITimelinePeriod) => void
}

const TimelineViewContext = React.createContext(null)

export default TimelineViewContext
