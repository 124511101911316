import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import {
  ISurveyResultsResponse,
  ISurveyNpsScaleField
} from 'src/graphql/hooks/useSurveySummaries'
import useChart from 'src/features/HrAdmin/hooks/useChart'
import SurveyNpsChart from 'src/features/HrAdmin/components/charts/SurveyNpsChart'
import SurveyNpsGauge from 'src/features/HrAdmin/components/charts/SurveyNpsGauge'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: ISurveyResultsResponse[]
  npsScaleFields: ISurveyNpsScaleField[]
  npsScore: number
  isDesktop: boolean
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-height: 162px;
          margin: 0 0 24px;
        `
      : css`
          margin: 0 0 8px;
        `}
`

const GaugeContainer = styled.div`
  width: 246px;
  height: 123px;
`

const ChartContainer = styled.div`
  flex: 1;
  height: 160px;
  min-width: 600px;
`

const MobileValuesContainer = styled.div`
  width: 100%;
  margin: 8px 0 0;
`

const MobileValueRow = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.main10};

  &:last-child {
    border: none;
  }
`

const MobileLabel = styled.span`
  font-size: 12px;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
`

const MobileValue = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
`

export const SurveyNpsResults = React.memo((props: IProps) => {
  const { data, npsScaleFields, npsScore, isDesktop } = props
  const { t } = useTranslation()
  const gaugeContainerRef: any = useRef(null)
  const chartContainerRef: any = useRef(null)

  useChart(gaugeContainerRef, SurveyNpsGauge, {
    value: npsScore,
    isDesktop,
    isMobile: !isDesktop
  })
  useChart(chartContainerRef, SurveyNpsChart, {
    data,
    npsScaleFields,
    t,
    isDesktop,
    isMobile: !isDesktop
  })

  return (
    <Container>
      <GaugeContainer ref={gaugeContainerRef} />
      {isDesktop ? (
        <ChartContainer ref={chartContainerRef} />
      ) : (
        <MobileValuesContainer>
          {npsScaleFields
            .sort(
              (a: ISurveyNpsScaleField, b: ISurveyNpsScaleField) =>
                a.order - b.order
            )
            .map((scaleField: ISurveyNpsScaleField) => (
              <MobileValueRow key={scaleField.type}>
                <MobileLabel>
                  {t(`hrAdmin.npsChartTypes.${scaleField.type}`, {
                    count: scaleField.value
                  })}
                </MobileLabel>
                <MobileValue>{scaleField.value}</MobileValue>
              </MobileValueRow>
            ))}
        </MobileValuesContainer>
      )}
    </Container>
  )
})

SurveyNpsResults.displayName = 'SurveyNpsResults'

export default SurveyNpsResults
