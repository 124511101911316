import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { UserRole } from 'src/react-app-env'
import { Button } from 'src/UIKit'
import ModalsManager from 'src/components/ModalsView'

interface IProps {
  roles: UserRole[]
  onRoleSelected: (role: UserRole) => void
  onCancel: () => void
}

const Container = styled.div`
  background: ${props => props.theme.colors.light100};
  padding: 16px;
  width: calc(343px - 32px);
  border: 1px solid #e6e7e8;
  box-sizing: border-box;
  box-shadow: 0 0 12px rgb(0 0 0 / 8%);
  border-radius: 8px;
`

const Title = styled.span`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: ${props => props.theme.colors.dar60};
  margin-bottom: 16px;
`

const RoleButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`

const CancelButton = styled(Button)`
  width: 100%;
  margin-top: 32px;
`

export const RoleSwitchModalView = React.memo((props: IProps) => {
  const { roles, onRoleSelected, onCancel } = props
  const { t } = useTranslation()

  return (
    <ModalsManager>
      <Container>
        <Title>{t('header.switchYourRole')}</Title>
        {roles.map((role: UserRole) => (
          <RoleButton
            key={role}
            onClick={() => {
              onRoleSelected(role)
            }}
          >
            {t(`common.${role}`)}
          </RoleButton>
        ))}
        <CancelButton onClick={onCancel} appearance={'cancel'}>
          {t(`common.cancel`)}
        </CancelButton>
      </Container>
    </ModalsManager>
  )
})

RoleSwitchModalView.displayName = 'RoleSwitchModalView'

export default RoleSwitchModalView
