import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ITimelinePeriod } from 'src/react-app-env'
import PeriodHintsView from 'src/features/Timeline/components/vertical/DetailsView/components/DetailsContainer/components/PeriodHintsView'
import zIndex from 'src/constants/zIndex'
import ProgramList from '../ProgramList'
import HolidayList from '../HolidayList'
import { Description } from '../Description'

interface IProps {
  className?: string
  period?: ITimelinePeriod
  title?: string
  description?: string
  extraDescription?: string
  children?: any
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-right: 24px;
    `}
`

const ControlsContainer = styled.div`
  position: relative;
  z-index: ${zIndex.timeline.mobile.detailsContainer.controlsContainer};
  background: ${props => props.theme.colors.light100};
  ${props =>
    props.theme.isMobile &&
    css`
      box-shadow: 0 4px 13px rgb(98 106 116 / 10%);
    `}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${props =>
    props.theme.isMobile &&
    css`
      padding: 16px 0 0;
    `}
`

export const dataAttrs = {
  description: () => 'details-description'
}

const DetailsContainer = React.memo((props: IProps) => {
  const { className, period, description, extraDescription, children } = props
  const programs = period?.details?.leavePrograms
  const holidays = period?.details?.holidays

  const descriptionComp = useMemo(
    () => (
      <Description
        data-testid={dataAttrs.description()}
        allowsParagraph
        components={{
          a: (linkProps: any) => (
            <a
              href={linkProps.href}
              target={'_blank'}
              rel="noopener noreferrer"
              onClick={(event: any) => event.stopPropagation()}
            >
              {linkProps.children}
            </a>
          )
        }}
      >
        {description}
      </Description>
    ),
    [description]
  )

  const extraDescriptionComp = useMemo(
    () =>
      extraDescription && (
        <Description
          allowsParagraph
          components={{
            a: (linkProps: any) => (
              <a
                href={linkProps.href}
                target={'_blank'}
                rel="noopener noreferrer"
                onClick={(event: any) => event.stopPropagation()}
              >
                {linkProps.children}
              </a>
            )
          }}
        >
          {extraDescription}
        </Description>
      ),
    [extraDescription]
  )

  return (
    <Container className={className}>
      <ControlsContainer>{children}</ControlsContainer>
      {period && (
        <ContentContainer>
          {descriptionComp}
          {extraDescriptionComp}
          {programs?.length > 0 && <ProgramList programs={programs} />}
          {period.timelineConfig?.detailsHolidays.show && (
            <HolidayList holidays={holidays} period={period} />
          )}
          <PeriodHintsView hints={period.hints} />
        </ContentContainer>
      )}
    </Container>
  )
})

DetailsContainer.displayName = 'DetailsContainer'

export default DetailsContainer
