import merge from 'lodash.merge'
import { canSelectLeavePreference } from 'src/features/Leave/methods'
import Workflow from 'src/utils/workflow'
import { ITimelineContext } from 'src/features/Timeline/Context'
import { CreateLeaveStage, ITimelinePeriod } from 'src/react-app-env'
import { defaultCustomerConfig, ICustomerConfig } from './config'
import {
  isBondingPeriodInPast,
  isPeriodNotManagedTpa,
  isPeriodNotSynced
} from 'src/utils/periodUtils'
import {
  isPublishedLeave,
  isPublishedOrBabyArrivedLeave
} from 'src/utils/leaveStatusUtils'
import { openDatePickerForPreference } from 'src/utils/leaveUtils'
import logo from 'src/assets/images/google-logo.svg'

const MIN_DAYS_UNUSED_BONDING = 5

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    logo,
    tpa: 'Sedgwick',
    leave: {
      create: {
        hasTpaFlow: (workflow: Workflow<CreateLeaveStage>) =>
          workflow.current === 'parental',
        preferences: ['starter', 'middler', 'reliever'],
        workflow: {
          Parental: {
            parental: ({ tpaLeave }) => (tpaLeave ? 'selectTpa' : 'congrats'),
            selectTpa: ({ useTpaLeave }) =>
              useTpaLeave === 'no' ? 'congrats' : null,
            congrats: ({ selectedLeaveType, selectedDueDate }) =>
              selectedLeaveType?.type !== 'Pregnancy' &&
              selectedDueDate &&
              canSelectLeavePreference(selectedDueDate)
                ? 'selectLeavePreference'
                : null,
            selectLeavePreference: null
          }
        }
      },
      timeline: {
        showNextStepsNotice: (leave: ILeave): boolean =>
          isPublishedLeave(leave.status) && leave.type === 'Miscarriage',
        showReviewAlerts: true,
        showRBTToastMessages: true,
        showVacationBalanceEdit: (leave: ILeave): boolean =>
          leave.type === 'Pregnancy',
        onAfterCloseReviewAlert: (timelineContext: ITimelineContext) => {
          openDatePickerForPreference(timelineContext, 'reliever')
        },
        showUnplannedBondingTooltipLessThan: (unusedBondingDays: number) =>
          unusedBondingDays < MIN_DAYS_UNUSED_BONDING
      },
      planChange: {
        showAsteriskNoteView: true
      },
      tpaSync: {
        showSyncStatus: (leave: ILeave) =>
          isPublishedOrBabyArrivedLeave(leave.status),
        isPeriodNotManagedTpa: (leave: ILeave, period: ITimelinePeriod) =>
          isPeriodNotManagedTpa(leave, period),
        isPeriodNotSynced: (leave: ILeave, period: ITimelinePeriod) =>
          isPeriodNotSynced(leave, period),
        isPeriodNotUpdated: (leave: ILeave, period: ITimelinePeriod) =>
          isBondingPeriodInPast(leave, period)
      },
      tutorial: {
        useOtherTutorialSteps: true,
        hasVacationStep: (leave: ILeave) => leave.type === 'Pregnancy'
      }
    },
    showInfoPage: true,
    userProfile: {
      tpaSyncSection: {
        showPromptTurnOff: (tpaSyncing: boolean) => tpaSyncing,
        showLastUpdate: false
      }
    }
  })
