import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'

interface IProps {
  className?: string
  onApplyFilters: () => void
}

const ApplyFiltersWrapper = styled(Button)`
  width: 100%;
  ${mobileButtonMaxWidthMixin};
`

const ApplyFiltersButton = React.memo((props: IProps) => {
  const { className, onApplyFilters } = props
  const { isDesktop } = useContext(ScreenContext)
  const { t } = useTranslation()

  if (isDesktop) {
    return null
  }

  return (
    <ApplyFiltersWrapper className={className} onClick={onApplyFilters}>
      {t('common.filters.apply')}
    </ApplyFiltersWrapper>
  )
})

ApplyFiltersButton.displayName = 'ApplyFiltersButton'

export default ApplyFiltersButton
