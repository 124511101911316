import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  getDurationItem,
  getPeriodDurationTextComponents
} from 'src/utils/leaveUtils'
import Tooltip from 'src/components/Tooltip'

const MIN_APPROXIMATE_DAYS = 6

interface IProps {
  className?: string
  durationType: IDurationType
  unusedBondingDays: number
  unusedBondingCalendarDays: number
  showUnplannedBondingTooltipLessThan: (unusedBondingDays: number) => boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Details = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-size: ${props => (props.theme.isDesktop ? '14px' : '11px')};
  font-weight: normal;
  color: ${props => props.theme.colors.dark60};
`

const UnusedBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const NumberText = styled.span`
  font-size: ${props => (props.theme.isDesktop ? '20px' : '14px')};
  font-weight: ${props => (props.theme.isDesktop ? 'normal' : '500')};
`

const StringText = styled.span`
  color: ${props => props.theme.colors.dark60};
`

export const dataAttrs = {
  details: () => `remaining-bonding-details`
}

const RemainingBondingDetails = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    className,
    durationType,
    unusedBondingDays,
    unusedBondingCalendarDays,
    showUnplannedBondingTooltipLessThan
  } = props

  const tooltipMessage: string = useMemo(
    () =>
      showUnplannedBondingTooltipLessThan(unusedBondingDays)
        ? t('remainingBonding.tooltip.descriptionLessThan')
        : t('remainingBonding.tooltip.description'),
    [showUnplannedBondingTooltipLessThan, unusedBondingDays, t]
  )

  const durationItem = getDurationItem(durationType)

  const unusedBlock = useMemo(
    () => (
      <UnusedBlock>
        <NumberText>{unusedBondingDays}</NumberText>
        <StringText>
          {t(`common.durations.${durationItem}`, {
            count: unusedBondingDays
          })}
        </StringText>
        {unusedBondingCalendarDays > MIN_APPROXIMATE_DAYS && (
          <StringText>
            {t('common.approximateWeek', {
              week: getPeriodDurationTextComponents(
                unusedBondingCalendarDays,
                t
              ).shortString
            })}
          </StringText>
        )}
      </UnusedBlock>
    ),
    [unusedBondingDays, t, durationItem, unusedBondingCalendarDays]
  )

  return (
    <Container className={className}>
      <Details data-testid={dataAttrs.details()}>
        <Tooltip body={tooltipMessage}>{t('remainingBonding.title')}</Tooltip>
        {unusedBlock}
      </Details>
    </Container>
  )
})

RemainingBondingDetails.displayName = 'RemainingBondingDetails'

export default RemainingBondingDetails
