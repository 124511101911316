import React, { useContext, useImperativeHandle, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import { Icon } from 'src/UIKit'
import MonthSection from './MonthSection'
import { getMonths, IMonth } from './methods'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: string
  items: IJourneyMapItem[]
  itemToScrollTo: IJourneyMapItem
  onItemCompletedClick: (id: string) => void
  animatesCompleted: boolean
  onItemClick: (id: string) => void
  blurred: boolean
  topView?: any
}

const ScrollView = styled.ul`
  width: 100%;
  overflow-y: auto;

  ${props =>
    props.theme.isDesktop &&
    css`
      -ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: none;
      min-height: 100%;
      height: 100%;
      max-width: 1112px;
      margin-left: 12px;
    `}
`

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation-name: ${fadeInKeyFrames};
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  margin: 80px 0 0;
`

const Message = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin: 24px 40px 32px;
  color: ${props => props.theme.colors.dark60};
`

const JourneyMapItemsList = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      className,
      items,
      itemToScrollTo,
      onItemCompletedClick,
      onItemClick,
      animatesCompleted,
      topView
    } = props
    const { t } = useTranslation()
    const scrollViewRef = useRef(null)
    const itemToScrollToRef = useRef(null)
    const managerJourneyMapContext: IManagerJourneyMapContext = useContext(
      ManagerJourneyMapContext
    )
    const { isDesktop } = useContext(ScreenContext)

    useImperativeHandle(ref, () => ({
      scrollToTodayOrLaterItem: () => {
        try {
          const y = itemToScrollToRef.current.getBoundingClientRect().y - 192
          if (y > 5) {
            scrollViewRef.current.scrollTop = y
          }
        } catch (_) {
          return undefined
        }
      },
      scrollToTop: () => {
        scrollViewRef.current.scrollTop = 0
      }
    }))

    const months: IMonth[] = getMonths(items)
    let contentView
    if (items.length) {
      contentView = months.map((month: IMonth) => (
        <MonthSection
          key={month.title}
          items={month.items}
          fadesOut={month.fadesOut}
          title={month.title}
          animatesMarginTop={month.animatesMarginTop}
          onItemCompletedClick={onItemCompletedClick}
          onItemClick={onItemClick}
          itemToScrollTo={itemToScrollTo}
          itemToScrollToRef={itemToScrollToRef}
          useShortTopMargin={!managerJourneyMapContext && isDesktop}
        />
      ))
    } else if (!animatesCompleted) {
      contentView = (
        <MessageWrapper>
          <Message>
            {t(
              `journeyMap.${
                managerJourneyMapContext
                  ? 'youHaveNoItemsManager'
                  : 'youHaveNoItems'
              }`
            )}
          </Message>
          <Icon
            name={'pagick_no_idea_left'}
            ariaLabel={t('common.accessibilityText.noItems')}
          />
        </MessageWrapper>
      )
    }

    return (
      <ScrollView
        className={className}
        ref={scrollViewRef}
        role={'list'}
        aria-label={t('common.accessibilityText.regionJourneyList')}
      >
        {topView}
        {contentView}
      </ScrollView>
    )
  }),
  (prevProps: IProps, nextProps: IProps) =>
    nextProps.items === prevProps.items &&
    nextProps.itemToScrollTo === prevProps.itemToScrollTo &&
    nextProps.blurred === prevProps.blurred
)

JourneyMapItemsList.displayName = 'JourneyMapItemsList'

export default JourneyMapItemsList
