import InfoMessageDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/InfoMessageDatePicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: InfoMessageDatePicker,
  disableCreatePeriod: true,
  periodPickerInfoMessage: (t: any) =>
    t([
      `timeline.leaveDurationPicker.LongTermDisability_${input.leave.type}_${input.leave.metadata.transitionFromType}`,
      `timeline.leaveDurationPicker.LongTermDisability`
    ])
})
