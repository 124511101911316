import { ITimelinePeriodConfig } from '../../../index'
import IntermittentBlocksPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/IntermittentBlocksPicker'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  datePickerComponent: IntermittentBlocksPicker,
  intermittentPickerResetBlock: false,
  footerShowDetails: true,
  useApprovedByTpa: true,
  periodPickerSelectedNotice: {
    show: true
  }
})
