import { hasApproximatePayment } from 'src/utils/periodUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import IntermittentBlocksPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/IntermittentBlocksPicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: IntermittentBlocksPicker,
  usePeriodRefIdSearch: true,
  periodPickerSelectedNotice: {
    show: true
  },
  detailsExtraDescription: (t: any) =>
    hasApproximatePayment(input.period)
      ? t(
          `timelineHints.periods.${baseConfig.detailsType}.extra.approximatePayment`
        )
      : null
})
