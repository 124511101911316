import React from 'react'
import styled from 'styled-components'
import { Checkbox } from 'src/UIKit'

interface IProps {
  className?: string
  checked: boolean
  disabled: boolean
  index: number
  ariaLabel: string
}

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const FilterCheckbox = React.memo(
  (props: IProps) => {
    const { className, checked, index, disabled, ariaLabel } = props
    return (
      <Container>
        <Checkbox
          className={className}
          name={'checkbox'}
          checked={checked}
          payload={index}
          disabled={disabled}
          ariaLabel={ariaLabel}
        />
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.checked === nextProps.checked &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.index === nextProps.index
)

FilterCheckbox.displayName = 'FilterCheckbox'

export default FilterCheckbox
