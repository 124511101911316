import React, { useEffect, useState } from 'react'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import withQueries from 'src/components/HOC/withQueries'
import PlanChangePage from './components/PlanChangePage'
import { IWithQueriesProps } from 'src/react-app-env'
import {
  createLeaveRoute,
  journeyMapRoute,
  timelineRoute,
  errorPageRoute
} from 'src/routes/constants'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { hasNewTpaChanges } from 'src/utils/leaveUtils'
import { useNavigate } from 'react-router-dom'

interface IProps extends IWithQueriesProps {}

const PlanChangeContainer = React.memo((props: IProps) => {
  const { queries } = props
  const navigate = useNavigate()
  const [leave, setLeave] = useState<ILeave>(null)
  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  usePageTitle('planChange')

  useEffect(() => {
    fetchLeaveWithChanges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchLeaveWithChanges = async (fromCache = false) => {
    try {
      const aLeave: ILeave = await queries.fetchLeaveWithDatesAndMetadata({
        fetchPolicy: fromCache ? 'cache-first' : 'network-only',
        notFound: () => {
          navigate(createLeaveRoute)
        }
      })
      if (hasNewTpaChanges(aLeave)) {
        setLeave(aLeave)
      } else {
        navigate(journeyMapRoute)
      }
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const onContinueClicked = async () => {
    try {
      await queries.acceptTpaChanges(false)
      navigate(timelineRoute)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }

  const loadingSpinner = <LoadingSpinner fullScreen fadesIn />

  if (!leave) {
    return loadingSpinner
  }

  return <PlanChangePage leave={leave} onContinueClicked={onContinueClicked} />
})

PlanChangeContainer.displayName = 'PlanChangeContainer'

export default withQueries(PlanChangeContainer)
