import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IDialogData } from 'src/react-app-env'
import ModalsView from 'src/components/ModalsView'
import Dialog from './components/Dialog'
import * as Methods from './methods'
import { KEY_ESCAPE } from 'src/constants/keys'

interface IProps {
  children: any
}

const { Consumer, Provider } = React.createContext({})

export const DialogProvider = React.memo((props: IProps) => {
  const { children } = props
  const [dialog, setDialog] = useState<IDialogData>(null)
  const [opened, setOpened] = useState<boolean>(false)
  const overlayRef: any = useRef(null)

  const add = useCallback(
    (data: IDialogData) => {
      Methods.add(data, setDialog)
      setOpened(true)
    },
    [setDialog]
  )

  const update = useCallback(
    (data: IDialogData) => {
      setDialog(data)
    },
    [setDialog]
  )

  const remove = useCallback(() => {
    Methods.remove(dialog, setDialog)
    setOpened(false)
  }, [dialog, setDialog])

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === KEY_ESCAPE) {
        if (dialog?.onUserClosed) {
          dialog.onUserClosed()
        }
        remove()
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [dialog, remove])

  const containerProps: any = {
    ref: overlayRef,
    onClick: (event: any) => {
      if (event.target === overlayRef.current) {
        if (dialog?.onUserClosed) {
          dialog.onUserClosed()
        }
        remove()
      }
    }
  }

  const context = {
    opened,
    add,
    remove,
    update
  }

  return (
    <Provider value={context}>
      {children}
      {dialog?.children && (
        <ModalsView {...containerProps}>
          <Dialog dismiss={remove} {...dialog} />
        </ModalsView>
      )}
    </Provider>
  )
})

DialogProvider.displayName = 'DialogProvider'

export const DialogConsumer = (props: any) => (
  <Consumer>{context => props.children(context)}</Consumer>
)

export const withDialogManager = (Comp: any) => {
  const dialogConsumer = (props: any) => (
    <DialogConsumer>
      {(context: any) => <Comp dialogManager={context} {...props} />}
    </DialogConsumer>
  )

  dialogConsumer.displayName = 'DialogConsumer'

  return dialogConsumer
}
