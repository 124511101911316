import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
  className?: any
  ariaHidden: boolean
}

const Container = styled.span`
  line-height: 100%;
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
`

const FieldCardLabel = React.memo((props: IProps) => {
  const { children, className, ariaHidden } = props
  return (
    <Container className={className} aria-hidden={ariaHidden}>
      {children}
    </Container>
  )
})

FieldCardLabel.displayName = 'FieldCardLabel'

export default FieldCardLabel
