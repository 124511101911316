import * as d3 from 'd3'
import createTheme from 'src/theme'
import { ISurveyNpsScaleField } from 'src/graphql/hooks/useSurveySummaries'
import {
  createTopRoundedVerticalPath,
  getAnimationDelay,
  getAnimationDuration,
  getVerticalAxisData,
  IVerticalAxisData
} from 'src/features/HrAdmin/components/charts/methods'

const { colors } = createTheme()

export default (args: any, parentContainer: any) => {
  const { data, npsScaleFields, t } = args
  const parentRect = parentContainer
    ? parentContainer.getBoundingClientRect()
    : { width: 0, height: 0 }
  const margin: any = { top: 12, right: 0, bottom: 44, left: 14 }
  const width: number = parentRect.width - margin.left - margin.right
  const height: number = parentRect.height - margin.top - margin.bottom

  const animationDuration: number = getAnimationDuration(data.length)
  const animationDelay: number = getAnimationDelay(data.length)

  const verticalAxisData: IVerticalAxisData = getVerticalAxisData(data)

  const x: any = d3.scaleBand().range([0, width]).padding(0.3)
  const y: any = d3.scaleLinear().range([height, 0])

  const xAxis: any = d3.axisBottom(x).tickSize(0).tickPadding(10)

  x.domain(data.map((d: any) => d.label))
  y.domain([0, verticalAxisData.maxValue])

  const d3svg: any = d3
    .select(parentContainer)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  d3svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .style('font-size', '10px')
    .style('color', colors.dark60)
    .call(xAxis)
    .call((g: any) => g.select('.domain').remove())

  d3svg
    .selectAll('.bar')
    .data(data)
    .enter()
    .append('path')
    .style('display', (d: any) => (d.value === null ? 'none' : null))
    .style('fill', colors.main100)
    .attr('d', (d: any) =>
      createTopRoundedVerticalPath(x(d.label), height, x.bandwidth(), 0, 4)
    )
    .transition()
    .duration(animationDuration)
    .delay((d: any, i: number) => i * animationDelay)
    .attr('d', (d: any) =>
      createTopRoundedVerticalPath(
        x(d.label),
        height,
        x.bandwidth(),
        d.value === 0 ? -4 : y(d.value) - height,
        4
      )
    )

  d3svg
    .selectAll('.label')
    .data(data)
    .enter()
    .append('text')
    .style('font-size', '12px')
    .style('display', (d: any) => (d.value === null ? 'none' : null))
    .style('color', colors.main100)
    .attr('x', (d: any) => x(d.label) + x.bandwidth() / 2 - 4)
    .style('fill', () => colors.main100)
    .attr('y', () => height)
    .attr('height', 0)
    .transition()
    .duration(animationDuration)
    .delay((d: any, i: number) => i * animationDelay)
    .text((d: any) => d.value)
    .attr('y', (d: any) => y(d.value) + 0.1)
    .attr('dy', '-.7em')

  const drawScale = (sf: ISurveyNpsScaleField) => {
    const { type, value, startIndex, length } = sf
    const h: number = height + 17
    const step: number = x.step() * (length - 1) + x.bandwidth()
    const xPos: number = x(data[startIndex].label)

    d3svg
      .append('path')
      .attr('d', `M ${xPos} 0 l 0 7 l ${step} 0 l 0 -7`)
      .attr('transform', `translate(0, ${h})`)
      .style('fill', 'none')
      .style('stroke', colors.dark60)

    d3svg
      .append('path')
      .attr('d', `M ${xPos + step / 2} 7 l 0 8`)
      .attr('transform', `translate(0, ${h})`)
      .style('fill', 'none')
      .style('stroke', colors.dark60)

    d3svg
      .append('text')
      .style('color', colors.main100)
      .attr('x', xPos + step / 2)
      .attr('y', h + 24)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', colors.dark60)
      .text(`${value} `)
      .append('tspan')
      .style('fill', colors.dark60)
      .text(t(`hrAdmin.npsChartTypes.${type}`, { count: value }))
  }

  npsScaleFields.forEach((sf: ISurveyNpsScaleField) => {
    drawScale(sf)
  })
}
