import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  children: any
}

const Description = styled.span`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  color: ${props => props.theme.colors.dark60};
  font-weight: 500;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          line-height: 16px;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
        `}

  @media print {
    font-size: 14px;
    line-height: 16px;
  }
`

const TimelineTitleViewDescription = React.memo((props: IProps) => (
  <Description>{props.children}</Description>
))

TimelineTitleViewDescription.displayName = 'TimelineTitleViewDescription'

export default TimelineTitleViewDescription
