import { calculateDaysDifferenceInclusive } from 'src/utils/dateUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const { period, t } = input
  const count: number = calculateDaysDifferenceInclusive(
    period.periodEnd?.min,
    period.periodEnd?.max
  )

  return {
    ...baseConfig,
    periodKeyDate: 'periodEnd',
    datePickerComponent: LeaveDatePeriodPicker,
    datePickerDescription: t('timeline.leaveDurationPicker.Reinstatement', {
      count
    })
  }
}
