import React from 'react'
import styled from 'styled-components'

const Separator = styled.div`
  margin: 12px 0;
  height: 1px;
  width: 100%;
  background: #ebeff2;
`

export const LeftSidebarSectionSeparator = () => <Separator />

export default LeftSidebarSectionSeparator
