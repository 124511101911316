import React, { ReactNode, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { IIconName } from 'src/react-app-env'
import ScreenContext from 'src/contexts/ScreenContext'
import AccordionRootItem from './components/AccordionRootItem'
import AccordionSubItem from './components/AccordionSubItem'

export interface IAccordionSubItem {
  title: string
  iconName: IIconName
  onClick: () => void
  selected: boolean
}

export interface IAccordionData {
  rootItemIconName: IIconName
  rootItemTitle: string
  subItems: IAccordionSubItem[]
}

export interface IAccordion {
  data: IAccordionData
  onRootClick?: () => void
  className?: string
  expanded?: boolean
}

const ITEM_HEIGHT_DESKTOP = 36
const ITEM_HEIGHT_MOBILE = 38
const TRANSITION_DURATION = 200

const Container = styled.div`
  width: 100%;
`

const SubItemsContainer = styled.ul<any>`
  transition: all ${TRANSITION_DURATION}ms;
  height: ${props => props.height}px;
  opacity: ${props => props.opacity};
  overflow: hidden;
`

const Accordion = React.memo(
  React.forwardRef((props: IAccordion) => {
    const { isDesktop } = useContext(ScreenContext)

    const {
      data: { rootItemIconName, rootItemTitle, subItems },
      onRootClick,
      className,
      expanded
    } = props

    const subItemsContainerHeight: number = useMemo(
      () =>
        expanded
          ? (isDesktop ? ITEM_HEIGHT_DESKTOP : ITEM_HEIGHT_MOBILE) *
            subItems.length
          : 0,
      [subItems, expanded, isDesktop]
    )

    const subItemsContainerOpacity: number = useMemo(
      () => (expanded ? 1 : 0),
      [expanded]
    )

    const rootItemView: ReactNode = useMemo(
      () => (
        <AccordionRootItem
          onClick={onRootClick}
          title={rootItemTitle}
          expanded={expanded}
          iconName={rootItemIconName}
          transitionDuration={TRANSITION_DURATION}
        />
      ),
      [rootItemIconName, rootItemTitle, onRootClick, expanded]
    )

    const subItemsView: ReactNode = useMemo(
      () =>
        subItems.map((si: IAccordionSubItem) => (
          <AccordionSubItem
            key={si.title}
            onClick={si.onClick}
            title={si.title}
            iconName={si.iconName}
            selected={si.selected}
            hidden={!expanded}
          />
        )),
      [subItems, expanded]
    )

    return (
      <Container className={className}>
        {rootItemView}
        <SubItemsContainer
          height={subItemsContainerHeight}
          opacity={subItemsContainerOpacity}
          aria-hidden={!expanded}
        >
          {subItemsView}
        </SubItemsContainer>
      </Container>
    )
  })
)

Accordion.displayName = 'Accordion'

export default Accordion
