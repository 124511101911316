import React, { useCallback, useContext, useMemo, useRef } from 'react'
import DashboardContext, {
  IManagerDashboardContext
} from 'src/features/ManagerJourneyMap/DashboardContext'
import styled from 'styled-components'
import zIndex from 'src/constants/zIndex'
import PlanSettingsButton from 'src/components/JourneyMap/PlanSettingsButton'
import AbsenceSelect from './components/AbsenceSelect'

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  justify-content: space-between;
  padding: 12px 16px;
  z-index: ${zIndex.journeyMap.page.priorityFiltersWrapper};
`

const DashboardMobileHeader = React.memo(() => {
  const context: IManagerDashboardContext = useContext(DashboardContext)
  const { dashboardAbsences, dashboardAbsence, selectAbsence } = context
  const containerRef = useRef(null)

  const options = useMemo(
    () =>
      dashboardAbsences.map(absence => ({
        id: absence.id,
        text: absence.employee.name
      })),
    [dashboardAbsences]
  )

  const selected = useMemo(
    () => options.find(option => option.id === dashboardAbsence.id),
    [options, dashboardAbsence]
  )

  const onOptionChange = useCallback(
    (option: any) => {
      const absence = dashboardAbsences.find(a => a.id === option.id)
      if (absence) {
        selectAbsence(absence)
      }
    },
    [dashboardAbsences, selectAbsence]
  )

  return (
    <Container>
      <AbsenceSelect
        containerRef={containerRef}
        options={options}
        selected={selected}
        onChange={onOptionChange}
      />
      {!dashboardAbsence.outdated && (
        <PlanSettingsButton onOpen={() => containerRef.current?.close()} />
      )}
    </Container>
  )
})

DashboardMobileHeader.displayName = 'DashboardMobileHeader'

export default DashboardMobileHeader
