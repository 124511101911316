import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  alerts: input.leave.metadata?.LTDwarning
    ? ['longTermDisabilityMedicalExtend']
    : baseConfig.alerts,
  datePickerComponent: MultiDatePicker
})
