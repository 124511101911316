import { isFirstPeriodWithType } from 'src/utils/leaveUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import LeaveDateTimelinePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateTimelinePicker'
import OrdinaryMaternityPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/OrdinaryMaternityPicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isFirst = isFirstPeriodWithType(input.period.hash)
  return {
    ...baseConfig,
    datePickerComponent:
      input.leave.type === 'Miscarriage'
        ? LeaveDateTimelinePicker
        : OrdinaryMaternityPicker,
    periodKeyDate:
      input.leave.type === 'Miscarriage'
        ? 'periodEnd'
        : isFirst
          ? 'periodStart'
          : 'periodEnd',
    addsOneVisualDay: isFirst,
    timeViewProps: {
      ...baseConfig.timeViewProps
    },
    detailsHolidays: {
      ...baseConfig.detailsHolidays,
      show: true
    },
    periodPickerSelectedNotice: {
      show: true
    }
  }
}
