import React from 'react'
import styled, { css } from 'styled-components'
import SessionsListItem from 'src/features/UserProfile/components/Sessions/components/SessionsListItem'
import FlexibleContainer from 'src/components/FlexibleContainer'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: any
  sessions: ISession[]
  revokingSessionsId: string[]
}

const Container = styled(FlexibleContainer)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
        `
      : css`
          width: 100%;
        `}
`

const SessionsList = React.memo((props: IProps) => {
  const { className, sessions, revokingSessionsId } = props
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <ul
        role={'list'}
        aria-label={t('common.accessibilityText.regionSessionsList')}
      >
        {sessions.map((session: any, index: number) => (
          <SessionsListItem
            key={session.id}
            session={session}
            isRevoking={revokingSessionsId.indexOf(session.id) !== -1}
            showsSeparator={index !== sessions.length - 1}
          />
        ))}
      </ul>
    </Container>
  )
})

SessionsList.displayName = 'SessionsList'

export default SessionsList
