export const getSurveyWithSortedQuestions = (survey: ISurvey): ISurvey => {
  const questions: ISurveyQuestion[] = [...survey.questions]
  questions.sort(
    (q1: ISurveyQuestion, q2: ISurveyQuestion) => q1.rank - q2.rank
  )
  return { ...survey, questions }
}

export const areSurveyAnswersValid = (
  answers: any,
  questions: ISurveyQuestion[]
) => {
  for (const question of questions) {
    const answer: any = answers[question.id]
    if (question.responseRequired && !answer) {
      return false
    }
  }

  return true
}
