import { ITimelinePeriodBlock } from 'src/react-app-env'
import usePrevious from 'src/components/hooks/usePrevious'
import isEqual from 'lodash.isequal'

export default (
  blocks: ITimelinePeriodBlock[],
  skip: boolean
): ITimelinePeriodBlock[] => {
  const prevBlocks: ITimelinePeriodBlock[] = usePrevious(blocks)
  const blocksToRender: ITimelinePeriodBlock[] = []

  if (skip) {
    return blocks
  }

  if (isEqual(prevBlocks, blocks)) {
    return blocks
  }

  if (prevBlocks && blocks.length !== prevBlocks.length) {
    // if added
    if (prevBlocks.length < blocks.length) {
      blocks.forEach((block: ITimelinePeriodBlock) => {
        const { key } = block
        const hasPrevBlockWithTheKey: boolean =
          prevBlocks.filter((b: ITimelinePeriodBlock) => b.key === key)
            .length !== 0
        if (hasPrevBlockWithTheKey) {
          blocksToRender.push({ ...block })
        } else {
          blocksToRender.push({ ...block, animatesAdded: true })
        }
      })
    }

    // if removed
    if (prevBlocks.length > blocks.length) {
      let removedIndex = 0
      let removedBlock: ITimelinePeriodBlock

      for (let i = 0; i < prevBlocks.length; i++) {
        const block: ITimelinePeriodBlock = prevBlocks[i]
        const { key } = block
        const hasNewBlockWithTheKey: boolean =
          blocks.filter((b: ITimelinePeriodBlock) => b.key === key).length !== 0
        if (!hasNewBlockWithTheKey) {
          removedIndex = i
          removedBlock = block
          break
        }
      }

      blocksToRender.push(...blocks)

      blocksToRender.splice(removedIndex, 0, {
        ...removedBlock,
        animatesRemoved: true
      })
    }
  } else {
    blocksToRender.push(...blocks)
  }

  return blocksToRender
}
