import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { weekDays, workDays, isFiveDaySchedule } from 'src/utils/dateUtils'
import DayButton from 'src/UIKit/DayButton'
import moment from 'moment'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  className?: string
  schedule: IApplicableSchedule
  activeDaysWeekOne: Set<IWeekday>
  activeDaysWeekTwo: Set<IWeekday>
  onDayClicked: (week: number, day: IWeekday) => void
}

const Container = styled.div`
  width: 100%;
`

const Title = styled(MarkdownText)`
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  white-space: nowrap;
`

const DaysContainer = styled.div`
  display: flex;
  margin: 15px 0 0;
`

const DaysOfWeekContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`

export const dataAttrs = {
  dayButton: (weekNumber: number, day: string) =>
    `days-of-week-${weekNumber}-${day}`,
  weekOneContent: () => 'days-of-week-one',
  weekTwoContent: () => 'days-of-week-two'
}

const weekView = (
  days: string[],
  weekNumber: number,
  weekSchedule: IWeekday[],
  activeDays: Set<IWeekday>,
  onDayClicked: any
) =>
  days.map((day: string) => {
    const isSelected = activeDays.has(day as IWeekday)
    const isDisabled = weekSchedule && !weekSchedule.includes(day as IWeekday)
    return (
      <DayButton
        data-testid={dataAttrs.dayButton(weekNumber, day)}
        key={day}
        $selected={isSelected}
        disabled={isDisabled}
        onClick={() => {
          onDayClicked(weekNumber, day)
        }}
        role={'switch'}
        aria-checked={isSelected}
        aria-label={day}
      >
        {day.toUpperCase().charAt(0)}
      </DayButton>
    )
  })

const DATE_FORMAT = 'MMM DD'

const DaysOffView = React.memo((props: IProps) => {
  const {
    className,
    schedule,
    activeDaysWeekOne,
    activeDaysWeekTwo,
    onDayClicked
  } = props
  const { t } = useTranslation()
  const { weekOne, weekTwo, weekOneStart } = schedule
  const weekOneStartDate = moment(weekOneStart).utc()
  const weekOneEndDate = weekOneStartDate.clone().endOf('isoWeek')
  const weekTwoStartDate = weekOneStartDate.clone().add(1, 'week')
  const weekTwoEndDate = weekTwoStartDate.clone().endOf('isoWeek')
  const days = isFiveDaySchedule(weekOne, weekTwo) ? workDays : weekDays

  const daysForWeekOne = useMemo(
    () => weekView(days, 0, weekOne, activeDaysWeekOne, onDayClicked),
    [days, activeDaysWeekOne, weekOne, onDayClicked]
  )

  const daysForWeekTwo = useMemo(
    () =>
      weekTwo?.length &&
      weekView(days, 1, weekTwo, activeDaysWeekTwo, onDayClicked),
    [days, activeDaysWeekTwo, weekTwo, onDayClicked]
  )

  const weekOneDaysOff = useMemo(() => {
    const titleKey = daysForWeekTwo ? 'daysOffForWeek' : 'daysOff'

    return (
      <DaysOfWeekContainer data-testid={dataAttrs.weekOneContent()}>
        <Title>
          {t(`timeline.intermittentBlocksPicker.leaveBlockView.${titleKey}`, {
            dateFrom: weekOneStartDate.format(DATE_FORMAT),
            dateTo: weekOneEndDate.format(DATE_FORMAT)
          })}
        </Title>
        <DaysContainer>{daysForWeekOne}</DaysContainer>
      </DaysOfWeekContainer>
    )
  }, [t, weekOneStartDate, weekOneEndDate, daysForWeekOne, daysForWeekTwo])

  const weekTwoDaysOff = useMemo(
    () =>
      daysForWeekTwo && (
        <DaysOfWeekContainer data-testid={dataAttrs.weekTwoContent()}>
          <Title>
            {t(
              'timeline.intermittentBlocksPicker.leaveBlockView.daysOffForWeek',
              {
                dateFrom: weekTwoStartDate.format(DATE_FORMAT),
                dateTo: weekTwoEndDate.format(DATE_FORMAT)
              }
            )}
          </Title>
          <DaysContainer>{daysForWeekTwo}</DaysContainer>
        </DaysOfWeekContainer>
      ),
    [t, weekTwoStartDate, weekTwoEndDate, daysForWeekTwo]
  )

  return (
    <Container className={className}>
      {weekOneDaysOff}
      {weekTwoDaysOff}
    </Container>
  )
})

DaysOffView.displayName = 'DaysOffView'

export default DaysOffView
