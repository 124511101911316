import styled, { css } from 'styled-components'
import Cal from 'react-calendar'
import createTheme from 'src/theme'
import fixMixin from 'src/components/Calendar/styles/fixMixin'
import mainStylesMixin from '../mainStylesMixin'

const { colors } = createTheme()

export default styled(Cal)`
  ${mainStylesMixin};

  .react-calendar__tile {
    border-radius: 50px;
    font-size: 1em;
    padding: 0.6em 0.3em;
    ${fixMixin}
  }

  .highlighted__tile {
    border: 1px dashed ${colors.main40} !important;
    padding: calc(0.6em - 2px) 0.3em !important;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 322px !important;
        `
      : css`
          max-width: 304px !important;
        `}

  background: white;
  border: 1px solid white !important;
  color: #192433;
`
