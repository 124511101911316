import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import useComponentRect from 'src/components/hooks/useComponentRect'
import { useTranslation } from 'react-i18next'
import { ResponseStrings } from 'src/components/Surveys/SurveyContainer'

interface IProps {
  responses: string[]
  selectedResponse: ResponseStrings
  onAnswer: (response: ResponseStrings) => void
  labels: string[]
  isGlobalSurvey: boolean
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const LabelsContainer = styled.div`
  width: 100%;
  height: 21px;
  position: relative;
`

const labelMixin = css`
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  position: absolute;
  top: 16px;
`

const LabelLeft = styled.span<{ $isOneRow: boolean }>`
  ${labelMixin}
  ${props => css`
    transform: translateX(${props.$isOneRow ? '-50%' : 0});
    left: ${props.$isOneRow ? '32px' : props.theme.isDesktop ? 0 : '8px'};
  `}
`

const LabelRight = styled.span<{ $isOneRow: boolean }>`
  ${labelMixin}
  ${props => css`
    transform: translateX(${props.$isOneRow ? '50%' : 0});
    right: ${props.$isOneRow ? '32px' : props.theme.isDesktop ? 0 : '8px'};
  `}
`

export const ResponseView = styled(Button)<any>`
  border-radius: 50%;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 56px;
          height: 56px;
          margin: 4px;
        `
      : css`
          width: 48px;
          height: 48px;
          margin: 8px 4px;
          font-size: 20px;
          padding: 0;
        `}

  ${props => {
    if (props.appearance === 'default') {
      return css`
        color: ${props.theme.colors.main20};
        font-weight: 500;
      `
    }
  }}
`

const getLabel = (
  labels: string[],
  responses: string[],
  isOneRow: boolean,
  left: boolean
): string => {
  if (!labels) {
    return ''
  }
  const labelIndex: number = left ? 0 : 1
  if (labelIndex > labels.length - 1) {
    return ''
  }
  const label = labels[labelIndex]
  if (isOneRow) {
    return label
  } else {
    const responseIndex: number = left ? 0 : responses.length - 1
    if (responseIndex > responses.length - 1) {
      return label
    } else {
      return `${responses[responseIndex]} – ${label}`
    }
  }
}

export const dataAttrs = {
  component: () => 'answer-type-nps'
}

const AnswerTypeNps = React.memo((props: IProps) => {
  const { responses, onAnswer, selectedResponse, labels, isGlobalSurvey } =
    props
  const { t } = useTranslation()
  const [isOneRow, setIsOneRow] = useState<boolean>(true)
  const containerRef: any = useRef(null)
  const { height } = useComponentRect(containerRef)
  const lastResponseRef: any = useRef(null)

  useEffect(() => {
    if (!isGlobalSurvey) {
      lastResponseRef.current?.focus()
    }
  }, [lastResponseRef, isGlobalSurvey])

  useEffect(() => {
    setIsOneRow(height < 100)
  }, [height])

  const responsesView: any = responses.map(
    (response: string, index: number) => (
      <ResponseView
        appearance={response === selectedResponse ? 'default' : 'cancel'}
        key={response}
        onClick={() => {
          onAnswer(response)
        }}
        ref={index === responses.length - 1 ? lastResponseRef : null}
      >
        {response}
      </ResponseView>
    )
  )

  const labelLeftText: string = useMemo(
    () => getLabel(labels, responses, isOneRow, true),
    [labels, isOneRow, responses]
  )

  const labelRightText: string = useMemo(
    () => getLabel(labels, responses, isOneRow, false),
    [labels, isOneRow, responses]
  )

  return (
    <Container
      data-testid={dataAttrs.component()}
      ref={containerRef}
      role={'region'}
      aria-label={t('common.accessibilityText.options')}
    >
      {responsesView}
      <LabelsContainer>
        <LabelLeft $isOneRow={isOneRow}>{labelLeftText}</LabelLeft>
        <LabelRight $isOneRow={isOneRow}>{labelRightText}</LabelRight>
      </LabelsContainer>
    </Container>
  )
})

AnswerTypeNps.displayName = 'AnswerTypeNps'

export default AnswerTypeNps
