import { useCallback, useState } from 'react'
import { Moment } from 'moment'
import { IMomentCurrentMinMax } from 'src/react-app-env'
import { areSameDates } from 'src/utils/dateUtils'

export interface IUseDate {
  initialDate: IMomentCurrentMinMax
  date: IMomentCurrentMinMax
  setDate: (value: IMomentCurrentMinMax) => void
  setCurrent: (current: Moment) => void
  current: Moment
  setMin: (min: Moment) => void
  min: Moment
  setMax: (max: Moment) => void
  max: Moment
  reset: () => void
  isSameAsInitial: () => boolean
}

export default (defaultDate: IMomentCurrentMinMax): IUseDate => {
  const [initialDate] = useState<IMomentCurrentMinMax>(defaultDate)
  const [date, setDate] = useState<IMomentCurrentMinMax>(initialDate)

  const reset = useCallback(() => {
    setDate(initialDate)
  }, [initialDate, setDate])

  const setCurrent = useCallback(
    (current: Moment) => {
      setDate({ ...date, current })
    },
    [setDate, date]
  )

  const setMin = useCallback(
    (min: Moment) => {
      setDate({ ...date, min })
    },
    [setDate, date]
  )

  const setMax = useCallback(
    (max: Moment) => {
      setDate({ ...date, max })
    },
    [setDate, date]
  )

  const isSameAsInitial = useCallback(
    (): boolean => areSameDates(date?.current, initialDate.current),
    [date, initialDate]
  )

  return {
    ...date,
    initialDate,
    date,
    setDate,
    setCurrent,
    setMin,
    setMax,
    reset,
    isSameAsInitial
  }
}
