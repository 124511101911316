import BabyBonding from './BabyBonding'
import FamilyCare from './FamilyCare'
import Military from './Military'
import PaidMedical from './PaidMedical'
import PartTimeMedical from './PartTimeMedical'
import Personal from './Personal'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import Reinstatement from './Reinstatement'

export default {
  BabyBonding,
  FamilyCare,
  Military,
  PaidMedical,
  PartTimeMedical,
  Personal,
  PreDelivery,
  Recovery,
  Reinstatement
}
