import React from 'react'

export interface IManagerDashboardContext {
  dashboardAbsences: IAbsence[]
  dashboardAbsence?: IAbsence
  toggleEmailNotifications?: (absence: IAbsence) => void
  emailNotificationsOn?: boolean
  archivePlan?: (absence: IAbsence) => void
  selectAbsence: (absence: IAbsence) => void
}

const DashboardContext = React.createContext(null)

export default DashboardContext
