import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IIconName } from 'src/react-app-env'
import ContextMenuItem from './components/ContextMenuItem'

export type ContextMenuViewItemType = 'remove'

export interface IContextMenuViewItem {
  type: ContextMenuViewItemType
}

interface IProps {
  className?: string
  items: IContextMenuViewItem[]
  onItemClick: (type: ContextMenuViewItemType) => void
}

const Container = styled.ul`
  width: 132px;
  padding: 8px 0;
`

const ContextMenuView = React.memo((props: IProps) => {
  const { items, onItemClick } = props
  const { t } = useTranslation()

  const itemViews: ReactNode[] = useMemo(
    () =>
      items.map((item: IContextMenuViewItem) => {
        const { type } = item
        const label: string = t(`planHistory.contextMenu.${type}`)
        const ariaLabel: string = t(
          `common.accessibilityText.planContextMenuActions.${type}`
        )
        let iconName: IIconName

        if (type === 'remove') {
          iconName = 'trash_can'
        }

        return (
          <ContextMenuItem
            key={type}
            label={label}
            onClick={() => {
              onItemClick(type)
            }}
            iconName={iconName}
            ariaLabel={ariaLabel}
          />
        )
      }),
    [items, t, onItemClick]
  )

  return <Container>{itemViews}</Container>
})

ContextMenuView.displayName = 'ContextMenuView'

export default ContextMenuView
