import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import createTheme from 'src/theme'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'

const { colors } = createTheme()

interface IProps {
  className?: string
  fullScreen?: boolean
  fadesIn?: boolean
}

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const FullScreenContainer = styled.div<{ $fadesIn: boolean }>`
  position: fixed;
  inset: 0;
  z-index: ${zIndex.loadingSpinner.fullScreenContainer};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 50%);
  opacity: 0;

  ${props => {
    if (props.$fadesIn) {
      return css`
        animation-name: ${fadeInKeyFrames};
        animation-duration: 200ms;
        animation-timing-function: ease-in;
        animation-delay: 200ms;
        animation-fill-mode: forwards;
      `
    }
  }}
`

export const dataAttrs = {
  spinner: () => 'moon-loader'
}

const LoadingSpinner = React.memo((props: IProps) => {
  const { fullScreen, fadesIn } = props

  const loader: any = (
    <MoonLoader data-testid={dataAttrs.spinner()} color={colors.main40} />
  )

  if (fullScreen) {
    return (
      <FullScreenContainer $fadesIn={fadesIn}>{loader}</FullScreenContainer>
    )
  }

  return loader
})

LoadingSpinner.displayName = 'LoadingSpinner'

export default LoadingSpinner
