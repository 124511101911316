import React from 'react'
import styled, { css } from 'styled-components'
import { HrAdminBlockAppearance } from 'src/features/HrAdmin/components/Block'

type BlockContentValueDifferenceType = 'none' | 'up' | 'down'

export interface IBlockContentValue {
  value: string
  difference?: number
  appearance?: HrAdminBlockAppearance
  showsPlaceholder?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ValueText = styled.span<{ $appearance: string }>`
  line-height: 130%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 24px;
          font-weight: 500;
        `
      : css`
          font-size: 18px;
          font-weight: bold;
        `}

  ${(props: any) => {
    const { $appearance } = props
    switch ($appearance) {
      case 'active':
        return css`
          color: ${props.theme.colors.light100};
        `
      case 'blueValue':
        return css`
          color: ${props.theme.colors.main100};
          ${props.theme.isDesktop
            ? css`
                font-size: 32px;
                font-weight: bold;
                margin-top: -8px;
              `
            : css`
                font-size: 18px;
                font-weight: 500;
              `}
        `
      default:
        return css`
          color: ${props.theme.colors.dark80};
        `
    }
  }}
`

const DifferenceText = styled.span<{ $type: string }>`
  font-weight: 500;
  font-size: 16px;
  margin: 0 4px 0 12px;
  color: ${props =>
    props.$type === 'up'
      ? props.theme.colors.success100
      : props.theme.colors.error60};
`

const ArrowUp = styled.div`
  border-style: solid;
  border-width: 0 5px 5px;
  border-color: transparent transparent
    ${props => props.theme.colors.success100} transparent;
  margin-bottom: 10px;
`

const ArrowDown = styled.div`
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: ${props => props.theme.colors.error60} transparent transparent
    transparent;
  margin-top: 8px;
`

export const BlockContentValue = React.memo((props: IBlockContentValue) => {
  const { value, difference, appearance, showsPlaceholder } = props

  let differenceType: BlockContentValueDifferenceType = 'none'
  if (difference > 0) {
    differenceType = 'up'
  } else if (difference < 0) {
    differenceType = 'down'
  }

  return (
    <Container>
      <ValueText $appearance={appearance}>
        {showsPlaceholder ? '-' : value}
      </ValueText>
      {differenceType && (
        <React.Fragment>
          <DifferenceText $type={differenceType}>{difference}</DifferenceText>
          {differenceType === 'up' && <ArrowUp />}
          {differenceType === 'down' && <ArrowDown />}
        </React.Fragment>
      )}
    </Container>
  )
})

BlockContentValue.displayName = 'BlockContentValue'

export default BlockContentValue
