import React from 'react'
import { IUseSessions } from 'src/graphql/hooks/useSessions'

export interface IUserProfileContext {
  user?: IUser
  leave: ILeave
  fieldErrors?: any
  tpaSyncing: boolean
  hasPlanToCloseOut: boolean
  hasPlanHistory: boolean
  updatePersonalEmail: (value: string) => void
  updatePhoneNumber: (value: string) => void
  sendPersonalEmailConfirmationCode: (value: string) => void
  sendPhoneNumberConfirmationCode: (value: string) => void
  resendConfirmationCode: (kind: string) => void
  updateNotificationSettings: (
    notificationSettings: INotificationSettingsInput[]
  ) => void
  closeOutPlan: (
    shouldAddNotifyManagerField: boolean,
    notifyManager: boolean
  ) => void
  toggleTpaSyncing: () => void
  activateManagerForLeave: () => void
  onSessionSectionAppeared: () => void
  revokeSession: (sessionId: string) => void
  revokeAllSessions: () => void
  sessions: ISession[]
  sessionsResult: IUseSessions
  revokingSessionsId: string[]
  planHistory: ILeave[]
  removeLeave: (id: string) => void
  showTpaLastUpdate: boolean
}

const UserProfileContext = React.createContext(null)

export default UserProfileContext
