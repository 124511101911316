import React from 'react'
import styled from 'styled-components'
import Arrow from 'src/components/Popover/components/Arrow'

interface IProps {
  name: string
  hasOverdue: boolean
  isOpen: boolean
  hidesArrow?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const OverdueDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.theme.colors.error40};
  margin-right: 10px;
`

const Name = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const ArrowWrapper = styled(Arrow)`
  margin-left: 4px;
`

const MobileHeaderEmployee = React.memo((props: IProps) => {
  const { name, hasOverdue, isOpen, hidesArrow } = props
  return (
    <Container>
      {hasOverdue && <OverdueDot />}
      <Name>{name}</Name>
      {!hidesArrow && <ArrowWrapper isOpen={isOpen} />}
    </Container>
  )
})

MobileHeaderEmployee.displayName = 'MobileHeaderEmployee'

export default MobileHeaderEmployee
