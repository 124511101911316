import React, { useCallback, useEffect, useMemo } from 'react'
import moment, { Moment } from 'moment'
import { IMomentCurrentMinMax } from 'src/react-app-env'
import { Trans, useTranslation } from 'react-i18next'
import DefaultStyle from './styles/Default'
import LeaveHolidays from 'src/features/Leave/holidays'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

interface IProps {
  momentCurrentMinMax: IMomentCurrentMinMax
  highlightedDate?: Moment
  defaultActiveStartDate?: Moment
  onChange: (date: Moment | Moment[]) => void
  onOpened?: (date: Moment) => void
  activeStartDate?: Moment
  onActiveStartDateChange?: (...args: any[]) => void
  style?: any
  holidays?: LeaveHolidays
  disableWeekends?: boolean
  disabledDays?: Moment[]
}

const Text = styled.div`
  display: flex;
  gap: 3px;
  align-items: baseline;
  line-height: 16px;
  font-weight: normal;
  font-size: 12px;
  color: ${props => props.theme.colors.dark60};
`

export const TextMarkdown = styled(ReactMarkdown)`
  line-height: 16px;
  font-weight: normal;
  font-size: 12px;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HolidayNote = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 5px 16px 0;
        `
      : css`
          margin: 12px 16px 0;
          white-space: nowrap;
        `}
`

const HolidayDot = styled.span<{ $marginAuto: boolean; $size: number }>`
  ${props =>
    props.$marginAuto &&
    css`
      margin: auto;
      margin-bottom: -6px;
    `}

  display: block;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  min-width: ${props => props.$size}px;
  min-height: ${props => props.$size}px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.light100};
  background: ${props => props.theme.colors.timelineIncome66};
`

export const propsAreEqual = (prev: IProps, next: IProps): boolean => {
  try {
    if (prev.momentCurrentMinMax.current && next.momentCurrentMinMax.current) {
      if (
        !prev.momentCurrentMinMax.current.isSame(
          next.momentCurrentMinMax.current
        )
      ) {
        return false
      }
    } else if (
      !prev.momentCurrentMinMax.current &&
      next.momentCurrentMinMax.current
    ) {
      return false
    } else if (
      prev.momentCurrentMinMax.current &&
      !next.momentCurrentMinMax.current
    ) {
      return false
    }

    if (!prev.momentCurrentMinMax.min.isSame(next.momentCurrentMinMax.min)) {
      return false
    }
    if (!prev.momentCurrentMinMax.max.isSame(next.momentCurrentMinMax.max)) {
      return false
    }
    if (prev.style !== next.style) {
      return false
    }
    if (prev.onChange !== next.onChange) {
      return false
    }

    if (prev.onActiveStartDateChange !== next.onActiveStartDateChange) {
      return false
    }
    if (!prev.highlightedDate.isSame(next.highlightedDate)) {
      return false
    }
    if (prev.defaultActiveStartDate !== next.defaultActiveStartDate) {
      return false
    }
    if (prev.disabledDays !== next.disabledDays) {
      return false
    }
    if (prev.disableWeekends !== next.disableWeekends) {
      return false
    }
    if (prev.holidays !== next.holidays) {
      return false
    }
    if (prev.onOpened !== next.onOpened) {
      return false
    }
  } catch (_) {
    return false
  }

  return true
}

export const dataAttrs = {
  holidayDot: () => `calendar-holiday-dot`,
  holidayNote: () => `calendar-holiday-note`
}

const Calendar = React.memo((props: IProps) => {
  const {
    momentCurrentMinMax,
    onChange,
    activeStartDate,
    onActiveStartDateChange,
    style,
    holidays,
    highlightedDate,
    onOpened,
    defaultActiveStartDate,
    disableWeekends,
    disabledDays
  } = props

  const { t } = useTranslation()

  const focusDate = useMemo(
    () => momentCurrentMinMax.current || activeStartDate || moment(),
    [momentCurrentMinMax, activeStartDate]
  )

  useEffect(() => {
    if (onOpened) {
      onOpened(focusDate)
    }
    // eslint-disable-next-line
  }, [])

  const getTileClassname = useCallback(
    (date: Date) => {
      if (
        highlightedDate &&
        highlightedDate.isSame(moment(date).utc(true), 'day') &&
        !highlightedDate.isSame(momentCurrentMinMax.current)
      ) {
        return 'highlighted__tile'
      }

      return ''
    },
    [highlightedDate, momentCurrentMinMax]
  )

  const getTileDisabled = useCallback(
    (input: any) => {
      if (input.view === 'month' && disableWeekends) {
        const day = input.date.getDay()
        return day === 0 || day === 6
      }
      if (!disabledDays) {
        return false
      }

      const mDate: Moment = moment(input.date).utc(true)

      return disabledDays.some((m: any) => m.isSame(mDate, 'day'))
    },
    [disabledDays, disableWeekends]
  )

  const tileContentCallback = useCallback(
    ({ date, view }) => {
      if (!holidays || view !== 'month') {
        return null
      }

      return holidays.isHoliday(date) ? (
        <HolidayDot
          $marginAuto
          $size={4}
          data-testid={dataAttrs.holidayDot()}
        />
      ) : null
    },

    [holidays]
  )

  const holidaysNote = useMemo(() => {
    const currentYear = moment().utc().year()
    return (
      <HolidayNote data-testid={dataAttrs.holidayNote()}>
        <Text>
          <Trans
            i18nKey="calendar.holidayNote"
            components={[<HolidayDot key={0} $marginAuto={false} $size={6} />]}
          />
        </Text>
        <TextMarkdown
          urlTransform={TelUrlTransformer}
          components={{ a: LinkRenderer }}
        >
          {t('calendar.holidayLink', { currentYear })}
        </TextMarkdown>
      </HolidayNote>
    )
  }, [t])

  const ariaLabels: any = useMemo(() => {
    const keys: string[] = [
      'nextAriaLabel',
      'next2AriaLabel',
      'prevAriaLabel',
      'prev2AriaLabel'
    ]

    const result: any = {}
    keys.forEach((key: string) => {
      result[key] = t(`calendar.accessibility.${key}`)
    })

    return result
  }, [t])

  const onDateChange = (newDate: Date | Date[]) => {
    if (!Array.isArray(newDate)) {
      onChange(
        moment.utc({
          years: newDate.getFullYear(),
          month: newDate.getMonth(),
          date: newDate.getDate()
        })
      )
    } else {
      const moments: Moment[] = newDate.map((d: Date) =>
        moment.utc({
          years: d.getFullYear(),
          month: d.getMonth(),
          date: d.getDate()
        })
      )
      onChange(moments)
    }
  }

  const momentToDate = (m: Moment): Date => {
    if (!m) {
      return null
    }
    return new Date(
      m.year(),
      m.month(),
      m.date(),
      m.hours(),
      m.minutes(),
      m.seconds()
    )
  }

  const Component: any = style ? style : DefaultStyle

  return (
    <ComponentWrapper>
      <Component
        {...ariaLabels}
        calendarType="gregory"
        locale="en-US"
        value={
          momentCurrentMinMax.current
            ? momentToDate(momentCurrentMinMax.current)
            : null
        }
        tileContent={holidays ? tileContentCallback : null}
        minDate={momentToDate(momentCurrentMinMax.min)}
        maxDate={momentToDate(momentCurrentMinMax.max)}
        onChange={onDateChange}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        tileClassName={(p: any) => getTileClassname(p.date)}
        defaultActiveStartDate={momentToDate(defaultActiveStartDate)}
        tileDisabled={getTileDisabled}
      />
      {holidays && holidaysNote}
    </ComponentWrapper>
  )
}, propsAreEqual)

Calendar.displayName = 'Calendar'

export default Calendar
