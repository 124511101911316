import React from 'react'
import { useNavigate } from 'react-router-dom'
import { timelineRoute } from 'src/routes/constants'
import { TransitionStage } from '../../TransitionFlowContainer'
import TransitionStepsView from '../TransitionStepsView'

interface IProps {
  name: TransitionStage
}

export const FinalActionView = React.memo((props: IProps) => {
  const { name } = props
  const navigate = useNavigate()
  return (
    <TransitionStepsView
      name={name}
      onNextClicked={() => navigate(timelineRoute)}
      hidesCancelButton
      nextButtonTextKey={`transitionFlow.pages.${name}.nextButtonText`}
    />
  )
})

FinalActionView.displayName = 'FinalActionView'

export default FinalActionView
