import styled, { css } from 'styled-components'

export default styled.button<{ $selected: boolean }>`
  transition: background 100ms ease;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 29px;
  cursor: pointer;
  text-align: center;
  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: 5px;
    `}

  ${(props: any) =>
    props.$selected
      ? css`
          background: ${props.theme.colors.main100};
          color: ${props.theme.colors.light100};
        `
      : css`
          background: transparent;
          color: ${props.theme.colors.dark60};
          box-shadow: 0 0 0 1px ${props.theme.colors.dark60};
        `}

  &:not(:first-child) {
    margin-left: 6px;
  }

  &:hover {
    ${(props: any) =>
      props.$selected
        ? css`
            background: ${props.theme.colors.main110};
          `
        : css`
            background: transparent;
          `}
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px
      ${props =>
        props.$selected
          ? props.theme.colors.main110
          : props.theme.colors.dark60};
  }

  &:disabled {
    color: ${props => props.theme.colors.light100};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.main40};
    background: ${props => props.theme.colors.main40};
  }
`
