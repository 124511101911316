import { useMutation } from '@apollo/client'
import { mutationCreateMetric } from 'src/graphql/queries'
import { useState } from 'react'

export interface IUseCreateMetricArgs {
  eventType: string
  id?: string
}

const useCreateMetric = (args: IUseCreateMetricArgs, skip = false): boolean => {
  const [sent, setSent] = useState<boolean>(false)

  const { eventType, id } = args
  const variables: IMutationCreateMetricArgs = { eventType }
  if (id) {
    variables.id = id
  }
  const [createMetric, { error, data }] = useMutation<{
    createMetric: boolean
  }>(mutationCreateMetric, {
    variables
  })

  if (error) {
    return false
  }

  if (!skip && !sent) {
    setSent(true)
    createMetric()
  }

  return data?.createMetric
}

export default useCreateMetric
