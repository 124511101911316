import { IPeriodStatusAttr } from 'src/features/Timeline/components/common/PeriodView'
import {
  IIconName,
  ITimelinePeriod,
  ITimelinePeriodBlock
} from 'src/react-app-env'
import moment from 'moment'
import createTheme from 'src/theme'
import { isPublishedOrBabyArrivedLeave } from './leaveStatusUtils'
import { ICustomerConfig } from 'src/config/customers/config'
import { isSyncingLeaveAndClaimExists } from './leaveUtils'

const { colors } = createTheme()

const notManagedTpaTypes = ['Annual', 'RampBack', 'UnpaidParental', 'Personal']
const holidayTypes = ['PublicHolidays', 'AppliedHolidays']

const isWaitingPeriodClaimStatus = (status: IPeriodClaimStatus): boolean =>
  status === 'Pending' || status === 'InReview'

export const hasTpaCurrentWaitingStatuses = (tpaChanges: ITpaChange): boolean =>
  tpaChanges?.current?.some((currentItem: ITpaChangeItem) =>
    isWaitingPeriodClaimStatus(currentItem?.status)
  )

const convertTpaChangeItem = (
  change: ITpaChangeItem,
  leaveType: ILeaveType,
  t: any
): any => ({
  name: t([
    `planChange.date.${leaveType}.${change.type}`,
    `planChange.date.${change.type}`,
    `timeline.periodByTimelinePeriodType.${change.type}_${leaveType}`,
    `timeline.periodByTimelinePeriodType.${change.type}`
  ]),
  value: change.value,
  groupWithPrevious: holidayTypes.includes(change.type),
  showAsterisk: notManagedTpaTypes.includes(change.type),
  status: change?.status,
  isWaitingStatus: isWaitingPeriodClaimStatus(change?.status)
})

const convertTpaSyncItem = (
  change: ITpaSyncItem,
  leaveType: ILeaveType,
  t: any
): any => ({
  name: t([
    `planChange.date.${leaveType}.${change.type}`,
    `planChange.date.${change.type}`,
    `timeline.periodByTimelinePeriodType.${change.type}_${leaveType}`,
    `timeline.periodByTimelinePeriodType.${change.type}`
  ]),
  value: change.value,
  id: change.itemId,
  isSynced: change.isSynced,
  groupWithPrevious: holidayTypes.includes(change.type)
})

export const convertTpaChangeItems = (
  items: ITpaChangeItem[],
  leaveType: ILeaveType,
  t: any
): any[] => items?.map(change => convertTpaChangeItem(change, leaveType, t))

export const convertTpaSyncItems = (
  items: ITpaSyncItem[],
  leaveType: ILeaveType,
  t: any
): any[] => items?.map(change => convertTpaSyncItem(change, leaveType, t))

export const isPeriodSynced = (period: any): boolean => period.mode === 'Synced'

export const isPeriodReadOnly = (period: any): boolean =>
  period.mode === 'ReadOnly'

export const isInformationalPeriod = (period: any): boolean =>
  period.mode === 'InfoNotice'

export const getPeriodKeyDate = (period: ITimelinePeriod): string => {
  const { periodStart, periodEnd, timelineConfig } = period

  if (periodStart?.min?.isSame(periodStart.max)) {
    return 'end'
  }
  if (periodEnd?.min?.isSame(periodEnd.max)) {
    return 'start'
  }

  if (timelineConfig?.periodKeyDate) {
    const periodKeyDate = timelineConfig.periodKeyDate
    let keyDate = ''
    if (periodKeyDate === 'periodStart') {
      keyDate = 'start'
    } else if (periodKeyDate === 'periodEnd') {
      keyDate = 'end'
    }
    return keyDate
  }

  return ''
}

export const isBondingPeriodNotSynced = (
  leave: ILeave,
  period: ITimelinePeriod
): boolean => {
  const babyBondingNotSynced: boolean =
    period.mode !== 'Synced' &&
    period.type === 'BabyBonding' &&
    period.appearance === 'Standard'

  return isSyncingLeaveAndClaimExists(leave) && babyBondingNotSynced
}

export const isPeriodNotSynced = (
  leave: ILeave,
  period?: ITimelinePeriod
): boolean =>
  isSyncingLeaveAndClaimExists(leave) &&
  period.mode !== 'Synced' &&
  !notManagedTpaTypes.includes(period.type) &&
  (period.appearance === 'Standard' || period.appearance === 'Period')

export const isPeriodNotManagedTpa = (
  leave: ILeave,
  period: ITimelinePeriod
): boolean =>
  isSyncingLeaveAndClaimExists(leave) &&
  isPublishedOrBabyArrivedLeave(leave.status) &&
  notManagedTpaTypes.includes(period.type)

export const isBondingPeriodInPast = (
  leave: ILeave,
  period: ITimelinePeriod
): boolean => {
  const isLeaveSyncingAndBabyBondingNotSynced =
    isSyncingLeaveAndClaimExists(leave) &&
    period.mode !== 'Synced' &&
    period.type === 'BabyBonding' &&
    period.appearance === 'Standard'

  if (
    !isLeaveSyncingAndBabyBondingNotSynced ||
    !isPublishedOrBabyArrivedLeave(leave.status)
  ) {
    return false
  }

  const periodStartDate = moment(period.periodStart.current).utc()
  const today = moment().utc()

  return periodStartDate.isBefore(today, 'day')
}

export const isEqualActiveDays = (
  firstActiveDays?: string[],
  secondActiveDays?: string[]
) => {
  if (!firstActiveDays || !secondActiveDays) {
    return true
  }

  if (firstActiveDays.length !== secondActiveDays.length) {
    return false
  }
  const firstCompareDays = firstActiveDays.slice().sort().join(',')
  const secondCompareDays = secondActiveDays.slice().sort().join(',')
  return firstCompareDays === secondCompareDays
}

export const hasApproximatePayment = (period: ITimelinePeriod): boolean =>
  period.blocks?.some(
    (block: ITimelinePeriodBlock) => block.isApproximatePayment
  )

export const getPeriodStatusAttr = (
  leave: ILeave,
  period: ITimelinePeriod,
  customerConfig: ICustomerConfig,
  t: (key: string | string[]) => string
): IPeriodStatusAttr => {
  const getStatus = (
    iconName: IIconName,
    iconColor: string,
    tooltipTitle: string,
    tooltipBody: string
  ): IPeriodStatusAttr => ({
    iconName,
    iconColor,
    tooltipTitle,
    tooltipBody
  })

  if (isPeriodSynced(period)) {
    return getStatus(
      'lock_small',
      colors.purple,
      t('timeline.periodStatusTooltip.autoUpdated.title'),
      t('timeline.periodStatusTooltip.autoUpdated.body')
    )
  }

  if (customerConfig.leave.tpaSync.isPeriodNotUpdated(leave, period)) {
    return getStatus(
      'exclamation_sign_inverted',
      colors.error80,
      t('timeline.periodStatusTooltip.notUpdatedInThePast.title'),
      t('timeline.periodStatusTooltip.notUpdatedInThePast.body')
    )
  }

  if (customerConfig.leave.tpaSync.isPeriodNotSynced(leave, period)) {
    return getStatus(
      'unlock_small',
      colors.purple,
      t('timeline.periodStatusTooltip.notAutoUpdated.title'),
      t([
        `timeline.periodStatusTooltip.notAutoUpdated.body_${leave.type}`,
        'timeline.periodStatusTooltip.notAutoUpdated.body'
      ])
    )
  }

  if (customerConfig.leave.tpaSync.isPeriodNotManagedTpa(leave, period)) {
    return getStatus(
      'no_sync',
      colors.purple,
      t('timeline.periodStatusTooltip.notManaged.title'),
      t('timeline.periodStatusTooltip.notManaged.body')
    )
  }

  return {}
}
