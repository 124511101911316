import { gql } from '@apollo/client'

const partsConfirmations = gql`
  fragment partsConfirmations on User {
    confirmations {
      type
      validTill
      confirmedAt
      blockedTill
    }
  }
`

const partsMe = gql`
  fragment partsMe on User {
    id
    firstName
    lastName
    employeeNumber
    email
    phoneNumber
    personalEmail
    managerEmail
    roles
    timezone
    countryCode
    countryState
    ...partsConfirmations
    notificationSettings {
      type
      channels {
        corporateEmail {
          selected
          enabled
        }
        personalEmail {
          selected
          enabled
        }
        phoneNumber {
          selected
          enabled
        }
      }
    }
  }
  ${partsConfirmations}
`

const partsLeaveBasic = gql`
  fragment partsLeaveBasic on Leave {
    id
    type
    subtype
    dueDate
    status
    managerActivated
    note
    approvedByTPA
    isDirty
    transitions {
      type
      extra
    }
  }
`

const partsLeaveMetadata = gql`
  fragment partsLeaveMetadata on Leave {
    metadata
  }
`

const partsLeaveTpa = gql`
  fragment partsLeaveTpa on Leave {
    tpa {
      syncing
      changedAt
      syncFailedAt
      syncedAt
      tpaSyncedAt
      tpaLeaveStatus
      changes {
        status
        previous {
          status
          type
          value
        }
        current {
          status
          type
          value
        }
      }
    }
  }
`

const partsManagerActivationInfo = gql`
  fragment partsManagerActivationInfo on Leave {
    managerActivationInfo {
      startDate
      endDate
      timeline {
        type
        startDate
        endDate
      }
    }
  }
`

const partsLeaveDates = gql`
  fragment partsLeaveDates on Leave {
    dueDate
    dates {
      leaveStart {
        current
        min
        max
      }
      disabilityEnd {
        current
        min
        max
      }
      leaveEnd {
        current
        min
        max
      }
      gradualReturnStartDate {
        current
        min
        max
      }
      activeDutyEndDate {
        current
        min
        max
      }
    }
  }
`

const partsLeaveTimeline = gql`
  fragment partsLeaveTimeline on Leave {
    ...partsLeaveBasic
    ...partsLeaveDates
    note
    metadata
    unusedBonding {
      durationType
      unusedCalendarDays
      unusedDays
    }
    timeline {
      itemID
      refItemID
      type
      startDate
      endDate
      isRemovable
      isAdjusted
      isDirty
      claimStatus
      convertibleToType
      daysOnLeave
      mode
      appearance
      disabledDates {
        dates
      }
      rates {
        rate
        startDate
      }
      schedule {
        balance
        durationType
        hoursMaximumAllowed
        hoursTotalUnused
        hoursUsedBeforePeriod
        hoursUsedInPeriod
        maximumAllowed
        totalUnused
        totalUnusedCalendarDays
        usedInPeriod
        usedInPeriodCalendarDays
        weekOneStart
        weekOne
        weekTwo
      }
      periodStart {
        current
        min
        max
      }
      periodEnd {
        current
        min
        max
      }
      blocks {
        type
        duration
        durationType
        incomeType
        incomeValue
        jobProtected
        programNames
        requirements
        activeDays
        activeDaysWeekOne
        activeDaysWeekTwo
        unpaidDays
        startDate
        endDate
      }
      details {
        leavePrograms {
          name
          payment
          jobProtection
        }
        holidays {
          name
          observed
        }
        requirements
        infoNotice
      }
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveDates}
`

const partsLeaveJourneyMap = gql`
  fragment partsLeaveJourneyMap on Leave {
    ...partsLeaveBasic
    ...partsLeaveMetadata
    ...partsLeaveDates
    journeyMap {
      id
      phase
      type
      priority
      headline
      lead
      completed
      dueDate
      content
      cta {
        text
        link
      }
      attachments {
        text
        link
        type
      }
    }
  }
  ${partsLeaveBasic}
  ${partsLeaveMetadata}
  ${partsLeaveDates}
`

const partsAbsences = gql`
  fragment partsAbsences on Absence {
    id
    employee {
      id
      name
    }
    startDate
    endDate
    createdAt
    emailNotifications
    managers
    archived
    journeyMap {
      id
      type
      priority
      headline
      lead
      completed
      dueDate
      content
      completedAt
      completedBy
      cta {
        text
        link
      }
      attachments {
        text
        link
        type
      }
    }
  }
`

const partsAbsencesDashboard = gql`
  fragment partsAbsencesDashboard on Absence {
    id
    startDate
    outdated
    endDate
    archived
    emailNotifications
    managers
    employee {
      id
      name
    }
    info {
      currentPeriod {
        type
        startDate
        endDate
      }
      nextPeriod {
        type
        startDate
        endDate
      }
    }
    journeyMap {
      id
      type
      priority
      headline
      lead
      completed
      dueDate
      content
      completedAt
      completedBy
    }
    timeline {
      type
      startDate
      endDate
    }
    timelineHistoryDates {
      id
      createdAt
    }
  }
`

const partsConfiguration = gql`
  fragment partsConfiguration on Config {
    leaveTypes {
      type
      subtypes
      group
      enabled
      hidden
      limits
      creationWorkflow
      eligibility {
        reason
        status
      }
    }
  }
`

const partsCountryConfiguration = gql`
  fragment partsCountryConfiguration on CountryConfig {
    countryCode
    leaveTypes {
      type
      group
    }
  }
`

const partsAnalyticsChart = gql`
  fragment partsAnalyticsChart on AnalyticsChart {
    kind
    style
    data {
      date
      value
    }
  }
`

const partsHolidays = gql`
  fragment partsHolidays on Holiday {
    observed
  }
`

export {
  partsConfiguration,
  partsCountryConfiguration,
  partsLeaveJourneyMap,
  partsLeaveTimeline,
  partsLeaveDates,
  partsLeaveMetadata,
  partsLeaveTpa,
  partsManagerActivationInfo,
  partsLeaveBasic,
  partsMe,
  partsConfirmations,
  partsAbsences,
  partsAbsencesDashboard,
  partsAnalyticsChart,
  partsHolidays
}
