import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ITimelineTimeViewProps } from 'src/features/Timeline/components/vertical/TimeView'
import styled, { css } from 'styled-components'
import TitleView from 'src/features/Timeline/components/common/TitleView'
import BlockView from './components/BlockView'
import BlockBackgroundView from './components/BlockBackgroundView'
import {
  IIconName,
  ITimelinePeriod,
  ITimelinePeriodBlock
} from 'src/react-app-env'
import useBlocksToRender from './components/useBlocksToRender'
import isEqual from 'lodash.isequal'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import ScreenContext from 'src/contexts/ScreenContext'
import Tooltip from 'src/components/Tooltip'

export interface IPeriodStatusAttr {
  iconName?: IIconName
  iconColor?: string
  tooltipTitle?: string
  tooltipBody?: string
}

interface IProps {
  period: ITimelinePeriod
  timeViewProps?: ITimelineTimeViewProps
  overriddenDescription?: string
  startTitleBold?: boolean
  unselected?: boolean
  selected?: boolean
  statusAttr?: IPeriodStatusAttr
  claimStatus?: IPeriodClaimStatus
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const PeriodsContainer = styled.ul`
  width: 100%;
  margin: 16px 0 0;
`
const Description = styled.span`
  color: ${props => props.theme.colors.dark60};
  margin-top: 12px;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          line-height: 19px;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
        `}

  @media print {
    font-size: 16px;
    line-height: 19px;
  }
`

const PeriodNoticeContainer = styled.div`
  display: flex;
  padding: 8px 10px;
  margin-bottom: 8px;
  align-items: center;
  align-content: center;
  gap: 6px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.main20};
  background: ${props => props.theme.colors.main05};
`

const PeriodNoticeText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  color: ${props => props.theme.colors.dark60};
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`

let forceRerenderTimeoutId: number

const PeriodView = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      period,
      overriddenDescription,
      startTitleBold,
      unselected,
      selected,
      statusAttr,
      claimStatus
    } = props
    const { t } = useTranslation()
    const { isMobile } = useContext(ScreenContext)
    const { blocks } = period

    const forceUpdate = useForceUpdate()
    const blocksToRender: ITimelinePeriodBlock[] = useBlocksToRender(
      blocks,
      isMobile
    )

    useEffect(
      () => () => {
        clearTimeout(forceRerenderTimeoutId)
      },
      []
    )

    // force re-render after animations finished playing
    // to remove visible removed elements
    useEffect(() => {
      if (isMobile) {
        return
      }
      forceRerenderTimeoutId = window.setTimeout(() => {
        if (!isEqual(blocksToRender, blocks)) {
          forceUpdate()
        }
      }, TIMELINE_DETAILS_PUSH_DURATION)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocks])

    const description: string = useMemo(
      () => overriddenDescription || period.durationString,
      [overriddenDescription, period.durationString]
    )

    const getLongTermAbsence = useMemo(
      () =>
        period.type === 'LongTermAbsence' && (
          <Description>{t('timeline.longTermAbsenceDescription')}</Description>
        ),
      [period.type, t]
    )

    const isUnusedBlockFirst = useMemo(
      () => blocksToRender.length > 0 && blocksToRender[0].isUnused,
      [blocksToRender]
    )
    const isUnusedBlockLast = useMemo(
      () =>
        blocksToRender.length > 0 &&
        blocksToRender[blocksToRender.length - 1].isUnused,
      [blocksToRender]
    )

    const periodNotice = useMemo(() => {
      const notice = period?.timelineConfig?.periodTopNotice
        ? period.timelineConfig.periodTopNotice(t, isMobile)
        : null

      return (
        notice && (
          <PeriodNoticeContainer>
            <PeriodNoticeText>
              {notice.message}
              <Tooltip body={notice.tooltip} offset={[20, 15]}>
                {notice.date}
              </Tooltip>
            </PeriodNoticeText>
          </PeriodNoticeContainer>
        )
      )
    }, [t, period, isMobile])

    return (
      <BlockBackgroundView
        period={period}
        unselected={unselected}
        ref={ref}
        selected={selected}
      >
        <Container>
          <TitleView
            title={period.timelineConfig.title}
            description={description}
            duration={period.timelineConfig.duration?.shortString}
            titleBold={startTitleBold}
            statusAttr={statusAttr}
            claimStatus={claimStatus}
          >
            {blocksToRender.length !== 0 && (
              <PeriodsContainer
                role={'list'}
                aria-label={t('timeline.accessibility.regionBlocks')}
              >
                {periodNotice}
                {blocksToRender.map(
                  (block: ITimelinePeriodBlock, index: number) => {
                    const { key, ...otherBlockParams } = block
                    return (
                      <BlockView
                        key={key}
                        isFirst={index === +isUnusedBlockFirst}
                        isLast={
                          index ===
                          blocksToRender.length - 1 - +isUnusedBlockLast
                        }
                        {...otherBlockParams}
                        {...period.schedule}
                      />
                    )
                  }
                )}
              </PeriodsContainer>
            )}
          </TitleView>
          {getLongTermAbsence}
        </Container>
      </BlockBackgroundView>
    )
  })
)

PeriodView.displayName = 'PeriodView'

export default PeriodView
