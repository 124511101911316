import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Icon } from 'src/UIKit'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import { ILeaveListItemProps } from 'src/features/Leave/components/PickLeaveView/components/LeavesList'

const Container = styled.div<{ $enabled: boolean }>`
  position: relative;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark10};
  box-sizing: border-box;
  box-shadow: 0 0 20px rgb(98 106 116 / 5%);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  cursor: ${(props: any) => (props.$enabled ? 'pointer' : 'unset')};
  min-height: 65px;
  width: 100%;

  &:not(:first-child) {
    margin: 8px 0 0;
  }
`

const ItemClickOverlay = styled.button`
  z-index: ${zIndex.welcome.itemOverlay};
  border-radius: 8px;
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  &:focus {
    border: 2px solid ${props => props.theme.colors.main100};
    outline: none;
  }
`

const DisabledOverlay = styled.div`
  background: ${props => props.theme.colors.light100};
  border-radius: 8px;
  opacity: 0.7;
  z-index: ${zIndex.welcome.disabledOverlay};
  position: absolute;
  inset: 0;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px 0 12px 12px;
  gap: 4px;
`

export const ArrowContainer = styled.div`
  min-width: ${props => (props.theme.isDesktop ? '40px' : '32px')};
  max-width: ${props => (props.theme.isDesktop ? '40px' : '32px')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Type = styled.p`
  color: ${props => props.theme.colors.dark80};
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark50};
  font-size: 12px;
  line-height: 130%;
  font-weight: normal;
`

export const dataAttrs = {
  pickGroup: (name: string) => `pick-leave-pick-group-${name}`,
  type: () => 'pick-leave-type-item',
  description: () => 'pick-leave-description-item'
}

export const LeavesListItem = React.memo((props: ILeaveListItemProps) => {
  const { name, enabled, onSelected } = props
  const { t } = useTranslation()

  const typeText: string = t(`createLeave.groupsData.${name}.type`)
  const descriptionText: string = t(
    `createLeave.groupsData.${name}.description`
  )

  const onSelectedCallback = useCallback(() => {
    onSelected(name)
  }, [onSelected, name])

  return (
    <Container $enabled={enabled}>
      <ContentContainer>
        <Type data-testid={dataAttrs.type()}>{typeText}</Type>
        <Description
          data-testid={dataAttrs.description()}
          aria-label={t('common.accessibilityText.createLeaveDetails', {
            type: typeText,
            description: descriptionText
          })}
        >
          {descriptionText}
        </Description>
      </ContentContainer>
      <ArrowContainer>
        <Icon name={'arrow_forward'} />
      </ArrowContainer>
      <ItemClickOverlay
        data-testid={dataAttrs.pickGroup(name)}
        onClick={onSelectedCallback}
        aria-label={t('common.accessibilityText.createLeaveButton', {
          type: typeText
        })}
      />
      {!enabled && <DisabledOverlay />}
    </Container>
  )
})

LeavesListItem.displayName = 'LeavesListItem'

export default LeavesListItem
