import React from 'react'
import AnalyticsUsers from 'src/features/HrAdmin/components/sections/Analytics/components/AnalyticsUsers'
import AnalyticsActiveUsers from 'src/features/HrAdmin/components/sections/Analytics/components/AnalyticsActiveUsers'
import ManagerSupportActivation from 'src/features/HrAdmin/components/sections/Analytics/components/ManagerSupportActivation'
import styled, { css } from 'styled-components'

const Container = styled.div`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0 0 2px;
    `}
`

export const AnalyticsSection = () => (
  <Container>
    <AnalyticsUsers />
    <AnalyticsActiveUsers />
    <ManagerSupportActivation />
  </Container>
)

export default AnalyticsSection
