import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getAbsenceTimeline, isCurrentAbsencePeriod } from 'src/utils/absences'
import { getTodayYesterdayOrDate } from 'src/utils/dateUtils'
import styled from 'styled-components'
import AbsencePeriod from './components/AbsencePeriod'
import TimelineRow from './components/TimelineRow'

interface IProps {
  absence: IAbsence
  showCurrent: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${props => props.theme.colors.dark60};
`

const KeyDateContainer = styled.div`
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const KeyDateTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.colors.dark80};
`

const KeyDateValue = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${props => props.theme.colors.dark60};
`

const leaveRow = (title: string, value: string) => (
  <KeyDateContainer>
    <KeyDateTitle>{title}</KeyDateTitle>
    <KeyDateValue>{value}</KeyDateValue>
  </KeyDateContainer>
)

const AbsenceTimeline = React.forwardRef((props: IProps, ref: any) => {
  const { absence, showCurrent } = props
  const { t } = useTranslation()

  const periods = useMemo(() => {
    const timeline = getAbsenceTimeline(absence)
    const blocks = timeline.map(period => {
      switch (period.type) {
        case 'LeaveStart':
          const start = getTodayYesterdayOrDate(
            moment(period.startDate).utc(),
            t
          )
          return leaveRow(t('common.leaveStart'), start)
        case 'LeaveEnd':
          const end = getTodayYesterdayOrDate(moment(period.endDate).utc(), t)
          return leaveRow(t('common.leaveEnd'), end)
        default:
          return <AbsencePeriod period={period} />
      }
    })

    return blocks.map((block, index) => {
      const rowProps = {
        isFirst: index === 0,
        isLast: index === blocks.length - 1,
        isActive: showCurrent && isCurrentAbsencePeriod(timeline[index])
      }

      return (
        <TimelineRow key={index} {...rowProps}>
          {block}
        </TimelineRow>
      )
    })
  }, [t, absence, showCurrent])

  return <Container ref={ref}>{periods}</Container>
})

AbsenceTimeline.displayName = 'AbsenceTimeline'

export default AbsenceTimeline
