import zIndex from 'src/constants/zIndex'
import { css } from 'styled-components'
import { timelineNotice } from 'src/constants/frame'

export const printDisplayNoneMixin = css`
  @media print {
    display: none;
  }
`

export const printBackgroundWhiteMixin = css`
  @media print {
    background: white;
  }
`

export const mobileMaxWidthMixin = css`
  ${props =>
    props.theme.isMobile &&
    css`
      max-width: 640px;
    `}
`

export const mobileButtonMaxWidthMixin = css`
  ${props =>
    props.theme.isMobile &&
    css`
      max-width: 414px;
    `}
`

export const mobileButtonContainerPaddingMixin = css`
  ${props =>
    props.theme.isMobile &&
    css`
      padding-left: calc(50vw - 212px);
      padding-right: calc(50vw - 212px);
    `}
`

export const buttonWrapperAppearanceMixin = css<{
  $disabled: boolean
  $appearance: string
}>`
  ${props =>
    props.$disabled
      ? css`
          cursor: unset;
          color: ${props.theme.button[props.$appearance].color.disabled};
          background: ${props.theme.button[props.$appearance].background
            .disabled};
          border: ${props.theme.button[props.$appearance].border.disabled};
          box-shadow: ${props.theme.button[props.$appearance].boxShadow
            .disabled};
        `
      : css`
          color: ${props.theme.button[props.$appearance].color.normal};
          background: ${props.theme.button[props.$appearance].background
            .normal};
          border: ${props.theme.button[props.$appearance].border.normal};
          box-shadow: ${props.theme.button[props.$appearance].boxShadow.normal};
        `}

  &:hover {
    outline: none;
    ${props =>
      !props.$disabled &&
      props.theme.isDesktop &&
      css`
        color: ${props.theme.button[props.$appearance].color.hover};
        background: ${props.theme.button[props.$appearance].background.hover};
        border: ${props.theme.button[props.$appearance].border.hover};
        box-shadow: ${props.theme.button[props.$appearance].boxShadow.hover};
      `}
  }

  &:focus {
    outline: none;
    ${props =>
      !props.$disabled &&
      css`
        color: ${props.theme.button[props.$appearance].color.focus};
        background: ${props.theme.button[props.$appearance].background.focus};
        border: ${props.theme.button[props.$appearance].border.focus};
        box-shadow: ${props.theme.button[props.$appearance].boxShadow.focus};
      `}
  }

  &:active {
    outline: none;
    ${props =>
      !props.$disabled &&
      props.theme.isMobile &&
      css`
        color: ${props.theme.button[props.$appearance].color.hover};
        background: ${props.theme.button[props.$appearance].background.hover};
        border: ${props.theme.button[props.$appearance].border.hover};
        box-shadow: ${props.theme.button[props.$appearance].boxShadow.hover};
      `}
  }
`
export const timelineNoticeContainerMixin = css<{ $top?: number }>`
  background: ${props => props.theme.colors.main05};
  border: 1px solid ${props => props.theme.colors.main20};
  padding: 10px 12px;
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          top: ${props.$top || 0}px;
          left: 20px;
          z-index: ${zIndex.timeline.footnote};
          max-width: 264px;
          width: calc(50vw - ${p => timelineNotice.widthOffset(p.theme).px});
        `
      : css`
          width: calc(100% - ${p => timelineNotice.widthOffset(p.theme).px});
          margin: 16px auto 0;
          overflow: hidden;
          transition: all 300ms;
          height: auto;
        `}
`

export const timelineNoticeCloseButtonMixin = css`
  transition: border 100ms ease;
  border-radius: 3px;
  margin: -2px 0;

  &:hover {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
  }
`

export const timelineNoticeTextMixin = css`
  color: ${props => props.theme.colors.dark60};
  line-height: 150%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          margin-top: 10px;
        `
      : css`
          font-size: 12px;
          margin-top: 6px;
        `}
`
