import React from 'react'
import styled, { css } from 'styled-components'

export const Circle = styled.div`
  border-radius: 50%;
  background: ${props => props.theme.colors.tax};
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 9px;
          height: 9px;
        `
      : css`
          width: 7px;
          height: 7px;
        `}

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    width: 9px;
    height: 9px;
  }
`

export const dataAttrs = {
  circle: () => 'view-circle'
}

const TimelineTimeViewCircle = React.memo(() => (
  <Circle data-testid={dataAttrs.circle()} />
))

TimelineTimeViewCircle.displayName = 'TimelineTimeViewCircle'

export default TimelineTimeViewCircle
