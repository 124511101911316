import React, {
  SyntheticEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import InputMask from 'react-input-mask'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import usePrevious from 'src/components/hooks/usePrevious'
import { Icon } from 'src/UIKit'
import useForceUpdate from 'src/components/hooks/useForceUpdate'

const { colors } = createTheme()

type InputButtonType = 'Verify' | 'Resend' | 'Remove'

export interface IInputButtonProps {
  type: InputButtonType
  onClick?: () => void
  disabled?: boolean
  submitOnReturnButton?: boolean
}

interface IProps {
  'data-testid'?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: SyntheticEvent) => void
  value: string
  alt: string
  placeholder?: string
  className?: string
  name: string
  type: string
  invalid?: boolean
  verified?: boolean
  verifiedString?: string
  focused?: boolean
  loading?: boolean
  errorString?: string
  mask?: string
  pattern?: string
  disabled?: boolean
  button?: IInputButtonProps
  isConfirmationCode?: boolean
  maskChar?: string
  autoFocus?: boolean
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TopContainer = styled.div<{ $focused: boolean; $invalid: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: ${props => props.theme.colors.light100};
  border: ${props => {
    const color: string = props.$invalid
      ? props.theme.colors.dark20
      : props.$focused
        ? props.theme.colors.main110
        : props.theme.colors.dark50
    return `1px solid ${color}`
  }};
  box-shadow: ${props =>
    props.$focused && `0 0 0 2px ${props.theme.colors.main110}`};
`

const BottomContainer = styled.div`
  display: flex;
`

const rotateKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
}
`

const LoadingImage = styled.div`
  width: 24px;
  height: 24px;
  place-self: center center;
  margin-right: 16px;
  transform-origin: center center;
  animation: ${rotateKeyFrames} 0.7s linear 0s infinite;
`

const ErrorText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin: 4px 0 0;
  color: ${props => props.theme.colors.error80};
`

const fadeOutKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
}
`

const VerifiedText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin: 4px 0 0;
  color: ${props => props.theme.colors.success100};
  animation: ${fadeOutKeyFrames} 5s linear 0s forwards;
`

const Button = styled.button<{
  $color: string
  $disabled: boolean
  $hoverColor: string
  $invalid: boolean
}>`
  background: none;
  border: none;
  user-select: none;
  font-style: normal;
  font-size: 14px;
  padding: 14px 16px;
  color: ${props => props.$color};
  outline: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: initial;

  ${props =>
    !props.$disabled &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: ${props.$hoverColor || props.$color};
      }
    `}

  &:focus {
    outline: none;
    text-decoration: underline;
  }
`

const Label = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0;
`

let forceUpdateTimeoutId: any

const Input = React.forwardRef((props: IProps, ref: any) => {
  const {
    value,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    className,
    name,
    type,
    verified,
    verifiedString,
    loading,
    errorString,
    invalid,
    mask,
    pattern,
    disabled,
    focused,
    button,
    alt,
    isConfirmationCode,
    maskChar,
    autoFocus
  } = props

  const { t } = useTranslation()

  const [mounted, setMounted] = useState(false)
  const wasVerified = usePrevious(verified)
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
      clearTimeout(forceUpdateTimeoutId)
    }
  }, [])

  const showsVerified = useMemo(
    () => mounted && verified && verified !== wasVerified,
    [mounted, verified, wasVerified]
  )

  useEffect(() => {
    if (showsVerified) {
      forceUpdateTimeoutId = setTimeout(() => {
        forceUpdate()
      }, 5000)
    }
  }, [showsVerified, forceUpdate])

  const ariaTypeKey: string = useMemo(() => {
    switch (type) {
      case 'tel':
        return 'MobileNumber'
      case 'email':
        return 'PersonalEmail'
      default:
        return 'Text'
    }
  }, [type])

  const buttonView: ReactNode = useMemo(() => {
    if (!button || loading) {
      return null
    }

    let label: string = null
    let ariaLabel: string = null
    let color: string = null
    let hoverColor: string = null

    switch (button.type) {
      case 'Verify':
        color = button.disabled ? colors.main40 : colors.main100
        ariaLabel = t(`common.accessibilityText.verify${ariaTypeKey}`)
        label = t('common.verify')
        break
      case 'Resend':
        color = button.disabled ? colors.main40 : colors.main100
        ariaLabel = t(`common.accessibilityText.resend${ariaTypeKey}`)
        label = t('common.resend')
        break
      case 'Remove':
        color = button.disabled ? colors.error40 : colors.error100
        hoverColor = colors.error80
        ariaLabel = t(`common.accessibilityText.remove${ariaTypeKey}`)
        label = t('common.remove')
        break
    }

    return (
      <Button
        $color={color}
        $hoverColor={hoverColor}
        $disabled={button.disabled}
        $invalid={invalid}
        disabled={button.disabled}
        onClick={!button.disabled ? button.onClick : (): void => undefined}
        aria-label={ariaLabel}
        aria-disabled={button.disabled}
      >
        {label}
      </Button>
    )
  }, [button, invalid, loading, t, ariaTypeKey])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (props.onKeyDown) {
        props.onKeyDown(event)
        return
      }

      if (!button || !button.submitOnReturnButton || !button.onClick) {
        return
      }

      if (event.key === 'Enter') {
        button.onClick()
      }
    },
    [button, props]
  )

  const labelText: string = useMemo(
    () =>
      t(
        `common.accessibilityText.enter${
          isConfirmationCode ? 'ConfirmationCode' : ''
        }${ariaTypeKey}`
      ),
    [ariaTypeKey, isConfirmationCode, t]
  )

  const labelId = useMemo(() => `label_id_${Math.random()}`, [])

  return (
    <Container>
      <TopContainer $invalid={invalid} $focused={focused}>
        <Label id={labelId}>{labelText}</Label>
        <InputMask
          data-testid={props['data-testid']}
          autoFocus={autoFocus}
          className={className}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          type={type}
          ref={ref}
          mask={mask}
          pattern={pattern}
          tabIndex={0}
          disabled={disabled}
          spellCheck={'false'}
          autoCorrect={'off'}
          autoCapitalize={'off'}
          autoComplete={'off'}
          data-lpignore={'true'}
          alt={alt}
          aria-labelledby={labelId}
          maskChar={maskChar}
        />

        {loading && (
          <LoadingImage>
            <Icon
              name={'spinner'}
              ariaLabel={t('common.accessibilityText.loadingIcon')}
            />
          </LoadingImage>
        )}
        {buttonView}
      </TopContainer>
      <BottomContainer role="status">
        {invalid && errorString && <ErrorText>{t(errorString)}</ErrorText>}
        {showsVerified && <VerifiedText>{verifiedString}</VerifiedText>}
      </BottomContainer>
    </Container>
  )
})

const Wrapper = styled(Input)<IProps>`
  outline: none;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: transparent;
  border-radius: 0;
  font-size: 16px;
  line-height: 100%;
  padding: 0 12px;
  height: 44px;

  ${props => {
    if (props.invalid) {
      return css`
        color: ${props.theme.colors.error80};
      `
    } else if (props.disabled) {
      return css`
        color: ${props.theme.colors.dark60};
        -webkit-opacity: 1;
        -webkit-text-fill-color: ${props.theme.colors.dark60};
      `
    } else {
      return css`
        color: ${props.theme.colors.dark80};
      `
    }
  }}

  ::placeholder {
    color: ${props => props.theme.colors.dark60};
  }

  :not(output):-moz-ui-invalid {
    box-shadow: none;
  }

  :not(output):-moz-ui-invalid:-moz-focusring {
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
`

Input.displayName = 'Input'

export default Wrapper
