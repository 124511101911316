import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding: 20px 16px 0;
  margin: 0 12px;
  color: ${props => props.theme.colors.dark80};
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -webkit-fill-available;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -moz-available;
  width: fill-available;
`

export const Th = styled.th`
  border: 0.1em solid ${props => props.theme.colors.dark20};
  background: ${props => props.theme.colors.dark05};
  padding: 6px 10px;
  font-weight: 500;
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  vertical-align: middle;
`

export const Td = styled.td<{ $textAlign?: string }>`
  border: 1px solid ${props => props.theme.colors.dark20};
  padding: 6px 10px;
  text-align: ${props => props.$textAlign || 'left'};
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  line-height: 18.2px;
  vertical-align: middle;
`
