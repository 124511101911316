import React from 'react'
import styled, { css } from 'styled-components'
import { IIconName, IIconProps } from 'src/react-app-env'
import createIcon from 'src/UIKit/icons'

interface IProps {
  className?: string
  name: IIconName
  ariaLabel?: string
  settings?: IIconProps
  onClick?: () => void
  flex?: boolean
}

const Container = styled.div<{ $flex: boolean }>`
  ${props =>
    props.$flex &&
    css`
      display: flex;
    `}
`

const Icon = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const { className, name, settings, onClick, ariaLabel, flex } = props

    return (
      <Container
        className={className}
        ref={ref}
        onClick={onClick}
        aria-label={ariaLabel}
        role={ariaLabel && 'img'}
        $flex={flex}
      >
        {createIcon(name, settings)}
      </Container>
    )
  })
)

Icon.displayName = 'Icon'

export default Icon
