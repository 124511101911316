import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export type HrAdminBlockAppearance =
  | 'inactive'
  | 'active'
  | 'blueValue'
  | 'default'

export interface IHrAdminBlock {
  title: string
  children: ReactNode
  appearance?: HrAdminBlockAppearance
  onClick?: () => void
}

const Container = styled.div<any>`
  flex: 1;
  display: flex;
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 8px;
        `
      : css`
          margin: 0 6px;
        `}

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const Background = styled.button<{ $appearance: string }>`
  transition: 100ms background;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  outline: none;

  ${(props: any) => {
    const { $appearance } = props
    switch ($appearance) {
      case 'inactive':
        return css`
          border: 1px solid ${props.theme.colors.main100};
          background: ${props.theme.colors.light100};
          cursor: pointer;

          &:hover {
            background: ${props.theme.colors.main05};
          }

          &:focus {
            outline: none;
            background: ${props.theme.colors.main05};
          }
        `
      case 'active':
        return css`
          border: 1px solid ${props.theme.colors.main100};
          background: ${props.theme.colors.main100};
          cursor: pointer;

          &:focus {
            outline: none;
          }
        `
      default:
        return css`
          border: 1px solid ${props.theme.colors.dark05};
          background: ${props.theme.colors.light100};

          &:focus {
            outline: none;
          }
        `
    }
  }}
`

const Title = styled.span<{ $appearance: string }>`
  line-height: 130%;
  text-align: left;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 16px;
          font-size: 14px;
        `
      : css`
          padding: 8px;
          font-size: 12px;
        `}

  ${(props: any) => {
    const { $appearance } = props
    if ($appearance === 'active') {
      return css`
        color: ${props.theme.colors.light100};
      `
    } else {
      return css`
        color: ${props.theme.colors.dark60};
      `
    }
  }}
`

const ContentContainer = styled.div`
  flex: 1;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 16px 16px;
        `
      : css`
          padding: 0 8px 8px;
        `}
`

export const HrAdminBlock = React.memo((props: IHrAdminBlock) => {
  const { title, children, appearance, onClick } = props

  return (
    <Container>
      <Background
        $appearance={appearance}
        onClick={onClick}
        tabIndex={appearance === 'inactive' ? 0 : -1}
      >
        <Title $appearance={appearance}>{title}</Title>
        <ContentContainer>{children}</ContentContainer>
      </Background>
    </Container>
  )
})

HrAdminBlock.displayName = 'HrAdminBlock'

export default HrAdminBlock
