import React, { Fragment, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Badge from 'src/components/Badge'
import RouteContext from 'src/routes/Context'
import { darken } from 'polished'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import { getIsEmployee } from 'src/utils/userUtils'
import { UserRole } from 'src/react-app-env'
import { isPathnameLikeOneOf } from 'src/utils/routeUtils'
import {
  managerNotificationsRoute,
  notificationsRoute
} from 'src/routes/constants'

interface IProps {
  notifications: INotificationFeed
  onClick: () => void
  expanded?: boolean
  className?: string
  pathname: string
}

const Container = styled.button<{ $primaryColor: string }>`
  cursor: pointer;
  transition: color 100ms linear;
  display: flex;
  align-items: center;
  outline: none;
  padding: 8px;
  border-radius: 16px;
  border: 2px solid transparent;
  position: relative;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 6px 0 20px;
      padding: 0;
      width: max-content;
      margin-left: 16px;
    `}

  &:focus {
    outline: none;
    ${(props: any) =>
      props.theme.isDesktop
        ? css`
            border: 2px solid ${props.$primaryColor};
          `
        : css`
            outline: 2px solid ${props.theme.colors.main100};
            outline-offset: 3px;
          `}
  }
`

const Text = styled.span<{ $color?: string; $selected?: boolean }>`
  user-select: none;
  margin-right: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          text-align: right;
          text-transform: uppercase;
          color: ${props.theme.colors.main100};

          &:hover {
            color: ${props.theme.colors.main90};
          }
        `
      : css`
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: ${props.theme.colors.dark60};
          padding: 0 0 4px;
          ${() => {
            if (props.$selected) {
              return css`
                border-bottom: 2px solid ${props.theme.colors.main100};
                color: ${props.theme.colors.dark80};
              `
            }
          }}
        `}

  ${props =>
    props.$color &&
    css`
      color: ${props.$color};

      &:hover {
        color: ${darken(0.1, props.$color)};
      }
    `}
`

const IconBellWrapper = styled(Icon)`
  cursor: pointer;
`

const BadgeWrapper = styled(Badge)`
  ${(props: any) =>
    props.theme.isMobile &&
    css`
      margin: 0 0 4px;
    `}
`

const Notifications = React.forwardRef((props: IProps, ref: any) => {
  const { className, onClick, notifications, expanded, pathname } = props
  const unreadCounter = notifications ? notifications.unreadCounter : 0
  const { t } = useTranslation()
  const { primaryColor, isManagerRoute } = useContext(RouteContext)
  let content: any
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const userResult: IUseUser = useUser()

  const selected: boolean = useMemo(
    () =>
      isMobile &&
      isPathnameLikeOneOf(pathname, [
        notificationsRoute,
        managerNotificationsRoute
      ]),
    [pathname, isMobile]
  )

  if (!isManagerRoute && !getIsEmployee(userResult.user?.roles as UserRole[])) {
    return null
  }

  if (isDesktop) {
    content = (
      <Fragment>
        {unreadCounter > 0 && (
          <Text $color={primaryColor} aria-hidden>
            {unreadCounter}
          </Text>
        )}
        <IconBellWrapper
          name={'bell'}
          settings={{
            fill: primaryColor
          }}
        />
      </Fragment>
    )
  } else {
    content = (
      <Fragment>
        <Text $selected={selected}>{t('common.notifications')}</Text>
        {unreadCounter > 0 && (
          <BadgeWrapper
            text={unreadCounter.toString()}
            background={primaryColor}
          />
        )}
      </Fragment>
    )
  }

  return (
    <Container
      className={className}
      onClick={onClick}
      ref={ref}
      $primaryColor={primaryColor}
      aria-haspopup
      aria-expanded={expanded}
      aria-label={
        unreadCounter !== 0
          ? t('common.accessibilityText.unreadNotifications', {
              count: unreadCounter
            })
          : t('common.accessibilityText.notifications')
      }
    >
      {content}
    </Container>
  )
})

Notifications.displayName = 'Notifications'

export default Notifications
