import isEqual from 'lodash.isequal'
import { ITimelinePeriod } from 'src/react-app-env'

/**
 * Interface representing a diff item.
 */
interface DiffItem {
  from: ITimelinePeriod | null
  to: ITimelinePeriod | null
  type: 'added' | 'removed' | 'changed' | 'merged'
}

/**
 * Type representing a filter function.
 */
type FilterFunc = (element: DiffItem) => boolean

/* List of properties to use while comparing two timeline periods */
const periodComparableProps = [
  'type',
  'periodStart',
  'periodEnd',
  'appearance',
  'mode',
  'durationDays',
  'schedule'
]

/**
 * Class for calculating the difference between two timelines.
 */
export default class TimelineDiff {
  private diff: DiffItem[]

  /**
   * Constructs a new instance of the TimelineDiff class.
   * @param before The first array of ITimelinePeriod objects.
   * @param after The second array of ITimelinePeriod objects.
   */
  public constructor(before: ITimelinePeriod[], after: ITimelinePeriod[]) {
    this.diff = this.buildDiff(before, after)
  }

  /**
   * Returns timeline added periods that satisfy the given filter function.
   * @param filterFunc A filter function to further filter the added periods.
   * Defaults to a function that always returns true.
   * @returns An array of added timeline periods.
   */
  public added(filterFunc: FilterFunc = () => true) {
    return this.filterDiff('added', filterFunc)
  }

  /**
   * Returns timeline removed periods that satisfy the given filter function.
   * @param filterFunc A filter function to further filter the removed periods.
   * Defaults to a function that always returns true.
   * @returns An array of removed timeline periods.
   */
  public removed(filterFunc: FilterFunc = () => true) {
    return this.filterDiff('removed', filterFunc)
  }

  /**
   * Returns timeline merged periods that satisfy the given filter function.
   * @param filterFunc A filter function to further filter the merged periods.
   * Defaults to a function that always returns true.
   * @returns An array of merged timeline periods.
   */
  public merged(filterFunc: FilterFunc = () => true) {
    return this.filterDiff('merged', filterFunc)
  }

  /**
   * Returns timeline changed periods that satisfy the given filter function.
   * @param filterFunc A filter function to further filter the changed periods.
   * Defaults to a function that always returns true.
   * @returns An array of changed timeline periods.
   */
  public changed(filterFunc: FilterFunc = () => true) {
    return this.filterDiff('changed', filterFunc)
  }

  /**
   * Returns timeline diff filtered items.
   * @param itemType A diff item type
   * @param filterFunc A filter function to further filter the periods in diff.
   * @returns An array of timeline periods matching criteria.
   */
  private filterDiff(itemType, filterFunc: FilterFunc) {
    return this.diff
      .filter(diffItem => diffItem.type === itemType)
      .filter(filterFunc)
  }

  /**
   * Builds the diff array representing the difference between beforeList and afterList.
   * @param beforeList The first array of ITimelinePeriod objects.
   * @param afterList The second array of ITimelinePeriod objects.
   * @returns An array of DiffItem objects.
   */
  private buildDiff(
    beforeList: ITimelinePeriod[],
    afterList: ITimelinePeriod[]
  ): DiffItem[] {
    const diffList: DiffItem[] = []

    beforeList.forEach((from, index) => {
      const to = this.findPeriod(from, afterList)
      if (!to) {
        const prevFrom = beforeList[index - 1]
        const prevTo = afterList[index - 1]
        if (
          prevFrom?.itemID === prevTo?.itemID &&
          prevFrom?.durationDays < prevTo?.durationDays &&
          from.type === prevTo?.type
        ) {
          diffList.push({ from, to: prevTo, type: 'merged' })
        } else {
          diffList.push({ from, to, type: 'removed' })
        }
      } else if (!this.areSamePeriods(from, to)) {
        diffList.push({ from, to, type: 'changed' })
      }
    })

    afterList.forEach(to => {
      const from = this.findPeriod(to, beforeList)
      if (!from) {
        diffList.push({ from, to, type: 'added' })
      }
    })

    return diffList
  }

  /**
   * Returns the unique key for the given ITimelinePeriod object.
   * @param period The ITimelinePeriod object.
   * @returns The unique key for the ITimelinePeriod object.
   */
  private uniqKey(period: ITimelinePeriod): string {
    return !period.hasDynamicItemID && period.itemID ? 'itemID' : 'groupHash'
  }

  /**
   * Searches for an ITimelinePeriod object with the same unique key in the given list.
   * @param period The ITimelinePeriod object to search for.
   * @param list The array of ITimelinePeriod objects to search within.
   * @returns The found ITimelinePeriod object or undefined if not found.
   */
  private findPeriod(
    period: ITimelinePeriod,
    list: ITimelinePeriod[]
  ): ITimelinePeriod | undefined {
    const key = this.uniqKey(period)
    return list.find(listItem => listItem[key] === period[key])
  }

  /**
   * Compares two ITimelinePeriod objects to determine if they are the same.
   * @param p1 The first ITimelinePeriod object.
   * @param p2 The second ITimelinePeriod object.
   * @returns True if the objects are the same; otherwise, false.
   */
  private areSamePeriods(p1: ITimelinePeriod, p2: ITimelinePeriod): boolean {
    if (this.uniqKey(p1) !== this.uniqKey(p2)) {
      return false
    }

    const keys1 = Object.keys(p1)
    const keys2 = Object.keys(p2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (
        periodComparableProps.includes(key) &&
        !this.isSamePeriodProperty(p1[key], p2[key])
      ) {
        return false
      }
    }

    return true
  }

  /**
   * Compares two properties of ITimelinePeriod objects to determine if they are the same.
   * This function supports comparing Moment.js objects and objects with current, min, and max properties.
   * For other property types, it uses a deep equality check.
   * @param p1 The first property to compare.
   * @param p2 The second property to compare.
   * @returns True if the properties are the same; otherwise, false.
   */
  private isSamePeriodProperty(p1: any, p2: any): boolean {
    if (!p1) {
      return p1 === p2
    }

    if (p1._isAMomentObject) {
      return p1.isSame(p2)
    }

    if (p1.current && p1.min && p1.max) {
      return p1.current.isSame(p2.current)
    }

    return isEqual(p1, p2)
  }
}
