import React, { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import VacationViewTitleMobile from '../TitleMobile'
import VacationOverviewContent from '../VacationOverviewContent'
import VacationDialogContent from '../VacationDialogContent'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { VACATION_OVERVIEW_KEY } from 'src/utils/ls'
import zIndex from 'src/constants/zIndex'

interface IProps {
  metadata: any
  onVacationBalanceChanged: (vacationBalance: number) => void
  onExit: () => void
}

const MobileContainer = styled.div`
  z-index: ${zIndex.vacationSteps.mobileOverlay};
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: ${props => props.theme.colors.backgroundColor};
`

const ContentContainer = styled.div`
  overflow-y: auto;
  padding: 16px;
  width: calc(100% - 32px);
  margin: 0 auto;
  max-width: 640px;
  height: 100%;

  ${mobileMaxWidthMixin};
`

export const VacationMobileContentView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { metadata, onVacationBalanceChanged, onExit } = props

  const {
    PTOBalance,
    estimatedNextMaxAccrualDate,
    maxAccrualNotice,
    maxAccrualDate,
    plannedVacationDays,
    plannedVacationHours,
    recommendedDaysBeforeLeaveStart,
    recommendedDaysBeforeDisability,
    recommendedDaysBeforeBonding
  } = metadata || {}

  const [showVacationOverview, setShowVacationOverview] = useLocalStorage(
    VACATION_OVERVIEW_KEY,
    { canBeShown: true }
  )
  const [showEdit, setShowEdit] = useState(!showVacationOverview.canBeShown)

  const title: string = showEdit
    ? t('vacationBalance.dialog.title')
    : t('vacationBalance.overview.title')

  const onUpdate = useCallback(
    (value: string) => {
      onVacationBalanceChanged(parseFloat(value))
    },
    [onVacationBalanceChanged]
  )

  const body: ReactNode = !showEdit ? (
    <VacationOverviewContent
      onShowEdit={() => {
        setShowEdit(true)
      }}
      onCancel={onExit}
      onChecked={(check: boolean) => {
        setShowVacationOverview({ canBeShown: check })
      }}
    />
  ) : (
    <VacationDialogContent
      vacationHours={PTOBalance?.toFixed(2).toString()}
      onUpdate={(value: string) => {
        onUpdate(value)
      }}
      isMaxAccrual={maxAccrualNotice}
      maxAccrualDate={maxAccrualDate}
      plannedVacationDays={plannedVacationDays}
      plannedVacationHours={plannedVacationHours}
      recommendedDaysStart={recommendedDaysBeforeLeaveStart}
      recommendedDaysDisability={recommendedDaysBeforeDisability}
      recommendedDaysBonding={recommendedDaysBeforeBonding}
      estimatedNextMaxAccrualDate={estimatedNextMaxAccrualDate}
    />
  )

  return (
    <MobileContainer>
      <VacationViewTitleMobile onExit={onExit}>{title}</VacationViewTitleMobile>
      <ContentContainer>{body}</ContentContainer>
    </MobileContainer>
  )
})

VacationMobileContentView.displayName = 'VacationMobileContentView'

export default VacationMobileContentView
