import React from 'react'
import styled, { css } from 'styled-components'
import { H1 } from 'src/UIKit'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'

interface IProps {
  children: string
  role?: string
}

const Title = styled(H1)<any>`
  max-width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 16px;
        `}
`

export const dataAttrs = {
  title: () => 'create-leave-title'
}

export const CreateLeaveTitle = React.memo((props: IProps) => {
  const { children, role } = props
  return (
    <>
      <Title role={role} data-testid={dataAttrs.title()}>
        {children}
      </Title>
      <AccessibilitySelfFocusText ariaLabel={children} />
    </>
  )
})

CreateLeaveTitle.displayName = 'CreateLeaveTitle'

export default CreateLeaveTitle
