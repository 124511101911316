import React from 'react'
import AbsenceStatus from '../components/AbsenceStatus'
import LeaveStatus from '../components/LeaveStatus'
import TasksThisWeek from '../components/TasksThisWeek'
import OverdueTasks from '../components/OverdueTasks'

export interface DashboardProps {
  absence: IAbsence
}

const DefaultDashboard = React.memo((props: DashboardProps) => (
  <>
    <LeaveStatus {...props} />
    <AbsenceStatus {...props} />
    <TasksThisWeek {...props} />
    <OverdueTasks {...props} />
  </>
))

DefaultDashboard.displayName = 'DefaultDashboard'

export default DefaultDashboard
