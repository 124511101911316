import {
  DatePickerKeyDate,
  IGetPeriodConfigInput,
  ITimelinePeriodConfig
} from '../../../index'
import LeaveKeyDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveKeyDatePicker'
import SicknessPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/SicknessPicker'
import moment from 'moment'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent:
    input.leave.type === 'Sickness' ? SicknessPicker : LeaveKeyDatePicker,
  datePickerKeyDate: 'endDate' as DatePickerKeyDate,
  leaveKeyDatePickerInfoMessage: (leave: any, t: any) => {
    if (
      leave.type === 'Personal' &&
      leave.timeline.find((period: any) => period.type === 'AdditionalPersonal')
    ) {
      return t('timelineHints.periods.Personal.additionalPersonalInfoMessage')
    }
  }
})
