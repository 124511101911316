import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: any
  children?: ReactNode
}

const Wrapper = styled.span`
  font-weight: normal;
  font-size: 16px;
  color: ${props => props.theme.colors.dark80};
`

const ProfileFormFieldText = React.memo((props: IProps) => {
  const { children } = props
  return <Wrapper>{children}</Wrapper>
})

ProfileFormFieldText.displayName = 'ProfileFormFieldText'

export default ProfileFormFieldText
