import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import LoadingSpinner from 'src/components/LoadingSpinner'
import zIndex from 'src/constants/zIndex'
import ModalsView from 'src/components/ModalsView'
import VideoMobileOverlayView from 'src/components/JourneyMap/JourneyMapItemView/components/VideoView/components/VideoMobileOverlayView'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: string
  title: string
  src: string
  parentWidth: number
}

const Container = styled.div<{ $width: number; $height: number }>`
  position: relative;
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 24px auto;
        `
      : css`
          margin: 16px auto;
        `}

  &:not(:first-child) {
    ${props =>
      props.theme.isMobile &&
      css`
        margin-top: 0;
      `}
  }
`

const IFrame = styled.iframe`
  ${props =>
    props.theme.isDesktop &&
    css`
      position: absolute;
      inset: 0;
      z-index: ${zIndex.journeyMap.itemView.video.mobileIFrame};
    `}
`

const Modal = styled(ModalsView)`
  background: black;
`

const LoadingSpinnerContainer = styled.div<any>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.journeyMap.itemView.video.loadingSpinner};
`

const HorizontalLine = styled.div<{ $isTop?: boolean; $width: number }>`
  position: absolute;
  left: 0;
  width: ${props => props.$width}px;
  height: 2px;
  background: ${props => props.theme.colors.light100};
  z-index: ${zIndex.journeyMap.itemView.video.horizontalLine};
  ${props => {
    if (props.$isTop) {
      return css`
        top: -1px;
      `
    } else {
      return css`
        bottom: -1px;
      `
    }
  }}
`

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.main40};
  border: 1px solid ${props => props.theme.colors.main40};
  padding: 12px 16px;
  border-radius: 16px;
  background: rgb(0 0 0 / 50%);
  outline: none;

  &:active {
    color: ${props => props.theme.colors.main80};
    border: 1px solid ${props => props.theme.colors.main80};
  }
`

const VideoView = React.memo((props: IProps) => {
  const { className, title, src, parentWidth } = props
  const { isMobile, isDesktop, isMobileLandscape } = useContext(ScreenContext)
  const [isLoading, setIsLoading] = useState<boolean>(isDesktop)
  const [isMobileFullscreen, setIsMobileFullscreen] = useState<boolean>(false)

  let width: number
  let height: number
  const aspectRatio = 0.563484358144552

  if (isMobileLandscape) {
    height = window.innerHeight
    width = height / aspectRatio
  } else {
    width = Math.floor(parentWidth - (isDesktop ? 80 : 0)) // padding
    height = Math.ceil(width * aspectRatio)
  }

  const onLoad = useCallback(() => {
    setIsLoading(false)
  }, [setIsLoading])

  const loadingView: ReactNode = useMemo(
    () =>
      isLoading && (
        <LoadingSpinnerContainer width={width} height={height}>
          <LoadingSpinner fadesIn />
        </LoadingSpinnerContainer>
      ),
    [isLoading, width, height]
  )

  const videoView: ReactNode = useMemo(
    () => (
      <IFrame
        title={title}
        src={src}
        width={width}
        height={height}
        allowFullScreen
        onLoad={onLoad}
      />
    ),
    [title, src, width, height, onLoad]
  )

  const topLineView: ReactNode = useMemo(
    () => <HorizontalLine $isTop $width={width} />,
    [width]
  )
  const bottomLineView: ReactNode = useMemo(
    () => <HorizontalLine $width={width} />,
    [width]
  )

  const onMobileOverlayClick = useCallback(() => {
    setIsMobileFullscreen(true)
    setIsLoading(true)
  }, [setIsMobileFullscreen, setIsLoading])

  const mobileOverlayView: ReactNode = useMemo(
    () =>
      isMobile && (
        <VideoMobileOverlayView title={title} onClick={onMobileOverlayClick} />
      ),
    [onMobileOverlayClick, title, isMobile]
  )

  const onMobileCloseClick = useCallback(() => {
    setIsMobileFullscreen(false)
  }, [setIsMobileFullscreen])

  const mobileCloseButton: ReactNode = useMemo(
    () =>
      isMobileFullscreen && (
        <CloseButton onClick={onMobileCloseClick}>Close</CloseButton>
      ),
    [isMobileFullscreen, onMobileCloseClick]
  )

  const desktopView: ReactNode = useMemo(
    () =>
      isDesktop && (
        <>
          {videoView}
          {loadingView}
          {topLineView}
          {bottomLineView}
        </>
      ),
    [videoView, loadingView, topLineView, bottomLineView, isDesktop]
  )

  const mobileFullscreenView: ReactNode = useMemo(
    () =>
      isMobileFullscreen && (
        <Modal>
          {videoView}
          {loadingView}
          {mobileCloseButton}
        </Modal>
      ),
    [videoView, loadingView, mobileCloseButton, isMobileFullscreen]
  )

  return (
    <Container className={className} $width={width - 16} $height={height}>
      {desktopView}
      {mobileOverlayView}
      {mobileFullscreenView}
    </Container>
  )
})

VideoView.displayName = 'VideoView'

export default VideoView
