import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Description = styled.p`
  display: block;
  grid-area: description;
  font-weight: normal;
  text-align: justify;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          line-height: 150%;
          margin: 0 16px 16px;
        `
      : css`
          font-size: 12px;
          line-height: 130%;
          margin: 0 8px 8px;
          min-height: 38px;
        `};
`

const JourneyMapListItemDescription = React.memo((props: IProps) => {
  const { className, children } = props

  return (
    <Description className={className} aria-hidden>
      {children}
    </Description>
  )
})

JourneyMapListItemDescription.displayName = 'JourneyMapListItemDescription'

export default JourneyMapListItemDescription
