import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { withToastManager } from 'src/components/ToastManager'
import { withDialogManager } from 'src/components/DialogManager'
import withQueries from 'src/components/HOC/withQueries'
import styled, { css } from 'styled-components'
import {
  IWithDialogManager,
  IWithQueriesProps,
  IWithToastManager
} from 'src/react-app-env'
import {
  errorPageRoute,
  managerArchiveRoute,
  managerDashboardJourneyMapRoute,
  managerPreviousVersionsRoute
} from 'src/routes/constants'
import DashboardActive from '../components/DashboardActive'
import usePageTitle from 'src/components/hooks/usePageTitle'
import DashboardContext, { IManagerDashboardContext } from '../DashboardContext'
import { sortAbsencesByStartDate, yearBounds } from 'src/utils/absences'
import LoadingSpinner from 'src/components/LoadingSpinner'
import NoAbsencesView from '../components/NoAbsencesView'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import DashboardMobileHeader from '../components/DashboardMobileHeader'
import UsersTimelineView from '../components/UsersTimelineView'
import YearScroll from '../components/YearScroll'
import moment from 'moment'
import DashboardNoActive from '../components/DashboardNoActive'

interface IProps
  extends IWithQueriesProps,
    IWithToastManager,
    IWithDialogManager {}

const Container = styled.div`
  max-width: 1480px;
  margin: auto;
  height: 100%;
`

const DashboardWrapper = styled.div`
  display: block;
  overflow-y: auto;

  ${props =>
    props.theme.isDesktop
      ? css`
          height: 100%;
          margin: 0 20px 20px;
        `
      : css`
          height: calc(100% - 112px);
          margin: auto;
        `}
`

const DashboardMobileHeaderWrapper = styled(DashboardMobileHeader)`
  width: 100%;
`

const YearScrollWrapper = styled.div`
  margin-left: auto;
  width: 100px;
  padding-top: 16px;
  padding-bottom: 12px;
`

export const DashboardActiveContainer = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location

  const { isDesktop } = useContext(ScreenContext)
  const { dialogManager, toastManager } = props
  const [absences, setAbsences] = useState([])
  const [selectedYear, setSelectedYear] = useState<number>(moment().year())
  const [selectedAbsence, setSelectedAbsence] = useState<IAbsence>()
  const [emailNotificationsOn, setEmailNotificationsOn] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const absenceId: string = useMemo(
    () => state && (state as any).absenceId,
    [state]
  )

  useRedirectByUserRole({ navigate, allowedRole: 'manager' })
  usePageTitle('managerDashboard')

  const selectAbsence = useCallback(
    (absence: IAbsence) => {
      setSelectedAbsence(absence)
      setEmailNotificationsOn(absence?.emailNotifications)
    },
    [setSelectedAbsence, setEmailNotificationsOn]
  )

  const fetchActiveAbsences = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = sortAbsencesByStartDate(
        await props.queries.fetchAbsencesDashboard(false, {
          fetchPolicy: 'network-only'
        })
      )
      setAbsences(result)
      if (selectedAbsence) {
        const absence = result.find(a => a.id === selectedAbsence.id)
        selectAbsence(absence || result[0])
      } else {
        selectAbsence(result[0])
      }
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [props, navigate, selectedAbsence, selectAbsence])

  useEffect(() => {
    fetchActiveAbsences()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const absence = absences.find(a => a.id === absenceId)
    if (absence) {
      selectAbsence(absence)
    }
  }, [absenceId, absences, selectAbsence])

  const toggleEmailNotifications = (absence: IAbsence) => {
    const { id, emailNotifications }: IAbsence = absence
    const { updateAbsenceSettings } = props.queries

    const toggle = (value: boolean) => {
      updateAbsenceSettings(id, { emailNotifications: value }).then(() => {
        absence.emailNotifications = value
        setEmailNotificationsOn(value)
      })
    }

    if (emailNotifications) {
      dialogManager.add({
        title: t('emailNotifications.dialogTurnOffTitle'),
        buttons: [
          {
            title: t('common.cancel'),
            appearance: 'cancel',
            onClick: (): void => undefined,
            order: 1
          },
          {
            title: t('common.turnOff'),
            onClick: () => {
              toggle(false)
            },
            order: 2
          }
        ],
        children: t('emailNotifications.dialogTurnOffBody')
      })
    } else {
      toggle(true)
    }
  }

  const archivePlan = (absence: IAbsence) => {
    dialogManager.add({
      title: t('archiveThePlan.dialogArchiveTitle'),
      ariaText: t('archiveThePlan.dialogArchiveAria'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('common.archive'),
          onClick: () => {
            props.queries
              .archiveAbsence(absence.id, true)
              .then(() => fetchActiveAbsences())
              .catch(() =>
                toastManager.addError(t('common.somethingWentWrong'))
              )

            toastManager.addInfo(t('managerJourneyMap.planHasBeenArchived'))
          },
          order: 2
        }
      ],
      children: t('archiveThePlan.dialogArchiveBody')
    })
  }

  const removeAbsence = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true)
        await props.queries.removeAbsence(id)
      } catch (error) {
        if (error instanceof Error) {
          navigate(errorPageRoute, {
            state: { error: error.message }
          })
        }
      } finally {
        setIsLoading(false)
        fetchActiveAbsences()
      }
    },
    [fetchActiveAbsences, navigate, props.queries]
  )

  const contextValue: IManagerDashboardContext = {
    dashboardAbsences: absences,
    dashboardAbsence: selectedAbsence,
    selectAbsence,
    toggleEmailNotifications,
    emailNotificationsOn,
    archivePlan
  }

  const onYearChange = useCallback(
    (year: number) => setSelectedYear(year),
    [setSelectedYear]
  )

  const minMaxYear = useMemo(() => yearBounds(absences), [absences])

  const selectedDashboardView = useMemo(
    () =>
      selectedAbsence?.outdated ? (
        <DashboardNoActive
          absence={selectedAbsence}
          onRemoveAbsence={removeAbsence}
        />
      ) : (
        <DashboardActive
          absence={selectedAbsence}
          onViewTasksClicked={() =>
            navigate(
              managerDashboardJourneyMapRoute.replace(
                ':absenceId',
                selectedAbsence.id
              )
            )
          }
          onViewPreviousVersionsClicked={() =>
            navigate(
              managerPreviousVersionsRoute.replace(
                ':absenceId',
                selectedAbsence.id
              )
            )
          }
        />
      ),
    [removeAbsence, selectedAbsence, navigate]
  )

  const content = useMemo(
    () => (
      <Container>
        {isDesktop || <DashboardMobileHeaderWrapper />}
        <DashboardWrapper>
          {isDesktop && (
            <>
              <YearScrollWrapper>
                <YearScroll
                  selected={selectedYear}
                  min={minMaxYear.min}
                  max={minMaxYear.max}
                  onChange={onYearChange}
                />
              </YearScrollWrapper>

              <UsersTimelineView
                absences={absences}
                selectedAbsence={selectedAbsence}
                selectedYear={selectedYear}
                onClick={selectAbsence}
              />
            </>
          )}
          {selectedDashboardView}
        </DashboardWrapper>
      </Container>
    ),
    [
      isDesktop,
      absences,
      selectedAbsence,
      selectAbsence,
      onYearChange,
      minMaxYear,
      selectedYear,
      selectedDashboardView
    ]
  )

  if (isLoading) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  if (absences.length === 0) {
    return (
      <NoAbsencesView
        isArchiveMode={false}
        onPlansArchiveClick={() => navigate(managerArchiveRoute)}
      />
    )
  }

  return (
    <DashboardContext.Provider value={contextValue}>
      {content}
    </DashboardContext.Provider>
  )
})

DashboardActiveContainer.displayName = 'DashboardActiveContainer'

export default withToastManager(
  withDialogManager(withQueries(DashboardActiveContainer))
)
