import { useQuery } from '@apollo/client'
import { queryLeaveWithDates } from 'src/graphql/queries'
import { useState } from 'react'
import isEqual from 'lodash.isequal'

export interface IUseLeaveWithDates {
  loading: boolean
  error: any
  leave: ILeave
}

const useLeaveWithDates = (changes: any): IUseLeaveWithDates => {
  const [leave, setLeave] = useState(null)
  const [oldChanges, setOldChanges] = useState({})

  const returnValue: IUseLeaveWithDates = {
    loading: false,
    error: null,
    leave
  }

  const { loading, error, data } = useQuery(queryLeaveWithDates, {
    fetchPolicy: 'network-only',
    variables: { changes },
    skip: !!leave && isEqual(oldChanges, changes)
  })

  if (loading) {
    returnValue.loading = true
    return returnValue
  }

  if (error) {
    returnValue.error = error
    return returnValue
  }

  if (data) {
    const l: ILeave = data.leave
    returnValue.leave = l
    setLeave(l)
    setOldChanges(changes)
  }

  return returnValue
}

export default useLeaveWithDates
