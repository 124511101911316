import React from 'react'
import styled from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { Button } from 'src/UIKit'
import AccessibilityInvisibleText from 'src/components/AccessibilityInvisibleText'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  onSkipClick: () => void
  onStartTour: (value: boolean) => void
}

const Container = styled.div`
  background: ${props => props.theme.colors.main100};
  border-radius: 8px;
  padding: 16px;
  width: 288px;
  height: 114px;
  position: absolute;
  bottom: 60px;
  right: 20px;
  z-index: ${zIndex.timeline.footnote};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.light100};
  font-weight: 700;
  line-height: 100%;
`

const SkipButton = styled.button`
  color: ${props => props.theme.colors.light100};
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const Text = styled.p`
  color: ${props => props.theme.colors.light100};
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 12px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`

const TakeButton = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 16px;
`

const InductionTrip = React.memo((props: IProps) => {
  const { className, onSkipClick, onStartTour } = props
  const { t } = useTranslation()

  return (
    <Container
      className={className}
      role={'region'}
      aria-label={t('timelineTutorial.accessibility.region')}
    >
      <TitleContainer>
        <AccessibilityInvisibleText role={'alert'}>
          {t('timelineTutorial.accessibility.title')}
        </AccessibilityInvisibleText>
        <Title aria-hidden>
          {t('timelineTutorial.titles.exploreTimeline')}
        </Title>
        <SkipButton
          onClick={onSkipClick}
          aria-label={t('timelineTutorial.accessibility.skip')}
        >
          {t('timelineTutorial.skipOnBoarding')}
        </SkipButton>
      </TitleContainer>
      <BodyContainer>
        <TextContainer>
          <Text>{t('timelineTutorial.bodiesByLeaveType.startTour')}</Text>
        </TextContainer>
        <ButtonContainer>
          <TakeButton
            onClick={() => onStartTour(true)}
            appearance={'startUnbordered'}
          >
            {t(`timelineTutorial.takeTour`)}
          </TakeButton>
        </ButtonContainer>
      </BodyContainer>
    </Container>
  )
})

InductionTrip.displayName = 'InductionTrip'

export default InductionTrip
