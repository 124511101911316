import React, { useContext } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveOptions from '../UI/CreateLeaveOptions'

const WorkScheduleOptions = ['general', 'twoWeekRotation', 'other']

export const SelectWorkSchedule = React.memo(() => {
  const {
    workSchedule,
    setWorkSchedule,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  return (
    <CreateLeaveOptions
      name={'selectWorkSchedule'}
      options={WorkScheduleOptions}
      selectedOption={workSchedule}
      onOptionSelected={setWorkSchedule}
      onBackClicked={() => {
        setWorkSchedule(null)
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
    />
  )
})

SelectWorkSchedule.displayName = 'SelectWorkSchedule'

export default SelectWorkSchedule
