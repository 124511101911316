import React, { ReactNode, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import NormalRadioButtonOption from 'src/features/Leave/components/NormalRadioButtonOption'
import { PregnancyChangeStepView } from '../PregnancyChangeStepView'
import OptionContainer from 'src/UIKit/OptionContainer'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'

interface IProps {
  name: string
}

export const StillExpectedChildrenView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props

  const {
    onNextStage,
    onPrevStage,
    expectedChildren,
    setExpectedChildren
  }: IPregnancyLossContext = useContext(PregnancyLossContext)

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={expectedChildren === 'One'}
        onClick={() => setExpectedChildren('One')}
      >
        {t([`pregnancyChangeFlow.pages.${name}.options.one`])}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={expectedChildren === 'Multiple'}
        onClick={() => setExpectedChildren('Multiple')}
        hidesSeparator
      >
        {t([`pregnancyChangeFlow.pages.${name}.options.multiple`])}
      </NormalRadioButtonOption>
    ],
    [t, name, expectedChildren, setExpectedChildren]
  )
  const content: any = useMemo(
    () => <OptionContainer>{options}</OptionContainer>,
    [options]
  )

  return (
    <PregnancyChangeStepView
      name={name}
      onBackClicked={() => onPrevStage()}
      onNextClicked={() => onNextStage()}
      isNextDisabled={false}
    >
      {content}
    </PregnancyChangeStepView>
  )
})

StillExpectedChildrenView.displayName = 'StillExpectedChildrenView'

export default StillExpectedChildrenView
