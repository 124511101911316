import React, { useContext } from 'react'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import TpaApprovedView from 'src/features/Leave/components/TpaApprovedView/index'

export const CreateLeaveTpaApprovedViewContainer = React.memo(() => {
  const {
    isCreatingLeave,
    tpaApprovalState,
    onTpaApprovalStateChange,
    selectedLeaveGroupName,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  }: ICreateLeaveContext = useContext(LeaveContext)

  return (
    <TpaApprovedView
      leaveType={selectedLeaveGroupName}
      tpaApprovalState={tpaApprovalState}
      onBack={prevCreateLeaveStage}
      onNext={nextCreateLeaveStage}
      onOptionSelected={onTpaApprovalStateChange}
      showsSpinner={isCreatingLeave}
    />
  )
})

CreateLeaveTpaApprovedViewContainer.displayName =
  'CreateLeaveTpaApprovedViewContainer'

export default CreateLeaveTpaApprovedViewContainer
