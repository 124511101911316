import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import TimelineView from 'src/features/Timeline/components/vertical/TimelineView'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import DetailsView from 'src/features/Timeline/components/vertical/DetailsView'
import { printDisplayNoneMixin } from 'src/theme/utils'
import TimelineDesktopHeader from 'src/features/Timeline/components/vertical/PageDesktop/components/Header'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import EligibilityNotice from 'src/features/Timeline/components/common/EligibilityNotice'
import ViewNextStepsNotice from 'src/features/Timeline/components/common/ViewNextStepsNotice'
import TimelineTutorial, {
  TutorialElementsRegister
} from 'src/features/Timeline/Tutorial'
import { isSafari } from 'src/utils/deviceTypes'
import { ITimelinePeriod } from 'src/react-app-env'
import { timeline } from 'src/constants/frame'
import { shouldShowNextStepsNotice } from 'src/utils/viewNextStepsNotice'
import SharedContext from 'src/contexts/SharedContext'
import LeaveNotice from '../../common/LeaveNotice'
import TimelineUpdatedNotice from '../../common/TimelineUpdatedNotice'
import { useResizeDetector } from 'react-resize-detector'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { LeaveNoticeItem } from 'src/config/customers/config'
import ExternalNotice from 'src/features/Timeline/components/common/ExternalNotice'

const GlobalContainer = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'targetRef'
})`
  overflow: hidden;
  height: inherit;
  ${printDisplayNoneMixin}
`

const TimelineContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`

const TimelineViewWrapper = styled(TimelineView)<{
  $paddingTop: number
}>`
  padding-top: ${(props: any) => props.$paddingTop}px;
  min-height: calc(100% - ${(props: any) => props.$paddingTop}px);
`

const noticeMixin = css<{
  $shown?: boolean
}>`
  ${props => {
    if (props.$shown) {
      return css`
        transition: opacity 100ms ${TIMELINE_DETAILS_PUSH_DURATION}ms;
        opacity: 1;
      `
    } else {
      return css`
        transition: opacity 100ms;
        opacity: 0;
        pointer-events: none;
      `
    }
  }}
`

const EligibilityNoticeWrapper = styled(EligibilityNotice)<{ $shown: boolean }>`
  ${noticeMixin}
`

const ViewNextStepsNoticeWrapper = styled(ViewNextStepsNotice)<{
  $shown: boolean
}>`
  ${noticeMixin}
`

const LeaveNoticeWrapper = styled(LeaveNotice)<{ $shown: boolean }>`
  ${noticeMixin}
`
const TimelineUpdatedNoticeWrapper = styled(TimelineUpdatedNotice)`
  ${noticeMixin}
`
const ExternalNoticeWrapper = styled(ExternalNotice)<{ $shown: boolean }>`
  ${noticeMixin}
`
const NoticeContainer = styled.div<{
  $paddingTop: number
}>`
  position: absolute;
  margin-top: ${(props: any) => props.$paddingTop}px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 20px 60px;
`

let animationTimeoutId: number

const PageDesktop = React.memo(() => {
  const {
    onBabyHasArrivedClicked,
    leave,
    timelinePeriods,
    currentDetailsViewPeriod,
    setCurrentDetailsViewPeriod,
    currentDatePickerViewPeriod,
    setCurrentDatePickerViewPeriod,
    setDetailsOpened,
    fetchLeaveTimeline,
    countryCode,
    externalNotices,
    onCloseExternalNotice
  } = useContext<ITimelineContext>(TimelineContext)
  const { customerConfig } = useContext(SharedContext)

  const { note } = leave
  const [showsDetailsView, setShowsDetailsView] = useState<boolean>(false)
  const [showsDatePicker, setShowsDatePicker] = useState(false)

  const globalContainerRef: any = useRef(null)
  const containerRef: any = useRef(null)
  const timelineHeaderRef: any = useRef(null)
  const forceUpdate = useForceUpdate()
  const paddingTop =
    timelineHeaderRef?.current?.offsetHeight +
    timeline.paddingTopDefault().value

  useEffect(
    () => () => {
      clearTimeout(animationTimeoutId)
    },
    []
  )

  useEffect(() => {
    if (containerRef.current) {
      TutorialElementsRegister.getInstance().timelineViewElement =
        containerRef.current
    }
  }, [containerRef])

  useEffect(() => {
    // TODO: This is a temporary solution and needs to be reworked.
    // Used to ensure that when editing the timeline, the period in the date picker is correctly updated and reassigned if the period search by RefID is used.
    // This code is duplicated in the Mobile page.
    if (currentDatePickerViewPeriod?.timelineConfig.usePeriodRefIdSearch) {
      const updatedPeriod = timelinePeriods?.find(
        period =>
          currentDatePickerViewPeriod?.refID &&
          period.refID === currentDatePickerViewPeriod?.refID &&
          period.appearance === 'Standard'
      )
      if (updatedPeriod) {
        setCurrentDatePickerViewPeriod(updatedPeriod)
      }
    }

    const dirtyPeriod = timelinePeriods?.find(period => period.isDirty)
    if (dirtyPeriod) {
      setCurrentDatePickerViewPeriod(dirtyPeriod)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelinePeriods])

  const onBabyHasArrivedClick = useCallback(() => {
    onBabyHasArrivedClicked()
  }, [onBabyHasArrivedClicked])

  const onDetailsClickFunc = (
    period: ITimelinePeriod,
    skipSafariTrick = false
  ) => {
    if (isSafari() && !skipSafariTrick && !!currentDatePickerViewPeriod) {
      onDatePickerClickFunc(null)
      animationTimeoutId = window.setTimeout(() => {
        onDetailsClickFunc(period, true)
      }, TIMELINE_DETAILS_PUSH_DURATION)
      return
    }
    setShowsDetailsView(!!period)
    if (period) {
      setDetailsOpened(true)
      setCurrentDetailsViewPeriod(period)
      if (!isSafari()) {
        onDatePickerClickFunc(null)
      }
    } else {
      setDetailsOpened(false)
      animationTimeoutId = window.setTimeout(() => {
        setCurrentDetailsViewPeriod(null)
      }, TIMELINE_DETAILS_PUSH_DURATION)
    }
  }

  const onDatePickerClickFunc = (
    period: ITimelinePeriod,
    skipSafariTrick = false
  ) => {
    if (isSafari() && !skipSafariTrick && !!currentDetailsViewPeriod) {
      onDetailsClickFunc(null)
      animationTimeoutId = window.setTimeout(() => {
        onDatePickerClickFunc(period, true)
      }, TIMELINE_DETAILS_PUSH_DURATION)
      return
    }
    setShowsDatePicker(!!period)
    if (period) {
      setCurrentDatePickerViewPeriod(period)
      if (!isSafari()) {
        onDetailsClick(null)
      }
    } else {
      animationTimeoutId = window.setTimeout(() => {
        setCurrentDatePickerViewPeriod(null)
      }, TIMELINE_DETAILS_PUSH_DURATION)
    }
  }

  const onDetailsClick = useCallback(onDetailsClickFunc, [onDetailsClickFunc])
  const onDatePickerClick = useCallback(onDatePickerClickFunc, [
    onDatePickerClickFunc
  ])

  const onExit = useCallback(() => {
    onDetailsClick(null)
  }, [onDetailsClick])

  const detailsView: ReactNode = useMemo(
    () => (
      <DetailsView
        period={currentDetailsViewPeriod}
        onExit={onExit}
        shown={!!currentDetailsViewPeriod}
        timelineHeaderHeight={timelineHeaderRef.current?.offsetHeight}
      />
    ),
    [currentDetailsViewPeriod, onExit, timelineHeaderRef]
  )

  const getTopOffset = useCallback(() => {
    const { current } = containerRef
    if (!current) {
      return 0
    }
    return current.scrollTop - paddingTop - current.offsetTop
  }, [paddingTop])

  const onResize = useCallback(() => {
    forceUpdate()
  }, [forceUpdate])

  useResizeDetector({
    targetRef: globalContainerRef,
    onResize
  })

  const eligibilityNotice: ReactNode = useMemo(
    () => (
      <EligibilityNoticeWrapper
        note={note}
        $shown={!currentDatePickerViewPeriod && !currentDetailsViewPeriod}
      />
    ),
    [currentDatePickerViewPeriod, currentDetailsViewPeriod, note]
  )

  const viewNextStepsNotice: ReactNode = useMemo(
    () => (
      <ViewNextStepsNoticeWrapper
        $shown={!currentDatePickerViewPeriod && !currentDetailsViewPeriod}
        type={leave.metadata?.pregnancyLossType}
        top={paddingTop}
      />
    ),
    [leave, currentDatePickerViewPeriod, currentDetailsViewPeriod, paddingTop]
  )

  const leaveNotice: ReactNode = useMemo(() => {
    const leaveNoticeItems = customerConfig.leave.timeline.getLeaveNoticeItems(
      leave,
      countryCode,
      timelinePeriods
    )
    if (leaveNoticeItems.length) {
      return leaveNoticeItems.map((leaveNoticeItem: LeaveNoticeItem) => (
        <LeaveNoticeWrapper
          key={leaveNoticeItem.noticeKey}
          leave={leave}
          $shown={!currentDatePickerViewPeriod && !currentDetailsViewPeriod}
          paddingTop={paddingTop}
          noticeKey={leaveNoticeItem.noticeKey}
          titleKey={leaveNoticeItem.titleKey}
          bodyTextKey={leaveNoticeItem.bodyTextKey}
          nextButton={leaveNoticeItem.nextButton}
        />
      ))
    }
  }, [
    currentDatePickerViewPeriod,
    currentDetailsViewPeriod,
    customerConfig.leave.timeline,
    leave,
    paddingTop,
    countryCode,
    timelinePeriods
  ])

  const timelineUpdatedNotice: ReactNode = useMemo(
    () => (
      <TimelineUpdatedNoticeWrapper
        leave={leave}
        updateLeave={fetchLeaveTimeline}
        $shown={!currentDatePickerViewPeriod && !currentDetailsViewPeriod}
      />
    ),
    [
      currentDatePickerViewPeriod,
      currentDetailsViewPeriod,
      leave,
      fetchLeaveTimeline
    ]
  )

  const externalNotice: ReactNode = useMemo(() => {
    if (externalNotices.length) {
      return externalNotices.map((externalNoticeItem: IAlert) => (
        <ExternalNoticeWrapper
          key={externalNoticeItem.id}
          noticeAttr={externalNoticeItem}
          onCloseNotice={onCloseExternalNotice}
          $shown={!currentDatePickerViewPeriod && !currentDetailsViewPeriod}
        />
      ))
    }
  }, [
    currentDatePickerViewPeriod,
    currentDetailsViewPeriod,
    externalNotices,
    onCloseExternalNotice
  ])

  const timelineView: ReactNode = useMemo(
    () => (
      <TimelineContainer ref={containerRef}>
        <TimelineDesktopHeader timelineHeaderRef={timelineHeaderRef} />
        <NoticeContainer $paddingTop={paddingTop}>
          {externalNotice}
          {eligibilityNotice}
          {shouldShowNextStepsNotice(leave, customerConfig) &&
            viewNextStepsNotice}
          {leaveNotice}
          {timelineUpdatedNotice}
        </NoticeContainer>
        <TimelineViewWrapper
          onBabyHasArrivedClick={onBabyHasArrivedClick}
          selectedDetailsPeriod={currentDetailsViewPeriod}
          selectedDatePickerPeriod={currentDatePickerViewPeriod}
          detailsView={currentDetailsViewPeriod && detailsView}
          onDetailsClick={onDetailsClick}
          onDatePickerClick={onDatePickerClick}
          showsDetailsView={showsDetailsView}
          showsDatePicker={showsDatePicker}
          getTopOffset={getTopOffset}
          $paddingTop={paddingTop}
        />
      </TimelineContainer>
    ),
    [
      paddingTop,
      externalNotice,
      eligibilityNotice,
      leave,
      customerConfig,
      viewNextStepsNotice,
      leaveNotice,
      timelineUpdatedNotice,
      onBabyHasArrivedClick,
      currentDetailsViewPeriod,
      currentDatePickerViewPeriod,
      detailsView,
      onDetailsClick,
      onDatePickerClick,
      showsDetailsView,
      showsDatePicker,
      getTopOffset
    ]
  )

  return (
    <GlobalContainer ref={globalContainerRef}>
      {timelineView}
      <TimelineTutorial />
    </GlobalContainer>
  )
})

PageDesktop.displayName = 'TimelinePageDesktop'

export default PageDesktop
