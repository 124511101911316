import React from 'react'
import styled, { css } from 'styled-components'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'
import ClaimUpdateButtons from '../ClaimUpdateButtons'

interface IProps {
  name: string
  leaveType: ILeaveType
  checked: boolean
  onChecked: (checked: boolean) => void
  onBackClick: () => void
  onNextClick: () => void
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.theme.isDesktop ? '32px' : '16px')};
  min-height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          margin: 0 auto;
          align-items: center;
        `}

  ${mobileMaxWidthMixin};
`

const Text = styled(ReactMarkdown)`
  white-space: pre-wrap;
  padding-left: 17px;
  line-height: 24px;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.dark60};

  h2 {
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    color: ${(props: any) => props.theme.colors.dark80};

    ${props =>
      props.theme.isDesktop
        ? css`
            margin: 0 auto 16px;
            font-size: 22px;
          `
        : css`
            margin: auto;
            font-size: 20px;
          `}
  }

  ol {
    list-style: auto;
    white-space: normal;
    margin-block-end: -20px;
    font-weight: 500;
  }
`

const CheckboxConfirmationWrapper = styled(CheckboxConfirmationView)`
  width: 100%;
`

const IntroductionView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name, leaveType, checked, onChecked, onBackClick, onNextClick } =
    props

  return (
    <>
      <ContentContainer>
        <Text>
          {t([
            `claimUpdate.${name}.text_${leaveType}`,
            `claimUpdate.${name}.text`
          ])}
        </Text>
        <CheckboxConfirmationWrapper
          confirmed={checked}
          translationKey={`claimUpdate.${name}.checkBoxText`}
          onChange={() => {
            onChecked(!checked)
          }}
          fontSize={'16px'}
        />
      </ContentContainer>
      <ClaimUpdateButtons
        backTitle={t(`claimUpdate.${name}.control.cancel`)}
        onBackClick={onBackClick}
        nextTitle={t(`claimUpdate.${name}.control.submit`)}
        onNextClick={onNextClick}
      />
    </>
  )
})

IntroductionView.displayName = 'IntroductionView'

export default IntroductionView
