import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PregnancyChangeStepView } from '../PregnancyChangeStepView'
import { timelineRoute } from 'src/routes/constants'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'

interface IProps {
  name: string
}

export const PregnancyChangeSuccessView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { name } = props
  const { expectedChildren }: IPregnancyLossContext =
    useContext(PregnancyLossContext)

  return (
    <PregnancyChangeStepView
      name={name}
      onNextClicked={() => {
        navigate(timelineRoute)
      }}
      isNextDisabled={false}
      nextButtonText={
        expectedChildren === 'Multiple'
          ? t('common.continue')
          : t('common.goToTimeline')
      }
    />
  )
})

PregnancyChangeSuccessView.displayName = 'PregnancyChangeSuccessView'

export default PregnancyChangeSuccessView
