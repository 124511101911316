import React from 'react'
import { IPeriodFilter } from 'src/components/JourneyMap/Filters'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import FiltersTitle from '../FiltersTitle'
import PeriodRow from '../PeriodRow'

interface IProps {
  className?: string
  periods?: IPeriodFilter[]
  onPeriodClick: (index: number) => void
  title: string
}

const Container = styled.div`
  width: 100%;
`

const FiltersLeavePhase = React.memo(
  (props: IProps) => {
    const { periods, onPeriodClick, title, className } = props
    const { t } = useTranslation()

    return (
      <Container
        className={className}
        role={'region'}
        aria-label={t('common.accessibilityText.regionJourneyFiltersByPhase')}
      >
        <FiltersTitle>{title}</FiltersTitle>
        {periods.map((period: IPeriodFilter, index: number) => (
          <PeriodRow
            key={period.type}
            type={period.type}
            onClick={onPeriodClick}
            index={index}
            checked={period.checked}
            disabled={period.disabled}
          />
        ))}
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.periods === nextProps.periods
)

FiltersLeavePhase.displayName = 'FiltersLeavePhase'

export default FiltersLeavePhase
