import {
  DatePickerKeyDate,
  IGetPeriodConfigInput,
  ITimelinePeriodConfig
} from '../index'
import LeaveDateSinglePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateSinglePicker'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'
import moment from 'moment'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent: LeaveDateSinglePicker,
  datePickerKeyDate: 'startDate' as DatePickerKeyDate
})
