import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import HrAdminCardHeader from 'src/features/HrAdmin/components/Card/Header'
import { IHrAdminFilter } from 'src/features/HrAdmin/components/Card/Filter'

interface IProps {
  title?: string
  description?: string
  filters?: IHrAdminFilter[]
  rightLabel?: string
  children: ReactNode | ReactNode[]
  className?: string
}

const BlocksContainer = styled.div`
  display: flex;
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 16px;
        `
      : css`
          padding: 12px;
        `}
`

const Container = styled.div`
  flex: 1;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 12px 8px;
          border-radius: 8px;
        `
      : css`
          margin: 12px 0;
        `}
`

const HrAdminCardHeaderWrapper = styled(HrAdminCardHeader)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 16px 16px 0;
        `
      : css`
          margin: 12px 12px 0;
        `}
`

export const HrAdminCard = React.memo((props: IProps) => {
  const { title, description, children, filters, className, rightLabel } = props
  return (
    <Container className={className}>
      {title && (
        <HrAdminCardHeaderWrapper
          title={title}
          description={description}
          filters={filters}
          rightLabel={rightLabel}
        />
      )}
      <BlocksContainer>{children}</BlocksContainer>
    </Container>
  )
})

HrAdminCard.displayName = 'HrAdminCard'

export default HrAdminCard
