import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from 'src/components/LoadingSpinner'
import NoRecordsView from 'src/features/Notifications/components/NoRecordsView'
import Item from './NotificationsListItem'

interface IProps {
  className?: string
  items: INotification[]
  fetching: boolean
  useAlternativeItems?: boolean
  onItemClicked?: (notification: INotification) => void
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotificationsList = React.memo((props: IProps) => {
  const { className, items, fetching, useAlternativeItems, onItemClicked } =
    props

  const hasItems = items && items.length !== 0

  const itemViews: any = items.map((notification: INotification) => {
    const itemProps: any = {
      key: notification.id,
      notification,
      onClick: () => {
        if (onItemClicked) {
          onItemClicked(notification)
        }
      }
    }

    return (
      <Item
        {...itemProps}
        key={itemProps.key}
        isAlternativeView={useAlternativeItems}
      />
    )
  })

  return (
    <>
      {hasItems && <ul className={className}>{itemViews}</ul>}
      {!hasItems && !fetching && <NoRecordsView />}
      {!hasItems && fetching && <LoadingSpinner fadesIn fullScreen />}
    </>
  )
})

NotificationsList.displayName = 'NotificationsList'

export default NotificationsList
