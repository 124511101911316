import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import Info from 'src/features/Timeline/components/common/Info'
import Buttons from 'src/features/Timeline/components/common/Buttons'
import RequestClaimUpdateButton from 'src/features/Timeline/components/common/RequestClaimUpdateButton'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import TimelineView from 'src/features/Timeline/components/vertical/TimelineView'
import SubtypeSelector from 'src/features/Timeline/components/common/SubtypeSelector'
import {
  TIMELINE_DETAILS_PUSH_DURATION,
  TIMELINE_DETAILS_PUSH_EASING
} from 'src/features/Timeline/components/vertical/animationConstants'
import DetailsView from 'src/features/Timeline/components/vertical/DetailsView'
import Title from 'src/features/Timeline/components/vertical/PageMobile/components/Title'
import { printDisplayNoneMixin } from 'src/theme/utils'
import EligibilityNotice from 'src/features/Timeline/components/common/EligibilityNotice'
import LeaveApprovalSelector from 'src/features/Timeline/components/common/LeaveApprovalSelector'
import { shouldShowSubtypeSelector } from 'src/utils/leaveUtils'
import { ITimelinePeriod } from 'src/react-app-env'
import { isDraftLeave, isNewLeave } from 'src/utils/leaveStatusUtils'
import SupplementPayControlBar from 'src/features/Timeline/components/common/SupplementPayControlBar'
import { isGoogle } from 'src/utils/userUtils'
import SharedContext from 'src/contexts/SharedContext'
import VacationBalanceEditor from '../../common/VacationBalanceEditor'
import ViewNextStepsNotice from 'src/features/Timeline/components/common/ViewNextStepsNotice'
import { shouldShowNextStepsNotice } from 'src/utils/viewNextStepsNotice'
import LeaveNotice from '../../common/LeaveNotice'
import TimelineUpdatedNotice from '../../common/TimelineUpdatedNotice'
import SyncStatusView from 'src/components/SyncStatusView'
import { LeaveNoticeItem } from 'src/config/customers/config'
import ExternalNotice from 'src/features/Timeline/components/common/ExternalNotice'
import { isBondingPeriodInPast } from 'src/utils/periodUtils'

const GlobalContainer = styled.div`
  width: 100vw;
  overflow: hidden;
  ${printDisplayNoneMixin}
`

const containerHideKeyframes = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-100vw, 0);
    opacity: 0;
  }
`

const containerShowKeyframes = keyframes`
  0% {
    transform: translate(-100vw, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`

const TimelineContainer = styled.div<{ $hiding: boolean; $showing: boolean }>`
  overflow-y: auto;
  height: 100%;

  ${props => {
    const { $hiding, $showing } = props
    if ($hiding || $showing) {
      return css`
        animation: ${$hiding ? containerHideKeyframes : containerShowKeyframes}
          ${TIMELINE_DETAILS_PUSH_DURATION}ms ${TIMELINE_DETAILS_PUSH_EASING}
          forwards;
      `
    }
  }}
`

const InnerTimelineContainer = styled.div<{ $isShowButtons: boolean }>`
  background: ${props => props.theme.colors.backgroundColor};
  padding-bottom: ${props => (props.$isShowButtons ? '64px' : '16px')};
`

const buttonsHideKeyframes = keyframes`
  0% {
    bottom: 0;
  }
  50% {
    bottom: -56px;
  }
  100% {
    bottom: -56px;
  }
`

const buttonsShowKeyframes = keyframes`
  0% {
    bottom: -56px;
  }
  50% {
    bottom: -56px;
  }
  100% {
    bottom: 0;
  }
`

const appearanceMixin = css<{ $hiding: boolean; $showing: boolean }>`
  ${props => {
    const { $hiding, $showing } = props
    if ($hiding || $showing) {
      return css`
        animation: ${$hiding ? buttonsHideKeyframes : buttonsShowKeyframes}
          ${TIMELINE_DETAILS_PUSH_DURATION}ms ${TIMELINE_DETAILS_PUSH_EASING}
          forwards;
      `
    }
  }}
`

const ButtonsWrapper = styled(Buttons)<any>`
  background: ${props => props.theme.colors.light90};
  padding: 8px 0;
  margin: 0 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.timeline.mobileButtons};

  ${appearanceMixin};
`

const RequestClaimUpdateButtonWrapper = styled(RequestClaimUpdateButton)<any>`
  background: ${props => props.theme.colors.light90};
  padding-top: 8px;
  padding-bottom: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.timeline.mobileButtons};

  ${appearanceMixin};
`

const HeaderContainer = styled.div`
  background: ${props => props.theme.colors.light100};
  padding: 16px;
  box-shadow: 0 4px 13px rgb(98 106 116 / 10%);
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const SubtypeSelectorWrapper = styled(SubtypeSelector)`
  margin: 12px 0 0;
`

const LeaveApprovalSelectorWrapper = styled(LeaveApprovalSelector)`
  margin: 12px 0 0;
`

const detailsShownKeyframes = keyframes`
  0% {
    transform: translate(-100vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`

const detailsHiddenKeyframes = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100vw, 0);
  }
`

const DetailsWrapper = styled(DetailsView)<{ $shown: boolean }>`
  ${props => css`
    animation: ${props.$shown ? detailsShownKeyframes : detailsHiddenKeyframes}
      ${TIMELINE_DETAILS_PUSH_DURATION}ms ${TIMELINE_DETAILS_PUSH_EASING}
      forwards;
  `}
`

let animationTimeoutId: number

const PageMobile = React.memo(() => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    subtypes,
    subtype,
    countryCode,
    onBabyHasArrivedClicked,
    onLeaveSubtypeChanged,
    onUseSupplementPayChanged,
    totalLeaveDays,
    onApprovedByTpaChanged,
    isLeaveDurationPickerOpened,
    timelinePeriods,
    currentDatePickerViewPeriod,
    setCurrentDatePickerViewPeriod,
    onVacationBalanceChanged,
    fetchLeaveTimeline,
    externalNotices,
    onCloseExternalNotice,
    setShowVacationMobileView,
    onRequestClaimUpdateClick
  } = context
  const { customer, customerConfig } = useContext(SharedContext)

  const { type, approvedByTPA, metadata } = leave
  const { t } = useTranslation()

  const [detailsShown, setDetailsShown] = useState(false)
  const [detailsAreShowing, setDetailsAreShowing] = useState(false)
  const [detailsAreHiding, setDetailsAreHiding] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isDatePicker, setIsDatePicker] = useState(false)

  const timelineContainerRef: any = useRef(null)

  const useTpaApproval: boolean = customerConfig.leave.timeline.useTpaApproval(
    type,
    countryCode
  )
  const showApprovedDuration: boolean =
    customerConfig.leave.timeline.showApprovedDuration(leave)

  useEffect(
    () => () => {
      clearTimeout(animationTimeoutId)
    },
    []
  )

  useEffect(() => {
    if (detailsShown) {
      setScrollPosition(timelineContainerRef.current.scrollTop)
    } else {
      timelineContainerRef.current.scrollTop = scrollPosition
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsShown])

  useEffect(() => {
    // TODO: This is a temporary solution and needs to be reworked.
    // Used to ensure that when editing the timeline, the period in the date picker is correctly updated and reassigned if the period search by RefID is used.
    // This code is duplicated in the Desktop page.
    if (currentDatePickerViewPeriod?.timelineConfig.usePeriodRefIdSearch) {
      const updatedPeriod = timelinePeriods?.find(
        period =>
          currentDatePickerViewPeriod?.refID &&
          period.refID === currentDatePickerViewPeriod?.refID &&
          period.appearance === 'Standard'
      )
      if (updatedPeriod) {
        setCurrentDatePickerViewPeriod(updatedPeriod)
      }
    }

    const dirtyPeriod = timelinePeriods?.find(period => period.isDirty)
    if (dirtyPeriod) {
      setCurrentDatePickerViewPeriod(dirtyPeriod)
      if (!isLeaveDurationPickerOpened) {
        onDatePickerClick(dirtyPeriod)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelinePeriods])

  useEffect(() => {
    if (
      currentDatePickerViewPeriod &&
      isLeaveDurationPickerOpened &&
      !detailsShown
    ) {
      onDatePickerClick(currentDatePickerViewPeriod)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDatePickerViewPeriod, isLeaveDurationPickerOpened])

  const isNewOrDraft: boolean =
    isNewLeave(leave.status) || isDraftLeave(leave.status)

  const isShowButtons: boolean = isNewOrDraft || leave.isDirty
  const canRequestClaimUpdate: boolean =
    !leave.isDirty &&
    metadata?.canRequestClaimUpdate &&
    !isLeaveDurationPickerOpened

  const subtypeSelector: ReactNode = useMemo(
    () =>
      shouldShowSubtypeSelector(leave, subtypes) && (
        <SubtypeSelectorWrapper
          subtype={subtype}
          subtypes={subtypes}
          onLeaveSubtypeChanged={onLeaveSubtypeChanged}
          appearance={'timeline_mobile'}
        />
      ),
    [onLeaveSubtypeChanged, subtype, leave, subtypes]
  )

  const supplementPayControl: ReactNode = useMemo(
    () =>
      customerConfig.leave.timeline.hasSupplementPay(leave) && (
        <SupplementPayControlBar
          canUseSupplementPay={metadata?.canUseSupplementPay}
          useSupplementPay={metadata?.useSupplementPay}
          sickHoursToBeUsed={metadata?.sickHoursToBeUsed}
          vacationHoursToBeUsed={metadata?.vacationHoursToBeUsed}
          onUseSupplementPayChanged={onUseSupplementPayChanged}
          disabled={!metadata?.canUseSupplementPay}
          leaveType={type}
          leaveSubtype={leave.subtype}
        />
      ),
    [onUseSupplementPayChanged, metadata, customerConfig, type, leave]
  )

  const vacationBalanceEdit: ReactNode = useMemo(
    () =>
      customerConfig.leave.timeline.showVacationBalanceEdit(leave) && (
        <VacationBalanceEditor
          metadata={metadata}
          onVacationBalanceChanged={onVacationBalanceChanged}
          onShowMobile={() => setShowVacationMobileView(true)}
        />
      ),
    [
      customerConfig,
      leave,
      metadata,
      onVacationBalanceChanged,
      setShowVacationMobileView
    ]
  )

  const leaveApprovalSelector: ReactNode = useMemo(
    () =>
      useTpaApproval && (
        <LeaveApprovalSelectorWrapper
          value={approvedByTPA}
          onValueChanged={onApprovedByTpaChanged}
          disabled={isLeaveDurationPickerOpened}
        />
      ),
    [
      approvedByTPA,
      onApprovedByTpaChanged,
      isLeaveDurationPickerOpened,
      useTpaApproval
    ]
  )

  const pushDetails = useCallback(
    (selectedPeriod: ITimelinePeriod) => {
      setCurrentDatePickerViewPeriod(selectedPeriod)
      setDetailsShown(true)
      setDetailsAreShowing(true)
      animationTimeoutId = window.setTimeout(() => {
        setDetailsAreShowing(false)
      }, TIMELINE_DETAILS_PUSH_DURATION)
    },
    [setCurrentDatePickerViewPeriod]
  )

  const popDetails = useCallback(() => {
    setDetailsShown(false)
    setDetailsAreHiding(true)
    animationTimeoutId = window.setTimeout(() => {
      setCurrentDatePickerViewPeriod(null)
      setDetailsAreHiding(false)
      setIsDatePicker(false)
    }, TIMELINE_DETAILS_PUSH_DURATION)
  }, [setCurrentDatePickerViewPeriod])

  const onBabyHasArrivedClick = useCallback(() => {
    onBabyHasArrivedClicked()
  }, [onBabyHasArrivedClicked])

  const onDetailsClick = useCallback(
    (period: ITimelinePeriod) => {
      pushDetails(period)
    },
    [pushDetails]
  )

  const onDatePickerClick = useCallback(
    (period: ITimelinePeriod) => {
      setIsDatePicker(true)
      pushDetails(period)
    },
    [pushDetails]
  )

  const onExit = useCallback(() => {
    setCurrentDatePickerViewPeriod(null)
    popDetails()
  }, [setCurrentDatePickerViewPeriod, popDetails])

  const viewNextStepsNotice: ReactNode = useMemo(
    () => (
      <ViewNextStepsNotice top={0} type={leave.metadata?.pregnancyLossType} />
    ),
    [leave]
  )

  const isGoogleMiscarriage = useMemo(
    () => isGoogle(customer) && leave.type === 'Miscarriage',
    [customer, leave]
  )

  const timelineUpdatedNotice: ReactNode = useMemo(
    () => (
      <TimelineUpdatedNotice leave={leave} updateLeave={fetchLeaveTimeline} />
    ),
    [leave, fetchLeaveTimeline]
  )

  const leaveNotice: ReactNode = useMemo(() => {
    const leaveNoticeItems = customerConfig.leave.timeline.getLeaveNoticeItems(
      leave,
      countryCode,
      timelinePeriods
    )
    if (leaveNoticeItems.length) {
      return leaveNoticeItems.map((leaveNoticeItem: LeaveNoticeItem) => (
        <LeaveNotice
          key={leaveNoticeItem.noticeKey}
          leave={leave}
          noticeKey={leaveNoticeItem.noticeKey}
          titleKey={leaveNoticeItem.titleKey}
          bodyTextKey={leaveNoticeItem.bodyTextKey}
          nextButton={leaveNoticeItem.nextButton}
        />
      ))
    }
  }, [customerConfig.leave.timeline, leave, countryCode, timelinePeriods])

  const externalNotice: ReactNode = useMemo(() => {
    if (externalNotices.length) {
      return externalNotices.map((externalNoticeItem: IAlert) => (
        <ExternalNotice
          key={externalNoticeItem.id}
          noticeAttr={externalNoticeItem}
          onCloseNotice={onCloseExternalNotice}
        />
      ))
    }
  }, [externalNotices, onCloseExternalNotice])

  const timelineView = (
    <TimelineContainer
      $hiding={detailsAreShowing}
      $showing={detailsAreHiding}
      ref={timelineContainerRef}
    >
      <InnerTimelineContainer
        $isShowButtons={isShowButtons || canRequestClaimUpdate}
      >
        <HeaderContainer>
          <TitleContainer>
            <Title>
              {t('timeline.titles.leaveType', {
                context: `${leave.type}_mobile`
              })}
            </Title>
            {customerConfig.leave.tpaSync.showSyncStatus(leave) && (
              <SyncStatusView tpa={leave?.tpa} />
            )}
          </TitleContainer>
          {subtypeSelector}
          {supplementPayControl}
          {vacationBalanceEdit}
          {leaveApprovalSelector}
        </HeaderContainer>
        {shouldShowNextStepsNotice(leave, customerConfig) &&
          viewNextStepsNotice}
        {!isGoogleMiscarriage && (
          <Info
            buttons={null}
            metadata={metadata}
            totalLeaveDays={totalLeaveDays}
            approvedByTPA={approvedByTPA}
            timelinePeriods={timelinePeriods}
            useTpaApproval={useTpaApproval}
            unusedBonding={leave.unusedBonding}
            showUnplannedBondingTooltipLessThan={
              customerConfig.leave.timeline.showUnplannedBondingTooltipLessThan
            }
            showApprovedDuration={showApprovedDuration}
          />
        )}
        {externalNotice}
        <EligibilityNotice note={leave.note} />
        {leaveNotice}
        {timelineUpdatedNotice}
        <TimelineView
          onDetailsClick={onDetailsClick}
          onDatePickerClick={onDatePickerClick}
          onBabyHasArrivedClick={onBabyHasArrivedClick}
        />
      </InnerTimelineContainer>
    </TimelineContainer>
  )

  const detailsView = (
    <DetailsWrapper
      period={currentDatePickerViewPeriod}
      onExit={onExit}
      $shown={detailsShown}
      shown={detailsShown}
      isDatePicker={isDatePicker}
    />
  )

  const buttonsView: any = (
    <ButtonsWrapper
      $hiding={detailsAreShowing || !isShowButtons}
      $showing={detailsAreHiding || isShowButtons}
    />
  )

  const requestClaimUpdateButtonView: ReactNode = useMemo(
    () =>
      canRequestClaimUpdate && (
        <RequestClaimUpdateButtonWrapper
          hasBackdatedPeriod={timelinePeriods.some((period: any) =>
            isBondingPeriodInPast(leave, period)
          )}
          onClickCallback={onRequestClaimUpdateClick}
          disabled={!metadata?.hasChangesForTPA || metadata?.tpaRequestSent}
          tpaRequestSent={metadata?.tpaRequestSent}
          $hiding={detailsAreShowing}
          $showing={detailsAreHiding}
        />
      ),
    [
      detailsAreShowing,
      detailsAreHiding,
      canRequestClaimUpdate,
      metadata,
      leave,
      timelinePeriods,
      onRequestClaimUpdateClick
    ]
  )

  return (
    <GlobalContainer>
      {(!detailsShown || detailsAreShowing) && timelineView}
      {(!detailsShown || detailsAreShowing || detailsAreHiding) && buttonsView}
      {(!detailsShown || detailsAreShowing || detailsAreHiding) &&
        requestClaimUpdateButtonView}
      {(detailsShown || detailsAreHiding) && detailsView}
    </GlobalContainer>
  )
})

PageMobile.displayName = 'TimelinePageMobile'

export default PageMobile
