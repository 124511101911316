import React from 'react'
import styled from 'styled-components'
import { Icon, MarkdownText } from 'src/UIKit'
import createTheme from 'src/theme'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
const { colors } = createTheme()

interface IProps {
  className?: string
  message: string
}

const SelectedPeriodContainer = styled.div`
  width: 100%;
  display: flex;
`

const StyledIcon = styled(Icon)`
  display: inline;
  margin: 0 16px 0 0;
`

const SelectedPeriodText = styled(MarkdownText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  strong {
    color: ${p => p.theme.colors.dark60};
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }
`

export const dataAttrs = {
  content: () => 'info-message-content'
}

const InfoMessageView = React.memo((props: IProps) => {
  const { message } = props

  return (
    <SelectedPeriodContainer data-testid={dataAttrs.content()}>
      <StyledIcon name={'info_circle'} settings={{ fill: colors.main100 }} />
      <SelectedPeriodText
        allowsParagraph
        urlTransform={TelUrlTransformer}
        components={{
          a: LinkRenderer
        }}
      >
        {message}
      </SelectedPeriodText>
    </SelectedPeriodContainer>
  )
})

InfoMessageView.displayName = 'InfoMessageView'

export default InfoMessageView
