import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Icon } from 'src/UIKit'
import { IIconName } from 'src/react-app-env'
import useKeyActionHandler from 'src/components/hooks/useKeyActionHandler'

export type ContextMenuViewItemType = 'remove'

export interface IContextMenuViewItem {
  type: ContextMenuViewItemType
}

interface IProps {
  className?: string
  onClick: () => void
  iconName: IIconName
  label: string
  ariaLabel: string
}

const ItemContainer = styled.li`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.dark80};
  border: 2px solid transparent;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  &:hover {
    background: ${props => props.theme.colors.main10};
    font-weight: bold;
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main110};
    width: calc(100% - 4px);
  }
`

const ItemLabel = styled.span`
  font-size: 14px;
  line-height: 130%;
`

const ItemIconWrapper = styled(Icon)`
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContextMenuItem = React.memo((props: IProps) => {
  const { className, onClick, iconName, label, ariaLabel } = props
  const containerRef: any = useRef(null)

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 32 && event.target === containerRef.current) {
        event.preventDefault()
        onClick()
      }
    }
    window.addEventListener('keydown', onKeyDown)
  }, [onClick])

  useKeyActionHandler(containerRef.current, onClick)

  return (
    <ItemContainer
      className={className}
      onClick={onClick}
      tabIndex={0}
      aria-label={ariaLabel}
      role={'listitem'}
      ref={containerRef}
    >
      <ItemIconWrapper name={iconName} />
      <ItemLabel>{label}</ItemLabel>
    </ItemContainer>
  )
})

ContextMenuItem.displayName = 'ContextMenuItem'

export default ContextMenuItem
