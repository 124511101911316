import { useEffect, useState } from 'react'

export default (element: any): boolean => {
  const [focused, setFocused] = useState<boolean>(false)

  useEffect(() => {
    if (!element) {
      return
    }

    const onFocus = () => {
      setFocused(true)
    }

    const onBlur = () => {
      setFocused(false)
    }

    element.addEventListener('focusin', onFocus)
    element.addEventListener('mouseover', onFocus)
    element.addEventListener('focusout', onBlur)
    element.addEventListener('mouseout', onBlur)

    return () => {
      element.removeEventListener('focusin', onFocus)
      element.removeEventListener('mouseover', onFocus)
      element.removeEventListener('focusout', onBlur)
      element.removeEventListener('mouseout', onBlur)
    }
  }, [element, setFocused])

  return focused
}
