import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { INavigationItem } from 'src/react-app-env'
import RouteContext from 'src/routes/Context'
import { header } from 'src/constants/frame'

interface IProps {
  data: INavigationItem
  onClick: (props: any) => void
}

const Container = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 10px;
          height: ${header.height(props.theme).px};
        `
      : css`
          margin: 16px 16px 0;

          &:first-child {
            margin-top: 0;
          }
        `}
`

export const Button = styled.button<any>`
  user-select: none;
  line-height: 100%;
  outline: none;
  transition: all 100ms linear;
  border: 2px solid transparent;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 6px 6px 8px;
          color: ${props.$buttonData.disabled && props.theme.colors.dark20};
          margin: 30px 0;
          font-size: 16px;
          font-weight: normal;

          &:focus {
            outline: none;
            color: ${props.theme.colors.dark80};
            border: 2px solid ${props.$primaryColor};
            border-radius: 12px;
          }
        `
      : css`
          font-weight: 400;
          font-size: 16px;
          padding: 0 0 4px;
          color: ${props.$buttonData.disabled
            ? props.theme.colors.dark20
            : props.$buttonData.selected
              ? props.theme.colors.dark80
              : props.theme.colors.dark60};
          margin: 0;

          &:focus {
            color: ${props.theme.colors.dark80};
            border: 2px solid transparent;
            border-radius: 12px;
            outline: 2px solid ${props.theme.colors.main100};
          }

          ${props.$buttonData.selected &&
          css`
            font-weight: 500;
          `}
        `}

  &:hover {
    color: ${props =>
      props.$buttonData.disabled
        ? props.theme.colors.dark20
        : props.theme.colors.dark80};
  }

  ${props =>
    props.$buttonData.selected &&
    css`
      border-bottom: 2px solid ${props.theme.colors.main100};
    `}

  cursor: ${props => (props.$buttonData.disabled ? 'auto' : 'pointer')};
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.error80};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: -12px 0 0 4px;
        `
      : css`
          margin: -2px 0 0 8px;
        `}
`

const Item = React.memo((props: IProps) => {
  const { primaryColor } = useContext(RouteContext)
  const { data, onClick } = props
  const { title } = data
  return (
    <Container>
      <Button
        onClick={() => {
          onClick(data)
        }}
        disabled={data.disabled}
        tabIndex={data.disabled ? -1 : 0}
        aria-disabled={data.disabled}
        $buttonData={data}
        $primaryColor={primaryColor}
      >
        {title}
      </Button>
      {data.showsDot && <Dot />}
    </Container>
  )
})

Item.displayName = 'Item'

export default Item
