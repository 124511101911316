import { QueryResult, useQuery } from '@apollo/client'
import { querySessions } from 'src/graphql/queries'
import moment from 'moment'

export interface IUseSessions {
  loading: boolean
  error: any
  sessions: ISession[]
}

const useSessions = (skip: boolean): IUseSessions => {
  const returnValue: IUseSessions = {
    loading: false,
    error: null,
    sessions: []
  }

  const sessionsResult: QueryResult = useQuery(querySessions, {
    skip,
    fetchPolicy: 'network-only'
  })

  if (skip) {
    return returnValue
  }

  if (sessionsResult.loading) {
    returnValue.loading = true
    return returnValue
  }

  if (sessionsResult.error) {
    returnValue.error = sessionsResult.error
    return returnValue
  }

  const sortedSessions: ISession[] = [...sessionsResult.data.sessions]
  sortedSessions.sort((s1: ISession, s2: ISession) => {
    if (s1.current) {
      return -1
    } else if (s2.current) {
      return 1
    }

    return moment(s2.date).unix() - moment(s1.date).unix()
  })

  returnValue.sessions = sortedSessions

  return returnValue
}

export default useSessions
