import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button, Checkbox, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import withRouter from 'src/components/hooks/useRouter'
import { To, useNavigate } from 'react-router-dom'
import {
  errorPageRoute,
  timelineRoute,
  closeAccountRoute,
  welcomeRoute
} from 'src/routes/constants'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import { csrf } from 'src/graphql/csrf'
import { consentUrl, getPrivacyPolicyUrl, getTermsUrl } from 'src/config'
import { createLeaveButtons } from 'src/constants/frame'
import { authInfo } from 'src/graphql/authInfo'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps } from 'src/react-app-env'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import { captureResponseError } from 'src/utils/sentry'

interface IProps extends IWithQueriesProps {}

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
    `}
`

const TextWrapper = styled(MarkdownText)<any>`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  letter-spacing: 0.15px;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
          font-size: 14px;
        `}
  a {
    color: ${props => props.theme.colors.main100};
    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 40px;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 100%;
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}
`

const ConfirmationTextContainer = styled.span`
  user-select: none;
  cursor: pointer;
  padding: 2px 0 0 18px;
`

const ConfirmationText = styled(MarkdownText)`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ButtonsContainer = styled.div<any>`
  display: flex;
  justify-content: space-between;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 24px;
          width: calc(${createLeaveButtons.width().px} + 16px);
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
          margin: 0 0 16px;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)<any>`
  font-size: 16px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: calc(50% - 8px);
          height: 48px;
        `}
`

const Link = styled.button`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.main100};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const dataAttrs = {
  title: () => 'user-consent-title',
  buttonContinue: () => 'button-continue'
}

enum UserConsentAction {
  Grant = 'grant',
  Reject = 'reject'
}

export const UserConsentPage = React.memo((props: IProps) => {
  const { queries } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const sharedContext: ISharedContext = useContext(SharedContext)
  const getEnableOptOut = authInfo.getEnableOptOut()
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const termsUrl = getTermsUrl()
  const privacyPolicyUrl = getPrivacyPolicyUrl()

  const fetchMe = useCallback(async () => {
    try {
      const userResult: IUser = await queries.fetchMe()
      return userResult
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute)
      }
    }
  }, [navigate, queries])

  useEffect(() => {
    fetchMe().then(user => {
      if (user) {
        redirectByUserRole(user, navigate, sharedContext.apolloClient)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePageTitle('welcome')

  const bodyText: string = useMemo(
    () =>
      getEnableOptOut
        ? t('userConsentPage.bodyWithLink')
        : t('userConsentPage.body'),
    [getEnableOptOut, t]
  )

  const confirmationView: ReactNode = useMemo(
    () => (
      <ConfirmationContainer>
        <Checkbox
          name="checkbox"
          checked={confirmed}
          onChange={() => {
            setConfirmed(!confirmed)
          }}
          ariaLabel={t('userConsentPage.confirmation')}
        />
        <ConfirmationTextContainer
          onClick={() => {
            setConfirmed(!confirmed)
          }}
        >
          <ConfirmationText
            allowsParagraph
            components={{
              a: (linkProps: any) => (
                <a
                  href={linkProps.href}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  aria-label={linkProps.href}
                >
                  {linkProps.children}
                </a>
              )
            }}
          >
            {t('userConsentPage.confirmation', {
              termsUrl,
              privacyPolicyUrl
            })}
          </ConfirmationText>
        </ConfirmationTextContainer>
      </ConfirmationContainer>
    ),
    [confirmed, privacyPolicyUrl, t, termsUrl]
  )
  const consent = useCallback(
    (action: string, routeTo: To) => {
      fetch(consentUrl(), {
        method: 'POST',
        body: JSON.stringify({ action }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf.getToken()
        },
        credentials: 'include'
      })
        .then(async response => {
          if (!response.ok) {
            captureResponseError(response)
            throw Error('invalid response')
          }
          if (action === UserConsentAction.Grant) {
            // Trying to redirect to a page depending on the user's role
            await fetchMe().then(user => {
              if (user) {
                redirectByUserRole(user, navigate, sharedContext.apolloClient)
              } else {
                navigate(routeTo)
              }
            })
          } else {
            navigate(routeTo)
          }
        })
        .catch(() => {
          navigate(errorPageRoute)
        })
    },
    [fetchMe, navigate, sharedContext]
  )

  return (
    <Container>
      <H1 id={'title'} data-testid={dataAttrs.title()}>
        {t('userConsentPage.title')}
      </H1>
      <TextWrapper
        allowsParagraph
        components={{
          a: (linkProps: any) => (
            <Link onClick={() => navigate(closeAccountRoute)}>
              {linkProps.children}
            </Link>
          )
        }}
      >
        {bodyText}
      </TextWrapper>
      {confirmationView}
      <ButtonsContainer>
        <ButtonWrapper
          onClick={() => consent(UserConsentAction.Reject, welcomeRoute)}
          appearance={'cancel'}
        >
          {t('common.cancel')}
        </ButtonWrapper>
        <ButtonWrapper
          data-testid={dataAttrs.buttonContinue()}
          onClick={() => consent(UserConsentAction.Grant, timelineRoute)}
          disabled={!confirmed}
        >
          {t('common.continue')}
        </ButtonWrapper>
      </ButtonsContainer>
    </Container>
  )
})

UserConsentPage.displayName = 'UserConsentPage'

export default withRouter(withQueries(UserConsentPage))
