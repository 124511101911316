import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import Input from '../Input'
import {
  Wrapper,
  TitleWrapper,
  PopoverArrowWrapper,
  OptionWrapper,
  OptionsContainerWrapper
} from '../Dropdown'
import { useTranslation } from 'react-i18next'

interface IProps {
  title: string
  selected: string
  onChange: (value: string) => void
  options: string[]
  placeholder: string
  disabledOptionIndexes?: number[]
}

const SelectWrapper = styled.div<{ $isOpen: boolean }>`
  grid-area: select;
  width: 100%;
  background: ${props => props.theme.colors.light100};
  margin: 6px 0 0;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  outline: none;
  transition: all 100ms;
  overflow: ${props => (props.$isOpen ? `none` : `hidden`)};
`

const OptionsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border: 1px solid ${props => props.theme.colors.dark50};
`

export const dataAttrs = {
  dropdown: () => 'dropdown',
  option: () => 'dropdown-option'
}

const NotFoundTitle = styled.p`
  color: ${props => props.theme.colors.dark50};
  padding: 12px 0 12px 32px;
`

export const Dropdown = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    options,
    selected,
    placeholder,
    title,
    onChange,
    disabledOptionIndexes
  } = props
  const wrapperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState(selected)

  const filteredOptions = useMemo(
    () => options.filter(o => o.toLowerCase().startsWith(filter.toLowerCase())),
    [options, filter]
  )

  useEffect(() => {
    const onDocumentClick = (event: Event) => {
      const { current } = wrapperRef
      if (!current) {
        return
      }

      if (!current.contains(event.target)) {
        setFilter(selected)
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', onDocumentClick)

    return () => {
      document.removeEventListener('mousedown', onDocumentClick)
    }
  }, [filter, selected])

  const onClick = useCallback((): any => {
    if (!isOpen) {
      setFilter('')
    }
    setIsOpen(true)
  }, [isOpen])

  const onOptionSelected = useCallback(
    (option: string) => {
      onChange(option)
      setFilter(option)
      setIsOpen(false)
    },
    [onChange]
  )

  const optionsView: ReactNode = useMemo(() => {
    if (filteredOptions.length < 1) {
      return (
        <NotFoundTitle>{t('common.accessibilityText.notFound')}</NotFoundTitle>
      )
    }
    return filteredOptions.map((opt, index) => {
      const disabled: boolean = disabledOptionIndexes
        ? disabledOptionIndexes.indexOf(index) !== -1
        : false
      return (
        <OptionWrapper
          data-testid={dataAttrs.option()}
          key={opt}
          onClick={() => onOptionSelected(opt)}
          $disabled={disabled}
          onKeyDown={(event: SyntheticEvent) => {
            const { keyCode } = event as any
            if (keyCode === 13 || keyCode === 32) {
              onOptionSelected(opt)
            }
          }}
          tabIndex={disabled ? -1 : 0}
        >
          {opt}
        </OptionWrapper>
      )
    })
  }, [t, disabledOptionIndexes, onOptionSelected, filteredOptions])

  const optionsContainerView: ReactNode = useMemo(
    () => (
      <OptionsContainerWrapper>
        <OptionsContainer>{optionsView}</OptionsContainer>
      </OptionsContainerWrapper>
    ),
    [optionsView]
  )

  const inputView: ReactNode = useMemo(
    () => (
      <Input
        onChange={e => {
          onOptionSelected('')
          setIsOpen(true)
          setFilter(e.target.value)
        }}
        onFocus={onClick}
        value={filter}
        placeholder={selected || placeholder}
        alt={'text'}
        name={'input'}
        type={'string'}
      />
    ),
    [placeholder, onClick, filter, selected, onOptionSelected]
  )

  const arrowView: ReactNode = useMemo(
    () => <PopoverArrowWrapper isOpen={isOpen} pointerEvents={'none'} />,
    [isOpen]
  )

  return (
    <Wrapper ref={wrapperRef}>
      <TitleWrapper>{title}</TitleWrapper>
      <SelectWrapper data-testid={dataAttrs.dropdown()} $isOpen={isOpen}>
        {inputView}
        {isOpen && optionsContainerView}
      </SelectWrapper>
      {arrowView}
    </Wrapper>
  )
})

Dropdown.displayName = 'Dropdown'

export default Dropdown
