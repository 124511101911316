import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import ResetFiltersButton from 'src/components/JourneyMap/ResetFiltersButton'
import ApplyFiltersButton from 'src/components/JourneyMap/ApplyFiltersButton'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  className?: string
  onResetFilters?: () => void
  onApplyFilters?: () => void
  totalItemsCount: number
  currentItemsCount: number
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px 0;

  ${props =>
    props.theme.isDesktop
      ? css`
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          flex-direction: column;
          justify-content: center;
        `}
`

const ItemsShownWrapper = styled(MarkdownText)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const Stats = React.memo(
  (props: IProps) => {
    const {
      className,
      onResetFilters,
      onApplyFilters,
      currentItemsCount,
      totalItemsCount
    } = props
    const { t } = useTranslation()
    const { isDesktop } = useContext(ScreenContext)

    const text: string = useMemo(
      () =>
        t('journeyMap.itemsShown', {
          count: currentItemsCount,
          totalCount: totalItemsCount
        }),
      [currentItemsCount, totalItemsCount, t]
    )

    return (
      <Container className={className}>
        <div role="status">
          {isDesktop && <ItemsShownWrapper>{text}</ItemsShownWrapper>}
        </div>
        <ResetFiltersButton onResetFilters={onResetFilters} />
        <ApplyFiltersButton onApplyFilters={onApplyFilters} />
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.totalItemsCount === nextProps.totalItemsCount &&
    prevProps.currentItemsCount === nextProps.currentItemsCount &&
    prevProps.onResetFilters === nextProps.onResetFilters &&
    prevProps.onApplyFilters === nextProps.onApplyFilters
)

Stats.displayName = 'Stats'

export default Stats
