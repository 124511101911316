import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, Icon, Input } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { validateEmail } from 'src/utils/validators'
import { isEmpty } from 'lodash'
import SignInContext, {
  ISignInContext
} from 'src/features/Welcome/components/WelcomePage/context'
import ScreenContext from 'src/contexts/ScreenContext'
import moment from 'moment'
import { KEY_ENTER } from 'src/constants/keys'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  name: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
    `}
`

const ButtonWrapper = styled(Button)`
  width: 100%;
`

const Label = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
`

const EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const InputWrapper = styled(Input)`
  &:focus {
    border: 1px solid ${props => props.theme.colors.main110};
    border-radius: 6px;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
    outline: none;
  }
`

const InfoNoteContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`

const InfoNoteText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${props => props.theme.colors.dark60};
`

export const dataAttrs = {
  emailInput: () => 'get-personal-email-input',
  cancelBtn: () => 'get-personal-email-cancel-btn',
  submitBtn: () => 'get-personal-email-submit-btn'
}

const GetPersonalEmail = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props
  const {
    personalEmail,
    setPersonalEmail,
    onNextStage,
    onPrevStage,
    moveToStage,
    setCodeValidTill,
    handleRequestOtp
  }: ISignInContext = useContext(SignInContext)
  const { isDesktop } = useContext(ScreenContext)

  const [value, setValue] = useState<string>(personalEmail)
  const [isRequestingCode, setIsRequestingCode] = useState<boolean>(false)

  const isEmailValid = useMemo(
    () => !isEmpty(value) && validateEmail(value).valid,
    [value]
  )

  const onSendCode = useCallback(async () => {
    if (!isEmailValid || isRequestingCode) {
      return
    }
    setIsRequestingCode(true)
    handleRequestOtp(value)
      .then((data: any) => {
        if (data.attempts_left <= 0) {
          moveToStage('tooManyAttempts')
          return
        }
        setCodeValidTill(moment(data.valid_till))
        setPersonalEmail(value)
        onNextStage()
      })
      .finally(() => setIsRequestingCode(false))
  }, [
    value,
    onNextStage,
    moveToStage,
    isEmailValid,
    setPersonalEmail,
    setCodeValidTill,
    handleRequestOtp,
    isRequestingCode,
    setIsRequestingCode
  ])

  const emailForm = useMemo(
    () => (
      <EmailFormWrapper>
        <Label>{t(`welcomePage.${name}.personalEmail`)}</Label>
        <InputWrapper
          data-testid={dataAttrs.emailInput()}
          autoFocus
          name={'email'}
          type={'email'}
          value={value}
          aria-label={t('welcomePage.accessiblity.personalEmail')}
          placeholder={'your@email.com'}
          alt={t(`welcomePage.${name}.personalEmail`)}
          onChange={event => setValue(event.target.value)}
          onKeyDown={event => {
            const { keyCode } = event as any
            if (keyCode === KEY_ENTER) {
              onSendCode()
            }
          }}
        />
      </EmailFormWrapper>
    ),
    [t, value, setValue, name, onSendCode]
  )

  const infoNote = useMemo(
    () => (
      <InfoNoteContainer>
        <Icon name={'info_circle'} settings={{ fill: colors.main100 }} />
        <InfoNoteText>{t(`welcomePage.${name}.infoNote`)}</InfoNoteText>
      </InfoNoteContainer>
    ),
    [name, t]
  )

  const cancelButton = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.cancelBtn()}
        appearance="cancel"
        onClick={onPrevStage}
      >
        {t('common.back')}
      </ButtonWrapper>
    ),
    [t, onPrevStage]
  )

  const submitButton = useMemo(
    () => (
      <ButtonWrapper
        data-testid={dataAttrs.submitBtn()}
        disabled={!isEmailValid || isRequestingCode}
        onClick={onSendCode}
      >
        {t('common.sendCode')}
      </ButtonWrapper>
    ),
    [t, isEmailValid, onSendCode, isRequestingCode]
  )

  const buttons = useMemo(
    () =>
      isDesktop ? (
        <ButtonsContainer>
          {cancelButton}
          {submitButton}
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          {submitButton}
          {cancelButton}
        </ButtonsContainer>
      ),
    [isDesktop, cancelButton, submitButton]
  )

  return (
    <Container>
      {emailForm}
      {infoNote}
      {buttons}
    </Container>
  )
})

GetPersonalEmail.displayName = 'GetPersonalEmail'

export default GetPersonalEmail
