import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Month = styled.span`
  justify-self: center;
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 20px;
          margin: 4px 0 0;
        `
      : css`
          font-size: 12px;
          margin: 0;
        `}
`

const DateViewMonth = React.memo((props: IProps) => {
  const { className, children } = props

  return <Month className={className}>{children}</Month>
})

DateViewMonth.displayName = 'DateViewMonth'

export default DateViewMonth
