import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const periodTitle: string = input.t(
    'timeline.periodByTimelinePeriodType.Medical'
  )
  return {
    ...baseConfig,
    alerts: input.leave.metadata?.LTDwarning
      ? ['longTermDisabilityMedicalExtend']
      : baseConfig.alerts,
    datePickerComponent: MultiDatePicker,
    periodName: periodTitle.toLowerCase(),
    title: periodTitle
  }
}
