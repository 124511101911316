import React, { useContext, useEffect, useMemo } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveView from '../UI/CreateLeaveView'
import styled from 'styled-components'
import DayButton from 'src/UIKit/DayButton'
import { weekDays } from 'src/utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { MarkdownText } from 'src/UIKit'
import moment, { Moment } from 'moment'

const WorkingDaysContainer = styled.div`
  padding: 0 15px 15px;
  min-width: 100px;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
`

const WorkingDaysTitle = styled(MarkdownText)`
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 20px;
  margin-left: 10px;
`

export const dataAttrs = {
  dayButton: (week: number, day: string) =>
    `setup-two-week-day-button-${week}-${day}`
}

const MAX_WEEK_DAYS = 4
const MAX_TOTAL_DAYS = 7

const weekView = (weekNumber: number, workDays: any, onWorkDayClicked: any) =>
  weekDays.map((day: string) => {
    const w1 = workDays[weekNumber]
    const w2 = workDays[weekNumber === 0 ? 1 : 0]
    const isSelected = workDays[weekNumber].has(day)
    const maxSelected =
      w1.size >= MAX_WEEK_DAYS || w1.size + w2.size >= MAX_TOTAL_DAYS

    return (
      <DayButton
        data-testid={dataAttrs.dayButton(weekNumber, day)}
        key={day}
        $selected={isSelected}
        onClick={() => onWorkDayClicked(weekNumber, day)}
        disabled={!isSelected && maxSelected}
        role={'switch'}
        aria-checked={isSelected}
        aria-label={day}
      >
        {day.toUpperCase().charAt(0)}
      </DayButton>
    )
  })

const format = (date: Moment | null, f = 'MMM DD') =>
  date ? date.format(f) : null

export const SetupTwoWeekSchedule = React.memo(() => {
  const { t } = useTranslation()

  const {
    workDays,
    resetWorkSchedule,
    onWorkDayClicked,
    workScheduleStartDate,
    setWorkScheduleStartDate,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  useEffect(() => {
    setWorkScheduleStartDate(moment().utc().startOf('isoWeek'))
  }, [setWorkScheduleStartDate])

  const weekOneView = useMemo(
    () => weekView(0, workDays, onWorkDayClicked),
    [workDays, onWorkDayClicked]
  )
  const weekTwoView = useMemo(
    () => weekView(1, workDays, onWorkDayClicked),
    [workDays, onWorkDayClicked]
  )

  const [weekOneFrom, weekOneTo] = useMemo(
    () => [
      workScheduleStartDate,
      moment(workScheduleStartDate).endOf('isoWeek')
    ],
    [workScheduleStartDate]
  )
  const weekTwoDate = useMemo(
    () => moment(weekOneFrom)?.add(1, 'week'),
    [weekOneFrom]
  )
  const [weekTwoFrom, weekTwoTo] = useMemo(
    () => [
      moment(weekTwoDate)?.startOf('isoWeek'),
      moment(weekTwoDate)?.endOf('isoWeek')
    ],
    [weekTwoDate]
  )

  const isScheduleValid = useMemo(() => {
    const [w1, w2] = workDays

    return w1.size + w2.size === MAX_TOTAL_DAYS
  }, [workDays])

  return (
    <CreateLeaveView
      name={'twoWeekSchedule'}
      onBackClicked={() => {
        resetWorkSchedule()
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
      isNextDisabled={!isScheduleValid}
    >
      <WorkingDaysContainer>
        <WorkingDaysTitle>
          {t('createLeave.twoWeekSchedule.scheduleForWeek', {
            dateFrom: format(weekOneFrom),
            dateTo: format(weekOneTo)
          })}
        </WorkingDaysTitle>

        {weekOneView}

        <WorkingDaysTitle>
          {t('createLeave.twoWeekSchedule.scheduleForWeek', {
            dateFrom: format(weekTwoFrom),
            dateTo: format(weekTwoTo)
          })}
        </WorkingDaysTitle>
        {weekTwoView}
      </WorkingDaysContainer>
    </CreateLeaveView>
  )
})

SetupTwoWeekSchedule.displayName = 'SetupTwoWeekSchedule'

export default SetupTwoWeekSchedule
