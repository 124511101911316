import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import LeaveContext from 'src/features/Leave/context'
import LeavesListItem from 'src/features/Leave/components/PickLeaveView/components/LeavesListItem'
import { ILeaveGroupWithTypes } from 'src/react-app-env'
import LeavesListItemMobile from 'src/features/Leave/components/PickLeaveView/components/LeavesListItemMobile'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'

export interface ILeaveListItemProps {
  name: string
  enabled: boolean
  onSelected: (group: string) => void
}

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
        `
      : css`
          width: 100%;
        `}
`

export const LeavesList = React.memo(() => {
  const { onLeaveGroupSelected, leaveGroups } = useContext(LeaveContext)
  const { t } = useTranslation()

  const { isDesktop } = useContext(ScreenContext)

  const itemViews = useMemo(
    () =>
      leaveGroups.map((data: ILeaveGroupWithTypes) => {
        if (data.hidden) {
          return null
        }
        const p: ILeaveListItemProps = {
          onSelected: onLeaveGroupSelected,
          ...data
        }
        if (isDesktop) {
          return <LeavesListItem key={data.name} {...p} />
        }

        return <LeavesListItemMobile key={data.name} {...p} />
      }),
    [leaveGroups, onLeaveGroupSelected, isDesktop]
  )

  return (
    <Container
      role={'region'}
      aria-label={t('common.accessibilityText.regionLeavesList')}
    >
      {itemViews}
    </Container>
  )
})

LeavesList.displayName = 'LeavesList'

export default LeavesList
