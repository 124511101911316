import React, { useMemo } from 'react'
import styled from 'styled-components'

interface IProps {
  isFirst?: boolean
  isLast?: boolean
  isActive?: boolean
  children: any
}

const Container = styled.div`
  display: flex;
`

const BlockContainer = styled.div`
  padding: 4px;
  width: 100%;
`

const Dot = styled.div`
  background-color: ${props => props.theme.colors.sub100};
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  border-radius: 4.5px;
`

const DotRing = styled.div`
  border: 2px solid ${props => props.theme.colors.sub100};
  border-radius: 10px;
  min-height: 15px;
  min-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5.5px;
`

const Line = styled.div`
  border-left: 1px dashed ${props => props.theme.colors.sub100};
  height: 100%;
  margin-left: 4px;
`

const EmptySpace = styled.div`
  height: 100%;
`

const TimeView = styled.div`
  display: flex;
  flex-direction: column;
  width: 10px;
  margin-right: 4px;
`

export const dataAttrs = {
  component: () => 'timeline-row'
}

const TimelineRow = React.memo((props: IProps) => {
  const { children, isFirst, isLast, isActive } = props

  const dot = useMemo(
    () =>
      isActive ? (
        <DotRing>
          <Dot />
        </DotRing>
      ) : (
        <Dot />
      ),
    [isActive]
  )

  const timeView = useMemo(
    () => (
      <TimeView>
        {isFirst ? <EmptySpace /> : <Line />}
        {dot}
        {isLast ? <EmptySpace /> : <Line />}
      </TimeView>
    ),
    [isFirst, isLast, dot]
  )
  return (
    <Container data-testid={dataAttrs.component()}>
      {timeView}
      <BlockContainer>{children}</BlockContainer>
    </Container>
  )
})
TimelineRow.displayName = 'TimelineRow'

export default TimelineRow
