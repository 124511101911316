import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  onResetFilters?: () => void
}

const ResetFiltersWrapper = styled.button`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  font-size: 14px;
  color: ${props => props.theme.colors.main90};

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.main110};
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 16px 0 24px;

      &:focus {
        outline: 2px solid ${props.theme.colors.main100};
        outline-offset: 10px;
        border-radius: 5px;
        text-decoration: underline;
      }
    `}
`

const ResetFiltersButton = React.memo((props: IProps) => {
  const { className, onResetFilters } = props
  const { t } = useTranslation()
  if (!onResetFilters) {
    return null
  }
  return (
    <ResetFiltersWrapper className={className} onClick={onResetFilters}>
      {t('common.filters.reset')}
    </ResetFiltersWrapper>
  )
})

ResetFiltersButton.displayName = 'ResetFiltersButton'

export default ResetFiltersButton
