import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { useTranslation } from 'react-i18next'
import usePrevious from 'src/components/hooks/usePrevious'
import Buttons from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Buttons'
import ContainerView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ContainerView'
import DescriptionText from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/DescriptionText'
import Title from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Title'
import { ITimelinePeriod } from 'src/react-app-env'
import { Moment } from 'moment'
import PeriodDurationPicker from '../components/PeriodDurationPicker'
import useDate, { IUseDate } from '../hooks/useDate'
import { StyledDatePicker } from '../components/Datepickers'
import { getAnnualDays, getChanges } from '../methods'
import {
  getAsWeeksPicker,
  getAsDaysPicker,
  getAvailableDaysCount,
  getAvailableWeeksCount,
  getCanBeRemoved,
  getMinWeeksCount,
  getMinDaysCount,
  getSelectedDaysCount,
  getAnnualParams,
  getAsMaxSelector
} from './methods'
import StartDateComponent from './components/StartDateComponent'
import * as Methods from './methods'
import PeriodDurationPickerDay from '../components/PeriodDurationPickerDay'
import InfoView from '../components/InfoView'
import InfoMessageView from '../components/InfoMessageView'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import { ContentContainer } from '../components/ContentContainer'
import SelectedMessage from '../components/SelectedMessage'

interface IProps {
  className?: string
  y?: number
  alignsToTop?: boolean
  bottomOffset?: number
  onExit: () => void
  period: ITimelinePeriod
}

const StartDateAndDurationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const InfoMessageWrapper = styled.div`
  margin-top: 20px;
`

const TitleWrapper = styled(Title)`
  margin-bottom: 20px;
`

const PeriodKeyDatePicker = React.memo((props: IProps) => {
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    leave,
    onNewChanges,
    timelinePeriods,
    leaveHolidays,
    onCalendarActiveDateChanged
  } = context
  const { dueDate } = leave
  const { onExit, period } = props
  const { periodStart, periodEnd, appearance } = period
  const { t } = useTranslation()
  const forceUpdate = useForceUpdate()

  const MIN_AMOUNT_FOR_SELECT_MAX = 7

  const onResize = () => {
    forceUpdate()
  }

  const { annualMaxDays, annualAvailableDays, annualUsedDays } = useMemo(
    () => getAnnualParams(leave, period),
    [leave, period]
  )

  const annualDays: number = useMemo(() => getAnnualDays(period), [period])

  const asWeeksPicker: boolean = useMemo(
    () => getAsWeeksPicker(period),
    [period]
  )

  const asDaysPicker: boolean = useMemo(() => getAsDaysPicker(period), [period])

  const asMaxSelector: boolean = useMemo(
    () => getAsMaxSelector(period),
    [period]
  )

  const endDateCalendarRef = useRef(null)
  const disableWeekends = period.timelineConfig.datePickerDisableWeekends
  const { periodKeyDate, periodPickerInfoMessage, periodPickerTotalSelected } =
    period.timelineConfig

  const isAddable: boolean = appearance === 'Addable'
  const periodStartDateCurrent: Moment = isAddable
    ? periodStart.min
    : periodStart.current
  const periodEndDateCurrent: Moment = useMemo(() => {
    if (!isAddable) {
      return periodEnd.current
    }

    return asWeeksPicker ? periodEnd.min : null
  }, [isAddable, asWeeksPicker, periodEnd])

  const startDate: IUseDate = useDate({
    ...periodStart,
    current: periodStartDateCurrent
  })
  const endDate: IUseDate = useDate({
    ...periodEnd,
    current: !periodEndDateCurrent ? periodEnd.min : periodEndDateCurrent
  })

  const initialSelectedDays: number = useMemo(
    () =>
      annualDays ? annualDays : getSelectedDaysCount({ startDate, endDate }),
    [annualDays, startDate, endDate]
  )

  const [selectedDays, setSelectedDays] = useState<number>(initialSelectedDays)
  const [isSendingChanges] = useState(false)
  const prevLeave: ILeave = usePrevious(leave)
  const [shouldPushChanges, setShouldPushChanges] = useState<boolean>(false)

  const minWeeks: number = useMemo(
    () =>
      getMinWeeksCount({
        startDate,
        endDate,
        periodKeyDate
      }),
    [startDate, endDate, periodKeyDate]
  )

  const minDays: number = useMemo(
    () =>
      getMinDaysCount({
        startDate,
        endDate
      }),
    [startDate, endDate]
  )

  const maxDays: number = annualMaxDays - (annualUsedDays - annualDays)

  const availableWeeks: number = useMemo(
    () =>
      getAvailableWeeksCount({
        startDate,
        endDate,
        periodKeyDate
      }),
    [startDate, endDate, periodKeyDate]
  )

  const availableDays: number = useMemo(
    () =>
      period.type === 'Annual'
        ? annualAvailableDays
        : getAvailableDaysCount({
            startDate,
            endDate,
            periodKeyDate
          }),
    [period, annualAvailableDays, startDate, endDate, periodKeyDate]
  )

  useEffect(() => {
    if (Methods.shouldExit({ isSendingChanges, leave, prevLeave })) {
      requestAnimationFrame(() => {
        onExit()
      })
    }
  }, [isSendingChanges, leave, prevLeave, onExit])

  const pushChanges = useCallback(() => {
    const changes: any = getChanges({
      timelinePeriods,
      period,
      duration: asWeeksPicker ? selectedDays * 7 : selectedDays,
      startDate: leave.dates.leaveStart.current,
      dueDate,
      leave
    })

    onNewChanges(changes)
  }, [
    onNewChanges,
    asWeeksPicker,
    timelinePeriods,
    period,
    selectedDays,
    dueDate,
    leave
  ])

  useEffect(() => {
    if (!shouldPushChanges) {
      return
    }
    pushChanges()
    setShouldPushChanges(false)
  }, [shouldPushChanges, pushChanges, setShouldPushChanges])

  const makeInvalid = useCallback(() => {
    setShouldPushChanges(false)
  }, [setShouldPushChanges])

  const isNothingChanged: boolean = useMemo(
    () => Methods.isNothingChanged({ selectedDays, initialSelectedDays }),
    [selectedDays, initialSelectedDays]
  )

  const makeValid = useCallback(() => {
    requestAnimationFrame(() => {
      if (!isNothingChanged) {
        setShouldPushChanges(true)
      }
    })
  }, [setShouldPushChanges, isNothingChanged])

  const onCancelClicked = useCallback(() => {
    onExit()
  }, [onExit])

  const onConfirm = useCallback(() => {
    Methods.onConfirm({
      onExit,
      onNewChanges,
      leave,
      timelinePeriods,
      period,
      dueDate,
      startDate,
      selectedDays
    })
  }, [
    onExit,
    onNewChanges,
    leave,
    timelinePeriods,
    period,
    dueDate,
    startDate,
    selectedDays
  ])

  const canBeRemoved: boolean = useMemo(
    () => isNothingChanged && getCanBeRemoved(period),
    [period, isNothingChanged]
  )

  const onRemove = useCallback(() => {
    Methods.onRemove({
      onExit,
      onNewChanges,
      leave,
      timelinePeriods,
      period,
      dueDate
    })
  }, [onExit, onNewChanges, leave, timelinePeriods, period, dueDate])

  const buttonsView: ReactNode = useMemo(
    () => (
      <Buttons
        hasChanges={!isNothingChanged || appearance === 'Addable'}
        isConfirmDisabled={false}
        onCancel={onCancelClicked}
        onClose={onExit}
        onConfirm={onConfirm}
        onRemove={canBeRemoved ? onRemove : null}
      />
    ),
    [
      canBeRemoved,
      onCancelClicked,
      onExit,
      onConfirm,
      onRemove,
      appearance,
      isNothingChanged
    ]
  )

  const startDateView: ReactNode = useMemo(() => {
    const date: Moment =
      periodKeyDate === 'periodStart'
        ? endDate.current.clone().subtract(selectedDays, 'days')
        : startDate.current.clone()
    if (period.timelineConfig.addsOneVisualDay) {
      date.add(1, 'day')
    }
    return (
      <StartDateComponent
        text={period.timelineConfig.periodPickerStartDateText}
        date={date}
      />
    )
  }, [startDate, endDate, period, selectedDays, periodKeyDate])

  const durationPicker: ReactNode = useMemo(
    () =>
      (asWeeksPicker && (
        <PeriodDurationPicker
          title={t('common.leaveDuration') + ':'}
          valid
          editable
          minWeeks={minWeeks}
          maxWeeks={availableWeeks}
          days={selectedDays}
          onChanged={(weeks: number) => {
            setSelectedDays(weeks * 7)
          }}
        />
      )) ||
      (asDaysPicker && (
        <PeriodDurationPickerDay
          title={t('common.leaveDurationDays') + ':'}
          valid
          editable
          minDays={minDays}
          maxDays={maxDays}
          days={selectedDays}
          onChanged={(days: number) => {
            setSelectedDays(days)
          }}
        />
      )),
    [
      asWeeksPicker,
      t,
      minWeeks,
      availableWeeks,
      selectedDays,
      asDaysPicker,
      minDays,
      maxDays
    ]
  )

  const onEndDateChanged = useCallback(
    (date: Moment): void => {
      Methods.onEndDateChanged({
        date,
        endDate,
        startDate,
        setSelectedDays,
        makeInvalid,
        makeValid
      })
    },
    [endDate, startDate, setSelectedDays, makeInvalid, makeValid]
  )

  const endDatePicker: ReactNode = useMemo(
    () =>
      !asWeeksPicker &&
      !asDaysPicker && (
        <StyledDatePicker
          calendarRef={endDateCalendarRef}
          title={period.timelineConfig.periodPickerEndDateText}
          momentCurrentMinMax={
            selectedDays > 1
              ? {
                  ...endDate.date,
                  current: startDate.current
                    .clone()
                    .add(selectedDays - 1, 'days')
                }
              : endDate.date
          }
          disableWeekends={disableWeekends}
          placeholder={t('common.selectDate')}
          onDateChanged={onEndDateChanged}
          defaultActiveStartDate={endDate.current || endDate.min}
          holidays={leaveHolidays}
          onOpened={(date: any) => {
            onCalendarActiveDateChanged(date)
          }}
          onActiveStartDateChange={(action: any) => {
            onCalendarActiveDateChanged(action.activeStartDate)
          }}
        />
      ),
    [
      t,
      startDate,
      endDate,
      leaveHolidays,
      onCalendarActiveDateChanged,
      selectedDays,
      onEndDateChanged,
      asWeeksPicker,
      asDaysPicker,
      disableWeekends,
      period
    ]
  )

  const selectedPeriodView: ReactNode = useMemo(() => {
    if (!period?.timelineConfig?.periodPickerSelectedNotice.show) {
      return null
    }
    const currentDays = periodPickerTotalSelected(selectedDays)
    const current = asWeeksPicker ? currentDays / 7 : currentDays
    const available = (asWeeksPicker ? availableWeeks : availableDays) - current
    const useSelectMax =
      availableWeeks >= MIN_AMOUNT_FOR_SELECT_MAX && asMaxSelector

    return (
      <SelectedMessage
        used={current}
        unused={available}
        durationType={asWeeksPicker ? 'week' : 'day'}
        notShowUnused={asDaysPicker}
        useSelectMax={useSelectMax}
        onSelectMax={() =>
          asWeeksPicker
            ? setSelectedDays(availableWeeks * 7)
            : setSelectedDays(availableDays)
        }
      />
    )
  }, [
    asWeeksPicker,
    availableDays,
    availableWeeks,
    periodPickerTotalSelected,
    selectedDays,
    asDaysPicker,
    period,
    asMaxSelector
  ])

  const infoMessageView: ReactNode = useMemo(() => {
    const message = periodPickerInfoMessage(selectedDays, t)
    return (
      message && (
        <InfoMessageWrapper>
          <InfoMessageView message={message} />
        </InfoMessageWrapper>
      )
    )
  }, [selectedDays, periodPickerInfoMessage, t])

  const title = useMemo(
    () =>
      t('timeline.periodPicker.title.duration', {
        periodName: period?.timelineConfig?.periodName
      }),
    [t, period]
  )

  return (
    <ContainerView {...props}>
      <ContentContainer>
        <TitleWrapper>{title}</TitleWrapper>
        {period?.timelineConfig?.datePickerDescription && (
          <DescriptionText margin="0 0 20px">
            {period?.timelineConfig?.datePickerDescription}
          </DescriptionText>
        )}
        <StartDateAndDurationContainer>
          {startDateView}
          {durationPicker}
        </StartDateAndDurationContainer>
        {endDatePicker}
        {selectedPeriodView}
        {infoMessageView}
        {asDaysPicker && (
          <InfoView alertTranslationKey="annualInfo" onResize={onResize} />
        )}
        {buttonsView}
      </ContentContainer>
    </ContainerView>
  )
})

PeriodKeyDatePicker.displayName = 'PeriodKeyDatePicker'

export default PeriodKeyDatePicker
