import React, { Context } from 'react'
import { IHrAdminContext } from 'src/features/HrAdmin/HrAdminContext'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ICustomerConfig } from 'src/config/customers/config'
import { IBannerContext } from 'src/components/InformationBanner/BannerContext'

export interface ISharedContext {
  bannerContext: IBannerContext
  setBannerContext: (context: IBannerContext) => void
  hrAdminContext: IHrAdminContext
  setHrAdminContext: (context: IHrAdminContext) => void
  apolloClient: ApolloClient<NormalizedCacheObject>
  customer: string
  customerConfig: ICustomerConfig
}

const SharedContext: Context<ISharedContext> = React.createContext(null)

export default SharedContext
