import React from 'react'

export interface IPublishedLeaveContext {
  user: IUser
  leave: ILeave
  nextStage: (...args: any[]) => void
  prevStage: () => void
  activateManager: string
  setActivateManager: (_v: string) => void
  setShowProgress: (_v: boolean) => void
}

export default React.createContext(null)
