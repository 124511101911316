import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  linesCount?: number
  lineSpacing?: number
}

const Container = styled.div`
  overflow: hidden;
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
`

const NoteLine = styled.div<{ $lineSpacing: number }>`
  height: ${props => (props.$lineSpacing ? `${props.$lineSpacing}px` : '32px')};
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.dark10};

  &:first-child {
    height: 8px;
  }
`

const PrintNotes = React.memo((props: IProps) => {
  const { className, linesCount, lineSpacing } = props
  const { t } = useTranslation()

  const getNoteLines = () => {
    const count: number = linesCount ? linesCount : 7
    const result: any[] = []
    for (let i = 0; i < count; i++) {
      result.push(<NoteLine key={i.toString()} $lineSpacing={lineSpacing} />)
    }
    return result
  }

  return (
    <Container className={className}>
      {t('common.printNotes')}
      {getNoteLines()}
    </Container>
  )
})

PrintNotes.displayName = 'PrintNotes'

export default PrintNotes
