import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import createTheme from 'src/theme'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'
import { ResponseStrings } from 'src/components/Surveys/SurveyContainer'
import SharedContext from 'src/contexts/SharedContext'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

const { colors } = createTheme()

interface IProps {
  selectedResponse: ResponseStrings
  onAnswer: (response: ResponseStrings) => void
  maxTextLength: number
  isGlobalSurvey: boolean
}

const maxCharacterDefaultLength = 500
const characterThresholdLength = 950
const minRows = 1
const maxRows = 5

const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TextAreaWrapper = styled.textarea<any>`
  outline: none;
  resize: none;
  display: flex;
  width: 100%;
  position: relative;
  height: auto;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark40};
  box-sizing: border-box;
  border-radius: 6px;

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.main100};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          padding: 14px 12px;
        `
      : css`
          font-size: 14px;
          padding: 12px 8px;
          width: 100%;
        `}
`

const TextCountContainer = styled.div<{
  $isGlobalSurvey: boolean
  $isThresholdLength: boolean
}>`
  position: static;
  height: 12px;
  width: 100%;
  margin: 8px 0;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  color: ${props =>
    props.$isGlobalSurvey && props.$isThresholdLength
      ? props.theme.colors.error100
      : props.theme.colors.dark60};

  ${props =>
    props.$isGlobalSurvey &&
    !props.$isThresholdLength &&
    css`
      visibility: hidden;
    `}
`
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  position: static;
  margin: 16px 0 0;
`

const ContentText = styled(ReactMarkdown)`
  margin-left: 18px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

export const dataAttrs = {
  component: () => 'answer-type-text'
}

const AnswerTypeText = React.memo((props: IProps) => {
  const { selectedResponse, onAnswer, maxTextLength, isGlobalSurvey } = props
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { customer } = useContext(SharedContext)
  const textAreaRef: any = useRef(null)
  const [characterCount, setCharacterCount] = useState(
    selectedResponse?.length || 0
  )
  const [rows, setRows] = useState(minRows)
  const maxCharacterLength = maxTextLength || maxCharacterDefaultLength

  useEffect(() => {
    updateRowsCount()
    if (isGlobalSurvey && !selectedResponse) {
      onAnswer('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateRowsCount = useCallback(() => {
    const previousRows = textAreaRef.current.rows
    textAreaRef.current.rows = minRows
    const currentRows: number = Math.floor(
      textAreaRef.current.scrollHeight / (isDesktop ? 29 : 24)
    )

    if (currentRows === previousRows) {
      textAreaRef.current.rows = currentRows
    }

    if (currentRows >= maxRows) {
      textAreaRef.current.rows = maxRows
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight
    }

    setRows(currentRows < maxRows ? currentRows : maxRows)
  }, [isDesktop])

  const handleInputChange = (event: any) => {
    const inputValue = event.target.value
    const utf8Bytes = new TextEncoder().encode(inputValue)
    const byteLength = utf8Bytes.length

    if (byteLength <= maxCharacterLength) {
      onAnswer(inputValue)
      setCharacterCount(byteLength)
    }
    updateRowsCount()
  }

  const infoMessageView: ReactNode = useMemo(
    () => (
      <ContentContainer>
        <Icon name={'info_circle'} settings={{ fill: colors.main100 }} />
        <ContentText
          urlTransform={TelUrlTransformer}
          components={{ a: LinkRenderer }}
        >
          {isGlobalSurvey
            ? t('surveys.infoGeneralTextMessage', {
                companyName: customer
              })
            : t('surveys.infoTextMessage')}
        </ContentText>
      </ContentContainer>
    ),
    [customer, isGlobalSurvey, t]
  )

  return (
    <AnswerContainer data-testid={dataAttrs.component()}>
      <TextAreaWrapper
        ref={textAreaRef}
        rows={rows}
        value={selectedResponse}
        placeholder={t('surveys.typeYourAnswerHere')}
        maxLength={maxCharacterLength}
        onChange={handleInputChange}
        spellCheck={false}
        autoComplete={'off'}
        autoCorrect={'off'}
        autoCapitalize={'off'}
      />
      <TextCountContainer
        $isGlobalSurvey={isGlobalSurvey}
        $isThresholdLength={characterCount >= characterThresholdLength}
      >
        {t(`surveys.textCount`, {
          total: maxCharacterLength,
          current: characterCount
        })}
      </TextCountContainer>
      {infoMessageView}
    </AnswerContainer>
  )
})

AnswerTypeText.displayName = 'AnswerTypeText'

export default AnswerTypeText
