import React, { useCallback, useContext, useMemo } from 'react'
import useAnalytics, {
  AnalyticsNumbersType
} from 'src/graphql/hooks/useAnalytics'
import HrAdminCard from 'src/features/HrAdmin/components/Card'
import Layout from 'src/features/HrAdmin/components/Layout'
import BlockContentValue from 'src/features/HrAdmin/components/BlockContentValue'
import HrAdminBlock from 'src/features/HrAdmin/components/Block'
import { useTranslation } from 'react-i18next'
import { queryAnalyticsActiveUsers } from 'src/graphql/queries'
import ScreenContext from 'src/contexts/ScreenContext'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'

export const AnalyticsActiveUsers = () => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { selectedAnalyticsType, selectedCountryCode }: IHrAdminContext =
    useContext(HrAdminContext)

  const { analytics } = useAnalytics({
    query: queryAnalyticsActiveUsers,
    type: selectedAnalyticsType,
    countryCode: selectedCountryCode,
    kind: 'None',
    period: -1
  })

  const getNumberBlockView = useCallback(
    (type: AnalyticsNumbersType) => {
      const value: number = analytics.numbers[type]

      return (
        <HrAdminBlock title={t(`hrAdmin.analytics.labels.${type}`)}>
          <BlockContentValue
            value={value.toString()}
            showsPlaceholder={value === -1}
          />
        </HrAdminBlock>
      )
    },
    [t, analytics.numbers]
  )

  return useMemo(
    () => (
      <HrAdminCard
        title={t('hrAdmin.analytics.activeUsers.title')}
        description={t('hrAdmin.analytics.activeUsers.description')}
        rightLabel={t('hrAdmin.asOfToday')}
      >
        <Layout minBlockWidth={isDesktop ? 200 : 100}>
          {getNumberBlockView('activeUsers')}
          {getNumberBlockView('planningUsers')}
          {getNumberBlockView('onLeaveUsers')}
          {getNumberBlockView('backAtWorkUsers')}
        </Layout>
      </HrAdminCard>
    ),
    [getNumberBlockView, t, isDesktop]
  )
}

export default AnalyticsActiveUsers
