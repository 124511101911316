import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  children: string
}

const Text = styled.span<any>`
  line-height: 150%;
  text-align: center;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
          margin-top: 24px;
        `
      : css`
          font-size: 14px;
          margin-top: 16px;
        `}
`

const SurveyQuestionViewDescription = React.memo((props: IProps) => {
  const { children } = props

  return <Text>{children}</Text>
})

SurveyQuestionViewDescription.displayName = 'SurveyQuestionViewDescription'

export default SurveyQuestionViewDescription
