import { rgba } from 'polished'

const backgroundColor = '#FFFFFF'

const light20 = rgba(255, 255, 255, 0.1)
const light40 = rgba(255, 255, 255, 0.2)
const light50 = rgba(255, 255, 255, 0.4)
const light60 = rgba(255, 255, 255, 0.6)
const light80 = rgba(255, 255, 255, 0.8)
const light90 = rgba(255, 255, 255, 0.9)
const light95 = rgba(255, 255, 255, 0.95)
const light100 = '#FFFFFF'

const veerLogo = '#55A0F7'
const success = '#008A1D'
const purple = '#874DE1'

const main05 = '#F6FAFF'
const main10 = '#ECF4FE'
const main20 = '#D9EAFD'
const main40 = '#A4C5FC'
const main60 = '#8EBFFA'
const main80 = '#68AAF8'
const main90 = '#087ACC'
const main100 = '#1C6FF7'
const main110 = '#002DCB'

const dark05 = '#F2F3F4'
const dark10 = '#E6E7E8'
const dark20 = '#C5C7CB'
const dark50 = '#80868E'
const dark60 = '#626A74'
const dark80 = '#192433'
const dark100 = '#000D1D'

const error05 = '#FFF2F2'
const error10 = '#FFE6E6'
const error20 = '#FFCCCC'
const error40 = '#FF9999'
const error60 = '#F0B1B0'
const error80 = '#D83837'
const error100 = '#ED0603'

const accent100 = '#D8232A'

const success100 = '#24A840'

const timelineBlockType1GradientTop = '#874DE1'
const timelineBlockType1GradientBottom = '#8E59E1'
const timelineBlockType2GradientTop = '#5D4FEC'
const timelineBlockType2GradientBottom = '#7064E7'

const tax = '#7E74FA'
const timelineLeaveLite = '#A59EFC'
const timelineIncome = '#874DE1'
const timelineIncome66 = '#B72FEF'
const timelineIncomeLite = '#BC9EFC'
const timelinePurple60 = '#7F64D3'

const specialBrown = '#905C1D'
const specialBrownLow = '#FFEAD1'
const specialCritical = '#E86868'
const specialCriticalHigh = '#D04343'
const specialGreen = '#00875F'
const specialGreenHigh = '#06827E'
const specialHigh = '#937ED2'
const specialMedium = '#20A05C'
const specialLow = '#0F97FF'

const dodgerBlue = '#555BFF'
const sub100 = '#5D4FEC'
const sub60 = '#616DD4'

const orangeLight = '#FF6B00'

export default {
  button: {
    default: {
      color: {
        normal: light100,
        hover: light100,
        focus: light100,
        disabled: light100
      },
      background: {
        normal: main100,
        hover: main110,
        focus: main110,
        disabled: main40
      },
      border: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: 'none'
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${main110}`,
        disabled: 'none'
      }
    },
    cancel: {
      color: {
        normal: main100,
        hover: main110,
        focus: main110,
        disabled: main40
      },
      background: {
        normal: 'transparent',
        hover: 'transparent',
        focus: 'transparent',
        disabled: 'transparent'
      },
      border: {
        normal: `1px solid ${main100}`,
        hover: `1px solid ${main110}`,
        focus: `1px solid ${main110}`,
        disabled: `1px solid ${main40}`
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${main110}`,
        disabled: 'none'
      }
    },
    select: {
      color: {
        normal: dark80,
        hover: dark80,
        focus: dark80,
        disabled: light100
      },
      background: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: main100
      },
      border: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: 'none'
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${main110}`,
        disabled: 'none'
      }
    },
    destructive: {
      color: {
        normal: light100,
        hover: light100,
        focus: light100,
        disabled: light100
      },
      background: {
        normal: error80,
        hover: error100,
        focus: error100,
        disabled: error60
      },
      border: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: 'none'
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${error100}`,
        disabled: 'none'
      }
    },
    destructiveBordered: {
      color: {
        normal: error80,
        hover: error100,
        focus: error100,
        disabled: error60
      },
      background: {
        normal: 'transparent',
        hover: 'transparent',
        focus: 'transparent',
        disabled: 'transparent'
      },
      border: {
        normal: `1px solid ${error80}`,
        hover: `1px solid ${error100}`,
        focus: `1px solid ${error100}`,
        disabled: `1px solid ${error60}`
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${error100}`,
        disabled: 'none'
      }
    },
    destructiveUnbordered: {
      color: {
        normal: error80,
        hover: error100,
        focus: error100,
        disabled: error60
      },
      background: {
        normal: 'transparent',
        hover: error10,
        focus: error10,
        disabled: 'transparent'
      },
      border: {
        normal: `none`,
        hover: `none`,
        focus: `none`,
        disabled: `none`
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${error100}`,
        disabled: 'none'
      }
    },
    unbordered: {
      color: {
        normal: main100,
        hover: main110,
        focus: main110,
        disabled: main40
      },
      background: {
        normal: 'transparent',
        hover: main20,
        focus: main20,
        disabled: 'transparent'
      },
      border: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: 'none'
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${main110}`,
        disabled: 'none'
      }
    },
    startUnbordered: {
      color: {
        normal: main100,
        hover: main110,
        focus: main110,
        disabled: main40
      },
      background: {
        normal: light100,
        hover: light100,
        focus: light100,
        disabled: light100
      },
      border: {
        normal: 'none',
        hover: 'none',
        focus: 'none',
        disabled: 'none'
      },
      boxShadow: {
        normal: 'none',
        hover: 'none',
        focus: `0 0 0 3px ${main110}`,
        disabled: 'none'
      }
    }
  },
  colors: {
    main05,
    main10,
    main20,
    main40,
    main60,
    main80,
    main90,
    main100,
    main110,
    success,
    purple,
    backgroundColor,
    dark05,
    dark10,
    dark20,
    dark50,
    dark60,
    dark80,
    dark100,
    light20,
    light40,
    light50,
    light60,
    light80,
    light90,
    light95,
    light100,
    accent100,
    error05,
    error10,
    error20,
    error40,
    error60,
    error80,
    error100,
    success100,
    tax,
    timelineLeaveLite,
    timelineIncome,
    timelineIncomeLite,
    timelineIncome66,
    timelinePurple60,
    dodgerBlue,
    specialCritical,
    specialCriticalHigh,
    specialHigh,
    specialMedium,
    specialLow,
    sub100,
    sub60,
    timelineBlockType1GradientTop,
    timelineBlockType1GradientBottom,
    timelineBlockType2GradientTop,
    timelineBlockType2GradientBottom,
    veerLogo,
    orangeLight,
    specialBrown,
    specialBrownLow,
    specialGreen,
    specialGreenHigh
  },
  table: {
    borderRadius: '8px'
  }
}
