import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button, MarkdownText } from 'src/UIKit'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import { SAVE_CHANGES_TOOLTIP_KEY } from 'src/utils/ls'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { printDisplayNoneMixin } from 'src/theme/utils'

interface IProps {
  children: any
  disabled: boolean
  visible: boolean
  onClose?: () => void
}

const Component = styled(Tippy)`
  &.tippy-box {
    background-color: ${props => props.theme.colors.main100};
    border-radius: 8px;
  }

  .tippy-content {
    background-color: ${props => props.theme.colors.main100};
    padding: 16px;
    border-radius: 8px;
  }

  .tippy-arrow {
    color: ${props => props.theme.colors.main100};
  }

  .tippy-arrow::before {
    transform: scale(1.3);
  }

  ${printDisplayNoneMixin};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`

const Title = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
`

const Body = styled(MarkdownText)`
  font-size: 14px;
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
`

const CloseButton = styled(Button)`
  pointer-events: auto;
  align-self: self-end;
  font-weight: 400;
  font-size: 16px;
  width: 138px;
  height: 40px;
  border-radius: 8px;
`

export const dataAttrs = {
  content: () => 'save-changes-tooltip-content',
  close: () => 'save-changes-tooltip-button-close'
}

const SaveChangesTooltip = React.memo((props: IProps) => {
  const { children, disabled, visible, onClose } = props
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useLocalStorage(
    SAVE_CHANGES_TOOLTIP_KEY,
    { canBeShown: true }
  )

  const content = useMemo(
    () => (
      <ContentContainer data-testid={dataAttrs.content()}>
        <Title>{t('timeline.saveChangesHint.title')}</Title>
        <Body>{t('timeline.saveChangesHint.body')}</Body>
        <CloseButton
          data-testid={dataAttrs.close()}
          onClick={() => {
            if (onClose) {
              onClose()
            }
            setShowTooltip({ canBeShown: false })
          }}
          aria-label={t('timeline.saveChangesHint.closeButton')}
          appearance={'startUnbordered'}
        >
          {t('timeline.saveChangesHint.closeButton')}
        </CloseButton>
      </ContentContainer>
    ),
    [setShowTooltip, t, onClose]
  )

  return (
    <Component
      content={content}
      placement={'bottom-start'}
      offset={[0, 15]}
      zIndex={zIndex.tooltip}
      visible={visible && showTooltip.canBeShown}
      disabled={disabled}
    >
      {children}
    </Component>
  )
})

SaveChangesTooltip.displayName = 'SaveChangesTooltip'

export default SaveChangesTooltip
