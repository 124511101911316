import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import createTheme from 'src/theme'
import { Icon } from 'src/UIKit'

const { colors } = createTheme()

interface IProps {
  absencePeriod: IAbsenceTimelinePeriod
}

const Container = styled.p`
  display: flex;
  gap: 18px;
  color: ${props => props.theme.colors.dark60};
  font-size: 14px;
  line-height: 18.2px;
  align-items: center;
`

export const dataAttrs = {
  component: () => 'manager-unclassified-note'
}

const UnclassifiedNote = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { absencePeriod } = props

  const dateFrom = useMemo(
    () => moment(absencePeriod.startDate).utc().format(DATE_FORMAT),
    [absencePeriod]
  )

  const dateTo = useMemo(
    () => moment(absencePeriod.endDate).utc().format(DATE_FORMAT),
    [absencePeriod]
  )

  return (
    <Container data-testid={dataAttrs.component()}>
      <Icon name={'info_circle'} settings={{ fill: colors.main100 }} flex />
      {t('manager.unclassifiedNote', { dateFrom, dateTo })}
    </Container>
  )
})

UnclassifiedNote.displayName = 'UnclassifiedNote'

export default UnclassifiedNote
