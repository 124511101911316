import React, { useMemo } from 'react'
import styled from 'styled-components'
import Disclaimer from 'src/components/Disclaimer'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { footer } from 'src/constants/frame'
import { authInfo } from 'src/graphql/authInfo'

interface IProps {
  className?: string
}

export const Copyright = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.dark60};
`

const FooterWrapper = styled.footer`
  grid-area: footer;
  align-self: end;
  height: ${footer.height().px};
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.main10};
  background: ${props => props.theme.colors.light100};
  z-index: ${zIndex.footer};
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  ${printDisplayNoneMixin}
`

const Footer = React.memo((props: IProps) => {
  const { className } = props
  const { t } = useTranslation()
  const year: number = useMemo(() => new Date().getFullYear(), [])
  const isCustomerDisabled = authInfo.getCustomerDisabled()

  if (isCustomerDisabled) {
    return null
  }

  return (
    <FooterWrapper
      className={className}
      aria-label={t('common.accessibilityText.region.footer')}
    >
      <Disclaimer />
      <Copyright>{`© ${year} ${t('footer.copyright')}`}</Copyright>
    </FooterWrapper>
  )
})

Footer.displayName = 'Footer'

export default Footer
