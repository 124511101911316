import React, { ReactNode, useContext, useMemo } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import { weekDays, workDays } from 'src/utils/dateUtils'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  activeDays: string[]
  schedule: string[]
  isFiveDaySchedule?: boolean
}

const Container = styled.div`
  display: flex;
`

const DayView = styled.div`
  margin-left: 8px;

  &:not(:first-child) {
    margin-left: 2px;
  }
`

export const dataAttrs = {
  dayView: (day: string) => `data-test-day-view-${day}`
}

const ActiveDaysWeekView = React.memo(
  (props: IProps) => {
    const { isMobile } = useContext(ScreenContext)
    const { activeDays, schedule, isFiveDaySchedule } = props
    const days = isFiveDaySchedule ? workDays : weekDays

    const dayViews: ReactNode[] = useMemo(
      () =>
        days.map((day: string) => {
          const disabled = !!schedule && !schedule.includes(day)
          const iconName = disabled ? 'disabled_day' : 'active_day'
          const extra: any = {
            mobile: isMobile,
            active: activeDays?.includes(day),
            day: day.toLowerCase().charAt(0)
          }
          return (
            <DayView key={day} data-testid={dataAttrs.dayView(day)}>
              <Icon name={iconName} settings={{ extra }} />
            </DayView>
          )
        }),
      [days, activeDays, schedule, isMobile]
    )

    return <Container>{dayViews}</Container>
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

ActiveDaysWeekView.displayName = 'ActiveDaysWeekView'

export default ActiveDaysWeekView
