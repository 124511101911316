import React, { useContext, useEffect, useState } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveView from '../UI/CreateLeaveView'
import OptionSelector from '../OptionSelector'
import { IOption } from 'src/react-app-env'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingSpinner from 'src/components/LoadingSpinner'

interface IProps {
  selectorWidth?: string
}

const OptionSelectorWrapper = styled(OptionSelector)<{ $width: string }>`
  width: ${props => (props.theme.isDesktop ? props.$width : '100%')};
`

export const SelectStateView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<IOption[]>([])

  const {
    fetchStates,
    countryState,
    setCountryState,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  const [initialCountryState] = useState(countryState)

  useEffect(() => {
    fetchStates().then(states =>
      setOptions(states.map(state => ({ key: state, value: state })))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!options.length) {
    return <LoadingSpinner />
  }

  return (
    <CreateLeaveView
      name={'setupState'}
      isNextDisabled={!countryState}
      onBackClicked={() => {
        setCountryState(initialCountryState)
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
    >
      <OptionSelectorWrapper
        $width={props.selectorWidth}
        title={t('createLeave.setupState.selector.title')}
        placeholder={t('createLeave.setupState.selector.placeholder')}
        options={options}
        selectedOption={options.find(option => option.value === countryState)}
        onOptionChange={(option: IOption) => setCountryState(option?.value)}
      />
    </CreateLeaveView>
  )
})

SelectStateView.displayName = 'SelectStateView'

export default SelectStateView
