import * as d3 from 'd3'
import createTheme from 'src/theme'

const { colors } = createTheme()

const ANIMATION_DURATION = 2000
const ANIMATION_DELAY = 500

export default (args: any, parentContainer: any) => {
  const { value } = args
  const { width, height } = parentContainer
    ? parentContainer.getBoundingClientRect()
    : { width: 0, height: 0 }

  const n = 4
  const radius: number = width / 2
  const needleRad = 60
  const pi: number = Math.PI
  const halfPi: number = pi / 2
  const startAngle: number = -halfPi
  const data: number[] = d3.range(startAngle, halfPi, pi / n)
  const mData: number[] = data.slice(0)
  const scale: any = d3.scaleLinear().range([startAngle + halfPi, halfPi])
  const theColors: string[] = [
    colors.main60,
    colors.main60,
    colors.main100,
    colors.main110
  ]
  const colorScale: any = d3
    .scaleLinear()
    .domain([data[0], data[1], data[2], data[3]])
    .range(theColors as any)
    .interpolate(d3.interpolateRgb as any)
  const dataDex: number[] = d3.range(-100, 100, 200 / n)
  const colorScaleDex: any = d3
    .scaleLinear()
    .domain([dataDex[0], dataDex[1], dataDex[2], dataDex[3]])
    .range(theColors as any)
    .interpolate(d3.interpolateRgb as any)

  const svg: any = d3
    .select(parentContainer)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height + ')')

  mData.push(halfPi)

  const arc: any = d3
    .arc()
    .innerRadius(radius - 48)
    .outerRadius(radius)
    .startAngle((d: any) => d)
    .endAngle((d: any, i: number) => mData[i + 1])

  const slice: any = svg.append('g').selectAll('path.slice').data(data)

  slice
    .enter()
    .append('path')
    .attr('d', arc)
    .attr('fill', (d: any) => colorScale(d))

  const needle: any = svg.append('g').append('path').attr('fill', 'white')

  const text: any = svg
    .append('g')
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('width', width / 2)
    .attr('y', '-2')
    .style('font-size', '56px')
    .style('font-weight', 'bold')
    .style('fill', colors.main100)

  const update: any = (oldValue: number, newValue: number) => {
    needle
      .datum({ oldValue })
      .transition()
      .ease(d3.easeExp)
      .delay(ANIMATION_DELAY)
      .duration(ANIMATION_DURATION)
      .attrTween('d', lineTween(newValue))

    text
      .datum({ oldValue })
      .transition()
      .ease(d3.easeExp)
      .delay(ANIMATION_DELAY)
      .duration(ANIMATION_DURATION)
      .tween('text', textTween(newValue))
  }

  const textTween: any = (newValue: number) => (d: any) => {
    const i = d3.interpolate(d.oldValue, newValue)
    return (t: any) => {
      text.text(d3.format(',.0f')(scale.invert(i(t)) * 100))
      text.style('fill', colorScaleDex(scale.invert(i(t)) * 100))
    }
  }

  const lineTween: any = (newValue: number) => (d: any) => {
    const interpolate = d3.interpolate(d.oldValue, newValue)

    return (t: any) => {
      const r = radius - 24
      const val = interpolate(t) - halfPi
      const im = val - halfPi
      const ip = val + halfPi
      const topX = r * Math.cos(val)
      const topY = r * Math.sin(val)
      const leftX = needleRad * Math.cos(im)
      const leftY = needleRad * Math.sin(im)
      const rightX = needleRad * Math.cos(ip)
      const rightY = needleRad * Math.sin(ip)

      return (
        d3.line()([
          [topX, topY],
          [leftX, leftY],
          [rightX, rightY]
        ]) + 'Z'
      )
    }
  }

  update(scale(0), scale(value / 100))
}
