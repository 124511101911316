import React, { ReactNode, useContext, useMemo, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { IDialogButton, IDialogData } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import ReactFocusLock from 'react-focus-lock'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import useMoveFocusToElement from 'src/components/hooks/useMoveFocusToElement'
import ScreenContext from 'src/contexts/ScreenContext'
import Button from '../Button'

const { colors } = createTheme()

const APPEARING_TIME = 200
export const DISAPPEARING_TIME = 100

const appearingKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translate(0px, -25px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
}
`

const disappearingKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, -5px);
}
`

const CenteredWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledDialog = styled.div<{ $isDismissing: boolean }>`
  display: grid;
  grid-template-areas:
    '. title close'
    'content content content'
    'buttons buttons buttons';
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  box-shadow: 0 0 12px rgb(0 0 0 / 8%);
  border-radius: 8px;
  width: 640px;

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-template-columns: 32px 1fr 32px;
        `
      : css`
          grid-template-columns: 16px 1fr 16px;
        `}

  ${props =>
    props.$isDismissing
      ? css`
          animation: ${disappearingKeyframes} ${DISAPPEARING_TIME}ms ease
            forwards;
        `
      : css`
          animation: ${appearingKeyframes} ${APPEARING_TIME}ms ease forwards;
        `}

  ${props => {
    if (props.theme.isMobile) {
      return css`
        width: calc(100vw - 32px);
        max-width: 400px;
        min-width: 320px;
      `
    }
  }}
`

const StyledTitle = styled.span`
  grid-area: title;
  font-weight: 500;
  line-height: 130%;
  text-align: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 22px;
          margin: 20px 16px 0;
        `
      : css`
          font-size: 18px;
          margin: 12px 0 0;
        `}
`

const CloseButton = styled.button`
  grid-area: close;
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  align-items: flex-start;
  transition: border 100ms ease;

  &:hover {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 3px;
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 3px;
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 20px 20px 0 0;
        `
      : css`
          margin: 12px 12px 0 0;
        `}
`

const CloseIcon = styled(Icon)`
  height: 14px;
`

const StyledContentWrapper = styled.div<{
  $centerContent: boolean
}>`
  grid-area: content;
  place-self: ${props => (props.$centerContent ? 'center' : 'flex-start')};
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          margin: 20px 20px 0;
        `
      : css`
          font-size: 14px;
          margin: 12px 12px 0;
        `}
`

const StyledButtonsWrapper = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 20px auto;
          width: auto;
        `
      : css`
          flex-direction: column;
          margin: 6px 12px;
        `}
`

const Dialog = React.memo((props: IDialogData) => {
  const {
    dismiss,
    children,
    title,
    buttons,
    showsCloseButtonOnMobile,
    isDismissing,
    onUserClosed,
    ariaText,
    showsCenterContent
  } = props
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const containerRef = useRef(null)

  useMoveFocusToElement(containerRef.current)

  const closeButtonView: ReactNode = useMemo(
    () =>
      (isDesktop || showsCloseButtonOnMobile) && (
        <CloseButton
          onClick={() => {
            if (onUserClosed) {
              onUserClosed()
            }
            dismiss()
          }}
          aria-label={t('common.accessibilityText.close')}
        >
          <CloseIcon
            name={'close_crossing'}
            settings={{
              fill: colors.main100
            }}
          />
        </CloseButton>
      ),
    [t, showsCloseButtonOnMobile, onUserClosed, dismiss, isDesktop]
  )

  const applySort = (btn1: IDialogButton, btn2: IDialogButton) => {
    if (isMobile) {
      return btn2.order - btn1.order
    }
    return btn1.order - btn2.order
  }

  const applyMap = (btn: IDialogButton, index: number) => (
    <Button
      key={index}
      {...btn}
      onClick={() => {
        dismiss()
        btn.onClick()
      }}
    />
  )

  return (
    <CenteredWrapper ref={containerRef} role={'dialog'}>
      <ReactFocusLock autoFocus={false}>
        <StyledDialog $isDismissing={isDismissing}>
          <StyledTitle aria-label={ariaText}>{title}</StyledTitle>
          {closeButtonView}
          <StyledContentWrapper $centerContent={showsCenterContent}>
            {children}
          </StyledContentWrapper>
          {buttons && (
            <StyledButtonsWrapper>
              {buttons.sort(applySort).map(applyMap)}
            </StyledButtonsWrapper>
          )}
        </StyledDialog>
      </ReactFocusLock>
    </CenteredWrapper>
  )
})

Dialog.displayName = 'Dialog'
export default Dialog
