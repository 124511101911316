import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import MarkdownText from 'src/UIKit/MarkdownText'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'
import zIndex from 'src/constants/zIndex'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  totalCount: number
  currentQuestionNumber: number
  onPrevClick: () => void
  onNextClick: () => void
  isNextEnabled: boolean
  isFirst: boolean
  isLast: boolean
  showsSubmitButton: boolean
  isSubmitButtonActive: boolean
  onSubmit: () => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);

  ${props =>
    props.theme.isDesktop
      ? css`
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          width: 100%;
        `
      : css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: ${zIndex.survey.bottomPanel};
        `}
`

const InfoText = styled(MarkdownText)`
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          line-height: 100%;
          font-size: 16px;
          margin-left: 32px;
        `
      : css`
          line-height: 150%;
          font-size: 14px;
          margin-left: 16px;
        `}
`

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const PrevButton = styled(Button)`
  transition: none;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 125px;
        `
      : css`
          width: 100px;
        `}
`

const ActionButton = styled(Button)`
  margin: 0 16px;
  transition: none;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 172px;
        `
      : css`
          width: 150px;
        `}
`

const SurveyQuestionViewBottomPanel = React.memo((props: IProps) => {
  const {
    currentQuestionNumber,
    totalCount,
    isFirst,
    isLast,
    isNextEnabled,
    onPrevClick,
    onNextClick,
    isSubmitButtonActive,
    onSubmit
  } = props
  const { t } = useTranslation()
  const { i18Context } = useContext(ScreenContext)
  return (
    <Container>
      <div role={'status'}>
        <InfoText>
          {t(`surveys.infoText`, {
            context: i18Context,
            total: totalCount,
            current: currentQuestionNumber
          })}
        </InfoText>
      </div>
      <ButtonsContainer>
        <PrevButton
          appearance={'cancel'}
          disabled={isFirst}
          onClick={onPrevClick}
          ariaLabel={t('common.accessibilityText.previousQuestion')}
        >
          {t('surveys.previous')}
        </PrevButton>
        {!isLast && (
          <ActionButton
            onClick={onNextClick}
            disabled={!isNextEnabled}
            ariaLabel={t('common.accessibilityText.nextQuestion')}
          >
            {t('surveys.next')}
          </ActionButton>
        )}
        {isLast && (
          <ActionButton onClick={onSubmit} disabled={!isSubmitButtonActive}>
            {t('surveys.submitSurvey')}
          </ActionButton>
        )}
      </ButtonsContainer>
    </Container>
  )
})

SurveyQuestionViewBottomPanel.displayName = 'SurveyQuestionViewBottomPanel'

export default SurveyQuestionViewBottomPanel
