import React from 'react'
import styled, { css } from 'styled-components'
import { mobileMaxWidthMixin } from 'src/theme/utils'

interface IProps {
  children: any
  className?: string
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 736px;
          margin: 0 16px;
        `
      : css`
          margin: 0 auto;
          width: calc(100vw - 32px);
        `}
  ${mobileMaxWidthMixin};
`

export const CreateLeaveContentContainer = React.memo((props: IProps) => (
  <ContentContainer className={props.className}>
    {props.children}
  </ContentContainer>
))

CreateLeaveContentContainer.displayName = 'CreateLeaveContentContainer'

export default CreateLeaveContentContainer
