import { Moment } from 'moment'
import { ITimelinePeriod } from 'src/react-app-env'
import { IUseDate } from '../hooks/useDate'
import { excludeChanges, getChanges } from '../methods'

interface IPeriodStartPickerInput {
  date?: Moment
  period?: ITimelinePeriod
  initialDate?: IUseDate
  currentDate?: IUseDate
  dueDate?: Moment
  startDate?: Moment
  endDate?: Moment
  onNewChanges?: (changes: any) => void
  timelinePeriods?: ITimelinePeriod[]
  leave?: ILeave
  onExit?: () => void
  updateLeave?: (changes: any) => void
  setIsSendingDate?: (value: boolean) => void
  isDateChangedValue?: boolean
  onDateChangeFunc?: (date: Moment) => void
}

export const getLocalDateChanges = ({
  leave,
  date,
  dueDate,
  period
}: IPeriodStartPickerInput): any => {
  const format = 'YYYY-MM-DDTHH:mm:ss.000'
  const dueDateFormat = dueDate.isValid() ? dueDate.format(format) + 'Z' : null
  const result: any = {
    dueDate: dueDateFormat
  }

  result[period.timelineConfig.datePickerKeyDate] = date.format(format) + 'Z'

  return {
    ...result,
    ...excludeChanges(leave, period)
  }
}

export const onDateChange = ({
  currentDate,
  date,
  onNewChanges,
  timelinePeriods,
  period,
  dueDate,
  startDate,
  endDate,
  leave
}: IPeriodStartPickerInput) => {
  currentDate.setCurrent(date)
  onNewChanges(
    getChanges({
      timelinePeriods,
      duration: 0,
      startDate,
      endDate,
      leave,
      ...getLocalDateChanges({ leave, date, dueDate, period })
    })
  )
}

export const onConfirm = ({
  onExit,
  updateLeave,
  timelinePeriods,
  period,
  currentDate,
  dueDate,
  startDate,
  endDate,
  leave
}: IPeriodStartPickerInput) => {
  onExit()
  updateLeave(
    getChanges({
      timelinePeriods,
      duration: 0,
      leave,
      startDate,
      endDate,
      ...getLocalDateChanges({
        leave,
        date: currentDate.current,
        dueDate,
        period
      })
    })
  )
}

export const isDateChanged = ({
  initialDate,
  currentDate
}: IPeriodStartPickerInput): boolean => {
  if (!initialDate?.current || !currentDate?.current) {
    return false
  }

  return !initialDate.current.isSame(currentDate.current)
}
