import React, { useContext, useEffect, useState } from 'react'
import withQueries from 'src/components/HOC/withQueries'
import { IWithQueriesProps, UserRole } from 'src/react-app-env'
import RouteContext from 'src/routes/Context'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import { notFoundPageRoute } from 'src/routes/constants'
import { getIsEmployee, getIsManager } from 'src/utils/userUtils'
import { markAllAsRead } from './methods'
import { useNavigate } from 'react-router-dom'

interface INotificationsContainerParameters {
  fetchCount: number
  shouldFetchMore: boolean
}

export default (
  WrappedComponent: any,
  parameters: INotificationsContainerParameters
) => {
  const WithNotificationsContainer = (props: IWithQueriesProps) => {
    const [fetching, setFetching] = useState(false)
    const [records, setRecords] = useState([])
    const [after, setAfter] = useState(null)
    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    const { notificationType } = useContext(RouteContext)

    useEffect(() => {
      if (user) {
        if (
          (notificationType === 'Manager' &&
            !getIsManager(user.roles as UserRole[])) ||
          (notificationType === 'Employee' &&
            !getIsEmployee(user.roles as UserRole[]))
        ) {
          navigate(notFoundPageRoute)
        } else {
          fetchNotifications()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const userUserResult: IUseUser = useUser()
    if (!user && userUserResult.user) {
      setUser(userUserResult.user)
    }

    const fetchNotifications = async () => {
      const { fetchNotificationFeed } = props.queries

      setFetching(true)
      const feed: INotificationFeed = await fetchNotificationFeed(
        notificationType,
        after,
        parameters.fetchCount,
        { fetchPolicy: 'network-only' }
      )

      const nextAfter: string = feed.cursor
      const newRecords = [...records, ...feed.records]

      setAfter(nextAfter)
      setRecords(newRecords)
      setFetching(false)
    }

    const onScrolledToBottom = () => {
      if (!parameters.shouldFetchMore) {
        return
      }

      if (fetching || after === '') {
        return
      }

      fetchNotifications()
    }

    const onMarkAllAsReadClicked = async () => {
      const result: boolean = await markAllAsRead(
        props.queries.readAllNotifications,
        notificationType
      )
      if (result) {
        const newRecords: INotification[] = records.map((r: INotification) => ({
          ...r,
          read: true
        }))
        setRecords(newRecords)
      }
    }

    return (
      <WrappedComponent
        {...props}
        records={records}
        fetching={fetching}
        fetchCount={parameters.fetchCount}
        onMarkAllAsReadClicked={onMarkAllAsReadClicked}
        onScrolledToBottom={onScrolledToBottom}
      />
    )
  }

  return withQueries(WithNotificationsContainer)
}
