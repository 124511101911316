import styled, { css } from 'styled-components'
import { mobileMaxWidthMixin } from 'src/theme/utils'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.light100};
  padding: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
          width: calc(429px - 42px);
          padding-bottom: 16px;
        `
      : css`
          width: calc(100% - 32px);
          height: auto;
          margin: 0 auto;
        `}

  ${mobileMaxWidthMixin};
`
