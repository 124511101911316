import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  SyntheticEvent,
  useContext,
  useMemo
} from 'react'
import styled from 'styled-components'
import moment, { Moment } from 'moment'
import Popover from 'src/components/Popover'
import { IMomentCurrentMinMax, IWithDialogManager } from 'src/react-app-env'
import { withDialogManager } from 'src/components/DialogManager'
import zIndex from 'src/constants/zIndex'
import DatePickerDefaultView from 'src/components/DatePicker/components/views/DefaultView'
import {
  DATE_PICKER_CALENDAR_DATE_CHANGED,
  DATE_PICKER_CALENDAR_HIDDEN,
  DATE_PICKER_CALENDAR_SHOWN
} from 'src/constants/events'
import ScreenContext from 'src/contexts/ScreenContext'
import Calendar, { IExtraButton } from './components/Calendar'
import LeaveHolidays from 'src/features/Leave/holidays'

interface IProps extends IWithDialogManager {
  className?: string
  title: string
  momentCurrentMinMax: IMomentCurrentMinMax
  holidays?: LeaveHolidays
  activeStartDate?: Moment
  onActiveStartDateChange?: (date: Moment) => void
  defaultActiveStartDate?: Moment
  mobileDialogTitle: string
  description: string
  onDateChanged: (date: Moment) => void
  onOpened: (date: Moment) => void
  extraButton?: IExtraButton
  useVerticalLayout?: boolean
  disabled?: boolean
  TopViewComponent?: any
  disableWeekends?: boolean
  disabledDays?: Moment[]
  ariaHidden?: boolean
  topViewComponentRef?: any
  actAsTextField?: boolean
  calendarRef?: any
  isUsedDefaultOffset?: boolean
  highlightedDate?: Moment
  stickRight?: boolean
  useDefaultTopOffset?: boolean
  handleBottomOverlap?: boolean
}

type Behaviour = 'popover' | 'dialog'

const TOP_HEIGHT_DATEPICKER_SHIFT = 60
const LEFT_WIDTH_CALENDAR_SHIFT = 164

const Container = styled.div`
  z-index: ${zIndex.datePicker.container};
  position: relative;
  cursor: pointer;
  user-select: none;
`

export const ClickOverlay = styled.div<{ $disabled: boolean }>`
  z-index: ${zIndex.datePicker.clickOverlay};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: ${props => (props.$disabled ? 'initial' : 'pointer')};
`

export const dataAttrs = {
  overlay: () => 'date-picker-overlay'
}

const DatePicker = React.memo((props: IProps) => {
  const {
    TopViewComponent,
    activeStartDate,
    ariaHidden,
    calendarRef,
    className,
    defaultActiveStartDate,
    description,
    dialogManager,
    disableWeekends,
    disabled,
    disabledDays,
    extraButton,
    highlightedDate,
    holidays,
    isUsedDefaultOffset,
    mobileDialogTitle,
    momentCurrentMinMax,
    onActiveStartDateChange,
    onDateChanged,
    onOpened,
    stickRight,
    topViewComponentRef,
    useDefaultTopOffset,
    useVerticalLayout,
    handleBottomOverlap
  } = props
  const { isMobile, isDesktop } = useContext(ScreenContext)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [hovered, setHovered] = useState(false)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const popoverRef = calendarRef || useRef(null)
  const containerRef = useRef(null)

  const behavior: Behaviour =
    isMobile || useVerticalLayout ? 'dialog' : 'popover'

  const focusedDate = useMemo(
    () =>
      momentCurrentMinMax.current ||
      activeStartDate ||
      defaultActiveStartDate ||
      moment(),
    [momentCurrentMinMax, activeStartDate, defaultActiveStartDate]
  )

  const onPopoverChangesState = (oldIsOpen: boolean, newIsOpen: boolean) => {
    setIsPopoverOpen(newIsOpen)
    const eventName: string = newIsOpen
      ? DATE_PICKER_CALENDAR_SHOWN
      : DATE_PICKER_CALENDAR_HIDDEN
    window.dispatchEvent(new Event(eventName))

    if (newIsOpen && onOpened) {
      onOpened(focusedDate)
    }
  }

  const reset = useCallback(() => {
    if (behavior === 'popover') {
      try {
        popoverRef.current.toggle()
      } catch (_) {
        return undefined
      }
    }

    if (dialogManager.remove) {
      dialogManager.remove()
    }
  }, [behavior, dialogManager, popoverRef])

  const renderCalendar = useMemo(
    () => (
      <Calendar
        text={description}
        holidays={holidays}
        momentCurrentMinMax={momentCurrentMinMax}
        onChange={(date: any) => {
          window.dispatchEvent(new Event(DATE_PICKER_CALENDAR_DATE_CHANGED))
          onDateChanged(date)
          reset()
        }}
        extraButton={extraButton}
        closeParent={() => {
          reset()
        }}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        defaultActiveStartDate={defaultActiveStartDate}
        disableWeekends={disableWeekends}
        disabledDays={disabledDays}
        highlightedDate={highlightedDate}
      />
    ),
    [
      description,
      holidays,
      momentCurrentMinMax,
      onDateChanged,
      reset,
      extraButton,
      activeStartDate,
      onActiveStartDateChange,
      defaultActiveStartDate,
      disableWeekends,
      disabledDays,
      highlightedDate
    ]
  )

  const onClick = () => {
    if (disabled) {
      return
    }
    if (behavior === 'popover') {
      togglePopover()
    } else {
      showDialog()
    }
  }

  useEffect(() => {
    if (!isMobile || !dialogManager.opened) {
      return
    }

    dialogManager.update({
      title: mobileDialogTitle,
      children: renderCalendar,
      showsCloseButtonOnMobile: true,
      showsCenterContent: true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    description,
    holidays,
    momentCurrentMinMax,
    extraButton,
    activeStartDate,
    defaultActiveStartDate,
    disableWeekends,
    disabledDays,
    highlightedDate
  ])

  const showDialog = useCallback(() => {
    dialogManager.add({
      title: mobileDialogTitle,
      children: renderCalendar,
      showsCloseButtonOnMobile: true,
      showsCenterContent: true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCalendar, mobileDialogTitle])

  const togglePopover = () => {
    const { current } = popoverRef
    if (!current) {
      return
    }

    if (current) {
      current.toggle()
    }
  }

  const getOffset = useCallback(() => {
    const { current } = containerRef
    if (!current || isUsedDefaultOffset) {
      return { top: 0, left: 0 }
    }
    const containerRect: any = current.getBoundingClientRect()

    const top: number = useDefaultTopOffset
      ? 0
      : TOP_HEIGHT_DATEPICKER_SHIFT - containerRect.height
    const left: number = LEFT_WIDTH_CALENDAR_SHIFT - containerRect.width / 2

    return { top, left: stickRight ? -left : left }
  }, [containerRef, isUsedDefaultOffset, stickRight, useDefaultTopOffset])

  const renderPopover = () =>
    isDesktop && (
      <Popover
        parent={containerRef.current}
        ref={popoverRef}
        beforeStateChanged={onPopoverChangesState}
        allowsHitAreaCheck
        offset={getOffset()}
        handleBottomOverlap={handleBottomOverlap}
      >
        {renderCalendar}
      </Popover>
    )

  const renderClickOverlay = () => (
    <ClickOverlay
      data-testid={dataAttrs.overlay()}
      onClick={onClick}
      $disabled={disabled}
      onMouseOver={() => {
        setHovered(true)
      }}
      onMouseOut={() => {
        setHovered(false)
      }}
    />
  )

  const Component = TopViewComponent || DatePickerDefaultView

  const onKeyDown = useCallback(
    (event: SyntheticEvent) => {
      const { keyCode } = event as any
      if (
        (keyCode === 13 || keyCode === 32) &&
        behavior === 'popover' &&
        !popoverRef.current.isOpen
      ) {
        try {
          popoverRef.current.toggle()
        } catch (_) {
          return undefined
        }
      } else if ((keyCode === 13 || keyCode === 32) && isMobile) {
        showDialog()
      }
    },
    [behavior, isMobile, popoverRef, showDialog]
  )

  return (
    <Container
      ref={containerRef}
      className={className}
      onKeyDown={onKeyDown}
      aria-hidden={ariaHidden}
    >
      <Component
        {...props}
        ref={topViewComponentRef}
        isPopoverOpen={isPopoverOpen}
        hovered={hovered}
        className={null}
      />
      {renderPopover()}
      {renderClickOverlay()}
    </Container>
  )
})

DatePicker.displayName = 'DatePicker'

export default withDialogManager(DatePicker)
