import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Tooltip from 'src/components/Tooltip'

interface IProps {
  className?: string
  sickHoursToBeUsed: number
  vacationHoursToBeUsed: number
}

const Details = styled.div`
  font-size: ${props => (props.theme.isDesktop ? '14px' : '11px')};
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  margin-right: ${props => (props.theme.isDesktop ? '40px' : '0')};
  display: flex;
  align-items: center;
  gap: 7px;
`

const UsedBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const NumberText = styled.span`
  font-size: ${props => (props.theme.isDesktop ? '20px' : '14px')};
  font-weight: ${props => (props.theme.isDesktop ? 'normal' : '500')};
`

const usedBlock = (t: any, h: number, tp: string) =>
  h > 0 && (
    <UsedBlock>
      <NumberText>{h}</NumberText>
      {t(`supplementPay.${tp}`)}
    </UsedBlock>
  )

export const dataAttrs = {
  details: () => `supplement-pay-details`
}

const SupplementPayDetails = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { sickHoursToBeUsed, vacationHoursToBeUsed } = props

  if (sickHoursToBeUsed === 0 && vacationHoursToBeUsed === 0) {
    return
  }

  return (
    <Details data-testid={dataAttrs.details()}>
      <Tooltip
        title={t('supplementPay.detailsTooltip.title')}
        body={t('supplementPay.detailsTooltip.description')}
        offset={[20, 15]}
      >
        {t('supplementPay.maxSupplement')}
      </Tooltip>
      {usedBlock(t, sickHoursToBeUsed, 'sickHours')}
      {usedBlock(t, vacationHoursToBeUsed, 'vacationHours')}
    </Details>
  )
})

SupplementPayDetails.displayName = 'SupplementPayDetails'

export default SupplementPayDetails
