import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TileInfo from 'src/components/Dashboard/TileInfo'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import { DashboardProps } from '../../config'

const AbsenceStatus = React.memo((props: DashboardProps) => {
  const { t } = useTranslation()
  const { absence } = props
  const now = moment.utc()
  const nextPeriod = absence.info?.nextPeriod

  const absenceType = now.isBefore(absence.startDate)
    ? 'OnLeave'
    : nextPeriod?.type || 'AtWork'

  let date = null
  if (now.isBefore(absence.startDate)) {
    date = moment(absence.startDate)
  } else if (nextPeriod?.startDate) {
    date = moment(nextPeriod.startDate)
  } else {
    date = moment(absence.endDate).add(1, 'day')
  }

  const title = t(`manager.tiles.absenceStatus.${absenceType}`)

  return <TileInfo title={title}>{date.utc().format(DATE_FORMAT)}</TileInfo>
})

AbsenceStatus.displayName = 'AbsenceStatus'

export default AbsenceStatus
