import React, { useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import PopoverArrow from 'src/components/Popover/components/Arrow'
import useComponentRect from 'src/components/hooks/useComponentRect'
import { IIconName } from 'src/react-app-env'
import createTheme from 'src/theme'
import ReactMarkdown from 'react-markdown'

const { colors } = createTheme()

interface IProps {
  className?: string
  alertTranslationKey: string
  metadata?: any
  iconName?: IIconName
  onResize: () => void
}

const Container = styled.div<any>`
  width: 100%;
  margin: 16px;
`
const TitleContainer = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  border-radius: 8px;

  ${props =>
    !props.theme.isDesktop &&
    css`
      text-align: left;
    `}

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
  }
`

const IconWrapper = styled(Icon)`
  margin: 2px 0 0;
`

const Title = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  margin-left: 16px;
`

const BodyContainer = styled.div<{ $height: number; $opened: boolean }>`
  transition: all 300ms;
  overflow: hidden;
  ${props => {
    const { $height, $opened } = props
    const opacity: string = $opened ? '1' : '0'
    const margin: string = $opened ? '16px 0 0' : '0'
    return css`
      height: ${$opened ? $height : 0}px;
      visibility: ${$opened ? 'visible' : 'hidden'};
      margin: ${margin};
      opacity: ${opacity};
    `
  }}
`

const Text = styled(ReactMarkdown)`
  transition: all 300ms;
  font-size: 14px;
  line-height: 150%;
  margin-top: 2px;
  color: ${props => props.theme.colors.dark60};

  ol {
    list-style-position: inside;
    list-style-type: decimal;
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 2px solid ${props => props.theme.colors.main100};
      outline-offset: 2px;
      border-radius: 3px;
    }
  }
`
const ArrowContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
`

const InfoView = React.memo(
  React.memo((props: IProps) => {
    const {
      alertTranslationKey,
      metadata,
      iconName = 'info_circle',
      onResize
    } = props
    const { t }: { t: any } = useTranslation()

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const bodyRef: any = useRef(null)
    const { height } = useComponentRect(bodyRef)

    const title: string = useMemo(
      () => t(`timeline.datePickerAlerts.${alertTranslationKey}.title`),
      [t, alertTranslationKey]
    )

    const text: string = useMemo(
      () =>
        t(`timeline.datePickerAlerts.${alertTranslationKey}.text`, metadata),
      [t, alertTranslationKey, metadata]
    )

    return (
      <Container>
        <TitleContainer
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          role={'status'}
          aria-haspopup
          aria-expanded={isOpen}
          aria-label={isOpen ? text : title}
        >
          <IconWrapper name={iconName} settings={{ fill: colors.main100 }} />
          <Title aria-hidden>{title}</Title>
          <ArrowContainer>
            <PopoverArrow isOpen={isOpen} />
          </ArrowContainer>
        </TitleContainer>

        <BodyContainer
          $height={height}
          $opened={isOpen}
          onTransitionEnd={onResize}
          aria-hidden
        >
          <div ref={bodyRef}>
            <Text
              components={{
                a: (linkProps: any) => (
                  <a
                    href={linkProps.href}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    aria-label={linkProps.href}
                  >
                    {linkProps.children}
                  </a>
                )
              }}
            >
              {text}
            </Text>
          </div>
        </BodyContainer>
      </Container>
    )
  })
)

InfoView.displayName = 'InfoView'

export default InfoView
