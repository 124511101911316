import React, { ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  children: ReactNode
  role?: string
  id?: string
}

const Wrapper = styled.h1<IProps>`
  font-weight: 500;
  line-height: 130%;
  width: max-content;
  text-align: center;
  color: ${(props: any) => props.theme.colors.dark80};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px 0 40px;
          font-size: 26px;
        `
      : css`
          margin: 16px 0;
          font-size: 22px;
        `}
`

const H1 = React.memo(
  React.forwardRef((props: IProps, ref: Ref<any>) => (
    <Wrapper {...props} ref={ref} role={props.role}>
      {props.children}
    </Wrapper>
  ))
)

H1.displayName = 'H1'

export default H1
