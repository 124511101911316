import React from 'react'
import styled from 'styled-components'
import Markdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { DATE_FORMAT_WITH_TIME, DATE_FORMAT_YYYY } from 'src/utils/dateUtils'

interface IProps {
  className?: string
}

const Container = styled.div`
  display: none;

  @media print {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 5mm;
    left: 5mm;
    right: 5mm;
  }
`

const GrayText = styled(Markdown)`
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

const PrintFooter = React.memo((props: IProps) => {
  const { t } = useTranslation()

  const { className } = props
  const now: Moment = moment()
  const copyright: string = t('footer.copyright')
  const year: string = now.format(DATE_FORMAT_YYYY)
  const date: string = now.format(DATE_FORMAT_WITH_TIME)
  const link = `https://${window.location.hostname}`
  const generated: string = t('footer.generated', { date })

  return (
    <Container className={className}>
      <GrayText>{generated}</GrayText>
      <GrayText>{link}</GrayText>
      <GrayText>{`© ${year} ${copyright}`}</GrayText>
    </Container>
  )
})

PrintFooter.displayName = 'PrintFooter'

export default PrintFooter
