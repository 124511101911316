import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  children: string
  bold: boolean
}

const Title = styled.span<{ $bold: boolean }>`
  color: ${props => props.theme.colors.dark80};
  font-weight: ${props => (props.$bold ? 500 : 'normal')};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 8px;
        `
      : css`
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 4px;
        `}

  @media print {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 4px;
    font-weight: ${props => (props.$bold ? 500 : 'normal')};
  }
`

const TimelineTitleViewTitle = React.memo((props: IProps) => (
  <Title $bold={props.bold} aria-hidden>
    {props.children}
  </Title>
))

TimelineTitleViewTitle.displayName = 'TimelineTitleViewTitle'

export default TimelineTitleViewTitle
