import React, { useState } from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: any
  children: string
  active: boolean
  onClick: () => void
  isFirst: boolean
}

export const Button = styled.button<{ $isFirst: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 0 16px;
  width: 100%;

  ${p =>
    p.$isFirst &&
    css`
      padding-top: 16px;
    `}

  &:focus {
    text-decoration: underline;
    color: ${props => props.theme.colors.main110};
    outline: none;
  }
`

const Circle = styled.div<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 16px;
  ${props =>
    props.$active
      ? css`
          border: 1px solid transparent;
          background: ${props.theme.colors.main100};
        `
      : css`
          border: 1px solid ${props.theme.colors.main20};
        `};
`

const Title = styled.span<{ $active: boolean; $hovered: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  transition: color 200ms;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.$active &&
    css`
      font-weight: 500;
    `}

  ${props =>
    props.$hovered &&
    css`
      color: ${props.theme.colors.dark80};
    `}
`

export const RolesSwitcherRow = React.memo((props: IProps) => {
  const { children, active, onClick, isFirst } = props
  const [hovered, setHovered] = useState(false)

  return (
    <li>
      <Button
        $isFirst={isFirst}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={() => {
          if (!active) {
            onClick()
          }
        }}
      >
        <Circle $active={active} />
        <Title $active={active} $hovered={hovered}>
          {children}
        </Title>
      </Button>
    </li>
  )
})

RolesSwitcherRow.displayName = 'RolesSwitcherRow'

export default RolesSwitcherRow
