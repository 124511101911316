import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ItemsList from 'src/components/JourneyMap/List'
import { printDisplayNoneMixin } from 'src/theme/utils'
import {
  filterItems,
  getItemById,
  getTodayOrLaterItem,
  openItemIfNeeded,
  toggleItemCompleted
} from 'src/utils/journeyMap'
import { MENU_OPEN } from 'src/constants/events'
import first from 'lodash.first'
import JourneyMapItemView from 'src/components/JourneyMap/JourneyMapItemView'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  IPeriodFilter,
  IPriorityFilter
} from 'src/components/JourneyMap/Filters'
import zIndex from 'src/constants/zIndex'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import { withToastManager } from 'src/components/ToastManager'
import { useTranslation } from 'react-i18next'
import { IWithToastManager } from 'src/react-app-env'

interface IProps extends IWithToastManager {
  journeyMap: IJourneyMapItem[]
  isOverdueMode: boolean
  blurred: boolean
  toggleJourneyMapItem: any
  onToggleError: () => void
  priorityFilters: IPriorityFilter[]
  periodFilters?: IPeriodFilter[]
  extraMarginBottom?: boolean
  leaveId?: string
}

const ItemsListWrapper = styled(ItemsList)<any>`
  grid-area: list;
  transition: all 200ms ease;
  ${props => {
    if (props.blurred) {
      return css`
        filter: blur(4px);
        pointer-events: none;
        z-index: ${zIndex.journeyMap.list.blurred};
      `
    } else {
      return css`
        pointer-events: unset;
      `
    }
  }};

  ${props =>
    props.extraMarginBottom &&
    css`
      margin-bottom: 80px;
    `}

  ${printDisplayNoneMixin}
`

const JourneyMapItemViewWrapper = styled(JourneyMapItemView)`
  ${props =>
    props.theme.isMobile &&
    css`
      @media not print {
        grid-area: 1 / 1 / last-line / last-line;
      }
    `};
`

export const ListContainer = React.memo((props: IProps) => {
  const {
    journeyMap,
    isOverdueMode,
    blurred,
    toggleJourneyMapItem,
    onToggleError,
    priorityFilters,
    periodFilters,
    extraMarginBottom,
    leaveId,
    toastManager
  } = props

  const managerJourneyMapContext: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )

  const { t } = useTranslation()

  const [removingItemsId, setRemovingItemsId] = useState([])
  const [selectedItemId, setSelectedItemId] = useState(null)
  const listRef: any = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const items: IJourneyMapItem[] = filterItems(
    journeyMap,
    priorityFilters,
    periodFilters || [],
    isOverdueMode
  ).map((item: IJourneyMapItem) => {
    if (!isOverdueMode || removingItemsId.indexOf(item.id) === -1) {
      return { ...item, animatesCompleted: false }
    }

    return { ...item, animatesCompleted: true, completed: true }
  })

  const selectedItem: IJourneyMapItem = getItemById(selectedItemId, journeyMap)

  useEffect(() => {
    const timeoutId: number = scrollToTodayOrLater()
    setRemovingItemsId([])
    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverdueMode])

  useEffect(() => {
    const onMenuOpen = () => {
      setSelectedItemId(null)
    }

    window.addEventListener(MENU_OPEN, onMenuOpen)

    return () => {
      window.removeEventListener(MENU_OPEN, onMenuOpen)
    }
  }, [])

  useEffect(() => {
    openItemIfNeeded(
      navigate,
      location,
      leaveId,
      setSelectedItemId,
      (textKey: string) => {
        toastManager.addInfo(t(textKey))
      }
    )
  }, [setSelectedItemId, navigate, location, leaveId, t, toastManager])

  const scrollToTodayOrLater = (): number => {
    if (!!managerJourneyMapContext && managerJourneyMapContext.isArchiveMode) {
      listRef.current.scrollToTop()
      return
    }

    return window.setTimeout(() => {
      listRef.current?.scrollToTodayOrLaterItem()
    }, 0)
  }

  const callToggleItemCompleted = (id: string, deferred: boolean) => {
    toggleItemCompleted(
      id,
      deferred,
      toggleJourneyMapItem,
      removingItemsId,
      setRemovingItemsId,
      onToggleError
    )
  }

  return (
    <React.Fragment>
      <ItemsListWrapper
        items={items}
        animatesCompleted={isOverdueMode}
        blurred={blurred}
        itemToScrollTo={
          isOverdueMode ? first(items) : getTodayOrLaterItem(items)
        }
        ref={listRef}
        onItemClick={(id: string) => {
          setSelectedItemId(id)
        }}
        onItemCompletedClick={(id: string) => {
          callToggleItemCompleted(id, isOverdueMode)
        }}
        extraMarginBottom={extraMarginBottom}
      />
      {selectedItem && (
        <JourneyMapItemViewWrapper
          item={selectedItem}
          navigate={navigate}
          onItemCompletedClick={() => {
            callToggleItemCompleted(selectedItemId, false)
          }}
          onClose={() => {
            setSelectedItemId(null)
          }}
        />
      )}
    </React.Fragment>
  )
})

ListContainer.displayName = 'ListContainer'

export default withToastManager(withRouter(ListContainer))
