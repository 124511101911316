import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import zIndex from 'src/constants/zIndex'
import { H1, Button } from 'src/UIKit'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import LeaveInfoView from 'src/components/LeaveInfoView'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import { pageButtons } from 'src/constants/frame'

interface IProps {
  onConfirm: () => void
  onCancel: () => void
}

const Container = styled.div`
  position: absolute;
  z-index: ${zIndex.timeline.birthDateOverlay};
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => props.theme.colors.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          top: 89px;
        `
      : css`
          top: 49px;
        `}
`

const Title = styled(H1)`
  ${props =>
    props.theme.isMobile &&
    css`
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: ${props.theme.colors.dark60};
      margin: 0 16px;
      padding: 0;
      border: none;
    `}
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 16px;
          flex: 1;
        `}
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          max-width: 736px;
        `
      : css`
          font-size: 14px;
        `}
`

const LeaveInfoViewWrapper = styled(LeaveInfoView)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 0;
        `
      : css`
          margin: 16px 0 0;
        `}
`

const ButtonsContainer = styled.div`
  gap: 16px;
  display: flex;
  justify-content: center;
  max-width: 736px;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0 0;
          align-items: center;
        `
      : css`
          flex: 1;
          gap: 16px;
          align-items: flex-end;
          width: 100%;
        `}
`

const mobileButtonsMixin = css`
  height: 48px;
  font-size: 16px;
  flex: 1;
`

const CancelButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          ${mobileButtonsMixin}
        `}
`

const ConfirmButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          ${mobileButtonsMixin}
        `}
`

const MobileHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 4px 13px rgb(98 106 116 / 10%);
`

const ConfirmationLeaveApprovalOverlay = React.memo((props: IProps) => {
  const { t } = useTranslation()

  const { leave }: ITimelineContext = useContext(TimelineContext)
  const { onConfirm, onCancel } = props

  const titleView: ReactNode = useMemo(
    () => <Title aria-hidden>{t('confirmationLeaveApproval.title')}</Title>,
    [t]
  )

  const { isMobile, isDesktop } = useContext(ScreenContext)

  return (
    <Container>
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('confirmationLeaveApproval.title')}
      />
      {isMobile && <MobileHeader>{titleView}</MobileHeader>}
      <InnerContainer>
        {isDesktop && titleView}
        <Text>{t('confirmationLeaveApproval.text')}</Text>
        <LeaveInfoViewWrapper leave={leave} hidesLeaveType />
        <ButtonsContainer>
          <CancelButton appearance={'cancel'} onClick={onCancel}>
            {t('common.cancel')}
          </CancelButton>
          <ConfirmButton onClick={onConfirm}>
            {t('common.confirm')}
          </ConfirmButton>
        </ButtonsContainer>
      </InnerContainer>
    </Container>
  )
})

ConfirmationLeaveApprovalOverlay.displayName =
  'ConfirmationLeaveApprovalOverlay'

export default ConfirmationLeaveApprovalOverlay
