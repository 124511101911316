import moment, { Moment } from 'moment'
import { IMomentCurrentMinMax, ITimelinePeriod } from 'src/react-app-env'
import isEqual from 'lodash.isequal'

export const DATE_FORMAT = 'MMM DD, YYYY'
export const DATE_FORMAT_WITH_DAY = 'ddd MMM DD, YYYY'
export const DATE_FORMAT_MMM_DD_YY = `MMM DD 'YY`
export const DATE_FORMAT_MM_SS = `mm:ss`
export const DATE_FORMAT_YYYY = 'YYYY'
export const DATE_FORMAT_WITH_TIME = 'MMM DD, YYYY hh:mmA'
export const LEAVE_PREFERENCE_DATE = '2022-01-01'

const getNowMoment = () => {
  const date: Date = new Date()
  return getUTCMomentFromDate(date)
}

const getUTCMomentFromDate = (date: Date): Moment =>
  moment({
    years: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    date: date.getUTCDate()
  })

const getUTCMomentFromMoment = (m: Moment): Moment =>
  moment({
    years: m.get('year'),
    month: m.get('month'),
    date: m.get('date')
  }).utc()

const getTodayYesterdayOrDate = (strDate: string | Moment, t: any): string => {
  const m: Moment = moment(strDate).utc()
  const today = moment().utc()
  if (m.isSame(today, 'day')) {
    return t('common.today')
  } else if (m.isSame(today.subtract(1, 'day'), 'day')) {
    return t('common.yesterday')
  }

  return m.format(DATE_FORMAT)
}

const getProgressBetweenDates = (
  s: Moment,
  e: Moment,
  n: Moment = getNowMoment()
): number => {
  const start: Moment = getUTCMomentFromMoment(s)
  const end: Moment = getUTCMomentFromMoment(e)
  const now: Moment = getUTCMomentFromMoment(n)
  if (start.isSameOrAfter(now)) {
    return 0
  }

  if (end.isSameOrBefore(now)) {
    return 1
  }

  const interval: number = end.diff(start, 'day')
  const current: number = now.diff(start, 'day')

  return parseFloat((current / interval).toFixed(2))
}

const getMomentCurrentMinMaxFromObject = (obj: any): IMomentCurrentMinMax => ({
  current: moment(obj.current),
  min: moment(obj.min),
  max: moment(obj.max)
})

const isWorkingDay = (m: Moment): boolean => m.isoWeekday() < 6

const getNextWorkingDayMoment = (m: Moment): Moment => {
  const mCopy: Moment = m.clone()
  mCopy.add(1, 'days')

  if (!isWorkingDay(mCopy)) {
    const addDays: number = 8 - mCopy.isoWeekday()
    mCopy.add(addDays, 'days')
  }

  return mCopy
}

const getMeetingTimeSinceNow = (fromHours: number, toHours: number): Moment => {
  const setMorningTime = (m: Moment) => {
    m.set({
      hours: fromHours,
      minutes: 0,
      seconds: 0
    })
  }

  let result: Moment = moment()
  result.add(30, 'minute')

  if (result.hours() < fromHours) {
    setMorningTime(result)
  }

  if (
    result.hours() > toHours ||
    (result.hours() === toHours && result.minutes() > 0)
  ) {
    result = getNextWorkingDayMoment(result)
    setMorningTime(result)
  }

  const minutes: number = result.minutes()
  if (minutes > 0 && minutes < 30) {
    result.add(30 - minutes, 'minutes')
  } else if (minutes > 30 && minutes < 60) {
    result.add(60 - minutes, 'minutes')
  }

  while (!isWorkingDay(result)) {
    result = getNextWorkingDayMoment(result)
    setMorningTime(result)
  }

  return result
}

const getAdjustedDateByWorkingDays = (
  m: Moment,
  numberDays: number
): Moment => {
  let result: Moment = m.clone()
  let count: number = numberDays
  while (count > 0) {
    if (isWorkingDay(result)) {
      count--
    }

    result = getNextWorkingDayMoment(result)
  }

  return result
}

const areSameDates = (m1: Moment, m2: Moment) =>
  (!m1 && !m2) || m1?.isSame(m2, 'day')

const isBlankDate = (m?: Moment): boolean =>
  !m || (m.year() === 1 && m.month() === 0 && m.day() === 1)

const workDays: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
]

const weekDays: string[] = [...workDays, 'Saturday', 'Sunday']

const isFiveDaySchedule = (weekOne: IWeekday[], weekTwo: IWeekday[]): boolean =>
  !weekTwo?.length && isEqual(weekOne, workDays)

const isFiveWorkdaysSchedule = (activeDays: IWeekday[]): boolean =>
  activeDays?.length && isEqual(activeDays, workDays)

const isRotatingSchedule = (periods: ITimelinePeriod[]): boolean =>
  periods.some(
    p =>
      p.schedule &&
      p.schedule.weekOne?.length > 0 &&
      p.schedule.weekTwo?.length > 0
  )

const isEndOfDay = (date: Moment): boolean =>
  date.hour() === 23 && date.minute() === 59 && date.second() === 59

const convertToMomentArray = (dates: Date[]): Moment[] =>
  dates?.map(date => moment(date).utc())

const calculateDaysDifferenceInclusive = (
  startDate: Moment,
  endDate: Moment
): number => {
  if (!startDate || !endDate) {
    return 0
  }

  return endDate.diff(startDate, 'days') + 1
}

export {
  getNowMoment,
  getUTCMomentFromDate,
  getTodayYesterdayOrDate,
  getProgressBetweenDates,
  getMomentCurrentMinMaxFromObject,
  getNextWorkingDayMoment,
  getMeetingTimeSinceNow,
  getAdjustedDateByWorkingDays,
  areSameDates,
  isFiveDaySchedule,
  isFiveWorkdaysSchedule,
  isRotatingSchedule,
  isEndOfDay,
  isBlankDate,
  isWorkingDay,
  workDays,
  weekDays,
  convertToMomentArray,
  calculateDaysDifferenceInclusive
}
