import React, { useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { IIconName } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import useHovered from 'src/components/hooks/useHovered'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  title: string
  iconName: IIconName
  onClick: () => void
  selected: boolean
  hidden: boolean
}

const Container = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  margin-left: 24px;
  padding-right: 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      border-radius: 8px;
      border: 2px solid transparent;

      &:focus {
        outline: none;
        border: 2px solid ${colors.main60};
      }
    `}
`

const IconWrapper = styled(Icon)<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`

const Text = styled.span<{ $hovered?: boolean; $selected?: boolean }>`
  font-family: Roboto, serif;
  font-size: 14px;
  user-select: none;
  letter-spacing: 0.01em;
  margin-left: 8px;
  padding: 3.5px 0;

  &:focus {
    outline: none;
  }

  ${props => {
    const { $selected, $hovered } = props
    if ($selected) {
      return css`
        color: ${$hovered ? colors.main110 : colors.main100};
        font-weight: 500;
      `
    } else {
      return css`
        color: ${$hovered ? colors.dark100 : colors.dark80};
        font-weight: 400;
      `
    }
  }}
`

const AccordionSubItem = React.memo((props: IProps) => {
  const { title, iconName, onClick, selected, hidden } = props
  const containerRef: any = useRef(null)
  const hovered: boolean = useHovered(containerRef)

  const iconColor: string = useMemo(() => {
    if (selected) {
      return hovered ? colors.main110 : colors.main100
    } else {
      return hovered ? colors.dark50 : colors.dark20
    }
  }, [hovered, selected])

  return (
    <Container key={title} ref={containerRef} onClick={onClick}>
      <InnerContainer
        aria-label={title}
        aria-selected={selected}
        onClick={onClick}
        tabIndex={hidden ? -1 : 0}
      >
        <IconWrapper
          name={iconName}
          isSubItem
          selected={selected}
          hovered={hovered}
          settings={{ fill: iconColor }}
          aria-hidden
        />
        <Text $selected={selected} $hovered={hovered}>
          {title}
        </Text>
      </InnerContainer>
    </Container>
  )
})

AccordionSubItem.displayName = 'AccordionSubItem'

export default AccordionSubItem
