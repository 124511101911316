import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { ITimelinePeriodConfig, IGetPeriodConfigInput } from '../../../index'
import getAddablePeriodKeyDate from '../getAddablePeriodKeyDate'
import getDetailsType from '../getDetailsType'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: LeaveDatePeriodPicker,
  periodKeyDate: getAddablePeriodKeyDate(input),
  removable: true,
  detailsType:
    input.leave.type === 'Sabbatical'
      ? getDetailsType(input)
      : baseConfig.detailsType
})
