import LeaveDateSinglePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateSinglePicker'
import moment from 'moment'
import { IIconName } from 'src/react-app-env'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '..'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent: LeaveDateSinglePicker,
  timeViewProps: {
    ...baseConfig.timeViewProps,
    iconName: 'due_date_baby_head' as IIconName
  }
})
