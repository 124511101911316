import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Toggle from 'src/components/Toggle'

interface IProps {
  className?: string
  value: boolean
  onValueChanged: (value: boolean) => void
  disabled: boolean
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          display: flex;
          align-items: center;
        `
      : css`
          width: 100%;
        `}
`

const RowContainer = styled.div<{ $disabled: boolean }>`
  cursor: ${props => (props.$disabled ? 'unset' : 'pointer')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}
`

const Text = styled.span`
  user-select: none;
  font-style: normal;
  font-weight: normal;
  margin-right: 12px;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-weight: 500;
          font-size: 16px;
        `}
`

const ToggleContainer = styled.div`
  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    `}
`

const LeaveApprovalSelector = React.memo((props: IProps) => {
  const { className, value, onValueChanged, disabled } = props
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    if (disabled) {
      return
    }
    onValueChanged(!value)
  }, [value, onValueChanged, disabled])

  return (
    <Container onClick={onClick} className={className}>
      <RowContainer $disabled={disabled}>
        <Text aria-hidden>{t(`common.leaveApprovedByTpa`)}</Text>
        <ToggleContainer>
          <Toggle
            toggled={value}
            disabled={disabled}
            ariaLabel={t(`common.leaveApprovedByTpa`)}
          />
        </ToggleContainer>
      </RowContainer>
    </Container>
  )
})

LeaveApprovalSelector.displayName = 'LeaveApprovalSelector'

export default LeaveApprovalSelector
