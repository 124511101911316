import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Th, Td } from '../Table'
import { DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'
import moment from 'moment'
import { Description } from '../Description'
import { styled } from 'styled-components'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

interface IProps {
  holidays: IHoliday[]
  period: any
}

export const dataAttrs = {
  description: () => 'holiday-list-description',
  table: () => 'holiday-list-table',
  tr: () => 'holiday-list-tr'
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DescriptionWrapper = styled(Description)`
  margin-left: 12px;
`

const HolidayList = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { holidays, period } = props
  const { periodName, detailsHolidays } = period.timelineConfig
  const hasHolidays = holidays?.length > 0

  const rows = useMemo(
    () =>
      holidays?.map((holiday, index) => (
        <tr data-testid={dataAttrs.tr()} key={index}>
          <Td>{moment(holiday.observed).utc().format(DATE_FORMAT_WITH_DAY)}</Td>
          <Td
            aria-label={t('common.accessibilityText.holidayName', {
              item: holiday.name
            })}
          >
            {holiday.name}
          </Td>
        </tr>
      )),
    [t, holidays]
  )
  return (
    <Container>
      {detailsHolidays.showDescription && (
        <DescriptionWrapper
          allowsParagraph
          data-testid={dataAttrs.description()}
          urlTransform={TelUrlTransformer}
          components={{ a: LinkRenderer }}
        >
          {t(detailsHolidays.descriptionKey(), { periodName })}
        </DescriptionWrapper>
      )}
      {hasHolidays && (
        <>
          <DescriptionWrapper allowsParagraph components={{ a: LinkRenderer }}>
            {t('common.holidays.details', { periodName })}
          </DescriptionWrapper>
          <Table data-testid={dataAttrs.table()}>
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <thead>
              <tr data-testid={dataAttrs.tr()}>
                <Th>{t('common.holidays.date')}</Th>
                <Th>{t('common.holidays.name')}</Th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </>
      )}
    </Container>
  )
})

HolidayList.displayName = 'HolidayList'

export default HolidayList
