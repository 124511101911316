import React, { useRef, useState, useImperativeHandle } from 'react'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { printDisplayNoneMixin } from 'src/theme/utils'

const ANIMATION_TIME = 200

interface IProps {
  className?: string
  children: any
  headerView: any
  top?: string
  onExpanderStateWillChange?: (isExpanded: boolean) => void
  onExpanderStateDidChange?: (isExpanded: boolean) => void
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.light100};
  ${printDisplayNoneMixin};
  z-index: ${zIndex.mobileExpanderView.content};
`

const ExpanderContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.mobileExpanderView.content};
`

const expandKeyFrames = keyframes`
  0% {
    transform: translate(0px, -8px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
}`

const collapsingKeyFrames = keyframes`
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, -8px);
    opacity: 0;
}`

const ContentContainer = styled.div<{ $top: string; $expanded: boolean }>`
  position: absolute;
  top: ${props => props.$top || '88px'};
  left: 0;
  right: 0;
  z-index: ${zIndex.mobileExpanderView.content};

  ${props => {
    const { $expanded } = props
    if ($expanded) {
      return css`
        animation: ${expandKeyFrames} ${ANIMATION_TIME}ms ease-in-out forwards;
      `
    } else {
      return css`
        animation: ${collapsingKeyFrames} ${ANIMATION_TIME}ms ease-in-out
          forwards;
      `
    }
  }}
`

const MobileTopWrapper = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 16px;
  height: 100%;
`

const MobileExpandView = React.forwardRef((props: IProps, ref: any) => {
  const {
    className,
    children,
    headerView,
    top,
    onExpanderStateWillChange,
    onExpanderStateDidChange
  } = props

  const [expanded, setExpanded] = useState(false)
  const [animating, setAnimating] = useState(false)

  const contentContainerRef: any = useRef(null)

  const expand = () => {
    setExpanded(true)
    if (onExpanderStateWillChange) {
      onExpanderStateWillChange(true)
    }

    if (onExpanderStateDidChange) {
      window.setTimeout(() => {
        onExpanderStateDidChange(true)
      }, ANIMATION_TIME)
    }
  }

  const collapse = () => {
    setExpanded(false)
    setAnimating(true)

    if (onExpanderStateWillChange) {
      onExpanderStateWillChange(false)
    }

    window.setTimeout(() => {
      setAnimating(false)
      if (onExpanderStateDidChange) {
        onExpanderStateDidChange(false)
      }
    }, ANIMATION_TIME)
  }

  const toggle = () => {
    if (expanded) {
      collapse()
    } else {
      expand()
    }
  }

  useImperativeHandle(ref, () => ({
    expand,
    collapse,
    toggle
  }))

  return (
    <Container className={className}>
      <ExpanderContainer>
        <MobileTopWrapper>{headerView}</MobileTopWrapper>
      </ExpanderContainer>
      {(expanded || animating) && (
        <ContentContainer
          ref={contentContainerRef}
          $expanded={expanded}
          $top={top}
        >
          {children}
        </ContentContainer>
      )}
    </Container>
  )
})

MobileExpandView.displayName = 'MobileExpandView'

export default MobileExpandView
