import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Link } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { authInfo } from 'src/graphql/authInfo'
import SignInContext, {
  ISignInContext
} from 'src/features/Welcome/components/WelcomePage/context'

interface IProps {
  name: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.theme.isDesktop ? '24px' : '16px')};
`

const ButtonWrapper = styled(Button)`
  min-width: 200px;
  align-self: center;
`

const SignInWithPersonalEmailLink = styled(Link)`
  grid-area: link;
  font-size: 14px;
  height: 14px;
`

export const dataAttrs = {
  signInWithSsoBtn: () => 'select-sign-in-type-sso-btn',
  signInWithOtpBtn: () => 'select-sign-in-type-otp-btn'
}

const SelectSignInType = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props
  const { onSignInWithSso, signing, onNextStage }: ISignInContext =
    useContext(SignInContext)

  const loginButtonView = useMemo(() => {
    if (signing) {
      return (
        <ButtonWrapper
          icon={{ iconName: 'checkmark', iconProps: { fill: 'white' } }}
        >
          {t('welcomePage.signingIn')}
        </ButtonWrapper>
      )
    } else {
      return (
        <ButtonWrapper
          data-testid={dataAttrs.signInWithSsoBtn()}
          onClick={onSignInWithSso}
        >
          {t(`welcomePage.${name}.withSSO`)}
        </ButtonWrapper>
      )
    }
  }, [t, name, signing, onSignInWithSso])

  return (
    <Container>
      {loginButtonView}
      {authInfo.getEnableOtpLogin() && (
        <SignInWithPersonalEmailLink
          data-testid={dataAttrs.signInWithOtpBtn()}
          onClick={onNextStage}
        >
          {t(`welcomePage.${name}.withPersonalEmail`)}
        </SignInWithPersonalEmailLink>
      )}
    </Container>
  )
})

SelectSignInType.displayName = 'SelectSignInType'

export default SelectSignInType
