import React, { ReactNode, useMemo, useRef } from 'react'
import styled from 'styled-components'
import useComponentRect from 'src/components/hooks/useComponentRect'
import { getLayoutRule } from './methods'

export interface IProps {
  children: any
  minBlockWidth: number
  noVerticalMargin?: boolean
}

const Container = styled.div<any>`
  width: 100%;
  transition: all 100ms;
  overflow: hidden;
`

const Row = styled.div<{ $minWidth: number; $noVerticalMargin: boolean }>`
  transition: all 100ms;
  display: flex;
  overflow: hidden;
  min-width: ${(props: any) => props.$minWidth}px;
  margin: ${props =>
    props.$noVerticalMargin
      ? '0'
      : props.theme.isDesktop
        ? '16px 0'
        : '12px 0'};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const Layout = React.memo((props: IProps) => {
  const { children, minBlockWidth, noVerticalMargin } = props
  const containerRef = useRef(null)

  const { width } = useComponentRect(containerRef)

  const layout: number[] = useMemo(() => {
    if (width === 0) {
      return [0]
    }

    return getLayoutRule(
      width,
      minBlockWidth,
      React.Children.toArray(children).length
    )
  }, [width, minBlockWidth, children])

  const rowViews: any[] = useMemo(() => {
    let addedBlocks = 0
    const result: ReactNode[] = []

    for (let i = 0; i < layout.length; i++) {
      const rowBlocksCount: number = layout[i]
      const currentBlocks: ReactNode[] = []
      for (let j = addedBlocks; j < rowBlocksCount + addedBlocks; j++) {
        currentBlocks.push(React.Children.toArray(children)[j])
      }
      addedBlocks += rowBlocksCount

      result.push(
        <Row
          key={i}
          $minWidth={minBlockWidth}
          $noVerticalMargin={noVerticalMargin}
        >
          {currentBlocks}
        </Row>
      )
    }

    return result
  }, [layout, children, minBlockWidth, noVerticalMargin])

  return <Container ref={containerRef}>{rowViews}</Container>
})

Layout.displayName = 'Layout'

export default Layout
