import React from 'react'
import styled, { css } from 'styled-components'
import isEqual from 'lodash.isequal'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  children: string
  isUnused: boolean
}

const Text = styled(MarkdownText)<{ $isUnused: boolean }>`
  font-size: 12px;
  line-height: 130%;
  color: ${props =>
    props.$isUnused
      ? props.theme.colors.timelineIncome
      : props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 2px;
        `
      : css`
          margin-top: 6px;
        `}

  @media print {
    margin-top: 2px;
  }
`

const TypeView = React.memo(
  (props: IProps) => {
    const { isUnused, children } = props

    return <Text $isUnused={isUnused}>{children}</Text>
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

TypeView.displayName = 'TypeView'

export default TypeView
