import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from 'src/UIKit'
import { IIcon } from 'src/react-app-env'
import theme from 'src/theme'
import useElementFocused from 'src/components/hooks/useElementFocused'
import ScreenContext from 'src/contexts/ScreenContext'
import { TutorialElementsRegister } from 'src/features/Timeline/Tutorial'

const { colors } = theme()

interface IProps {
  className?: string
  onClick: () => void
  translationKey: string
  disabled: boolean
}

const ButtonWrapper = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  margin-right: 40px;
  width: 171px;
  height: 40px;
`

const Container = styled.div`
  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}
`

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12px 0 0;
`

const Text = styled.span`
  user-select: none;
  font-style: normal;
  margin-right: 12px;
  color: ${props => props.theme.colors.dark60};
  font-size: 16px;
  font-weight: 500;
`

const MobileButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  cursor: pointer;
  color: ${props => props.theme.colors.main100};
  user-select: none;
  white-space: nowrap;
  display: flex;
  gap: 8.1px;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    outline-offset: 0;
    text-decoration: underline;
  }
`

export const dataAttrs = {
  button: () => `vacation-button`,
  mobileButton: () => `mobile-button`
}

const VacationReportButton = React.memo((props: IProps) => {
  const { onClick, disabled, translationKey } = props
  const { isDesktop, i18Context } = useContext(ScreenContext)
  const { t } = useTranslation()
  const buttonRef: any = useRef(null)
  const [hovered, setHovered] = useState(false)
  const focused: boolean = useElementFocused(buttonRef.current)

  useEffect(() => {
    TutorialElementsRegister.getInstance().vacationCalculatorElement =
      buttonRef.current
  }, [buttonRef])

  const fillIconColor = () => {
    if (hovered || focused) {
      return colors.main110
    }
    if (disabled) {
      return colors.main40
    }
    return colors.main100
  }

  const icon: IIcon = {
    iconName: 'calculator',
    iconProps: {
      fill: fillIconColor()
    }
  }

  const vacationAccrualText: string = useMemo(
    () =>
      t(translationKey, {
        context: i18Context
      }),
    [i18Context, t, translationKey]
  )

  return (
    <Container>
      {isDesktop ? (
        <ButtonWrapper
          data-testid={dataAttrs.button()}
          ref={buttonRef}
          onClick={onClick}
          disabled={disabled}
          icon={icon}
          appearance={'cancel'}
          onMouseOver={() => {
            setHovered(true)
          }}
          onMouseOut={() => {
            setHovered(false)
          }}
          showChildByCenter
        >
          {vacationAccrualText}
        </ButtonWrapper>
      ) : (
        <RowContainer>
          <Text>{t('vacationBalance.title')}</Text>
          <MobileButton
            data-testid={dataAttrs.mobileButton()}
            onMouseOver={() => {
              setHovered(true)
            }}
            onMouseOut={() => {
              setHovered(false)
            }}
            ref={buttonRef}
            onClick={onClick}
            disabled={disabled}
          >
            <Icon name={icon.iconName} settings={icon.iconProps} />
            {vacationAccrualText}
          </MobileButton>
        </RowContainer>
      )}
    </Container>
  )
})

VacationReportButton.displayName = 'VacationReportButton'

export default VacationReportButton
