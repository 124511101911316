import styled, { css } from 'styled-components'

const Value = styled.div<{ $showAsterisk?: boolean }>`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  font-weight: 400;
  color: ${props => props.theme.colors.dark80};
  text-align: left;
  line-height: 150%;
  padding-top: 2px;

  ${props =>
    props.$showAsterisk &&
    css`
      &::after {
        content: '*';
        color: ${props.theme.colors.dark80};
      }
    `}
`

export default Value
