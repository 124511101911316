import React, { useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TutorialElementsRegister } from 'src/features/Timeline/Tutorial'
import Toggle from 'src/components/Toggle'
import { SUBTYPE_SELECTOR_TOGGLED } from 'src/constants/events'

export type SubtypeSelectorAppearance =
  | 'timeline'
  | 'timeline_mobile'
  | 'single_line'

interface IProps {
  className?: string
  subtypes: ILeaveSubType[]
  subtype: ILeaveSubType
  onLeaveSubtypeChanged: (subtype: ILeaveSubType) => void
  appearance: SubtypeSelectorAppearance
  customTitle?: string
  customTitleStyle?: any
  disabled?: boolean
}

const Container = styled.div<{ $appearance: string }>`
  ${props =>
    props.theme.isDesktop &&
    css`
      display: flex;
      align-items: center;
    `}
  ${props =>
    (props.$appearance === 'single_line' ||
      props.$appearance === 'timeline_mobile') &&
    css`
      width: 100%;
    `}
`

const RowContainer = styled.div<{ $appearance: string; $disabled: boolean }>`
  cursor: ${props => (props.$disabled ? 'unset' : 'pointer')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props =>
    (props.$appearance === 'single_line' ||
      props.$appearance === 'timeline_mobile') &&
    css`
      width: 100%;
    `}
`

const Text = styled.span<{ $appearance: string }>`
  user-select: none;
  font-style: normal;
  font-weight: 400;
  color: ${props => props.theme.colors.dark60};
  margin-right: 12px;
  font-size: 14px;

  ${props => {
    const { $appearance } = props
    if ($appearance === 'timeline_mobile') {
      return css`
        font-weight: 500;
        font-size: 16px;
      `
    } else if (props.theme.isDesktop && $appearance === 'single_line') {
      return css`
        font-size: 16px;
      `
    }
  }}
`

const ToggleContainer = styled.div<{ $appearance: string }>`
  ${props =>
    (props.$appearance === 'single_line' ||
      props.$appearance === 'timeline_mobile') &&
    css`
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    `}
`

const SubtypeSelector = React.memo((props: IProps) => {
  const {
    className,
    subtypes,
    subtype,
    onLeaveSubtypeChanged,
    appearance,
    customTitle,
    disabled
  } = props
  const { t } = useTranslation()
  const ref: any = useRef()

  useEffect(() => {
    TutorialElementsRegister.getInstance().subtypeSelectorElement = ref.current
  }, [ref])

  const toggled: boolean = subtypes.indexOf(subtype) === 1

  const onClick: any = () => {
    if (disabled) {
      return
    }
    window.dispatchEvent(new Event(SUBTYPE_SELECTOR_TOGGLED))
    onLeaveSubtypeChanged(props.subtypes[toggled ? 0 : 1])
  }

  const title: string = useMemo(
    () => customTitle || t(`subtypeToggleTitleBySubtype.${subtypes[1]}`),
    [customTitle, t, subtypes]
  )

  if (!subtypes.length) {
    return null
  }

  return (
    <Container
      ref={ref}
      onClick={onClick}
      $appearance={appearance}
      className={className}
    >
      <RowContainer $appearance={appearance} $disabled={disabled}>
        <Text $appearance={appearance} aria-hidden>
          {title}
        </Text>
        <ToggleContainer $appearance={appearance}>
          <Toggle toggled={toggled} ariaLabel={title} disabled={disabled} />
        </ToggleContainer>
      </RowContainer>
    </Container>
  )
})

SubtypeSelector.displayName = 'SubtypeSelector'

export default SubtypeSelector
