import React, { useContext } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import zIndex from 'src/constants/zIndex'
import TitleDatesRangeView from 'src/features/Timeline/components/vertical/DetailsView/components/TitleDesktop/components/TitleDatesRangeView'
import { ITimelinePeriodDatesRange } from 'src/utils/leaveUtils'
import TimelineViewContext, {
  ITimelineViewContext
} from 'src/features/Timeline/components/vertical/TimelineView/TimelineViewContext'
import { useTranslation } from 'react-i18next'

interface IProps {
  children: string
  datesRange: ITimelinePeriodDatesRange
}

const Container = styled.div`
  position: relative;
  z-index: ${zIndex.timeline.mobile.detailsViewTitle};
`

const TitleContainer = styled.div`
  padding: 0 32px 0 0;
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 24px;
  line-height: 28px;
  color: ${props => props.theme.colors.dark60};
`

const Separator = styled.div`
  width: calc(100% - 36px);
  height: 1px;
  margin: 16px 24px 16px 12px;
  background: ${props => props.theme.colors.main10};
`

const TitleDatesRangeViewWrapper = styled(TitleDatesRangeView)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const HideDetailsButton = styled.button`
  margin-left: 16px;
  font-size: 14px;
  line-height: 16px;
  color: ${p => p.theme.colors.main100};

  &:hover {
    color: ${p => p.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${p => p.theme.colors.main110};
  }
`

const TimelineDetailsViewTitleDesktop = React.memo(
  (props: IProps) => {
    const { children, datesRange } = props
    const { onDetailsClick }: ITimelineViewContext =
      useContext(TimelineViewContext)
    const { t } = useTranslation()

    return (
      <Container>
        <TitleContainer>
          <Title>{children}</Title>
          {datesRange && <TitleDatesRangeViewWrapper datesRange={datesRange} />}
          <HideDetailsButton
            onClick={() => {
              onDetailsClick(null)
            }}
          >
            {t('common.hideDetails')}
          </HideDetailsButton>
        </TitleContainer>
        <Separator />
      </Container>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

TimelineDetailsViewTitleDesktop.displayName = 'TimelineDetailsViewTitleDesktop'

export default TimelineDetailsViewTitleDesktop
