import React, { useContext, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import DatePicker from 'src/components/DatePicker'
import { useTranslation } from 'react-i18next'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import ScreenContext from 'src/contexts/ScreenContext'

const datePickerMixin = css`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 360px;
        `
      : css`
          width: 100%;
        `}
`

const StartDatePicker = styled(DatePicker)`
  ${datePickerMixin}
`

const EndDatePicker = styled(DatePicker)`
  ${datePickerMixin}

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 16px;
        `
      : css`
          margin-top: 16px;
        `}
`

const ControlsContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      width: 100%;
    `}
`

export const CreateSicknessView = React.memo(() => {
  const { t } = useTranslation()
  const {
    minDate,
    maxDate,
    selectedStartDate,
    selectedEndDate,
    minEndDate,
    maxEndDate,
    onStartDateChanged,
    onEndDateChanged,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  }: ICreateLeaveContext = useContext(LeaveContext)
  const { i18Context } = useContext(ScreenContext)

  const startDateRef = useRef(null)
  const endDateRef = useRef(null)

  const startDatePicker = useMemo(
    () => (
      <StartDatePicker
        title={t('createLeave.sickness.leaveStartDate')}
        momentCurrentMinMax={{
          current: selectedStartDate,
          min: minDate,
          max: maxDate
        }}
        placeholder={t('common.selectDate')}
        onDateChanged={onStartDateChanged}
        onOpened={() =>
          endDateRef.current.isOpen && endDateRef.current.toggle()
        }
        calendarRef={startDateRef}
      />
    ),
    [t, selectedStartDate, minDate, maxDate, onStartDateChanged]
  )

  const endDatePicker = useMemo(
    () => (
      <EndDatePicker
        title={t('createLeave.sickness.leaveEndDate')}
        momentCurrentMinMax={{
          current: selectedEndDate,
          min: minEndDate,
          max: maxEndDate
        }}
        defaultActiveStartDate={minEndDate}
        disabled={!selectedStartDate}
        placeholder={t('common.selectDate')}
        onDateChanged={onEndDateChanged}
        onOpened={() =>
          startDateRef.current.isOpen && startDateRef.current.toggle()
        }
        calendarRef={endDateRef}
      />
    ),
    [
      t,
      selectedEndDate,
      minEndDate,
      maxEndDate,
      onEndDateChanged,
      selectedStartDate
    ]
  )

  const content: any = useMemo(
    () => (
      <>
        <ControlsContainer>
          {startDatePicker}
          {endDatePicker}
        </ControlsContainer>
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={!selectedStartDate || !selectedEndDate}
        />
      </>
    ),
    [
      startDatePicker,
      endDatePicker,
      t,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      selectedStartDate,
      selectedEndDate
    ]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{t('createLeave.sickness.title')}</CreateLeaveTitle>
      <CreateLeaveDescription>
        {t('createLeave.sickness.description', {
          context: i18Context
        })}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

CreateSicknessView.displayName = 'CreateSicknessView'

export default CreateSicknessView
