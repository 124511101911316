import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import withRouter from 'src/components/hooks/useRouter'
import PublishedLeaveView from 'src/components/pages/PublishedLeavePage/components/PublishedLeaveView'
import Context, {
  IPublishedLeaveContext
} from 'src/components/pages/PublishedLeavePage/context'
import { MetricEventSetupManagerImpression } from 'src/constants/metrics'
import NormalRadioButtonOption from 'src/features/Leave/components/NormalRadioButtonOption'
import useCreateMetric from 'src/graphql/hooks/useCreateMetric'
import { ActionableLinkRenderer } from 'src/utils/contentRenderUtils'
import PreviewTimeline from '../PreviewTimeline'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import OptionContainer from 'src/UIKit/OptionContainer'
import styled from 'styled-components'
import { ActivateManagerEnum } from 'src/types/ActivateManagerEnum'

const OptionContainerWrapper = styled(OptionContainer)`
  margin-top: 16px;
`

export const SetupManager = React.memo(() => {
  const { t } = useTranslation()

  const [canSeePreview, setCanSeePreview] = React.useState(false)
  const {
    user,
    nextStage,
    prevStage,
    activateManager,
    setActivateManager,
    setShowProgress
  } = useContext<IPublishedLeaveContext>(Context)

  useCreateMetric({ eventType: MetricEventSetupManagerImpression })

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={activateManager === ActivateManagerEnum.Now}
        onClick={() => {
          setActivateManager(ActivateManagerEnum.Now)
        }}
      >
        {t('publishedLeave.setupManager.options.activateNow')}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={activateManager === ActivateManagerEnum.Later}
        onClick={() => {
          setActivateManager(ActivateManagerEnum.Later)
        }}
        hidesSeparator
      >
        {t('publishedLeave.setupManager.options.activateLater')}
      </NormalRadioButtonOption>
    ],
    [t, activateManager, setActivateManager]
  )

  const content = useMemo(
    () => (
      <>
        <OptionContainerWrapper>{options}</OptionContainerWrapper>
        <CreateLeaveNote
          text={t('publishedLeave.setupManager.note')}
          shows={activateManager === ActivateManagerEnum.Later}
          marginTop={32}
        />
      </>
    ),
    [options, activateManager, t]
  )

  const onPreviewClicked = useCallback(() => {
    setCanSeePreview(true)
    setShowProgress(false)
  }, [setCanSeePreview, setShowProgress])

  if (canSeePreview) {
    return (
      <PreviewTimeline
        onBackClicked={() => {
          setShowProgress(true)
          setCanSeePreview(false)
        }}
      />
    )
  }

  return (
    <PublishedLeaveView
      descriptionProps={{ managerEmail: user.managerEmail }}
      descriptionComponents={{
        a: ActionableLinkRenderer('#preview', onPreviewClicked)
      }}
      name={'setupManager'}
      onNextClicked={() => nextStage()}
      onBackClicked={() => prevStage()}
      isNextDisabled={activateManager === null}
    >
      {content}
    </PublishedLeaveView>
  )
})

SetupManager.displayName = 'SetupManager'

export default withRouter(SetupManager)
