export interface Stages<T> {
  [key: string]: T | ((context: any) => T)
}

interface Props<T> {
  init: T
  stages: Stages<T>
}

/**
 * Implements screen workflow.
 * @example
 *
 * ````js
 * w = new Workflow<T>({
 *   init: 'stage1',
 *   stages: {
 *     stage1: 'stage2',
 *     stage2: ({ length }) => length === 2 ? 'stage3' : 'stage4',
 *     stage4: ({ workDays }) => workDays > 24 ? null : 'stage5'
 *   }
 * })
 * ````
 */
export default class Workflow<T> {
  public current: T
  public history: T[]
  private stages: Stages<T>
  public context: any

  #level: number = 1

  public constructor(props: Props<T>) {
    this.current = props.init
    this.stages = props.stages
    this.history = []
  }

  public setContext(context: any) {
    this.context = context
  }

  public addStages(newStages: Stages<T>) {
    this.stages = {
      ...this.stages,
      ...newStages
    }
  }

  public get next(): T {
    return this.getNextStage(this.current)
  }

  public get level(): number {
    return this.#level
  }

  public get depth(): number {
    let count = 0
    let stage = this.current

    while ((stage = this.getNextStage(stage))) {
      count++
    }
    return this.#level + count
  }

  public get progress() {
    return { current: this.level, total: this.depth }
  }

  public moveNext(): T {
    this.history.push(this.current)
    this.current = this.next
    if (this.current) {
      this.#level++
    }
    return this.current
  }

  public moveBack(): T {
    const previous = this.history.pop()
    this.current = previous
    if (previous) {
      this.#level--
    }
    return this.current
  }

  public moveTo(stage: T): T {
    while (this.history.pop() !== stage && this.history.length > 0) {}
    this.current = stage
    return stage
  }

  private getNextStage(stage: T) {
    if (!stage) {
      return undefined
    }
    const nextValue = this.stages[stage as string]
    return nextValue instanceof Function
      ? nextValue({ ...this.context, workflow: this })
      : nextValue
  }
}

/**
 * Creates a simple workflow based on array of stages
 * @param values
 * @example
 *
 * ````js
 * ws<T>(['stage1', 'stage2', 'stage3']) // => creates worklow
 *
 * {
 *   init: 'stage1',
 *   stages: {
 *     stage1: 'stage2',
 *     stage2: 'stage3',
 *     stage3: null
 *   }
 * }
 * ````
 */
export const ws = <T>(values: T[]): Workflow<T> => {
  const [init] = values

  const stages = values.reduce((acc: Stages<T>, value: T, index: number) => {
    acc[value as string] = values[index + 1]
    return acc
  }, {})

  return new Workflow({ init, stages })
}

/**
 * A shortcut to create a workflow from properties
 * @param props
 */
export const wf = <T>(props: Props<T>): Workflow<T> => new Workflow<T>(props)
