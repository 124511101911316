import React, { ReactNode, useMemo, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import LeavesList from 'src/features/Leave/components/PickLeaveView/components/LeavesList'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import ScreenContext from 'src/contexts/ScreenContext'
import InfoNoticeExpandable from 'src/components/InfoNoticeExpandable'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          margin: 0 16px;
        `
      : css`
          padding: 0 16px;
          width: calc(100vw - 32px);
          height: 100%;
          margin: 0 auto;
        `}

  ${mobileMaxWidthMixin};
`

export const PickLeaveView = React.memo(() => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const { user }: ICreateLeaveContext = useContext(LeaveContext)

  const noneOfTheseApplyToMeView: ReactNode = useMemo(
    () => (
      <InfoNoticeExpandable
        title={t('createLeave.noneOfTheseApplyToMe')}
        text={t('createLeave.noneOfThisApplyToMeNote')}
        marginTop={isDesktop ? 24 : 16}
        marginBottom={isDesktop ? 8 : 24}
      />
    ),
    [isDesktop, t]
  )

  const buttonsView: ReactNode = useMemo(
    () => (
      <CreateLeaveButtons
        backTitle={t('common.back')}
        nextTitle={t('common.next')}
        onBackClick={() => {}}
        onNextClick={() => {}}
        isNextDisabled
        hidesBackButton
        hidesNextButton
      />
    ),
    [t]
  )

  return (
    <Content>
      <CreateLeaveTitle>
        {t(
          user?.firstName
            ? 'createLeave.welcome'
            : 'createLeave.welcomeWithoutName',
          { user: user?.firstName }
        )}
      </CreateLeaveTitle>
      <CreateLeaveDescription>
        {t('createLeave.description')}
      </CreateLeaveDescription>
      <LeavesList />
      {noneOfTheseApplyToMeView}
      {buttonsView}
    </Content>
  )
})

PickLeaveView.displayName = 'PickLeaveView'

export default PickLeaveView
