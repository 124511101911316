import React, { useMemo } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import { ITimelinePeriodDatesRange } from 'src/utils/leaveUtils'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  datesRange: ITimelinePeriodDatesRange
  className?: string
}

const Container = styled.div`
  display: flex;
`

const Text = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
`

const TitleDatesRangeView = React.memo(
  (props: IProps) => {
    const {
      datesRange: { start, end },
      className
    } = props

    const s: string = useMemo(() => start.format(DATE_FORMAT), [start])
    const e: string = useMemo(() => end.format(DATE_FORMAT), [end])

    return (
      <Container className={className}>
        <Text aria-label={`${s}, ${e}`}>{`${s} ➞ ${e}`}</Text>
      </Container>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

TitleDatesRangeView.displayName = 'TitleDatesRangeView'

export default TitleDatesRangeView
