import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'
import { isPathnameLikeOneOf } from 'src/utils/routeUtils'
import { journeyMapRoute, timelineRoute } from 'src/routes/constants'

const GlobalStyle = createGlobalStyle<any>`
  ${reset}

  html, body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'font-family: SF UI Text';
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: auto;

    @media print {
      overflow-x: hidden;
    }
  }

  body {
    background: #fff;
    b, strong {
      font-weight: 500;
    }
  }

  #root {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  ${(props: any) => {
    const { pathname } = props
    if (isPathnameLikeOneOf(pathname, [timelineRoute, journeyMapRoute])) {
      return css`
        @media print {
          @page {
            size: A4 portrait;
            margin: 5mm;
            padding: 0;
          }
          #root {
            height: 100%;
          }
          body {
            width: 210mm;
          }
        }
      `
    }
  }}

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  * {
    &:focus {
      outline: 2px solid ${props => props.theme.colors.main110};
      outline-offset: 4px;
    }

    &:focus:active {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: none;
    background: white;
    font-size: 17px;
    -webkit-text-fill-color: #626a74;
    -webkit-box-shadow: 0 0 0px 1000px #FCFCFC inset;
  }
`

export default GlobalStyle
