import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: string
  role: string
}

const Text = styled.span`
  position: absolute;
  color: transparent;
`

const AccessibilityInvisibleText = React.memo((props: IProps) => {
  const { className, children, role } = props
  return (
    <Text className={className} role={role}>
      {children}
    </Text>
  )
})

AccessibilityInvisibleText.displayName = 'AccessibilityInvisibleText'

export default AccessibilityInvisibleText
