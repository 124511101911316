import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface IProps {
  action: IPeriodBlockRequirement
}

const Text = styled.span<any>`
  line-height: 130%;
  color: ${props => props.theme.colors.error10};
  font-size: 12px;

  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: 3px;
      margin-bottom: -4px;
    `}

  @media print {
    margin: 0;
  }
`

const RequiredActionView = React.memo((props: IProps) => {
  const { action } = props
  const { t } = useTranslation()

  return <Text>{t(`timeline.requiredAction.${action}`)}</Text>
})

RequiredActionView.displayName = 'RequiredActionView'

export default RequiredActionView
