import React from 'react'
import styled from 'styled-components'
import Title from './components/Title'
import Value from './components/Value'
import ClaimStatusBadge from 'src/components/ClaimStatusBadge'

interface Item {
  name: string
  value: string
  groupWithPrevious: boolean
  showAsterisk: boolean
}

interface IProps {
  className?: string
  items: Item[]
  title: string
  isCurrentPlan?: boolean
}

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.background};
  border: 1px solid ${props => props.theme.colors.dark10};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.colors.dark60};
`

const TitleContainer = styled.div`
  height: 35px;
  background: ${props => props.theme.colors.dark05};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`

const ContentContainer = styled.div`
  width: 100%;
`

const RowContainer = styled.div<{ $showTopBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-top: ${props => (props.$showTopBorder ? '1px solid #e6e7e8' : 'none')};
`

const RowTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const ItemListTitled = React.memo((props: IProps) => {
  const { title, items, isCurrentPlan } = props
  return (
    <Container>
      <TitleContainer>{title}</TitleContainer>

      <ContentContainer>
        {items?.map((item: any, index: number) => (
          <RowContainer $showTopBorder={!item.groupWithPrevious} key={index}>
            <RowTitleContainer>
              <Title $showAsterisk={item.showAsterisk}>{item.name}</Title>
              <ClaimStatusBadge claimStatus={item.status} />
            </RowTitleContainer>
            <Value $showAsterisk={item.isWaitingStatus && isCurrentPlan}>
              {item.value}
            </Value>
          </RowContainer>
        ))}
      </ContentContainer>
    </Container>
  )
})

ItemListTitled.displayName = 'ItemListTitled'

export default ItemListTitled
