import React, { ReactNode, useMemo } from 'react'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import RadioButtonOption from 'src/components/RadioButtonOption'
import createTheme from 'src/theme'
import CreateLeaveView from '../CreateLeaveView'
import OptionContainer from 'src/UIKit/OptionContainer'
const { colors } = createTheme()

interface IProps {
  name: string
  options: string[]
  selectedOption: string
  onOptionSelected: (value: string) => void
  optionRow?: (option: string) => ReactNode
  optionContentMixin?: any
  onBackClicked: () => void
  onNextClicked: () => void
}

const optionTextMixin = css`
  font-weight: normal;
  color: ${(p: any) => p.theme.colors.dark60};
`

const optionContentMixin = css`
  background: none;
`

export const CreateLeaveOptions = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    name,
    options,
    selectedOption,
    onOptionSelected,
    onBackClicked,
    onNextClicked
  } = props

  const optionRow = useMemo(
    () =>
      props.optionRow ||
      ((option: string) => t(`createLeave.${name}.options.${option}`)),
    [t, name, props.optionRow]
  )

  const radioOptions: ReactNode[] = useMemo(
    () =>
      options.map((option, index) => (
        <RadioButtonOption
          color={colors.dark60}
          key={option}
          selected={selectedOption === option}
          onClick={() => onOptionSelected(option)}
          optionTextMixin={optionTextMixin}
          optionContentMixin={props.optionContentMixin || optionContentMixin}
          hidesSeparator={index === options.length - 1}
        >
          {optionRow(option)}
        </RadioButtonOption>
      )),
    [
      options,
      selectedOption,
      onOptionSelected,
      optionRow,
      props.optionContentMixin
    ]
  )

  return (
    <CreateLeaveView
      name={name}
      onBackClicked={onBackClicked}
      onNextClicked={onNextClicked}
      isNextDisabled={!selectedOption}
    >
      <OptionContainer>{radioOptions}</OptionContainer>
    </CreateLeaveView>
  )
})

CreateLeaveOptions.displayName = 'CreateLeaveOptions'

export default CreateLeaveOptions
