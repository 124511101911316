import React from 'react'
import styled, { css } from 'styled-components'
import { printDisplayNoneMixin } from 'src/theme/utils'

interface IProps {
  children: any
  className?: string
  ariaLabel: string
  useShortTopMargin?: boolean
}

const Container = styled.div`
  max-width: 368px;
  width: 30vw;
  min-width: 320px;
  max-height: calc(100vh - 150px);
  ${printDisplayNoneMixin}
`

const Background = styled.div<{
  $useShortTopMargin: boolean
}>`
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-sizing: border-box;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 8px;
  overflow: hidden auto;

  ${props =>
    props.$useShortTopMargin
      ? css`
          margin: 24px 0 0 20px;
          max-height: calc(100% - 24px);
        `
      : css`
          margin: 104px 0 0 20px;
          max-height: calc(100% - 104px);
        `}
`

const LeftPanelContainer = React.memo((props: IProps) => {
  const { children, className, ariaLabel, useShortTopMargin } = props

  return (
    <Container className={className}>
      <Background
        role={'region'}
        aria-label={ariaLabel}
        $useShortTopMargin={useShortTopMargin}
      >
        {children}
      </Background>
    </Container>
  )
})

LeftPanelContainer.displayName = 'LeftPanelContainer'

export default LeftPanelContainer
