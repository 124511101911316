import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon, Link } from 'src/UIKit'
import { printDisplayNoneMixin } from 'src/theme/utils'
import createTheme from 'src/theme'
import ReactMarkdown from 'react-markdown'
import { banner } from 'src/constants/frame'

const { colors } = createTheme()

interface IProps {
  className?: string
  bannerAttr: IAlert
  onClose: (id: string) => void
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${p => banner.height(p.theme).px};
  background: ${props => props.theme.colors.main90};
  border-bottom: 1px solid ${props => props.theme.colors.main20};

  ${printDisplayNoneMixin}
`

const StyledIcon = styled(Icon)`
  display: inline;
  margin: 0 ${props => (props.theme.isDesktop ? '16px' : '12px')};
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0;
  gap: 6px;
`

const ContentText = styled(ReactMarkdown)`
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  font-weight: normal;
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
`

const ContentLink = styled(Link)`
  font-size: 14px;
  font-weight: normal;
  line-height: 130%;
  color: ${props => props.theme.colors.light100};
  text-decoration-line: underline;

  &:hover {
    text-decoration-line: none;
    color: ${props => props.theme.colors.light100};
  }

  &:focus {
    text-decoration-line: none;
    color: ${props => props.theme.colors.light100};
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 300ms;
  margin: 0 ${props => (props.theme.isDesktop ? '16px' : '12px')};

  &:focus {
    transform: scale(1.2);
  }

  &:hover {
    transform: scale(1.2);
  }
`

export const dataAttrs = {
  closeBtn: () => 'banner-item-close-btn'
}

const BannerItem = React.memo((props: IProps) => {
  const { className, bannerAttr, onClose } = props
  const { t } = useTranslation()

  const onCloseClick = () => {
    onClose(bannerAttr.id)
  }

  const onLinkClick = () => {
    onClose(bannerAttr.id)
    window.open(bannerAttr.cta.link, '_blank', 'noopener')
  }

  return (
    <Container
      className={className}
      aria-label={t('common.accessibilityText.region.banner')}
    >
      <LeftContainer>
        <StyledIcon name={'info_circle'} settings={{ fill: colors.light100 }} />
        <ContentContainer>
          <ContentText>{bannerAttr.content}</ContentText>
          {bannerAttr?.cta?.text && (
            <ContentLink onClick={onLinkClick}>
              {bannerAttr.cta.text}
            </ContentLink>
          )}
        </ContentContainer>
      </LeftContainer>
      <CloseButton
        data-testid={dataAttrs.closeBtn()}
        onClick={onCloseClick}
        aria-label={t('common.accessibilityText.closeButton')}
      >
        <Icon
          name={'close_crossing'}
          settings={{ fill: colors.light100, width: '14px', height: '14px' }}
        />
      </CloseButton>
    </Container>
  )
})

BannerItem.displayName = 'BannerItem'

export default BannerItem
