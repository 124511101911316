import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: any
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${props => (props.theme.isDesktop ? '12px' : '8px')};
  gap: ${props => (props.theme.isDesktop ? '12px' : '8px')};
  height: ${props => (props.theme.isDesktop ? '82px' : '55px')};
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
`

const TileContainer = React.memo((props: IProps) => {
  const { className, children } = props

  return <Container className={className}>{children}</Container>
})

TileContainer.displayName = 'TileContainer'

export default TileContainer
