import React from 'react'
import { H1, Text } from 'src/UIKit'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { mobileMaxWidthMixin } from 'src/theme/utils'

const Container = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    'title'
    'text';
  justify-items: center;
  margin: 0 auto;
  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  grid-area: title;
`

const TextWrapper = styled(Text)`
  grid-area: text;
`

const ErrorPage = React.memo(() => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t('common.oops')}</Title>
      <TextWrapper>{t('common.somethingWentWrong')}</TextWrapper>
    </Container>
  )
})

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
