import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import DatePicker from 'src/components/DatePicker'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  className?: string
  date: any
  title: string
  onDateChanged: (date: any) => void
}

const Container = styled.div`
  background: ${props => props.theme.colors.background};
  width: 100%;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.colors.dark60};
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  font-weight: 400;
`

const PickerContainer = styled.div`
  display: flex;
  font-size: 16px;
  color: ${props => props.theme.colors.main100};
`

const DatePickerWrapper = styled(DatePicker)`
  width: 150px;
  margin-bottom: 20px;
`

const DateSelectorTitled = React.memo((props: IProps) => {
  const { title, date, onDateChanged } = props

  const datePickerView: ReactNode = useMemo(
    () => (
      <DatePickerWrapper
        placeholder={(date.current || date.min).format(DATE_FORMAT)}
        momentCurrentMinMax={date}
        onDateChanged={onDateChanged}
        actAsTextField
      />
    ),
    [date, onDateChanged]
  )

  return (
    <Container>
      <TitleContainer>{title}</TitleContainer>
      <PickerContainer>{datePickerView}</PickerContainer>
    </Container>
  )
})

DateSelectorTitled.displayName = 'DateSelectorTitled'

export default DateSelectorTitled
