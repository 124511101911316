import { IUseDate } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/hooks/useDate'
import { ITimelinePeriod } from 'src/react-app-env'
import {
  getPeriodDurationTextComponents,
  getTimelinePeriodItemDaysDuration,
  isPreOrdinaryMaternity,
  isPostOrdinaryMaternity
} from 'src/utils/leaveUtils'

const MATERNITY_MAX_WEEKS = 26
const MATERNITY_MAX_DAYS = MATERNITY_MAX_WEEKS * 7

export interface IPersonalPickerMethodsInput {
  startDate?: IUseDate
  endDate?: IUseDate
}

export const isConfirmDisabled = ({
  endDate
}: IPersonalPickerMethodsInput): boolean => !endDate?.current

export const isNothingChanged = ({
  startDate,
  endDate
}: IPersonalPickerMethodsInput): boolean =>
  startDate.isSameAsInitial() && endDate.isSameAsInitial()

const maternityTotalDuration = (
  period: ITimelinePeriod,
  duration: number,
  periods: any
): number => {
  let ordinary = null
  if (isPreOrdinaryMaternity(period)) {
    ordinary = periods.find(isPostOrdinaryMaternity)
  } else if (isPostOrdinaryMaternity(period)) {
    ordinary = periods.find(isPreOrdinaryMaternity)
  } else {
    return 0
  }

  return getTimelinePeriodItemDaysDuration(ordinary) + duration
}

const exceededDays = (
  period: ITimelinePeriod,
  duration: number,
  periods: any
): number => {
  const totalDuration = maternityTotalDuration(period, duration, periods)
  return totalDuration <= 0 ? 0 : totalDuration - MATERNITY_MAX_DAYS
}

export const expandPostOrdinaryPeriod = (
  period: ITimelinePeriod,
  duration: number,
  periods: any
): void => {
  const shift = exceededDays(period, duration, periods)
  if (shift > 0) {
    const ordinary = periods.find(isPostOrdinaryMaternity)
    ordinary.periodEnd.current.subtract(shift, 'days')
  }
}

export const expandPreOrdinaryPeriod = (
  period: ITimelinePeriod,
  duration: number,
  periods: any
): void => {
  const shift = exceededDays(period, duration, periods)
  if (shift > 0) {
    const ordinary = periods.find(isPreOrdinaryMaternity)
    ordinary.periodStart.current.add(shift, 'days')
  }
}

export const infoMessage = (
  period: ITimelinePeriod,
  duration: number,
  periods: any,
  t: any
) => {
  const count = exceededDays(period, duration, periods)
  if (count < 1) {
    return ''
  }

  if (isPreOrdinaryMaternity(period)) {
    const amount = getPeriodDurationTextComponents(
      count,
      t
    ).humanReadableFullString
    return t('timelineHints.periods.OrdinaryMaternity.prePartumInfoMessage', {
      amount
    })
  }
}

export const selectedMessage = (periods: any, t: any): string => {
  const d1 = getTimelinePeriodItemDaysDuration(
    periods.find(isPreOrdinaryMaternity)
  )
  const d2 = getTimelinePeriodItemDaysDuration(
    periods.find(isPostOrdinaryMaternity)
  )

  const amount = getPeriodDurationTextComponents(
    d1 + d2,
    t
  ).humanReadableFullString

  return `${t('common.selectedFrom', { count: amount })} ${t(
    'common.maxAvailableWeeks',
    { count: MATERNITY_MAX_WEEKS }
  )}`
}

export const getUsedDuration = (periods: any): number => {
  const d1 = getTimelinePeriodItemDaysDuration(
    periods.find(isPreOrdinaryMaternity)
  )
  const d2 = getTimelinePeriodItemDaysDuration(
    periods.find(isPostOrdinaryMaternity)
  )

  return d1 + d2
}

export const getUnusedDuration = (periods: any): number => {
  const usedDuration = getUsedDuration(periods)
  return MATERNITY_MAX_WEEKS * 7 - usedDuration
}
