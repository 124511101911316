import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

const HOLIDAY_THRESHOLD = 5

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  periodPickerSelectedNotice: {
    show: true,
    showDoubleText: true
  },
  detailsHolidays: {
    ...baseConfig.detailsHolidays,
    show: true,
    descriptionKey: () => {
      if (input.period.appearance === 'Addable') {
        return 'common.holidays.descriptionAddable'
      }
      return input.period?.schedule?.usedInPeriod > HOLIDAY_THRESHOLD
        ? 'common.holidays.descriptionMoreThanThreshold'
        : 'common.holidays.description'
    }
  },
  datePickerComponent: MultiDatePicker
})
