import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import DateSelectorTitled from 'src/components/DateSelectorTitled'
import CreateLeaveView from '../UI/CreateLeaveView'
import NormalRadioButtonOption from '../NormalRadioButtonOption'
import OptionContainer from 'src/UIKit/OptionContainer'
const { colors } = createTheme()

const ChooseDateContainer = styled.div<{ $shown: boolean }>`
  margin-top: 20px;
  display: flex;
  ${props =>
    props.$shown
      ? css`
          height: 64px;
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
          height: 0;
        `}
  transition: all 300ms ease-in-out;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          max-width: 640px;
          background: ${props.theme.colors.light100};
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
        `
      : css`
          width: 100%;
        `}
`

const LeaveStatusOptions = ['yesOnLeave', 'noNotOnLeave']

export const MiscarriageLeaveStatus = React.memo(() => {
  const { t } = useTranslation()
  const {
    leaveStatus,
    setLeaveStatus,
    selectedStartDate,
    onStartDateChanged,
    selectedDueDate,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  const radioOptions: ReactNode[] = useMemo(
    () =>
      LeaveStatusOptions.map((option, index) => (
        <NormalRadioButtonOption
          color={colors.dark60}
          key={option}
          selected={leaveStatus === option}
          onClick={() => setLeaveStatus(option)}
          hidesSeparator={index === LeaveStatusOptions.length - 1}
        >
          {t(`createLeave.miscarriageLeaveStatus.options.${option}`)}
        </NormalRadioButtonOption>
      )),
    [t, leaveStatus, setLeaveStatus]
  )

  const maxStartDate = useMemo(
    () => selectedDueDate.clone().subtract(1, 'days').startOf('day'),
    [selectedDueDate]
  )

  useEffect(() => {
    if (!selectedStartDate) {
      onStartDateChanged(maxStartDate)
    }
  }, [selectedStartDate, maxStartDate, onStartDateChanged])

  const content: any = useMemo(
    () => (
      <>
        <OptionContainer>{radioOptions}</OptionContainer>
        {selectedStartDate && (
          <ChooseDateContainer $shown={leaveStatus === 'yesOnLeave'}>
            <DateSelectorTitled
              title={t('createLeave.miscarriageLeaveStatus.leaveStartDate')}
              date={{
                current: selectedStartDate,
                max: maxStartDate
              }}
              onDateChanged={onStartDateChanged}
            />
          </ChooseDateContainer>
        )}
      </>
    ),
    [
      t,
      leaveStatus,
      radioOptions,
      maxStartDate,
      selectedStartDate,
      onStartDateChanged
    ]
  )

  return (
    <CreateLeaveView
      name={'miscarriageLeaveStatus'}
      onBackClicked={() => {
        setLeaveStatus(null)
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
      isNextDisabled={!leaveStatus}
    >
      {content}
    </CreateLeaveView>
  )
})

MiscarriageLeaveStatus.displayName = 'MiscarriageLeaveStatus'

export default MiscarriageLeaveStatus
