import React, { ReactNode, useContext, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import DatePicker from 'src/components/DatePicker'
import { useTranslation } from 'react-i18next'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import { Moment } from 'moment'
import ScreenContext from 'src/contexts/ScreenContext'
import InfoNoticeExpandable from 'src/components/InfoNoticeExpandable'

const datePickerMixin = css`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 360px;
        `
      : css`
          width: 100%;
        `}
`

const StartDatePicker = styled(DatePicker)`
  ${datePickerMixin}
`

const EndDatePicker = styled(DatePicker)`
  ${datePickerMixin}

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 16px;
        `
      : css`
          margin-top: 16px;
        `}
`

const ControlsContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      width: 100%;
    `}
`

export const CreatePersonalView = React.memo(() => {
  const { t } = useTranslation()
  const {
    countryCode,
    minDate,
    maxDate,
    selectedStartDate,
    selectedEndDate,
    minEndDate,
    maxEndDate,
    onStartDateChanged,
    onEndDateChanged,
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    highlightedDateNow,
    customerLeaveCreateConfig
  }: ICreateLeaveContext = useContext(LeaveContext)
  const { isDesktop, i18Context } = useContext(ScreenContext)

  const startDateRef = useRef(null)
  const endDateRef = useRef(null)

  const isUSCountry = countryCode === 'US'
  const { showPersonalNotes } = customerLeaveCreateConfig

  const showsChosePastDateNote: boolean = useMemo(
    () =>
      highlightedDateNow &&
      selectedStartDate &&
      selectedStartDate.utc().isBefore(highlightedDateNow, 'day'),
    [selectedStartDate, highlightedDateNow]
  )

  const startDatePicker = useMemo(
    () => (
      <StartDatePicker
        title={t('createLeave.estimatedLeaveStartDate')}
        momentCurrentMinMax={{
          current: selectedStartDate,
          min: minDate,
          max: maxDate
        }}
        placeholder={t('common.selectDate')}
        onDateChanged={onStartDateChanged}
        onOpened={() =>
          endDateRef.current.isOpen && endDateRef.current.toggle()
        }
        calendarRef={startDateRef}
        highlightedDate={highlightedDateNow}
      />
    ),
    [
      t,
      selectedStartDate,
      minDate,
      maxDate,
      onStartDateChanged,
      highlightedDateNow
    ]
  )

  const endDatePicker = useMemo(
    () => (
      <EndDatePicker
        title={t('createLeave.estimatedLeaveEndDate')}
        momentCurrentMinMax={{
          current: selectedEndDate,
          min: minEndDate,
          max: maxEndDate
        }}
        defaultActiveStartDate={minEndDate}
        disabled={!selectedStartDate}
        placeholder={t('common.selectDate')}
        onDateChanged={onEndDateChanged}
        onOpened={() =>
          startDateRef.current.isOpen && startDateRef.current.toggle()
        }
        calendarRef={endDateRef}
      />
    ),
    [
      t,
      selectedEndDate,
      minEndDate,
      maxEndDate,
      onEndDateChanged,
      selectedStartDate
    ]
  )

  const durationOverlayView: ReactNode = useMemo(() => {
    if (!isUSCountry) {
      return
    }

    return (
      <InfoNoticeExpandable
        title={t('createLeave.iDontKnowMyLeave.duration')}
        text={t('createLeave.iDontKnowMyLeave.durationNote')}
        marginTop={isDesktop ? 0 : 16}
        marginBottom={24}
      />
    )
  }, [isDesktop, isUSCountry, t])

  const showsLessThanThirtyDaysNote: boolean = useMemo(
    () => isUSCountry && showPersonalNotes && !selectedEndDate,
    [isUSCountry, selectedEndDate, showPersonalNotes]
  )

  const showsMoreThanNumberMonthsNote: boolean = useMemo(() => {
    if (
      !isUSCountry ||
      !selectedStartDate ||
      !showPersonalNotes ||
      !selectedEndDate
    ) {
      return false
    }
    const m: Moment = selectedStartDate
      .clone()
      .add(3, 'months')
      .subtract(1, 'day')

    return selectedEndDate.isAfter(m)
  }, [isUSCountry, selectedStartDate, selectedEndDate, showPersonalNotes])

  const content: any = useMemo(
    () => (
      <>
        <ControlsContainer>
          {startDatePicker}
          {endDatePicker}
        </ControlsContainer>
        {isUSCountry && showPersonalNotes && (
          <CreateLeaveNote
            text={t('createLeave.personal.lessThanThirtyDaysNote')}
            shows={showsLessThanThirtyDaysNote}
            marginTop={36}
          />
        )}
        {isUSCountry && showPersonalNotes && (
          <CreateLeaveNote
            text={t('createLeave.personal.moreThanNumberMonthsNote')}
            shows={showsMoreThanNumberMonthsNote}
            marginTop={36}
          />
        )}
        <CreateLeaveNote
          text={t('createLeave.chosePastDateNote')}
          shows={showsChosePastDateNote}
          marginTop={32}
        />
        {!isDesktop && durationOverlayView}
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={
            !selectedStartDate ||
            !selectedEndDate ||
            showsLessThanThirtyDaysNote
          }
        />
        {isDesktop && durationOverlayView}
      </>
    ),
    [
      isUSCountry,
      startDatePicker,
      endDatePicker,
      t,
      showsLessThanThirtyDaysNote,
      showsMoreThanNumberMonthsNote,
      durationOverlayView,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      selectedStartDate,
      selectedEndDate,
      isDesktop,
      showsChosePastDateNote,
      showPersonalNotes
    ]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{t('createLeave.personal.title')}</CreateLeaveTitle>
      <CreateLeaveDescription>
        {t(`createLeave.personal.description`, {
          context: i18Context
        })}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

CreatePersonalView.displayName = 'CreatePersonalView'

export default CreatePersonalView
