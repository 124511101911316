import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import { ITimelinePeriodConfig } from '../../../index'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  periodPickerSelectedNotice: {
    show: true,
    showDoubleText: true
  },
  datePickerComponent: MultiDatePicker
})
