import Annual from './Annual'
import BabyBonding from './BabyBonding'
import Personal from './Personal'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import Sickness from './Sickness'
import UnpaidPersonal from './UnpaidPersonal'

export default {
  Annual,
  BabyBonding,
  Personal,
  PreDelivery,
  Recovery,
  Sickness,
  UnpaidPersonal
}
