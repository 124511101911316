import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { H1 } from 'src/UIKit'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import ScreenContext from 'src/contexts/ScreenContext'
import rehypeRaw from 'rehype-raw'
import {
  HeadingRenderer,
  LinkRenderer,
  TelUrlTransformer
} from 'src/utils/contentRenderUtils'
import { useTranslation } from 'react-i18next'

const TOP_DESKTOP_OFFSET = 108
const TOP_MOBILE_OFFSET = 52

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 0 16px 16px;
      align-items: center;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px auto 32px;
        `
      : css`
          margin: 16px auto 20px;
        `}
`

const Content = styled(ReactMarkdown)`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  h2 {
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    color: ${(props: any) => props.theme.colors.dark80};

    ${props =>
      props.theme.isDesktop
        ? css`
            margin: 14px auto 20px;
            font-size: 22px;
          `
        : css`
            margin: auto;
            font-size: 20px;
          `}
  }

  p {
    ${props =>
      props.theme.isDesktop
        ? css`
            font-size: 16px;
          `
        : css`
            font-size: 14px;
          `}
  }

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  ul {
    list-style: disc;
    padding-left: 2em;
    white-space: normal;
    margin-block-start: -20px;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  blockquote {
    white-space: normal;
    background-color: ${props => props.theme.colors.main05};
    border-left: 4px solid ${props => props.theme.colors.main90};
    padding: 16px;
  }
`

interface IProps {
  role: 'employee' | 'manager'
}

const HelpCenterPage = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const { role } = props

  useEffect(() => {
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split('#')
      if (hashParts.length > 1) {
        const hash = hashParts.slice(-1)[0]
        const section = document.querySelector(`#${hash}`)
        if (section) {
          const elementTop: number = section.getBoundingClientRect().top
          const top: number =
            elementTop +
            document.body.scrollTop -
            (isDesktop ? TOP_DESKTOP_OFFSET : TOP_MOBILE_OFFSET)
          document.body.scrollTo({ top, behavior: 'smooth' })
        }
      }
    }
    scrollToAnchor()
    window.onhashchange = scrollToAnchor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>{t(`helpCenter.${role}.title`)}</Title>
      <Content
        urlTransform={TelUrlTransformer}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: LinkRenderer,
          h2: HeadingRenderer
        }}
      >
        {t(`helpCenter.${role}.content`)}
      </Content>
    </Container>
  )
})

HelpCenterPage.displayName = 'HelpCenterPage'

export default HelpCenterPage
