export const isNewLeave = (status: string): boolean => status === 'New'

export const isDraftLeave = (status: string): boolean => status === 'Draft'

export const isNewOrDraftLeave = (status: string): boolean =>
  isNewLeave(status) || isDraftLeave(status)

export const isPublishedLeave = (status: string): boolean =>
  status === 'Published'

export const isBabyArrivedLeave = (status: string): boolean =>
  status === 'BabyArrived'

export const isPublishedOrBabyArrivedLeave = (status: string): boolean =>
  isPublishedLeave(status) || isBabyArrivedLeave(status)

export const isSuspendedLeave = (status: string): boolean =>
  status === 'Suspended'
