import React from 'react'
import createTheme from 'src/theme'
import { NotificationType } from 'src/react-app-env'
const { colors } = createTheme()

export interface IRouteContext {
  isManagerRoute: boolean
  isHrAdminRoute: boolean
  primaryColor: string
  notificationType: NotificationType
}

const RouteContext = React.createContext({
  isManagerRoute: false,
  isHrAdminRoute: false,
  primaryColor: colors.main100,
  notificationType: 'Employee'
})

export default RouteContext
