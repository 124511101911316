import React from 'react'
import {
  IMomentCurrentMinMax,
  ITimelinePeriod,
  ICountryCode
} from 'src/react-app-env'
import { Moment } from 'moment'
import LeaveHolidays from '../Leave/holidays'

export interface ITimelineContext {
  onSavePlan: () => void
  leave: ILeave
  countryCode: ICountryCode
  externalNotices: IAlert[]
  leaveStartDate: IMomentCurrentMinMax
  dueDate: IMomentCurrentMinMax
  birthDate?: IMomentCurrentMinMax
  disabilityEndDate: IMomentCurrentMinMax
  leaveEndDate: IMomentCurrentMinMax
  gradualReturnStartDate?: IMomentCurrentMinMax
  leaveActiveDutyEndDate?: IMomentCurrentMinMax
  subtypes: ILeaveSubType[]
  subtype: ILeaveSubType
  fromStartToReturnDays: number
  totalLeaveDays: number
  onLeaveSubtypeChanged: (subtype: ILeaveSubType) => void
  onUseSupplementPayChanged: (useSupplementPayChanged: boolean) => void
  onVacationBalanceChanged: (vacationBalance: number) => void
  onDueDateChanged: (date: Moment) => void
  onStartDateChanged: (date: Moment) => void
  onDisabilityEndDateChanged: (date: Moment) => void
  onLeaveEndDateChanged: (date: Moment) => void
  onNewChanges: (changes: any, forceUpdateLeave?: boolean) => void
  updateLeave: (changes: any) => void
  onTimelineSaveChanges: () => void
  onTimelineCancelChanges: () => void
  leaveHolidays: LeaveHolidays
  onCalendarActiveDateChanged: (date: any) => void
  onCloseExternalNotice: (id: string) => void
  onStartOver: () => void
  hasTimelineChanges: boolean
  initialDueDateChanged?: boolean
  initialSubtypeChanged?: boolean
  allInitialValuesChanged?: boolean
  onBabyHasArrivedClicked: () => void
  onBirthDate: (
    birthDate: Moment,
    subtype: string,
    disabilityEndDate: Moment
  ) => void
  onChangeBirthDate: () => void
  onTutorialCompleted: () => void
  timelinePeriods?: ITimelinePeriod[]
  timelinePreference: string
  onApprovedByTpaChanged: (value: boolean) => void
  setTimelineViewInactive: (inactive: boolean) => void
  isTimelineViewInactive: boolean
  isLeaveDurationPickerOpened: boolean
  setDetailsOpened: (opened: boolean) => void
  isDetailsOpened: boolean
  currentDetailsViewPeriod: ITimelinePeriod
  setCurrentDetailsViewPeriod: (period: ITimelinePeriod) => void
  currentDatePickerViewPeriod: ITimelinePeriod
  setCurrentDatePickerViewPeriod: (period: ITimelinePeriod) => void
  showTpaApprovedView: (changes: any) => void
  onCancelChanges: () => void
  fetchLeaveTimeline: (changes: any) => void
  setShowVacationMobileView: (value: boolean) => void
  onRequestClaimUpdateClick: () => void
}

const TimelineContext = React.createContext(null)

export default TimelineContext
