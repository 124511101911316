import React, { useContext, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { ITEM_COMPLETE_ANIMATION_DURATION } from 'src/features/JourneyMap/constants'
import isEqual from 'lodash.isequal'
import moment from 'moment'
import UserContext, { IUserContext } from 'src/contexts/UserContext'
import { useTranslation } from 'react-i18next'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import ScreenContext from 'src/contexts/ScreenContext'
import PriorityLabel from '../../PriorityLabel'
import PhaseLabel from '../../PhaseLabel'
import CompletedBy from './CompletedByView'
import DateView from './DateView'
import Completed from './CompletedView'
import Arrow from './Arrow'
import Description from './Description'
import Title from './Title'
import { journeyMapItem } from 'src/constants/frame'

interface IProps {
  className?: string
  item: IJourneyMapItem
  onItemCompletedClick: () => void
  onItemClick: () => void
}

const fadeOutKeyFrames = (props: any) => keyframes`
   0% {
    transform: translateX(0);
    grid-template-rows: auto auto;
   }
   100% {
    transform: translateX(100%);
    opacity: 0;
    overflow: hidden;
    height: 0;
    min-height: 0;
    grid-template-rows: 0 0;

    ${
      props.theme.isDesktop
        ? css`
            margin: -16px 32px 0 0;
          `
        : css`
            margin: -6px 0 0;
          `
    }
}
`

const OuterContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 12px 32px 12px 0;
        `
      : css`
          margin: 8px 0;
        `}
`

const InnerContainer = styled.div<{ $fadesOut: boolean }>`
  display: grid;
  max-width: 100%;
  width: 100%;
  grid-template-rows: auto auto;

  ${props =>
    props.$fadesOut &&
    css`
      animation: ${fadeOutKeyFrames} ${ITEM_COMPLETE_ANIMATION_DURATION}ms
        forwards;
    `}

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-template-columns: min-content min-content 102px min-content auto min-content;
          grid-template-areas:
            'check indicator date separator title arrow'
            'check indicator date separator description arrow'
            'check indicator date separator labels arrow';
          min-height: 128px;
        `
      : css`
          grid-template-columns: min-content 64px min-content auto min-content;
          grid-template-areas:
            'indicator date separator title arrow'
            'indicator date separator description arrow'
            'indicator date separator labels arrow';
          min-height: 90px;
        `}
`

const Background = styled.div`
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  background: ${props => props.theme.colors.light100};
  grid-row: 1 / 4;

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-column: 2 / span 5;
          border-radius: 8px;
          border: 1px solid ${props.theme.colors.dark10};
        `
      : css`
          grid-column: 1 / span 5;
        `}
`

const Separator = styled.div`
  grid-area: separator;
  width: 1px;
  background: ${props => props.theme.colors.dark05};
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 16px 0;
        `
      : css`
          margin: 8px 0;
        `};
`

export const ClickWrapper = styled.button`
  cursor: pointer;
  grid-row: 1 / 4;
  z-index: ${zIndex.journeyMap.list.item.clickWrapper};

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-column: 2 / span 5;
          border-radius: 8px;
        `
      : css`
          grid-column: 3 / span 3;
        `}

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main110};
  }
`

const LabelsContainer = styled.div`
  grid-area: labels;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px 16px;
        `
      : css`
          margin: 0 8px 8px;

          /* stylelint-disable-next-line media-query-no-invalid */
          @media only screen and (max-width: ${journeyMapItem.mediaMaxWidth()
              .px}) {
            flex-direction: column;
          }
        `}
`

const CompletedViewWrapper = styled(Completed)`
  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 4px;
    `}
`

const JourneyMapListItem = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      className,
      item: {
        priority,
        headline,
        lead,
        completed,
        dueDate,
        phase,
        completedAt,
        completedBy
      },
      onItemCompletedClick,
      onItemClick
    } = props
    const { t } = useTranslation()

    const { isDesktop } = useContext(ScreenContext)
    const managerJourneyMapContext: IManagerJourneyMapContext = useContext(
      ManagerJourneyMapContext
    )

    const animatesCompleted: boolean = (props.item as any).animatesCompleted

    const userContext: IUserContext = useContext(UserContext)
    const shouldShowChangedBy: boolean =
      userContext && completedBy !== userContext.email

    const overdue: boolean = useMemo(
      () =>
        !!managerJourneyMapContext && managerJourneyMapContext.isArchiveMode
          ? false
          : !completed && moment().startOf('day').isAfter(dueDate),
      [managerJourneyMapContext, completed, dueDate]
    )

    const ariaLabel: string = useMemo(() => {
      const status: string = completed
        ? t('common.accessibilityText.jmItemCompleted')
        : t(
            `common.accessibilityText.${
              overdue ? 'jmItemUncompletedOverdue' : 'jmItemUncompleted'
            }`
          )
      return t('common.accessibilityText.jmItem', {
        status,
        headline,
        priority: t(`journeyMapPriorities.${priority}`),
        phase: t(`journeyMapPhases.${phase}`),
        completeBy: dueDate.format('MMM DD, YYYY')
      })
    }, [headline, dueDate, overdue, t, completed, phase, priority])

    return (
      <OuterContainer aria-label={ariaLabel}>
        <InnerContainer
          className={className}
          ref={ref}
          $fadesOut={animatesCompleted}
        >
          <Background />
          <Title>{headline}</Title>
          <Description>{lead}</Description>
          {isDesktop && (
            <CompletedViewWrapper
              completed={completed || animatesCompleted}
              onItemCompletedClick={onItemCompletedClick}
            />
          )}
          <Separator />
          <DateView
            overdue={overdue}
            completed={completed || animatesCompleted}
            dueDate={dueDate}
            onItemCompletedClick={onItemCompletedClick}
          />
          <LabelsContainer>
            {priority && <PriorityLabel priority={priority} ariaHidden />}
            {phase && <PhaseLabel phase={phase} ariaHidden />}
          </LabelsContainer>
          <Arrow />
          <ClickWrapper
            onClick={onItemClick}
            tabIndex={0}
            aria-label={t('common.accessibilityText.openItem')}
          />
        </InnerContainer>
        {shouldShowChangedBy && (
          <CompletedBy email={completedBy} date={moment(completedAt)} />
        )}
      </OuterContainer>
    )
  }),
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.onItemCompletedClick === nextProps.onItemCompletedClick &&
    isEqual(prevProps.item, nextProps.item)
)

JourneyMapListItem.displayName = 'JourneyMapListItem'

export default JourneyMapListItem
