import React from 'react'
import styled from 'styled-components'
import TileContainer from '../TileContainer'

interface IProps {
  title: string
  children: any
}

export const dataAttrs = {
  title: () => 'tile-info-title',
  value: () => 'tile-info-value'
}

const Title = styled.p`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
`

const Value = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${props => (props.theme.isDesktop ? '22px' : '15px')};
  line-height: 100%;
  color: ${props => props.theme.colors.dark80};
`

const TileInfo = React.memo((props: IProps) => {
  const { title, children } = props

  return (
    <TileContainer>
      <Title data-testid={dataAttrs.title()}>{title}:</Title>
      <Value data-testid={dataAttrs.value()}>{children}</Value>
    </TileContainer>
  )
})

TileInfo.displayName = 'TileInfo'

export default TileInfo
