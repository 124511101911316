import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import hasPeriodBlock from '../hasPeriodBlock'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: MultiDatePicker,
  detailsExtraDescription: (t: any) =>
    hasPeriodBlock(input, 'Military Leave of Absence (MLOA)')
      ? t(
          `timelineHints.periods.${baseConfig.detailsType}.extra.militaryLeaveOfAbsence`
        )
      : null
})
