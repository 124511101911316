export const csrf = new (class {
  private token: string

  public constructor() {
    this.token = ''
  }

  public getToken() {
    return this.token
  }

  public setToken(value: string) {
    this.token = value
  }
})()
