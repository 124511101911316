import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import { ILeaveListItemProps } from 'src/features/Leave/components/PickLeaveView/components/LeavesList'
import { KEY_ENTER, KEY_SPACE } from 'src/constants/keys'
import { ArrowContainer } from '../LeavesListItem'

const Container = styled.div<{ $isPressed: boolean }>`
  position: relative;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark10};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  margin: 0;
  width: 100%;
  overflow: hidden;
  user-select: none;

  ${props => {
    if (!props.$isPressed) {
      return css`
        box-shadow: 0 0 20px rgb(98 106 116 / 5%);
      `
    }
  }}

  &:not(:first-child) {
    margin: 8px 0 0;
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 0;
  }
`

const DisabledOverlay = styled.div`
  background: ${props => props.theme.colors.light100};
  border-radius: 8px;
  opacity: 0.7;
  z-index: ${zIndex.welcome.disabledOverlay};
  position: absolute;
  inset: 0;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 0 8px 12px;
  gap: 2px;
`

const TypeText = styled.p`
  color: ${props => props.theme.colors.dark80};
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
`

const DescriptionText = styled.p`
  color: ${props => props.theme.colors.dark50};
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
`

export const LeavesListItemMobile = React.memo((props: ILeaveListItemProps) => {
  const { name, enabled, onSelected } = props
  const { t } = useTranslation()

  const [isPressed, setIsPressed] = useState(false)

  const typeText: string = t(`createLeave.groupsData.${name}.type`)
  const descriptionText: string = t(
    `createLeave.groupsData.${name}.description`
  )

  const onSelectedCallback = useCallback(() => {
    if (!enabled) {
      return
    }

    onSelected(name)
  }, [onSelected, name, enabled])

  const onTouchStart = useCallback(() => {
    if (!enabled) {
      return
    }

    setIsPressed(true)
  }, [setIsPressed, enabled])

  const onTouchEnd = useCallback(() => {
    if (!enabled) {
      return
    }

    setIsPressed(false)
  }, [setIsPressed, enabled])

  const keyHandler = useCallback(
    (event: any) => {
      switch (event.which) {
        case KEY_ENTER:
        case KEY_SPACE: {
          onSelectedCallback()
          break
        }
      }
    },
    [onSelectedCallback]
  )

  return (
    <Container
      tabIndex={0}
      onKeyDown={keyHandler}
      onClick={onSelectedCallback}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchEnd}
      onTouchMove={onTouchEnd}
      $isPressed={isPressed}
    >
      <ContentContainer>
        <TypeText>{typeText}</TypeText>
        <DescriptionText>{descriptionText}</DescriptionText>
      </ContentContainer>
      <ArrowContainer>
        <Icon name={'arrow_forward'} />
      </ArrowContainer>
      {!enabled && <DisabledOverlay />}
    </Container>
  )
})

LeavesListItemMobile.displayName = 'LeavesListItemMobile'

export default LeavesListItemMobile
