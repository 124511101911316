import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react'
import {
  requestOtpUrl,
  loginOtpUrl,
  loginUrl,
  getTermsUrl,
  getPrivacyPolicyUrl
} from 'src/config'
import styled, { css } from 'styled-components'
import { MarkdownText, Icon, Link } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'
import SignInContext, { ISignInContext, SignInType } from './context'
import { SignInStage, getCurrentStageView, getWorkflow } from './methods'
import { csrf } from 'src/graphql/csrf'
import { Moment } from 'moment'
import SharedContext from 'src/contexts/SharedContext'
import { authInfo } from 'src/graphql/authInfo'
import { captureResponseError } from 'src/utils/sentry'

const { colors } = createTheme()

interface IProps {
  customer: string
  onSignInHandler: () => void
  resetSignIn: () => void
  signing: boolean
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'content content'
    'terms privacy';

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-template-rows: repeat(2, min-content);
          align-self: center;
        `
      : css`
          grid-template-rows: auto min-content;
          min-height: 100%;
        `}
`

const GridContainer = styled.div`
  grid-area: content;
  justify-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logos'
    'title'
    'description'
    'content';
  background: rgb(255 255 255 / 80%);
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border-radius: 16px;
  place-self: center;
  max-width: ${props => (props.theme.isDesktop ? '480px' : '343px')};

  ${props =>
    props.theme.isDesktop
      ? css`
          grid-template-rows: repeat(4, min-content) auto;
          min-height: 390px;
          width: 480px;
        `
      : css`
          grid-template-rows: repeat(4, auto);
          min-height: 290px;
        `}
`

const LogosWrapper = styled.div`
  display: grid;
  grid-area: logos;
  place-items: center;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: auto;
  grid-template-areas: 'veerLogo separator companyLogo';

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 32px;
        `
      : css`
          margin-top: 16px;
        `}
`

export const VeerWrapper = styled.div`
  grid-area: veerLogo;
  margin-top: 16px;
`

const Separator = styled.div`
  grid-area: separator;
  width: 1px;
  background: ${props => props.theme.colors.dark10};

  ${props =>
    props.theme.isDesktop
      ? css`
          height: 72px;
          margin: 0 32px;
        `
      : css`
          height: 54px;
          margin: 0 24px;
        `}
`

const CompanyLogo = styled.img`
  grid-area: companyLogo;

  ${props =>
    props.theme.isDesktop &&
    css`
      max-width: 120px;
      height: 56px;
    `}
`

const Title = styled.h1`
  grid-area: title;
  font-weight: 500;
  color: ${props => props.theme.colors.dark80};
  text-align: center;
  margin-top: 48px;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 28px;
        `
      : css`
          font-size: 24px;
          align-self: end;
          margin-right: 16px;
          margin-left: 16px;
        `}
`

const Description = styled(MarkdownText)`
  grid-area: description;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
          margin: 16px 32px 0;
        `
      : css`
          font-size: 14px;
          margin: 16px 16px 0;
        `}
`

const ContentWrapper = styled.div`
  grid-area: content;
  width: 86%;
  margin: 48px 32px 32px;
`

const linkMixin = css`
  margin: 16px 12px;
  font-size: 14px;
`

export const TermsLink = styled(Link)`
  ${linkMixin};
  grid-area: terms;
  justify-self: end;
`

export const PrivacyLink = styled(Link)`
  ${linkMixin};
  grid-area: privacy;
  justify-self: start;
`

export const dataAttrs = {
  title: () => 'welcome-title',
  description: () => 'welcome-description',
  logo: () => 'company-logo'
}

const INITIAL_STAGE = 'selectSignInType'

const WelcomePage = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { customer, onSignInHandler, resetSignIn, signing } = props
  const isMaintenance = !customer
  const isCustomerDisabled = authInfo.getCustomerDisabled()
  const isSignInView = !isMaintenance && !isCustomerDisabled
  const { isDesktop } = useContext(ScreenContext)
  const { customerConfig } = useContext(SharedContext)

  const termsUrl = getTermsUrl()
  const privacyPolicyUrl = getPrivacyPolicyUrl()

  const onSignInWithSso = useCallback(() => {
    onSignInHandler()
    window.open(loginUrl(), '_self')
  }, [onSignInHandler])

  useEffect(() => {
    // Reset the signing state when the page is shown again
    window.addEventListener('pageshow', resetSignIn)

    return () => {
      window.removeEventListener('pageshow', resetSignIn)
    }
  }, [resetSignIn])

  const onSignInWithOtp = useCallback(() => {
    onSignInHandler()
    window.open('/', '_self')
  }, [onSignInHandler])

  const onPrivacyPolicy = () => {
    window.open(privacyPolicyUrl, '_blank', 'noopener')
  }

  const onTerms = () => {
    window.open(termsUrl, '_blank', 'noopener')
  }

  const initalStage: SignInStage = useMemo(() => {
    if (isCustomerDisabled) {
      return 'notAvailable'
    }
    if (isMaintenance) {
      return 'maintenance'
    }
    return INITIAL_STAGE
  }, [isCustomerDisabled, isMaintenance])

  const [signInType, setSignInType] = useState<SignInType>('sso')
  const [personalEmail, setPersonalEmail] = useState<string>('')
  const [currentStage, setCurrentStage] = useState<SignInStage>(initalStage)
  const [codeValidTill, setCodeValidTill] = useState<Moment>(null)

  const handleRequestOtp = (email: string) =>
    fetch(requestOtpUrl(), {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf.getToken()
      },
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        captureResponseError(response)
        throw Error('invalid response')
      }
    })

  const handleLoginOtp = (email: string, code: string) =>
    fetch(loginOtpUrl(), {
      method: 'POST',
      body: JSON.stringify({ email, code }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf.getToken()
      },
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        onSignInWithOtp()
        return null
      }
      return response.json()
    })

  const workflow = useMemo(() => getWorkflow(), [])
  const onNextStage = useCallback(() => {
    const newStage = workflow.moveNext()
    if (newStage) {
      setCurrentStage(newStage)
    }
  }, [workflow, setCurrentStage])

  const onPrevStage = useCallback(() => {
    const newStage = workflow.moveBack()

    if (newStage) {
      setCurrentStage(newStage)
    }
  }, [workflow, setCurrentStage])

  const moveToStage = useCallback(
    (stage: SignInStage) => {
      setCurrentStage(workflow.moveTo(stage))
    },
    [workflow]
  )

  const resetWorkflow = useCallback(() => {
    setCodeValidTill(undefined)
    setCurrentStage(workflow.moveTo(INITIAL_STAGE))
    setPersonalEmail('')
    setSignInType('sso')
  }, [workflow, setPersonalEmail, setSignInType])

  const view = useMemo(() => getCurrentStageView(currentStage), [currentStage])

  const contextValue: ISignInContext = {
    signInType,
    setSignInType,

    personalEmail,
    setPersonalEmail,

    codeValidTill,
    setCodeValidTill,

    onNextStage,
    onPrevStage,
    moveToStage,
    resetWorkflow,

    handleRequestOtp,
    handleLoginOtp,

    signing,
    onSignInWithSso
  }

  useEffect(() => {
    workflow?.setContext(contextValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow])

  const descriptionProps = useMemo(
    () => ({
      email: personalEmail
    }),
    [personalEmail]
  )

  return (
    <Container>
      <GridContainer>
        <LogosWrapper
          role={'region'}
          aria-label={t('common.accessibilityText.region.header')}
        >
          <VeerWrapper>
            {
              <Icon
                name={'veer'}
                settings={
                  isDesktop
                    ? { width: '127px', height: '53px', fill: colors.veerLogo }
                    : { width: '88px', height: '37px', fill: colors.veerLogo }
                }
                ariaLabel={t('common.accessibilityText.veerLogo')}
              />
            }
          </VeerWrapper>
          {isSignInView && <Separator />}
          {isSignInView && (
            <CompanyLogo
              data-testid={dataAttrs.logo()}
              src={customerConfig.logo}
              alt={t('common.accessibilityText.companyLogo', {
                companyName: customer
              })}
            />
          )}
        </LogosWrapper>
        <Title data-testid={dataAttrs.title()}>
          {t(`welcomePage.${currentStage}.title`)}
        </Title>
        <Description data-testid={dataAttrs.description()}>
          {t(`welcomePage.${currentStage}.description`, descriptionProps)}
        </Description>
        <SignInContext.Provider value={contextValue}>
          <ContentWrapper>{view}</ContentWrapper>
        </SignInContext.Provider>
      </GridContainer>
      {isSignInView && (
        <TermsLink onClick={onTerms}>{t('welcomePage.termsLink')}</TermsLink>
      )}
      {isSignInView && (
        <PrivacyLink onClick={onPrivacyPolicy}>
          {t('welcomePage.privacyLink')}
        </PrivacyLink>
      )}
    </Container>
  )
})

WelcomePage.displayName = 'WelcomePage'

export default WelcomePage
