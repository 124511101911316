import React from 'react'
import styled, { css } from 'styled-components'
import { Checkbox } from 'src/UIKit'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  translationKey: string
  onChange: (value: boolean) => void
  confirmed: boolean
  fontSize?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.span<{
  fontSize: string
}>`
  margin: 0 0 0 16px;
  font-weight: normal;
  color: ${props => props.theme.colors.dark60};
  cursor: pointer;
  user-select: none;

  ${props =>
    props.theme.isDesktop
      ? css`
          line-height: 150%;
          font-size: ${props.fontSize || '18px'};
          align-self: center;
        `
      : css`
          font-size: 14px;
          align-self: center;
        `}
`

const CheckboxConfirmationView = React.memo((props: IProps) => {
  const { className, onChange, translationKey, confirmed, fontSize } = props
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <Checkbox
        name="checkbox"
        checked={confirmed}
        onChange={onChange}
        ariaLabel={t(translationKey)}
      />
      <Text
        onClick={() => {
          onChange(!confirmed)
        }}
        fontSize={fontSize}
        aria-hidden
      >
        {t(translationKey)}
      </Text>
    </Container>
  )
})

CheckboxConfirmationView.displayName = 'CheckboxConfirmationView'

export default CheckboxConfirmationView
