import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
}

const ArrowWrapper = styled(Icon)`
  grid-area: arrow;
  align-self: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 24px;
        `
      : css`
          margin-left: 8px;
          margin-right: 12px;
        `}
`

const JourneyMapListItemArrow = React.memo((props: IProps) => {
  const { className } = props

  return <ArrowWrapper className={className} name={'chevron_right'} />
})

JourneyMapListItemArrow.displayName = 'JourneyMapListItemArrow'

export default JourneyMapListItemArrow
