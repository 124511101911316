import React, { ReactNode, useContext, useMemo, useState } from 'react'
import Context, {
  ICreateLeaveContext,
  MilitaryTraining
} from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import NormalRadioButtonOption from '../NormalRadioButtonOption'
import OptionContainer from 'src/UIKit/OptionContainer'

const translationKey = 'createLeave.military.training'

export const MilitaryDutyOrTrainingView = React.memo(() => {
  const { prevCreateLeaveStage, nextCreateLeaveStage } =
    useContext<ICreateLeaveContext>(Context)
  const { t } = useTranslation()

  const [trainingState, setTrainingState] = useState<MilitaryTraining>('none')

  const options: ReactNode[] = useMemo(
    () => [
      <NormalRadioButtonOption
        key={'1'}
        selected={trainingState === 'activeDuty'}
        onClick={() => {
          setTrainingState('activeDuty')
        }}
      >
        {t(`${translationKey}.options.activeDuty`)}
      </NormalRadioButtonOption>,
      <NormalRadioButtonOption
        key={'2'}
        selected={trainingState === 'adHoc'}
        onClick={() => {
          setTrainingState('adHoc')
        }}
        hidesSeparator
      >
        {t(`${translationKey}.options.adHoc`)}
      </NormalRadioButtonOption>
    ],
    [t, trainingState, setTrainingState]
  )

  const content: any = useMemo(
    () => (
      <>
        <OptionContainer>{options}</OptionContainer>
        <CreateLeaveNote
          text={t(`${translationKey}.adHocNote`)}
          shows={trainingState === 'adHoc'}
          marginTop={40}
        />
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={trainingState !== 'activeDuty'}
        />
      </>
    ),
    [prevCreateLeaveStage, nextCreateLeaveStage, t, trainingState, options]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{t(`createLeave.military.title`)}</CreateLeaveTitle>
      <CreateLeaveDescription>
        {t(`${translationKey}.description`)}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

MilitaryDutyOrTrainingView.displayName = 'MilitaryDutyOrTrainingView'

export default MilitaryDutyOrTrainingView
