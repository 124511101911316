import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import moment, { Moment } from 'moment'
import { DATE_FORMAT_MM_SS } from 'src/utils/dateUtils'
import useForceUpdate from 'src/components/hooks/useForceUpdate'

interface IProps {
  name: string
  expiresAt: Moment
  onExpired: () => void
}

const Note = styled(ReactMarkdown)`
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.dark60};
`

let forceUpdateTimeoutId: number

const SpamNote = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const forceUpdate = useForceUpdate()

  const { name, expiresAt, onExpired } = props
  const secondsLeft = expiresAt.diff(moment(), 'seconds')

  useEffect(() => {
    clearTimeout(forceUpdateTimeoutId)

    forceUpdateTimeoutId = window.setTimeout(() => {
      forceUpdate()
    }, 1000)

    if (secondsLeft <= 0) {
      onExpired()
    }

    return () => {
      clearTimeout(forceUpdateTimeoutId)
    }
  })

  return (
    <Note>
      {t(`welcomePage.${name}.checkSpamNote`, {
        time: moment.utc(secondsLeft * 1000).format(DATE_FORMAT_MM_SS)
      })}
    </Note>
  )
})
SpamNote.displayName = 'SpamNote'

export default SpamNote
