import React, { useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { IIconName } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import useHovered from 'src/components/hooks/useHovered'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  title: string
  iconName: IIconName
  onClick: () => void
  expanded?: boolean
  transitionDuration: number
}

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 4px;
      border-radius: 8px;
      border: 2px solid transparent;

      &:focus {
        outline: none;
        border: 2px solid ${colors.main60};
      }
    `}
`

const IconWrapper = styled(Icon)<any>`
  margin-left: 8px;
  width: 24px;
  height: 24px;
`

const Text = styled.span<{ $hovered: boolean }>`
  font-family: Roboto, serif;
  font-size: 14px;
  user-select: none;
  margin-left: 8px;
  padding: 3.5px 0;

  ${props =>
    !props.theme.isDesktop &&
    css`
      &:focus {
        outline: none;
      }
    `}

  ${props => {
    const {
      $hovered,
      theme: { isDesktop }
    } = props
    if (isDesktop) {
      return css`
        color: ${$hovered ? colors.dark100 : colors.dark80};
        font-weight: 400;
      `
    } else {
      return css`
        color: ${colors.dark60};
      `
    }
  }}
`

const ArrowWrapper = styled.div<{
  $transitionDuration: number
  $rotation: number
}>`
  cursor: pointer;
  transition: transform ${props => props.$transitionDuration}ms;
  transform: rotateZ(${props => props.$rotation}deg);

  &:focus {
    outline: none;
  }
`

const AccordionRootItem = React.memo((props: IProps) => {
  const { title, iconName, onClick, expanded, transitionDuration } = props
  const containerRef: any = useRef(null)
  const hovered: boolean = useHovered(containerRef)

  const iconColor: string = useMemo(
    () => (hovered ? colors.dark50 : colors.dark20),
    [hovered]
  )

  return (
    <Container onClick={onClick} ref={containerRef}>
      <InnerContainer aria-label={title} aria-expanded={expanded}>
        <ArrowWrapper
          $rotation={expanded ? 90 : 0}
          $transitionDuration={transitionDuration}
        >
          <Icon name="arrow_expand" />
        </ArrowWrapper>
        <IconWrapper
          name={iconName}
          settings={{ fill: iconColor }}
          hovered={hovered}
          aria-hidden
        />
        <Text $hovered={hovered}>{title}</Text>
      </InnerContainer>
    </Container>
  )
})

AccordionRootItem.displayName = 'AccordionRootItem'

export default AccordionRootItem
