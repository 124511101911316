import React, { useContext, useMemo, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import LeaveHeading from 'src/components/LeaveHeading'
import ManagerPreviousVersionsContext from 'src/features/ManagerJourneyMap/PreviousVersionsContext'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'
import PopoverArrow from 'src/components/Popover/components/Arrow'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);

  ${props =>
    props.theme.isDesktop
      ? css`
          background: ${props.theme.colors.light95};
          position: absolute;
          top: 0;
          left: 0;
          z-index: ${zIndex.journeyMap.header};
          height: 80px;
        `
      : css`
          background: ${props.theme.colors.light100};
          height: 51px;
        `}
`

const LeaveHeadingWrapper = styled(LeaveHeading)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px 0 20px;
        `
      : css`
          font-weight: 400;
          font-size: 16px;
          margin: 0 16px;
        `}
`

const Button = styled.button`
  margin-left: 20px;
  border-radius: 24px;
  outline-offset: 0 !important;

  ${props =>
    props.theme.isDesktop &&
    css`
      &:hover {
        background: ${props.theme.colors.main20};
      }

      padding: 14px;
    `}
`

const IconWrapper = styled(Icon)`
  width: 16px;
  height: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ShowDatesButton = styled.button`
  display: flex;
  margin: 0 20px;
`
const ShowDatesButtonText = styled.span`
  font-family: Roboto, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  white-space: nowrap;
  color: ${props => props.theme.colors.main100};
`

export const dataAttrs = {
  component: () => 'previous-versions-header'
}

export const PreviousVersionsHeader = React.memo(() => {
  const { showDates, setShowDates, clickBack, currentAbsence } = useContext(
    ManagerPreviousVersionsContext
  )
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const title: string = useMemo(() => {
    const employee = currentAbsence?.employee

    if (!employee?.name) {
      return null
    }

    return isDesktop
      ? t('manager.previousVersions.header', { name: employee.name })
      : employee.name
  }, [currentAbsence, t, isDesktop])

  const actionButtonView: ReactNode = useMemo(
    () =>
      !isDesktop ? (
        <ShowDatesButton onClick={() => setShowDates(!showDates)}>
          <ShowDatesButtonText>
            {t('manager.previousVersions.viewButton')}
          </ShowDatesButtonText>
          <PopoverArrow isOpen={false} />
        </ShowDatesButton>
      ) : null,
    [isDesktop, t, setShowDates, showDates]
  )

  if (showDates) {
    return null
  }

  return (
    <Container data-testid={dataAttrs.component()}>
      <Button onClick={clickBack}>
        <IconWrapper name="arrow_prev" />
      </Button>
      <TitleContainer>
        <LeaveHeadingWrapper>{title}</LeaveHeadingWrapper>
        {actionButtonView}
      </TitleContainer>
    </Container>
  )
})

PreviousVersionsHeader.displayName = 'PreviousVersionsHeader'

export default PreviousVersionsHeader
