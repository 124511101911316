import React, { ReactNode, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/UIKit'

interface IProps {
  className?: string
  hasChanges: boolean
  isConfirmDisabled: boolean
  isCancelDisabled?: boolean
  onCancel: () => void
  onClose: () => void
  onConfirm: () => void
  onRemove?: () => void
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100%;
          margin: 20px 0 0;
        `
      : css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 48px;
          padding: 16px;
          background: rgb(255 255 255 / 95%);
          justify-content: center;
          align-items: center;
        `}
`

const buttonMixin = css`
  flex: 1;
  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 16px;
      height: 48px;
    `}
`

const CancelButton = styled(Button)`
  ${buttonMixin}
`

const ConfirmButton = styled(Button)`
  ${buttonMixin}
`

const RemoveButton = styled(Button)`
  ${buttonMixin}
`

export const dataAttrs = {
  removeButton: () => 'remove-btn',
  cancelButton: () => 'cancel-btn',
  confirmButton: () => 'confirm-btn'
}

const Buttons = React.memo((props: IProps) => {
  const {
    hasChanges,
    isConfirmDisabled,
    isCancelDisabled,
    onCancel,
    onClose,
    onConfirm,
    onRemove
  } = props

  const { t } = useTranslation()

  const onCancelClicked = useCallback(() => {
    if (hasChanges) {
      onCancel()
    } else {
      onClose()
    }
  }, [hasChanges, onCancel, onClose])

  const cancelButton = useMemo(
    () => (
      <CancelButton
        data-testid={dataAttrs.cancelButton()}
        appearance={'cancel'}
        onClick={onCancelClicked}
        disabled={isCancelDisabled}
      >
        {t(hasChanges ? 'common.cancel' : 'common.close')}
      </CancelButton>
    ),
    [t, onCancelClicked, hasChanges, isCancelDisabled]
  )

  const confirmButton: ReactNode = useMemo(
    () =>
      hasChanges && (
        <ConfirmButton
          data-testid={dataAttrs.confirmButton()}
          onClick={onConfirm}
          disabled={isConfirmDisabled}
        >
          {t('common.confirm')}
        </ConfirmButton>
      ),
    [t, onConfirm, isConfirmDisabled, hasChanges]
  )

  const removeButton: ReactNode = useMemo(
    () =>
      !!onRemove && (
        <RemoveButton
          data-testid={dataAttrs.removeButton()}
          appearance={'destructiveBordered'}
          onClick={onRemove}
        >
          {t('common.remove')}
        </RemoveButton>
      ),
    [t, onRemove]
  )

  return (
    <ButtonsContainer>
      {removeButton}
      {cancelButton}
      {confirmButton}
    </ButtonsContainer>
  )
})

Buttons.displayName = 'TimelineLeaveDurationPickerButtons'

export default Buttons
