import React from 'react'
import styled, { css } from 'styled-components'
import AccessibilityInvisibleText from 'src/components/AccessibilityInvisibleText'

interface IProps {
  children: string
  responseRequired: boolean
}

const Text = styled.span<{ $responseRequired: boolean }>`
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  color: ${props => props.theme.colors.dark80};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 24px;
        `
      : css`
          font-size: 18px;
        `}

  ${props =>
    props.$responseRequired &&
    css`
      &::after {
        content: ' *';
        color: ${props.theme.colors.error80};
      }
    `}
`

const SurveyQuestionViewTitle = React.memo((props: IProps) => {
  const { children, responseRequired } = props

  return (
    <>
      <AccessibilityInvisibleText role={'status'}>
        {children}
      </AccessibilityInvisibleText>
      <Text $responseRequired={responseRequired} aria-hidden>
        {children}
      </Text>
    </>
  )
})

SurveyQuestionViewTitle.displayName = 'SurveyQuestionViewTitle'

export default SurveyQuestionViewTitle
