import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { IMomentCurrentMinMax } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  className?: string
  title: string
  momentCurrentMinMax: IMomentCurrentMinMax
  datePlaceholder?: string
  hovered: boolean
  disabled: boolean
  fontWeight?: string
}

const Container = styled.button<any>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;

  &:focus {
    outline: none;
    font-weight: ${props => props.fontWeight};
  }
`

const Title = styled.span`
  color: ${props => props.theme.colors.dark60};
  text-align: left;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
          font-weight: normal;
        `}
`

const RightContainer = styled.div`
  margin-left: 16px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const IconWrapper = styled(Icon)`
  margin: 2px 0 0 12px;
`

export const Date = styled.span<{ $hovered?: boolean }>`
  transition: color 200ms ease;
  color: ${props => props.theme.colors.main100};
  white-space: nowrap;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}

  ${props =>
    props.$hovered &&
    css`
      color: ${props.theme.colors.main110};
    `}
`

const DatePickerSingleLineView = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      className,
      title,
      momentCurrentMinMax,
      datePlaceholder,
      hovered,
      disabled,
      fontWeight
    } = props
    const { t } = useTranslation()

    const titleView = useMemo(() => <Title>{title}</Title>, [title])

    const dateView = useMemo(() => {
      const text: string = momentCurrentMinMax.current
        ? momentCurrentMinMax.current.utc().format(DATE_FORMAT)
        : datePlaceholder
      return <Date $hovered={hovered}>{text}</Date>
    }, [momentCurrentMinMax, datePlaceholder, hovered])

    return (
      <Container
        className={className}
        tabIndex={disabled ? -1 : 0}
        ref={ref}
        fontWeight={fontWeight || 'bold'}
      >
        {titleView}
        <RightContainer>
          {dateView}
          <IconWrapper
            name={'calendar'}
            settings={{ width: '18', height: '20' }}
            ariaLabel={t('common.accessibilityText.calendar')}
          />
        </RightContainer>
      </Container>
    )
  })
)

DatePickerSingleLineView.displayName = 'DatePickerSingleLineView'

export default DatePickerSingleLineView
