import React, { ReactNode, useContext, useMemo } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import { workDays } from 'src/utils/dateUtils'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  activeDays: string[]
}

const Container = styled.div`
  display: flex;
`

const DayView = styled.div`
  margin-left: 8px;

  &:not(:first-child) {
    margin-left: 2px;
  }
`

const ActiveDaysView = React.memo(
  (props: IProps) => {
    const { isMobile } = useContext(ScreenContext)
    const { activeDays } = props
    const days: Set<string> = useMemo(() => new Set(activeDays), [activeDays])

    const dayViews: ReactNode[] = useMemo(
      () =>
        workDays.map((day: string) => {
          const extra: any = {
            mobile: isMobile,
            active: days?.has(day),
            day: day.toLowerCase().charAt(0)
          }
          return (
            <DayView key={day}>
              <Icon name={'active_day'} settings={{ extra }} />
            </DayView>
          )
        }),
      [days, isMobile]
    )

    return <Container>{dayViews}</Container>
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

ActiveDaysView.displayName = 'ActiveDaysView'

export default ActiveDaysView
