import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Text = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.dark60};
`

const DescriptionText = React.memo((props: IProps) => (
  <Text className={props.className}>{props.children}</Text>
))

DescriptionText.displayName = 'DescriptionText'

export default DescriptionText
