import { useQuery } from '@apollo/client'
import { querySurvey } from 'src/graphql/queries'
import { useState } from 'react'
import { getSurveyWithSortedQuestions } from 'src/utils/surveys'

export interface IUseSurvey {
  loading: boolean
  error: any
  survey: ISurvey
}

const useSurvey = (id: string): IUseSurvey => {
  const [survey, setSurvey] = useState(null)

  const returnValue: IUseSurvey = {
    loading: false,
    error: null,
    survey
  }

  const { loading, error, data } = useQuery(querySurvey, {
    fetchPolicy: 'cache-first',
    variables: { id },
    skip: !!survey
  })

  if (loading) {
    returnValue.loading = true
    return returnValue
  }

  if (error) {
    returnValue.error = error
    return returnValue
  }

  if (data) {
    const s: ISurvey = getSurveyWithSortedQuestions(data.survey)
    returnValue.survey = s
    setSurvey(s)
  }

  return returnValue
}

export default useSurvey
