import React from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import Toggle from 'src/components/Toggle'
import { MarkdownText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'

const { colors } = createTheme()

interface IProps {
  className?: any
  syncing: boolean
  changedAt: Moment
  toggleTpaSyncing: () => void
  showLastUpdate: boolean
}

const Container = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          height: 64px;
          width: 640px;
          background: ${props.theme.colors.background};
          border: 1px solid ${props.theme.colors.dark05};
          border-radius: 8px;
        `
      : css`
          width: 100%;
          background: none;
          border-bottom: 1px solid ${props.theme.colors.main20};
          border-radius: 0;
          border: none;
        `}
  display: flex;
  align-items: center;
`

const TextsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 130%;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 24px;
        `
      : css`
          height: calc(100% - 30px);
        `}
`

const Title = styled(MarkdownText)<{ $bold: boolean }>`
  color: ${colors.dark60};
  line-height: 100%;
  font-weight: ${props => (props.$bold ? 500 : 400)};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const NoteText = styled(MarkdownText)`
  color: ${colors.dark60};
  line-height: 100%;
  font-weight: 400;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 5px;
          font-size: 14px;
        `
      : css`
          margin-top: 4px;
          font-size: 12px;
        `}
`

const ToggleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 24px;
        `
      : css`
          margin: 0;
        `}
`

const ClaimBlock = React.memo((props: IProps) => {
  const { className, syncing, changedAt, toggleTpaSyncing, showLastUpdate } =
    props
  const { t } = useTranslation()
  const date = changedAt.format(DATE_FORMAT)

  return (
    <Container
      className={className}
      role={'group'}
      aria-label={t('common.accessibilityText.claimBlock', { syncing, date })}
    >
      <TextsContainer>
        <Title $bold={showLastUpdate}>{t('claimData.block.title')}</Title>
        {showLastUpdate && (
          <NoteText>{t('claimData.block.note', { date })}</NoteText>
        )}
      </TextsContainer>
      <ToggleContainer>
        <Toggle toggled={syncing} onClick={toggleTpaSyncing} />
      </ToggleContainer>
    </Container>
  )
})

ClaimBlock.displayName = 'ClaimBlock'

export default ClaimBlock
