import React from 'react'
import ReactMarkdown from 'react-markdown'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: string
  margin?: string
}

const Text = styled(ReactMarkdown)<{ $margin: string }>`
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.dark60};
  margin: ${props => props.$margin};
  width: 100%;

  ul {
    padding-left: 22px;
    white-space: normal;
    list-style: disc outside;
  }

  em {
    font-style: italic;
  }

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
      outline-offset: 1px;
    }
  }
`

const DescriptionText = React.memo((props: IProps) => (
  <Text
    className={props.className}
    $margin={props.margin}
    urlTransform={TelUrlTransformer}
    components={{
      a: LinkRenderer
    }}
  >
    {props.children}
  </Text>
))

DescriptionText.displayName = 'DescriptionText'

export default DescriptionText
