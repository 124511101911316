import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import HrAdminCard from 'src/features/HrAdmin/components/Card'
import Layout from 'src/features/HrAdmin/components/Layout'
import useSurveySummaries, {
  ISurveyResults,
  ISurveySummary
} from 'src/graphql/hooks/useSurveySummaries'
import HrAdminCardHeader from 'src/features/HrAdmin/components/Card/Header'
import SurveyMultipleChoiceResults from 'src/features/HrAdmin/components/SurveyMultipleChoiceResults'
import { MarkdownText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import SurveyNpsResults from 'src/features/HrAdmin/components/SurveyNpsResults'
import LoadingSpinner from 'src/components/LoadingSpinner'
import ScreenContext from 'src/contexts/ScreenContext'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'

const TitleDesktop = styled.span`
  color: ${props => props.theme.colors.dark60};
  font-weight: 500;
  display: block;
  font-size: 24px;
  line-height: 100%;
  margin: 8px 0 8px 8px;

  &:first-child {
    margin-top: 24px;
  }
`

const TitleMobile = styled.span`
  color: ${props => props.theme.colors.dark60};
  font-weight: 400;
  display: block;
  font-size: 18px;
  line-height: 130%;
`

const TypeMobile = styled.span`
  color: ${props => props.theme.colors.dark60};
  display: block;
  font-size: 12px;
  line-height: 130%;
`

const MobileTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px;

  &:first-child {
    margin-top: 24px;
  }
`

const HrAdminCardHeaderWrapper = styled(HrAdminCardHeader)`
  &:first-child {
    margin: 0 0 16px;
  }
`

const SurveyContainer = styled.div`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100vw - 32px);
        `}
`

const LabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled(MarkdownText)`
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;

          strong {
            font-weight: 500;
          }
        `
      : css`
          font-size: 12px;
        `}
`

const LabelMobileSmall = styled(MarkdownText)`
  line-height: 130%;
  font-size: 10px;
  color: ${props => props.theme.colors.dark60};
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
`

const NoRecordsText = styled.span`
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 12px;
        `}
`

export const SurveysSection = React.memo(() => {
  const {
    selectedCountryCode,
    selectedSurveySubItemIndex,
    surveysLeaveGroups,
    surveysSubItemTitles
  }: IHrAdminContext = useContext(HrAdminContext)
  const { surveySummaries, loading } = useSurveySummaries(
    surveysLeaveGroups[selectedSurveySubItemIndex],
    selectedCountryCode
  )
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const surveyResultsView: ReactNode = useMemo(() => {
    if (!surveySummaries) {
      return []
    }

    if (loading) {
      return (
        <SpinnerContainer>
          <LoadingSpinner fadesIn />
        </SpinnerContainer>
      )
    }

    return surveySummaries.map((surveySummary: ISurveySummary) => (
      <React.Fragment key={surveySummary.survey.id}>
        {isDesktop ? (
          <TitleDesktop>{surveySummary.survey.headline}</TitleDesktop>
        ) : (
          <MobileTitleContainer>
            <TitleMobile>{surveySummary.survey.headline}</TitleMobile>
            <TypeMobile>
              {surveysSubItemTitles[selectedSurveySubItemIndex]}
            </TypeMobile>
          </MobileTitleContainer>
        )}
        <Layout minBlockWidth={isDesktop ? 600 : 300}>
          <HrAdminCard>
            <SurveyContainer>
              {surveySummary.results.length === 0 && (
                <NoRecordsText>{t('hrAdmin.noRecords')}</NoRecordsText>
              )}
              {surveySummary.results.map((result: ISurveyResults) => (
                <React.Fragment key={result.text}>
                  <HrAdminCardHeaderWrapper
                    title={result.description}
                    description={result.text}
                    rightLabel={t('hrAdmin.allRecords')}
                  />
                  {result.chart === 'Columns' ? (
                    <SurveyMultipleChoiceResults
                      data={result.responses}
                      isDesktop={isDesktop}
                    />
                  ) : (
                    <SurveyNpsResults
                      data={result.responses}
                      npsScaleFields={result.npsScaleFields}
                      npsScore={result.npsScore}
                      isDesktop={isDesktop}
                    />
                  )}
                  <LabelsContainer>
                    <Label>{`${t('hrAdmin.totalResponses')} **${
                      result.totalResponsesCount
                    }**`}</Label>
                    {result.chart === 'NetScore' &&
                      (isDesktop ? (
                        <Label>{t('hrAdmin.npsScoreFormula')}</Label>
                      ) : (
                        <LabelMobileSmall>
                          {t('hrAdmin.npsScoreFormula')}
                        </LabelMobileSmall>
                      ))}
                  </LabelsContainer>
                </React.Fragment>
              ))}
            </SurveyContainer>
          </HrAdminCard>
        </Layout>
      </React.Fragment>
    ))
  }, [
    surveySummaries,
    t,
    loading,
    isDesktop,
    surveysSubItemTitles,
    selectedSurveySubItemIndex
  ])

  return <React.Fragment>{surveyResultsView}</React.Fragment>
})

SurveysSection.displayName = 'SurveysSection'

export default SurveysSection
