import React from 'react'
import styled from 'styled-components'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
  color: string
}

const IconWrapper = styled(Icon)`
  width: 16px;
  height: 24px;

  @media print {
    width: 10px;
    height: 15px;
  }
`

const BookmarkView = React.memo((props: IProps) => {
  const { className, color } = props
  return (
    <IconWrapper
      className={className}
      name={'bookmark'}
      settings={{ fill: color, width: '100%', height: '100%' }}
    />
  )
})

BookmarkView.displayName = 'BookmarkView'

export default BookmarkView
