import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { Icon } from 'src/UIKit'
import useFocused from 'src/components/hooks/useFocused'
import useHovered from 'src/components/hooks/useHovered'
import { buttonWrapperAppearanceMixin } from 'src/theme/utils'

const { colors } = createTheme()

interface IProps {
  className?: string
  checked: boolean
  children: any
  disabled?: boolean
  appearance: string
  onClick: () => void
}

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
`

const CheckboxContainer = styled.div<{ $focused: boolean; $hovered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 16px;
  width: 16px;
  min-width: 16px;
  outline-offset: -3px;
  border: 1px solid ${props => props.theme.colors.main100};

  ${props =>
    props.$hovered &&
    css`
      border: 1px solid ${props.theme.colors.main110};
    `}

  ${props =>
    props.$focused &&
    css`
      border: 2px solid ${props.theme.colors.main110};
    `}
`

const CheckmarkWrapper = styled(Icon)`
  display: inline-block;
  padding: 0 0 4px 1px;
`

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
`

const Wrapper = styled.button<any>`
  cursor: pointer;
  outline: none;
  user-select: none;
  display: inline-block;
  width: auto;
  min-height: 40px;
  padding: 10px 12px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: start;

  ${buttonWrapperAppearanceMixin}
`

export const dataAttrs = {
  button: () => 'checkbox-checkmark-button'
}

const CheckboxButton = React.memo((props: IProps) => {
  const { children, className, onClick, checked, disabled, appearance } = props
  const ref: any = useRef(null)
  const focused: boolean = useFocused(ref)
  const hovered: boolean = useHovered(ref)
  const [isChecked, setIsChecked] = useState(checked)

  const content = (
    <Content>
      <CheckboxContainer $focused={focused} $hovered={hovered}>
        {isChecked && (
          <CheckmarkWrapper
            name={'checkbox_checkmark'}
            settings={{
              fill: colors.main100,
              width: '8.17',
              height: '7'
            }}
          />
        )}
      </CheckboxContainer>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Content>
  )

  return (
    <Wrapper
      data-testid={dataAttrs.button()}
      ref={ref}
      className={className}
      disabled={disabled}
      $disabled={disabled}
      $appearance={appearance || 'default'}
      onClick={() => {
        onClick()
        setIsChecked(!isChecked)
      }}
    >
      {content}
    </Wrapper>
  )
})

CheckboxButton.displayName = 'CheckboxButton'

export default CheckboxButton
