import React, { useContext } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveView from '../UI/CreateLeaveView'

export const CongratsView = React.memo(() => {
  const {
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    selectedLeaveType,
    countryState
  } = useContext<ICreateLeaveContext>(Context)

  return (
    <CreateLeaveView
      name={'congrats'}
      isNextDisabled={false}
      onBackClicked={prevCreateLeaveStage}
      onNextClicked={nextCreateLeaveStage}
      leaveType={selectedLeaveType?.type}
      countryState={countryState}
    />
  )
})

CongratsView.displayName = 'CongratsView'

export default CongratsView
