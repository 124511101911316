import { useEffect } from 'react'

export default (element: any) => {
  useEffect(() => {
    if (element) {
      try {
        element.focus()
      } catch (_) {
        return undefined
      }
    }
  }, [element])
}
