import React, { ReactNode, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import NumStepperButton from 'src/components/NumStepper/components/NumStepperButton'

interface IProps {
  className?: string
  value: number
  maxValue: number
  minValue: number
  onValueChanged: (value: number) => void
  disabled?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ValueText = styled.span`
  min-width: 36px;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  user-select: none;
  color: ${props => props.theme.colors.dark80};
`

const NumStepper = React.memo((props: IProps) => {
  const { className, maxValue, minValue, onValueChanged, value, disabled } =
    props

  const isDecrementDisabled: boolean = useMemo(
    () => disabled || value <= minValue,
    [value, minValue, disabled]
  )

  const isIncrementDisabled: boolean = useMemo(
    () => disabled || value >= maxValue,
    [value, maxValue, disabled]
  )

  const onIncrement = useCallback(() => {
    if (disabled || isIncrementDisabled) {
      return
    }

    const newValue: number = value + 1
    onValueChanged(newValue)
  }, [value, onValueChanged, isIncrementDisabled, disabled])

  const onDecrement = useCallback(() => {
    if (disabled || isDecrementDisabled) {
      return
    }

    const newValue: number = value - 1
    onValueChanged(newValue)
  }, [value, onValueChanged, isDecrementDisabled, disabled])

  const decrementButton: ReactNode = useMemo(
    () => (
      <NumStepperButton
        onClick={onDecrement}
        disabled={isDecrementDisabled}
        isIncrement={false}
      />
    ),
    [isDecrementDisabled, onDecrement]
  )

  const incrementButton: ReactNode = useMemo(
    () => (
      <NumStepperButton
        onClick={onIncrement}
        disabled={isIncrementDisabled}
        isIncrement
      />
    ),
    [isIncrementDisabled, onIncrement]
  )

  return (
    <Container className={className}>
      {decrementButton}
      <ValueText>{value}</ValueText>
      {incrementButton}
    </Container>
  )
})

NumStepper.displayName = 'NumStepper'

export default NumStepper
