import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
  children: string
}

const Heading = styled.h1`
  font-weight: 500;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 28px;
        `
      : css`
          font-size: 20px;
        `}
`

const LeaveHeading = React.memo((props: IProps) => {
  const { children, className } = props
  const { t } = useTranslation()

  return (
    <Heading
      className={className}
      aria-label={t('timeline.accessibility.title', { type: children })}
    >
      {children}
    </Heading>
  )
})

LeaveHeading.displayName = 'LeaveHeading'

export default LeaveHeading
