import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'

interface IProps {
  periodType: string
  isDurationApproved: boolean
  setIsDurationApproved: (value: boolean) => void
}

const RemoveDialogBody = styled.p`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  width: 100%;
  margin-bottom: ${props => (props.theme.isDesktop ? '20px' : '12px')};
`

const RemoveDialogContent = React.memo((props: IProps) => {
  const { periodType, isDurationApproved, setIsDurationApproved } = props
  const { t } = useTranslation()

  return (
    <div>
      <RemoveDialogBody>
        {t(`timeline.removeDialog.${periodType}.body`)}
      </RemoveDialogBody>
      <CheckboxConfirmationView
        confirmed={isDurationApproved}
        translationKey={'timeline.removeDialog.checkBoxText'}
        onChange={() => {
          setIsDurationApproved(!isDurationApproved)
        }}
        fontSize={'14px'}
      />
    </div>
  )
})

RemoveDialogContent.displayName = 'RemoveDialogContent'

export default RemoveDialogContent
