import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import styled, { css } from 'styled-components'
import { printDisplayNoneMixin } from 'src/theme/utils'
import TimelineContext from 'src/features/Timeline/Context'
import TimelineViewContext, {
  ITimelineViewContext
} from 'src/features/Timeline/components/vertical/TimelineView/TimelineViewContext'
import { useTranslation } from 'react-i18next'
import { TutorialElementsRegister } from 'src/features/Timeline/Tutorial'
import {
  hasAddableBabyBonding,
  isFirstPeriodWithType
} from 'src/utils/leaveUtils'
import { ITimelinePeriod, IIconName } from 'src/react-app-env'
import { getFooterButtons } from './methods'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
const { colors } = createTheme()

export interface IBlockFooterViewButton {
  iconName?: IIconName
  title: string
  onClick: () => void
  disabled?: boolean
  alignRight?: boolean
}

interface IProps {
  period: ITimelinePeriod
  hidesSeparator?: boolean
  selected: boolean
}

const Container = styled.div<{ $isNotice?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px 8px 0 0;

  ${props => {
    if (props.$isNotice) {
      return css`
        margin-top: 16px;
      `
    }
  }}

  ${props =>
    props.theme.isDesktop
      ? css`
          height: 42px;
        `
      : css`
          height: 32px;
        `};

  ${printDisplayNoneMixin}
`

export const ButtonsContainer = styled.div<{ $inverse?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;

  ${props =>
    props.$inverse &&
    css`
      flex-direction: row-reverse;
    `}
`

export const Separator = styled.div<{ $isNotice: boolean; $selected: boolean }>`
  ${props =>
    props.$isNotice && !props.$selected
      ? css`
          background: ${props.theme.colors.error10};
        `
      : css`
          background: ${props.theme.colors.dark05};
        `}
  height: 1px;
  width: 100%;
`

export const Button = styled.button<{
  $isGrey?: boolean
  $alignRight?: boolean
}>`
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          line-height: 16px;
          padding: 12px 0;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
          padding: 8px 0;
        `}

  cursor: pointer;
  color: ${props => props.theme.colors.main100};
  user-select: none;
  white-space: nowrap;
  display: flex;
  gap: 10px;

  ${(props: any) =>
    props.$alignRight &&
    css`
      text-align: right;
    `}

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    outline-offset: 0;
    text-decoration: underline;
  }

  ${(props: any) => {
    if (props.$isGrey) {
      return css`
        cursor: unset;
        color: ${props.theme.colors.dark20};
        text-decoration: none;

        &:hover {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }

        &:focus {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }
      `
    }
  }}
`

const ButtonIcon = styled(Icon)`
  margin-top: -1px;
`

const BlockFooterView = React.memo((props: IProps) => {
  const { leave } = useContext(TimelineContext)
  const {
    onDetailsClick,
    onDatePickerClick,
    selectedDatePickerPeriod,
    selectedDetailsPeriod
  }: ITimelineViewContext = useContext(TimelineViewContext)

  const [buttonRef1, setButtonRef1] = useState(null)
  const [buttonRef2, setButtonRef2] = useState(null)
  const [activeIconColor, setActiveIconColor] = useState<string>(colors.main100)

  const { t } = useTranslation()
  const { period, hidesSeparator, selected } = props
  const { type, hash, mode } = period
  const isAddablePreDelivery: boolean = useMemo(
    () =>
      leave.timeline.some(
        (tItem: ITimelinePeriodItem) =>
          tItem.type === 'PreDelivery' && tItem.appearance === 'Addable'
      ),
    [leave]
  )
  const isHasPaidLeavesPeriod: boolean = useMemo(
    () =>
      leave.timeline.some(
        (tItem: ITimelinePeriodItem) => tItem.type === 'PaidLeaves'
      ),
    [leave]
  )

  // TODO can it be shorter?
  useEffect(() => {
    if (leave.type === 'Pregnancy') {
      if (type === 'DueDate' && isAddablePreDelivery) {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
      }
      if (type === 'PreDelivery') {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }

      if (!leave.dates.disabilityEnd) {
        if (type === 'StartDate') {
          TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        }
        if (type === 'BabyBonding') {
          TutorialElementsRegister.getInstance().detailsElement = buttonRef2
        }
      }
    } else {
      if (!isHasPaidLeavesPeriod && type === 'Sabbatical') {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }
      if (type === 'PaidLeave') {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }
      if (type === 'DueDate' && hasAddableBabyBonding(leave)) {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
      }
      if (type === 'StartDate' && mode !== 'ReadOnly') {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
      }
      if (
        type === 'BabyBonding' &&
        isFirstPeriodWithType(hash) &&
        !hasAddableBabyBonding(leave)
      ) {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }
      if (type === 'BabyBonding' && hasAddableBabyBonding(leave)) {
        TutorialElementsRegister.getInstance().leaveDurationPickerElement =
          buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }
      if (
        type === 'Military' ||
        (type === 'PaidMedical' && isFirstPeriodWithType(hash)) ||
        type === 'PregnancyLossDate' ||
        (type === 'Personal' && leave.type !== 'Family') ||
        (type === 'FamilyCare' && isFirstPeriodWithType(hash)) ||
        (type === 'UnpaidPersonal' && !isHasPaidLeavesPeriod)
      ) {
        TutorialElementsRegister.getInstance().leaveDurationPickerElement =
          buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }

      if (
        (type === 'Personal' && mode === 'ReadOnly') ||
        type === 'Sickness' ||
        type === 'PaidLeaves'
      ) {
        TutorialElementsRegister.getInstance().detailsElement = buttonRef1
      }

      if (type === 'OrdinaryMaternity' && isFirstPeriodWithType(hash)) {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }

      if (type === 'OrdinaryAdoption' || type === 'Partner') {
        TutorialElementsRegister.getInstance().leaveStartElement = buttonRef1
        TutorialElementsRegister.getInstance().detailsElement = buttonRef2
      }
    }
  }, [
    leave,
    type,
    buttonRef1,
    buttonRef2,
    hash,
    isAddablePreDelivery,
    isHasPaidLeavesPeriod,
    mode
  ])

  const footerButtons = useMemo(
    () =>
      getFooterButtons({
        period,
        t,
        onDatePickerClick,
        onDetailsClick,
        selectedDetailsPeriod,
        selectedDatePickerPeriod
      }),
    [
      period,
      onDetailsClick,
      onDatePickerClick,
      selectedDetailsPeriod,
      selectedDatePickerPeriod,
      t
    ]
  )

  const onRefChange = useCallback((node: any, index: number) => {
    if (node) {
      if (index === 0) {
        setButtonRef1(node)
      } else {
        setButtonRef2(node)
      }
    }
  }, [])

  if (!footerButtons || footerButtons.length === 0 || !type) {
    return null
  }

  const isNotice: boolean = period.appearance === 'Notice'

  return (
    <Container className="background-view-footer" $isNotice={isNotice}>
      {!hidesSeparator && (
        <Separator $isNotice={isNotice} $selected={selected} />
      )}
      <ButtonsContainer
        $inverse={footerButtons?.length === 1 && footerButtons[0].alignRight}
      >
        {footerButtons.map((button: IBlockFooterViewButton, index: number) => (
          <Button
            onMouseEnter={() =>
              button.iconName && setActiveIconColor(colors.main110)
            }
            onMouseLeave={() =>
              button.iconName && setActiveIconColor(colors.main100)
            }
            onFocus={() =>
              button.iconName && setActiveIconColor(colors.main110)
            }
            onBlur={() => button.iconName && setActiveIconColor(colors.main100)}
            key={button.title}
            onClick={() => {
              if (button.iconName) {
                setActiveIconColor(colors.main100)
              }
              button.onClick()
            }}
            $isGrey={button.disabled}
            $alignRight={button.alignRight}
            tabIndex={button.disabled ? -1 : 0}
            ref={(newRef: any) => onRefChange(newRef, index)}
            disabled={button.disabled}
          >
            {button.iconName && (
              <ButtonIcon
                name={button.iconName as IIconName}
                settings={{
                  fill: button.disabled ? colors.dark20 : activeIconColor
                }}
              />
            )}
            {button.title}
          </Button>
        ))}
      </ButtonsContainer>
    </Container>
  )
})

BlockFooterView.displayName = 'BlockFooterView'

export default BlockFooterView
