import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloLink,
  HttpLink
} from '@apollo/client'
import { graphqlUri } from 'src/config'
import { csrf } from 'src/graphql/csrf'

const csrfLink = new ApolloLink((operation, forward) => {
  const headers = {
    'X-CSRF-Token': csrf.getToken(),
    'X-App-Version': process.env.REACT_APP_VERSION
  }
  operation.setContext({
    headers
  })
  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    Config: {
      keyFields: []
    },
    AnalyticsResponse: {
      merge: true
    }
  },
  dataIdFromObject: object => defaultDataIdFromObject(object)
})

const httpLink = new HttpLink({ uri: graphqlUri(), credentials: 'include' })
const aplClient = new ApolloClient({
  link: csrfLink.concat(httpLink),
  cache
})

export default () => aplClient
