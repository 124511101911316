import { IGraphqlQueries } from 'src/react-app-env'
import { useEffect, useState } from 'react'

export interface IUseAlerts {
  loading: boolean
  error: any
  alerts: IAlert[]
  closeAlert: (id: string) => Promise<void>
}

const useAlerts = (
  alertType: IAlertType,
  queries: IGraphqlQueries,
  fetchPolicy = 'network-only'
): IUseAlerts => {
  const [alerts, setAlerts] = useState<IAlert[]>([])
  const [resultError, setResultError] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const alertsResult: IAlert[] = await queries.fetchAlerts({
        fetchPolicy
      })
      const filteredAlerts: IAlert[] = alertsResult.filter(
        (a: IAlert) => a.type === alertType
      )
      setAlerts(filteredAlerts)
    } catch (error) {
      setResultError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeAlert = async (id: string) => {
    try {
      setLoading(true)
      await queries.closeAlert(id)
    } catch (error) {
      setResultError(error)
    } finally {
      setLoading(false)
    }
  }

  return { alerts, loading, error: resultError, closeAlert }
}

export default useAlerts
