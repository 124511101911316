import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isFirefox } from 'src/utils/deviceTypes'
import {
  getFilterPropsByJourneyMapItemType,
  getIconNameByJourneyMapItemType
} from 'src/utils/leaveUtils'
import { Icon } from 'src/UIKit'
import { journeyMapItem } from 'src/constants/frame'

interface IProps {
  className?: string
  priority: IPriority
  ariaHidden?: boolean
}

const Container = styled.div<any>`
  display: flex;
  align-items: center;

  /* stylelint-disable-next-line media-query-no-invalid */
  @media only screen and (max-width: ${journeyMapItem.mediaMaxWidth().px}) {
    margin: 0 0 4px;
  }
`

const textMixin = css`
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 12px;
        `}

  @media print {
    font-size: 14pt;
    ${isFirefox() &&
    css`
      font-size: 12px;
    `}
  }
`

const IconWrapper = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Value = styled.span<any>`
  ${textMixin};
  font-weight: 500;
`

const PriorityLabel = React.memo((props: IProps) => {
  const { className, priority, ariaHidden } = props
  const { t } = useTranslation()
  const icon = useMemo(
    () => (
      <IconWrapper
        name={getIconNameByJourneyMapItemType(priority)}
        settings={{ fill: getFilterPropsByJourneyMapItemType(priority).color }}
      />
    ),
    [priority]
  )
  const value: string = useMemo(
    () => t(`journeyMapPriorities.${priority}`).toLocaleLowerCase(),
    [priority, t]
  )
  const ariaLabel: string = useMemo(
    () => value + ' ' + t('common.priority').toLocaleLowerCase(),
    [value, t]
  )

  return (
    <Container
      className={className}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
    >
      {icon}
      <Value aria-hidden>{value}</Value>
    </Container>
  )
})

PriorityLabel.displayName = 'PriorityLabel'

export default PriorityLabel
