import React from 'react'
import { IPriorityFilter } from 'src/components/JourneyMap/Filters'
import FiltersPriority from 'src/components/JourneyMap/Filters/FiltersPriority'
import Stats from 'src/components/JourneyMap/Stats'
import { Separator } from 'src/components/JourneyMap/SettingsViewContainer'
import { useTranslation } from 'react-i18next'

interface IProps {
  filters: IPriorityFilter[]
  onFilterClick: (index: number) => void
  onResetFilters?: () => void
  totalItemsCount: number
  currentItemsCount: number
}

const DesktopContent = React.memo((props: IProps) => {
  const {
    filters,
    onFilterClick,
    onResetFilters,
    currentItemsCount,
    totalItemsCount
  } = props
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FiltersPriority
        onFilterClick={onFilterClick}
        filters={filters}
        title={t('common.filters.filterItemsByPriority', {
          context: 'manager'
        })}
      />
      <Separator />
      <Stats
        onResetFilters={
          onResetFilters
            ? () => {
                onResetFilters()
              }
            : null
        }
        currentItemsCount={currentItemsCount}
        totalItemsCount={totalItemsCount}
      />
    </React.Fragment>
  )
})

DesktopContent.displayName = 'DesktopContent'

export default DesktopContent
