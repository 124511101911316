import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
}

const Container = styled.div`
  display: flex;
  margin: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-area: list;
`

const BellWrapper = styled(Icon)`
  margin-bottom: 24px;
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
        `
      : css`
          font-size: 14px;
        `}
`

const NoRecordsView = React.memo((props: IProps) => {
  const { className } = props

  const { t } = useTranslation()

  return (
    <Container className={className} role="status">
      <BellWrapper
        name={'big_bell'}
        ariaLabel={t('common.accessibilityText.noNotifications')}
      />
      <Text>{t('common.youHaveNoNotificationsYet')}</Text>
    </Container>
  )
})

NoRecordsView.displayName = 'NoRecordsView'

export default NoRecordsView
