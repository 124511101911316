import React from 'react'
import { SignInStage } from './methods'
import { Moment } from 'moment'

export type SignInType = 'sso' | 'email'

export interface ISignInContext {
  signInType: SignInType
  setSignInType: (t: SignInType) => void

  personalEmail: string
  setPersonalEmail: (e: string) => void

  codeValidTill?: Moment
  setCodeValidTill: (d: Moment) => void

  onPrevStage: () => void
  onNextStage: () => void
  moveToStage: (s: SignInStage) => void
  resetWorkflow: () => void

  handleRequestOtp: (e: string) => Promise<Response>
  handleLoginOtp: (e: string, c: string) => Promise<Response>

  signing: boolean
  onSignInWithSso: () => void
}

export default React.createContext(null)
