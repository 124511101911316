import styled, { css } from 'styled-components'

const Title = styled.div<{ $showAsterisk?: boolean }>`
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  font-weight: 400;
  color: ${props => props.theme.colors.dark60};
  text-align: left;
  line-height: 129%;
  min-width: 30%;

  ${props =>
    props.$showAsterisk &&
    css`
      &::after {
        content: '*';
        color: ${props.theme.colors.dark60};
      }
    `}
`

export default Title
