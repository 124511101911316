import React, { useEffect, useMemo } from 'react'
import { Button, H1, MarkdownText } from 'src/UIKit'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import withRouter from 'src/components/hooks/useRouter'
import { useNavigate, useLocation } from 'react-router-dom'
import { timelineRoute } from 'src/routes/constants'
import { setCanBeShown } from 'src/utils/EligibilityNoticeManager'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.theme.isMobile &&
    css`
      padding: 0 16px;
      margin: 0 auto;
    `}

  ${mobileMaxWidthMixin};
`

const TextWrapper = styled(MarkdownText)`
  p {
    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
        `
      : css`
          width: 100%;
        `}
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}
`

const ButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

export const EligibilityNoticePage = React.memo(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { t } = useTranslation()

  usePageTitle('eligibilityNotice')

  const title: string = useMemo(
    () => (state && (state as any).title) || t('eligibilityNotice.page.title'),
    [state, t]
  )
  const notice: string = useMemo(() => state && (state as any).notice, [state])
  const button: any = useMemo(() => state && (state as any).button, [state])

  useEffect(() => {
    if (!notice) {
      navigate(timelineRoute)
    } else {
      setCanBeShown(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notice])

  return (
    <Container>
      <H1>{title}</H1>
      <TextWrapper allowsParagraph>{notice}</TextWrapper>
      <ButtonContainer>
        {button ? (
          <ButtonWrapper
            onClick={() => {
              navigate(timelineRoute)
            }}
            appearance={button.appearance}
          >
            {button.title}
          </ButtonWrapper>
        ) : (
          <ButtonWrapper
            onClick={() => {
              navigate(timelineRoute)
            }}
          >
            {t('eligibilityNotice.page.buttonTitle')}
          </ButtonWrapper>
        )}
      </ButtonContainer>
    </Container>
  )
})

EligibilityNoticePage.displayName = 'EligibilityNoticePage'

export default withRouter(EligibilityNoticePage)
