import React, { ReactNode, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Button, H1, MarkdownText } from 'src/UIKit'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import PregnancyLossContext, {
  IPregnancyLossContext
} from 'src/features/Timeline/components/common/PregnancyChangeContainer/context'

interface IProps {
  name: string
  onCancelClicked?: () => void
  onBackClicked?: () => void
  onNextClicked: () => void
  isNextDisabled: boolean
  children?: ReactNode
  nextButtonText?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          max-width: 736px;
          margin: 0 16px;
        `
      : css`
          margin: 0 auto;
          width: calc(100vw - 32px);
        `}
  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  max-width: 100%;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 48px;
        `
      : css`
          margin-top: 16px;
        `}
`

const Description = styled(MarkdownText)`
  width: 100%;
  white-space: pre-wrap;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 0 36px;
        `
      : css`
          margin: 0 0 16px;
        `}

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ButtonsContainer = styled.div<{ $marginTop?: string }>`
  display: flex;
  justify-content: center;
  gap: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: ${props.$marginTop || '32px'} 0 48px;
          max-width: 736px;
        `
      : css`
          flex: 1;
          align-items: flex-end;
          width: 100%;
          margin: 0 0 16px;
        `}

  ${mobileButtonMaxWidthMixin};
`

const ButtonWrapper = styled(Button)<any>`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
        `
      : css`
          width: 100%;
          height: 48px;
          font-size: 16px;
        `}
`

export const dataAttrs = {
  title: () => 'pregnancy-change-step-title',
  cancelBtn: () => 'pregnancy-change-cancel-btn',
  backBtn: () => 'pregnancy-change-back-btn',
  nextBtn: () => 'pregnancy-change-next-btn'
}

export const PregnancyChangeStepView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    name,
    children,
    onCancelClicked,
    onBackClicked,
    onNextClicked,
    isNextDisabled,
    nextButtonText
  } = props

  const { leave, expectedChildren }: IPregnancyLossContext =
    useContext(PregnancyLossContext)

  const nextButtonContent: string = nextButtonText || t('common.next')
  const leaveType = leave.type

  const content: any = useMemo(
    () => (
      <>
        {children}
        <ButtonsContainer $marginTop={children ? null : '0px'}>
          {!!onCancelClicked && (
            <ButtonWrapper
              data-testid={dataAttrs.cancelBtn()}
              appearance={'cancel'}
              onClick={onCancelClicked}
            >
              {t('common.cancel')}
            </ButtonWrapper>
          )}
          {!!onBackClicked && (
            <ButtonWrapper
              data-testid={dataAttrs.backBtn()}
              appearance={'cancel'}
              onClick={onBackClicked}
            >
              {t('common.back')}
            </ButtonWrapper>
          )}
          <ButtonWrapper
            data-testid={dataAttrs.nextBtn()}
            onClick={onNextClicked}
            disabled={isNextDisabled}
          >
            {nextButtonContent}
          </ButtonWrapper>
        </ButtonsContainer>
      </>
    ),
    [
      t,
      children,
      onCancelClicked,
      onBackClicked,
      onNextClicked,
      isNextDisabled,
      nextButtonContent
    ]
  )

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>
        {t([
          `pregnancyChangeFlow.pages.${name}.title_${leaveType}_${expectedChildren}`,
          `pregnancyChangeFlow.pages.${name}.title_${leaveType}`,
          `pregnancyChangeFlow.pages.${name}.title`
        ])}
      </Title>
      <Description
        allowsParagraph
        urlTransform={TelUrlTransformer}
        components={{
          a: LinkRenderer
        }}
      >
        {t([
          `pregnancyChangeFlow.pages.${name}.description_${leaveType}_${expectedChildren}`,
          `pregnancyChangeFlow.pages.${name}.description_${leaveType}`,
          `pregnancyChangeFlow.pages.${name}.description`
        ])}
      </Description>
      {content}
    </Container>
  )
})

PregnancyChangeStepView.displayName = 'PregnancyChangeStepView'

export default PregnancyChangeStepView
