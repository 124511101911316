import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import getDetailsType from '../getDetailsType'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  detailsType: getDetailsType(input)
})
