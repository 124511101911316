import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import withRouter from 'src/components/hooks/useRouter'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import redirectByUserRole from 'src/utils/redirectByUserRole'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import { useNavigate } from 'react-router-dom'

const { colors } = createTheme()

interface IProps {
  className?: any
}

export const Container = styled.div`
  cursor: pointer;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0;
        `
      : css`
          margin: 16px 8px 12px 16px;
          width: 47px;
          height: 20px;
        `}
`

export const VeerLogo = React.memo((props: IProps) => {
  const { className } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const sharedContext: ISharedContext = useContext(SharedContext)
  const userResult: IUseUser = useUser()

  return (
    <Container
      className={className}
      onClick={() => {
        redirectByUserRole(
          userResult.user,
          navigate,
          sharedContext.apolloClient
        )
      }}
    >
      <Icon
        name={'veer'}
        settings={
          isDesktop
            ? { fill: colors.veerLogo }
            : {
                width: '100%',
                height: '100%',
                fill: colors.veerLogo
              }
        }
        ariaLabel={t('common.accessibilityText.veerLogo')}
      />
    </Container>
  )
})

VeerLogo.displayName = 'VeerLogo'

export default withRouter(VeerLogo)
