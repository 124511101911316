import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import { ITimelinePeriodConfig, IGetPeriodConfigInput } from '../../../index'
import getAddablePeriodKeyDate from '../getAddablePeriodKeyDate'
import getDetailsType from '../getDetailsType'

const parentalTypes = [
  'Pregnancy',
  'Adoption',
  'Surrogate',
  'AdoptionFather',
  'SurrogateFather',
  'Partner'
]

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const detailsHolidays = {
    ...baseConfig.detailsHolidays,
    show: true,
    showDescription: false
  }

  if (input.leave.type === 'Sabbatical') {
    return {
      ...baseConfig,
      datePickerComponent: MultiDatePicker,
      detailsType: getDetailsType(input),
      detailsHolidays
    }
  }
  return {
    ...baseConfig,
    datePickerComponent: LeaveDatePeriodPicker,
    periodKeyDate: getAddablePeriodKeyDate(input),
    removable: true,
    detailsType: parentalTypes.includes(input.leave.type)
      ? baseConfig.detailsType
      : getDetailsType(input),
    detailsHolidays
  }
}
