import styled from 'styled-components'

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  background: ${props => props.theme.colors.light100};
  border: 1px solid ${props => props.theme.colors.dark05};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 36px;
`
Box.displayName = 'Box'

export default Box
