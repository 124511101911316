import React from 'react'
import styled, { css } from 'styled-components'
import Calendar from 'src/components/Calendar'
import { Moment } from 'moment'
import { IIconName, IMomentCurrentMinMax } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import LeaveHolidays from 'src/features/Leave/holidays'

const WIDTH = '396px'

interface IProps {
  text: string
  momentCurrentMinMax: IMomentCurrentMinMax
  holidays?: LeaveHolidays
  activeStartDate?: Moment
  onActiveStartDateChange?: (date: Moment) => void
  defaultActiveStartDate?: Moment
  onChange: (date: Moment | Moment[]) => void
  extraButton?: IExtraButton
  closeParent?: () => void
  disableWeekends?: boolean
  disabledDays?: Moment[]
  highlightedDate?: Moment
}

export interface IExtraButton {
  title: string
  iconName?: IIconName
  onClick: () => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 1px;
          width: 322px !important;
        `
      : css`
          padding: 0;
          max-width: 480px;
        `}
  padding-bottom: 12px;
`

export const Text = styled.span`
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 15px;
          width: ${WIDTH};
        `
      : css`
          font-size: 12px;
          width: 100%;
        `}
`

export const ExtraButtonWrapper = styled.div<any>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 16px 8px 12px;
        `
      : css`
          padding: 16px;
        `}
`

const ExtraButtonIconWrapper = styled(Icon)`
  margin-right: 8px;
`

const ExtraButtonTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.main90};

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }
`

const DatePickerCalendar = React.memo((props: IProps) => {
  const {
    text,
    momentCurrentMinMax,
    onChange,
    extraButton,
    closeParent,
    holidays,
    activeStartDate,
    onActiveStartDateChange,
    defaultActiveStartDate,
    disableWeekends,
    disabledDays,
    highlightedDate
  } = props

  const renderExtraButton = () => {
    if (!extraButton) {
      return null
    }
    return (
      <ExtraButtonWrapper
        onClick={() => {
          extraButton.onClick()
          if (closeParent) {
            closeParent()
          }
        }}
      >
        {extraButton.iconName && (
          <ExtraButtonIconWrapper
            name={extraButton.iconName}
            ariaLabel={extraButton.title}
          />
        )}
        <ExtraButtonTitle>{extraButton.title}</ExtraButtonTitle>
      </ExtraButtonWrapper>
    )
  }

  return (
    <ContentWrapper>
      <Calendar
        holidays={holidays}
        momentCurrentMinMax={momentCurrentMinMax}
        onChange={onChange}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={onActiveStartDateChange}
        defaultActiveStartDate={defaultActiveStartDate}
        disableWeekends={disableWeekends}
        disabledDays={disabledDays}
        highlightedDate={highlightedDate}
      />
      {text && text.length && <Text>{text}</Text>}
      {renderExtraButton()}
    </ContentWrapper>
  )
})

DatePickerCalendar.displayName = 'DatePickerCalendar'

export default DatePickerCalendar
