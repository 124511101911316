import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { useTranslation } from 'react-i18next'
import { shouldShowBabyArrivedButton } from 'src/utils/leaveUtils'
import {
  getPeriodStatusAttr,
  isInformationalPeriod,
  isPeriodSynced
} from 'src/utils/periodUtils'
import BabyHasArrivedButton from 'src/features/Timeline/components/vertical/BabyHasArrivedButton'
import isEqual from 'lodash.isequal'
import clonedeep from 'lodash.clonedeep'
import { ITimelinePeriod } from 'src/react-app-env'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import usePrevious from 'src/components/hooks/usePrevious'
import {
  TIMELINE_DETAILS_PUSH_DURATION,
  TIMELINE_DETAILS_PUSH_EASING
} from 'src/features/Timeline/components/vertical/animationConstants'
import useComponentRect from 'src/components/hooks/useComponentRect'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import TimelineViewContext, {
  ITimelineViewContext
} from 'src/features/Timeline/components/vertical/TimelineView/TimelineViewContext'
import { useResizeDetector } from 'react-resize-detector'
import ScreenContext from 'src/contexts/ScreenContext'
import TitleView from 'src/features/Timeline/components/common/TitleView'
import PeriodView from 'src/features/Timeline/components/common/PeriodView'
import BlockBackgroundView from 'src/features/Timeline/components/common/PeriodView/components/BlockBackgroundView'
import SharedContext from 'src/contexts/SharedContext'
import { isAdobe } from 'src/utils/userUtils'

import ExpandablePeriodsView from '../ExpandablePeriodsView'
import TpaInfoBlockPicker from '../LeaveDurationPickers/TpaInfoBlockPicker'

interface IProps {
  className?: string
  onBabyHasArrivedClick: () => void
  selectedDetailsPeriod?: ITimelinePeriod
  selectedDatePickerPeriod?: ITimelinePeriod
  detailsView?: any
  onDatePickerClick: (period: ITimelinePeriod) => void
  onDetailsClick: (period: ITimelinePeriod) => void
  showsDatePicker?: boolean
  showsDetailsView?: boolean
  getTopOffset?: () => number
}

const TIMELINE_MARGIN_LEFT = 20
const TIMELINE_WIDTH = 484
const DATE_PICKER_WIDTH = 457
const DETAILS_MAX_WIDTH = 900
const PERIOD_GROUP_MIN_ITEMS = 3
const TOP_SCROLL_INDENTATION = 160

const GlobalContainer = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'targetRef'
})`
  ${props =>
    props.theme.isDesktop
      ? css`
          display: flex;
          justify-content: center;
        `
      : css`
          max-width: calc(100vw - 8px);
          margin: 18px auto 0;
        `}
  ${mobileMaxWidthMixin};

  @media print {
    width: 450px;
    padding: 0 24px 0 0;
    margin: 0 auto;
  }
`

const viewShownKeyframes = (width: number) => keyframes`
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    width: ${width}px;
    opacity: 0;
  }
  100% {
    width: ${width}px;
    opacity: 1;
  }
`

const viewHiddenKeyframes = (width: number) => keyframes`
  0% {
    width: ${width}px;
    opacity: 1;
  }
  50% {
  width: ${width}px;
    opacity: 0;
  }
  100% {
    width: 0;
    opacity: 0;
  }
`

const framesMixin = css<any>`
  ${props => {
    const { $frames, $width } = props
    if ($frames) {
      return css`
        animation: ${$frames($width)} ${TIMELINE_DETAILS_PUSH_DURATION}ms
          ${TIMELINE_DETAILS_PUSH_EASING} forwards;
      `
    }
  }}
`

const DatepickerContainer = styled.div<any>`
  width: ${DATE_PICKER_WIDTH}px;
  position: relative;
  ${framesMixin}
`

const DetailsContainer = styled.div<any>`
  position: relative;
  max-width: ${DETAILS_MAX_WIDTH}px;
  width: ${props => props.$width}px;
  margin-left: 10px;
  ${framesMixin}
`

const TimelineContainer = styled.div<any>`
  ${props =>
    props.theme.isDesktop
      ? css`
          transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
          width: ${TIMELINE_WIDTH}px;
          display: flex;
          flex-direction: column;
          padding-bottom: 48px;
          ${props.$appliesMarginLeft &&
          css`
            margin-left: ${TIMELINE_MARGIN_LEFT}px;
          `};

          ${() => {
            const inactive = props.$inactive
            return css`
              filter: ${inactive ? 'grayscale(0.9)' : 'none'};
              opacity: ${inactive ? 0.4 : 1};
            `
          }}
        `
      : css`
          overflow-x: hidden;
          max-width: calc(100vw - 8px);
          margin-right: 8px;
          margin-top: 18px;
        `}

  @media print {
    width: 450px;
    padding: 0 24px 0 0;
    margin: 0 auto;
  }
`

const TimelineView = React.memo(
  (props: IProps) => {
    const context: ITimelineContext = useContext(TimelineContext)
    const {
      leave,
      timelinePeriods,
      isTimelineViewInactive,
      isLeaveDurationPickerOpened,
      onCancelChanges
    } = context
    const {
      onBabyHasArrivedClick,
      className,
      selectedDetailsPeriod,
      selectedDatePickerPeriod,
      detailsView,
      onDatePickerClick,
      onDetailsClick,
      showsDatePicker,
      showsDetailsView,
      getTopOffset
    } = props
    const { t } = useTranslation()
    const { isDesktop, isMobile } = useContext(ScreenContext)
    const wasMobile: boolean = usePrevious(isMobile)
    const isPregnancy: boolean =
      leave.type === 'Pregnancy' && !!leave.dates.disabilityEnd?.current
    const hasActiveDetailsType = !!selectedDetailsPeriod
    const prevShowsDatePicker: boolean = usePrevious(showsDatePicker)
    const prevShowsDetailsView: any = usePrevious(showsDetailsView)
    const forceUpdate = useForceUpdate()
    const { customer, customerConfig } = useContext(SharedContext)

    const lastPositionRef: any = useRef()
    const [position, setPosition] = useState<number>(0)

    const globalContainerRef: any = useRef(null)

    const periodRefs: any = {}

    const isScreenSwitched = useMemo(
      () => (wasMobile ? isDesktop : isMobile),
      [isMobile, isDesktop, wasMobile]
    )

    useEffect(() => {
      if (isLeaveDurationPickerOpened && isScreenSwitched) {
        onCancelChanges()
      }
    }, [isLeaveDurationPickerOpened, onCancelChanges, isScreenSwitched])

    const linkNodeToPeriodRef = useCallback(
      (node: any, period: ITimelinePeriod) => {
        periodRefs[period.hash] = { current: node }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const getPeriodRefByType = useCallback((type: string) => {
      const keys: string[] = Object.keys(periodRefs)
      for (let i = keys.length - 1; i >= 0; i--) {
        if (keys[i].indexOf(type) !== -1) {
          return periodRefs[keys[i]]
        }
      }

      return null
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPeriodRefByHash = useCallback(
      (period: ITimelinePeriod, addableSelected = false) => {
        const { hash } = period
        const result: any = periodRefs[hash]
        if (addableSelected && !result?.current && period.addableItemHash) {
          const addedPeriod: any = periodRefs[period.addableItemHash]
          if (addedPeriod?.current) {
            return addedPeriod
          }
        }
        return result ? result : getPeriodRefByType(period.type)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const { width: globalContainerWidth } = useComponentRect(globalContainerRef)

    const getRefForDatepicker = useCallback(
      (period: ITimelinePeriod) => {
        if (!period) {
          return null
        }
        const {
          timelineConfig: { datePickerViewForPeriod }
        } = period
        switch (datePickerViewForPeriod) {
          case 'endDate':
            return getPeriodRefByType('EndDate')
          case 'startDate':
            return getPeriodRefByType('StartDate')
          default:
            return getPeriodRefByHash(period, true)
        }
      },
      [getPeriodRefByHash, getPeriodRefByType]
    )

    const getRefForDetails = useCallback(
      (period: ITimelinePeriod) => {
        const {
          timelineConfig: { detailsViewForPeriod }
        } = period
        switch (detailsViewForPeriod) {
          case 'endDate':
            return getPeriodRefByType('EndDate')
          case 'startDate':
            return getPeriodRefByType('StartDate')
          default:
            return getPeriodRefByHash(period)
        }
      },
      [getPeriodRefByType, getPeriodRefByHash]
    )

    const scrollPeriodToTop = useCallback(
      (period: any) => {
        if (isDesktop) {
          // trying to scroll period to top
          try {
            const scrollView = globalContainerRef.current?.parentNode
            if (scrollView) {
              const containerTopPosition =
                scrollView.getBoundingClientRect().top
              const rect = period.getBoundingClientRect()
              const top: number =
                rect.top +
                scrollView.scrollTop -
                containerTopPosition -
                TOP_SCROLL_INDENTATION
              scrollView.scrollTo({
                top,
                left: 0,
                behavior: 'smooth'
              })
            }
          } catch (_) {
            return undefined
          }
        }
      },
      [isDesktop]
    )

    const getPositionForDatePicker = useCallback(
      (isForce?: boolean) => {
        let picker = getRefForDatepicker(selectedDatePickerPeriod)
        if (
          selectedDatePickerPeriod.parent &&
          isInformationalPeriod(selectedDatePickerPeriod)
        ) {
          picker = getRefForDatepicker(selectedDatePickerPeriod.parent)
        }
        if (!picker) {
          return
        }
        if (!picker.current && selectedDatePickerPeriod.type === 'BirthDate') {
          picker = getPeriodRefByType('DueDate')
        }
        const { current } = picker
        if (!current) {
          return { y: 0, alignsToTop: true }
        }
        scrollPeriodToTop(current)

        const margin = 4
        let y: number
        let bottomOffset = 0
        let alignsToTop = true
        const element: any = current.getBoundingClientRect()
        if (
          selectedDatePickerPeriod.type === 'Recovery' &&
          leave.type === 'Military'
        ) {
          y = element.top + getTopOffset() + margin
        } else if (
          current === getPeriodRefByType('EndDate')?.current ||
          (isAdobe(customer) &&
            selectedDatePickerPeriod.type === 'Annual' &&
            selectedDatePickerPeriod.appearance === 'Addable')
        ) {
          y = element.bottom + getTopOffset() - margin
          alignsToTop = false
        } else if (
          selectedDatePickerPeriod.timelineConfig.disableCreatePeriod
        ) {
          y = element.top + getTopOffset()
        } else {
          y = element.top + getTopOffset() + margin
        }

        const footer = current.getElementsByClassName(
          'background-view-footer'
        )[0]
        if (!alignsToTop && footer) {
          bottomOffset = footer.offsetHeight
        }

        if (!isForce) {
          lastPositionRef.current = y
        }

        return { y, alignsToTop, bottomOffset }
      },
      [
        customer,
        getTopOffset,
        selectedDatePickerPeriod,
        getRefForDatepicker,
        scrollPeriodToTop,
        leave,
        getPeriodRefByType
      ]
    )

    const onDetailsClickCallback = useCallback(
      (selectedPeriod: ITimelinePeriod) => {
        if (selectedPeriod) {
          const details = getRefForDetails(selectedPeriod)
          if (details && details.current) {
            scrollPeriodToTop(details.current)
          }
        }
        onDetailsClick(selectedPeriod)
      },
      [onDetailsClick, getRefForDetails, scrollPeriodToTop]
    )

    const onDatePickerClickCallback = useCallback(
      (selectedPeriod: ITimelinePeriod) => {
        onDatePickerClick(selectedPeriod)
      },
      [onDatePickerClick]
    )

    const getElementPosition = useCallback(() => {
      if (selectedDatePickerPeriod && lastPositionRef.current) {
        const positionPicker = getPositionForDatePicker(true)
        if (positionPicker.y !== lastPositionRef.current) {
          setPosition(positionPicker.y)
        }
      }
    }, [getPositionForDatePicker, lastPositionRef, selectedDatePickerPeriod])

    useEffect(() => {
      const positionTimeoutId = window.setTimeout(() => {
        getElementPosition()
      }, 350)

      return () => {
        clearTimeout(positionTimeoutId)
      }
    })

    const getStandardView: any = useCallback(
      (period: ITimelinePeriod) => (
        <PeriodView
          key={period.hash}
          period={period}
          unselected={
            hasActiveDetailsType && selectedDetailsPeriod?.hash !== period.hash
          }
          selected={selectedDetailsPeriod?.hash === period.hash}
          ref={(newRef: any) => linkNodeToPeriodRef(newRef, period)}
          statusAttr={getPeriodStatusAttr(leave, period, customerConfig, t)}
          claimStatus={period.claimStatus}
        />
      ),
      [
        hasActiveDetailsType,
        selectedDetailsPeriod,
        leave,
        customerConfig,
        t,
        linkNodeToPeriodRef
      ]
    )

    const getAddablePeriodView: any = useCallback(
      (period: ITimelinePeriod) => {
        if (
          period === selectedDatePickerPeriod &&
          (!period.timelineConfig?.disableCreatePeriod ||
            period.timelineConfig?.periodPickerBlankStartDate)
        ) {
          try {
            let index: number = timelinePeriods.indexOf(period) - 1
            let prevPeriod: ITimelinePeriod = timelinePeriods[index]

            while (prevPeriod?.appearance === 'Addable') {
              index -= 1
              prevPeriod = timelinePeriods[index]
            }

            const p: ITimelinePeriod = clonedeep(period)
            p.timelineConfig.hidesFooter = true
            p.timelineConfig.timeViewProps.isCircleHidden = false
            if (prevPeriod) {
              p.timelineConfig.timeViewProps.date = prevPeriod.periodEnd.current
                ? prevPeriod.periodEnd.current.clone()
                : prevPeriod.endDate.clone()
            }
            p.timelineConfig.timeViewProps.date.add(1, 'second')
            return (
              <PeriodView
                key={period.hash}
                period={p}
                overriddenDescription={'-'}
                ref={(newRef: any) => linkNodeToPeriodRef(newRef, period)}
              />
            )
          } catch (_) {}
        }

        if (
          !period.timelineConfig?.disableCreatePeriod &&
          period.type === selectedDatePickerPeriod?.type
        ) {
          return null
        }

        return (
          <BlockBackgroundView
            key={period.hash}
            period={period}
            ref={(newRef: any) => linkNodeToPeriodRef(newRef, period)}
            dashedBorder
            hidesOnPrint
          />
        )
      },
      [linkNodeToPeriodRef, selectedDatePickerPeriod, timelinePeriods]
    )

    const getAddablePeriodsGroupView: any = useCallback(
      (periodsGroup: ITimelinePeriod[]) => {
        const groupViews = periodsGroup
          .map(period => getAddablePeriodView(period))
          .filter(period => period !== null)
        return (
          <ExpandablePeriodsView key={periodsGroup[0].hash}>
            {groupViews}
          </ExpandablePeriodsView>
        )
      },
      [getAddablePeriodView]
    )

    const getDateView: any = useCallback(
      (period: ITimelinePeriod) => {
        const { type, hash } = period
        const {
          timelineConfig: { title, description }
        } = period

        return (
          <BlockBackgroundView
            key={hash}
            inactive={isPregnancy}
            unselected={
              hasActiveDetailsType &&
              selectedDetailsPeriod?.hash !== period.hash
            }
            selected={selectedDetailsPeriod?.hash === period.hash}
            period={period}
            ref={(newRef: any) => linkNodeToPeriodRef(newRef, period)}
          >
            <TitleView
              title={title}
              titleBold
              description={description}
              statusAttr={getPeriodStatusAttr(leave, period, customerConfig, t)}
              claimStatus={period.claimStatus}
            >
              {type === 'DueDate' &&
                isMobile &&
                shouldShowBabyArrivedButton(leave) && (
                  <BabyHasArrivedButton onClick={onBabyHasArrivedClick} />
                )}
            </TitleView>
          </BlockBackgroundView>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        hasActiveDetailsType,
        isPregnancy,
        selectedDetailsPeriod,
        leave,
        t,
        isMobile,
        timelinePeriods,
        onBabyHasArrivedClick
      ]
    )

    const getPeriodView = useCallback(
      (period: ITimelinePeriod, group: ITimelinePeriod[] = []) => (
        <BlockBackgroundView
          key={period.hash}
          inactive
          period={period}
          expandableGroup={group}
          ref={(newRef: any) => linkNodeToPeriodRef(newRef, period)}
          selected={selectedDetailsPeriod?.hash === period.hash}
        >
          <TitleView
            title={period.timelineConfig.title}
            description={period.timelineConfig.description}
            duration={period.timelineConfig.duration.shortString}
          />
        </BlockBackgroundView>
      ),
      [selectedDetailsPeriod, linkNodeToPeriodRef]
    )

    const getConvertibleView = useCallback(
      (period: ITimelinePeriod) => (
        <BlockBackgroundView key={period.hash} inactive period={period}>
          <TitleView
            title={period.timelineConfig.title}
            titleBold
            description={period.timelineConfig.description}
            duration={period.timelineConfig.duration.shortString}
          />
        </BlockBackgroundView>
      ),
      []
    )

    const timelinePeriodViews: any[] = useMemo(() => {
      const getAddableGroup = (pos: number) => {
        const group = []
        while (
          pos < timelinePeriods.length &&
          timelinePeriods[pos].appearance === 'Addable'
        ) {
          group.push(timelinePeriods[pos])
          pos += 1
        }
        return group
      }
      const result: ReactNode[] = []
      for (let i = 0; i < timelinePeriods.length; i += 1) {
        const period = timelinePeriods[i]
        const { appearance } = period
        switch (appearance) {
          case 'Standard':
          case 'Notice':
            result.push(getStandardView(period))
            break
          case 'Addable':
            const group = getAddableGroup(i)
            if (group.length >= PERIOD_GROUP_MIN_ITEMS) {
              result.push(getAddablePeriodsGroupView(group))
              i += group.length - 1
            } else {
              result.push(getAddablePeriodView(period))
            }
            break
          case 'KeyDate':
            result.push(getDateView(period))
            break
          case 'Convertible':
            result.push(getConvertibleView(period))
            break
          case 'Period':
            const innerPeriods = timelinePeriods.filter(
              p => p.parent === period
            )
            i += innerPeriods.length
            result.push(getPeriodView(period, innerPeriods))
            break
        }
      }

      return result
    }, [
      timelinePeriods,
      getAddablePeriodView,
      getDateView,
      getPeriodView,
      getConvertibleView,
      getStandardView,
      getAddablePeriodsGroupView
    ])

    const onResize = useCallback(() => {
      if (isDesktop && !!selectedDatePickerPeriod) {
        forceUpdate()
      }
    }, [selectedDatePickerPeriod, forceUpdate, isDesktop])

    useResizeDetector({
      targetRef: globalContainerRef,
      handleHeight: true,
      onResize
    })

    const timelineView: any = useMemo(
      () => (
        <TimelineContainer
          $appliesMarginLeft={showsDetailsView}
          $inactive={isTimelineViewInactive}
        >
          {timelinePeriodViews}
        </TimelineContainer>
      ),
      [timelinePeriodViews, showsDetailsView, isTimelineViewInactive]
    )

    const getFrames = useCallback(
      (was: boolean, now: boolean) =>
        was !== now ? (now ? viewShownKeyframes : viewHiddenKeyframes) : null,
      []
    )

    const detailsWidth: number = useMemo(
      () =>
        isDesktop
          ? Math.min(
              DETAILS_MAX_WIDTH,
              globalContainerWidth - TIMELINE_WIDTH - TIMELINE_MARGIN_LEFT - 10 // for scroll bar
            )
          : 0,
      [globalContainerWidth, isDesktop]
    )

    const currentPickerView: ReactNode = useMemo(() => {
      if (!selectedDatePickerPeriod) {
        setPosition(0)
        return null
      }

      const p: any = {
        onExit: () => {
          onDatePickerClickCallback(null)
        },
        ...getPositionForDatePicker(),
        position
      }

      const Component = isPeriodSynced(selectedDatePickerPeriod)
        ? TpaInfoBlockPicker
        : selectedDatePickerPeriod.timelineConfig.datePickerComponent
      return <Component period={selectedDatePickerPeriod} {...p} />
    }, [
      selectedDatePickerPeriod,
      onDatePickerClickCallback,
      getPositionForDatePicker,
      position
    ])

    const content: any = useMemo(() => {
      if (isMobile) {
        return timelineView
      }

      return (
        <React.Fragment>
          {selectedDatePickerPeriod && (
            <DatepickerContainer
              $frames={getFrames(prevShowsDatePicker, showsDatePicker)}
              $width={DATE_PICKER_WIDTH}
            >
              {currentPickerView}
            </DatepickerContainer>
          )}
          {timelineView}
          {selectedDetailsPeriod && (
            <DetailsContainer
              $frames={getFrames(prevShowsDetailsView, showsDetailsView)}
              $width={detailsWidth}
            >
              {React.cloneElement(detailsView, {
                containerWidth: detailsWidth
              })}
            </DetailsContainer>
          )}
        </React.Fragment>
      )
    }, [
      detailsView,
      detailsWidth,
      prevShowsDatePicker,
      prevShowsDetailsView,
      selectedDatePickerPeriod,
      selectedDetailsPeriod,
      showsDatePicker,
      showsDetailsView,
      timelineView,
      getFrames,
      currentPickerView,
      isMobile
    ])

    const contextValue: ITimelineViewContext = useMemo(
      () => ({
        selectedDatePickerPeriod,
        selectedDetailsPeriod,
        onDetailsClick: onDetailsClickCallback,
        onDatePickerClick: onDatePickerClickCallback
      }),
      [
        selectedDatePickerPeriod,
        selectedDetailsPeriod,
        onDetailsClickCallback,
        onDatePickerClickCallback
      ]
    )

    return (
      <TimelineViewContext.Provider value={contextValue}>
        <GlobalContainer
          ref={globalContainerRef}
          className={className}
          role={'region'}
          aria-label={t('timeline.accessibility.regionTimeline')}
        >
          {content}
        </GlobalContainer>
      </TimelineViewContext.Provider>
    )
  },
  (prevProps: IProps, nextProps: IProps) => isEqual(prevProps, nextProps)
)

TimelineView.displayName = 'TimelineView'

export default TimelineView
