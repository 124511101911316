import React from 'react'
import styled from 'styled-components'
import constructionZone from 'src/assets/images/construction-zone.svg'
import { useTranslation } from 'react-i18next'

const MaintananceView = styled.img`
  width: 100%;
`

export const dataAttrs = {
  component: () => 'welcome-maintenance'
}

const MaintenanceView = React.memo(() => {
  const { t } = useTranslation()

  return (
    <MaintananceView
      data-testid={dataAttrs.component()}
      src={constructionZone}
      alt={t('common.accessibilityText.constructionZone')}
    />
  )
})

MaintenanceView.displayName = 'MaintenanceView'

export default MaintenanceView
