import React, { useRef } from 'react'
import styled from 'styled-components'
import useChart from 'src/features/HrAdmin/hooks/useChart'
import UsersChart from 'src/features/HrAdmin/components/charts/UsersChart'
import { useTranslation } from 'react-i18next'
import { AnalyticsNumbersType } from 'src/graphql/hooks/useAnalytics'

interface IProps {
  data: any
  numberType: AnalyticsNumbersType
}

const ChartContainer = styled.div`
  height: 184px;
  min-width: 100%;
`

export const UsersChartResults = React.memo((props: IProps) => {
  const { data, numberType } = props
  const { t } = useTranslation()
  const chartContainerRef: any = useRef(null)

  useChart(chartContainerRef, UsersChart, {
    data,
    t,
    translationKey: numberType
  })

  return <ChartContainer ref={chartContainerRef} />
})

UsersChartResults.displayName = 'UsersChartResults'

export default UsersChartResults
