import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import { useTranslation } from 'react-i18next'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import DatePicker from 'src/components/DatePicker'
import DatePickerSingleLineView from 'src/components/DatePicker/components/views/SingleLineView'
import { Moment } from 'moment'
import Toggle from 'src/components/Toggle'
import ScreenContext from 'src/contexts/ScreenContext'
import { isNullOrEmpty } from 'src/utils/helpers'

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
          background: ${props.theme.colors.light100};
        `
      : css`
          width: 100%;
        `}
`

const ReinstatementToggleContainer = styled.div`
  margin: ${props => (props.theme.isDesktop ? '20px' : '16px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const ReinstatementToggleText = styled.span`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  color: ${props => props.theme.colors.dark60};
`

const shownMixin = (
  height: number,
  mobileMargin = 0,
  desktopMargin = 0
) => css<{ $shown: boolean }>`
  transition: all 100ms;
  ${(props: any) => {
    const { $shown } = props
    const h: string = $shown ? `${height}px` : '0'
    const m: string = $shown
      ? `${props.theme.isDesktop ? desktopMargin : mobileMargin}px`
      : '0'
    const opacity: number = $shown ? 1 : 0
    return css`
      height: ${h};
      opacity: ${opacity};
      margin-top: ${m};
      margin-bottom: ${m};
    `
  }}
`

const ReinstatementDatePicker = styled(DatePicker)<{ $shown: boolean }>`
  margin: ${props => (props.theme.isDesktop ? '0 20px' : '0 16px')};
  ${shownMixin(25, 16, 20)};
`

const Separator = styled.div<{ $shown: boolean }>`
  background: ${props => props.theme.colors.dark05};
  margin: ${props => (props.theme.isDesktop ? '0 20px' : '0')};
  ${shownMixin(1)};
`

export const dataAttrs = {
  toggle: () => 'reinstatement-toggle'
}

export const ReturningFromMilitaryView = React.memo(() => {
  const { t } = useTranslation()
  const {
    selectedStartDate,
    selectedEndDate,
    selectedReinstatementDate,
    onReinstatementDateChanged,
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    militaryDaysCount,
    reinstatementMaxDurations
  }: ICreateLeaveContext = useContext(LeaveContext)

  const [isReinstatementToggled, setIsReinstatementToggled] =
    useState<boolean>(false)

  const selectedReinstatementDuration = useMemo(
    () =>
      reinstatementMaxDurations.find(
        ({ activeDutyDurationMinDays, activeDutyDurationMaxDays }) =>
          (!isNullOrEmpty(activeDutyDurationMinDays) &&
            !isNullOrEmpty(activeDutyDurationMaxDays) &&
            militaryDaysCount >= activeDutyDurationMinDays &&
            militaryDaysCount <= activeDutyDurationMaxDays) ||
          (!isNullOrEmpty(activeDutyDurationMinDays) &&
            militaryDaysCount > activeDutyDurationMinDays) ||
          (!isNullOrEmpty(activeDutyDurationMaxDays) &&
            militaryDaysCount < activeDutyDurationMaxDays)
      ) || { reinstatementMaxDurationDays: 0 },
    [reinstatementMaxDurations, militaryDaysCount]
  )

  const minDate: Moment = useMemo(
    () => selectedEndDate.clone().add(1, 'day'),
    [selectedEndDate]
  )
  const maxDate: Moment = useMemo(
    () =>
      minDate
        .clone()
        .add(
          selectedReinstatementDuration.reinstatementMaxDurationDays - 1,
          'days'
        ),
    [minDate, selectedReinstatementDuration]
  )

  const onToggle = useCallback(() => {
    const newIsReinstatementToggled = !isReinstatementToggled
    setIsReinstatementToggled(newIsReinstatementToggled)
    let date: Moment = null
    if (newIsReinstatementToggled) {
      date = maxDate.clone()
    }
    onReinstatementDateChanged(date)
  }, [
    setIsReinstatementToggled,
    isReinstatementToggled,
    onReinstatementDateChanged,
    maxDate
  ])

  const reinstatementToggle: ReactNode = useMemo(
    () => (
      <ReinstatementToggleContainer
        data-testid={dataAttrs.toggle()}
        onClick={onToggle}
      >
        <ReinstatementToggleText>
          {t(
            'createLeave.military.returningFrom.addReinstatementPeriodAfterLeave'
          )}
        </ReinstatementToggleText>
        <Toggle toggled={isReinstatementToggled} />
      </ReinstatementToggleContainer>
    ),
    [t, isReinstatementToggled, onToggle]
  )

  const separatorView: ReactNode = useMemo(
    () => <Separator $shown={isReinstatementToggled} />,
    [isReinstatementToggled]
  )

  const { isDesktop } = useContext(ScreenContext)

  const reinstatementDatePicker: ReactNode = useMemo(() => {
    const title: string = t(
      'createLeave.military.returningFrom.reinstatementPeriodEndDate'
    )
    return (
      <ReinstatementDatePicker
        TopViewComponent={DatePickerSingleLineView}
        title={title}
        momentCurrentMinMax={{
          min: minDate,
          current: selectedReinstatementDate,
          max: maxDate
        }}
        onDateChanged={(date: any) => {
          onReinstatementDateChanged(date)
        }}
        description=""
        mobileDialogTitle={title}
        datePlaceholder={t(
          `common.${isDesktop ? 'selectDate' : 'tapToSelect'}`
        )}
        $shown={isReinstatementToggled}
        useDefaultTopOffset
        stickRight
      />
    )
  }, [
    t,
    isReinstatementToggled,
    minDate,
    maxDate,
    selectedReinstatementDate,
    onReinstatementDateChanged,
    isDesktop
  ])

  const content: any = useMemo(
    () => (
      <>
        <ControlsContainer>
          {reinstatementToggle}
          {separatorView}
          {reinstatementDatePicker}
        </ControlsContainer>
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={!selectedStartDate || !selectedEndDate}
        />
      </>
    ),
    [
      t,
      selectedStartDate,
      selectedEndDate,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      reinstatementToggle,
      separatorView,
      reinstatementDatePicker
    ]
  )

  const descriptionText: string = useMemo(
    () =>
      t(
        `createLeave.military.returningFrom.description${
          selectedReinstatementDuration.reinstatementMaxDurationDays
        }days`
      ),
    [selectedReinstatementDuration, t]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>
        {t('createLeave.military.returningFrom.title')}
      </CreateLeaveTitle>
      <CreateLeaveDescription>{descriptionText}</CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

ReturningFromMilitaryView.displayName = 'ReturningFromMilitaryView'

export default ReturningFromMilitaryView
