import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  title: string
  value: string
}

const Container = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const textMixin = css`
  line-height: 200%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const Title = styled.span`
  ${textMixin};
`

const Value = styled.span`
  ${textMixin}
`

export const dataAttrs = {
  container: () => 'leave-info-view-row-container'
}

const LeaveInfoViewRow = React.memo((props: IProps) => {
  const { title, value } = props

  return (
    <Container
      data-testid={dataAttrs.container()}
      aria-label={`${title} ${value}`}
    >
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  )
})

LeaveInfoViewRow.displayName = 'LeaveInfoViewRow'

export default LeaveInfoViewRow
