import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { IWithQueriesProps } from 'src/react-app-env'
import withQueries from '../HOC/withQueries'
import BannerItem from './components/BannerItem'
import useAlerts, { IUseAlerts } from 'src/graphql/hooks/useAlerts'
import styled from 'styled-components'
import LoadingSpinner from '../LoadingSpinner'
import zIndex from 'src/constants/zIndex'
import BannerContext, { IBannerContext } from './BannerContext'
import SharedContext, { ISharedContext } from 'src/contexts/SharedContext'
import useComponentRect from '../hooks/useComponentRect'

interface IProps extends IWithQueriesProps {}

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.banner};
`

const InformationBanner = React.memo((props: IProps) => {
  const { queries } = props
  const sharedContext: ISharedContext = useContext(SharedContext)
  const bannerRef: any = useRef(null)
  const { height } = useComponentRect(bannerRef)
  const { alerts, error, loading, closeAlert }: IUseAlerts = useAlerts(
    'Banner' as IAlertType,
    queries
  )
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const [bannerHeight, setBannerHeight] = useState(0)

  const currentBannerItem: IAlert = useMemo(
    () => alerts[currentBannerIndex],
    [alerts, currentBannerIndex]
  )

  const onClose = useCallback(
    (id: string) => {
      closeAlert(id)
      setCurrentBannerIndex(prevIndex => prevIndex + 1)
    },
    [closeAlert]
  )

  useEffect(() => {
    setBannerHeight(height)
  }, [alerts, height])

  const contextValue: IBannerContext = useMemo(
    () => ({
      bannerHeight
    }),
    [bannerHeight]
  )

  useEffect(() => {
    sharedContext.setBannerContext(contextValue)
  }, [sharedContext, contextValue])

  useEffect(
    () => () => {
      setBannerHeight(height)
      sharedContext.setBannerContext(contextValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (loading) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  if (error) {
    console.error(error)
    return null
  }

  if (!currentBannerItem) {
    return null
  }

  return (
    <BannerContext.Provider value={contextValue}>
      <Container ref={bannerRef}>
        <BannerItem bannerAttr={currentBannerItem} onClose={onClose} />
      </Container>
    </BannerContext.Provider>
  )
})

InformationBanner.displayName = 'InformationBanner'

export default withQueries(InformationBanner)
