import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Button, Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import {
  mobileMaxWidthMixin,
  timelineNoticeCloseButtonMixin,
  timelineNoticeContainerMixin,
  timelineNoticeTextMixin
} from 'src/theme/utils'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'
import { useLocalStorage } from 'src/components/hooks/useLocalStorage'
import { LeaveNoticeItemNextButton } from 'src/config/customers/config'
import { useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'

interface IProps {
  className?: string
  leave: ILeave
  paddingTop?: number
  noticeKey: string
  titleKey: string
  bodyTextKey: string
  nextButton?: LeaveNoticeItemNextButton
}

interface ILeaveNotice {
  canBeShown: boolean
  leaveID: Scalars['ID']['output']
}

const { colors } = createTheme()

const Container = styled.div<{ $top?: number }>`
  ${timelineNoticeContainerMixin};
  ${mobileMaxWidthMixin};
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  line-height: 130%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: bold;
        `}
`

const CloseButton = styled.button`
  ${timelineNoticeCloseButtonMixin};
`

const IconWrapper = styled(Icon)`
  padding-top: 2px;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Text = styled(ReactMarkdown)`
  ${timelineNoticeTextMixin};
  white-space: pre-wrap;

  a {
    color: ${props => props.theme.colors.main100};
    font-weight: normal;
    line-height: 150%;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 12px;
      `}
  }
`

export const NextButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 8px 0;
          min-width: 160px;
          width: 100%;
        `
      : css`
          width: 100%;
          flex: 1;
          height: 48px;
          font-size: 16px;
        `}
`

const ButtonsContainer = styled.div`
  display: flex;
  padding-top: ${props => (props.theme.isDesktop ? '10px' : '6px')};
`

const LeaveNotice = React.memo((props: IProps) => {
  const {
    className,
    leave,
    paddingTop,
    noticeKey,
    titleKey,
    bodyTextKey,
    nextButton
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const forceUpdate = useForceUpdate()
  const [leaveNotice, setLeaveNotice] = useLocalStorage(
    noticeKey,
    {} as ILeaveNotice
  )

  useEffect(() => {
    if (leaveNotice.leaveID !== leave.id) {
      setLeaveNotice({ canBeShown: true, leaveID: leave.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!leaveNotice.canBeShown) {
    return null
  }

  const onClose = () => {
    setLeaveNotice({ canBeShown: false, leaveID: leave.id })
    forceUpdate()
  }

  return (
    <Container
      className={className}
      role={'region'}
      aria-label={t('leaveNotice.accessibility.region')}
      $top={paddingTop || 0}
    >
      <TitleContainer>
        <Title>{t(titleKey)}</Title>
        <CloseButton onClick={onClose} aria-label={t('common.close')}>
          <IconWrapper
            name={'close_crossing'}
            settings={{
              fill: colors.main100
            }}
          />
        </CloseButton>
      </TitleContainer>
      <TextContainer>
        <Text
          urlTransform={TelUrlTransformer}
          components={{
            a: LinkRenderer
          }}
        >
          {t(bodyTextKey)}
        </Text>
      </TextContainer>
      {nextButton && (
        <ButtonsContainer>
          <NextButton
            appearance="cancel"
            onClick={() => {
              flushSync(onClose)
              nextButton.onClick({ navigate })
            }}
          >
            {t(nextButton.textKey)}
          </NextButton>
        </ButtonsContainer>
      )}
    </Container>
  )
})

LeaveNotice.displayName = 'LeaveNotice'

export default LeaveNotice
