import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  className?: string
  email?: string
  date?: Moment
}

const Container = styled.div`
  flex: 0 0 auto;

  ${props =>
    props.theme.isDesktop
      ? css`
          background: ${props.theme.colors.light100};
          padding: 4px 8px;
          margin: 20px 0 0 52px;
          border-radius: 8px;
        `
      : css`
          margin: 8px 0 16px 16px;
        `}
`

const textMixin = css`
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  display: inline;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
        `}
`

const StaticText = styled.span`
  ${textMixin};
  color: ${props => props.theme.colors.dark60};
`

const DynamicText = styled.span`
  ${textMixin};
  color: ${props => props.theme.colors.dark60};
  margin: 0 2px;
`

const JourneyMapListItemCompletedByView = React.memo((props: IProps) => {
  const { className, date, email } = props
  const { t } = useTranslation()

  if (!date || !email) {
    return null
  }

  return (
    <Container className={className} aria-hidden>
      <StaticText>{t('common.changedBy')}</StaticText>
      <DynamicText>{email}</DynamicText>
      <StaticText>{t('common.on')}</StaticText>
      <DynamicText>{date.format(DATE_FORMAT)}</DynamicText>
    </Container>
  )
})

JourneyMapListItemCompletedByView.displayName =
  'JourneyMapListItemCompletedByView'

export default JourneyMapListItemCompletedByView
