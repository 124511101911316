import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import MobileHeaderEmployee from 'src/features/ManagerJourneyMap/components/Settings/components/MobileHeaderEmployee'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import MobilePlanSettingsView from 'src/features/ManagerJourneyMap/components/Settings/components/MobilePlanSettingsView'
import { Icon } from 'src/UIKit'
import AbsenceDatesRangeView from 'src/components/AbsenceDatesRangeView'
import moment from 'moment'

interface IProps {
  viewContainerRef: any
  setSettingsExpanded: (flag: boolean) => void
  settingsExpanded: boolean
}

const MobileExpandViewHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MobileExpandViewHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const IconWrapper = styled(Icon)`
  margin-right: 16px;
  margin-top: 3px;
`

export const dataAttrs = {
  right: () => `header-right-view`
}

const MobileHeaderView = React.memo((props: IProps) => {
  const { viewContainerRef, setSettingsExpanded, settingsExpanded } = props

  const context: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const { currentAbsence, isArchiveMode, priorityFilters, isOverdueMode } =
    context

  const headerLeftView = currentAbsence ? (
    <>
      <button onClick={context.clickBack}>
        <IconWrapper name="arrow_prev" />
      </button>
      <MobileHeaderEmployee
        name={currentAbsence.employee.name}
        hasOverdue={false}
        isOpen={false}
        hidesArrow
      />
    </>
  ) : null

  const numOfActiveFilters = useMemo(
    () =>
      isOverdueMode ? 1 : (priorityFilters || []).filter(f => f.checked).length,
    [isOverdueMode, priorityFilters]
  )

  const headerRightView = useMemo(
    () =>
      isArchiveMode && currentAbsence ? (
        <AbsenceDatesRangeView
          startDate={moment.utc(currentAbsence.startDate)}
          endDate={moment.utc(currentAbsence.endDate)}
        />
      ) : (
        <MobilePlanSettingsView
          journeyMap={currentAbsence ? currentAbsence.journeyMap : null}
          numOfActiveFilters={numOfActiveFilters}
        />
      ),
    [isArchiveMode, currentAbsence, numOfActiveFilters]
  )

  return (
    <MobileExpandViewHeader>
      <MobileExpandViewHeaderTitleContainer>
        {headerLeftView}
      </MobileExpandViewHeaderTitleContainer>
      <MobileExpandViewHeaderTitleContainer
        data-testid={dataAttrs.right()}
        onClick={() => {
          setSettingsExpanded(!settingsExpanded)
          if (settingsExpanded) {
            viewContainerRef.current.collapse()
          } else {
            viewContainerRef.current.expand()
          }
        }}
      >
        {headerRightView}
      </MobileExpandViewHeaderTitleContainer>
    </MobileExpandViewHeader>
  )
})

MobileHeaderView.displayName = 'MobileHeaderView'

export default MobileHeaderView
