import React from 'react'
import styled from 'styled-components'
import { Moment } from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'

interface IProps {
  date: Moment
}

const DateWrapper = styled.div<any>`
  display: flex;
  justify-content: flex-start;
`

const DateValue = styled.span`
  font-weight: 500;
  font-family: Roboto, serif;
  line-height: 100%;
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  color: ${props => props.theme.colors.dark60};
`

const AbsenceListItemDate = React.memo(
  (props: IProps) => {
    const { date } = props

    return (
      <DateWrapper>
        <DateValue>{date.utc().format(DATE_FORMAT)}</DateValue>
      </DateWrapper>
    )
  },
  (prev: IProps, next: IProps) => prev.date.isSame(next.date)
)

AbsenceListItemDate.displayName = 'AbsenceListItemDate'

export default AbsenceListItemDate
