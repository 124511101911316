import React, { ReactNode, useCallback, useMemo, useContext } from 'react'
import ListContainer from 'src/components/JourneyMap/ListContainer'
import UnarchiveButton from 'src/components/JourneyMap/UnarchiveButton'
import ScreenContext from 'src/contexts/ScreenContext'
import { IGraphqlExecutorOptions } from 'src/react-app-env'
import { convertJourneyMapDueDateToMoment } from 'src/utils/journeyMap'
import styled from 'styled-components'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from '../JourneyMapContext'

const ActionButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 32px;
`

const ManagerJourneyMapListContainer = React.memo(() => {
  const context: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const {
    isOverdueMode,
    priorityFilters,
    blurred,
    queries,
    onToggleError,
    currentAbsence,
    isArchiveMode,
    unarchivePlan
  } = context

  const { isMobile } = useContext(ScreenContext)

  const toggleJourneyMapItem = useCallback(
    (ID: string, options?: IGraphqlExecutorOptions) => {
      const variables = {
        archived: false
      }
      return queries.toggleAbsenceJourneyMapItem(ID, variables, options)
    },
    [queries]
  )

  const actionButtonView: ReactNode = useMemo(
    () => (isArchiveMode ? <UnarchiveButton onClick={unarchivePlan} /> : null),
    [isArchiveMode, unarchivePlan]
  )

  const journeyMap = useMemo(
    () => convertJourneyMapDueDateToMoment(currentAbsence?.journeyMap),
    [currentAbsence?.journeyMap]
  )

  if (!currentAbsence) {
    return null
  }

  return (
    <React.Fragment>
      <ListContainer
        blurred={blurred}
        isOverdueMode={isOverdueMode}
        journeyMap={journeyMap}
        onToggleError={onToggleError}
        toggleJourneyMapItem={toggleJourneyMapItem}
        priorityFilters={priorityFilters}
        extraMarginBottom={isMobile && isArchiveMode}
      />
      {isMobile && (
        <ActionButtonContainer>{actionButtonView}</ActionButtonContainer>
      )}
    </React.Fragment>
  )
})

ManagerJourneyMapListContainer.displayName = 'ManagerJourneyMapListContainer'

export default ManagerJourneyMapListContainer
