import React from 'react'

export interface IUserContext {
  firstName: string
  lastName: string
  fullName: string
  email: string
}

const UserContext = React.createContext(null)

export default UserContext
