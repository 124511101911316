import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  selected?: boolean
  onClick: () => void
  children: any
  showsSeparator: boolean
  ariaLabel: string
}

export const Container = styled.button<{
  $selected: boolean
  $showsSeparator: boolean
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 16px;
  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.main10};
    `}
  ${props =>
    props.$showsSeparator &&
    css`
      border-top: 1px solid
        ${props.theme.isDesktop
          ? props.theme.colors.main05
          : props.theme.colors.main20};
    `}
  transition: background 200ms;

  &:hover {
    background: ${props => props.theme.colors.main10};
    border-radius: 16px;
  }

  &:focus {
    outline: none;
    background: ${props => props.theme.colors.main10};
    border: 2px solid ${props => props.theme.colors.main110};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-left: 16px;
          padding: 10px 16px;
        `
      : css`
          margin-left: 15px;
          padding: 20px 10px;
          height: 28px;
          width: calc(100% - 20px);
        `}
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-right: 16px;
    `}
`

const ArrowWrapper = styled(Icon)`
  margin-top: 4px;
`

export const dataAttrs = {
  component: () => 'absence-archived-list-item'
}

const AbsenceArchivedListItemContainer = React.memo((props: IProps) => {
  const { selected, onClick, children, showsSeparator, ariaLabel } = props

  return (
    <Container
      data-testid={dataAttrs.component()}
      $selected={selected}
      onClick={onClick}
      $showsSeparator={showsSeparator}
      aria-label={ariaLabel}
      role={'tab'}
      aria-selected={selected}
    >
      <ContentContainer>{children}</ContentContainer>
      <ArrowWrapper
        name={'arrow_forward'}
        settings={{ fill: selected ? colors.main10 : colors.main100 }}
      />
    </Container>
  )
})

AbsenceArchivedListItemContainer.displayName =
  'AbsenceArchivedListItemContainer'

export default AbsenceArchivedListItemContainer
