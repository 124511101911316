import { useEffect, useState } from 'react'
import isEqual from 'lodash.isequal'

export interface IRect {
  width: number
  height: number
  left: number
  right: number
  top: number
  bottom: number
}

const zeroRect: IRect = {
  width: 0,
  height: 0,
  bottom: 0,
  top: 0,
  left: 0,
  right: 0
}

export default (componentRef: any): IRect => {
  const [rect, setRect] = useState<IRect>(zeroRect)

  const domToRect = (d: any): IRect => ({
    width: d.width,
    left: d.left,
    height: d.height,
    top: d.top,
    bottom: d.bottom,
    right: d.right
  })

  const getRect = (): IRect => {
    if (!componentRef?.current) {
      return zeroRect
    }

    return domToRect(componentRef.current.getBoundingClientRect())
  }

  const updateRect = (value: IRect) => {
    setRect(value)
  }

  useEffect(() => {
    const onResize = () => {
      updateRect(getRect())
    }

    window.addEventListener('resize', onResize)
    const element: any = componentRef?.current
    if (element) {
      element.addEventListener('resize', onResize)
    }
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
      if (element) {
        element.removeEventListener('resize', onResize)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef])

  const r: IRect = getRect()
  if (!isEqual(r, rect)) {
    updateRect(r)
    return r
  }

  return rect
}
