import React, { useCallback } from 'react'
import styled from 'styled-components'
import AbsenceArchivedEmployeeName from 'src/features/ManagerJourneyMap/components/AbsenceArchivedEmployeeName'
import { sortAbsencesByCreatedDate } from 'src/utils/absences'
import { useTranslation } from 'react-i18next'
import AbsenceArchivedListItem from '../AbsenceArchivedListItem'

interface IProps {
  className?: string
  absences: IAbsence[]
  selectAbsence: (absence: IAbsence) => void
}

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

export const AbsencesArchivedList = React.memo((props: IProps) => {
  const { absences, selectAbsence } = props
  const { t } = useTranslation()

  const employees: any = {}
  for (const absence of absences) {
    const employeeName: string = absence.employee.name
    let employee: any[] = employees[employeeName]
    if (!employee) {
      employee = []
    }
    employee.push(absence)
    employees[employeeName] = employee
  }
  const resultViews: any = []
  const sortedEmployeeNames: string[] = Object.keys(employees).sort()

  const onClickCallback = useCallback(
    (absence: IAbsence) => {
      selectAbsence(absence)
    },
    [selectAbsence]
  )

  for (const employeeName of sortedEmployeeNames) {
    resultViews.push(
      <AbsenceArchivedEmployeeName key={employeeName} name={employeeName} />
    )
    const employee: IAbsence[] = employees[employeeName]
    sortAbsencesByCreatedDate(employee)
    for (let i = 0; i < employee.length; i++) {
      const absence: IAbsence = employee[i]
      resultViews.push(
        <AbsenceArchivedListItem
          key={absence.id}
          absence={absence}
          onClick={onClickCallback}
          badgeNumber={employee.length - i}
        />
      )
    }
  }

  return (
    <Container
      className={props.className}
      role={'region'}
      aria-label={t('common.accessibilityText.regionArchivedPlansList')}
    >
      {resultViews}
    </Container>
  )
})

AbsencesArchivedList.displayName = 'AbsencesArchivedList'

export default AbsencesArchivedList
