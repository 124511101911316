import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from 'react'
import styled, { css } from 'styled-components'
import { printDisplayNoneMixin } from 'src/theme/utils'
import TimeView from 'src/features/Timeline/components/vertical/TimeView'
import { useTranslation } from 'react-i18next'
import AnimateHeight from 'react-animate-height'
import TimelineViewContext, {
  ITimelineViewContext
} from '../TimelineView/TimelineViewContext'

const TRANSITION_DURATION = 200

const Container = styled.div`
  display: flex;
  width: 100%;
  cursor: default;
  position: relative;
  ${printDisplayNoneMixin}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 20px 'transparent';
  border: 1px dashed ${props => props.theme.colors.dark10};
  background: ${props => props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 4px 0;
        `
      : css`
          margin: 2px 0;
        `}

  @media print {
    padding: 24px;
    margin: 4px 0;
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.dark10};
    background: ${props => props.theme.colors.light100};
  }
`
const Button = styled.button<{
  $disabled: boolean
}>`
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          line-height: 16px;
          padding: 12px 0;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
          padding: 8px 0;
        `}

  cursor: pointer;
  color: ${props => props.theme.colors.main100};
  user-select: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 10px;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    outline: none;
  }

  ${(props: any) => {
    if (props.$disabled) {
      return css`
        cursor: unset;
        color: ${props.theme.colors.dark20};
        text-decoration: none;

        &:hover {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }

        &:focus {
          color: ${props.theme.colors.dark20};
          text-decoration: none;
        }
      `
    }
  }}
`

const AnimatedButtonText = styled.span<any>`
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
  opacity: ${props => props.opacity};
  position: ${props => (props.opacity ? 'relative' : 'absolute')};
`

interface IProps {
  children: any[]
}

export const dataAttrs = {
  expandButton: () => 'expandable-periods-expand-button',
  content: () => 'expandable-periods-content'
}

const ExpandablePeriodsView = React.memo((props: IProps) => {
  const { children } = props
  const { selectedDatePickerPeriod }: ITimelineViewContext =
    useContext(TimelineViewContext)
  const [expanded, setExpanded] = useState<boolean>(false)
  const onClick = useCallback(() => {
    if (selectedDatePickerPeriod) {
      return
    }
    setExpanded(!expanded)
  }, [expanded, setExpanded, selectedDatePickerPeriod])
  const { t } = useTranslation()

  const lastChild = useMemo(
    () => (children.length > 0 ? children[children.length - 1] : null),
    [children]
  )

  useEffect(() => {
    const hasAddableBabyBonding = children.some(
      (child: any) =>
        child?.props?.period?.type === 'BabyBonding' &&
        child?.props?.period?.appearance === 'Addable'
    )
    if (hasAddableBabyBonding) {
      setExpanded(true)
    }
  }, [children])
  return (
    <div>
      <Container onClick={onClick}>
        <TimeView period={lastChild?.props?.period} />
        <ContentContainer>
          <Button
            data-testid={dataAttrs.expandButton()}
            disabled={!!selectedDatePickerPeriod}
            $disabled={!!selectedDatePickerPeriod}
          >
            <AnimatedButtonText opacity={expanded ? 1 : 0}>
              {t('timeline.expandablePeriods.cancel')}
            </AnimatedButtonText>
            <AnimatedButtonText opacity={expanded ? 0 : 1}>
              {t('timeline.expandablePeriods.expand')}
            </AnimatedButtonText>
          </Button>
        </ContentContainer>
      </Container>
      <AnimateHeight
        duration={300}
        height={expanded ? 'auto' : 0}
        animateOpacity
      >
        {children}
      </AnimateHeight>
    </div>
  )
})

ExpandablePeriodsView.displayName = 'ExpandablePeriodsView'

export default ExpandablePeriodsView
