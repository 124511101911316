import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Day = styled.span`
  justify-self: center;
  font-weight: 300;
  line-height: 130%;
  margin: 0;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 28px;
        `
      : css`
          font-size: 16px;
          margin-left: 2px;
          margin-bottom: 2px;
        `}
`

const DateViewDay = React.memo((props: IProps) => {
  const { className, children } = props

  return <Day className={className}>{children}</Day>
})

DateViewDay.displayName = 'DateViewDay'

export default DateViewDay
