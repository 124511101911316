import merge from 'lodash.merge'
import {
  defaultCustomerConfig,
  ICustomerConfig,
  LeaveNoticeItem
} from './config'
import { ITimelineContext } from 'src/features/Timeline/Context'
import { openDatePickerForPreference } from 'src/utils/leaveUtils'
import { isPublishedLeave } from 'src/utils/leaveStatusUtils'
import {
  VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY,
  VIEW_LEAVE_NOTICE_TPA_KEY,
  VIEW_LEAVE_NOTICE_TRANSITION_PL_KEY
} from 'src/utils/ls'
import moment from 'moment'
import logo from 'src/assets/images/workday-logo.svg'

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    logo,
    tpa: 'Lincoln Financial',
    leave: {
      create: {
        showsMoreThanNote: () => false,
        showMilitaryNotSupportedNote: true,
        showsMultipleBirth: true,
        preferences: ['starter', 'reliever', 'middler'],
        workflow: {
          Medical: {
            init: 'medicalBeforeWeStart',
            medicalBeforeWeStart: 'medical',
            medical: null
          },
          Parental: {
            parental: ({ selectedLeaveType }) =>
              selectedLeaveType?.type !== 'Pregnancy'
                ? 'selectLeavePreference'
                : null,
            selectLeavePreference: null
          },
          Military: {
            military: ({ isMilitaryIncludingReturn }) =>
              isMilitaryIncludingReturn ? 'militaryReturning' : null,
            militaryReturning: null
          }
        }
      },
      timeline: {
        showReviewAlerts: true,
        showNextStepsNotice: (leave: ILeave): boolean =>
          isPublishedLeave(leave.status) && leave.type === 'Miscarriage',
        onAfterCloseReviewAlert: (timelineContext: ITimelineContext) => {
          openDatePickerForPreference(timelineContext, 'middler')
        },
        getLeaveNoticeItems: (leave: ILeave) => {
          const result: LeaveNoticeItem[] = [
            {
              noticeKey: VIEW_LEAVE_NOTICE_TPA_KEY,
              titleKey: 'leaveNotice.viewTpa.title',
              bodyTextKey: 'leaveNotice.viewTpa.text'
            }
          ]
          const currentDate = moment().utc()
          const expirationDate = moment('2024-03-01').utc()

          if (
            (leave.type === 'Pregnancy' &&
              leave.metadata?.transitionFromType === 'MultiPregnancy') ||
            (leave.type === 'Partner' &&
              leave.metadata?.transitionFromType === 'MultiPartner')
          ) {
            result.push({
              noticeKey: VIEW_LEAVE_NOTICE_TRANSITION_PL_KEY,
              titleKey: 'leaveNotice.viewTransitionPL.title',
              bodyTextKey: 'leaveNotice.viewTransitionPL.text'
            })
          }

          // TODO remove this after March 1, 2024
          if (currentDate.isBefore(expirationDate)) {
            result.push({
              noticeKey: VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY,
              titleKey: 'leaveNotice.viewPolicyChange.title',
              bodyTextKey: 'leaveNotice.viewPolicyChange.text'
            })
          }

          return result
        },
        hideBlockIncomeSection: (
          leave: ILeave,
          countryCode: string,
          block: IPeriodBlock
        ) => block.type === 'Other time away'
      }
    }
  })
