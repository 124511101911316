import moment from 'moment'
import { IIconName } from 'src/react-app-env'
import {
  DatePickerKeyDate,
  IGetPeriodConfigInput,
  ITimelinePeriodConfig
} from '../../../index'
import LeaveDateSinglePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateSinglePicker'
import { isRotatingSchedule, DATE_FORMAT_WITH_DAY } from 'src/utils/dateUtils'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  description: moment(input.period.startDate)
    .utc()
    .format(DATE_FORMAT_WITH_DAY),
  datePickerComponent: LeaveDateSinglePicker,
  datePickerKeyDate: 'dueDate' as DatePickerKeyDate,
  timeViewProps: {
    ...baseConfig.timeViewProps,
    iconName: 'due_date_baby_head' as IIconName
  },
  datePickerFooterInfoMessage: (t: any) =>
    isRotatingSchedule(input.periods)
      ? t('timeline.datePickerAlerts.resetNotice.text')
      : null
})
