import React, { useCallback, useContext, useMemo, useState } from 'react'
import useAnalytics from 'src/graphql/hooks/useAnalytics'
import HrAdminCard from 'src/features/HrAdmin/components/Card'
import Layout from 'src/features/HrAdmin/components/Layout'
import BlockContentValue from 'src/features/HrAdmin/components/BlockContentValue'
import HrAdminBlock from 'src/features/HrAdmin/components/Block'
import { useTranslation } from 'react-i18next'
import {
  queryAnalyticsManagerSupportActivationChart,
  queryAnalyticsManagerSupportActivationNumbers
} from 'src/graphql/queries'
import styled from 'styled-components'
import LoadingSpinner from 'src/components/LoadingSpinner'
import UsersChartResults from 'src/features/HrAdmin/components/UsersChartResults'
import ScreenContext from 'src/contexts/ScreenContext'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ManagerSupportActivation = () => {
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const [selectedFilterIndex, setSelectedFilterIndex] = useState<number>(0)
  const { selectedAnalyticsType, selectedCountryCode }: IHrAdminContext =
    useContext(HrAdminContext)

  const { analytics: analyticsChart, loading: loadingChart } = useAnalytics({
    query: queryAnalyticsManagerSupportActivationChart,
    type: selectedAnalyticsType,
    kind: 'ManagerActivations',
    countryCode: selectedCountryCode,
    period: selectedFilterIndex === 0 ? -1 : 4,
    skipsChart: isMobile,
    skipsNumbers: true
  })

  const { analytics: analyticsNumbers, loading: loadingNumbers } = useAnalytics(
    {
      query: queryAnalyticsManagerSupportActivationNumbers,
      kind: 'ManagerActivations',
      type: selectedAnalyticsType,
      countryCode: selectedCountryCode,
      period: -1,
      skipsChart: true
    }
  )

  const getNumberBlockView = useCallback(
    (title: string, value: string, isValueBlue: boolean) => (
      <HrAdminBlock title={title}>
        <BlockContentValue
          value={value}
          showsPlaceholder={value === '-1'}
          appearance={isValueBlue ? 'blueValue' : 'default'}
        />
      </HrAdminBlock>
    ),
    []
  )

  const filters = useMemo(() => {
    if (isMobile) {
      return null
    }

    return [
      {
        options: [
          t('hrAdmin.lastTwelveMonths'),
          t('hrAdmin.lastNumWeeks', { weeks: 4 })
        ],
        selectedIndex: selectedFilterIndex,
        onOptionSelected: (index: number) => {
          setSelectedFilterIndex(index)
        }
      }
    ]
  }, [t, selectedFilterIndex, setSelectedFilterIndex, isMobile])

  return useMemo(
    () => (
      <HrAdminCard
        title={t('hrAdmin.analytics.managerSupport.title')}
        description={t('hrAdmin.analytics.managerSupport.description')}
        filters={filters}
        rightLabel={t('hrAdmin.allRecords')}
      >
        <ContentContainer>
          <Layout minBlockWidth={400}>
            {loadingChart ? (
              <SpinnerContainer>
                <LoadingSpinner fadesIn fullScreen={false} />
              </SpinnerContainer>
            ) : isDesktop ? (
              <UsersChartResults
                data={analyticsChart.chart.data}
                numberType={'managerActivations'}
              />
            ) : null}
          </Layout>
          <Layout minBlockWidth={100}>
            {loadingNumbers ? (
              <SpinnerContainer>
                <LoadingSpinner fadesIn fullScreen={false} />
              </SpinnerContainer>
            ) : (
              <>
                {getNumberBlockView(
                  t('hrAdmin.analytics.labels.managerActivations', {
                    count: analyticsNumbers.numbers.managerActivations
                  }),
                  analyticsNumbers.numbers.managerActivations.toString(),
                  false
                )}
              </>
            )}
          </Layout>
        </ContentContainer>
      </HrAdminCard>
    ),
    [
      getNumberBlockView,
      t,
      filters,
      analyticsChart,
      loadingChart,
      loadingNumbers,
      analyticsNumbers,
      isDesktop
    ]
  )
}

export default ManagerSupportActivation
