import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import zIndex from 'src/constants/zIndex'
import PopoverArrow from 'src/components/Popover/components/Arrow'

interface DropdownOption {
  id: string
  text: string
}

interface IProps {
  onChange?: (value: DropdownOption) => void
  options: DropdownOption[]
  selected?: DropdownOption
  containerRef?: any
}

const Container = styled.div`
  display: flex;
  z-index: ${zIndex.dropDown.wrapper};
  width: 100%;
`

const SelectWrapper = styled.button<{ $isOpen: boolean }>`
  background: ${props => props.theme.colors.light100};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: all 100ms;
  overflow: ${props => (props.$isOpen ? `none` : `hidden`)};
`

export const PlaceholderWrapper = styled.span`
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: 100%;
  z-index: ${zIndex.dropDown.placeholderWrapper};
  position: relative;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.colors.dark60};
`

export const OptionsContainerWrapper = styled.div<any>`
  background: ${props => props.theme.colors.light100};
  z-index: ${zIndex.dropDown.optionsContainer};
  position: absolute;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  left: -1px;
  border-radius: 6px;
  margin-top: 41px;
  top: -1px;
`

const OptionsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  border: 1px solid ${props => props.theme.colors.dark50};
`

const OptionWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  color: ${props =>
    props.selected ? props.theme.colors.light100 : props.theme.colors.dark80};
  background: ${props =>
    props.selected ? props.theme.colors.main100 : props.theme.colors.light100};
  white-space: nowrap;
  padding: 12px 24px 12px 12px;
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  text-align: left;
  outline: none;

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

const PopoverArrowWrapper = styled(PopoverArrow)`
  align-self: center;
  cursor: pointer;
  z-index: ${zIndex.dropDown.arrow};
`

export const dataAttrs = {
  dropdown: () => 'absence-select-dropdown',
  option: () => 'absence-select-option'
}

export const AbsenceSelect = React.memo((props: IProps) => {
  const { options, selected, containerRef } = props
  const [isOpen, setIsOpen] = useState(false)

  const onClick = useCallback((): any => setIsOpen(!isOpen), [isOpen])

  const onChange = useCallback(
    (option: DropdownOption) => props.onChange(option),
    [props]
  )

  const close = useCallback(() => isOpen && setIsOpen(false), [isOpen])

  const optionsView: ReactNode = useMemo(
    () =>
      options.map((opt, index) => (
        <OptionWrapper
          data-testid={dataAttrs.option()}
          key={index}
          value={opt === selected}
          selected={opt === selected}
          onClick={() => {
            onChange(opt)
          }}
          onKeyDown={(event: SyntheticEvent) => {
            const { keyCode } = event as any
            if (keyCode === 13 || keyCode === 32) {
              onChange(opt)
            }
          }}
          tabIndex={0}
        >
          {opt.text}
        </OptionWrapper>
      )),
    [onChange, options, selected]
  )

  const optionsContainerView: ReactNode = useMemo(
    () => (
      <OptionsContainerWrapper>
        <OptionsContainer>{optionsView}</OptionsContainer>
      </OptionsContainerWrapper>
    ),
    [optionsView]
  )

  const textView: ReactNode = useMemo(
    () => <PlaceholderWrapper>{selected?.text}</PlaceholderWrapper>,
    [selected]
  )

  const arrowView: ReactNode = useMemo(
    () => <PopoverArrowWrapper isOpen={isOpen} pointerEvents={'none'} />,
    [isOpen]
  )

  useImperativeHandle(containerRef, () => ({ close }))

  return (
    <Container
      ref={containerRef}
      data-testid={dataAttrs.dropdown()}
      onClick={onClick}
    >
      <SelectWrapper $isOpen={isOpen}>
        {textView}
        {isOpen && optionsContainerView}
      </SelectWrapper>
      {arrowView}
    </Container>
  )
})

AbsenceSelect.displayName = 'AbsenceSelect'

export default AbsenceSelect
