import React, { ReactNode } from 'react'
import { ws } from 'src/utils/workflow'
import SelectSignInType from './components/SelectSignInType'
import GetPersonalEmail from './components/GetPersonalEmail'
import CodeConfirmation from './components/CodeConfirmation'
import CodeExpired from './components/CodeExpired'
import TooManyAttempts from './components/TooManyAttempts'
import Maintenance from './components/Maintenance'
import NotAvailable from './components/NotAvailable'

export type SignInStage =
  | 'selectSignInType'
  | 'providePersonalEmail'
  | 'codeConfirmation'
  | 'codeExpired'
  | 'tooManyAttempts'
  | 'maintenance'
  | 'notAvailable'

export const getWorkflow = () =>
  ws<SignInStage>([
    'selectSignInType',
    'providePersonalEmail',
    'codeConfirmation'
  ])

export const getCurrentStageView = (stage: SignInStage): ReactNode => {
  switch (stage) {
    case 'selectSignInType':
      return <SelectSignInType name={stage} />
    case 'providePersonalEmail':
      return <GetPersonalEmail name={stage} />
    case 'codeConfirmation':
      return <CodeConfirmation name={stage} />
    case 'codeExpired':
      return <CodeExpired />
    case 'tooManyAttempts':
      return <TooManyAttempts />

    case 'maintenance':
      return <Maintenance />
    case 'notAvailable':
      return <NotAvailable />
    default:
      return null
  }
}
