import {
  ISurveyResults,
  ISurveyResultsResponse,
  ISurveySummary,
  SurveyNpsScaleFieldType
} from 'src/graphql/hooks/useSurveySummaries'

const calcTotalResponsesCount = (obj: ISurveyResults) => {
  obj.totalResponsesCount = obj.responses.reduce(
    (accumulator: number, currentValue: ISurveyResultsResponse) =>
      accumulator + currentValue.value,
    0
  )
}

const addScaleField = (
  obj: ISurveyResults,
  fieldType: SurveyNpsScaleFieldType,
  start: number,
  length: number
) => {
  const getOrder = (type: SurveyNpsScaleFieldType): number => {
    switch (type) {
      case 'promoter':
        return 1
      case 'passive':
        return 2
      case 'detractor':
        return 3
    }
  }

  let value = 0

  for (let i = start; i < start + length; i++) {
    value += obj.responses[i].value
  }

  obj.npsScaleFields.push({
    startIndex: start,
    length,
    type: fieldType,
    value,
    order: getOrder(fieldType)
  })
}

const setNpsScaleFields = (obj: ISurveyResults) => {
  addScaleField(obj, 'detractor', 0, 7)
  addScaleField(obj, 'passive', 7, 2)
  addScaleField(obj, 'promoter', 9, 2)
}

const calcNpsScore = (obj: ISurveyResults) => {
  obj.npsScore =
    (obj.npsScaleFields[2].value * 100) / obj.totalResponsesCount -
    (obj.npsScaleFields[0].value * 100) / obj.totalResponsesCount
}

const getResultValue = (d: any[], key: string): number => {
  for (const p of d) {
    if (p.response === key) {
      return p.amount
    }
  }

  return 0
}

const parseSurveySummaryResults = (
  summary: ISurveyQuestionSummary
): ISurveyResults => {
  const {
    surveyQuestion: { text, description, chart, responses },
    data: surveyData
  } = summary
  const obj: ISurveyResults = {
    text,
    chart,
    description,
    totalResponsesCount: 0,
    npsScaleFields: [],
    npsScore: 0,
    responses: responses.map((response: string) => ({
      label: response,
      value: getResultValue(surveyData, response)
    }))
  }

  calcTotalResponsesCount(obj)

  if (chart === 'NetScore') {
    setNpsScaleFields(obj)
    calcNpsScore(obj)
  }

  return obj
}

const parseSurveySummary = (
  surveySummary: IAggregatedSurveySummary
): ISurveySummary => ({
  survey: surveySummary.survey,
  results: surveySummary.summary.map((summary: ISurveyQuestionSummary) =>
    parseSurveySummaryResults(summary)
  )
})

export const getSurveySummaries = (
  surveySummaries: IAggregatedSurveySummary[]
): ISurveySummary[] =>
  surveySummaries.map((surveySummary: IAggregatedSurveySummary) =>
    parseSurveySummary(surveySummary)
  )

export const forTests = {
  parseSurveySummary,
  parseSurveySummaryResults,
  getResultValue,
  calcNpsScore,
  setNpsScaleFields,
  addScaleField,
  calcTotalResponsesCount
}
