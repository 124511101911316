import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import {
  IGetPeriodConfigInput,
  ITimelinePeriodConfig,
  PeriodViewForPeriod
} from '../../../index'

const withoutLeaveStartTypes = ['PartnerPregnancy', 'Adoption', 'Foster']

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isWithoutLeaveStart = withoutLeaveStartTypes.includes(input.leave.type)
  return {
    ...baseConfig,
    datePickerComponent: isWithoutLeaveStart
      ? MultiDatePicker
      : LeaveDatePeriodPicker,
    datePickerViewForPeriod: isWithoutLeaveStart
      ? baseConfig.datePickerViewForPeriod
      : ('endDate' as PeriodViewForPeriod),
    detailsViewForPeriod: isWithoutLeaveStart
      ? baseConfig.detailsViewForPeriod
      : ('endDate' as PeriodViewForPeriod),
    datePickerFooterInfoMessage: (t: any, leave: ILeave) => {
      const title = t('timeline.datePickerAlerts.unused.title')
      const text = t('timeline.datePickerAlerts.unused.text')
      return leave?.unusedBonding?.unusedDays > 0
        ? [title, text].join('  \n') // Markdown text needs two space in the separator
        : null
    },
    footerDatePickerTitle: isWithoutLeaveStart
      ? input.t('timeline.datePickerTitleByPeriod.ChangeDates')
      : baseConfig.footerDatePickerTitle,
    periodKeyDate: 'periodEnd'
  }
}
