import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import FilterCheckbox from 'src/components/JourneyMap/Filters/Checkbox'
import Container from '../RowContainer'

interface IProps {
  className?: string
  checked: boolean
  disabled: boolean
  onClick: (index: number) => void
  index: number
  type: string
}

const Label = styled.span<{ $hovered?: boolean }>`
  font-weight: normal;
  line-height: 50%;
  color: ${props => props.theme.colors.dark60};
  user-select: none;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          margin: 0 0 0 8px;
        `
      : css`
          font-size: 14px;
          margin: 0;
        `}

  ${props => {
    if (props.$hovered && props.theme.isDesktop) {
      return css`
        transform: translateX(2px);
      `
    }
  }}
`

const PeriodRow = React.memo(
  (props: IProps) => {
    const { className, checked, onClick, index, disabled, type } = props
    const { t } = useTranslation()

    const ariaLabel: string = useMemo(
      () =>
        t(`journeyMapPhases.${type}`) +
        ' ' +
        t('common.phase').toLocaleLowerCase(),
      [t, type]
    )

    return (
      <Container
        className={className}
        onClick={onClick}
        disabled={disabled}
        index={index}
      >
        <Label aria-hidden>{t(`journeyMapPhases.${type}`)}</Label>
        <FilterCheckbox
          checked={checked}
          index={index}
          disabled={disabled}
          ariaLabel={ariaLabel}
        />
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.onClick === nextProps.onClick &&
    prevProps.checked === nextProps.checked &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.type === nextProps.type &&
    prevProps.index === nextProps.index
)

PeriodRow.displayName = 'PeriodRow'

export default PeriodRow
