import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon, MarkdownText } from 'src/UIKit'
import {
  getPeriodDurationTextComponents,
  IDuration
} from 'src/utils/leaveUtils'
import createTheme from 'src/theme'
import Popover from 'src/components/Popover'
import ContextMenuView, {
  ContextMenuViewItemType
} from 'src/features/UserProfile/components/PlanHistory/components/ContextMenuView'
import moment, { Moment } from 'moment'
import ScreenContext from 'src/contexts/ScreenContext'
import { DATE_FORMAT } from 'src/utils/dateUtils'

const { colors } = createTheme()

interface IProps {
  className?: string
  plan: ILeave
  onRemovePlan: (id: string) => void
}

const Container = styled.li`
  user-select: none;
  display: flex;
  align-items: center;
  ${props =>
    props.theme.isDesktop
      ? css`
          height: 78px;
          width: 640px;
          margin: 8px 0 0;
          background: ${props.theme.colors.background};
          border: 1px solid ${props.theme.colors.dark05};
          box-sizing: border-box;
          box-shadow: 0 0 10px rgb(0 0 0 / 5%);
          border-radius: 8px;

          &:first-child {
            margin: 0;
          }
        `
      : css`
          max-height: 66px;
          width: 100%;
          border-bottom: 1px solid ${props.theme.colors.main10};

          &:last-child {
            border: none;
          }
        `}
`

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 56px;
        `
      : css`
          width: 24px;
          margin: 0 12px;
        `}
`

const TextsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${props =>
    props.theme.isDesktop
      ? css`
          height: calc(100% - 32px);
        `
      : css`
          height: calc(100% - 30px);
          gap: 4px;
          padding: 12px 0;
        `}
`

const Title = styled.span`
  font-weight: 500;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
        `}
`

const Dates = styled.span`
  line-height: 100%;
  font-weight: 400;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 11px;
        `}
`

const StatusText = styled(MarkdownText)`
  line-height: 150%;
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  background: ${props => props.theme.colors.light100};

  bold: {
    font-weight: 500;
    font-size: 20px;
    color: ${props => props.theme.colors.dark60};
  }

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
          border: 1px solid ${props.theme.colors.dark05};
          box-sizing: border-box;
          border-radius: 16px;
          padding: 4px 10px;
        `
      : css`
          font-size: 11px;
        `}
`

const ContextMenuButton = styled.button`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 16px;
        `
      : css`
          margin: 0 8px;
        `}
  position: relative;
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 4px;

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main100};
  }
`

const ContextMenu = styled(Icon)<any>`
  display: flex;
  align-items: center;
  cursor: ${(props: any) => (props.clickable ? 'pointer' : 'unset')};
`

const PlansListItem = React.memo((props: IProps) => {
  const {
    className,
    plan: { id, type: leaveType, dates },
    onRemovePlan
  } = props
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const leaveStart: Moment = useMemo(
    () => moment(dates.leaveStart.current).utc(),
    [dates]
  )
  const leaveEnd: Moment = useMemo(
    () => moment(dates.leaveEnd.current).utc(),
    [dates]
  )

  const startDateString: string = useMemo(
    () => leaveStart.format(DATE_FORMAT),
    [leaveStart]
  )
  const endDateString: string = useMemo(
    () => leaveEnd.format(DATE_FORMAT),
    [leaveEnd]
  )

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(true)

  const popoverRef: any = useRef(null)
  const contextMenuRef: any = useRef(null)

  const datesString: string = useMemo(
    () =>
      t('planHistory.datesRange', {
        startDate: startDateString,
        endDate: endDateString
      }),
    [t, startDateString, endDateString]
  )

  const statusText: string = useMemo(() => {
    const { components }: IDuration = getPeriodDurationTextComponents(
      leaveEnd.add(1, 'second').diff(leaveStart, 'day'),
      t
    )

    let result = `**${components[0].value}** ${components[0].duration}`
    if (components.length > 1 && components[1].value !== 0) {
      result += ` **${components[1].value}** ${components[1].duration}`
    }

    return result
  }, [leaveEnd, leaveStart, t])

  const onContextMenuClick = useCallback(() => {
    if (popoverRef.current) {
      popoverRef.current.toggle()
    }
  }, [popoverRef])

  const popoverView = useMemo(
    () => (
      <Popover
        parent={contextMenuRef.current}
        ref={popoverRef}
        afterStateChanged={(value: boolean) => {
          setIsContextMenuOpen(value)
        }}
        offset={{
          top: isDesktop ? -6 : -116,
          left: isDesktop ? -34 : 0
        }}
      >
        <ContextMenuView
          items={[{ type: 'remove' }]}
          onItemClick={(type: ContextMenuViewItemType) => {
            if (popoverRef.current) {
              popoverRef.current.toggle()
            }

            if (type === 'remove') {
              onRemovePlan(id)
            }
          }}
        />
      </Popover>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contextMenuRef,
      popoverRef,
      isContextMenuOpen,
      setIsContextMenuOpen,
      isDesktop
    ]
  )

  const title: string = useMemo(
    () => t(`planHistory.leaveTypeToTitle.${leaveType}`),
    [leaveType, t]
  )

  const ariaLabel: string = useMemo(
    () =>
      t('common.accessibilityText.planItem', {
        type: title,
        startDate: startDateString,
        endDate: endDateString
      }),
    [startDateString, endDateString, title, t]
  )

  return (
    <Container className={className} aria-label={ariaLabel}>
      <IconContainer>
        <Icon name={'file'} settings={{ fill: colors.main100 }} />
      </IconContainer>
      <TextsContainer aria-hidden>
        <Title>{title}</Title>
        <Dates>{datesString}</Dates>
      </TextsContainer>
      <div aria-hidden>
        <StatusText>{statusText}</StatusText>
      </div>
      <ContextMenuButton
        onClick={onContextMenuClick}
        ref={contextMenuRef}
        aria-haspopup
        aria-label={t('common.accessibilityText.contextMenu')}
        aria-expanded={isContextMenuOpen}
      >
        <ContextMenu
          name={isDesktop ? 'context_menu' : 'context_submenu'}
          settings={{ fill: colors.main100 }}
        />
        {popoverView}
      </ContextMenuButton>
    </Container>
  )
})

PlansListItem.displayName = 'PlansListItem'

export default PlansListItem
