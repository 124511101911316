import moment from 'moment'

interface MonthsHolidays {
  [month: number]: Set<number>
}

interface YearsHoliday {
  [year: number]: MonthsHolidays
}

/**
 * Class that represents a collection of leave holidays as a tree.
 * @example
 *
 * const holidays = [
 *   { observed: "2022-01-01" },
 *   { observed: "2022-12-25" },
 *   { observed: "2022-12-31" }
 * ];
 *
 * const leaveHolidays = new LeaveHolidays(holidays)
 *
 * const date = new Date("2022-01-01")
 * console.log(leaveHolidays.isHoliday(date)) // true
 *
 * const yearHolidays = leaveHolidays.forYear(2022)
 * console.log(yearHolidays); // [{ 0: Set { 1, 25, 31 } }]
 *
 * const clone = leaveHolidays.clone()
 * console.log(clone.holidays === leaveHolidays.holidays) // false
 */
export default class LeaveHolidays {
  private holidays: YearsHoliday

  /**
   * Creates an instance of LeaveHolidays.
   * @param list List of holidays to be added to the class
   */
  public constructor(list: IHoliday[]) {
    this.holidays = {} as YearsHoliday
    this.add(list)
  }

  /**
   * Checks if the given date is a holiday.
   * @param date Date to be checked
   * @returns True if the date is a holiday, False otherwise
   */
  public isHoliday(date: Date) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    return this.holidays[year]?.[month]?.has(day)
  }

  /**
   * Gets all holidays for the given year.
   * @param year Year for which holidays should be retrieved
   * @returns Holidays for the given year
   */
  public forYear(year: number) {
    return this.holidays[year]
  }

  /**
   * Adds a list of holidays to the class.
   * @param list List of holidays to be added
   */
  public add(list: IHoliday[]) {
    list.forEach((h: IHoliday) => {
      const date = moment(h.observed).utc()
      const y = date.year()
      const m = date.month()
      const d = date.date()

      if (!this.holidays[y]) {
        this.holidays[y] = {} as MonthsHolidays
      }

      if (!this.holidays[y][m]) {
        this.holidays[y][m] = new Set<number>()
      }

      this.holidays[y][m].add(d)
    })
  }

  /**
   * Clones the current instance of LeaveHolidays.
   * @returns A new instance of LeaveHolidays
   */
  public clone(): LeaveHolidays {
    const leave = new LeaveHolidays([])
    leave.holidays = this.holidays
    return leave
  }
}
