import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IHint } from 'src/react-app-env'
import styled, { css } from 'styled-components'
import PeriodHintView from './components/PeriodHintView'

interface IProps {
  hints: IHint[]
}

const KnowMoreText = styled.span`
  display: block;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 20px;
          line-height: 23px;
          margin: 8px 0 8px 12px;
        `
      : css`
          font-size: 16px;
          line-height: 19px;
          margin: 8px 0 0;
        `}
`

const PeriodHintsView = React.memo((props: IProps) => {
  const { hints } = props
  const { t } = useTranslation()

  const [openedHint, setOpenedHint] = useState<string>(null)

  if (!hints.length) {
    return null
  }

  return (
    <div>
      <KnowMoreText>{t('common.answersToCommonQuestions')}</KnowMoreText>
      <ul>
        {hints.map((data: IHint) => (
          <PeriodHintView
            key={data.title}
            title={data.title}
            body={data.body}
            opened={data.body === openedHint}
            onClick={() => {
              if (openedHint === data.body) {
                setOpenedHint(null)
              } else {
                setOpenedHint(data.body)
              }
            }}
          />
        ))}
      </ul>
    </div>
  )
})

PeriodHintsView.displayName = 'PeriodHintsView'

export default PeriodHintsView
