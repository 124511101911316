import React from 'react'
import { IPriorityFilter } from 'src/components/JourneyMap/Filters'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import FiltersTitle from '../FiltersTitle'
import FilterRow from '../PriorityRow'

interface IProps {
  className?: string
  filters: IPriorityFilter[]
  onFilterClick: (index: number) => void
  title?: string
}

const Container = styled.div`
  width: 100%;
`

const FiltersPriority = React.memo(
  (props: IProps) => {
    const { filters, onFilterClick, title, className } = props
    const { t } = useTranslation()

    return (
      <Container
        className={className}
        role={'region'}
        aria-label={t(
          'common.accessibilityText.regionJourneyFiltersByPriority'
        )}
      >
        {title && <FiltersTitle>{title}</FiltersTitle>}
        {filters &&
          filters.map((filter: IPriorityFilter, index: number) => (
            <FilterRow
              key={filter.priority}
              priority={filter.priority}
              index={index}
              checked={filter.checked}
              onClick={onFilterClick}
              disabled={filter.disabled}
            />
          ))}
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.filters === nextProps.filters
)

FiltersPriority.displayName = 'FiltersPriority'

export default FiltersPriority
