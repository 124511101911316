import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import { IIconProps } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'

const { colors } = createTheme()

interface IProps {
  isUnused: boolean
  jobProtectedText: string
  jobProtected: boolean
}

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  right: 4px;
  top: 2px;
`

const Text = styled.span<{ $jobProtected: boolean; $color: string }>`
  line-height: 130%;
  color: ${props => props.$color};
  margin-top: ${props => (!props.$jobProtected ? '1px' : '0')};
  font-size: 12px;
  margin-right: 8px;
`

const JobProtectedView = React.memo(
  (props: IProps) => {
    const { isDesktop } = useContext(ScreenContext)
    const { isUnused, jobProtected, jobProtectedText } = props

    const jobProtectedColor: string = useMemo(
      () => (isUnused ? colors.timelineIncomeLite : colors.light100),
      [isUnused]
    )

    const jobProtectedIconSettings: IIconProps = useMemo(
      () =>
        isDesktop
          ? {
              width: jobProtected ? '12' : '15',
              height: '15',
              fill: jobProtectedColor
            }
          : {
              width: jobProtected ? '10' : '12',
              height: '12',
              fill: jobProtectedColor
            },
      [jobProtected, jobProtectedColor, isDesktop]
    )

    return (
      <Container>
        <Text $color={jobProtectedColor} $jobProtected={jobProtected}>
          {jobProtectedText}
        </Text>
        <Icon
          name={jobProtected ? 'job_protected' : 'job_unprotected'}
          settings={jobProtectedIconSettings}
          ariaLabel={jobProtectedText}
        />
      </Container>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

JobProtectedView.displayName = 'JobProtectedView'

export default JobProtectedView
