import React from 'react'
import styled from 'styled-components'

interface IProps {
  children: any
  className?: any
}

const Wrapper = styled.div`
  background: ${props => props.theme.colors.light100};
  box-sizing: border-box;
  box-shadow: 0 0 12px rgb(0 0 0 / 8%);
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.dark05};
  overflow: hidden;
`

const PopoverBackground = React.memo((props: IProps) => {
  const { className, children } = props

  return <Wrapper className={className}>{children}</Wrapper>
})

PopoverBackground.displayName = 'PopoverBackground'

export default PopoverBackground
