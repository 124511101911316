import React from 'react'
import styled, { css } from 'styled-components'
import isEqual from 'lodash.isequal'

interface IProps {
  children: string
  isUnused: boolean
}

const Text = styled.span<{ $isUnused: boolean }>`
  align-self: flex-start;
  font-weight: 500;
  line-height: 130%;
  color: ${props =>
    props.$isUnused
      ? props.theme.colors.timelineIncome
      : props.theme.colors.light100};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 14px;
          margin-top: -2px;
        `}

  @media print {
    font-size: 16px;
  }
`

const DurationView = React.memo(
  (props: IProps) => {
    const { isUnused, children } = props

    return <Text $isUnused={isUnused}>{children}</Text>
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

DurationView.displayName = 'DurationView'

export default DurationView
