import React, { useCallback, useState } from 'react'
import { TpaApprovalState } from 'src/features/Leave/context'
import styled from 'styled-components'
import zIndex from 'src/constants/zIndex'
import TpaApprovedView from 'src/features/Leave/components/TpaApprovedView/index'
import { header } from 'src/constants/frame'

interface IProps {
  onNext: (state: TpaApprovalState) => void
  leaveType: string
  onBack: () => void
}

const Container = styled.div`
  position: absolute;
  z-index: ${zIndex.timeline.tpaApprovedView};
  inset: ${p => header.height(p.theme).px} 0 0 0;
  background: ${props => props.theme.colors.light100};
  display: flex;
  justify-content: center;
`

export const TimelineTpaApprovedViewContainer = React.memo((props: IProps) => {
  const { onNext, onBack, leaveType } = props

  const [tpaApprovalState, setTpaApprovalState] =
    useState<TpaApprovalState>('none')

  const onTpaApprovalStateChange = useCallback(
    (state: TpaApprovalState) => {
      setTpaApprovalState(state)
    },
    [setTpaApprovalState]
  )

  const onNextClick = useCallback(() => {
    onNext(tpaApprovalState)
  }, [onNext, tpaApprovalState])

  return (
    <Container>
      <TpaApprovedView
        leaveType={leaveType}
        tpaApprovalState={tpaApprovalState}
        onBack={onBack}
        onNext={onNextClick}
        onOptionSelected={onTpaApprovalStateChange}
      />
    </Container>
  )
})

TimelineTpaApprovedViewContainer.displayName =
  'TimelineTpaApprovedViewContainer'

export default TimelineTpaApprovedViewContainer
