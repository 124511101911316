import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import InfoMessageDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/InfoMessageDatePicker'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const leaveDescriptionKey = `${input.period.type}_${input.leave.type}`
  const isMiscarriage = input.leave.type === 'Miscarriage'

  return {
    ...baseConfig,
    datePickerComponent: isMiscarriage
      ? InfoMessageDatePicker
      : LeaveDatePeriodPicker,
    periodPickerInfoMessage: (t: any) =>
      t(`timeline.leaveDurationPicker.${leaveDescriptionKey}`),
    detailsType: isMiscarriage ? leaveDescriptionKey : baseConfig.detailsType
  }
}
