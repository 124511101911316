import React, { useCallback, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import UserProfileContext, {
  IUserProfileContext
} from 'src/features/UserProfile/Context'
import PlansListItem from 'src/features/UserProfile/components/PlanHistory/components/PlansListItem'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
}

const Container = styled.ul`
  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      border: 1px solid ${props.theme.colors.dark05};
      border-radius: 8px;
    `}
`

const PlansList = React.memo((props: IProps) => {
  const { className } = props
  const { planHistory, removeLeave } =
    useContext<IUserProfileContext>(UserProfileContext)
  const { t } = useTranslation()

  const onRemovePlan = useCallback(
    (planId: string) => {
      removeLeave(planId)
    },
    [removeLeave]
  )

  return useMemo(
    () => (
      <Container
        className={className}
        role={'list'}
        aria-label={t('common.accessibilityText.regionPlansHistoryList')}
      >
        {planHistory.map((plan: ILeave) => (
          <PlansListItem
            key={plan.id}
            plan={plan}
            onRemovePlan={onRemovePlan}
          />
        ))}
      </Container>
    ),
    [planHistory, className, onRemovePlan, t]
  )
})

PlansList.displayName = 'PlansList'

export default PlansList
