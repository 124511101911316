import React, { useState, useEffect } from 'react'
import RouteContext from 'src/routes/Context'
import createTheme from 'src/theme'
import * as RouteUtils from 'src/utils/routeUtils'
import { useLocation, Location } from 'react-router-dom'

const theme: any = createTheme()

const WithRouteContextWrapper = (props: any) => {
  const { children } = props
  const location = useLocation()
  const [isManagerRoute, setIsManagerRoute] = useState<boolean>(
    RouteUtils.isManagerRoute(window.location.pathname)
  )
  const [isHrAdminRoute, setIsHrAdminRoute] = useState<boolean>(
    RouteUtils.isHrAdminRoute(window.location.pathname)
  )

  useEffect(() => {
    const onRouteChanged = (l: Location) => {
      setIsManagerRoute(RouteUtils.isManagerRoute(l.pathname))
      setIsHrAdminRoute(RouteUtils.isHrAdminRoute(l.pathname))
    }

    onRouteChanged(location)
  }, [location])

  let primaryColor: string = theme.colors.main100
  if (isManagerRoute || isHrAdminRoute) {
    primaryColor = theme.colors.sub100
  }

  return (
    <RouteContext.Provider
      value={{
        isManagerRoute,
        isHrAdminRoute,
        primaryColor,
        notificationType: isManagerRoute ? 'Manager' : 'Employee'
      }}
    >
      {children}
    </RouteContext.Provider>
  )
}

WithRouteContextWrapper.displayName = 'WithRouteContextWrapper'

export default WithRouteContextWrapper
