import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import NumStepper from 'src/components/NumStepper'
import { MarkdownText } from 'src/UIKit'

interface IProps {
  className?: string
  title: string
  minWeeks: number
  maxWeeks: number
  valid: boolean
  days: number
  onChanged: (weeks: number) => void
  editable: boolean
}

const Container = styled.div`
  user-select: none;
`

const Title = styled.span`
  display: block;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  margin: 0 0 15px;
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  width: 142px;
`

const WeeksText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
  margin-left: 8px;
`

const DurationText = styled(MarkdownText)`
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};

  strong {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.dark80};
  }
`

export const dataAttrs = {
  durationText: () => 'period-duration-text'
}

const PeriodDurationPicker = React.memo((props: IProps) => {
  const {
    className,
    title,
    minWeeks,
    maxWeeks,
    onChanged,
    editable,
    days,
    valid
  } = props
  const { t } = useTranslation()

  const weeks: number = useMemo(() => Math.max(Math.floor(days / 7), 1), [days])

  const numStepper: ReactNode = useMemo(
    () =>
      editable && (
        <NumStepper
          minValue={minWeeks}
          maxValue={valid ? maxWeeks : weeks}
          onValueChanged={onChanged}
          value={weeks}
        />
      ),
    [maxWeeks, onChanged, editable, weeks, valid, minWeeks]
  )

  const weeksText: ReactNode = useMemo(
    () =>
      editable && (
        <WeeksText aria-hidden>
          {t('common.durations.week', {
            count: weeks
          })}
        </WeeksText>
      ),
    [weeks, t, editable]
  )

  const durationString: string = useMemo(() => {
    if (editable) {
      return null
    }
    const w: number = Math.floor(days / 7)
    const d: number = days - w * 7
    const strW = `${t('common.durations.weekValueBold', {
      count: w
    })}`
    const strD: string =
      d !== 0
        ? `${t('common.durations.dayValueBold', {
            count: d
          })}`
        : ''
    return `${strW} ${strD}`
  }, [editable, days, t])

  const ariaLabel: string = useMemo(
    () =>
      durationString
        ? t('timeline.periodPicker.regionLeaveDurationWithValue', {
            duration: durationString.replace(/\*/g, '')
          })
        : t('timeline.periodPicker.regionLeaveDuration'),
    [durationString, t]
  )

  return (
    <Container className={className} role={'region'} aria-label={ariaLabel}>
      <Title aria-hidden>{title}</Title>
      <BottomContainer>
        {numStepper}
        {weeksText}
        <div aria-hidden data-testid={dataAttrs.durationText()}>
          <DurationText>{durationString}</DurationText>
        </div>
      </BottomContainer>
    </Container>
  )
})

PeriodDurationPicker.displayName = 'PeriodDurationPicker'

export default PeriodDurationPicker
