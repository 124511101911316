export const getLayoutRule = (
  containerWidth: number,
  minItemWidth: number,
  itemsCount: number
): number[] => {
  if (itemsCount === 1) {
    return [1]
  }

  let result: number[] = [0]
  let maxItemsPerRow: number = itemsCount
  const isEvenItemsCount: boolean = itemsCount % 2 === 0

  if (isEvenItemsCount) {
    let rowsCount: number = Math.ceil(
      (minItemWidth * itemsCount) / containerWidth
    )

    while (itemsCount % rowsCount !== 0) {
      rowsCount++
      if (rowsCount >= itemsCount) {
        rowsCount = itemsCount
        break
      }
    }

    maxItemsPerRow = itemsCount / rowsCount

    result = []
    for (let i = 0; i < rowsCount; i++) {
      result.push(maxItemsPerRow)
    }

    return result
  }

  let resetAttempts = 3
  let itemsRemained: number = itemsCount

  while (itemsRemained > 0) {
    itemsRemained--
    let itemAdded = false
    for (let j = 0; j < result.length; j++) {
      if (
        result[j] < maxItemsPerRow &&
        containerWidth - result[j] * minItemWidth > minItemWidth
      ) {
        result[j] += 1
        itemAdded = true
        break
      }
    }

    if (!itemAdded) {
      if (
        resetAttempts > 0 &&
        result.length === 1 &&
        maxItemsPerRow > itemsCount / 2 + 1
      ) {
        resetAttempts--
        maxItemsPerRow = Math.ceil(maxItemsPerRow / 2 + 1)
        result = [0]
        itemsRemained = itemsCount
      } else {
        result.push(1)
        maxItemsPerRow = itemsRemained % 2 === 0 ? result[0] : result[0] - 1
      }
    }
  }

  return result
}
