import React from 'react'
import styled from 'styled-components'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
  justify-content: space-between;
  padding: 16px;
  z-index: ${zIndex.journeyMap.page.priorityFiltersWrapper};
  color: ${props => props.theme.colors.dark60};
  font-size: 18px;
`

const ArchiveMobileHeader = React.memo(() => {
  const { t } = useTranslation()
  return <Container>{t('managerJourneyMap.plansArchive')}</Container>
})

ArchiveMobileHeader.displayName = 'ArchiveMobileHeader'

export default ArchiveMobileHeader
