import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'

const { colors } = createTheme()

interface IProps {
  className?: string
  completed: boolean
  disabled?: boolean
  onClick?: () => void
}

const CheckWrapper = styled.button<{ $completed: boolean; $disabled: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms;
  border: 1px solid ${props => props.theme.colors.main100};
  ${props =>
    props.$disabled &&
    css`
      border: 1px solid ${props.theme.colors.main40};
      cursor: unset;
    `}

  ${props =>
    props.$completed &&
    css`
      background: ${props.$disabled
        ? props.theme.colors.main40
        : props.theme.colors.main100};
    `}

  ${props =>
    !props.$disabled &&
    css`
      &:hover {
        background: ${props.$completed
          ? props.theme.colors.main110
          : 'transparent'};
        border: 1px solid ${props.theme.colors.main110};
        ${() =>
          !props.$completed &&
          css`
            box-shadow: 0 0 0 1px ${props.theme.colors.main110};
          `}
      }

      &:focus {
        background: ${props.$completed
          ? props.theme.colors.main110
          : 'transparent'};
        box-shadow: 0 0 0 3px ${props.theme.colors.main110};
        border: 1px solid ${props.theme.colors.main110};
        outline: none;
      }
    `}
`

const IconWrapper = styled(Icon)`
  padding-top: 3px;
`

const CompletedView = React.memo((props: IProps) => {
  const { className, completed, disabled, onClick } = props
  const containerRef: any = useRef(null)
  const { t } = useTranslation()

  return (
    <CheckWrapper
      className={className}
      $completed={completed}
      $disabled={disabled}
      disabled={disabled}
      onClick={!disabled && onClick ? onClick : (): void => undefined}
      ref={containerRef}
      role={'checkbox'}
      aria-hidden={disabled}
      aria-checked={completed}
      aria-label={t(
        `common.accessibilityText.${
          completed ? 'itemCompleted' : 'itemUncompleted'
        }`
      )}
    >
      {completed && (
        <IconWrapper
          name={'checkmark'}
          settings={{
            fill: colors.light100
          }}
          ariaLabel={t('common.accessibilityText.checkmark')}
        />
      )}
    </CheckWrapper>
  )
})

CompletedView.displayName = 'CompletedView'

export default CompletedView
