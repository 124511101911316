import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon, Link, Button } from 'src/UIKit'
import createTheme from 'src/theme'
import ScreenContext from 'src/contexts/ScreenContext'
import PlanSettingsButton from 'src/components/JourneyMap/PlanSettingsButton'
import DefaultDashboard, { DashboardProps } from './config'
import AbsenceTimeline from '../AbsenceTimeline'
import Box from 'src/components/Box'
import UnclassifiedNote from '../UnclassifiedNote'

const { colors } = createTheme()

interface IProps {
  absence: IAbsence
  onViewTasksClicked: (absence: IAbsence) => void
  onViewPreviousVersionsClicked: (absence: IAbsence) => void
}

const ViewTasksButton = styled(Button)`
  margin: 16px 0;
  width: 100%;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 0;
    `}
`

const ViewPreviousVersionsButton = styled(Link)`
  display: flex;
  font-size: 14px;
`

const MobileButtonContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -webkit-fill-available;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  width: -moz-available;
  width: fill-available;
  padding: 8px;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const IconWrapper = styled(Icon)`
  display: inline-block;
  margin-left: 15px;
`

const PreviousVersionsIconWrapper = styled(Icon)`
  display: inline-block;
  margin-left: 5px;
  width: 16px;
  height: 12px;
`

const ButtonsRow = styled.div`
  margin-right: 16px;
  display: flex;
`

const TilesContainer = styled.div`
  padding-right: 16px;
  display: grid;
  grid-gap: 16px;
  grid-column-gap: 15px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${props => (props.theme.isDesktop ? '220px' : '174px')}, 1fr)
  );

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      gap: 8px;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid ${p => p.theme.colors.dark05};
      box-shadow: 0 0 10px rgb(0 0 0 / 5%);
    `}
`

const TimelineContainer = styled.div`
  min-width: ${props => (props.theme.isDesktop ? '348px' : 'auto')};
  max-width: ${props => (props.theme.isDesktop ? '348px' : 'auto')};
  border: 1px solid ${props => props.theme.colors.dark05};
  border-radius: 8px;
  padding: 16px;

  ${props =>
    props.theme.isMobile &&
    css`
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 8px;
      border-radius: 0;
      box-sizing: border-box;
      border: 1px solid ${p => p.theme.colors.dark05};
      box-shadow: 0 0 10px rgb(0 0 0 / 5%);
    `}
`

const TimelineTitle = styled.p`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 10px;
  padding: 8px 5px;
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
`

const BoxInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const BoxContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const dataAttrs = {
  viewTasksBtn: () => 'manager-dashboard-view-tasks-btn',
  viewPreviousVersionsBtn: () => 'manager-dashboard-view-previous-versions-btn'
}

const DashboardActive = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { absence, onViewTasksClicked, onViewPreviousVersionsClicked } = props

  const tasksButton = useMemo(
    () => (
      <ViewTasksButton
        data-testid={dataAttrs.viewTasksBtn()}
        onClick={() => onViewTasksClicked(absence)}
      >
        {t('common.viewTasks')}
        <IconWrapper
          name={'arrow_expand'}
          settings={{ fill: colors.light100 }}
        />
      </ViewTasksButton>
    ),
    [t, onViewTasksClicked, absence]
  )

  const previousVersionsButton = useMemo(
    () => (
      <ViewPreviousVersionsButton
        data-testid={dataAttrs.viewPreviousVersionsBtn()}
        onClick={() => onViewPreviousVersionsClicked(absence)}
      >
        {t('manager.previousVersions.viewButton')}
        <PreviousVersionsIconWrapper
          name={'chevron_right'}
          settings={{ fill: colors.main100 }}
        />
      </ViewPreviousVersionsButton>
    ),
    [t, onViewPreviousVersionsClicked, absence]
  )

  const context: DashboardProps = useMemo(() => ({ absence }), [absence])
  const tilesContainer = useMemo(
    () => (
      <TilesContainer>
        <DefaultDashboard {...context} />
      </TilesContainer>
    ),
    [context]
  )
  const timelineContainer = useMemo(
    () => (
      <TimelineContainer>
        <TimelineTitle>
          {t('manager.leaveTimeline')}
          {previousVersionsButton}
        </TimelineTitle>
        <AbsenceTimeline absence={absence} showCurrent />
      </TimelineContainer>
    ),
    [t, absence, previousVersionsButton]
  )

  const unclassifiedPeriod = useMemo(
    () => absence.timeline?.find(p => p.type === 'Unknown'),
    [absence]
  )

  const desktopContainer = useMemo(
    () => (
      <Box>
        <BoxInnerContainer>
          {unclassifiedPeriod && (
            <UnclassifiedNote absencePeriod={unclassifiedPeriod} />
          )}
          <BoxContentContainer>
            <LeftContainer>
              {tilesContainer}
              <ButtonsRow>
                {tasksButton}
                <PlanSettingsButton />
              </ButtonsRow>
            </LeftContainer>

            {timelineContainer}
          </BoxContentContainer>
        </BoxInnerContainer>
      </Box>
    ),
    [tasksButton, tilesContainer, timelineContainer, unclassifiedPeriod]
  )

  const mobileContainer = useMemo(
    () => (
      <>
        {tilesContainer}
        {timelineContainer}
        <MobileButtonContainer>{tasksButton}</MobileButtonContainer>
      </>
    ),
    [tasksButton, tilesContainer, timelineContainer]
  )

  return <div>{isDesktop ? desktopContainer : mobileContainer}</div>
})

DashboardActive.displayName = 'DashboardActive'

export default DashboardActive
