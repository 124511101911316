import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useAbsences, { IUseAbsences } from 'src/graphql/hooks/useAbsences'
import {
  managerArchiveJourneyMapRoute,
  managerDashboardJourneyMapRoute,
  notFoundPageRoute
} from 'src/routes/constants'

export const AbsenceRedirectHandler = React.memo(() => {
  const navigate = useNavigate()
  const { absenceId } = useParams()
  const [searchParams] = useSearchParams()

  const absencesResult: IUseAbsences = useAbsences()
  const { absences } = absencesResult

  useEffect(() => {
    if (absences?.length) {
      const record = absences.find(abs => abs.id === absenceId)
      if (!record) {
        navigate(notFoundPageRoute)
      }

      const route = record?.archived
        ? managerArchiveJourneyMapRoute
        : managerDashboardJourneyMapRoute

      const routeItem = route.replace(':absenceId', absenceId)

      navigate(routeItem, { state: { openItemId: searchParams.get('itemId') } })
    }
  }, [navigate, absenceId, absences, searchParams])

  return null
})

AbsenceRedirectHandler.displayName = 'AbsenceRedirectHandler'

export default AbsenceRedirectHandler
