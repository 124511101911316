import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import Tooltip from 'src/components/Tooltip'

const { colors } = createTheme()

export interface IProps {
  className?: string
  claimStatus: IPeriodClaimStatus
}

const Container = styled.div<{
  $background: string
}>`
  display: flex;
  align-items: center;
  background: ${props => props.$background};
  border-radius: 64px;
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 4px 8px;
          gap: 6px;
        `
      : css`
          padding: 3px 6px;
          gap: 4px;
        `}
`

const Text = styled.span<{
  $color: string
}>`
  color: ${props => props.$color};
  font-weight: 500;
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 12px;
        `
      : css`
          font-size: 11px;
        `}
`

export const dataAttrs = {
  container: () => 'status-badge-container'
}

const ClaimStatusBadge = React.memo((props: IProps) => {
  const { className, claimStatus } = props
  const { t } = useTranslation()

  const backgroundColor = useMemo(() => {
    switch (claimStatus) {
      case 'InReview':
      case 'Pending':
        return colors.specialBrownLow
      case 'Approved':
        return colors.specialGreen
      case 'NotReported':
        return colors.dark60
      default:
        return 'unset'
    }
  }, [claimStatus])

  const textColor = useMemo(() => {
    switch (claimStatus) {
      case 'InReview':
      case 'Pending':
        return colors.specialBrown
      case 'Approved':
      case 'NotReported':
        return colors.light100
      default:
        return 'unset'
    }
  }, [claimStatus])

  if (!claimStatus) {
    return null
  }

  return (
    <Tooltip
      dashedUnderline={{ skipUnderline: true }}
      title={t(`timeline.claimStatus.tooltip.${claimStatus}.title`)}
      body={t(`timeline.claimStatus.tooltip.${claimStatus}.body`)}
      offset={[5, 10]}
      placement={'bottom-end'}
    >
      <Container
        data-testid={dataAttrs.container()}
        className={className}
        role={'note'}
        $background={backgroundColor}
      >
        <Text $color={textColor}>
          {t(`timeline.claimStatus.label.${claimStatus}`)}
        </Text>
      </Container>
    </Tooltip>
  )
})

ClaimStatusBadge.displayName = 'ClaimStatusBadge'

export default ClaimStatusBadge
