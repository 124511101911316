export const MetricEventManagerDashboardImpression =
  'veer.client.manager_dashboard.impression'
export const MetricEventHrAdminDashboardImpression =
  'veer.client.hradmin_dashboard.impression'
export const MetricEventWelcomeScreenImpression =
  'veer.client.welcome_screen.impression'
export const MetricEventRemindersImpression =
  'veer.client.contacts_form.impression'
export const MetricEventSetupManagerImpression =
  'veer.client.setup_manager.impression'
export const MetricEventJourneyMapImpression =
  'veer.client.journeymap.impression'
export const MetricEventJourneyMapClick = 'veer.client.journeymap.click'
export const MetricEventTimelineImpression = 'veer.client.timeline.impression'
export const MetricEventTransitionFlowImpression =
  'veer.client.transition_flow.impression'
export const MetricEventTransitionFlowStartClick =
  'veer.client.transition_flow.start_click'
export const MetricEventTransitionFlowSkipClick =
  'veer.client.transition_flow.skip_click'
export const MetricClaimUpdateRequestClick =
  'veer.client.claim_update_request.click'
