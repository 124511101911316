import React, { ReactNode } from 'react'
import { wf } from 'src/utils/workflow'
import PregnancyChangeView from './components/PregnancyChangeView'
import ConnectWithHRView from './components/ConnectWithHRView'
import PregnancyLossView from './components/PregnancyLossView'
import PregnancyChangeSuccessView from './components/PregnancyChangeSuccessView'
import StillExpectedChildrenView from './components/StillExpectedChildrenView'
import { IPregnancyLossContext } from './context'

export type PregnancyLossStage =
  | 'pregnancyChange'
  | 'pregnancyLoss'
  | 'connectWithHR'
  | 'pregnancyChangeSuccess'
  | 'stillExpectedChildren'

export const getWorkflow = () =>
  wf<PregnancyLossStage>({
    init: 'pregnancyChange',
    stages: {
      pregnancyChange: (context: IPregnancyLossContext) =>
        context.isMiscarriageOrStillBirth ? 'pregnancyLoss' : 'connectWithHR',

      pregnancyLoss: (context: IPregnancyLossContext) =>
        context.expectedChildren
          ? 'stillExpectedChildren'
          : 'pregnancyChangeSuccess',

      stillExpectedChildren: 'pregnancyChangeSuccess',

      connectWithHR: null,
      pregnancyChangeSuccess: null
    }
  })

export const getCurrentStageView = (stage: PregnancyLossStage): ReactNode => {
  switch (stage) {
    case 'pregnancyChange':
      return <PregnancyChangeView name={stage} />
    case 'connectWithHR':
      return <ConnectWithHRView name={stage} />
    case 'pregnancyLoss':
      return <PregnancyLossView name={stage} />
    case 'stillExpectedChildren':
      return <StillExpectedChildrenView name={stage} />
    case 'pregnancyChangeSuccess':
      return <PregnancyChangeSuccessView name={stage} />
    default:
      return null
  }
}
