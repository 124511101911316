import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import { mobileButtonContainerPaddingMixin } from 'src/theme/utils'

interface IProps {
  backTitle: string
  onBackClick: () => void
  nextTitle: string
  onNextClick: () => void
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  padding-bottom: 73px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 414px;
      padding-bottom: 16px;
    `}

  ${mobileButtonContainerPaddingMixin};
`

const ButtonWrapper = styled(Button)`
  width: ${props => (props.theme.isDesktop ? '256px' : '100%')};
`

export const dataAttrs = {
  buttonBack: () => 'claim-update-button-back',
  buttonNext: () => 'claim-update-button-next'
}

export const ClaimUpdateButtons = React.memo((props: IProps) => {
  const { backTitle, onBackClick, nextTitle, onNextClick } = props

  return (
    <ButtonsContainer>
      <ButtonWrapper
        data-testid={dataAttrs.buttonBack()}
        onClick={onBackClick}
        appearance={'cancel'}
      >
        {backTitle}
      </ButtonWrapper>
      <ButtonWrapper data-testid={dataAttrs.buttonNext()} onClick={onNextClick}>
        {nextTitle}
      </ButtonWrapper>
    </ButtonsContainer>
  )
})

ClaimUpdateButtons.displayName = 'ClaimUpdateButtons'

export default ClaimUpdateButtons
