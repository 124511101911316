import React from 'react'
import styled, { css } from 'styled-components'
import PrivacyListItem from '../PrivacyListItem'
import FlexibleContainer from 'src/components/FlexibleContainer'
import { useTranslation } from 'react-i18next'
import { PrivacyItem } from '../..'

interface IProps {
  className?: any
  onViewItem: (item: PrivacyItem) => void
  items: PrivacyItem[]
  shows: boolean
}

const Container = styled(FlexibleContainer)<any>`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 640px;
        `
      : css`
          width: 100%;
        `}
`

const ItemsContainer = styled.ul`
  padding-bottom: 16px;
`

export const dataAttrs = {
  component: () => 'privacy-list-components'
}

const PrivacyList = React.memo((props: IProps) => {
  const { className, items, onViewItem, shows } = props
  const { t } = useTranslation()

  return (
    <Container className={className} shows={shows}>
      <ItemsContainer
        data-testid={dataAttrs.component()}
        role={'list'}
        aria-label={t('common.accessibilityText.regionPrivacyControls')}
      >
        {items.map((item: any, index: number) => (
          <PrivacyListItem
            key={item.title}
            item={item}
            onViewItem={onViewItem}
            showsSeparator={index !== items.length - 1}
          />
        ))}
      </ItemsContainer>
    </Container>
  )
})

PrivacyList.displayName = 'PrivacyList'

export default PrivacyList
