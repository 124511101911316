import React, { useContext, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import LeftPanelContainer from 'src/components/JourneyMap/LeftPanelContainer'
import { useTranslation } from 'react-i18next'
import Settings from 'src/features/ManagerJourneyMap/components/Settings'
import zIndex from 'src/constants/zIndex'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { getJourneyMapStats } from 'src/utils/journeyMap'
import OverdueRow from 'src/components/JourneyMap/OverdueRow'
import ScreenContext from 'src/contexts/ScreenContext'
import LeaveStatRow from 'src/components/JourneyMap/LeaveStatRow'
import moment from 'moment'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import { getAbsencePeriodDurationString } from 'src/utils/absences'

const OverdueRowWrapper = styled(OverdueRow)`
  margin: 16px 0 8px;
`

const LeaveStatsWrapper = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SettingsWrapper = styled(Settings)`
  ${props =>
    props.theme.isMobile &&
    css`
      grid-area: filters;
      box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
      z-index: ${zIndex.journeyMap.page.priorityFiltersWrapper};
    `}
  ${printDisplayNoneMixin}
`

export const ManagerJourneyMapLeftSideContainer = React.memo(() => {
  const context: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const {
    priorityFilters,
    isOverdueMode,
    onOverdueClick,
    currentAbsence,
    isArchiveMode
  } = context
  const { isDesktop } = useContext(ScreenContext)

  const { t } = useTranslation()

  const hasJourneyMap = !!currentAbsence
  const journeyMap: IJourneyMapItem[] = hasJourneyMap
    ? currentAbsence.journeyMap
    : []

  const { totalItemsCount, currentItemsCount, overdueItemsCount } =
    getJourneyMapStats(journeyMap, priorityFilters, [], isOverdueMode)

  useEffect(() => {
    if (isOverdueMode && overdueItemsCount === 0) {
      onOverdueClick()
    }
  }, [isOverdueMode, overdueItemsCount, onOverdueClick])

  const settingsView: any = (
    <SettingsWrapper
      filters={priorityFilters}
      onFilterClick={context.onFilterClick}
      onResetFilters={isOverdueMode ? null : context.onResetFiltersClick}
      totalItemsCount={totalItemsCount}
      currentItemsCount={currentItemsCount}
      onExpanderStateDidChange={context.onMobileFiltersExpandStateDidChange}
    />
  )

  const overdueView: any = overdueItemsCount !== 0 && (
    <OverdueRowWrapper
      onClick={onOverdueClick}
      selected={isOverdueMode}
      value={overdueItemsCount}
    />
  )

  const leaveLength = useMemo(
    () =>
      currentAbsence &&
      getAbsencePeriodDurationString(
        {
          type: '',
          startDate: currentAbsence.startDate,
          endDate: currentAbsence.endDate
        },
        t
      ),
    [currentAbsence, t]
  )

  const leaveStats = currentAbsence && (
    <LeaveStatsWrapper>
      <LeaveStatRow
        text={`${t('common.startDate')}:`}
        value={moment(currentAbsence.startDate).utc().format(DATE_FORMAT)}
      />
      <LeaveStatRow
        text={`${t('common.endDate')}:`}
        value={moment(currentAbsence.endDate).utc().format(DATE_FORMAT)}
      />
      {isArchiveMode && (
        <LeaveStatRow
          text={`${t('common.leaveLength')}:`}
          value={leaveLength.humanReadableFullString}
        />
      )}
    </LeaveStatsWrapper>
  )

  const desktopDefaultModeView: any = (
    <React.Fragment>
      {leaveStats}
      {overdueView}
      {settingsView}
    </React.Fragment>
  )

  const desktopArchiveModeView: any = (
    <React.Fragment>{leaveStats}</React.Fragment>
  )

  return isDesktop ? (
    <LeftPanelContainer
      ariaLabel={t(
        `common.accessibilityText.${
          isArchiveMode ? 'regionArchivedPlans' : 'regionManagerSettings'
        }`
      )}
    >
      {isArchiveMode ? desktopArchiveModeView : desktopDefaultModeView}
    </LeftPanelContainer>
  ) : (
    settingsView
  )
})

ManagerJourneyMapLeftSideContainer.displayName =
  'ManagerJourneyMapLeftSideContainer'

export default ManagerJourneyMapLeftSideContainer
