import React, { useContext, useRef } from 'react'
import OverdueView, {
  IOverdueItemsProps
} from 'src/components/JourneyMap/OvedueView'
import { useTranslation } from 'react-i18next'
import {
  IPeriodFilter,
  IPriorityFilter
} from 'src/components/JourneyMap/Filters'
import FiltersLeavePhase from 'src/components/JourneyMap/Filters/FiltersLeavePhase'
import FiltersPriority from 'src/components/JourneyMap/Filters/FiltersPriority'
import Stats from 'src/components/JourneyMap/Stats'
import JourneyMapSettingsViewContainer, {
  Separator
} from 'src/components/JourneyMap/SettingsViewContainer'
import styled, { css } from 'styled-components'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'
import ReactFocusLock from 'react-focus-lock'

interface IProps extends IOverdueItemsProps {
  filters: IPriorityFilter[]
  periods: IPeriodFilter[]
  onPeriodClick: (index: number) => void
  onFilterClick: (index: number) => void
  onResetFilters?: () => void
  totalItemsCount: number
  currentItemsCount: number
  onExpanderStateDidChange: (isExpanded: boolean) => void
}

const MobileExpandViewHeader = styled.div<{ $justifyContent: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.$justifyContent};
  align-items: center;
`

const MobileExpandViewHeaderTitleContainer = styled.button`
  display: flex;
  align-items: center;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 4px;
    border-radius: 10px;
  }
`

const MobileExpandViewHeaderTitle = styled.span`
  margin-left: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  pointer-events: none;
  color: ${props => props.theme.colors.main100};
`

const ContentContainer = styled.div`
  width: 100%;
  ${props =>
    props.theme.isMobile &&
    css`
      width: calc(100% - 32px);
      padding: 0 16px;
    `}
`

const filtersMixin = css`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 24px;
        `
      : css`
          margin-top: 16px;
        `}
`

const FiltersLeavePhaseWrapper = styled(FiltersLeavePhase)`
  ${filtersMixin}
`

const FiltersPriorityWrapper = styled(FiltersPriority)`
  ${filtersMixin}
`

const JourneyMapSettings = React.memo(
  (props: IProps) => {
    const {
      filters,
      periods,
      onPeriodClick,
      onFilterClick,
      onResetFilters,
      overdueItemsCount,
      onOverdueClick,
      showOverdueAsRed,
      currentItemsCount,
      totalItemsCount,
      onExpanderStateDidChange
    } = props
    const viewContainerRef = useRef(null)
    const { t } = useTranslation()
    const { isDesktop } = useContext(ScreenContext)

    const renderOverdue = () => {
      if (!overdueItemsCount) {
        return null
      }

      return (
        <OverdueView
          showOverdueAsRed={showOverdueAsRed}
          overdueItemsCount={overdueItemsCount}
          onOverdueClick={() => {
            if (viewContainerRef.current) {
              viewContainerRef.current.collapse()
            }
            onOverdueClick()
          }}
        />
      )
    }

    return (
      <JourneyMapSettingsViewContainer
        ref={viewContainerRef}
        mobileExpandViewProps={{
          headerView: (
            <MobileExpandViewHeader
              $justifyContent={overdueItemsCount ? 'space-between' : 'center'}
            >
              {renderOverdue()}
              {showOverdueAsRed && (
                <MobileExpandViewHeaderTitleContainer
                  onClick={() => {
                    viewContainerRef.current.toggle()
                  }}
                >
                  <Icon
                    name={'filters'}
                    ariaLabel={t('common.accessibilityText.filtersIcon')}
                  />
                  <MobileExpandViewHeaderTitle>
                    {t(`common.filters.filterTitle`)}
                  </MobileExpandViewHeaderTitle>
                </MobileExpandViewHeaderTitleContainer>
              )}
            </MobileExpandViewHeader>
          ),
          onExpanderStateDidChange
        }}
      >
        <ContentContainer>
          <ReactFocusLock disabled={isDesktop}>
            <FiltersPriorityWrapper
              onFilterClick={onFilterClick}
              filters={filters}
              title={t('common.filters.filterItemsByPriority')}
            />
            <FiltersLeavePhaseWrapper
              onPeriodClick={onPeriodClick}
              periods={periods}
              title={t('common.filters.filterItemsByLeavePhase')}
            />
            {isDesktop && <Separator />}
            <Stats
              onResetFilters={() => {
                if (!isDesktop) {
                  viewContainerRef.current.collapse()
                }
                if (onResetFilters) {
                  onResetFilters()
                }
              }}
              onApplyFilters={() => {
                viewContainerRef.current.collapse()
              }}
              currentItemsCount={currentItemsCount}
              totalItemsCount={totalItemsCount}
            />
          </ReactFocusLock>
        </ContentContainer>
      </JourneyMapSettingsViewContainer>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.filters === nextProps.filters &&
    prevProps.periods === nextProps.periods &&
    prevProps.totalItemsCount === nextProps.totalItemsCount &&
    prevProps.currentItemsCount === nextProps.currentItemsCount &&
    prevProps.overdueItemsCount === nextProps.overdueItemsCount &&
    prevProps.onResetFilters === nextProps.onResetFilters
)

JourneyMapSettings.displayName = 'JourneyMapSettings'

export default JourneyMapSettings
