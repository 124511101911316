import React, { useContext, useMemo } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveOptions from '../UI/CreateLeaveOptions'

export const MiscarriageLengthView = React.memo(() => {
  const {
    leaveTypes,
    subtype,
    setSubtype,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  const subtypes = useMemo(
    () =>
      leaveTypes.find(leave => leave.group === 'Miscarriage')?.subtypes || [],
    [leaveTypes]
  )

  return (
    <CreateLeaveOptions
      name={'miscarriageLengthView'}
      options={subtypes}
      selectedOption={subtype}
      onOptionSelected={setSubtype}
      onBackClicked={() => {
        setSubtype(null)
        prevCreateLeaveStage()
      }}
      onNextClicked={nextCreateLeaveStage}
    />
  )
})

MiscarriageLengthView.displayName = 'MiscarriageLengthView'

export default MiscarriageLengthView
