import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Badge from 'src/components/Badge'
import { Checkbox } from 'src/UIKit'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'

const { colors } = createTheme()

interface IProps {
  className?: string
  selected: boolean
  onClick: () => void
  value: number
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 24px 0 21px;
        `
      : css`
          padding: 0 22px 0 21px;
        `}
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  margin-left: 11px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
`

export const OverdueRow = React.memo(
  (props: IProps) => {
    const { className, selected, value, onClick } = props
    const { t } = useTranslation()

    const ariaLabel: string = useMemo(
      () =>
        t(
          `common.accessibilityText.${
            value !== 0
              ? 'youHaveOverdueItemsForThisEmployee'
              : 'youHaveNoOverdueItemsForThisEmployee'
          }`,
          { count: value }
        ),
      [t, value]
    )

    return (
      <Container className={className} onClick={onClick}>
        <ContentContainer aria-label={ariaLabel} role={'status'}>
          <Badge text={value.toString()} background={colors.error80} />
          <Text aria-hidden>{t('common.overdue')}</Text>
        </ContentContainer>
        <Checkbox
          name={'overdue'}
          checked={selected}
          onChange={onClick}
          ariaLabel={t('common.accessibilityText.showOverdueItemsOnly')}
        />
      </Container>
    )
  },
  (prev: IProps, next: IProps) => {
    if (prev.selected !== next.selected) {
      return false
    }
    if (prev.value !== next.value) {
      return false
    }
    return true
  }
)

OverdueRow.displayName = 'OverdueRow'

export default OverdueRow
