import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { ITimelinePeriodConfig } from '../../../index'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  periodName: 'ramp back time',
  periodKeyDate: 'periodEnd',
  periodPickerSelectedNotice: {
    show: true,
    showDoubleText: true
  },
  periodPickerShowSelectedTextWithWeeks: true,
  periodPickerCreateAddable: true,
  datePickerComponent: LeaveDatePeriodPicker
})
