import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'

interface IProps {
  name: string
  onBackClicked?: () => void
  onNextClicked?: () => void
  isNextDisabled?: boolean
  children?: ReactNode
  descriptionComponents?: any
  descriptionProps?: any
}

export const PublishedLeaveView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    name,
    children,
    onBackClicked,
    onNextClicked,
    isNextDisabled,
    descriptionProps,
    descriptionComponents
  } = props

  const buttons: any = useMemo(
    () =>
      onBackClicked || onNextClicked ? (
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={onBackClicked}
          onNextClick={onNextClicked}
          isNextDisabled={isNextDisabled}
          hidesBackButton={onBackClicked === undefined}
          hidesNextButton={onNextClicked === undefined}
        />
      ) : null,
    [t, isNextDisabled, onBackClicked, onNextClicked]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{t(`publishedLeave.${name}.title`)}</CreateLeaveTitle>
      <CreateLeaveDescription
        components={descriptionComponents}
        marginBottom={'16px'}
      >
        {t(`publishedLeave.${name}.description`, { ...descriptionProps })}
      </CreateLeaveDescription>
      {children}
      {buttons}
    </CreateLeaveContentContainer>
  )
})

PublishedLeaveView.displayName = 'PublishedLeaveView'

export default PublishedLeaveView
