import React from 'react'
import styled, { css } from 'styled-components'
import Toggle from 'src/components/Toggle'

interface IProps {
  className?: string
  toggled: boolean
  onClick: () => void
  text: string
  ariaLabel?: string
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`

const Text = styled.span`
  user-select: none;
  font-style: normal;
  font-weight: normal;
  margin-right: 5px;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 17px;
          line-height: 100%;
        `
      : css`
          font-size: 14px;
          line-height: 130%;
        `}
`

const ToggleRow = React.memo((props: IProps) => {
  const { className, toggled, onClick, text, ariaLabel } = props
  return (
    <Container className={className} onClick={onClick}>
      <Text aria-hidden>{text}</Text>
      <Toggle toggled={toggled} ariaLabel={ariaLabel} />
    </Container>
  )
})

ToggleRow.displayName = 'ToggleRow'

export default ToggleRow
