import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import SurveysSection from 'src/features/HrAdmin/components/sections/Surveys'
import { useTranslation } from 'react-i18next'
import AnalyticsSection from 'src/features/HrAdmin/components/sections/Analytics'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import ScreenContext from 'src/contexts/ScreenContext'
import HrAdminContext, { IHrAdminContext } from '../../HrAdminContext'

export const HE_ADMIN_CONTENT_AREA_ID = 'hr_admin_content_area'

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 12px 16px;
        `
      : css`
          padding: 12px 0;
          margin: 0 auto;
        `}

  ${mobileMaxWidthMixin};
`

const InnerContainer = styled.div`
  flex: 1;

  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: 670px;
          max-width: 1528px;
          margin: 0 auto;
        `
      : css`
          width: 100%;
        `}
`

const headingMixin = css`
  color: ${props => props.theme.colors.dark80};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 28px;
          font-weight: 400;
        `
      : css`
          font-size: 18px;
          font-weight: 500;
        `}
`

const Heading = styled.h1`
  ${headingMixin}

  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 24px 0 24px 8px;
        `
      : css`
          margin: 4px 0 8px 8px;
        `}
`

export const ContentArea = React.memo(() => {
  const { t } = useTranslation()
  const { i18Context } = useContext(ScreenContext)
  const context: IHrAdminContext = useContext(HrAdminContext)

  const {
    selectedInsightSubItemIndex,
    selectedSurveySubItemIndex,
    surveysSubItemTitles,
    selectedAnalyticsType
  } = context

  const insightsView: ReactNode = useMemo(() => {
    if (selectedInsightSubItemIndex === -1) {
      return null
    }

    const title: string = selectedAnalyticsType
      ? t('hrAdmin.dashboardTyped', { leaveGroup: selectedAnalyticsType })
      : t('hrAdmin.title')

    return (
      <>
        <Heading>{title}</Heading>
        <AnalyticsSection />
      </>
    )
  }, [selectedInsightSubItemIndex, selectedAnalyticsType, t])

  const surveysView: ReactNode = useMemo(() => {
    if (selectedSurveySubItemIndex === -1) {
      return null
    }

    return (
      <>
        <Heading>
          {t('hrAdmin.userSurveyResponseData', {
            leaveGroup: surveysSubItemTitles[selectedSurveySubItemIndex],
            context: i18Context
          })}
        </Heading>
        <SurveysSection />
      </>
    )
  }, [selectedSurveySubItemIndex, surveysSubItemTitles, i18Context, t])

  return (
    <Container id={HE_ADMIN_CONTENT_AREA_ID}>
      <InnerContainer>
        {insightsView}
        {surveysView}
      </InnerContainer>
    </Container>
  )
})

ContentArea.displayName = 'ContentArea'

export default ContentArea
