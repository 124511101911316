import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withToastManager } from 'src/components/ToastManager'
import { withDialogManager } from 'src/components/DialogManager'
import ManagerJourneyMapLeftSideContainer from 'src/features/ManagerJourneyMap/containers/ManagerJourneyMapLeftSideContainer'
import {
  createPriorityFilters,
  flipFilter,
  resetFilters,
  switchFiltersDisability
} from 'src/utils/journeyMap'
import {
  IWithQueriesProps,
  IWithDialogManager,
  IWithToastManager
} from 'src/react-app-env'
import LoadingSpinner from 'src/components/LoadingSpinner'
import withQueries from 'src/components/HOC/withQueries'
import { useTranslation } from 'react-i18next'
import JourneyMapTopViewContainer from 'src/components/JourneyMap/TopViewContainer'
import ManagerJourneyMapListContainer from 'src/features/ManagerJourneyMap/containers/ManagerJourneyMapListContainer'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import {
  errorPageRoute,
  managerArchiveRoute,
  managerDashboardRoute,
  notFoundPageRoute
} from 'src/routes/constants'
import useAbsences, { IUseAbsences } from 'src/graphql/hooks/useAbsences'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import UserContext, { IUserContext } from 'src/contexts/UserContext'
import { getUserContextValue } from 'src/contexts/UserContext/methods'
import { MetricEventManagerDashboardImpression } from 'src/constants/metrics'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

interface IProps
  extends IWithQueriesProps,
    IWithToastManager,
    IWithDialogManager {
  isArchive: boolean
}

export const ManagerJourneyMapContainer = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { toastManager, isArchive, dialogManager } = props
  const [priorityFilters, setPriorityFilters] = useState(
    createPriorityFilters()
  )
  const [isOverdueMode, setIsOverdueMode] = useState(false)
  const [isMobilePanelExpanded, setIsMobilePanelExpanded] = useState(false)
  const [currentAbsence, setCurrentAbsence] = useState(null)

  const userResult: IUseUser = useUser()

  const userContextValue: IUserContext = useMemo(
    () => getUserContextValue(userResult),
    [userResult]
  )

  usePageTitle(isArchive ? 'managerDashboardArchive' : 'managerDashboard')
  useRedirectByUserRole({ navigate, allowedRole: 'manager' })

  const { absenceId } = useParams()

  const absencesResult: IUseAbsences = useAbsences(
    !userResult.user,
    null,
    isArchive
  )
  const { absences, loading, error } = absencesResult

  useEffect(() => {
    if (absences?.length) {
      const record = absences.find(abs => abs.id === absenceId)
      if (!record) {
        navigate(notFoundPageRoute)
        return
      }
      setCurrentAbsence(record)
    }
  }, [navigate, absenceId, absences])

  useEffect(() => {
    if (currentAbsence) {
      props.queries.createMetric(
        {
          eventType: MetricEventManagerDashboardImpression,
          id: currentAbsence.id
        },
        { anyError: (): void => undefined }
      )
    }
  }, [currentAbsence]) // eslint-disable-line react-hooks/exhaustive-deps

  const clickBack = useCallback(
    () =>
      isArchive
        ? navigate(managerArchiveRoute)
        : navigate(managerDashboardRoute, { state: { absenceId } }),
    [absenceId, navigate, isArchive]
  )

  const unarchivePlan = (absence: IAbsence) => {
    dialogManager.add({
      title: t('unarchiveThePlan.dialogUnarchiveTitle'),
      ariaText: t('unarchiveThePlan.dialogUnarchiveAria'),
      buttons: [
        {
          title: t('common.cancel'),
          appearance: 'cancel',
          onClick: (): void => undefined,
          order: 1
        },
        {
          title: t('common.unarchive'),
          onClick: () => {
            props.queries
              .archiveAbsence(absence.id, false)
              .then(() => clickBack())
              .catch(() =>
                toastManager.addError(t('common.somethingWentWrong'))
              )

            toastManager.addInfo(t('managerJourneyMap.planHasBeenUnarchived'))
          },
          order: 2
        }
      ],
      children: t('archiveThePlan.dialogArchiveBody')
    })
  }

  const onFilterClick = useCallback(
    (index: number) => {
      setPriorityFilters(flipFilter(index, priorityFilters))
    },
    [priorityFilters]
  )

  const onResetFiltersClick = useCallback(() => {
    setPriorityFilters(resetFilters(priorityFilters))
  }, [priorityFilters])

  const onOverdueClick = useCallback(() => {
    const newOverdueMode = !isOverdueMode
    setIsOverdueMode(newOverdueMode)
    setPriorityFilters(switchFiltersDisability(priorityFilters, newOverdueMode))
  }, [priorityFilters, isOverdueMode])

  const onError = useCallback(() => {
    toastManager.addError(t('common.somethingWentWrong'))
  }, [toastManager, t])

  if (loading || !currentAbsence) {
    return <LoadingSpinner fadesIn fullScreen />
  }

  if (error) {
    navigate(errorPageRoute, {
      state: { error: error.message }
    })
    return null
  }

  const contextValue: IManagerJourneyMapContext = {
    priorityFilters,
    isOverdueMode,
    onFilterClick,
    onResetFiltersClick,
    onOverdueClick,
    onMobileFiltersExpandStateDidChange: (expanded: boolean) => {
      setIsMobilePanelExpanded(expanded)
    },
    blurred: isMobilePanelExpanded,
    queries: props.queries,
    navigate,
    location,
    onToggleError: onError,
    unarchivePlan: () => unarchivePlan(currentAbsence),
    currentAbsence,
    isArchiveMode: isArchive,
    clickBack
  }

  return (
    <ManagerJourneyMapContext.Provider value={contextValue}>
      <UserContext.Provider value={userContextValue}>
        <JourneyMapTopViewContainer>
          <ManagerJourneyMapLeftSideContainer />
          <ManagerJourneyMapListContainer />
        </JourneyMapTopViewContainer>
      </UserContext.Provider>
    </ManagerJourneyMapContext.Provider>
  )
})

ManagerJourneyMapContainer.displayName = 'ManagerJourneyMapContainer'

export default withToastManager(
  withDialogManager(withQueries(ManagerJourneyMapContainer))
)
