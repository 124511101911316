import IntermittentBlocksPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/IntermittentBlocksPicker'
import { ITimelinePeriodConfig } from '../../../index'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  datePickerComponent: IntermittentBlocksPicker,
  periodPickerSelectedNotice: {
    show: true
  }
})
