import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import LoadingSpinner from 'src/components/LoadingSpinner'
import DatePicker from 'src/components/DatePicker'
import { getDueDateLabelKey } from 'src/utils/leaveUtils'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import TypeSelector from '../../TypeSelector'
import { camelCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import CreateLeaveNote from '../CreateLeaveNote'
import CheckboxConfirmationView from 'src/components/CheckboxConfirmationView'

interface IProps {
  selectorWidth?: string
}

const TypeSelectorWrapper = styled(TypeSelector)<{ $width: string }>`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: ${props.$width || '308px'};
        `
      : css`
          width: 100%;
        `}
`

const DatePickerWrapper = styled(DatePicker)<{
  $shown: boolean
  $width: string
}>`
  ${props =>
    props.theme.isDesktop
      ? css`
          transition:
            width 100ms ease-in,
            margin-left 100ms ease-in,
            opacity 100ms ease-in 100ms;
          ${() => {
            if (props.$shown) {
              return css`
                opacity: 1;
                width: ${props.$width || '308px'};
                margin-left: 16px;
              `
            } else {
              return css`
                opacity: 0;
                width: 0;
                margin-left: 0;
              `
            }
          }}
        `
      : css`
          width: 100%;
          transition: opacity 100ms ease-in 100ms;
          ${() => {
            if (props.$shown) {
              return css`
                opacity: 1;
                margin: 16px 0 0;
              `
            } else {
              return css`
                opacity: 0;
                display: none;
              `
            }
          }}
        `}
`

const ControlsContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          height: 68px;
        `
      : css`
          flex-direction: column;
          width: 100%;
        `}
`

const CheckboxConfirmationWrapper = styled(CheckboxConfirmationView)`
  width: 100%;
  margin-top: ${props => (props.theme.isDesktop ? '32px' : '16px')};
`

export const CreateLeaveGroupView = React.memo((props: IProps) => {
  const { t } = useTranslation()

  const {
    user,
    isCreatingLeave,
    selectedLeaveType,
    selectedDueDate,
    onDueDateChanged,
    minDate,
    maxDate,
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    leaveGroups,
    selectedLeaveGroupName,
    highlightedDateNow,
    customerLeaveCreateConfig,
    isMoreThanOneChild,
    setIsMoreThanOneChild
  }: ICreateLeaveContext = useContext(LeaveContext)

  const singleLeaveGroup = useMemo(
    () => leaveGroups.length === 1,
    [leaveGroups]
  )

  const groupName = useMemo(
    () => camelCase(selectedLeaveGroupName),
    [selectedLeaveGroupName]
  )

  const showsChosePastDateNote: boolean = useMemo(
    () =>
      highlightedDateNow &&
      selectedDueDate &&
      selectedDueDate.utc().isBefore(highlightedDateNow, 'day'),
    [selectedDueDate, highlightedDateNow]
  )

  const typeSelectorView: ReactNode = useMemo(
    () => (
      <TypeSelectorWrapper
        $width={props.selectorWidth}
        title={t(`createLeave.${groupName}.typeSelectorTitle`)}
      />
    ),
    [t, props.selectorWidth, groupName]
  )

  const datePickerView: ReactNode = useMemo(
    () => (
      <DatePickerWrapper
        title={t(getDueDateLabelKey({ type: selectedLeaveType?.type })) + ':'}
        momentCurrentMinMax={{
          current: selectedDueDate,
          min: minDate,
          max: maxDate
        }}
        disabled={!selectedLeaveType}
        placeholder={t('common.selectDate')}
        onDateChanged={onDueDateChanged}
        ariaHidden={!selectedLeaveType}
        $shown={!!selectedLeaveType}
        $width={props.selectorWidth}
        highlightedDate={highlightedDateNow}
      />
    ),
    [
      t,
      selectedLeaveType,
      selectedDueDate,
      minDate,
      maxDate,
      onDueDateChanged,
      props.selectorWidth,
      highlightedDateNow
    ]
  )

  const multipleBirthView: ReactNode = useMemo(
    () =>
      customerLeaveCreateConfig.showsMultipleBirth && (
        <>
          <CheckboxConfirmationWrapper
            confirmed={isMoreThanOneChild}
            translationKey={'createLeave.multipleBirth.confirmation'}
            onChange={() => {
              setIsMoreThanOneChild(!isMoreThanOneChild)
            }}
            fontSize={'16px'}
          />
          <CreateLeaveNote
            text={t('createLeave.multipleBirth.note')}
            shows={isMoreThanOneChild}
            marginTop={32}
          />
        </>
      ),
    [customerLeaveCreateConfig, isMoreThanOneChild, t, setIsMoreThanOneChild]
  )

  const controlsView: ReactNode = useMemo(
    () => (
      <>
        <ControlsContainer>
          {typeSelectorView}
          {datePickerView}
        </ControlsContainer>
        {multipleBirthView}
        <CreateLeaveNote
          text={t('createLeave.chosePastDateNote')}
          shows={showsChosePastDateNote}
          marginTop={32}
        />
      </>
    ),
    [
      typeSelectorView,
      datePickerView,
      multipleBirthView,
      t,
      showsChosePastDateNote
    ]
  )

  const buttonsView: ReactNode = useMemo(
    () => (
      <CreateLeaveButtons
        backTitle={t('common.back')}
        nextTitle={t('common.next')}
        onBackClick={prevCreateLeaveStage}
        onNextClick={nextCreateLeaveStage}
        isNextDisabled={!selectedLeaveType || !selectedDueDate}
        hidesBackButton={singleLeaveGroup}
      />
    ),
    [
      t,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      selectedLeaveType,
      selectedDueDate,
      singleLeaveGroup
    ]
  )

  const content: any = useMemo(
    () =>
      isCreatingLeave ? (
        <LoadingSpinner />
      ) : (
        <>
          {controlsView}
          {buttonsView}
        </>
      ),
    [isCreatingLeave, controlsView, buttonsView]
  )

  const title: string = useMemo(
    () =>
      singleLeaveGroup
        ? t(
            user?.firstName
              ? 'createLeave.welcome'
              : 'createLeave.welcomeWithoutName',
            { user: user?.firstName }
          )
        : t(`createLeave.${groupName}.title`),
    [singleLeaveGroup, groupName, user, t]
  )

  const description: string = useMemo(
    () =>
      singleLeaveGroup
        ? t('createLeave.description')
        : t(`createLeave.${groupName}.description`),
    [singleLeaveGroup, groupName, t]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{title}</CreateLeaveTitle>
      <CreateLeaveDescription>{description}</CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

CreateLeaveGroupView.displayName = 'CreateLeaveGroupView'

export default CreateLeaveGroupView
