import React, { useContext, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import ModalsView from 'src/components/ModalsView'
import { PrivacyItem } from '../..'
import { MarkdownText, H1, Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import ReactFocusLock from 'react-focus-lock'
import { KEY_ESCAPE } from 'src/constants/keys'
import ScreenContext from 'src/contexts/ScreenContext'

const { colors } = createTheme()

interface IProps {
  item: PrivacyItem
  onClose: () => void
}

const Container = styled.div`
  background: ${props => props.theme.colors.light100};
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop
      ? css`
          border-radius: 16px;
          max-width: 1440px;
          width: 65vw;
          max-height: 90vh;
        `
      : css`
          width: 100%;
          height: 100%;
        `}
`

const Title = styled(H1)`
  color: ${props => props.theme.colors.dark80};
  max-width: 90%;
  margin: 0 auto 8px;
`

const DescriptionContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const ItemDescription = styled(MarkdownText)`
  color: ${props => props.theme.colors.dark80};
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 24px;
  white-space: pre-wrap;
  letter-spacing: 0.15px;
  flex: 1;

  ${props =>
    props.theme.isDesktop
      ? css`
          -ms-overflow-style: -ms-autohiding-scrollbar;
          scrollbar-width: none;
          padding: 24px 32px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 16px 16px 8px;
        `}

  h2 {
    font-weight: 700;
  }

  h3 {
    font-weight: 500;
    margin-block-end: -20px;
  }

  ul {
    list-style: disc;
    white-space: normal;
    padding-left: 1.5em;
    margin-block-start: -20px;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }
`

export const CloseButton = styled.button`
  transition: border 100ms;
  width: 30px;
  height: 30px;
  margin: 9px 14px -15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    border: 2px solid ${props => props.theme.colors.main100};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colors.main100};
  }
`

const CloseIcon = styled(Icon)`
  height: 14px;
`

export const dataAttrs = {
  title: () => 'privacy-item-modal-title',
  description: () => 'privacy-item-modal-description',
  closeBtn: () => 'privacy-item-close-btn'
}

export const PrivacyItemModalView = React.memo((props: IProps) => {
  const { item, onClose } = props
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const containerRef = useRef(null)

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === KEY_ESCAPE) {
        onClose()
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onClose])

  const containerProps: any = {
    ref: containerRef,
    onClick: (event: any) => {
      if (event.target === containerRef.current) {
        onClose()
      }
    }
  }

  const contentContainerView = useMemo(
    () => (
      <Container>
        <div>
          <CloseButton
            data-testid={dataAttrs.closeBtn()}
            onClick={onClose}
            aria-label={t('common.accessibilityText.closeButton')}
          >
            <CloseIcon
              name={'close_crossing'}
              settings={{ fill: colors.main100 }}
            />
          </CloseButton>
          <Title data-testid={dataAttrs.title()}>{item.title}</Title>
        </div>
        <DescriptionContainer>
          <ItemDescription
            data-testid={dataAttrs.description()}
            allowsParagraph
          >
            {item.description}
          </ItemDescription>
        </DescriptionContainer>
      </Container>
    ),
    [item, onClose, t]
  )

  if (isDesktop) {
    return (
      <ModalsView {...containerProps}>
        <ReactFocusLock autoFocus={false}>
          {contentContainerView}
        </ReactFocusLock>
      </ModalsView>
    )
  }

  return <ModalsView {...containerProps}>{contentContainerView}</ModalsView>
})

PrivacyItemModalView.displayName = 'PrivacyItemModalView'

export default PrivacyItemModalView
