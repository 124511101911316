import React from 'react'
import { css } from 'styled-components'
import RadioButtonOption from 'src/components/RadioButtonOption'

interface IProps {
  selected: boolean
  onClick: () => void
  hidesSeparator?: boolean
  color?: string
}

const normalText = css`
  font-weight: normal;
  color: ${(p: any) => p.theme.colors.dark60};
`

const noneBackground = css`
  background: none;
`

export const NormalRadioButtonOption = React.memo(
  (props: React.PropsWithChildren<IProps>) => (
    <RadioButtonOption
      optionTextMixin={normalText}
      optionContentMixin={noneBackground}
      {...props}
    />
  )
)

NormalRadioButtonOption.displayName = 'NormalRadioButtonOption'

export default NormalRadioButtonOption
