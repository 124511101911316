import InfoMessageDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/InfoMessageDatePicker'
import { ITimelinePeriodConfig } from '../../../index'

export default (baseConfig: ITimelinePeriodConfig) => ({
  ...baseConfig,
  datePickerComponent: InfoMessageDatePicker,
  disableCreatePeriod: true,
  periodPickerInfoMessage: (t: any) =>
    t('timeline.leaveDurationPicker.LongTermDisability')
})
