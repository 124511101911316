import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import MultiDatePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/MultiDatePicker'
import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => {
  const isPersonalLeave = input.leave.type === 'Personal'
  return {
    ...baseConfig,
    datePickerComponent: isPersonalLeave
      ? MultiDatePicker
      : LeaveDatePeriodPicker,
    defaultActiveKeyDate: !isPersonalLeave ? 'periodStart' : null
  }
}
