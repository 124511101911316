import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
  onClick: () => void
  isSlim: boolean
  disabled: boolean
  isFirstBirthDateButton: boolean
}

const Container = styled.button<{
  $disabled: boolean
  $isFirst: boolean
  $isSlim: boolean
}>`
  transition: all 100ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: ${props => (props.$disabled ? 'unset' : 'pointer')};
  color: ${props => props.theme.colors.light100};
  background: ${props =>
    props.$disabled ? props.theme.colors.main40 : props.theme.colors.main100};
  border-radius: 16px;

  ${props =>
    !props.$isFirst &&
    css`
      margin-left: 26px;
    `}

  ${props => {
    const { $isSlim } = props

    if ($isSlim) {
      return css`
        height: 40px;
        padding-right: 16px;
      `
    } else {
      return css`
        height: 48px;
        width: 190px;
        margin-top: 16px;
      `
    }
  }}

  ${props => {
    const { $disabled, theme } = props

    if (!$disabled) {
      return css`
        &:hover {
          background: ${theme.colors.main110};
          box-shadow: ${`0 0 0 3px ${theme.colors.main110}`};
        }

        &:focus {
          outline: none;
          background: ${theme.colors.main110};
          box-shadow: ${`0 0 0 3px ${theme.colors.main110}`};
        }
      `
    }
  }}
`

const IconWrapper = styled(Icon)<{ $isSlim: boolean }>`
  ${props => {
    const { $isSlim } = props

    if ($isSlim) {
      return css`
        width: 20px;
        height: 20px;
        margin: 0 8px 0 9.5px;
      `
    } else {
      return css`
        width: 24px;
        height: 24px;
        margin: 0 8px 0 12px;
      `
    }
  }}
`

const Text = styled.span<{ $isSlim: boolean }>`
  font-size: ${(props: any) => (props.$isSlim ? '14px' : '17px')};
`

export const dataAttrs = {
  buttonBirthDate: () => 'button-birth-date'
}

const BirthDateButton = React.memo((props: IProps) => {
  const { onClick, className, isSlim, disabled, isFirstBirthDateButton } = props
  const { t } = useTranslation()

  const size: string = isSlim ? '20' : '24'
  return (
    <Container
      data-testid={dataAttrs.buttonBirthDate()}
      onClick={onClick}
      className={className}
      disabled={disabled}
      $disabled={disabled}
      $isSlim={isSlim}
      $isFirst={isFirstBirthDateButton}
    >
      <IconWrapper
        name={'baby_head'}
        settings={{ fill: 'white', width: size, height: size }}
        $isSlim={isSlim}
      />
      <Text $isSlim={isSlim}>
        {t('babyHasArrived.calendarButtonArrivedTitle')}
      </Text>
    </Container>
  )
})

BirthDateButton.displayName = 'BirthDateButton'

export default BirthDateButton
