import { Moment } from 'moment'
import { ITimelinePeriod } from 'src/react-app-env'
import { IUseDate } from '../hooks/useDate'
import { getChanges, getDaysBetweenDates } from '../methods'

interface IPeriodPickerInput {
  date?: Moment
  period?: ITimelinePeriod
  initialDate?: IUseDate
  currentDate?: IUseDate
  dueDate?: Moment
  startDate?: Moment
  onNewChanges?: (changes: any) => void
  timelinePeriods?: ITimelinePeriod[]
  leave?: ILeave
  onExit?: () => void
  updateLeave?: (changes: any) => void
  setIsSendingDate?: (value: boolean) => void
  isDateChangedValue?: boolean
  onDateChangeFunc?: (date: Moment) => void
}

export const onChange = ({
  currentDate,
  date,
  onNewChanges,
  timelinePeriods,
  period,
  dueDate,
  startDate,
  leave
}: IPeriodPickerInput) => {
  currentDate.setCurrent(date)
  onNewChanges(
    getChanges({
      timelinePeriods,
      period,
      duration: getDaysBetweenDates(period.periodStart.current, date) + 1,
      dueDate: dueDate.toISOString(),
      startDate: startDate.toISOString(),
      leave
    })
  )
}

export const onConfirm = ({
  onExit,
  currentDate,
  updateLeave,
  timelinePeriods,
  period,
  dueDate,
  startDate,
  leave
}: IPeriodPickerInput) => {
  onExit()
  updateLeave(
    getChanges({
      timelinePeriods,
      period,
      duration:
        getDaysBetweenDates(period.periodStart.current, currentDate.current) +
        1,
      dueDate: dueDate.toISOString(),
      startDate: startDate.toISOString(),
      leave
    })
  )
}

export const onCancel = ({
  setIsSendingDate,
  initialDate,
  onExit,
  isDateChangedValue,
  onDateChangeFunc
}: IPeriodPickerInput) => {
  if (isDateChangedValue) {
    setIsSendingDate(true)
    onDateChangeFunc(initialDate.current.add(-1, 'day'))
  } else {
    onExit()
  }
}

export const isDateChanged = ({
  initialDate,
  currentDate
}: IPeriodPickerInput): boolean => {
  if (!initialDate?.current || !currentDate?.current) {
    return false
  }

  if (!!initialDate && currentDate.current.isSame(initialDate.current, 'day')) {
    return false
  }

  return !initialDate.current.isSame(currentDate.current)
}

export const hasLongTermAbsenceItem = (leave: ILeave): boolean =>
  leave.timeline.some(tItem => tItem.type === 'LongTermAbsence')
