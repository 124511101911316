import React from 'react'
import { Moment } from 'moment'

export type NumberOfExpectedChildren = 'One' | 'Multiple'

export interface IPregnancyLossContext {
  leave: ILeave

  onPrevStage: () => void
  onNextStage: () => void

  dueDate: Moment
  setDueDate: (value: Moment) => void

  isMiscarriageOrStillBirth: boolean
  setIsMiscarriageOrStillBirth: (value: boolean) => void

  expectedChildren?: NumberOfExpectedChildren
  setExpectedChildren: (value: NumberOfExpectedChildren) => void
}

export default React.createContext(null)
