import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ITimelinePeriod } from 'src/react-app-env'
import styled from 'styled-components'
import DaysOffView from './components/DaysOffView'
import PeriodDurationPicker from '../../../components/PeriodDurationPicker'
import { useTranslation } from 'react-i18next'
import { getPeriodDuration } from '../../../methods'
import { StyledDatePicker } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/Datepickers'
import useDate, {
  IUseDate
} from 'src/features/Timeline/components/vertical/LeaveDurationPickers/hooks/useDate'
import { Moment } from 'moment'
import { getIntermittentBlockDuration, hasChanges } from '../../methods'
import InfoMessageView from '../../../components/InfoMessageView'
import LeaveHolidays from 'src/features/Leave/holidays'
import SelectedMessage from '../../../components/SelectedMessage'

interface IProps {
  className?: string
  isNew: boolean
  removable?: boolean
  period: ITimelinePeriod
  onStartDateChanged?: (period: ITimelinePeriod, startDate: Moment) => void
  getChangesParams?: (isChange: boolean) => void
  getParams?: (
    startDate: Moment,
    duration: number,
    activeDaysWeekOne: Set<IWeekday>,
    activeDaysWeekTwo: Set<IWeekday>,
    isDurationValid: boolean
  ) => void
  holidays: LeaveHolidays
  onCalendarActiveDateChanged: (date: any) => void
}

const Container = styled.div<any>`
  width: 100%;
`

const ControlsContainer = styled.div`
  margin: 10px 0 0;
  display: flex;
`

const PeriodDurationPickerWrapper = styled(PeriodDurationPicker)`
  margin-left: ${props => (props.editable ? '10px' : '24px')};
  margin-bottom: 15px;
  align-self: flex-end;
`

const InfoMessageWrapper = styled.div`
  margin-top: 15px;
`

const tKey = 'timeline.intermittentBlocksPicker.leaveBlockView'

const BlockView = React.memo((props: IProps) => {
  const {
    period,
    isNew,
    className,
    onStartDateChanged,
    getChangesParams,
    getParams,
    holidays,
    onCalendarActiveDateChanged
  } = props

  const { t } = useTranslation()

  const block = useMemo(() => period.blocks[0] || ({} as any), [period])
  const [duration, setDuration] = useState<number>(0)
  const [startDateChanged, setStartDateChanged] = useState<boolean>(false)
  const [activeDaysWeekOne, setActiveDaysWeekOne] = useState<Set<IWeekday>>(
    new Set()
  )
  const [activeDaysWeekTwo, setActiveDaysWeekTwo] = useState<Set<IWeekday>>(
    new Set()
  )
  const activeDays = useMemo(
    () => [activeDaysWeekOne, activeDaysWeekTwo],
    [activeDaysWeekOne, activeDaysWeekTwo]
  )
  const startDate: IUseDate = useDate({ ...period.periodStart })

  useEffect(() => {
    startDate.setDate(period.periodStart)
    setActiveDaysWeekOne(
      new Set(
        startDateChanged && period.timelineConfig.intermittentPickerResetBlock
          ? []
          : block.activeDaysWeekOne
      )
    )
    setActiveDaysWeekTwo(
      new Set(
        startDateChanged && period.timelineConfig.intermittentPickerResetBlock
          ? []
          : block.activeDaysWeekTwo
      )
    )
    setDuration(getPeriodDuration(period))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block])

  const selectedDuration = useMemo(
    () =>
      getIntermittentBlockDuration({
        schedule: period.schedule,
        startDate: startDate.current,
        duration,
        activeDaysWeekOne,
        activeDaysWeekTwo
      }),
    [period, startDate, duration, activeDaysWeekOne, activeDaysWeekTwo]
  )

  const availableDuration = useMemo(
    () =>
      (period.schedule?.totalUnused || 0) +
      (period.schedule?.usedInPeriod || 0),
    [period]
  )

  const unusedDuration: number = useMemo(
    () => Math.max(0, availableDuration - selectedDuration),
    [availableDuration, selectedDuration]
  )

  const isDurationValid = useMemo(
    () => selectedDuration > 0 && selectedDuration <= availableDuration,
    [selectedDuration, availableDuration]
  )

  useEffect(() => {
    const isDurationChanges: boolean = hasChanges(
      duration,
      getPeriodDuration(period)
    )

    const isActiveDaysWeekOneChanges: boolean =
      block.activeDaysWeekOne &&
      hasChanges(Array.from(activeDaysWeekOne), block.activeDaysWeekOne)

    const isActiveDaysWeekTwoChanges: boolean =
      block.activeDaysWeekTwo &&
      hasChanges(Array.from(activeDaysWeekTwo), block.activeDaysWeekTwo)

    getChangesParams(
      isDurationChanges ||
        isActiveDaysWeekOneChanges ||
        isActiveDaysWeekTwoChanges ||
        startDateChanged
    )

    getParams(
      startDate.current,
      duration,
      activeDaysWeekOne,
      activeDaysWeekTwo,
      isDurationValid
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, activeDaysWeekOne, activeDaysWeekTwo, isDurationValid])

  const onDayClicked = useCallback(
    (weekNumber: number, day: IWeekday) => {
      const days = activeDays[weekNumber]
      const newDays: Set<IWeekday> = new Set(days)

      if (newDays.has(day)) {
        newDays.delete(day)
      } else {
        newDays.add(day)
      }

      return weekNumber === 0
        ? setActiveDaysWeekOne(newDays)
        : setActiveDaysWeekTwo(newDays)
    },
    [activeDays, setActiveDaysWeekOne, setActiveDaysWeekTwo]
  )

  const startDatePicker: ReactNode = useMemo(
    () =>
      isNew && (
        <StyledDatePicker
          title={t('timeline.periodPicker.startDate')}
          momentCurrentMinMax={startDate.date}
          placeholder={t('common.selectDate')}
          onDateChanged={(date: Moment) => {
            startDate.setCurrent(date)
            onStartDateChanged(period, date)
            setStartDateChanged(true)
          }}
          holidays={holidays}
          onOpened={(date: any) => {
            onCalendarActiveDateChanged(date)
          }}
          onActiveStartDateChange={(action: any) => {
            onCalendarActiveDateChanged(action.activeStartDate)
          }}
          handleBottomOverlap={
            period.timelineConfig?.handleDatePickerBottomOverlap
          }
        />
      ),
    [
      t,
      period,
      isNew,
      startDate,
      onStartDateChanged,
      holidays,
      onCalendarActiveDateChanged
    ]
  )

  const periodDurationPicker: ReactNode = useMemo(
    () => (
      <PeriodDurationPickerWrapper
        title={t(`${tKey}.blockDuration`)}
        days={duration}
        minWeeks={1}
        maxWeeks={99}
        valid
        editable={isNew}
        onChanged={(value: number) => {
          setDuration(value * 7)
        }}
      />
    ),
    [t, isNew, duration, setDuration]
  )

  const selectedText: ReactNode = useMemo(
    () =>
      period.timelineConfig?.periodPickerSelectedNotice.show && (
        <SelectedMessage
          used={selectedDuration}
          unused={unusedDuration}
          externalDurationType={period.schedule.durationType}
          notShowUnused={
            period.timelineConfig?.periodPickerSelectedNotice.notShowUnused
          }
        />
      ),
    [period, selectedDuration, unusedDuration]
  )

  const infoMessageView: ReactNode = useMemo(
    () =>
      period.schedule?.weekOne?.length &&
      period.schedule?.weekTwo?.length && (
        <InfoMessageWrapper>
          <InfoMessageView message={t(`${tKey}.infoMessage`)} />
        </InfoMessageWrapper>
      ),
    [t, period]
  )

  return (
    <Container className={className} role={'region'}>
      {startDatePicker}
      <ControlsContainer>
        <DaysOffView
          schedule={period.schedule}
          activeDaysWeekOne={activeDaysWeekOne}
          activeDaysWeekTwo={activeDaysWeekTwo}
          onDayClicked={onDayClicked}
        />
        {periodDurationPicker}
      </ControlsContainer>
      {selectedText}
      {infoMessageView}
    </Container>
  )
})

BlockView.displayName = 'BlockView'

export default BlockView
