import React, { useCallback, useContext } from 'react'
import Context, { ICreateLeaveContext } from 'src/features/Leave/context'
import CreateLeaveOptions from '../UI/CreateLeaveOptions'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LeavePreference } from 'src/config/customers/config'

const OptionRowWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const dataAttrs = {
  title: () => 'leave-preference-title'
}

interface IProps {
  preferences: LeavePreference[]
}

export const SelectLeavePreference = React.memo((props: IProps) => {
  const { preferences } = props
  const { t } = useTranslation()

  const {
    timelinePreference,
    setTimelinePreference,
    prevCreateLeaveStage,
    nextCreateLeaveStage
  } = useContext<ICreateLeaveContext>(Context)

  const optionRow = useCallback(
    option => (
      <OptionRowWrapper data-testid={dataAttrs.title()}>
        {t(`createLeave.selectLeavePreference.options.${option}.title`)}
      </OptionRowWrapper>
    ),
    [t]
  )

  return (
    <CreateLeaveOptions
      name={'selectLeavePreference'}
      options={preferences}
      selectedOption={timelinePreference}
      onOptionSelected={setTimelinePreference}
      onBackClicked={() => {
        setTimelinePreference(null)
        prevCreateLeaveStage()
      }}
      optionRow={optionRow}
      onNextClicked={nextCreateLeaveStage}
    />
  )
})

SelectLeavePreference.displayName = 'SelectLeavePreference'

export default SelectLeavePreference
