import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { H1, Button } from 'src/UIKit'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import ChangeList from './components/ChangeList'
import SharedContext from 'src/contexts/SharedContext'
import CreateLeaveNote from 'src/features/Leave/components/UI/CreateLeaveNote'
import InfoNoticeExpandable from 'src/components/InfoNoticeExpandable'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  leave: ILeave
  onContinueClicked: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  gap: ${props => (props.theme.isDesktop ? '20px' : '16px')};

  ${props =>
    props.theme.isMobile &&
    css`
      height: 100%;
      margin: 0 16px 16px;
      align-items: center;
    `}

  ${mobileMaxWidthMixin};
`

const Title = styled(H1)`
  width: auto;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 48px auto 32px;
        `
      : css`
          margin: 16px auto 0;
        `}
`

const Description = styled.p`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 24px;
  max-width: 736px;
  color: ${props => props.theme.colors.dark60};
`

const ContinueButton = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 256px;
          margin: auto;
        `
      : css`
          width: 100%;
        `}
`

const Hint = styled.span`
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  font-weight: 400;
  line-height: 24px;
  max-width: 736px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.dark60};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
        `
      : css`
          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
        `}

  ${mobileButtonMaxWidthMixin};
`

export const dataAttrs = {
  title: () => `plan-change-page-title`
}

const PlanChangePage = React.memo((props: IProps) => {
  const { leave, onContinueClicked } = props
  const { t } = useTranslation()
  const { customerConfig } = useContext(SharedContext)
  const { isDesktop } = useContext(ScreenContext)

  const asteriskFootnoteView: ReactNode = useMemo(
    () =>
      customerConfig.leave.planChange.showAsteriskNoteView && (
        <CreateLeaveNote
          text={t('planChange.asteriskFootnote')}
          shows
          marginTop={12}
        />
      ),
    [customerConfig.leave.planChange.showAsteriskNoteView, t]
  )

  return (
    <Container>
      <Title data-testid={dataAttrs.title()}>{t('planChange.title')}</Title>
      <Description>
        {t([`planChange.description_${leave?.type}`, 'planChange.description'])}
      </Description>
      {asteriskFootnoteView}
      <ChangeList changes={leave?.tpa?.changes} leaveType={leave?.type} />
      {customerConfig.leave.planChange.showHint(leave) && (
        <Hint>{t('planChange.hint')}</Hint>
      )}
      <ButtonsContainer>
        <ContinueButton onClick={onContinueClicked}>
          {t([
            `planChange.control.continue_${leave?.type}`,
            'planChange.control.continue'
          ])}
        </ContinueButton>
        <InfoNoticeExpandable
          title={t('planChange.control.incorrectDates')}
          text={t('planChange.control.incorrectDatesInfo')}
          marginBottom={isDesktop ? 54 : 16}
          scrollToBottomView
        />
      </ButtonsContainer>
    </Container>
  )
})

PlanChangePage.displayName = 'PlanChangePage'

export default PlanChangePage
