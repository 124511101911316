import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  children: string
}

const Title = styled.span`
  line-height: 100%;
  font-weight: 500;
  color: ${props => props.theme.colors.dark60};
  display: table;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
`

const FiltersTitle = React.memo((props: IProps) => {
  const { className, children } = props

  return (
    <Title className={className} aria-hidden>
      {children}
    </Title>
  )
})

FiltersTitle.displayName = 'FiltersTitle'

export default FiltersTitle
