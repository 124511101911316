import {
  hrAdminDashboardRoute,
  journeyMapRoute,
  managerDashboardRoute
} from 'src/routes/constants'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import get from 'lodash.get'
import { UserRole } from '../react-app-env'
import QueryExecutor from '../graphql/QueryExecutor'
import { queryLeaveBasic } from '../graphql/queries'
import { getIsEmployee, getIsHrAdmin, getIsManager } from './userUtils'
import { NavigateFunction } from 'react-router-dom'

export default (
  user: IUser,
  navigate: NavigateFunction,
  client: ApolloClient<NormalizedCacheObject>
): Promise<void> =>
  new Promise(resolve => {
    // Current logic is here LM-754
    if (!user || !user.roles || !client) {
      resolve()
      return
    }

    const roles: UserRole[] = user.roles as UserRole[]
    const isEmployee: boolean = getIsEmployee(roles)
    const isManager: boolean = getIsManager(roles)
    const isHrAdmin: boolean = getIsHrAdmin(roles)

    const goToManagerOrHrAdmin = () => {
      if (isManager) {
        navigate(managerDashboardRoute)
      } else if (isHrAdmin) {
        navigate(hrAdminDashboardRoute)
      }
      resolve()
    }

    if (isEmployee && roles.length === 1) {
      navigate(journeyMapRoute)
      resolve()
    } else if (isEmployee && roles.length > 1) {
      QueryExecutor(
        async () => {
          const leaveResult: any = await client.query({
            query: queryLeaveBasic,
            fetchPolicy: 'cache-first'
          })
          const leave: ILeave = get(leaveResult, 'data.leave')
          if (leave) {
            navigate(journeyMapRoute)
            resolve()
          } else {
            goToManagerOrHrAdmin()
          }
        },
        navigate,
        {
          unhandled: goToManagerOrHrAdmin,
          notFound: goToManagerOrHrAdmin
        }
      )
    } else {
      goToManagerOrHrAdmin()
    }
  })
