import { useEffect } from 'react'

export default (element: any, callback: () => void) => {
  useEffect(() => {
    if (!element || !callback) {
      return
    }

    const onKeyDown = (event: any) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault()
        callback()
      }
    }

    element.addEventListener('keydown', onKeyDown)

    return () => {
      element.removeEventListener('keydown', onKeyDown)
    }
  }, [element, callback])
}
