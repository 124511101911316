import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import useAnalytics, {
  AnalyticsChartKind,
  AnalyticsNumbersType
} from 'src/graphql/hooks/useAnalytics'
import HrAdminCard from 'src/features/HrAdmin/components/Card'
import Layout from 'src/features/HrAdmin/components/Layout'
import BlockContentValue from 'src/features/HrAdmin/components/BlockContentValue'
import HrAdminBlock, {
  HrAdminBlockAppearance
} from 'src/features/HrAdmin/components/Block'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'src/utils/stringUtils'
import {
  queryAnalyticsUsersAllTime,
  queryAnalyticsUsersWeeks,
  queryAnalyticsUsersWeeksNoChart
} from 'src/graphql/queries'
import styled from 'styled-components'
import UsersChartResults from 'src/features/HrAdmin/components/UsersChartResults'
import LoadingSpinner from 'src/components/LoadingSpinner'
import ScreenContext from 'src/contexts/ScreenContext'
import HrAdminContext, {
  IHrAdminContext
} from 'src/features/HrAdmin/HrAdminContext'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FirstCard = styled(HrAdminCard)`
  margin-top: 0;
`

export const AnalyticsUsers = () => {
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useContext(ScreenContext)
  const { selectedAnalyticsType, selectedCountryCode }: IHrAdminContext =
    useContext(HrAdminContext)

  const [activeNumberType, setActiveNumberType] =
    useState<AnalyticsNumbersType>('uniqueUsers')
  const [selectedFilterIndex, setSelectedFilterIndex] = useState<number>(0)

  useEffect(() => {
    setActiveNumberType(selectedAnalyticsType ? 'timelineUsers' : 'uniqueUsers')
  }, [selectedAnalyticsType, setActiveNumberType])

  const { analytics, loading } = useAnalytics({
    query:
      selectedFilterIndex === 1
        ? isDesktop
          ? queryAnalyticsUsersWeeks
          : queryAnalyticsUsersWeeksNoChart
        : queryAnalyticsUsersAllTime,
    kind: capitalizeFirstLetter(activeNumberType) as AnalyticsChartKind,
    type: selectedAnalyticsType,
    countryCode: selectedCountryCode,
    period: selectedFilterIndex === 0 ? -1 : 4,
    skipsChart: isMobile
  })

  const getNumberBlockView = useCallback(
    (type: AnalyticsNumbersType) => {
      const appearance: HrAdminBlockAppearance = isDesktop
        ? type === activeNumberType
          ? 'active'
          : 'inactive'
        : null
      const onClick: () => void = () => {
        setActiveNumberType(type)
      }

      const value: number = analytics.numbers[type]

      return (
        <HrAdminBlock
          title={t(`hrAdmin.analytics.labels.${type}`, { count: value })}
          appearance={appearance}
          onClick={onClick}
        >
          <BlockContentValue
            value={value.toString()}
            appearance={appearance}
            showsPlaceholder={value === -1}
          />
        </HrAdminBlock>
      )
    },
    [t, analytics.numbers, setActiveNumberType, activeNumberType, isDesktop]
  )

  const filters = useMemo(
    () => [
      {
        options: [
          t('hrAdmin.lastTwelveMonths'),
          t('hrAdmin.lastNumWeeks', { weeks: 4 })
        ],
        selectedIndex: selectedFilterIndex,
        onOptionSelected: (index: number) => {
          setSelectedFilterIndex(index)
        }
      }
    ],
    [t, selectedFilterIndex, setSelectedFilterIndex]
  )

  return useMemo(
    () => (
      <FirstCard
        title={t('hrAdmin.analytics.users.title')}
        description={t('hrAdmin.analytics.users.description')}
        filters={filters}
      >
        <ContentContainer>
          <Layout minBlockWidth={400}>
            {loading ? (
              <SpinnerContainer>
                <LoadingSpinner fadesIn fullScreen={false} />
              </SpinnerContainer>
            ) : isDesktop ? (
              <UsersChartResults
                data={analytics.chart.data}
                numberType={activeNumberType}
              />
            ) : null}
          </Layout>
          <Layout minBlockWidth={isDesktop ? 200 : 100}>
            {!selectedAnalyticsType && getNumberBlockView('uniqueUsers')}
            {getNumberBlockView('timelineUsers')}
            {getNumberBlockView('journeyUsers')}
          </Layout>
        </ContentContainer>
      </FirstCard>
    ),
    [
      selectedAnalyticsType,
      getNumberBlockView,
      t,
      filters,
      analytics,
      loading,
      activeNumberType,
      isDesktop
    ]
  )
}

export default AnalyticsUsers
