import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Priority } from 'src/react-app-env'
import * as LeaveUtils from 'src/utils/leaveUtils'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'

interface IProps {
  className?: string
  priority: Priority
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
`

const Label = styled.span<{ $hovered?: boolean }>`
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  user-select: none;
  font-size: 14px;
  margin-left: 12px;

  ${props => {
    if (props.$hovered && props.theme.isDesktop) {
      return css`
        transform: translateX(2px);
      `
    }
  }}
`

const IconWrapper = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TypeFilterLabel = React.memo((props: IProps) => {
  const { className, priority } = props
  const { t } = useTranslation()
  const icon = useMemo(
    () => (
      <IconWrapper
        name={LeaveUtils.getIconNameByJourneyMapItemType(priority)}
        settings={{
          fill: LeaveUtils.getFilterPropsByJourneyMapItemType(priority).color,
          width: '12'
        }}
      />
    ),
    [priority]
  )

  return (
    <Container className={className}>
      {icon}
      <Label aria-hidden>{t(`notificationSettings.${priority}`)}</Label>
    </Container>
  )
})

TypeFilterLabel.displayName = 'TypeFilterLabel'

export default TypeFilterLabel
