import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IButton } from 'src/react-app-env'
import { Icon } from 'src/UIKit'
import useElementFocused from 'src/components/hooks/useElementFocused'
import { buttonWrapperAppearanceMixin } from 'src/theme/utils'

const Content = styled.div<{ $showChildByCenter: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props =>
    props.$showChildByCenter ? 'center' : 'space-between'};
  align-items: center;
  pointer-events: none;
`

const ChildrenWrapper = styled.div<{ $showChildByCenter: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  width: ${props => (props.$showChildByCenter ? 'fit-content' : '100%')};
`

const Wrapper = styled.button<any>`
  cursor: pointer;
  outline: none;
  display: inline-block;
  border: none;
  transition: all 100ms ease;
  width: auto;
  height: 48px;
  padding: 0 16px;
  font-weight: normal;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  border-radius: 16px;

  ${buttonWrapperAppearanceMixin}
`

const Button = React.memo(
  React.forwardRef((props: IButton, ref: any) => {
    const {
      children,
      className,
      icon,
      appearance,
      disabled,
      onClick,
      type,
      onMouseOut,
      onMouseOver,
      ariaLabel,
      showChildByCenter
    } = props

    const focused: boolean = useElementFocused(ref?.current)

    useEffect(() => {
      if (!ref) {
        return
      }
      const { current } = ref
      if (current && !focused) {
        current.blur()
      }
    }, [ref, focused])

    const content = icon ? (
      <Content $showChildByCenter={showChildByCenter}>
        <Icon name={icon.iconName} settings={icon.iconProps} />
        <ChildrenWrapper $showChildByCenter={showChildByCenter}>
          {children}
        </ChildrenWrapper>
      </Content>
    ) : (
      children
    )

    return (
      <Wrapper
        data-testid={props['data-testid']}
        ref={ref}
        className={className}
        onClick={onClick}
        type={type}
        disabled={disabled}
        $disabled={disabled}
        $appearance={appearance || 'default'}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        aria-label={ariaLabel}
      >
        {content}
      </Wrapper>
    )
  })
)

Button.displayName = 'Button'

export default Button
