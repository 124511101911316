import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import CompletedView from 'src/components/JourneyMap/CompletedView'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'

interface IProps {
  className?: string
  completed: boolean
  onItemCompletedClick: () => void
}

export const CompletedViewWrapper = styled(CompletedView)`
  grid-area: check;
  place-self: center;
  align-self: center;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 16px;
        `
      : css`
          margin: 8px 16px;
        `}
`

const JourneyMapListItemCompletedView = React.memo((props: IProps) => {
  const { className, completed, onItemCompletedClick } = props
  const managerJourneyMapContext: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )

  return (
    <CompletedViewWrapper
      className={className}
      completed={completed}
      disabled={
        managerJourneyMapContext && managerJourneyMapContext.isArchiveMode
      }
      onClick={onItemCompletedClick}
    />
  )
})

JourneyMapListItemCompletedView.displayName = 'JourneyMapListItemCompletedView'

export default JourneyMapListItemCompletedView
