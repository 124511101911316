export const SECTION_USER = '#u'
export const SECTION_NOTIFICATION_SETTINGS = '#ns'
export const SECTION_ACTIVATE_MANAGER = '#am'
export const SECTION_PRIVACY_CONTROLS = '#pc'
export const SECTION_CLAIM_DATA = '#cd'
export const SECTION_SESSIONS = '#as'
export const SECTION_PLAN_HISTORY = '#ph'
export const SECTION_CLOSE_PLAN = '#cp'

export const generateAnchor = (title: string) =>
  title.match(/(\w+)/g).join('_').toLowerCase()
