import merge from 'lodash.merge'
import { defaultCustomerConfig, ICustomerConfig } from './config'
import logo from 'src/assets/images/ibm-logo.svg'

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    companyName: () => 'IBM',
    logo,
    tpa: 'MetLife',
    leave: {
      create: {
        preferences: ['starter', 'reliever', 'middler'],
        workflow: {
          Parental: {
            parental: ({ selectedLeaveType }) =>
              selectedLeaveType?.type !== 'Pregnancy'
                ? 'selectLeavePreference'
                : null,
            selectLeavePreference: null
          }
        }
      }
    }
  })
