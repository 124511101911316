import React from 'react'
import styled, { css } from 'styled-components'
import { MarkdownText } from 'src/UIKit'
import { LinkRenderer, TelUrlTransformer } from 'src/utils/contentRenderUtils'

interface IProps {
  'data-testid'?: string
  marginBottom?: string
  components?: any
  children: any
}

const Text = styled(MarkdownText)<{ $marginBottom: string }>`
  width: 100%;
  white-space: pre-wrap;
  margin-bottom: ${props => props.$marginBottom || '36px'};

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }
  }

  ul {
    list-style: disc;
    padding-left: 2em;
    white-space: normal;
    margin-block-start: -20px;

    ${props =>
      props.theme.isMobile &&
      css`
        font-size: 14px;
      `}
  }

  ol {
    list-style: auto;
    padding-left: 24px;
    white-space: normal;
  }
`

export const CreateLeaveDescription = React.memo((props: IProps) => {
  const { children, marginBottom } = props
  const components = props.components || { a: LinkRenderer }
  return (
    <Text
      data-testid={props['data-testid']}
      $marginBottom={marginBottom}
      allowsParagraph
      urlTransform={TelUrlTransformer}
      components={components}
    >
      {children}
    </Text>
  )
})

CreateLeaveDescription.displayName = 'CreateLeaveDescription'

export default CreateLeaveDescription
