import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import createTheme from 'src/theme'
import { useTranslation } from 'react-i18next'
import { Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: string
  onClick?: () => void
  appearance?: BackButtonAppearance
  ariaLabel?: string
}

export type BackButtonAppearance = 'default' | 'bow_arrow'

const { colors }: any = createTheme()

const Container = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.theme.isDesktop &&
    css`
      height: 52px;
    `}

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 3px;
  }
`

const Text = styled.span<{ $appearance: string; $hovered: boolean }>`
  font-style: normal;
  font-weight: normal;
  color: ${props =>
    props.$hovered ? props.theme.colors.main110 : props.theme.colors.main100};

  ${(props: any) => {
    if (props.$appearance === 'bow_arrow') {
      return css`
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        margin-left: 16px;
      `
    }

    if (props.theme.isDesktop) {
      return css`
        font-size: 20px;
        line-height: 100%;
        margin-left: 24px;
      `
    } else {
      return css`
        font-size: 14px;
        line-height: 150%;
        margin-left: 12px;
      `
    }
  }}
`

const BackButton = React.memo((props: IProps) => {
  const { className, onClick, appearance, ariaLabel } = props
  const [hovered, setHovered] = useState(false)
  const theAppearance: BackButtonAppearance = appearance || 'default'
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const renderIcon = () => {
    const fill: string = hovered ? colors.main110 : colors.main100
    if (theAppearance === 'bow_arrow') {
      return <Icon name={'arrow_bow_right'} settings={{ fill }} />
    }

    return (
      <Icon
        name={isDesktop ? 'arrow_back' : 'arrow_back_small'}
        settings={{ fill }}
      />
    )
  }

  return (
    <Container
      className={className}
      onClick={onClick}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      aria-label={ariaLabel}
    >
      {renderIcon()}
      <Text $hovered={hovered} $appearance={theAppearance}>
        {t('common.back')}
      </Text>
    </Container>
  )
})

BackButton.displayName = 'BackButton'

export default BackButton
