import { Moment } from 'moment'

export interface IMonth {
  title: string
  date: Moment
  items?: IJourneyMapItem[]
  fadesOut?: boolean
  animatesMarginTop?: boolean
}

const getMonths = (items: IJourneyMapItem[]): IMonth[] => {
  const temp: any = {}
  items.forEach((item: IJourneyMapItem) => {
    const title: string = item.dueDate.format('MMM YYYY')

    if (!temp[title]) {
      temp[title] = { date: item.dueDate, title, items: [] }
    }

    temp[title].items.push(item)
  })

  let nextAnimatesMarginTop = false
  let result: IMonth[] = Object.values(temp)
  result.sort((m1: IMonth, m2: IMonth) => m1.date.diff(m2.date))
  result = result.map((m: IMonth) => {
    if (nextAnimatesMarginTop) {
      m.animatesMarginTop = true
    }
    nextAnimatesMarginTop = false
    if (m.items.length === 1 && (m.items[0] as any).animatesCompleted) {
      m.fadesOut = true
      nextAnimatesMarginTop = true
    }
    return m
  })

  return result
}

export { getMonths }
