import React, { Fragment, useRef, useEffect, useContext } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { H1, Icon } from 'src/UIKit'
import { IWithQueriesProps } from 'src/react-app-env'
import theme from 'src/theme'
import { openItem } from 'src/features/Notifications/methods'
import withQueries from 'src/components/HOC/withQueries'
import zIndex from 'src/constants/zIndex'
import RouteContext from 'src/routes/Context'
import usePageTitle from 'src/components/hooks/usePageTitle'
import { mobileButtonMaxWidthMixin, mobileMaxWidthMixin } from 'src/theme/utils'
import NotificationsList from '../NotificationsList'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const { colors } = theme()

interface IProps extends IWithQueriesProps {
  records: INotification[]
  fetching: boolean
  fetchCount: number
  onMarkAllAsReadClicked: () => void
  onScrolledToBottom: () => void
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.backgroundColor};
`

export const Grid = styled.div`
  display: grid;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: 100%;
          grid-template-columns: auto 680px auto;
          grid-template-rows: repeat(3, min-content);
          grid-template-areas:
            '. title .'
            '. markAll .'
            '. list .';
        `
      : css`
          width: 100%;
          grid-template-columns: 1fr;
          grid-template-rows: min-content min-content;
          grid-template-areas:
            'title'
            'list';
        `}
`

const TitleWrapper = styled(H1)`
  grid-area: title;
  justify-self: center;
`

const MarkAllAsReadWrapper = styled.div`
  grid-area: markAll;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          flex-direction: row-reverse;
          padding: 0 20px;
        `
      : css`
          height: 80px;
          position: fixed;
          width: 100%;
          bottom: 0;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: rgb(255 255 255 / 80%);
          box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
          z-index: ${zIndex.notifications.page.markAllAsReadWrapper};
        `}
`

export const MarkAllAsReadButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 8px 0;
          margin: 8px 0;
        `
      : css`
          padding: 16px 0;
          max-width: 414px;
        `}
  color: ${props => props.theme.colors.main90};

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
    outline-offset: 2px;
  }

  ${props =>
    props.theme.isMobile &&
    css`
      font-size: 17px;
      border: 1px solid ${props.theme.colors.main100};
      width: calc(100% - 32px);
      text-align: center;
      justify-content: center;
      border-radius: 16px;

      &:active {
        transition: all 200ms;
        background: ${props.theme.colors.main40};
        color: ${props.theme.colors.light100};
      }
    `}

  ${mobileButtonMaxWidthMixin};
`

const ListWrapper = styled(NotificationsList)`
  grid-area: list;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 0 20px;
        `
      : css`
          margin-top: 16px;
          justify-self: center;
          max-width: unset;
          width: 100%;
        `}

  ${mobileMaxWidthMixin};
`

const rotateFeyFrames = keyframes`
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
}
`

const FetchingImage = styled.div`
  grid-area: list;
  width: 12px;
  height: 20px;
  place-self: end center;
  transform-origin: center center;
  animation: ${rotateFeyFrames} 2s linear 0s infinite;
`

export const dataAttrs = {
  grid: () => 'notifications-grid'
}

export const NotificationsPage = React.memo((props: IProps) => {
  const {
    onMarkAllAsReadClicked,
    records,
    fetching,
    fetchCount,
    onScrolledToBottom
  } = props
  const navigate = useNavigate()

  const { t } = useTranslation()

  const hasRecords = records && records.length !== 0
  const scrollViewRef = useRef(null)

  usePageTitle('notifications')

  useEffect(() => {
    if (!hasRecords) {
      return
    }

    const scrollView: any = scrollViewRef.current
    if (!scrollView) {
      return
    }

    if (
      records.length === fetchCount &&
      onScrolledToBottom &&
      scrollView.clientHeight === scrollView.scrollHeight
    ) {
      onScrolledToBottom()
    }
  })

  const onScroll = (event: any) => {
    const { target } = event
    if (
      onScrolledToBottom &&
      target.scrollTop >= target.scrollHeight - target.offsetHeight
    ) {
      onScrolledToBottom()
    }
  }

  const { notificationType } = useContext(RouteContext)

  return (
    <Container>
      <Grid
        data-testid={dataAttrs.grid()}
        onScroll={onScroll}
        ref={scrollViewRef}
      >
        <TitleWrapper>{t('common.notifications')}</TitleWrapper>
        {hasRecords && (
          <MarkAllAsReadWrapper>
            <MarkAllAsReadButton onClick={onMarkAllAsReadClicked}>
              {t('common.markAllAsRead')}
            </MarkAllAsReadButton>
          </MarkAllAsReadWrapper>
        )}
        <Fragment>
          <ListWrapper
            items={records}
            fetching={fetching}
            onItemClicked={notification => {
              openItem(
                notification,
                navigate,
                props.queries.readNotification,
                notificationType
              )
            }}
          />
          {hasRecords && fetching && (
            <FetchingImage>
              <Icon
                name={'hourglass'}
                settings={{ fill: colors.main100 }}
                ariaLabel={t('common.accessibilityText.loadingIcon')}
              />
            </FetchingImage>
          )}
        </Fragment>
      </Grid>
    </Container>
  )
})

NotificationsPage.displayName = 'NotificationsPage'

export default withQueries(NotificationsPage)
