import React, { useCallback, useContext, useMemo } from 'react'
import { ITimelinePeriod, TimelinePeriodType } from 'src/react-app-env'
import { ButtonsContainer, Button } from '../Footer'
import { useTranslation } from 'react-i18next'
import TimelineContext from 'src/features/Timeline/Context'
import TimelineViewContext, {
  ITimelineViewContext
} from 'src/features/Timeline/components/vertical/TimelineView/TimelineViewContext'
import { getChangesForPeriodIntermittent } from 'src/features/Timeline/components/vertical/LeaveDurationPickers/methods'
import { getHidesDetails } from '../Footer/methods'

interface IProps {
  period: ITimelinePeriod
  type: TimelinePeriodType
}

export const dataAttrs = {
  convertBtn: () => 'convert-period-type-convert-btn',
  detailsBtn: () => 'convert-period-type-details-btn'
}

const ConvertPeriodTypeRow = React.memo((props: IProps) => {
  const { period, type } = props
  const { t } = useTranslation()
  const { leave, onNewChanges } = useContext(TimelineContext)
  const { onDetailsClick, selectedDetailsPeriod }: ITimelineViewContext =
    useContext(TimelineViewContext)

  const persistChanges = type === 'AtWork'

  const detailsPeriod = useMemo(
    () => ({
      ...period,
      appearance: 'Addable' as IPeriodItemAppearanceType,
      type,
      timelineConfig: {
        ...period.timelineConfig,
        title: t(`timeline.periodByTimelinePeriodType.${type}`),
        detailsType: type
      }
    }),
    [t, period, type]
  )

  const clickConvert = useCallback(() => {
    const changes = getChangesForPeriodIntermittent({
      period: { ...period, type },
      intermittentBlockChanges: { startDate: period.periodStart.min }
    })
    onNewChanges(changes, persistChanges)
  }, [period, type, onNewChanges, persistChanges])

  return (
    <ButtonsContainer>
      <Button data-testid={dataAttrs.convertBtn()} onClick={clickConvert}>
        {t(`timeline.classifiable.${leave.type}.${period.type}.${type}`)}
      </Button>
      {!getHidesDetails(detailsPeriod) && (
        <Button
          data-testid={dataAttrs.detailsBtn()}
          onClick={() =>
            onDetailsClick(
              selectedDetailsPeriod?.type === type ? null : detailsPeriod
            )
          }
        >
          {t(
            selectedDetailsPeriod?.type === type
              ? 'common.hideDetails'
              : 'common.details'
          )}
        </Button>
      )}
    </ButtonsContainer>
  )
})

ConvertPeriodTypeRow.displayName = 'ConvertPeriodTypeRow'

export default ConvertPeriodTypeRow
