import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Priority } from 'src/react-app-env'
import FilterCheckbox from 'src/components/JourneyMap/Filters/Checkbox'
import { useTranslation } from 'react-i18next'
import Container from '../RowContainer'
import FilterLabel from '../FilterLabel'

interface IProps {
  className?: string
  checked: boolean
  disabled: boolean
  onClick: (index: number) => void
  index: number
  priority: Priority
}

const FilterLabelWrapper = styled(FilterLabel)`
  width: 100%;
`

const PriorityRow = React.memo(
  (props: IProps) => {
    const { className, checked, onClick, index, disabled, priority } = props
    const { t } = useTranslation()

    const ariaLabel: string = useMemo(
      () =>
        t(`notificationSettings.${priority}`) +
        ' ' +
        t('common.priority').toLocaleLowerCase(),
      [t, priority]
    )

    return (
      <Container
        className={className}
        onClick={onClick}
        disabled={disabled}
        index={index}
      >
        <FilterLabelWrapper priority={priority} />
        <FilterCheckbox
          checked={checked}
          index={index}
          disabled={disabled}
          ariaLabel={ariaLabel}
        />
      </Container>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.onClick === nextProps.onClick &&
    prevProps.checked === nextProps.checked &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.priority === nextProps.priority &&
    prevProps.index === nextProps.index
)

PriorityRow.displayName = 'PriorityRow'

export default PriorityRow
