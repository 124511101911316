import React from 'react'
import LeftPanelContainer from 'src/components/JourneyMap/LeftPanelContainer'
import { useTranslation } from 'react-i18next'
import OverdueView, { IOverdueItemsProps } from '../OvedueView'

interface IProps extends IOverdueItemsProps {
  children: any
  className?: string
  useShortTopMargin?: boolean
}

const LeftPanel = React.memo(
  (props: IProps) => {
    const {
      children,
      className,
      overdueItemsCount,
      onOverdueClick,
      showOverdueAsRed,
      useShortTopMargin
    } = props
    const { t } = useTranslation()

    const renderOverdue = () => {
      if (!overdueItemsCount) {
        return null
      }

      return (
        <OverdueView
          showOverdueAsRed={showOverdueAsRed}
          overdueItemsCount={overdueItemsCount}
          onOverdueClick={onOverdueClick}
        />
      )
    }

    return (
      <LeftPanelContainer
        className={className}
        ariaLabel={t('common.accessibilityText.regionJourneyInfo')}
        useShortTopMargin={useShortTopMargin}
      >
        {renderOverdue()}
        {children}
      </LeftPanelContainer>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.children === nextProps.children &&
    prevProps.overdueItemsCount === nextProps.overdueItemsCount &&
    prevProps.showOverdueAsRed === nextProps.showOverdueAsRed
)

LeftPanel.displayName = 'LeftPanel'

export default LeftPanel
