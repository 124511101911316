import { NavigateFunction } from 'react-router-dom'
import { transitionFlowRoute } from 'src/routes/constants'

export const checkTransitionFlow = (
  leave: ILeave,
  navigate: NavigateFunction,
  skip: boolean
) => {
  if (!leave || skip) {
    return
  }

  const transitions = leave?.transitions

  transitions?.forEach((transition: any) => {
    if (transition.type === 'DisabilityToBonding') {
      navigate(transitionFlowRoute, {
        state: {
          flow: transition.type,
          disabilityEnd: transition.extra.disabilityPeriodEndDate
        }
      })
    }
  })
}
