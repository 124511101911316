import React, { useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import moment, { Moment } from 'moment'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import Container from './components/Container'
import Date from './components/Date'
import ScreenContext from 'src/contexts/ScreenContext'
import { getAbsencePeriodDurationString } from 'src/utils/absences'

interface IProps {
  absence: IAbsence
  selected?: boolean
  onClick: (absence: IAbsence) => void
  badgeNumber: number
}

const DatesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const BadgeText = styled.span`
  visibility: visible;
  font-size: 13px;
  line-height: 135%;
  text-align: center;
  color: ${props => props.theme.colors.dark50};
  border: 1px solid ${props => props.theme.colors.dark50};
  border-radius: 50%;
  box-sizing: border-box;
  min-width: 24px;
  min-height: 24px;
  padding-top: 2px;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 10px;
        `
      : css`
          margin-right: 8px;
        `}
`

const Comma = styled.span`
  ::before {
    content: ',';
  }
`

const Text = styled.span`
  margin-left: 12px;
  font-weight: 500;
  font-family: Roboto, serif;
  line-height: 100%;
  font-size: ${props => (props.theme.isDesktop ? '14px' : '12px')};
  color: ${props => props.theme.colors.dark60};
`

const ArrowWrapper = styled(Icon)`
  margin: 4px 9px;
`

export const arePropsEqual = (prev: IProps, next: IProps) => {
  if (prev.selected !== next.selected) {
    return false
  }
  if (prev.absence !== next.absence) {
    return false
  }
  if (prev.badgeNumber !== next.badgeNumber) {
    return false
  }

  return true
}

const AbsenceArchivedListItem = React.memo((props: IProps) => {
  const { absence, selected, onClick, badgeNumber } = props
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)

  const { startDate, endDate } = absence

  const start: Moment = useMemo(() => moment(startDate), [startDate])
  const end: Moment = useMemo(() => moment(endDate), [endDate])
  const ariaLabel: string = useMemo(
    () =>
      t(
        `common.accessibilityText.${
          badgeNumber === 0 ? 'archivedAbsenceItem' : 'archivedAbsenceNumItem'
        }`,
        {
          num: badgeNumber,
          start: start.format('MMM DD, YYYY'),
          end: end.format('MMM DD, YYYY')
        }
      ),
    [badgeNumber, start, end, t]
  )

  const leaveLength = useMemo(
    () =>
      absence &&
      getAbsencePeriodDurationString(
        {
          type: '',
          startDate: absence.startDate,
          endDate: absence.endDate
        },
        t
      ),
    [absence, t]
  )

  return (
    <Container
      selected={selected}
      onClick={() => {
        onClick(absence)
      }}
      showsSeparator={false}
      ariaLabel={ariaLabel}
    >
      <BadgeText aria-hidden>{badgeNumber}</BadgeText>
      <DatesContainer aria-hidden>
        <Date date={start} />
        <ArrowWrapper name={'arrow_right_small'} />
        <Date date={end} />
        {isDesktop && (
          <>
            <Comma />
            <Text>{leaveLength.humanReadableFullString}</Text>
          </>
        )}
      </DatesContainer>
    </Container>
  )
}, arePropsEqual)

AbsenceArchivedListItem.displayName = 'AbsenceArchivedListItem'

export default AbsenceArchivedListItem
