import merge from 'lodash.merge'
import { defaultCustomerConfig, ICustomerConfig } from './config'
import logo from 'src/assets/images/roche-logo.svg'

export default (): ICustomerConfig =>
  merge(defaultCustomerConfig(), {
    logo,
    tpa: 'Sedgwick',
    leave: {
      timeline: {
        hasDynamicItemID: () => true
      },
      create: {
        workflow: {
          Parental: {
            parental: 'selectWorkSchedule',
            selectWorkSchedule: (context: any) => {
              switch (context.workSchedule) {
                case 'twoWeekRotation':
                  return 'setupTwoWeekSchedule'
                case 'other':
                  return 'setupOneWeekSchedule'
                default:
                  return 'selectState'
              }
            },
            setupTwoWeekSchedule: 'selectState',
            setupOneWeekSchedule: 'selectState',
            selectState: null
          }
        }
      }
    },
    showInfoPage: true
  })
