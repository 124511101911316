import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import createTheme from 'src/theme'
import { printDisplayNoneMixin } from 'src/theme/utils'
import { Icon } from 'src/UIKit'
import { KEY_ENTER, KEY_SPACE } from 'src/constants/keys'

interface IProps {
  onClick: () => void
}

const { colors } = createTheme()

const Container = styled.div`
  transition: background 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  height: calc(40px - 32px);
  border-radius: 16px;
  background: ${props => props.theme.colors.main100};
  padding: 16px;

  &:hover {
    background: ${props => props.theme.colors.main110};
  }

  &:focus {
    outline: none;
    background: ${props => props.theme.colors.main110};
  }

  ${printDisplayNoneMixin};
`

const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.colors.light100};
  margin-left: 14px;
`

const BabyHasArrivedButton = React.memo((props: IProps) => {
  const { onClick } = props
  const { t } = useTranslation()

  const onClickCallback = useCallback(
    (event: any) => {
      event.stopPropagation()
      onClick()
    },
    [onClick]
  )

  const keyHandler = useCallback(
    (event: any) => {
      switch (event.which) {
        case KEY_ENTER:
        case KEY_SPACE: {
          onClickCallback(event)
          break
        }
      }
    },
    [onClickCallback]
  )

  return (
    <Container tabIndex={0} onKeyDown={keyHandler} onClick={onClickCallback}>
      <Icon
        name={'baby_head'}
        settings={{ fill: colors.light100, width: '20', height: '20' }}
      />
      <Text>{t('timeline.babyHasArrived')}</Text>
    </Container>
  )
})

BabyHasArrivedButton.displayName = 'BabyHasArrivedButton'

export default BabyHasArrivedButton
