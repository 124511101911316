import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import isEqual from 'lodash.isequal'
import zIndex from 'src/constants/zIndex'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { ITimelinePeriodDatesRange } from 'src/utils/leaveUtils'
import { ITimelinePeriod } from 'src/react-app-env'
import TimelineDetailsViewTitleDesktop from 'src/features/Timeline/components/vertical/DetailsView/components/TitleDesktop'
import TimelineDetailsViewTitleMobile from 'src/features/Timeline/components/vertical/DetailsView/components/TitleMobile'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from 'src/theme/utils'
import PeriodDetailsView from './components/PeriodDetailsView'
import { isPeriodSynced } from 'src/utils/periodUtils'
import SharedContext from 'src/contexts/SharedContext'
import { header } from 'src/constants/frame'
import TpaInfoBlockPicker from '../LeaveDurationPickers/TpaInfoBlockPicker'

interface IProps {
  className?: string
  period: ITimelinePeriod
  onExit: () => void
  shown: boolean
  isDatePicker?: boolean
  containerWidth?: number
  timelineHeaderHeight?: number
}

const Container = styled.div<{
  $timelineHeaderHeight: number
  $containerWidth: number
  $bannerHeight: number
}>`
  overflow-y: hidden;
  z-index: ${zIndex.timeline.mobile.detailsView};
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: fixed;
          top: calc(
            ${props.$timelineHeaderHeight + props.$bannerHeight}px +
              ${header.height(props.theme).px}
          );
          width: ${props.$containerWidth - 24}px;
          height: calc(
            100vh - ${props.$timelineHeaderHeight + props.$bannerHeight + 60}px -
              ${header.height(props.theme).px}
          );
          padding: 16px 0 0 12px;
          background: ${props.theme.colors.light100};
          border-left: 1px solid ${props.theme.colors.dark10};
          border-right: 1px solid ${props.theme.colors.dark10};
        `
      : css`
          position: absolute;
          top: calc(
            ${props.$bannerHeight}px + ${header.height(props.theme).px}
          );
          width: 100vw;
          height: calc(
            100vh - ${props.$bannerHeight}px - ${header.height(props.theme).px}
          );
          height: calc(
            var(--vh, 1vh) * 100 - ${props.$bannerHeight}px -
              ${header.height(props.theme).px}
          );
          background: ${props.theme.colors.backgroundColor};
        `}
`

const ContentContainer = styled.div<{
  $bannerHeight: number
}>`
  width: 100%;
  overflow-y: auto;

  ${props =>
    props.theme.isMobile &&
    css`
      height: calc(
        100vh - ${props.$bannerHeight}px - ${header.height(props.theme).px} -
          60px
      );
      height: calc(
        var(--vh, 1vh) * 100 - ${props.$bannerHeight}px -
          ${header.height(props.theme).px} - 60px
      );
      margin: 0 auto;
    `}
  ${mobileMaxWidthMixin};
`

const propsAreEqual = (prev: IProps, next: IProps): boolean =>
  isEqual(prev, next)

const DetailsView = React.memo((props: IProps) => {
  const {
    className,
    period,
    onExit,
    isDatePicker,
    containerWidth,
    timelineHeaderHeight
  } = props
  const { type } = period || {}
  const { t } = useTranslation()
  const { isDesktop } = useContext(ScreenContext)
  const { bannerContext } = useContext(SharedContext)
  const bannerHeight = bannerContext.bannerHeight

  // exit on swipe
  useEffect(() => {
    let xDown: number
    let yDown: number
    let time: number
    let isTouching = false

    const getTouches = (event: any) =>
      event.touches || event.originalEvent.touches

    const handleTouchStart = (event: any) => {
      const firstTouch = getTouches(event)[0]
      xDown = firstTouch.clientX
      yDown = firstTouch.clientY
      time = Date.now()
      isTouching = true
    }

    const handleTouchEnd = () => {
      isTouching = false
      xDown = 0
      yDown = 0
      time = 0
    }

    const handleTouchMove = (event: any) => {
      if (!isTouching) {
        return
      }
      const xUp = event.touches[0].clientX
      const yUp = event.touches[0].clientY
      const xDiff = xDown - xUp
      const yDiff = yDown - yUp
      const moveTime: number = Date.now() - time
      const velocity: number = Math.abs(xDiff / moveTime)
      if (
        Math.abs(xDiff) > Math.abs(yDiff) &&
        xDiff < 0 &&
        velocity > 2 &&
        velocity < 10
      ) {
        handleTouchEnd()
        onExit()
      }

      xDown = xUp
      yDown = yUp
      time = Date.now()
    }

    document.addEventListener('touchstart', handleTouchStart, false)
    document.addEventListener('touchend', handleTouchEnd, false)
    document.addEventListener('touchmove', handleTouchMove, false)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart, false)
      document.removeEventListener('touchend', handleTouchEnd, false)
      document.removeEventListener('touchmove', handleTouchMove, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title: string = useMemo(() => period?.timelineConfig?.title, [period])

  const periodContentView: ReactNode = useMemo(
    () => (
      <ContentContainer $bannerHeight={bannerHeight}>
        <PeriodDetailsView period={period} />
      </ContentContainer>
    ),
    [period, bannerHeight]
  )

  const periodDatesRange: ITimelinePeriodDatesRange = useMemo(() => {
    if (!period || !period.startDate || period.appearance === 'Addable') {
      return null
    }
    const start: Moment = period.startDate.clone()
    if (period.timelineConfig?.addsOneVisualDay) {
      start.add(1, 'day')
    }
    return {
      start: start.utc(),
      end: moment(period.endDate).utc()
    }
  }, [period])

  const contentsView: ReactNode = useMemo(() => {
    if (isDatePicker && period) {
      const Component = isPeriodSynced(period)
        ? TpaInfoBlockPicker
        : period.timelineConfig.datePickerComponent

      return <Component period={period} onExit={onExit} />
    }

    return periodContentView
  }, [isDatePicker, onExit, periodContentView, period])

  const titleView: ReactNode = useMemo(
    () =>
      isDesktop ? (
        <TimelineDetailsViewTitleDesktop datesRange={periodDatesRange}>
          {title}
        </TimelineDetailsViewTitleDesktop>
      ) : (
        <TimelineDetailsViewTitleMobile
          onArrowClick={!isDatePicker ? onExit : null}
        >
          {title}
        </TimelineDetailsViewTitleMobile>
      ),
    [title, isDatePicker, onExit, periodDatesRange, isDesktop]
  )

  const ariaLabel: string = useMemo(
    () => t('timeline.accessibility.periodDetails', { type: title }),
    [title, t]
  )

  if (!type) {
    return null
  }

  return (
    <Container
      className={className}
      $containerWidth={containerWidth}
      $timelineHeaderHeight={timelineHeaderHeight}
      $bannerHeight={bannerHeight}
    >
      <AccessibilitySelfFocusText ariaLabel={ariaLabel} />
      {titleView}
      {contentsView}
    </Container>
  )
}, propsAreEqual)

DetailsView.displayName = 'DetailsView'

export default DetailsView
