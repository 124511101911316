import { IRect } from 'src/components/hooks/useComponentRect'
import { footer, popoverOffset } from 'src/constants/frame'

interface IGetPopoverPositionInput {
  popoverRect: IRect
  parentRect: IRect
  innerWidth: number
  innerHeight: number
  limit: number
  customOffset: IPopoverPosition
  handleBottomOverlap: boolean
}

export interface IPopoverPosition {
  top?: number
  left?: number
}

export const getPopoverPosition = (
  input: IGetPopoverPositionInput
): IPopoverPosition => {
  const {
    popoverRect,
    parentRect,
    innerWidth,
    innerHeight,
    limit,
    customOffset,
    handleBottomOverlap
  } = input
  const minLeft: number = -parentRect.left + limit
  const maxLeft: number =
    innerWidth - parentRect.left - popoverRect.width - limit
  const customOffsetTop: number = customOffset?.top || 0
  const customOffsetLeft: number = customOffset?.left || 0

  let top: number = Math.max(parentRect.height + 8, limit)
  // If the popup is outside the bottom viewport, we move it to the top of the parent element
  if (
    handleBottomOverlap &&
    parentRect.bottom + popoverRect.height > innerHeight - footer.height().value
  ) {
    top += -popoverRect.height - parentRect.height + popoverOffset.top().value
  }

  let left: number = parentRect.width / 2 - popoverRect.width / 2
  left = Math.min(left, maxLeft)
  left = Math.max(left, minLeft)

  top += customOffsetTop
  left += customOffsetLeft

  return { top, left }
}
