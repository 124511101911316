import * as d3 from 'd3'
import createTheme from 'src/theme'
import moment, { Moment } from 'moment'
import {
  createTopRoundedVerticalPath,
  getAnimationDelay,
  getAnimationDuration,
  getVerticalAxisData,
  IVerticalAxisData
} from 'src/features/HrAdmin/components/charts/methods'
import zIndex from 'src/constants/zIndex'
import { HE_ADMIN_CONTENT_AREA_ID } from 'src/features/HrAdmin/components/ContentArea'
import { DATE_FORMAT_MMM_DD_YY } from 'src/utils/dateUtils'

const { colors } = createTheme()

const tooltipHeight = 58

let tooltipDiv: any = null

const showTooltip = (
  value: string,
  label: string,
  date: string,
  x: number,
  y: number
) => {
  tooltipDiv = document.createElement('div')
  tooltipDiv.setAttribute(
    'style',
    `
    position: absolute;
    top: ${y}px;
    left: ${x}px;
    z-index: ${zIndex.charts.tooltip};
    pointer-events: none;
    `
  )

  const tooltipInnerContainer = document.createElement('div')
  tooltipInnerContainer.setAttribute(
    'style',
    `
    background:${colors.light100};
    border-radius: 8px;
    position: relative;
    left: calc(-50% + 4px);
    height:${tooltipHeight}px;
    pointer-events: none;
    transition: opacity 100ms;
    opacity: 0;
    border: 1px solid ${colors.dark05};
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    `
  )

  requestAnimationFrame(() => {
    tooltipInnerContainer.style.opacity = '1'
  })

  const pDate: any = document.createElement('p')
  pDate.setAttribute(
    'style',
    `
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: ${colors.dark60};
  white-space: nowrap;
  `
  )
  pDate.innerText = date
  tooltipInnerContainer.appendChild(pDate)

  const valueContainer: any = document.createElement('div')
  valueContainer.setAttribute(
    'style',
    `
    display: flex;
    justify-content: center;
    max-height: 12px;
  `
  )
  tooltipInnerContainer.appendChild(valueContainer)

  const pValue: any = document.createElement('p')
  pValue.setAttribute(
    'style',
    `
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: ${colors.dark60};
  `
  )
  pValue.innerText = value
  valueContainer.appendChild(pValue)

  const pLabel: any = document.createElement('p')
  pLabel.setAttribute(
    'style',
    `
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: ${colors.dark60};
  margin-left: 4px;
  white-space: nowrap;
  `
  )
  pLabel.innerText = label
  valueContainer.appendChild(pLabel)

  tooltipDiv.appendChild(tooltipInnerContainer)
  document.body.appendChild(tooltipDiv)
}

const hideTooltip = (): any => {
  try {
    document.body.removeChild(tooltipDiv)
  } catch (_) {
    return undefined
  }
}

const getTooltipDateString = (m1: Moment, m2: Moment): string => {
  if (!m1.isSame(m2, 'year')) {
    return `${m1.format('MMM DD, YYYY')} - ${m2.format('MMM DD, YYYY')}`
  }
  if (!m1.isSame(m2, 'month')) {
    return `${m1.format('MMM DD')} - ${m2.format('MMM DD')}, ${m2.format(
      'YYYY'
    )}`
  }
  return `${m1.format('MMM')} ${m1.format('DD')} - ${m2.format(
    'DD'
  )}, ${m2.format('YYYY')}`
}

export const dataAttrs = {
  container: () => 'chart-container'
}

export default (args: any, parentContainer: any) => {
  if (!args.data || !Array.isArray(args.data)) {
    return
  }

  const { t, translationKey } = args

  const data = [...args.data].map((d: any) => {
    const date = moment(d.date)
    return {
      ...d,
      date,
      label: date.format(DATE_FORMAT_MMM_DD_YY)
    }
  })

  const animationDuration: number = getAnimationDuration(data.length)
  const animationDelay: number = getAnimationDelay(data.length)

  data.sort((a: any, b: any) => a.date.diff(b.date))

  const parentRect = parentContainer
    ? parentContainer.getBoundingClientRect()
    : { width: 0, height: 0 }
  const margin: any = { top: 8, right: 0, bottom: 34, left: 20 }
  const width: number = parentRect.width - margin.left - margin.right
  const height: number = parentRect.height - margin.top - margin.bottom

  const isMoreThan10Bars: boolean = data.length > 10
  const verticalAxisData: IVerticalAxisData = getVerticalAxisData(data)

  const tickEvery: number = isMoreThan10Bars
    ? parentRect.width > 900
      ? 4
      : 8
    : 1

  const x: any = d3.scaleBand().range([0, width]).padding(0.3)
  const y: any = d3.scaleLinear().range([height, 0])

  const xAxis: any = d3
    .axisBottom(x)
    .tickSize(1)
    .tickPadding(10)
    .tickFormat((d: any, index: number) => {
      if (data.length > 10 && index === data.length - 1) {
        return null
      }
      return index % tickEvery === 0 || index === data.length - 1 ? d : null
    })

  const yAxis: any = d3
    .axisLeft(y)
    .tickValues(verticalAxisData.tickValues)
    .tickFormat(d3.format('d') as any)
    .tickSize(0)

  x.domain(data.map((d: any) => d.label))
  y.domain([0, verticalAxisData.maxValue])

  const d3svg: any = d3
    .select(parentContainer)
    .append('svg')
    .attr('data-testid', dataAttrs.container())
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  d3svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .style('font-size', '10px')
    .style('color', colors.dark60)
    .call(xAxis)
  d3svg
    .append('g')
    .style('font-size', '10px')
    .style('color', colors.dark60)
    .call(yAxis)

  /**
   *
   * @param event
   * @param barData
   */
  function onMouseOver(event: MouseEvent, barData: any) {
    if (!isMoreThan10Bars) {
      return
    }
    d3.select(event.target as any).style('fill', colors.main110)

    const scrollView: any = document.getElementById(HE_ADMIN_CONTENT_AREA_ID)
    if (!scrollView) {
      return
    }

    const scrollViewRect = scrollView.getBoundingClientRect()
    const pr = parentContainer.getBoundingClientRect()
    let pX: number = pr.left + x(barData.label) + margin.left
    pX = Math.min(pX, scrollViewRect.right - 90)
    const pY: number = pr.top + y(barData.value) - tooltipHeight - 8
    const label: string = t(`hrAdmin.analytics.labels.${translationKey}`, {
      count: barData.value
    }).toLowerCase()
    const prevDate: Moment = moment(barData.date).subtract(6, 'days')
    const tooltip = getTooltipDateString(prevDate, barData.date)
    showTooltip(barData.value, label, tooltip, pX, pY)
  }

  /**
   *
   * @param event
   */
  function onMouseOut(event: MouseEvent) {
    if (!isMoreThan10Bars) {
      return
    }

    d3.select(event.target as any).style('fill', colors.main100)
    hideTooltip()
  }

  d3svg
    .selectAll('.bar')
    .data(data)
    .enter()
    .append('path')
    .style('display', (d: any) => (d.value === null ? 'none' : null))
    .style('fill', () => colors.main100)
    .attr('data', data)
    .attr('d', (d: any) =>
      createTopRoundedVerticalPath(x(d.label), height, x.bandwidth(), 0, 4)
    )
    .on('mouseover', onMouseOver)
    .on('mouseout', onMouseOut)
    .transition()
    .duration(animationDuration)
    .delay((d: any, i: number) => i * animationDelay)
    .attr('d', (d: any) =>
      createTopRoundedVerticalPath(
        x(d.label),
        height,
        x.bandwidth(),
        d.value === 0 ? -4 : y(d.value) - height,
        4
      )
    )

  d3svg
    .selectAll('.label')
    .data(data)
    .enter()
    .append('text')
    .style('display', (d: any) => (d.value === null ? 'none' : null))
    .style('font-size', `12px`)
    .style('color', colors.main100)
    .attr('x', (d: any) => x(d.label) + x.bandwidth() / 2)
    .attr('width', () => x.bandwidth())
    .attr('text-anchor', 'middle')
    .style('fill', () => colors.main100)
    .attr('y', () => height)
    .attr('height', 0)
    .transition()
    .duration(animationDuration)
    .delay((d: any, i: number) => i * animationDelay)
    .text((d: any) => d.value)
    .attr('y', (d: any) => {
      let v: number = y(d.value) + 0.1
      if (d.value === 0) {
        v -= 4
      }
      return v
    })
    .attr('dy', '-.3em')

  if (isMoreThan10Bars) {
    d3svg
      .selectAll('.verticalGrid')
      .data(data)
      .enter()
      .append('rect')
      .style('display', (d: any, i: number) =>
        i !== 0 && i % 4 === 0 ? null : 'none'
      )
      .style('fill', () => '#F0F0F0')
      .attr('x', (d: any) => x(d.label) - x.bandwidth() / 4)
      .attr('width', 1)
      .attr('y', 0)
      .attr('height', height)
  }
}
