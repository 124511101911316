const replaceSpecialSymbols = (value: string) => {
  let phoneNumber = value.slice(0)
  phoneNumber = phoneNumber.replace(/^00\s|[ ()-/_]/g, '')
  return phoneNumber
}

const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1)

const floatAmountToString = (amount: number) => {
  const floorAmount: number = Math.floor(amount)
  const value: number = amount - floorAmount
  if (value > 0.65 && value < 0.67) {
    return `${floorAmount} 2/3 %`
  }

  return `${amount}%`
}

const removeSpaces = (value: string) => (value ? value.replace(/\s/g, '') : '')

export {
  capitalizeFirstLetter,
  floatAmountToString,
  removeSpaces,
  replaceSpecialSymbols
}
