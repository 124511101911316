import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { TIMELINE_DETAILS_PUSH_DURATION } from 'src/features/Timeline/components/vertical/animationConstants'
import ArrowView from 'src/features/Timeline/components/vertical/LeaveDurationPickers/components/ArrowView'
import { useTranslation } from 'react-i18next'
import AccessibilitySelfFocusText from 'src/components/AccessibilitySelfFocusText'
import useComponentRect from 'src/components/hooks/useComponentRect'

interface IProps {
  className?: string
  y?: number
  alignsToTop?: boolean
  children: any
}

const Container = styled.div<{
  $y: number
  $alignsToTop: boolean
  $containerHeight: number
}>`
  transition: all ${TIMELINE_DETAILS_PUSH_DURATION}ms;
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          position: absolute;
          width: 445px;
          filter: drop-shadow(0 0 10px rgb(0 0 0 / 5%));

          ${() => {
            const { $y, $alignsToTop, $containerHeight } = props
            if ($alignsToTop) {
              return css`
                top: ${$y}px;
              `
            } else {
              return css`
                top: ${$y - $containerHeight}px;
                align-items: flex-end;
              `
            }
          }}
        `
      : css`
          width: 100%;
          overflow-y: scroll;
          height: 100%;
        `}
`

const ContainerView = React.memo((props: IProps) => {
  const { y, alignsToTop, children } = props
  const { t } = useTranslation()
  const containerRef: any = useRef(null)
  const containerHeight: number = useComponentRect(containerRef).height

  return (
    <Container
      $y={y}
      $alignsToTop={alignsToTop}
      $containerHeight={containerHeight}
      role={'region'}
      aria-label={t('timeline.accessibility.regionPeriodSettings')}
      ref={containerRef}
    >
      <AccessibilitySelfFocusText
        role={'alert'}
        ariaLabel={t('timeline.accessibility.datePickerOpened')}
      />
      {children}
      <ArrowView alignsToTop={alignsToTop} />
    </Container>
  )
})

ContainerView.displayName = 'ContainerView'

export default ContainerView
