import React, { useContext, useEffect, useRef, useState } from 'react'
import { IPriorityFilter } from 'src/components/JourneyMap/Filters'
import JourneyMapSettingsViewContainer from 'src/components/JourneyMap/SettingsViewContainer'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'
import DesktopContent from 'src/features/ManagerJourneyMap/components/Settings/components/DesktopContent'
import MobileHeaderView from 'src/features/ManagerJourneyMap/components/Settings/components/MobileHeaderView'
import MobilePlanSettingsContent from 'src/features/ManagerJourneyMap/components/Settings/components/MobilePlanSettingsContent'
import ArchiveMobileHeader from 'src/features/ManagerJourneyMap/components/ArchiveMobileHeader'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  filters: IPriorityFilter[]
  onFilterClick: (index: number) => void
  onResetFilters?: () => void
  totalItemsCount: number
  currentItemsCount: number
  onExpanderStateDidChange: (isExpanded: boolean) => void
}

const ManagerJourneyMapSettings = React.memo(
  (props: IProps) => {
    const {
      filters,
      onFilterClick,
      onResetFilters,
      currentItemsCount,
      totalItemsCount,
      onExpanderStateDidChange
    } = props

    const context: IManagerJourneyMapContext = useContext(
      ManagerJourneyMapContext
    )
    const { currentAbsence, isArchiveMode } = context

    const viewContainerRef = useRef(null)
    const [settingsExpanded, setSettingsExpanded] = useState(false)

    useEffect(() => {
      if (viewContainerRef.current) {
        viewContainerRef.current.collapse()
      }
      if (onResetFilters) {
        onResetFilters()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAbsence, isArchiveMode])

    const { isDesktop } = useContext(ScreenContext)

    let currentContent: any

    if (isDesktop) {
      currentContent = (
        <DesktopContent
          filters={filters}
          onFilterClick={onFilterClick}
          currentItemsCount={currentItemsCount}
          totalItemsCount={totalItemsCount}
          onResetFilters={onResetFilters}
        />
      )
    } else {
      if (settingsExpanded && currentAbsence) {
        currentContent = (
          <MobilePlanSettingsContent
            viewContainerRef={viewContainerRef}
            onClose={() => {
              setSettingsExpanded(false)
              onExpanderStateDidChange(false)
            }}
          />
        )
      }
    }

    if (isArchiveMode && !currentAbsence) {
      return <ArchiveMobileHeader />
    }

    return (
      <JourneyMapSettingsViewContainer
        ref={viewContainerRef}
        mobileExpandViewProps={{
          headerView: (
            <MobileHeaderView
              setSettingsExpanded={setSettingsExpanded}
              settingsExpanded={settingsExpanded}
              viewContainerRef={viewContainerRef}
            />
          ),
          top: '10px',
          onExpanderStateDidChange,
          onExpanderStateWillChange: (flag: boolean) => {
            if (!flag) {
              setSettingsExpanded(false)
            }
          }
        }}
      >
        {currentContent}
      </JourneyMapSettingsViewContainer>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.filters === nextProps.filters &&
    prevProps.totalItemsCount === nextProps.totalItemsCount &&
    prevProps.currentItemsCount === nextProps.currentItemsCount &&
    prevProps.onResetFilters === nextProps.onResetFilters
)

ManagerJourneyMapSettings.displayName = 'ManagerJourneyMapSettings'

export default ManagerJourneyMapSettings
