import React from 'react'
import styled from 'styled-components'
import Badge from 'src/components/Badge'
import createTheme from 'src/theme'
const { colors } = createTheme()

interface IProps {
  className?: string
  overdueCount: number
}

const BadgeWrapper = styled(Badge)`
  margin: 0 8px 0 0;
`

const AbsenceListItemOverdueBadge = React.memo((props: IProps) => {
  const { overdueCount, className } = props
  return (
    <BadgeWrapper
      className={className}
      text={overdueCount.toString()}
      background={colors.error80}
    />
  )
})

AbsenceListItemOverdueBadge.displayName = 'AbsenceListItemOverdueBadge'

export default AbsenceListItemOverdueBadge
