import React, { ReactNode, useContext, useMemo } from 'react'
import styled, { css } from 'styled-components'
import JourneyMapHeader from '../Header'
import ManagerJourneyMapContext, {
  IManagerJourneyMapContext
} from 'src/features/ManagerJourneyMap/JourneyMapContext'

interface IProps {
  children: any
}

const Container = styled.div`
  position: relative;
  height: 100%;
  ${props => {
    if (props.theme.isDesktop) {
      return css`
        width: 100vw;
      `
    } else {
      return css`
        width: 100%;
      `
    }
  }}
`

const ChildrenContainer = styled.div<{ $isManagerJourneyMap: boolean }>`
  width: 100%;
  -webkit-overflow-scrolling: touch;

  ${props => {
    if (props.theme.isDesktop) {
      return css`
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
      `
    } else {
      return css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
          'filters'
          'list';
        overflow: auto;
        height: ${props.$isManagerJourneyMap ? '100%' : 'calc(100% - 51px)'};

        @media print {
          overflow: visible;
        }
      `
    }
  }}
`

export const JourneyMapTopViewContainer = React.memo((props: IProps) => {
  const managerContext: IManagerJourneyMapContext = useContext(
    ManagerJourneyMapContext
  )
  const headerView: ReactNode = useMemo(() => <JourneyMapHeader />, [])

  return (
    <Container>
      {headerView}
      <ChildrenContainer $isManagerJourneyMap={!!managerContext}>
        {props.children}
      </ChildrenContainer>
    </Container>
  )
})

JourneyMapTopViewContainer.displayName = 'JourneyMapTopViewContainer'

export default JourneyMapTopViewContainer
