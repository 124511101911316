import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  border: 1px solid ${props => props.theme.colors.dark10};
  border-radius: 8px;
  font-weight: 400;
  padding: 8px 12px;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 18.2px;
  color: ${props => props.theme.colors.dark60};
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark80};
`

const NoUpcomingPeriods = React.memo(() => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('claimUpdate.reviewDates.upcoming.BabyBonding')}</Title>
      <Description>
        {t('claimUpdate.reviewDates.upcoming.noPeriods')}
      </Description>
    </Container>
  )
})

NoUpcomingPeriods.displayName = 'NoUpcomingPeriods'

export default NoUpcomingPeriods
