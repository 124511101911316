import React, { ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { IMomentCurrentMinMax } from 'src/react-app-env'
import createTheme from 'src/theme'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/utils/dateUtils'

const colors: any = createTheme().colors

interface IProps {
  className?: string
  title: string
  placeholder: string
  momentCurrentMinMax: IMomentCurrentMinMax
  disabled?: boolean
  actAsTextField?: boolean
  isPopoverOpen: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const ContentContainer = styled.button<{
  $focused: boolean
  $disabled: boolean
  $actAsTextField: boolean
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 12px;
  margin: 8px 0 0;
  background: ${colors.light100};
  border-radius: 6px;
  outline: none;
  transition: all 100ms;

  ${(props: any) => {
    const { $focused, $disabled } = props
    const color: string = $focused
      ? colors.main110
      : $disabled
        ? colors.dark10
        : colors.dark50
    return css`
      border: 1px solid ${color};
    `
  }}

  ${(props: any) =>
    props.$actAsTextField &&
    css`
      border: none;
    `}

  ${(props: any) =>
    props.$focused &&
    !props.$actAsTextField &&
    css`
      box-shadow: 0 0 0 2px ${props.theme.colors.main110};
    `}

  &:focus {
    border: 1px solid ${props => props.theme.colors.main110};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.main110};
    outline: none;
  }
`

const Title = styled.span<any>`
  height: 14px;
  font-size: 14px;
  color: ${props => props.theme.colors.dark60};
`

export const Text = styled.span<{
  $disabled: boolean
  $actAsTextField: boolean
  $hasDate: boolean
}>`
  font-size: 16px;
  color: ${props =>
    props.$actAsTextField
      ? props.theme.colors.main100
      : props.$disabled
        ? props.theme.colors.dark20
        : props.$hasDate
          ? props.theme.colors.dark80
          : props.theme.colors.dark60};

  &:hover {
    color: ${props => props.theme.colors.dark80};
  }
`

export const dataAttrs = {
  date: () => 'date-picker-default-view-date'
}

const DatePickerDefaultView = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      className,
      title,
      momentCurrentMinMax,
      placeholder,
      isPopoverOpen,
      actAsTextField,
      disabled
    } = props
    const { t } = useTranslation()

    const titleView: ReactNode = useMemo(
      () => (
        <Title disabled={disabled} aria-hidden>
          {title}
        </Title>
      ),
      [disabled, title]
    )

    const hasDate: boolean = useMemo(
      () => !!momentCurrentMinMax.current,
      [momentCurrentMinMax]
    )

    const text: string = useMemo(
      () =>
        hasDate
          ? momentCurrentMinMax.current.utc().format(DATE_FORMAT)
          : placeholder,
      [momentCurrentMinMax, placeholder, hasDate]
    )

    const textView: ReactNode = useMemo(
      () => (
        <Text
          $disabled={disabled}
          $hasDate={hasDate}
          $actAsTextField={actAsTextField}
        >
          {text}
        </Text>
      ),
      [disabled, text, hasDate, actAsTextField]
    )

    return (
      <Container className={className}>
        {titleView}
        <ContentContainer
          data-testid={dataAttrs.date()}
          disabled={disabled}
          $focused={isPopoverOpen}
          $disabled={disabled}
          $actAsTextField={actAsTextField}
          ref={ref}
          aria-label={`${title}, ${text}`}
          aria-haspopup
          aria-expanded={isPopoverOpen}
        >
          {textView}
          <Icon
            name={'calendar'}
            settings={{
              width: '18',
              height: '20',
              fill: disabled
                ? colors.main40
                : actAsTextField
                  ? colors.main100
                  : isPopoverOpen
                    ? colors.main110
                    : colors.main100
            }}
            ariaLabel={t('common.accessibilityText.calendar')}
          />
        </ContentContainer>
      </Container>
    )
  })
)

DatePickerDefaultView.displayName = 'DatePickerDefaultView'

export default DatePickerDefaultView
