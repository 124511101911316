import React, { useContext } from 'react'
import styled from 'styled-components'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import Completed from '../CompletedView'
import Month from './Month'
import Day from './Day'

interface IProps {
  completed: boolean
  overdue: boolean
  onItemCompletedClick: () => void
  dueDate: Moment
}

const DateWrapper = styled.div`
  grid-area: date;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const CompleteByText = styled.span`
  font-weight: normal;
  font-size: ${props => (props.theme.isDesktop ? '12px' : '9px')};
  line-height: ${props => (props.theme.isDesktop ? '100%' : '130%')};
  margin: 8px 0 0;
  color: ${props => props.theme.colors.dark60};
`

const DateHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DateRow = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
`

const Overdue = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: ${props => (props.theme.isDesktop ? '13px' : '11px')};
  line-height: 100%;
  border-radius: 8px;
  height: ${props => (props.theme.isDesktop ? '21px' : '19px')};
  width: ${props => (props.theme.isDesktop ? '70px' : '53px')};
  margin-top: ${props => (props.theme.isDesktop ? '11px' : '8px')};
  color: ${props => props.theme.colors.light100};
  background: ${props => props.theme.colors.specialCriticalHigh};
`

const JourneyMapListItemDateView = React.memo(
  (props: IProps) => {
    const { completed, dueDate, onItemCompletedClick, overdue } = props
    const { isDesktop, isMobile } = useContext(ScreenContext)
    const month: string = dueDate.format('MMM')
    const day: string = dueDate.format('DD')

    let dateView: any
    if (isDesktop) {
      dateView = (
        <React.Fragment>
          <Month>{month}</Month>
          <Day>{day}</Day>
        </React.Fragment>
      )
    } else {
      dateView = (
        <DateHorizontalWrapper>
          <Month>{month}</Month>
          <Day>{day}</Day>
        </DateHorizontalWrapper>
      )
    }

    const { t } = useTranslation()

    return (
      <DateWrapper aria-hidden>
        {overdue && <Overdue>{t('common.overdue')}</Overdue>}
        <DateRow>
          <CompleteByText>{t('common.journeyMapCompleteBy')}</CompleteByText>
          {dateView}
          {isMobile && (
            <Completed
              completed={completed}
              onItemCompletedClick={onItemCompletedClick}
            />
          )}
        </DateRow>
      </DateWrapper>
    )
  },
  (prevProps: IProps, nextProps: IProps) =>
    prevProps.onItemCompletedClick === nextProps.onItemCompletedClick &&
    prevProps.dueDate === nextProps.dueDate &&
    prevProps.completed === nextProps.completed &&
    prevProps.overdue === nextProps.overdue
)

JourneyMapListItemDateView.displayName = 'JourneyMapListItemDateView'

export default JourneyMapListItemDateView
