import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getDurationItem,
  getPeriodDurationTextComponents
} from 'src/utils/leaveUtils'
import styled from 'styled-components'
import SelectedNotice from '../SelectedNotice'
import { Icon } from 'src/UIKit'

type DurationType = 'weekday' | 'week' | 'day'

interface IProps {
  used: number
  unused: number
  usedCalendarDays?: number
  unusedCalendarDays?: number
  notShowUnused?: boolean
  showDoubleText?: boolean
  showHumanReadableDuration?: boolean
  externalDurationType?: IDurationType
  durationType?: DurationType
  useSelectMax?: boolean
  onSelectMax?: () => void
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`

const SelectedContainer = styled.div`
  display: flex;
`

const SelectMaxButton = styled.button`
  display: flex;
  height: 12px;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  gap: 2px;
  color: ${props => props.theme.colors.main100};
  margin: 10px 0 0 3px;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: 2px;
    border-radius: 3px;
  }
`

const IconWrapper = styled(Icon)`
  padding-top: 1px;
`

const SelectedMessage = React.memo((props: IProps) => {
  const { t } = useTranslation()

  const {
    used,
    unused,
    usedCalendarDays,
    unusedCalendarDays,
    durationType,
    externalDurationType,
    notShowUnused,
    showDoubleText,
    showHumanReadableDuration,
    useSelectMax,
    onSelectMax
  } = props

  const durationItem = durationType || getDurationItem(externalDurationType)

  const selectMax = useMemo(
    () =>
      useSelectMax && (
        <SelectMaxButton onClick={onSelectMax}>
          <IconWrapper name={'up_right'} />
          {t('common.selectMax')}
        </SelectMaxButton>
      ),
    [onSelectMax, t, useSelectMax]
  )

  const selectedView = useMemo(() => {
    let selected: string

    if (showHumanReadableDuration) {
      const durationText = getPeriodDurationTextComponents(
        used,
        t,
        false,
        true
      ).humanReadableFullString
      selected = t('common.selectedDuration', {
        duration: durationText
      })
    } else {
      const selectedString = getPeriodDurationTextComponents(
        usedCalendarDays,
        t
      )
      const selectedDuration = t(`common.durations.${durationItem}`, {
        count: used
      })
      selected = t(
        showDoubleText
          ? 'common.selectedWithWeeks'
          : 'common.selectedWithoutWeeks',
        {
          days: used,
          weeks: selectedString.shortString,
          duration: selectedDuration
        }
      )
    }

    return <SelectedNotice>{selected}</SelectedNotice>
  }, [
    durationItem,
    showDoubleText,
    t,
    used,
    showHumanReadableDuration,
    usedCalendarDays
  ])

  const remainingView = useMemo(() => {
    if (notShowUnused || unused === null) {
      return null
    }
    let remaining: string

    if (showHumanReadableDuration) {
      const durationText = getPeriodDurationTextComponents(
        unused,
        t,
        false,
        true
      ).humanReadableFullString
      remaining = t('common.remainingDuration', {
        duration: durationText
      })
    } else {
      const remainingString = getPeriodDurationTextComponents(
        unusedCalendarDays,
        t
      )
      const remainingDuration = t(`common.durations.${durationItem}`, {
        count: unused
      })
      remaining = t(
        showDoubleText
          ? 'common.remainingWithWeeks'
          : 'common.remainingWithoutWeeks',
        {
          days: unused,
          weeks: remainingString.shortString,
          duration: remainingDuration
        }
      )
    }

    return <SelectedNotice>{remaining}</SelectedNotice>
  }, [
    durationItem,
    notShowUnused,
    showDoubleText,
    t,
    unused,
    showHumanReadableDuration,
    unusedCalendarDays
  ])

  return (
    <Container>
      <SelectedContainer>
        {selectedView}
        {selectMax}
      </SelectedContainer>
      {remainingView}
    </Container>
  )
})

SelectedMessage.displayName = 'SelectedMessage'

export default SelectedMessage
