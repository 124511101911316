import { IUseUser } from 'src/graphql/hooks/useUser'
import { IUserContext } from 'src/contexts/UserContext/index'

export const getUserContextValue = (userResult: IUseUser): IUserContext => {
  if (!userResult.user) {
    return null
  }

  const { firstName, lastName, email } = userResult.user
  return {
    firstName,
    lastName,
    email,
    fullName: `${firstName} ${lastName}`
  }
}
