import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import { Moment } from 'moment'

export interface IProps {
  className?: string
  startDate: Moment
  endDate: Moment
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.light100};
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.dark05};
  padding: 4px 10px;
`

const Text = styled.span`
  color: ${props => props.theme.colors.dark60};
  font-weight: 400;
  font-size: 11px;
`

const AbsenceDatesRangeView = React.memo((props: IProps) => {
  const { startDate, endDate } = props
  const { t } = useTranslation()

  const start: string = useMemo(
    () => startDate.format(DATE_FORMAT),
    [startDate]
  )

  const end: string = useMemo(() => endDate.format(DATE_FORMAT), [endDate])

  const ariaLabel: string = useMemo(
    () => t('timeline.accessibility.duration', { start, end }),
    [start, end, t]
  )

  if (!startDate || !endDate) {
    return null
  }

  return (
    <Container className={props.className} aria-label={ariaLabel} role={'note'}>
      <Text aria-hidden>{`${start} - ${end}`}</Text>
    </Container>
  )
})

AbsenceDatesRangeView.displayName = 'AbsenceDatesRangeView'

export default AbsenceDatesRangeView
