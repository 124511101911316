import { QueryResult, useQuery } from '@apollo/client'
import { queryMe } from 'src/graphql/queries'

export interface IUseUser {
  loading: boolean
  error: any
  user: IUser
}

const useUser = (fetchPolicy: any = 'cache-first'): IUseUser => {
  const returnValue: IUseUser = {
    loading: false,
    error: null,
    user: null
  }

  const userResult: QueryResult = useQuery(queryMe, {
    fetchPolicy
  })

  if (userResult.loading) {
    returnValue.loading = true
    return returnValue
  }

  if (userResult.error) {
    returnValue.error = userResult.error
    return returnValue
  }

  returnValue.user = userResult.data.me

  return returnValue
}

export default useUser
