import React, { useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import ScreenContext from 'src/contexts/ScreenContext'
import CheckboxButton from 'src/UIKit/CheckboxButton'
import { ResponseStrings } from 'src/components/Surveys/SurveyContainer'

interface IProps {
  responses: string[]
  selectedResponse: ResponseStrings
  onAnswer: (response: ResponseStrings) => void
}

const Container = styled.div<{ $isMobileLandscape: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props => {
    if (props.$isMobileLandscape || props.theme.isDesktop) {
      return css`
        max-width: 570px;
      `
    } else {
      return css`
        width: 100%;
      `
    }
  }}
`

const ChooseView = styled(CheckboxButton)<any>`
  width: 270px;
  margin: 4px 0;

  ${props =>
    props.theme.isMobile &&
    css`
      min-height: 40px;
    `}
`

export const dataAttrs = {
  component: () => 'answer-type-check-choice'
}

export const AnswerTypeCheckChoice = React.memo((props: IProps) => {
  const { responses, onAnswer, selectedResponse } = props
  const { t } = useTranslation()
  const { isMobileLandscape } = useContext(ScreenContext)

  const onItemClicked = useCallback(
    (item: string) => {
      const newItems: Set<string> = new Set(selectedResponse)
      if (newItems.has(item)) {
        newItems.delete(item)
      } else {
        newItems.add(item)
      }
      onAnswer(Array.from(newItems))
    },
    [onAnswer, selectedResponse]
  )

  const responsesView: any = responses.map((response: string) => {
    const isSelected = selectedResponse?.includes(response)
    return (
      <ChooseView
        key={response}
        checked={isSelected}
        appearance={'cancel'}
        onClick={() => {
          onItemClicked(response)
        }}
      >
        {response}
      </ChooseView>
    )
  })

  return (
    <Container
      data-testid={dataAttrs.component()}
      $isMobileLandscape={isMobileLandscape}
      role={'region'}
      aria-label={t('common.accessibilityText.options')}
    >
      {responsesView}
    </Container>
  )
})

AnswerTypeCheckChoice.displayName = 'AnswerTypeCheckChoice'

export default AnswerTypeCheckChoice
