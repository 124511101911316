import LeaveDatePeriodPicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDatePeriodPicker'
import { isRotatingSchedule } from 'src/utils/dateUtils'
import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  addsOneVisualDay: true,
  datePickerComponent: LeaveDatePeriodPicker,
  datePickerFooterInfoMessage: (t: any) =>
    isRotatingSchedule(input.periods)
      ? t('timeline.datePickerAlerts.resetNotice.text')
      : null
})
