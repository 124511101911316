import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import BookmarkView from 'src/components/JourneyMap/JourneyMapItemView/components/BookmarkView'
import { getFilterPropsByJourneyMapItemType } from 'src/utils/leaveUtils'
import PhaseLabel from 'src/components/JourneyMap/PhaseLabel'
import CompleteByView from 'src/components/JourneyMap/JourneyMapItemView/components/CompleteByView'
import ScreenContext from 'src/contexts/ScreenContext'

interface IProps {
  className?: string
  item: IJourneyMapItem
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 0 40px;
          padding: 16px 0;
          border-bottom: 1px solid ${props.theme.colors.main20};
        `
      : css`
          @media print {
            margin: 0 0 16px;
            padding: 0 0 16px;
            border-bottom: 1px solid ${props.theme.colors.main20};
          }
        `}
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${props =>
    props.theme.isDesktop
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-start;
        `}
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const CompleteByWrapper = styled(CompleteByView)`
  margin-left: ${props => (props.theme.isDesktop ? '16px' : '8px')};

  @media print {
    margin-left: 14px;
  }
`

const ItemInfoView = React.memo((props: IProps) => {
  const { className, item } = props
  const { priority, phase } = item
  const { isMobile } = useContext(ScreenContext)

  const renderLeftContainer = () => (
    <LeftContainer>
      <BookmarkView
        color={getFilterPropsByJourneyMapItemType(priority).color}
      />
      <CompleteByWrapper item={item} />
    </LeftContainer>
  )

  const renderRightContainer = () => (
    <RightContainer>
      {phase && <PhaseLabel phase={phase} useVerticalLayout={isMobile} />}
    </RightContainer>
  )

  return (
    <Container className={className}>
      {renderLeftContainer()}
      {renderRightContainer()}
    </Container>
  )
})

ItemInfoView.displayName = 'ItemInfoView'

export default ItemInfoView
