import { IGetPeriodConfigInput, ITimelinePeriodConfig } from './index'
import { ITimelinePeriod } from 'src/react-app-env'
import { getPeriodKeyDate } from 'src/utils/periodUtils'
import isEqual from 'lodash.isequal'
import { lowerFirst } from 'lodash'
import { capitalizeFirstLetter, removeSpaces } from 'src/utils/stringUtils'

const isTailAddable = (timeline: ITimelinePeriod[], index: number) => {
  if (index < 0) {
    return false
  }

  return timeline.slice(index).every((p: any) => p.appearance === 'Addable')
}

const isFrontAddable = (timeline: ITimelinePeriod[], index: number) => {
  if (index < 0) {
    return false
  }
  return timeline
    .slice(0, index + 1)
    .every((p: any) => p.appearance === 'Addable')
}

const isFirstPeriod = (
  timeline: ITimelinePeriod[],
  period: ITimelinePeriod
) => {
  const firstPeriod = timeline.find((p: any) => p.appearance !== 'Addable')
  return isEqual(firstPeriod, period)
}

export default ({
  period,
  periods,
  leave,
  t
}: IGetPeriodConfigInput): ITimelinePeriodConfig => {
  const title: string = t([
    `timeline.periodByTimelinePeriodType.${period.type}_${leave.type}`,
    `timeline.periodByTimelinePeriodType.${period.type}`
  ])
  const periodName: string = lowerFirst(title)?.replace(' period', '')
  const datePickerViewForPeriod = 'self'
  const detailsViewForPeriod = 'self'
  const detailsType = period.type
  const datePickerDescriptionKey = `timeline.leaveDurationPicker.${period.type}`
  let datePickerDescription = t(
    [
      `timeline.leaveDurationPicker.${period.type}_${
        leave.metadata?.countryState
      }_${removeSpaces(leave.metadata?.city)}`,
      `timeline.leaveDurationPicker.${period.type}_${leave.metadata?.countryState}`,
      `timeline.leaveDurationPicker.${period.type}_${
        leave.type
      }_${getPeriodKeyDate(period)}`,
      `timeline.leaveDurationPicker.${period.type}_${leave.type}`,
      datePickerDescriptionKey
    ],
    {
      ...leave.metadata
    }
  )

  const periodPickerStartDateText: string = t('timeline.periodPicker.startDate')
  const periodPickerEndDateText: string = t('timeline.periodPicker.endDate')

  const footerDatePickerTitleKey = `timeline.datePickerTitleByPeriod.${leave.type}.${period.type}.${period.appearance}`
  const footerDatePickerTitle: string = t(footerDatePickerTitleKey, {
    keyDate: capitalizeFirstLetter(getPeriodKeyDate(period))
  })

  if (datePickerDescription === datePickerDescriptionKey) {
    datePickerDescription = null
  }

  return {
    title,
    periodName,
    description: period.durationString,
    duration: period.duration,
    detailsViewForPeriod,
    addsOneVisualDay: false,
    detailsExtraDescription: () => null,
    detailsHolidays: {
      show: false,
      showDescription: true,
      descriptionKey: () => 'common.holidays.description'
    },
    detailsHintPostfix: null,
    detailsType,
    timeViewProps: {
      date: period.periodStart?.current
        ? period.periodStart.current.clone()
        : period.startDate.clone(),
      iconName: null,
      iconAriaLabel: null,
      isFirst: isFirstPeriod(periods, period),
      isLast:
        period.appearance !== 'Addable' &&
        isTailAddable(periods, periods.indexOf(period) + 1),
      isFrontAddable: isFrontAddable(periods, periods.indexOf(period)),
      skip:
        isTailAddable(periods, periods.indexOf(period)) ||
        isFrontAddable(periods, periods.indexOf(period)),
      isCircleHidden: period.appearance === 'Addable'
    },
    hidesFooter: period.appearance === 'Notice',
    footerDatePickerTitle,
    footerShowDetails: false,
    periodKeyDate: 'periodEnd',
    removable: false,

    datePickerFooterInfoMessage: () => null,

    // TODO: create inner property to hold picker related props
    datePickerDescription,
    datePickerViewForPeriod,
    datePickerComponent: null,
    datePickerKeyDate: null,
    datePickerDisableWeekends: false,
    periodPickerStartDateText,
    periodPickerEndDateText,
    periodTopNotice: () => null,
    periodPickerSelectedNotice: {
      show: false,
      showDoubleText: false,
      notShowUnused: false
    },
    periodPickerShowWorkdays: false,
    periodPickerShowBalance: false,
    periodPickerInfoMessage: () => period.details?.infoNotice || '',
    periodPickerMaxSelected: d => d,
    periodPickerTotalSelected: d => d,
    periodPickerBlankStartDate: false,
    periodPickerCreateAddable: false,
    periodPickerAsWeeksPicker:
      period.type !== 'Annual' &&
      period.type !== 'Partner' &&
      period.type !== 'Sickness',
    leaveKeyDatePickerInfoMessage: () => '',
    alerts: [],
    useApprovedByTpa: false,
    usePeriodRefIdSearch: false,
    isMaximumAllowed: false,
    intermittentPickerResetBlock: true,
    handleDatePickerBottomOverlap: false
  }
}
