import { ITimelinePeriod } from 'src/react-app-env'
import { IBlockFooterViewButton } from './index'
import { isPeriodSynced, isPeriodReadOnly } from 'src/utils/periodUtils'

interface IInput {
  period: ITimelinePeriod
  t: any
  selectedDatePickerPeriod: ITimelinePeriod
  onDatePickerClick: (period: ITimelinePeriod) => void
  selectedDetailsPeriod: ITimelinePeriod
  onDetailsClick: (period: ITimelinePeriod) => void
}

export const getHidesDatePicker = (period: ITimelinePeriod): boolean => {
  switch (period.appearance) {
    case 'Standard':
    case 'KeyDate':
    case 'Period':
      return isPeriodReadOnly(period)
    case 'Addable':
      return false
    default:
      return true
  }
}

export const getHidesDetails = (period: ITimelinePeriod): boolean =>
  period.type === 'DueDate' ||
  period.type === 'StartDate' ||
  (period.type === 'AtWork' && !period.timelineConfig.footerShowDetails) ||
  period.type === 'EndDate' ||
  period.type === 'BirthDate' ||
  period.type === 'ReturnToWorkDate' ||
  (period.type === 'FamilyCare' &&
    period.hash.indexOf('index:0') === -1 &&
    !period.timelineConfig.footerShowDetails)

export const getFooterButtons = ({
  period,
  t,
  selectedDatePickerPeriod,
  onDatePickerClick,
  selectedDetailsPeriod,
  onDetailsClick
}: IInput) => {
  const result: IBlockFooterViewButton[] = []

  if (!getHidesDatePicker(period)) {
    const title: string = isPeriodSynced(period)
      ? t('timelineLockInfo.footerKeyDateButton')
      : period.timelineConfig.footerDatePickerTitle
    result.push({
      title,
      onClick: () => {
        if (!selectedDatePickerPeriod) {
          onDatePickerClick(period)
        }
      },
      disabled:
        !!selectedDatePickerPeriod ||
        (period.appearance === 'Addable' && isPeriodReadOnly(period))
    })
  }

  if (!getHidesDetails(period)) {
    result.push({
      title: t(
        selectedDetailsPeriod?.hash === period.hash
          ? 'common.hideDetails'
          : 'common.details'
      ),
      onClick: () => {
        if (!selectedDatePickerPeriod) {
          onDetailsClick(
            selectedDetailsPeriod?.hash !== period.hash ? period : null
          )
        }
      },
      disabled: !!selectedDatePickerPeriod,
      alignRight: result.length === 0
    })
  }

  return result
}
