import React, {
  useRef,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import { Button, Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import SignInContext, {
  ISignInContext
} from 'src/features/Welcome/components/WelcomePage/context'
import AuthCode from 'react-auth-code-input'
import createTheme from 'src/theme'
import SpamNote from './components/SpamNote'

const { colors } = createTheme()

interface IProps {
  name: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const AuthCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  align-items: start;

  .container {
    display: flex;
    justify-content: center;
    gap: ${props => (props.theme.isDesktop ? '10px' : '8px')};
  }

  .input {
    font-size: 22px;
    font-weight: 500;
    font-family: Roboto, serif;
    line-height: 22px;
    width: ${props => (props.theme.isDesktop ? '46px' : '38px')};
    height: 56px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.dark80};
    border: 1px solid ${props => props.theme.colors.dark20};
    border-radius: 6px;
    background-clip: padding-box;
  }

  .input:focus {
    border-radius: 6px;
    outline-offset: -1px;
    outline: 2px solid ${props => props.theme.colors.main110};
  }
`

const NoteWrapper = styled.span`
  width: 100%;
  display: flex;
  gap: 18px;
  padding-top: 2px;
`

const WrongCodeNote = styled.span<{ $visible: boolean }>`
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${props => props.theme.colors.error80};
`

export const dataAttrs = {
  backBtn: () => 'code-confirmation-back-btn',
  wrongCodeNote: () => 'code-confirmation-wrong-code'
}

export const AUTH_CODE_LENGTH = 6

const CodeConfirmation = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const { name } = props
  const {
    onPrevStage,
    handleLoginOtp,
    moveToStage,
    personalEmail,
    codeValidTill
  }: ISignInContext = useContext(SignInContext)

  const authRef = useRef(null)
  const [isCodeWrong, setIsCodeWrong] = useState(false)
  const [isCheckingCode, setIsCheckingCode] = useState(false)

  const handleCodeChange = useCallback(
    (code: string) => {
      if (code.length > 0) {
        setIsCodeWrong(false)
      }

      if (code.length !== AUTH_CODE_LENGTH) {
        return
      }

      setIsCheckingCode(true)
      handleLoginOtp(personalEmail, code.toUpperCase())
        .then((data: any) => {
          if (!data) {
            return
          }
          if (data.attempts_left <= 0) {
            moveToStage('tooManyAttempts')
          }
          setIsCodeWrong(true)
          authRef.current.clear()
        })
        .finally(() => setIsCheckingCode(false))
    },
    [
      moveToStage,
      handleLoginOtp,
      personalEmail,
      setIsCodeWrong,
      setIsCheckingCode
    ]
  )

  const authCode = useMemo(
    () => (
      <AuthCodeWrapper>
        <AuthCode
          ariaLabel={t('welcomePage.accessibility.authCode')}
          ref={authRef}
          length={AUTH_CODE_LENGTH}
          containerClassName="container"
          inputClassName="input"
          onChange={handleCodeChange}
        />
        <WrongCodeNote
          data-testid={dataAttrs.wrongCodeNote()}
          $visible={isCodeWrong}
        >
          {t(`welcomePage.${name}.wrongCodeNote`)}
        </WrongCodeNote>
      </AuthCodeWrapper>
    ),
    [t, name, handleCodeChange, isCodeWrong]
  )

  const checkSpamNote = useMemo(
    () => (
      <NoteWrapper>
        <Icon name="info_circle" settings={{ fill: colors.main100 }} />
        <SpamNote
          name={name}
          expiresAt={codeValidTill}
          onExpired={() => moveToStage('codeExpired')}
        />
      </NoteWrapper>
    ),
    [name, codeValidTill, moveToStage]
  )

  const backButton = useMemo(
    () => (
      <Button
        data-testid={dataAttrs.backBtn()}
        appearance="cancel"
        onClick={onPrevStage}
        disabled={isCheckingCode}
      >
        {t('common.back')}
      </Button>
    ),
    [t, onPrevStage, isCheckingCode]
  )

  return (
    <Container>
      {authCode}
      {checkSpamNote}
      {backButton}
    </Container>
  )
})

CodeConfirmation.displayName = 'CodeConfirmation'

export default CodeConfirmation
