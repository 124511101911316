import { capitalizeFirstLetter } from 'src/utils/stringUtils'
import {
  IInputProps,
  IUserProfileState,
  PERSONAL_EMAIL,
  PERSONAL_EMAIL_CODE,
  PHONE_NUMBER,
  PHONE_NUMBER_CODE
} from 'src/features/UserProfile/components/ProfileForm/index'
import moment, { Duration } from 'moment'
import get from 'lodash.get'
import { IInputButtonProps } from 'src/UIKit/Input'

export const getConfirmation = (
  fieldName: string,
  state: IUserProfileState
): IConfirmation => {
  const { user } = state
  if (!user || !user.confirmations) {
    return null
  }
  const { confirmations } = user
  const type = capitalizeFirstLetter(fieldName)
  const found: IConfirmation[] = confirmations.filter(
    (confirmation: IConfirmation) => confirmation.type === type
  )
  return found.length === 0 ? null : found[0]
}

export const getFieldExpiresIn = (
  fieldName: string,
  state: IUserProfileState
): number => {
  const confirmation: IConfirmation = getConfirmation(fieldName, state)
  if (!confirmation || confirmation.confirmedAt) {
    return -1
  }

  const duration: Duration = moment.duration(
    moment(confirmation.validTill).diff(moment())
  )

  const result: number = duration.asSeconds()
  if (isNaN(result)) {
    return -1
  }

  return result
}

export const getFieldUnlocksIn = (
  fieldName: string,
  state: IUserProfileState
): number => {
  const confirmation: IConfirmation = getConfirmation(fieldName, state)
  if (!confirmation || !confirmation.blockedTill) {
    return 0
  }

  const duration: Duration = moment.duration(
    moment(confirmation.blockedTill).diff(moment())
  )
  return duration.asSeconds()
}

export const setupField = (
  state: IUserProfileState,
  value: string,
  confirmation: IConfirmation,
  name: string,
  defaultState: IUserProfileState
) => {
  state[name] = { ...defaultState[name] }
  state[name].value = value
  state[name].valid = state[name].validator(value).valid
  state[name].verified = confirmation && !!confirmation.confirmedAt
  state[name].disabled = getFieldUnlocksIn(name, state) > 0
}

export const makeCodeExpiredIfNeeded = (
  state: IUserProfileState,
  confirmation: IConfirmation,
  fieldName: string,
  t: (key: string) => string
) => {
  if (confirmation && moment(confirmation.validTill).isBefore(moment())) {
    state[fieldName].disabled = true
    state[fieldName].valid = false
    state[fieldName].touched = true
    state[fieldName].errorString = t('common.codeHasExpired')
  }
}

export const applyFieldErrorsForState = (
  fieldErrors: any,
  state: IUserProfileState
) => {
  state.fieldErrors = fieldErrors
  state.personalEmail = { ...state.personalEmail, fetching: false }
  state.phoneNumber = { ...state.phoneNumber, fetching: false }
  state.personalEmailCode = { ...state.personalEmailCode, fetching: false }
  state.phoneNumberCode = { ...state.phoneNumberCode, fetching: false }

  const inputsArray: string[] = [
    PERSONAL_EMAIL,
    PHONE_NUMBER,
    PERSONAL_EMAIL_CODE,
    PHONE_NUMBER_CODE
  ]
  inputsArray.forEach((inputName: string) => {
    const inputProps: IInputProps = state[inputName]
    const fieldError: string = get(fieldErrors, inputName, null)
    if (fieldError) {
      inputProps.errorString = fieldError
      inputProps.valid = false
      inputProps.touched = true
    } else {
      inputProps.errorString = null
      inputProps.valid = true
    }
  })
}

export const populateFieldsForState = (
  user: IUser,
  state: IUserProfileState,
  defaultState: IUserProfileState,
  t: (key: string) => string
) => {
  state.user = user
  state.email = user.email

  const personalEmailConfirmation: IConfirmation = getConfirmation(
    PERSONAL_EMAIL,
    state
  )
  const phoneNumberConfirmation: IConfirmation = getConfirmation(
    PHONE_NUMBER,
    state
  )

  setupField(
    state,
    user.personalEmail,
    personalEmailConfirmation,
    PERSONAL_EMAIL,
    defaultState
  )

  setupField(
    state,
    user.phoneNumber,
    phoneNumberConfirmation,
    PHONE_NUMBER,
    defaultState
  )

  state.phoneNumberCode = { ...defaultState.phoneNumberCode }
  makeCodeExpiredIfNeeded(state, phoneNumberConfirmation, 'phoneNumberCode', t)

  state.personalEmailCode = { ...defaultState.personalEmailCode }
  makeCodeExpiredIfNeeded(
    state,
    personalEmailConfirmation,
    'personalEmailCode',
    t
  )
}

export const isFieldVerified = (
  fieldName: string,
  state: IUserProfileState
) => {
  const confirmation: IConfirmation = getConfirmation(fieldName, state)
  if (!confirmation) {
    return false
  }
  return !!confirmation.confirmedAt
}

export const getInputButtonProps = (
  submitUserValue: (name: string, value: string) => void,
  resendConfirmation: (kind: string) => void,
  state: IUserProfileState,
  name: string,
  data: any = null,
  user: IUser = null
): IInputButtonProps => {
  if (name === PHONE_NUMBER || name === PERSONAL_EMAIL) {
    if (!data.value || !data.value.length || !data.valid) {
      return { type: 'Verify', disabled: true }
    }

    if (data.value !== user[name]) {
      return {
        type: 'Verify',
        submitOnReturnButton: true,
        disabled: false,
        onClick: () => {
          submitUserValue(name, data.value)
        }
      }
    }

    if (data.value === user[name]) {
      return {
        type: 'Remove',
        disabled: data.disabled,
        onClick: () => {
          submitUserValue(name, '')
        }
      }
    }
  }

  if (name === PHONE_NUMBER_CODE || name === PERSONAL_EMAIL_CODE) {
    let confirmationKey: string
    let kind: string

    if (name === PHONE_NUMBER_CODE) {
      confirmationKey = PHONE_NUMBER
      kind = 'PhoneNumber'
    } else if (name === PERSONAL_EMAIL_CODE) {
      confirmationKey = PERSONAL_EMAIL
      kind = 'PersonalEmail'
    }

    const confirmation: IConfirmation = getConfirmation(confirmationKey, state)

    if (!confirmation) {
      return null
    }

    if (moment(confirmation.validTill).isBefore(moment())) {
      return {
        type: 'Resend',
        disabled: false,
        onClick: () => {
          resendConfirmation(kind)
        }
      }
    }
    return null
  }

  return null
}
