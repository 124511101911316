import React, { ReactNode, useEffect, useMemo } from 'react'
import ReactDom from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import zIndex from 'src/constants/zIndex'
import Bubble, {
  IBubbleProps,
  HORIZONTAL_OFFSET,
  TOP_OFFSET,
  WIDTH,
  MARGIN
} from 'src/components/BubblesOvelay/components/Bubble'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import ReactFocusLock from 'react-focus-lock'

interface IProps {
  targetElement?: any
  bubbleProps?: IBubbleProps
  hidesBubble?: boolean
  areaAddedWidth?: number
  areaAddedHeight?: number
  areaBorderRadius?: number
}

const fadeInKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`

const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: ${zIndex.bubblesOverlay.overlay};
  display: flex;
  justify-content: center;
  align-items: center;

  ${() => css`
    animation: ${fadeInKeyFrames} 0.1s ease-in-out;
  `}
`

const BubblesOverlay = React.memo(
  React.forwardRef((props: IProps, ref: any) => {
    const {
      targetElement,
      bubbleProps,
      hidesBubble,
      areaAddedHeight,
      areaAddedWidth,
      areaBorderRadius
    } = props

    const forceUpdate = useForceUpdate()

    useEffect(() => {
      const onResize = () => {
        forceUpdate()
      }
      window.addEventListener('resize', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
      }
    })

    const rect: any = targetElement && targetElement.getBoundingClientRect()
    const { clientHeight, clientWidth } = document.body

    const overlayView: ReactNode = useMemo(() => {
      let pathD: string
      if (rect) {
        const addedWidth: number = areaAddedWidth > 0 ? areaAddedWidth : 16
        const addedHeight: number = areaAddedHeight > 0 ? areaAddedHeight : 0
        const br: number = areaBorderRadius > 0 ? areaBorderRadius : 16
        const x: number = rect.x - addedWidth / 2
        const y: number = rect.y - addedHeight / 2
        const width: number = rect.width + addedWidth
        const height: number = rect.height + addedHeight

        pathD = `
      M0,0 h${clientWidth + 1000} v${clientHeight + 1000} h${
        -clientWidth - 1000
      } z
           M${x},${y + br} v${height - 2 * br} q${0},${br} ${br},${br} h${
             width - 2 * br
           } q${br},${0} ${br},${-br} v${
             -height + 2 * br
           } q${0},${-br} ${-br},${-br} h${
             -width + 2 * br
           } q${-br},${0} ${-br},${br} z
           `
      } else {
        pathD = `
      M0,0 h${clientWidth} v${clientHeight} h${-clientWidth} z
           `
      }

      return (
        <StyledOverlay ref={ref}>
          {pathD && (
            <svg width="100%" height="100%" pointerEvents="all">
              <path
                fill="black"
                opacity={hidesBubble ? 0 : 0.3}
                d={pathD}
                pointerEvents="visibleFill"
              />
            </svg>
          )}
        </StyledOverlay>
      )
    }, [
      areaAddedHeight,
      areaAddedWidth,
      areaBorderRadius,
      clientHeight,
      clientWidth,
      hidesBubble,
      rect,
      ref
    ])

    const bubbleView: ReactNode = useMemo(() => {
      if (hidesBubble || !rect) {
        return null
      }

      const addedHeight: number = areaAddedHeight > 0 ? areaAddedHeight : 0
      let top: number = rect.bottom + TOP_OFFSET + MARGIN + addedHeight / 2
      let bottom = 0
      let left: number = rect.left + rect.width / 2 - HORIZONTAL_OFFSET
      let triangleFromRight = false
      if (left > clientWidth / 2) {
        left = rect.right - rect.width / 2 + HORIZONTAL_OFFSET - WIDTH
        triangleFromRight = true
      }
      let triangleFromBottom = false
      if (rect.top > clientHeight / 2) {
        bottom = rect.top - TOP_OFFSET - MARGIN - addedHeight / 2
        triangleFromBottom = true
      }

      if (top === TOP_OFFSET) {
        top = 0
      }

      if (left === -HORIZONTAL_OFFSET) {
        left = 0
      }

      return (
        <Bubble
          {...bubbleProps}
          top={top}
          left={left}
          bottom={bottom}
          triangleFromRight={triangleFromRight}
          triangleFromBottom={triangleFromBottom}
        />
      )
    }, [
      areaAddedHeight,
      bubbleProps,
      clientHeight,
      clientWidth,
      hidesBubble,
      rect
    ])

    const views: ReactNode = useMemo(
      () => (
        <ReactFocusLock autoFocus={false}>
          {overlayView}
          {bubbleView}
        </ReactFocusLock>
      ),
      [overlayView, bubbleView]
    )

    return ReactDom.createPortal(views, document.body)
  })
)

BubblesOverlay.displayName = 'BubblesOverlay'

export default BubblesOverlay
