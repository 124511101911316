import Annual from './Annual'
import BabyBonding from './BabyBonding'
import PreDelivery from './PreDelivery'
import Recovery from './Recovery'
import UnpaidParental from './UnpaidParental'
import RampBack from './RampBack'
import Miscarriage from './Miscarriage'
import Sickness from './Sickness'
import LongTermDisability from './LongTermDisability'
import AtWork from './AtWork'
import Unknown from './Unknown'
import AdditionalDisability from './AdditionalDisability'
import Other from './Other'

export default {
  AdditionalDisability,
  Annual,
  AtWork,
  BabyBonding,
  LongTermDisability,
  Miscarriage,
  Other,
  PreDelivery,
  RampBack,
  Recovery,
  Sickness,
  Unknown,
  UnpaidParental
}
