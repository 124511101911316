import { useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { queryLeaveJourneyMap } from 'src/graphql/queries'
import JourneyMapContext from 'src/features/JourneyMap/Context'
import ListContainer from 'src/components/JourneyMap/ListContainer'
import { convertJourneyMapDueDateToMoment } from 'src/utils/journeyMap'

export const JourneyMapListContainer = React.memo(() => {
  const context = useContext(JourneyMapContext)
  const {
    isOverdueMode,
    periodFilters,
    priorityFilters,
    blurred,
    onToggleError
  } = context

  const { data, loading, error } = useQuery(queryLeaveJourneyMap, {
    fetchPolicy: 'cache-first'
  })

  const journeyMap = useMemo(
    () => convertJourneyMapDueDateToMoment(data?.leave?.journeyMap),
    [data?.leave?.journeyMap]
  )

  if (loading) {
    return
  }

  if (error) {
    return
  }

  return (
    <ListContainer
      blurred={blurred}
      isOverdueMode={isOverdueMode}
      journeyMap={journeyMap}
      onToggleError={onToggleError}
      toggleJourneyMapItem={context.queries.toggleJourneyMapItem}
      periodFilters={periodFilters}
      priorityFilters={priorityFilters}
      leaveId={data?.leave.id}
    />
  )
})

JourneyMapListContainer.displayName = 'JourneyMapListContainer'

export default JourneyMapListContainer
