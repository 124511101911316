import React, { useState } from 'react'
import { Hint, Link } from 'src/UIKit'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

const ANIMATION_DURATION = 300

interface IProps {
  className?: string
  title: string
  text: string
  marginTop?: number
  marginBottom?: number
  scrollToBottomView?: boolean
}

const Container = styled.div<{ $marginTop: number; $marginBottom: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${props => props.$marginTop || 0}px;
  margin-bottom: ${props => props.$marginBottom || 0}px;
`

const TitleLink = styled(Link)`
  font-family: Roboto, serif;
  font-weight: normal;
  font-size: ${props => (props.theme.isDesktop ? '16px' : '14px')};
  line-height: 130%;
  color: ${props => props.theme.colors.main100};
`

const ContentContainer = styled.div`
  margin-top: 8px;
  max-width: 736px;
`

const InfoNoticeExpandable = React.memo((props: IProps) => {
  const { title, text, marginTop, marginBottom, scrollToBottomView } = props
  const [expanded, setExpanded] = useState<boolean>(false)

  const onScrollToBottom = () => {
    if (scrollToBottomView && expanded) {
      document.body.scrollTo({
        behavior: 'smooth',
        top: document.body.scrollHeight
      })
    }
  }

  return (
    <Container $marginTop={marginTop} $marginBottom={marginBottom}>
      <TitleLink
        onClick={() => setExpanded(!expanded)}
        aria-haspopup
        aria-expanded={expanded}
      >
        {title}
      </TitleLink>
      <AnimateHeight
        duration={ANIMATION_DURATION}
        height={expanded ? 'auto' : 0}
        style={{ width: '100%' }}
        animateOpacity
        onHeightAnimationEnd={onScrollToBottom}
      >
        <ContentContainer>
          <Hint text={text} />
        </ContentContainer>
      </AnimateHeight>
    </Container>
  )
})

InfoNoticeExpandable.displayName = 'InfoNoticeExpandable'

export default InfoNoticeExpandable
