import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components'
import { Button, Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import PopoverArrow from 'src/components/Popover/components/Arrow'
import useComponentRect from 'src/components/hooks/useComponentRect'
import { ITimelinePeriod } from 'src/react-app-env'
import ReactMarkdown from 'react-markdown'
import VacationInput, {
  FLOAT_REG_EXP
} from 'src/features/Timeline/components/common/VacationBalanceEditor/components/VacationInput'
import { KEY_ENTER } from 'src/constants/keys'
import WidgetNote from './WidgetNote'
import { DATE_FORMAT, isBlankDate } from 'src/utils/dateUtils'
import useForceUpdate from 'src/components/hooks/useForceUpdate'
import moment from 'moment'

interface IProps {
  period: ITimelinePeriod
  metadata: any
  onVacationBalanceChanged: (value: number) => void
  onResize?: () => void
}

const MAX_PROJECTED_BALANCE_HOURS = 300

const contentMixin = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 12px;
`

const Container = styled.div`
  width: 100%;
  margin: 20px 16px 0;
  background: ${props => props.theme.colors.main05};
  border: 1px solid ${props => props.theme.colors.main20};
  border-radius: 8px;
`
const TitleContainer = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 48px;
  width: 100%;
  border-radius: 8px;

  ${props =>
    !props.theme.isDesktop &&
    css`
      text-align: left;
    `}

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
  }
`

const Title = styled.span`
  font-family: Roboto, serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};
  margin-left: 12px;
`

const BodyContainer = styled.div<{ $height: number; $opened: boolean }>`
  transition: all 300ms;
  overflow: hidden;
  ${props => {
    const { $height, $opened } = props
    const opacity: string = $opened ? '1' : '0'
    const margin: string = $opened ? '16px 0 12px' : '0'
    return css`
      height: ${$opened ? $height : 0}px;
      visibility: ${$opened ? 'visible' : 'hidden'};
      margin: ${margin};
      opacity: ${opacity};
    `
  }}
`

const EditContainer = styled.div`
  ${contentMixin};
`

const RowTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${props => props.theme.colors.dark60};
  line-height: 130%;
  margin-bottom: 8px;
  font-size: 14px;
`

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.dark60};
  line-height: 130%;
`

const ButtonWrapper = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  height: 48px;
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 159px;
        `
      : css`
          width: 96px;
        `}
`

const CalculationWrapper = styled.div`
  ${contentMixin};
`

const DescriptionContainer = styled(ReactMarkdown)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.dark60};
  white-space: pre-wrap;

  em {
    font-style: italic;
  }

  strong {
    white-space: nowrap;
  }
`

const ProjectedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  margin: 18px 0 16px;
`

const ProjectedBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
`

const NumberText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.colors.dark80};
`

const StringText = styled.span`
  color: ${props => props.theme.colors.dark60};
  font-size: 14px;
  white-space: nowrap;
`

const ArrowContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 20px 0 0;
`

const WarningContainer = styled.div`
  width: 100%;
  display: flex;
`

const IconWrapper = styled(Icon)`
  margin: 0 12px 0 14px;
`

const WarningText = styled.span`
  color: ${props => props.theme.colors.dark80};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const dataAttrs = {
  title: () => 'button-title',
  calculate: () => 'button-calculate',
  warning: () => 'warning-info'
}

const VacationWidget = React.memo((props: IProps) => {
  const { metadata, onVacationBalanceChanged, onResize, period } = props
  const { t } = useTranslation()
  const forceUpdate = useForceUpdate()

  const vacationHours = metadata.PTOBalance?.toFixed(2).toString()
  const dateNow = moment().utc()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [vacation, setVacation] = useState(vacationHours)
  const [isValid, setIsValid] = useState(false)
  const [isChangeWidgetHeight, setIsChangeWidgetHeight] = useState(false)

  const bodyRef: any = useRef(null)
  const { height } = useComponentRect(bodyRef)

  const title: string = useMemo(() => t('vacationBalance.widget.title'), [t])

  const startDatePeriod: string = useMemo(
    () => period.periodStart.current.format(DATE_FORMAT),
    [period]
  )

  const projectedBalanceHours: number = useMemo(
    () => period.schedule?.balance,
    [period]
  )

  const projectedBalanceDays: string = useMemo(() => {
    const days = period.schedule?.maximumAllowed
    const daysText = t('common.durations.day', { count: days })
    return `(${days} ${daysText})`
  }, [t, period])

  const accrualRate: string = useMemo(() => {
    const lastRate = period.rates?.slice(-1)[0].rate
    const rateText = t('common.hour', { count: lastRate })
    return `${lastRate} ${rateText}`
  }, [t, period])

  const calculationDescriptionText: string = useMemo(
    () =>
      projectedBalanceHours >= MAX_PROJECTED_BALANCE_HOURS
        ? t('vacationBalance.widget.calculationDescriptionWithDate', {
            accrualRate,
            startDatePeriod
          })
        : t('vacationBalance.widget.calculationDescription', {
            accrualRate
          }),
    [projectedBalanceHours, t, accrualRate, startDatePeriod]
  )

  const isEquals: boolean = Number(vacation) === Number(vacationHours)
  const canShowWarning =
    isBlankDate(period.periodStart.current) ||
    period.periodStart.current.isBefore(dateNow)

  useEffect(() => {
    forceUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacationHours, isChangeWidgetHeight])

  const onChange = useCallback((value: string) => {
    const valid = FLOAT_REG_EXP.test(value)
    setIsValid(valid)
    setVacation(value)
  }, [])

  const onUpdateVacation = useCallback(
    (vacationValue: string) => {
      onVacationBalanceChanged(parseFloat(vacationValue))
    },
    [onVacationBalanceChanged]
  )

  const keyHandler = useCallback(
    (event: SyntheticEvent) => {
      if (!isValid) {
        return
      }
      const { keyCode } = event as any
      switch (keyCode) {
        case KEY_ENTER: {
          onUpdateVacation(vacation)
          break
        }
      }
    },
    [onUpdateVacation, vacation, isValid]
  )

  const calculation = useMemo(
    () =>
      projectedBalanceHours && (
        <CalculationWrapper>
          <ProjectedContainer>
            <DescriptionContainer>
              {t('vacationBalance.widget.projectedDescription', {
                startDatePeriod
              })}
            </DescriptionContainer>
            <ProjectedBlock>
              <NumberText>{projectedBalanceHours}</NumberText>
              <StringText>
                {t('common.hour', {
                  count: projectedBalanceHours
                })}
              </StringText>
              <StringText>{projectedBalanceDays}</StringText>
            </ProjectedBlock>
          </ProjectedContainer>
          <DescriptionContainer>
            {calculationDescriptionText}
          </DescriptionContainer>
        </CalculationWrapper>
      ),
    [
      t,
      startDatePeriod,
      projectedBalanceHours,
      projectedBalanceDays,
      calculationDescriptionText
    ]
  )

  const vacationContainer = useMemo(
    () => (
      <>
        <EditContainer>
          <RowTitle>{t('vacationBalance.dialog.editVacation')}</RowTitle>
          <RowContainer>
            <VacationInput
              value={vacation}
              onChange={onChange}
              onKeyDown={keyHandler}
              width={'181px'}
            />
            <ButtonWrapper
              data-testid={dataAttrs.calculate()}
              onClick={() => onUpdateVacation(vacation)}
              disabled={!isValid || !vacation || isEquals}
              appearance={'cancel'}
            >
              {t('vacationBalance.dialog.calculate')}
            </ButtonWrapper>
          </RowContainer>
          <WidgetNote setIsChangeWidgetHeight={setIsChangeWidgetHeight} />
        </EditContainer>
        {vacationHours && calculation}
      </>
    ),
    [
      t,
      vacation,
      onChange,
      keyHandler,
      isValid,
      isEquals,
      vacationHours,
      calculation,
      onUpdateVacation
    ]
  )

  const warningInfo = useMemo(() => {
    let messageWarning: string
    if (isBlankDate(period.periodStart.current)) {
      messageWarning = t('vacationBalance.widget.warningStartDate')
    } else if (period.periodStart.current.isBefore(dateNow)) {
      messageWarning = t('vacationBalance.widget.warningFutureDates')
    }

    return (
      <WarningContainer data-testid={dataAttrs.warning()}>
        <IconWrapper name={'exclamation_sign_inverted'} />
        <WarningText>{messageWarning}</WarningText>
      </WarningContainer>
    )
  }, [dateNow, period, t])

  return (
    <Container>
      <TitleContainer
        data-testid={dataAttrs.title()}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        role={'status'}
        aria-haspopup
        aria-expanded={isOpen}
        aria-label={isOpen ? t('vacationBalance.dialog.editVacation') : title}
      >
        <Title aria-hidden>{title}</Title>
        <ArrowContainer>
          <PopoverArrow isOpen={isOpen} />
        </ArrowContainer>
      </TitleContainer>

      <BodyContainer
        $height={height}
        $opened={isOpen}
        onTransitionEnd={onResize}
        aria-hidden
      >
        <div ref={bodyRef}>
          {canShowWarning ? warningInfo : vacationContainer}
        </div>
      </BodyContainer>
    </Container>
  )
})

VacationWidget.displayName = 'VacationWidget'

export default VacationWidget
