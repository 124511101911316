import React from 'react'
import styled, { css } from 'styled-components'
import PreviousVersionsHeader from '../PreviousVersionsHeader'

interface IProps {
  children: any
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  ${props => {
    if (props.theme.isDesktop) {
      return css`
        width: 100vw;
        height: 100%;
      `
    } else {
      return css`
        width: 100%;
      `
    }
  }}
`

const ChildrenContainer = styled.div`
  width: 100%;
  -webkit-overflow-scrolling: touch;

  ${props => {
    if (props.theme.isDesktop) {
      return css`
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
      `
    } else {
      return css`
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media print {
          overflow: visible;
        }
      `
    }
  }}
`

export const PreviousVersionsTopViewContainer = React.memo((props: IProps) => (
  <Container>
    <PreviousVersionsHeader />
    <ChildrenContainer>{props.children}</ChildrenContainer>
  </Container>
))

PreviousVersionsTopViewContainer.displayName =
  'PreviousVersionsTopViewContainer'

export default PreviousVersionsTopViewContainer
