import React, { useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import RadioButton from 'src/UIKit/RadioButton'
import useHovered from 'src/components/hooks/useHovered'

interface IProps {
  selected: boolean
  onClick: () => void
  hidesSeparator?: boolean
  optionTextMixin?: any
  optionContentMixin?: any
  color?: string
}

export const dataAttrs = {
  radioButton: () => 'radio-button-option'
}

const Container = styled.li`
  width: 100%;
  cursor: pointer;
`

const ContentContainer = styled.div<{ $optionContentMixin: any }>`
  background: ${props => props.theme.colors.light100};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(100% - 48px);
          padding: 24px;
        `
      : css`
          width: calc(100% - 32px);
          padding: 16px;
        `}

  ${(props: any) => props.$optionContentMixin}
`

const OptionContainer = styled.div<{ $color: string; $optionTextMixin: any }>`
  font-weight: 500;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
          line-height: 100%;
          color: ${props.$color || props.theme.colors.dark80};
        `
      : css`
          font-size: 14px;
          line-height: 130%;
          color: ${props.$color || props.theme.colors.dark60};
          margin-right: 16px;
        `}

  ${(props: any) => props.$optionTextMixin}
`

const Separator = styled.div`
  height: 1px;
  background: ${props => props.theme.colors.dark05};

  ${props =>
    props.theme.isDesktop
      ? css`
          width: calc(100% - 48px);
          margin: 0 24px;
        `
      : css`
          width: 100%;
        `}
`

const RadioButtonOption = React.memo(
  (props: React.PropsWithChildren<IProps>) => {
    const {
      children,
      selected,
      onClick,
      hidesSeparator,
      optionTextMixin,
      color,
      optionContentMixin
    } = props
    const contentRef: any = useRef(null)
    const hovered: boolean = useHovered(contentRef)
    const ariaLabel: string = useMemo(() => {
      if (typeof children === 'string') {
        return children
      }

      if (Array.isArray(children)) {
        return children.find(child => typeof child === 'string')
      }

      return null
    }, [children])

    return (
      <Container data-testid={dataAttrs.radioButton()} onClick={onClick}>
        <ContentContainer
          ref={contentRef}
          $optionContentMixin={optionContentMixin}
        >
          <OptionContainer
            $optionTextMixin={optionTextMixin}
            $color={color}
            aria-hidden
          >
            {children}
          </OptionContainer>
          <RadioButton
            selected={selected}
            hovered={hovered}
            ariaLabel={ariaLabel}
          />
        </ContentContainer>
        {!hidesSeparator && <Separator />}
      </Container>
    )
  }
)

RadioButtonOption.displayName = 'RadioButtonOption'

export default RadioButtonOption
