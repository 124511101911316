import React from 'react'
import styled from 'styled-components'
import { IIcon } from 'src/react-app-env'
import { Icon } from 'src/UIKit'

interface IProps {
  icon: IIcon
  className?: any
  ariaLabel?: string
}

const Wrapper = styled(Icon)`
  grid-area: icon;
  place-self: center start;
  height: 24px;
`

const FieldCardIcon = React.memo((props: IProps) => {
  const { icon, className, ariaLabel } = props
  return (
    <Wrapper
      className={className}
      name={icon.iconName}
      settings={icon.iconProps}
      ariaLabel={ariaLabel}
    />
  )
})

FieldCardIcon.displayName = 'FieldCardIcon'

export default FieldCardIcon
