import React, { ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import WidgetNoteText from './WidgetNoteText'

interface IProps {
  setIsChangeWidgetHeight: (value: boolean) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const HowFindView = styled.button`
  margin: 6px 0 0;
  display: inline-block;
  outline: none;
  color: ${props => props.theme.colors.main100};
  border: none;
  font-weight: normal;
  line-height: 100%;
  font-size: 14px;
  text-align: center;
  background: none;
  text-decoration: none;
  padding: 0 0 4px;

  &:hover {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }

  &:focus {
    color: ${props => props.theme.colors.main110};
    text-decoration: underline;
  }
`

const WidgetNote = React.memo((props: IProps) => {
  const { setIsChangeWidgetHeight } = props
  const { t } = useTranslation()
  const [showsHowFindView, setShowsHowFindView] = useState<boolean>(false)

  const howDoFindView: ReactNode = useMemo(
    () => (
      <HowFindView
        onClick={() => {
          setShowsHowFindView(!showsHowFindView)
          setIsChangeWidgetHeight(!showsHowFindView)
        }}
        aria-haspopup
        aria-expanded={showsHowFindView}
      >
        {t('vacationBalance.widget.note.howDoFind')}
      </HowFindView>
    ),
    [showsHowFindView, t, setIsChangeWidgetHeight]
  )

  const howDoFindNoteView: ReactNode = useMemo(
    () =>
      showsHowFindView && (
        <WidgetNoteText
          text={t('vacationBalance.widget.note.howDoFindNote')}
          marginTop={8}
        />
      ),
    [t, showsHowFindView]
  )

  return (
    <Container>
      {howDoFindView}
      {howDoFindNoteView}
    </Container>
  )
})

WidgetNote.displayName = 'WidgetNote'

export default WidgetNote
