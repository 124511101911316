import { useCallback, useEffect, useState } from 'react'

export default (componentRef: any) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const onMouseOver = useCallback(() => {
    setHovered(true)
  }, [setHovered])

  const onMouseOut = useCallback(() => {
    setHovered(false)
  }, [setHovered])

  useEffect(() => {
    if (!componentRef) {
      return
    }

    const { current } = componentRef
    if (!current) {
      return
    }

    current.addEventListener('mouseover', onMouseOver)
    current.addEventListener('mouseout', onMouseOut)

    return () => {
      current.removeEventListener('mouseover', onMouseOver)
      current.removeEventListener('mouseout', onMouseOut)
    }
  })

  return hovered
}
