import moment, { Moment } from 'moment'
import { ITimelinePeriod } from 'src/react-app-env'
import {
  getDaysBetweenDates,
  IChangesIntermittentBlock,
  isIntermittent
} from '../methods'
import isEqual from 'lodash.isequal'
import { isBlankDate } from 'src/utils/dateUtils'

export const MIN_BLOCK_DURATION = 7

const availableStartDate = (
  period: ITimelinePeriod,
  timelinePeriods: ITimelinePeriod[]
): Moment => {
  const intermittentBlocks = timelinePeriods.filter(
    (p: ITimelinePeriod) => isIntermittent(p) && p.type === period.type
  )
  const latestBlock = intermittentBlocks.slice(-1)[0]
  if (!latestBlock) {
    return period.periodStart.current
  }

  const date = latestBlock.periodEnd.current
  if (isBlankDate(date)) {
    return latestBlock.periodStart.min
  }
  return date.clone().add(1, 'day')
}

export const newIntermittentBlock = (
  period: ITimelinePeriod,
  timelinePeriods: ITimelinePeriod[]
): IChangesIntermittentBlock => {
  const startDate =
    period.appearance === 'Addable' && period.periodStart.min
      ? period.periodStart.min
      : availableStartDate(period, timelinePeriods)

  const duration =
    period.appearance === 'Addable'
      ? getDaysBetweenDates(period.periodEnd.max, startDate) + 1
      : MIN_BLOCK_DURATION
  return { startDate, duration }
}

export const canAddNewIntermittentBlock = (
  intermittentBlocks: ITimelinePeriod[]
) => {
  if (intermittentBlocks.length < 1) {
    return true
  }
  const latestBlock = intermittentBlocks.slice(-1)[0]
  const totalUnusedAvailable = latestBlock.schedule.totalUnused > 0
  const { periodEnd } = latestBlock

  if (isBlankDate(periodEnd.current) || isBlankDate(periodEnd.max)) {
    return totalUnusedAvailable
  }
  return (
    totalUnusedAvailable &&
    getDaysBetweenDates(periodEnd.current, periodEnd.max) >= MIN_BLOCK_DURATION
  )
}

const getActiveDaysForDate = (
  date: Moment,
  schedule: IApplicableSchedule,
  activeDaysWeekOne: Set<IWeekday>,
  activeDaysWeekTwo: Set<IWeekday>
): Set<IWeekday> => {
  if (
    !schedule.weekTwo?.length ||
    isEqual(activeDaysWeekOne, activeDaysWeekTwo)
  ) {
    return activeDaysWeekOne
  }
  const startDateStartOfWeek = moment(schedule.weekOneStart)
    .clone()
    .startOf('isoWeek')
  const dateStartOfWeek = date.clone().startOf('isoWeek')
  const diffWeeks = dateStartOfWeek.diff(startDateStartOfWeek, 'weeks')

  return diffWeeks % 2 === 0 ? activeDaysWeekOne : activeDaysWeekTwo
}

export const getIntermittentBlockDuration = ({
  schedule,
  startDate,
  duration,
  activeDaysWeekOne,
  activeDaysWeekTwo
}: any): number => {
  let durationWorkdays = 0
  const start = moment(startDate)
  const end = moment(startDate).add(duration, 'days')

  for (const m = moment(start); m.isBefore(end); m.add(1, 'days')) {
    const activeDays = getActiveDaysForDate(
      m,
      schedule,
      activeDaysWeekOne,
      activeDaysWeekTwo
    )
    const dayName = m.format('dddd') as IWeekday
    if (activeDays.has(dayName)) {
      durationWorkdays += 1
    }
  }
  return durationWorkdays
}

export const hasChanges = (selectedParams: any, initialParams: any) => {
  if (Array.isArray(selectedParams) && Array.isArray(initialParams)) {
    const diff = selectedParams
      .filter(i => initialParams.indexOf(i) < 0)
      .concat(initialParams.filter(i => selectedParams.indexOf(i) < 0))
    return diff.length > 0
  }
  return selectedParams !== initialParams
}
