import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'src/utils/dateUtils'
import moment from 'moment'
import { Icon } from 'src/UIKit'
import createTheme from 'src/theme'
import Tooltip from 'src/components/Tooltip'

const { colors } = createTheme()

interface IProps {
  className?: string
  tpa: ITpa
}

interface IStatusText {
  labelText: string
  tooltipTitle: string
  tooltipBody: string
}

const Container = styled.div<{
  $isError: boolean
}>`
  display: flex;
  align-items: center;
  background: ${props =>
    props.$isError
      ? props.theme.colors.specialBrownLow
      : props.theme.colors.dark05};
  border-radius: 64px;

  ${props =>
    props.theme.isDesktop
      ? css`
          padding: 4px 6px;
        `
      : css`
          padding: 3px 6px;
        `}
`

const IconWrapper = styled(Icon)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 2px 6px 0 0;
        `
      : css`
          margin: 2px 4px 0 0;
        `}
`

const Text = styled.span<{
  $isError: boolean
}>`
  font-weight: 500;
  color: ${props =>
    props.$isError
      ? props.theme.colors.specialBrown
      : props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 11px;
        `}
`

export const dataAttrs = {
  container: () => 'sync-status-container'
}

const SyncStatusView = React.memo((props: IProps) => {
  const { className, tpa } = props
  const { t } = useTranslation()

  const dateSyncedAt: string = useMemo(
    () =>
      tpa?.syncedAt ? moment(tpa.syncedAt).utc().format(DATE_FORMAT) : null,
    [tpa?.syncedAt]
  )

  const dateFailedAt: string = useMemo(
    () => moment(tpa?.syncFailedAt).utc().format(DATE_FORMAT),
    [tpa?.syncFailedAt]
  )

  const isError: boolean = useMemo(
    () => !!tpa?.syncFailedAt,
    [tpa?.syncFailedAt]
  )

  const statusText: IStatusText = useMemo(() => {
    if (isError) {
      return {
        labelText: t('syncStatus.error.labelText'),
        tooltipTitle: t('syncStatus.error.tooltip.title'),
        tooltipBody: t('syncStatus.error.tooltip.body', { dateFailedAt })
      }
    }
    return tpa?.syncing
      ? {
          labelText: t('syncStatus.enabled.labelText'),
          tooltipTitle: t('syncStatus.enabled.tooltip.title'),
          tooltipBody: dateSyncedAt
            ? t('syncStatus.enabled.tooltip.bodyWithDate', { dateSyncedAt })
            : t('syncStatus.enabled.tooltip.body')
        }
      : {
          labelText: t('syncStatus.disabled.labelText'),
          tooltipTitle: t('syncStatus.disabled.tooltip.title'),
          tooltipBody: t('syncStatus.disabled.tooltip.body')
        }
  }, [isError, tpa?.syncing, t, dateSyncedAt, dateFailedAt])

  if (!tpa) {
    return null
  }

  return (
    <Tooltip
      dashedUnderline={{ skipUnderline: true }}
      title={statusText.tooltipTitle}
      body={statusText.tooltipBody}
    >
      <Container
        data-testid={dataAttrs.container()}
        className={className}
        aria-label={statusText.labelText}
        role={'note'}
        $isError={isError}
      >
        <IconWrapper
          name={'cloud_update'}
          settings={{ fill: isError ? colors.specialBrown : colors.dark60 }}
        />
        <Text aria-hidden $isError={isError}>
          {statusText.labelText}
        </Text>
      </Container>
    </Tooltip>
  )
})

SyncStatusView.displayName = 'SyncStatusView'

export default SyncStatusView
