import { IDialogData } from 'src/react-app-env'
import { DISAPPEARING_TIME } from 'src/components/DialogManager/components/Dialog'

const add = (data: IDialogData, setDialog: (a: IDialogData) => void): void => {
  setDialog(data)
}

const remove = (
  dialog: IDialogData,
  setDialog: (a: IDialogData) => void
): void => {
  setDialog({ ...dialog, isDismissing: true })

  window.setTimeout(() => {
    setDialog(null)
  }, DISAPPEARING_TIME)
}

export { add, remove }
