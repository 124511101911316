import { ELIGIBILITY_NOTICE_KEY } from './ls'

interface IEligibilityNotice {
  canBeShown: boolean
}

const getEligibilityNotice = (): IEligibilityNotice => {
  try {
    const eligibilityNotice: IEligibilityNotice = JSON.parse(
      localStorage.getItem(ELIGIBILITY_NOTICE_KEY)
    )
    if (!eligibilityNotice) {
      return { canBeShown: true }
    }

    return eligibilityNotice
  } catch (e) {
    return undefined
  }
}

const setEligibilityNotice = (eligibilityNotice: IEligibilityNotice) => {
  localStorage.setItem(
    ELIGIBILITY_NOTICE_KEY,
    JSON.stringify(eligibilityNotice)
  )
}

const setCanBeShown = (canBeShown: boolean) => {
  const eligibilityNotice: IEligibilityNotice = getEligibilityNotice()
  eligibilityNotice.canBeShown = canBeShown
  setEligibilityNotice(eligibilityNotice)
}

const getCanBeShown = (): boolean => {
  const eligibilityNotice: IEligibilityNotice = getEligibilityNotice()
  return eligibilityNotice.canBeShown
}

export { setCanBeShown, getCanBeShown }
