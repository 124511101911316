import queryString from 'fast-querystring'
import {
  createEvent,
  Alarm,
  Attendee,
  EventAttributes,
  Person,
  DateArray
} from 'ics'
import { Moment } from 'moment'
import { getMeetingTimeSinceNow } from 'src/utils/dateUtils'

const dateArrayFromMoment = (m: Moment): DateArray => [
  m.year(),
  m.month() + 1,
  m.date(),
  m.hour(),
  m.minute()
]

const downloadMeetingFile = (eventText: string) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/calendar;charset=utf-8,' + encodeURIComponent(eventText)
  )
  element.setAttribute('download', 'meeting.ics')
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

interface ICreateMeetingData {
  uid: string
  title: string
  description: string
  organizer: Person
  attendees: Attendee[]
  start: Moment
  end: Moment
  alarms?: Alarm[]
}

export interface IMeetingLinkParams {
  uid: string
  attendeeEmail: string
  start: Moment
  duration: number
  title: string
  description: string
}

export const parseMeetingLink = (link: string): IMeetingLinkParams => {
  link = decodeURI(link)
  if (link.indexOf('veercal:') === -1) {
    return null
  }

  try {
    const linkComponents: string[] = link.split(':')[1].split('/')
    const uid: string = linkComponents[0]
    const attendeeEmail: string = linkComponents[1].split('?')[0]
    const queryParams: any = queryString.parse(linkComponents[1].split('?')[1])

    const start: Moment = getMeetingTimeSinceNow(9, 16).utc()

    return {
      uid,
      attendeeEmail,
      start,
      duration: queryParams.duration,
      title: queryParams.subject,
      description: queryParams.description
    }
  } catch (e) {
    return undefined
  }
}

export const createMeeting = (data: ICreateMeetingData): Promise<any> =>
  new Promise(
    (success: (value: boolean) => void, reject: (error: Error) => void) => {
      const {
        uid,
        title,
        description,
        organizer,
        attendees,
        start,
        end,
        alarms
      } = data
      const attributes: EventAttributes = {
        uid,
        title,
        organizer,
        attendees,
        description,
        alarms,
        start: dateArrayFromMoment(start),
        end: dateArrayFromMoment(end),
        method: 'REQUEST',
        productId: '-//getveer.com//iCalendar Event//EN',
        startInputType: 'utc',
        endInputType: 'utc'
      }
      createEvent(attributes, (err: Error, value: string) => {
        if (err) {
          reject(err)
        } else {
          downloadMeetingFile(value)
          success(true)
        }
      })
    }
  )
