import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isFirefox } from 'src/utils/deviceTypes'

interface IProps {
  className?: string
  phase: ILeavePhase
  useVerticalLayout?: boolean
  ariaHidden?: boolean
}

const Container = styled.div<{ $useVerticalLayout: boolean }>`
  display: flex;

  ${props =>
    props.$useVerticalLayout
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `};

  @media print {
    flex-direction: row;
    align-items: center;
  }
`

const textMixin = css`
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  color: ${props => props.theme.colors.dark60};

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 12px;
        `}

  @media print {
    font-size: 14pt;
    ${isFirefox() &&
    css`
      font-size: 12px;
    `}
  }
`

const Value = styled.span<{ $useVerticalLayout: boolean }>`
  ${textMixin};
  font-weight: 500;
  ${props =>
    props.$useVerticalLayout &&
    css`
      margin-top: 4px;
    `};

  @media print {
    margin-top: 0;
  }
`

const PhaseLabel = React.memo((props: IProps) => {
  const { className, phase, useVerticalLayout, ariaHidden } = props
  const { t } = useTranslation()
  const value: string = useMemo(
    () => t(`journeyMapPhases.${phase}`).toLocaleLowerCase(),
    [phase, t]
  )
  const ariaLabel: string = useMemo(
    () => value + ' ' + t('common.phase').toLocaleLowerCase(),
    [value, t]
  )

  return (
    <Container
      className={className}
      $useVerticalLayout={useVerticalLayout}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      role={'note'}
    >
      <Value $useVerticalLayout={useVerticalLayout} aria-hidden>
        {value}
      </Value>
    </Container>
  )
})

PhaseLabel.displayName = 'PhaseLabel'

export default PhaseLabel
