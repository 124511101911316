import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from 'src/UIKit'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileMaxWidthMixin } from '../../../theme/utils'

interface IProps {
  onClose: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  ${props =>
    props.theme.isDesktop &&
    css`
      height: 570px;
    `}
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const MobileBottomPanel = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.light100};
  box-shadow: 0 -4px 10px rgb(0 0 0 / 5%);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const MobileCloseButton = styled(Button)`
  width: calc(100% - 32px);
  ${mobileMaxWidthMixin};
`

const IconWrapper = styled(Icon)`
  ${props =>
    props.theme.isDesktop
      ? css`
          margin: 32px 0;
        `
      : css`
          margin: 40px 0 16px;
        `}
`

const Title = styled.span`
  text-align: center;
  line-height: 150%;
  color: ${props => props.theme.colors.dark60};
  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 18px;
          margin: 32px 32px 0;
        `
      : css`
          font-size: 14px;
          margin: 0 16px;
        `}

  ${props =>
    props.theme.isMobileLandscape &&
    css`
      margin: 32px 16px 0;
    `}
`

const SurveySubmittedView = React.memo((props: IProps) => {
  const { onClose } = props
  const { t } = useTranslation()
  const { isMobile, isDesktop, isMobileLandscape } = useContext(ScreenContext)

  return (
    <Container>
      <InnerContainer>
        {!isMobileLandscape && (
          <IconWrapper
            name={isDesktop ? 'survey_done' : 'survey_done_mobile'}
            ariaLabel={t('common.accessibilityText.surveyDone')}
          />
        )}
        <Title>{t('surveys.thankYou')}</Title>
      </InnerContainer>
      {isMobile && (
        <MobileBottomPanel>
          <MobileCloseButton onClick={onClose}>
            {t('common.close')}
          </MobileCloseButton>
        </MobileBottomPanel>
      )}
    </Container>
  )
})

SurveySubmittedView.displayName = 'SurveySubmittedView'

export default SurveySubmittedView
