import React from 'react'
import { useTranslation } from 'react-i18next'
import TileInfo from 'src/components/Dashboard/TileInfo'
import styled, { css } from 'styled-components'
import { DashboardProps } from '../../config'
import createTheme from 'src/theme'
import moment from 'moment'
const { colors } = createTheme()

const Dot = styled.div<{ $color: string }>`
  background-color: ${props => props.$color};

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: 5px;
          margin-right: 10px;
          width: 12px;
          height: 12px;
          border-radius: 6px;
        `
      : css`
          margin-top: 3px;
          margin-right: 6px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
        `}
`
const Container = styled.span<{ $color: string }>`
  display: flex;
  color: ${props => props.$color};
`

const LeaveStatus = React.memo((props: DashboardProps) => {
  const { t } = useTranslation()
  const { absence } = props
  const now = moment.utc()
  const currentPeriod = absence.info?.currentPeriod

  let absenceType = 'Unknown'

  if (now.isBefore(absence.startDate)) {
    absenceType = 'LeavePlanned'
  } else if (currentPeriod) {
    absenceType = currentPeriod?.type
  }

  const title = t('manager.tiles.leaveStatus.title')
  const status = t(`manager.tiles.leaveStatus.${absenceType}`)
  const color = absenceType === 'OnLeave' ? colors.success : colors.purple

  return (
    <TileInfo title={title}>
      <Container $color={color}>
        <Dot $color={color} />
        {status}
      </Container>
    </TileInfo>
  )
})

LeaveStatus.displayName = 'LeaveStatus'

export default LeaveStatus
