import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Icon, MarkdownText } from 'src/UIKit'
import { useTranslation } from 'react-i18next'
import { removeSpaces } from 'src/utils/stringUtils'

interface IProps {
  className?: string
  alertTranslationKey: string
  metadata?: any
}

const Container = styled.div<any>`
  width: 100%;
  display: flex;
  transition: all 300ms;
  border-radius: 8px;
  background: ${props => props.theme.colors.error05};
`

const IconContainer = styled.div<{ $noText: boolean }>`
  ${props =>
    props.$noText
      ? css`
          margin: 5px 0 0 5px;
        `
      : css`
          margin: 9px 0 0 9px;
        `};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 130%;
  white-space: pre-line;
  width: 100%;
  padding: 8px;
  color: ${props => props.theme.colors.dark60};
`

const Title = styled.span`
  font-weight: 500;
`

const Text = styled(MarkdownText)`
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;

  a {
    color: ${props => props.theme.colors.main100};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
    }

    &:focus {
      text-decoration: underline;
      color: ${props => props.theme.colors.main110};
      outline-offset: 1px;
    }
  }
`

export const dataAttrs = {
  title: () => 'date-picker-alert-title',
  text: () => 'date-picker-alert-text'
}

const DatePickerAlert = React.memo((props: IProps) => {
  const { alertTranslationKey, metadata } = props
  const { t }: { t: any } = useTranslation()

  const title: string = useMemo(
    () =>
      t([
        `timeline.datePickerAlerts.${alertTranslationKey}.title_${metadata?.countryState}_${removeSpaces(
          metadata?.city
        )}`,
        `timeline.datePickerAlerts.${alertTranslationKey}.title_${metadata?.countryState}`,
        `timeline.datePickerAlerts.${alertTranslationKey}.title`
      ]),
    [t, alertTranslationKey, metadata]
  )

  const text: string = useMemo(
    () =>
      t(
        [
          `timeline.datePickerAlerts.${alertTranslationKey}.text_${metadata?.countryState}_${removeSpaces(
            metadata?.city
          )}`,
          `timeline.datePickerAlerts.${alertTranslationKey}.text_${metadata?.countryState}`,
          `timeline.datePickerAlerts.${alertTranslationKey}.text`
        ],
        {
          ...metadata
        }
      ),
    [t, alertTranslationKey, metadata]
  )

  return (
    <Container role={'alert'}>
      <IconContainer $noText={!text}>
        <Icon name={'exclamation_sign'} />
      </IconContainer>
      <BodyContainer>
        <Title data-testid={dataAttrs.title()}>{title}</Title>
        <Text data-testid={dataAttrs.text()} allowsParagraph>
          {text}
        </Text>
      </BodyContainer>
    </Container>
  )
})

DatePickerAlert.displayName = 'DatePickerAlert'

export default DatePickerAlert
