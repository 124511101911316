import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'

interface IProps {
  name: string
  onBackClicked: () => void
  onNextClicked: () => void
  isNextDisabled: boolean
  children?: ReactNode
  leaveType?: ILeaveType
  countryState?: string
}

export const CreateLeaveView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    name,
    children,
    onBackClicked,
    onNextClicked,
    isNextDisabled,
    leaveType,
    countryState
  } = props

  const buttons: any = useMemo(
    () => (
      <CreateLeaveButtons
        backTitle={t('common.back')}
        nextTitle={t('common.next')}
        onBackClick={onBackClicked}
        onNextClick={onNextClicked}
        isNextDisabled={isNextDisabled}
      />
    ),
    [t, isNextDisabled, onBackClicked, onNextClicked]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>
        {t([
          `createLeave.${name}.title_${leaveType}`,
          `createLeave.${name}.title`
        ])}
      </CreateLeaveTitle>
      <CreateLeaveDescription marginBottom={!children ? '8px' : null}>
        {t([
          `createLeave.${name}.description_${leaveType}_${countryState}`,
          `createLeave.${name}.description_${leaveType}`,
          `createLeave.${name}.description`
        ])}
      </CreateLeaveDescription>
      {children}
      {buttons}
    </CreateLeaveContentContainer>
  )
})

CreateLeaveView.displayName = 'CreateLeaveView'

export default CreateLeaveView
