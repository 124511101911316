import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'src/UIKit'
import TimelineContext, {
  ITimelineContext
} from 'src/features/Timeline/Context'
import { useTranslation } from 'react-i18next'
import { TutorialElementsRegister } from 'src/features/Timeline/Tutorial'
import { isDraftLeave, isNewLeave } from 'src/utils/leaveStatusUtils'
import ScreenContext from 'src/contexts/ScreenContext'
import { mobileButtonContainerPaddingMixin } from 'src/theme/utils'
import Tooltip from 'src/components/Tooltip'
import SaveChangesTooltip from '../SaveChangesTooltip'
import SharedContext from 'src/contexts/SharedContext'

interface IProps {
  className?: string
  buttonsSpace?: number
}

const ButtonsContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.isDesktop
      ? css`
          flex: 1;
          flex-direction: row;
          justify-content: flex-end;
          padding-top: 20px;
        `
      : css`
          justify-content: space-between;
        `}

  ${mobileButtonContainerPaddingMixin};
`

const ButtonWrapper = styled.div`
  width: 100%;
`

const buttonMixin = css`
  width: 100%;
  ${props =>
    props.theme.isMobile &&
    css`
      height: 48px;
      font-size: 16px;
    `}
`

export const CancelButton = styled(Button)`
  ${buttonMixin}
  ${props =>
    props.theme.isDesktop
      ? css`
          margin-right: 24px;
          min-width: 168px;
        `
      : css`
          margin: 0 8px 0 0;
        `}
`

export const SaveButton = styled(Button)<any>`
  ${buttonMixin}
  ${props =>
    props.theme.isDesktop &&
    css`
      min-width: 182px;
    `}

  ${props =>
    props.buttonsSpace &&
    css`
      margin-left: ${props.buttonsSpace}px;
    `}
`

const TimelineButtons = React.memo((props: IProps) => {
  const { customerConfig } = useContext(SharedContext)
  const context: ITimelineContext = useContext(TimelineContext)
  const {
    onTimelineCancelChanges,
    onTimelineSaveChanges,
    onStartOver,
    leave: { status, metadata, isDirty },
    isLeaveDurationPickerOpened
  } = context
  const [saveChangesTooltipVisible, setSaveChangesTooltipVisible] =
    useState(true)
  const { className, buttonsSpace } = props
  const { t } = useTranslation()

  const saveButtonRef: any = useRef(null)

  useEffect(() => {
    if (!saveButtonRef.current) {
      return
    }
    TutorialElementsRegister.getInstance().startJourneyElement =
      saveButtonRef.current
  }, [saveButtonRef])

  const isNew: boolean = isNewLeave(status)
  const isDraft: boolean = isDraftLeave(status)
  const isNewOrDraft: boolean = isNew || isDraft

  const saveDisabled: boolean =
    isLeaveDurationPickerOpened || metadata?.disableStartJourney
  const cancelDisabled: boolean = isLeaveDurationPickerOpened

  const cancelTitleKey: string = isNewOrDraft
    ? 'common.startOver'
    : 'common.cancelChanges'
  const saveButtonTitleKey: string = isNewOrDraft
    ? 'common.startJourney'
    : 'common.updateJourney'
  const saveButtonIcon: string = isNewOrDraft ? 'checkmark' : 'update'

  const cancelHandler: any = () => {
    if (isNewOrDraft) {
      onStartOver()
    } else {
      onTimelineCancelChanges()
    }
  }

  const { isMobile } = useContext(ScreenContext)

  const saveButton = useMemo(
    () => (
      <SaveButton
        disabled={saveDisabled}
        onClick={onTimelineSaveChanges}
        icon={{
          iconName: saveButtonIcon,
          iconProps: { fill: 'white' }
        }}
        buttonsSpace={buttonsSpace}
        ref={saveButtonRef}
        ariaLabel={
          isDraftLeave(status)
            ? t('timeline.startJourneyTooltip.body')
            : t(saveButtonTitleKey)
        }
      >
        {t(saveButtonTitleKey)}
      </SaveButton>
    ),
    [
      buttonsSpace,
      onTimelineSaveChanges,
      saveButtonIcon,
      saveButtonTitleKey,
      saveDisabled,
      status,
      t
    ]
  )

  const saveChangesTooltipComponent = useMemo(
    () =>
      isDirty &&
      saveChangesTooltipVisible &&
      metadata?.showSaveChangesTooltip ? (
        <SaveChangesTooltip
          visible
          disabled={isMobile || isLeaveDurationPickerOpened}
          onClose={() => setSaveChangesTooltipVisible(false)}
        >
          {saveButton}
        </SaveChangesTooltip>
      ) : (
        <ButtonWrapper>
          <Tooltip
            dashedUnderline={{ skipUnderline: true }}
            title={t('timeline.saveChangesTooltip.title')}
            body={t('timeline.saveChangesTooltip.body', {
              tpa: customerConfig.tpa
            })}
            disabled={isMobile}
          >
            {saveButton}
          </Tooltip>
        </ButtonWrapper>
      ),
    [
      customerConfig.tpa,
      isDirty,
      isLeaveDurationPickerOpened,
      isMobile,
      saveChangesTooltipVisible,
      metadata?.showSaveChangesTooltip,
      saveButton,
      t
    ]
  )

  const saveButtonWithTooltip = useMemo(
    () =>
      isNewOrDraft ? (
        <ButtonWrapper>
          <Tooltip
            dashedUnderline={{ skipUnderline: true }}
            disabled={isMobile}
            body={t('timeline.startJourneyTooltip.body')}
            offset={[0, 15]}
          >
            {saveButton}
          </Tooltip>
        </ButtonWrapper>
      ) : (
        isDirty && saveChangesTooltipComponent
      ),
    [
      isMobile,
      saveButton,
      isNewOrDraft,
      isDirty,
      saveChangesTooltipComponent,
      t
    ]
  )

  return (
    <ButtonsContainer className={className}>
      <CancelButton
        appearance="cancel"
        disabled={cancelDisabled}
        onClick={cancelHandler}
      >
        {t(cancelTitleKey)}
      </CancelButton>
      {saveButtonWithTooltip}
    </ButtonsContainer>
  )
})

TimelineButtons.displayName = 'TimelineButtons'

export default TimelineButtons
