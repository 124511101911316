import React, { useContext, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import LeaveContext, { ICreateLeaveContext } from 'src/features/Leave/context'
import DatePicker from 'src/components/DatePicker'
import { useTranslation } from 'react-i18next'
import CreateLeaveTitle from 'src/features/Leave/components/UI/CreateLeaveTitle'
import CreateLeaveDescription from 'src/features/Leave/components/UI/CreateLeaveDescription'
import CreateLeaveButtons from 'src/features/Leave/components/UI/CreateLeaveButtons'
import CreateLeaveContentContainer from 'src/features/Leave/components/UI/CreateLeaveContentContainer'
import ScreenContext from 'src/contexts/ScreenContext'
import CreateLeaveNote from '../UI/CreateLeaveNote'
import { DATE_FORMAT, getNowMoment } from 'src/utils/dateUtils'
import moment from 'moment'

interface IProps {
  leaveType: string
}

const datePickerMixin = css`
  ${props =>
    props.theme.isDesktop
      ? css`
          width: 360px;
        `
      : css`
          width: 100%;
        `}
`

const StartDatePicker = styled(DatePicker)`
  ${datePickerMixin}
  margin-bottom: 32px;
`

const ControlsContainer = styled.div`
  display: flex;

  ${props =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      width: 100%;
    `}
`

export const CreateStartDateView = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const {
    minDate,
    maxDate,
    selectedStartDate,
    onStartDateChanged,
    prevCreateLeaveStage,
    nextCreateLeaveStage,
    highlightedDateNow
  }: ICreateLeaveContext = useContext(LeaveContext)
  const { i18Context } = useContext(ScreenContext)

  const startDateRef = useRef(null)
  const { leaveType } = props
  const startDatePicker = useMemo(
    () => (
      <StartDatePicker
        title={t(`createLeave.${leaveType}.leaveStartDate`)}
        defaultActiveStartDate={moment.max(minDate, getNowMoment())}
        momentCurrentMinMax={{
          current: selectedStartDate,
          min: minDate,
          max: maxDate
        }}
        placeholder={t('common.selectDate')}
        onDateChanged={onStartDateChanged}
        calendarRef={startDateRef}
        highlightedDate={highlightedDateNow}
      />
    ),
    [
      t,
      leaveType,
      selectedStartDate,
      minDate,
      maxDate,
      onStartDateChanged,
      highlightedDateNow
    ]
  )

  const showsLeaveNote = useMemo(
    () => minDate.isAfter(getNowMoment(), 'day'),
    [minDate]
  )

  const showsChosePastDateNote: boolean = useMemo(
    () =>
      highlightedDateNow &&
      selectedStartDate &&
      selectedStartDate.utc().isBefore(highlightedDateNow, 'day'),
    [selectedStartDate, highlightedDateNow]
  )

  const content: any = useMemo(
    () => (
      <>
        <ControlsContainer>{startDatePicker}</ControlsContainer>
        <CreateLeaveNote
          text={t(`createLeave.${leaveType}.startDateNotice`, {
            date: minDate.format(DATE_FORMAT),
            context: i18Context
          })}
          shows={showsLeaveNote}
        />
        <CreateLeaveNote
          text={t('createLeave.chosePastDateNote')}
          shows={showsChosePastDateNote}
          marginTop={32}
        />
        <CreateLeaveButtons
          backTitle={t('common.back')}
          nextTitle={t('common.next')}
          onBackClick={prevCreateLeaveStage}
          onNextClick={nextCreateLeaveStage}
          isNextDisabled={!selectedStartDate}
        />
      </>
    ),
    [
      startDatePicker,
      t,
      prevCreateLeaveStage,
      nextCreateLeaveStage,
      selectedStartDate,
      i18Context,
      leaveType,
      minDate,
      showsLeaveNote,
      showsChosePastDateNote
    ]
  )

  return (
    <CreateLeaveContentContainer>
      <CreateLeaveTitle>{t(`createLeave.${leaveType}.title`)}</CreateLeaveTitle>
      <CreateLeaveDescription>
        {t(`createLeave.${leaveType}.description`, {
          context: i18Context
        })}
      </CreateLeaveDescription>
      {content}
    </CreateLeaveContentContainer>
  )
})

CreateStartDateView.displayName = 'CreateStartDateView'

export default CreateStartDateView
