import React, { useContext, useMemo } from 'react'
import withRouter from 'src/components/hooks/useRouter'
import PublishedLeaveView from 'src/components/pages/PublishedLeavePage/components/PublishedLeaveView'
import Context, {
  IPublishedLeaveContext
} from 'src/components/pages/PublishedLeavePage/context'
import AbsenceTimeline from 'src/features/ManagerJourneyMap/components/AbsenceTimeline'
import FlexibleContainer from 'src/components/FlexibleContainer'
import styled from 'styled-components'

interface IProps {
  onBackClicked: () => void
}

const Container = styled(FlexibleContainer)`
  margin-top: 16px;
  min-width: 348px;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.dark10};
  height: auto;
`

export const PreviewTimeline = React.memo((props: IProps) => {
  const { onBackClicked } = props

  const { leave } = useContext<IPublishedLeaveContext>(Context)

  const employeeAbsence = useMemo(() => {
    if (!leave?.managerActivationInfo) {
      return null
    }

    const info = leave?.managerActivationInfo

    return {
      startDate: info.startDate,
      endDate: info.endDate,
      timeline: info.timeline
    } as IAbsence
  }, [leave])

  return (
    <PublishedLeaveView name={'previewTimeline'} onBackClicked={onBackClicked}>
      <Container>
        <AbsenceTimeline absence={employeeAbsence} showCurrent={false} />
      </Container>
    </PublishedLeaveView>
  )
})

PreviewTimeline.displayName = 'PreviewTimeline'

export default withRouter(PreviewTimeline)
