import { IGetPeriodConfigInput, ITimelinePeriodConfig } from '../../../index'
import LeaveDateTimelinePicker from 'src/features/Timeline/components/vertical/LeaveDurationPickers/LeaveDateTimelinePicker'

export default (
  baseConfig: ITimelinePeriodConfig,
  input: IGetPeriodConfigInput
) => ({
  ...baseConfig,
  datePickerComponent: LeaveDateTimelinePicker,
  removable: input.leave.type !== 'Sickness'
})
