import React, { ReactNode, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PopoverArrow from 'src/components/Popover/components/Arrow'
import Popover from 'src/components/Popover'
import useHovered from 'src/components/hooks/useHovered'
import createTheme from 'src/theme'

const { colors } = createTheme()

export interface IHrAdminFilter {
  options: string[]
  selectedIndex: number
  onOptionSelected: (index: number) => void
}

const Container = styled.div`
  margin-left: 24px;
  position: relative;
`

const InnerContainer = styled.div`
  width: 100%;
`

const Button = styled.button<{ $hovered?: boolean }>`
  display: flex;

  &:focus {
    outline: none;
    text-decoration: underline;
    color: ${(props: any) =>
      props.$hovered ? props.theme.colors.main110 : props.theme.colors.main100};
  }
`

const PopoverContainer = styled.div`
  width: 100%;
  position: relative;
`

const Title = styled.span<{ $hovered: boolean }>`
  line-height: 130%;
  color: ${(props: any) =>
    props.$hovered ? props.theme.colors.main110 : props.theme.colors.main100};
  cursor: pointer;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
          font-weight: 400;
        `}
`

const ArrowWrapper = styled(PopoverArrow)`
  cursor: pointer;

  ${props =>
    props.theme.isDesktop
      ? css`
          margin-top: -1px;
        `
      : css`
          margin-top: -3px;
          margin-left: 8px;
        `}
`

const OptionsContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const OptionText = styled.button<any>`
  user-select: none;
  cursor: pointer;
  line-height: 130%;
  color: ${props => props.theme.colors.dark60};
  text-align: center;
  width: 100%;

  ${props =>
    props.theme.isDesktop
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
        `}

  &:hover {
    color: ${props => props.theme.colors.main100};
  }

  &:focus {
    outline: none;
    color: ${props => props.theme.colors.main100};
  }

  padding: 8px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

export const HrAdminFilter = React.memo((props: IHrAdminFilter) => {
  const { options, selectedIndex, onOptionSelected } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const containerRef: any = useRef(null)
  const popoverTargetRef: any = useRef(null)
  const popoverRef: any = useRef(null)
  const hovered: boolean = useHovered(containerRef)

  const onOptionSelectedCallback = useCallback(
    (index: number) => {
      onOptionSelected(index)
    },
    [onOptionSelected]
  )

  const renderPopover = () => {
    const getOptions = (): ReactNode[] => {
      const result: ReactNode[] = []
      options.forEach((option: string, index: number) => {
        if (index !== selectedIndex) {
          result.push(
            <OptionText
              key={option}
              onClick={() => {
                onOptionSelectedCallback(index)
              }}
              selected={selectedIndex === index}
            >
              {option}
            </OptionText>
          )
        }
      })
      return result
    }

    return (
      <Popover
        parent={popoverTargetRef.current}
        ref={popoverRef}
        afterStateChanged={(value: boolean) => {
          setIsOpen(value)
        }}
        offset={{ top: -20 }}
        minWidth={136}
      >
        <OptionsContainer>{getOptions()}</OptionsContainer>
      </Popover>
    )
  }

  const togglePopover = () => {
    const { current } = popoverRef
    if (!current) {
      return
    }

    current.toggle()
  }

  return (
    <Container ref={containerRef}>
      <InnerContainer>
        <Button onClick={togglePopover}>
          <Title $hovered={hovered}>{options[selectedIndex]}</Title>
          <ArrowWrapper
            isOpen={isOpen}
            hoveredColor={colors.main110}
            hovered={hovered}
          />
        </Button>
      </InnerContainer>
      <PopoverContainer ref={popoverTargetRef}>
        {renderPopover()}
      </PopoverContainer>
    </Container>
  )
})

HrAdminFilter.displayName = 'HrAdminFilter'

export default HrAdminFilter
