import { QueryResult, useQuery } from '@apollo/client'
import { querySetBirthDate } from 'src/graphql/queries'
import { useState } from 'react'
import isEqual from 'lodash.isequal'

export interface IBirthDate {
  loading: boolean
  error: any
  leave: ILeave
}

const useBirthDate = (variables: any): IBirthDate => {
  const [leave, setLeave] = useState(null)
  const [oldVariables, setOldVariables] = useState({})

  const returnValue: IBirthDate = {
    loading: false,
    error: null,
    leave
  }

  const leaveResult: QueryResult = useQuery(querySetBirthDate, {
    fetchPolicy: 'network-only',
    variables,
    skip: !!leave && isEqual(oldVariables, variables)
  })

  if (leaveResult.loading) {
    returnValue.loading = true
    return returnValue
  }

  if (leaveResult.error) {
    returnValue.error = leaveResult.error
    return returnValue
  }

  if (leaveResult.data) {
    const l: ILeave = leaveResult.data.setBirthDate
    returnValue.leave = l
    setLeave(l)
    setOldVariables(variables)
  }

  return returnValue
}

export default useBirthDate
