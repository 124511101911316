import React from 'react'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import zIndex from 'src/constants/zIndex'
import { Icon } from 'src/UIKit'
import { useTranslation } from 'react-i18next'

interface IProps {
  children: string
  onExit: () => void
}

const TitleContainer = styled.div`
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.light100};
  display: flex;
  align-items: center;
  position: relative;
  z-index: ${zIndex.timeline.mobile.detailsViewTitle};
  box-shadow: 0 4px 13px rgb(98 106 116 / 10%);
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${props => props.theme.colors.dark60};
  margin-bottom: 4px;

  &:first-child {
    margin-left: 16px;
  }
`

const ButtonContainer = styled.button`
  outline: none;

  &:focus {
    outline: 2px solid ${props => props.theme.colors.main100};
    outline-offset: -8px;
    border-radius: 12px;
  }
`

export const CloseWrapper = styled(Icon)`
  padding: 22px 16px 22px 20px;
`

const VacationViewTitleMobile = React.memo(
  (props: IProps) => {
    const { children, onExit } = props
    const { t } = useTranslation()

    return (
      <TitleContainer>
        <ButtonContainer
          aria-label={t('common.accessibilityText.backToAllItems')}
          onClick={onExit}
        >
          <CloseWrapper name={'close_crossing'} />
        </ButtonContainer>
        <Title>{children}</Title>
      </TitleContainer>
    )
  },
  (prev: IProps, next: IProps): boolean => isEqual(prev, next)
)

VacationViewTitleMobile.displayName = 'VacationViewTitleMobile'

export default VacationViewTitleMobile
