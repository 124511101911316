export const authInfo = new (class {
  private customerDisabled: boolean
  private enableOptOut: boolean
  private enableOtpLogin: boolean

  public constructor() {
    this.enableOptOut = false
    this.enableOtpLogin = false
  }

  public getCustomerDisabled() {
    return this.customerDisabled
  }

  public setCustomerDisabled(value: boolean) {
    this.customerDisabled = value
  }

  public getEnableOptOut() {
    return this.enableOptOut
  }

  public setEnableOptOut(value: boolean) {
    this.enableOptOut = value
  }

  public getEnableOtpLogin() {
    return this.enableOtpLogin
  }

  public setEnableOtpLogin(value: boolean) {
    this.enableOtpLogin = value
  }
})()
