import React from 'react'
import isEqual from 'lodash.isequal'
import ListItem from '../Item'
import Title from './Title'

interface IProps {
  className?: string
  items: IJourneyMapItem[]
  onItemCompletedClick: (id: string) => void
  onItemClick: (id: string) => void
  fadesOut: boolean
  title: string
  itemToScrollTo: IJourneyMapItem
  itemToScrollToRef: any
  animatesMarginTop: boolean
  useShortTopMargin?: boolean
}

export const areItemsEqual = (
  prev: IJourneyMapItem[],
  next: IJourneyMapItem[]
): boolean => {
  const count: number = prev.length
  if (count !== next.length) {
    return false
  }

  for (let i = 0; i < count; i++) {
    const i1: IJourneyMapItem = prev[i]
    const i2: IJourneyMapItem = next[i]
    if (!isEqual(i1, i2)) {
      return false
    }
  }

  return true
}

export const arePropsEqual = (prevProps: IProps, nextProps: IProps) => {
  if (prevProps.title !== nextProps.title) {
    return false
  }

  if (prevProps.itemToScrollTo.id !== nextProps.itemToScrollTo.id) {
    return false
  }

  if (prevProps.fadesOut !== nextProps.fadesOut) {
    return false
  }

  if (prevProps.animatesMarginTop !== nextProps.animatesMarginTop) {
    return false
  }

  if (!areItemsEqual(prevProps.items, nextProps.items)) {
    return false
  }

  return true
}

const JourneyMapMonthSection = React.memo((props: IProps) => {
  const {
    items,
    itemToScrollTo,
    onItemCompletedClick,
    onItemClick,
    title,
    fadesOut,
    animatesMarginTop,
    itemToScrollToRef,
    useShortTopMargin
  } = props

  return (
    <React.Fragment>
      <Title
        fadesOut={fadesOut}
        animatesMarginTop={animatesMarginTop}
        useShortTopMargin={useShortTopMargin}
      >
        {title}
      </Title>
      {items.map((item: IJourneyMapItem) => (
        <ListItem
          key={item.id}
          item={item}
          ref={item.id === itemToScrollTo.id ? itemToScrollToRef : null}
          onItemCompletedClick={() => {
            onItemCompletedClick(item.id)
          }}
          onItemClick={() => {
            onItemClick(item.id)
          }}
        />
      ))}
    </React.Fragment>
  )
}, arePropsEqual)

JourneyMapMonthSection.displayName = 'JourneyMapMonthSection'

export default JourneyMapMonthSection
