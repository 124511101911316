import React from 'react'
import styled from 'styled-components'
import { Moment } from 'moment'

interface IProps {
  className?: string
  text: string
  date: Moment
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 100%;
  color: ${p => p.theme.colors.dark60};
  min-width: max-content;
`

const Date = styled.span`
  margin: 16px 0 0;
  font-size: 16px;
  line-height: 100%;
  color: ${p => p.theme.colors.dakr80};
`

const StartDateComponent = React.memo((props: IProps) => {
  const { text, date, className } = props
  return (
    <Container className={className}>
      <Text>{text}</Text>
      <Date>{date.format('MMM DD, YYYY')}</Date>
    </Container>
  )
})

StartDateComponent.displayName = 'StartDateComponent'

export default StartDateComponent
