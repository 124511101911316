import { setCanBeShown as eligibilityNoticeSetCanBeShown } from './EligibilityNoticeManager'

export const ELIGIBILITY_NOTICE_KEY = 'eligibilityNotice'
export const JOURNEY_PRIORITIES_KEY = 'journeyPriorities'
export const JOURNEY_PERIODS_KEY = 'journeyPeriods'
export const VACATION_OVERVIEW_KEY = 'vacationOverview'
export const TRANSITION_FLOW_KEY = 'transitionFlow'
export const TIMELINE_REVIEW_ALERTS_KEY = 'timelineReviewAlerts'
export const VIEW_NEXT_STEPS_NOTICE_KEY = 'viewNextStepsNotice'
export const VIEW_LEAVE_NOTICE_DISABILITY_KEY = 'leaveNoticeDisability'
export const VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY = 'leaveNoticePolicyChange'
export const VIEW_LEAVE_NOTICE_TPA_KEY = 'leaveNoticeTpa'
export const VIEW_LEAVE_NOTICE_TRANSITION_PL_KEY = 'leaveNoticeTransitionPL'
export const SAVE_CHANGES_TOOLTIP_KEY = 'saveChangesTooltip'

export const singinLocalStorageHook = () => {
  eligibilityNoticeSetCanBeShown(true)
  localStorage.setItem(JOURNEY_PRIORITIES_KEY, '[]')
  localStorage.setItem(JOURNEY_PERIODS_KEY, '[]')
  localStorage.setItem(
    VACATION_OVERVIEW_KEY,
    JSON.stringify({ canBeShown: true })
  )
  localStorage.setItem(TRANSITION_FLOW_KEY, JSON.stringify({ skip: false }))
}

export const closePlanLocalStorageHook = () => {
  localStorage.setItem(TRANSITION_FLOW_KEY, JSON.stringify({ skip: false }))
  localStorage.setItem(
    VACATION_OVERVIEW_KEY,
    JSON.stringify({ canBeShown: true })
  )
  localStorage.setItem(
    VIEW_NEXT_STEPS_NOTICE_KEY,
    JSON.stringify({ canBeShown: true })
  )
  localStorage.setItem(
    VIEW_LEAVE_NOTICE_TRANSITION_PL_KEY,
    JSON.stringify({ canBeShown: true })
  )
  localStorage.setItem(
    VIEW_LEAVE_NOTICE_POLICY_CHANGE_KEY,
    JSON.stringify({ canBeShown: true })
  )
  localStorage.setItem(
    SAVE_CHANGES_TOOLTIP_KEY,
    JSON.stringify({ canBeShown: true })
  )
}
